import defaultTheme from 'evergreen-ui/commonjs/themes/default';

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    DialogBody: {
      ...defaultTheme.components.DialogBody,
      baseStyle: {
        ...defaultTheme.components.baseStyle,
        paddingX: 18,
        paddingY: 18,
      },
    },
    DialogFooter: {
      ...defaultTheme.components.DialogFooter,
      baseStyle: {
        ...defaultTheme.components.baseStyle,
        backgroundColor: 'hsla(210, 44%, 96%, 1)',
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        paddingX: 18,
        paddingY: 18,
      },
    },
    DialogHeader: {
      ...defaultTheme.components.DialogHeader,
      baseStyle: {
        ...defaultTheme.components.baseStyle,
        paddingTop: 18,
        paddingX: 18,
      },
    },
  },
};

export default theme;
