import { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button/Button';
import Loader from '@hiredigital/ui/Loader';

import { postAssignmentNewProject } from '@apis/assignments';
import ProjectsList from './ProjectsList';

import Styles from './AddTalent.module.scss';

const ActionButton = ({ title, isLoading, ...props }) => (
  <button className={Styles.menu} {...props}>
    {isLoading && (
      <Loader style={{ marginRight: '10px' }} color={Loader.Color.BLUE} size={Loader.Size.SMALL} />
    )}
    {title}
  </button>
);

const AddTalent = ({
  title,
  orgUuid,
  talentUuid,
  assignment,
  isLoading,
  isShown,
  onConfirm,
  onClose,
  onCloseComplete,
}) => {
  const history = useHistory();
  const [isListShown, setIsListShown] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [isDialogShown, setIsDialogShown] = useState(isShown);

  useEffect(() => {
    setIsDialogShown(isShown);
  }, [isShown]);

  const handleConvertBrief = () => {
    setIsConverting(true);
    postAssignmentNewProject(assignment?.uuid, {})
      .then(({ data }) => {
        setIsConverting(false);
        history.push(`/projects/${data?.uuid}`);
      })
      .catch((error) => {
        setIsConverting(false);
      });
  };

  const handleConfirm = () => {
    if (!isListShown) {
      setIsDialogShown(false);
      onConfirm?.();
      return;
    }
    history.push('/projects');
  };

  const handleClose = () => {
    setIsListShown(false);
    onClose?.();
  };

  return (
    <Dialog
      bodyClass={Styles.dialog}
      isOpen={isDialogShown}
      title={title}
      onClose={handleClose}
      onCloseComplete={onCloseComplete}>
      <div className={Styles.content}>
        {isListShown ? (
          <Fragment>
            <ProjectsList orgUuid={orgUuid} talentUuid={talentUuid} assignment={assignment} />
            <div style={{ marginTop: '30px' }}>
              <Button onClick={handleConfirm} type={Button.Type.BLUE} isLoading={isLoading}>
                {`Go to Workspace`}
              </Button>
              <Button onClick={handleClose} type={Button.Type.LIGHT_GRAY}>
                {`Return to Brief`}
              </Button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <ActionButton
              title={`Convert this Brief to a New Project`}
              onClick={handleConvertBrief}
              isLoading={isConverting}
            />
            <ActionButton
              title={`Assign to an Existing Project`}
              onClick={() => setIsListShown(true)}
            />
            <div style={{ marginTop: '30px' }}>
              <Button onClick={handleClose} type={Button.Type.LIGHT_GRAY} isLoading={isLoading}>
                {`Return to Brief`}
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    </Dialog>
  );
};

AddTalent.propTypes = {};

export default AddTalent;
