/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect } from 'react';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Loader from '@hiredigital/ui/Loader';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';

import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';

import {
  patchBrief,
  postBriefTalent,
  deleteBriefTalent,
  postBriefFormat,
  deleteBriefFormat,
  putBriefTalent,
  postBriefSubmit,
  postBriefLink,
  postBriefFile,
  deleteBriefFile,
  deleteBriefLink,
} from '@apis/briefs';
import { BriefSearchType } from '@hiredigital/lib/helpers/enum';
import { convertNumberToText } from '@hiredigital/lib/helpers/utils';
import { useCommon } from '@context/common';

import References from '@hiredigital/ui/Form/References';
import TileSelector from './components/tiles/TileSelector';
import SearchTypeOption from './components/option/Option';

import MainContainer from '../StagesLayout/MainContainer';
import TalentRequirements from './TalentRequirements';
import ActionBar from '../StagesLayout/ActionBar';
import FormLayout from '../StagesLayout/FormLayout';

import Quill from '@hiredigital/ui/Quill/Editor';

import Styles from './Styles.module.scss';

const options = [
  {
    title: 'Search by Skill',
    id: BriefSearchType.TALENT.id,
    renderIcon: () => (
      <svg
        className={Styles.secondOptionIcon}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 30 30'
        icon-skill=''>
        <path
          fill='currentColor'
          fillRule='nonzero'
          d='M20 25.51235H10v-3s.1077-2.10551-.693-4.31545-2.2986-4.31546-2.2986-4.31546l6.50487-7.22917c.73883-.8211 2.0034-.88779 2.8245-.14895a2 2 0 0 1 .14896.14895l6.50486 7.22917s-1.53571 2.20994-2.29858 4.31546C19.93014 20.3024 20 22.51235 20 22.51235v3zM14.36067 8.35712L9.4963 14.0488c.2179.3649.44721.7702.67688 1.2065.40225.76415.74622 1.52067 1.0142 2.2603.35434.97797.58065 1.98812.7055 2.99676h6.22476c.11035-.90044.29582-1.79369.57604-2.65125.038-.11629.07764-.23147.11895-.3455a13.61571 13.61571 0 0 1 .12632-.33458c.2524-.64335.56648-1.30872.92864-1.98798.2075-.38915.42359-.7696.64282-1.13642l-4.87107-5.6995v4.37814c.5645.24664.95899.8099.95899 1.46532 0 .88273-.7156 1.59832-1.59832 1.59832-.88273 0-1.59832-.7156-1.59832-1.59832 0-.65541.3945-1.21868.959-1.46532V8.35712z'></path>
      </svg>
    ),
  },
  {
    title: 'Search by Format',
    id: BriefSearchType.FORMAT.id,
    // eslint-disable-next-line react/display-name
    renderIcon: () => (
      <svg
        className={Styles.secondOptionIcon}
        viewBox='0 0 30 30'
        xmlns='http://www.w3.org/2000/svg'
        icon-assessment=''>
        <g fill='none' fillRule='evenodd' stroke='currentColor' strokeWidth='2'>
          <path d='M8.5 6A1.5 1.5 0 0 0 7 7.5v15A1.5 1.5 0 0 0 8.5 24h13a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 21.5 6h-13z'></path>
          <path d='M12 11h6M12 15h6M12 19h6' strokeLinecap='round'></path>
        </g>
      </svg>
    ),
  },
];

const BriefRequirements = ({ briefUuid, initialBrief, onBack, onAfterSubmit }) => {
  const common = useCommon();
  const [searchType, setSearchType] = useState(initialBrief.searchType);
  const [busyIndexes, setBusyIndexes] = useState([]);
  const [talentTypeList, setTalentTypeList] = useState([]);
  const [formatList, setFormatList] = useState([]);
  const [talents, setTalents] = useState(initialBrief?.talents);
  const [formats, setFormats] = useState(initialBrief?.formats);
  const [talentsRequirement, setTalentsRequirement] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState(initialBrief?.title);
  const [description, setDescription] = useState(initialBrief?.description);
  const [isAdditionalVisible, setIsAdditionalVisible] = useState(
    initialBrief?.description !== '' ||
      initialBrief?.attachments?.length ||
      initialBrief?.links?.length
      ? true
      : false
  );
  const [attachments, setAttachments] = useState(initialBrief?.attachments || []);
  const [links, setLinks] = useState(initialBrief?.links || []);

  const mappedMethods = {
    description: setDescription,
    title: setTitle,
  };

  useEffect(() => {
    setTalentTypeList(getCompanyVisibleItems(common?.talentTypes));
    setFormatList(getCompanyVisibleItems(common?.formats));
  }, [common]);

  // useEffect(() => {
  //   console.log(initialBrief);
  // }, [initialBrief]);

  useEffect(() => {
    // console.log('autosave');
    patchBrief(briefUuid, { searchType });
  }, [searchType]);

  const createTalentsRequest = (requirements) =>
    Object.keys(requirements).map((v, k) => {
      const { uuid, skills, countries, languages } = talentsRequirement?.[v];
      return putBriefTalent(briefUuid, uuid, { skills, countries, languages });
    });

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await createTalentsRequest(talentsRequirement);

      console.log(talentsRequirement);

      await patchBrief(briefUuid, { searchType, title, description });

      const { data } = await postBriefSubmit(initialBrief?.uuid, { page: initialBrief?.stage });
      setIsSaving(false);
      onAfterSubmit?.(data);
    } catch (error) {
      setIsSaving(false);
      console.error(error);
    }
  };

  const getCompanyVisibleItems = (data) => {
    return orderBy(data?.filter((v) => v.companyVisible) || [], 'order', 'asc');
  };

  const handleAddTalent = async (talentType, idx) => {
    setBusyIndexes([idx]);
    const { data } = await postBriefTalent(briefUuid, { talentType });
    setTalents([...talents, data]);
    setBusyIndexes([]);
  };

  const handleAddFormat = async (format, idx) => {
    setBusyIndexes([idx]);
    const { data } = await postBriefFormat(briefUuid, { format });
    setFormats([...formats, data]);
    setBusyIndexes([]);
  };

  // const handleItemAdd = async (v) => {
  //   if (searchType === BriefSearchType.TALENT.id) {
  //     const { data } = await postBriefTalent(briefUuid, { talentType: v });
  //     setTalents([...talents, data]);
  //   } else {
  //     const { data } = await postBriefFormat(briefUuid, { format: v });
  //     setFormats([...formats, data]);
  //   }
  // };

  // const handleItemRemove = async (v) => {
  //   if (!!v?.items?.length) {
  //     if (searchType === BriefSearchType.TALENT.id) {
  //       const { uuid } = v.items[0];
  //       await deleteBriefTalent(briefUuid, uuid);
  //       const list = talents.filter((t) => t.uuid !== uuid);
  //       setTalents(list);
  //     } else {
  //       const { uuid } = v.items[0];
  //       await deleteBriefFormat(briefUuid, uuid);
  //       const list = formats.filter((t) => t.uuid !== uuid);
  //       setFormats(list);
  //     }
  //   }
  // };

  const handleRemoveTalent = (talentType) => {
    try {
      const { uuid } = talentType.items[0];
      deleteBriefTalent(briefUuid, uuid);

      const list = talents.filter((t) => t.uuid !== uuid);
      setTalents(list);
    } catch (e) {
      // pass
    }
  };

  const handleRemoveFormat = (format) => {
    try {
      const { uuid } = format.items[0];
      deleteBriefFormat(briefUuid, uuid);

      const list = formats.filter((t) => t.uuid !== uuid);
      setFormats(list);
    } catch (e) {
      // pass
    }
  };

  const handleInputBlur = (e) => {
    // Autosave
    patchBrief(briefUuid, { title, description });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    mappedMethods[name](value);
  };

  const handleTalentRequirementChange = (data) => {
    setTalentsRequirement({ ...talentsRequirement, [data.uuid]: { ...data } });
  };

  const handleBack = () => onBack?.('/start');

  const activeKey = searchType === BriefSearchType.TALENT.id ? 'talentType' : 'format';
  const subRequirements = groupBy(talents, (v) => v?.[activeKey]?.id);
  let ctr = 0;

  return (
    <MainContainer
      title={`Work With a New Talent`}
      subtitle={`Let's find you the best solution for your project.`}
      onBack={handleBack}
      backLabel={`Back to Start`}>
      <div>
        <FormLayout
          title={`1. Search by Skill or Format`}
          subtitle={`Are you looking for a specific kind of talent or looking to complete a project?`}>
          <FormLayout.Row>
            <FormLayout.MainColumn direction={FormLayout.MainColumn.Direction.ROW}>
              {options.map((v, idx) => (
                <SearchTypeOption
                  className={Styles.searchTypeOption}
                  key={idx}
                  title={v.title}
                  isActive={searchType === v.id}
                  onClick={() => setSearchType(v.id)}
                  renderIcon={v.renderIcon}
                  height={60}
                />
              ))}
            </FormLayout.MainColumn>
            <FormLayout.AsideColumn noBorder />
          </FormLayout.Row>
        </FormLayout>

        {!!searchType && (
          <Fragment>
            {searchType === BriefSearchType.TALENT.id ? (
              <FormLayout
                title={`2. What kind of talents do you need?`}
                subtitle={`You can select multiple talents.`}>
                <FormLayout.Row>
                  <FormLayout.MainColumn>
                    <TileSelector
                      getIdentifier={(v) => v?.talentType?.id}
                      list={talentTypeList}
                      data={talents}
                      onItemAdd={handleAddTalent}
                      onItemSubtract={handleRemoveTalent}
                      busyIndexes={busyIndexes}
                    />
                  </FormLayout.MainColumn>
                  <FormLayout.AsideColumn>
                    <p className={Styles.normalSubtitle}>
                      {`Select as many as you think you'll need to accomplish your brief.`}
                    </p>
                  </FormLayout.AsideColumn>
                </FormLayout.Row>
              </FormLayout>
            ) : (
              <FormLayout
                title={`2. What kind of projects do you need?`}
                subtitle={`You can select multiple talents.`}>
                <FormLayout.Row>
                  <FormLayout.MainColumn>
                    <TileSelector
                      getIdentifier={(v) => v?.format?.id}
                      list={formatList}
                      data={formats}
                      onItemAdd={handleAddFormat}
                      onItemSubtract={handleRemoveFormat}
                      busyIndexes={busyIndexes}
                    />
                  </FormLayout.MainColumn>
                  <FormLayout.AsideColumn>
                    <p className={Styles.normalSubtitle}>
                      {`Select as many as you think you'll need to accomplish your brief.`}
                    </p>
                  </FormLayout.AsideColumn>
                </FormLayout.Row>
              </FormLayout>
            )}
          </Fragment>
        )}

        {searchType === BriefSearchType.TALENT.id &&
          Object.keys(subRequirements).map((v) => {
            return subRequirements[v].map((t, idx) => {
              ctr += 1;
              return (
                <TalentRequirements
                  key={t.uuid}
                  uuid={t.uuid}
                  titleNumber={ctr}
                  highlightedText={`${convertNumberToText(idx + 1)} ${t[
                    activeKey
                  ]?.label?.toLowerCase()}`}
                  data={t}
                  onChange={handleTalentRequirementChange}
                />
              );
            });
          })}

        {(!!Object.keys(subRequirements)?.length || searchType === BriefSearchType.FORMAT.id) && (
          <FormLayout
            title={`3. General Requirements`}
            subtitle={`You can update this section up until the talent has been assigned.`}>
            <FormLayout.Row>
              <FormLayout.MainColumn>
                <InputGroup>
                  <InputContainer>
                    <TextInput
                      label={`Title`}
                      name={`title`}
                      defaultValue={title}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                    />
                  </InputContainer>
                </InputGroup>

                {isAdditionalVisible ? (
                  <Fragment>
                    <InputGroup>
                      <InputContainer>
                        <Quill
                          legacyCompat
                          name='description'
                          label='Requirements'
                          placeholder={`Describe any project requirements`}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          defaultValue={description}
                        />
                      </InputContainer>
                    </InputGroup>
                    <References
                      attachments={attachments}
                      links={links}
                      postLinkRequest={(data, config) => postBriefLink(briefUuid, data, config)}
                      postFileRequest={(data, config) => postBriefFile(briefUuid, data, config)}
                      deleteLinkRequest={(id) => deleteBriefLink(briefUuid, id)}
                      deleteFileRequest={(id) => deleteBriefFile(briefUuid, id)}
                    />
                  </Fragment>
                ) : (
                  <div>
                    <button className={Styles.btnLink} onClick={() => setIsAdditionalVisible(true)}>
                      {`+ Add additional requirements or files`}
                    </button>
                  </div>
                )}
              </FormLayout.MainColumn>
              <FormLayout.AsideColumn>
                <p className={Styles.normalSubtitle}>
                  {`Add any other requirements, files or other information that will help us with matching the talent or help the talent when they are assigned to your organization.`}
                </p>
              </FormLayout.AsideColumn>
            </FormLayout.Row>
          </FormLayout>
        )}
      </div>
      {!!searchType && (
        <ActionBar isWhite>
          <Button name='submit' type={Button.Type.BLUE} onClick={handleSave}>
            {isSaving && (
              <Loader
                style={{ marginRight: '10px' }}
                color={Loader.Color.WHITE}
                size={Loader.Size.SMALL}
              />
            )}
            {`Save and Continue`}
          </Button>
        </ActionBar>
      )}
    </MainContainer>
  );
};

BriefRequirements.propTypes = {};

export default BriefRequirements;
