import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Action from '@hiredigital/ui/Action';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';

import { authRequest } from '@apis/utils';
import { onSkillsLoadOptions } from '@apis/dropdown';
import { ServiceRateType, ServiceRateStatus } from '@hiredigital/lib/helpers/enum';

import Quill from '@hiredigital/ui/Quill/Editor';
import SortableSelect from '@hiredigital/ui/Input/SortableSelect';
import Styles from './Styles.module.scss';
import { putServiceRate, postServiceRate, deleteServiceRate } from '@apis/serviceRates';
import { postTeamServiceRate, putTeamServiceRate, deleteTeamServiceRate } from '@apis/teams';
import CurrencyDropdown from '@hiredigital/ui/CurrencyDropdown';

const Type = {
  TALENT: 1,
  TEAM: 2,
};

const ServiceRateItem = ({
  service: initialService,
  onUpdateCompletion,
  onCreateCompletion,
  onDeleteCompletion,
  onCancelCompletion,
  resourceUuid,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [service, setService] = useState(initialService);

  useEffect(() => {
    if (!service?.uuid) {
      setEdit(true);
    }
    //
  }, []);

  const handleEdit = () => {
    setErrors({});
    setEdit(true);
  };

  const handleCancel = () => {
    setErrors({});
    setEdit(false);
    setService(initialService);

    if (!service.uuid) {
      onCancelCompletion?.();
    }
  };

  const handleChange = (event, valueKey) => {
    setErrors({});
    const { name, value } = event.target;
    setService((s) => ({ ...s, [name]: valueKey ? value[valueKey || 'id'] || value : value }));
  };

  const handleEnumChange = (event) => {
    setErrors({});
    const { name, value } = event.target;
    setService((s) => ({ ...s, [name]: value.id }));
  };

  const validateService = () => {
    // FIXME: This validation should be in the backend.
    const m = 'This field is required.';
    const e = {};
    if (!service.name) e.name = [m];
    if (!service.currency) e.currency = [m];
    if (!service.rate) e.rate = [m];
    else if (parseFloat(service.rate) <= 0.0) e.rate = ['Rate must be above zero.'];
    if (!service.rateType) e.rateType = [m];
    setErrors(e);
    return Object.keys(e).length <= 0;
  };

  const handleSubmit = async () => {
    if (Object.keys(errors).length > 0) return;
    setErrors({});

    // FIXME: Remove when backend validation is fixed.
    if (!validateService()) return;

    const submitData = {
      ...service,
      orderedSkills:
        (service.orderedSkills &&
          !!service.orderedSkills.length &&
          service.orderedSkills.map((skill) => skill.id)) ||
        [],
      clientRate: service.clientRate || 0,
      rate: service.rate || 0,
    };
    setLoading(true);
    if (service.uuid) {
      // Update
      try {
        let response;
        if (resourceUuid) {
          response = await putTeamServiceRate(resourceUuid, service.uuid, submitData);
        } else {
          response = await putServiceRate(service.uuid, submitData);
        }
        const { data } = response;
        onUpdateCompletion?.(data);
        setService(data);
        setEdit(false);
      } catch (e) {
        console.log(e);
        setErrors(e.response.data);
      }
      setLoading(false);
    } else {
      // Create
      try {
        let response;
        if (resourceUuid) {
          response = await postTeamServiceRate(resourceUuid, submitData);
        } else {
          response = await postServiceRate(submitData);
        }
        const { data } = response;
        onCreateCompletion?.(data);
        setService(data);
        setEdit(false);
      } catch (e) {
        console.log(e);
        setErrors(e.response.data);
      }
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      if (resourceUuid) {
        await deleteTeamServiceRate(resourceUuid, service.uuid);
      } else {
        await deleteServiceRate(service.uuid);
      }
      onDeleteCompletion?.(service.uuid);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCreateSkill = (value) => {
    const data = { label: value };
    const url = `skills/`;
    authRequest.post(url, data).then(({ data }) => {
      setService((s) => ({
        ...s,
        orderedSkills: (s.orderedSkills || []).concat([data]),
      }));
    });
  };

  return isEdit ? (
    <>
      <Card.Item>
        <div className={Styles.form}>
          <div className={Styles.formFieldsRow}>
            <div className={Styles.fieldsLeft}>
              <InputGroup>
                <InputContainer>
                  <TextInput
                    name='name'
                    label='Title'
                    placeholder='Add a title for your services'
                    error={errors?.name}
                    defaultValue={service.name}
                    onChange={handleChange}
                  />
                </InputContainer>
              </InputGroup>
              <InputGroup>
                <InputContainer style={{ flex: '100%' }} data-test-id='description'>
                  <Quill
                    style={{ minHeight: 100 }}
                    legacyCompat
                    name='description'
                    label='Details'
                    placeholder='Add details about your services. Include any specific types of work or other relevant information.'
                    error={errors?.description}
                    defaultValue={service?.description || ''}
                    onChange={handleChange}
                    className={Styles.fieldDescription}
                    inputClassName={Styles.fieldDescInput}
                  />
                </InputContainer>
              </InputGroup>

              <InputGroup>
                <InputContainer>
                  <SortableSelect
                    className={Styles.select}
                    value={service.orderedSkills || []}
                    name='orderedSkills'
                    label='Skills'
                    error={errors?.orderedSkills}
                    isClearable={false}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    isMulti
                    onChange={handleChange}
                    isPaginated
                    isCreatable
                    onCreateOption={handleCreateSkill}
                    loadOptions={onSkillsLoadOptions}
                  />
                </InputContainer>
              </InputGroup>
            </div>
            <div className={Styles.fieldsRight}>
              <InputGroup>
                <InputContainer>
                  <CurrencyDropdown
                    data-test-id='currency'
                    type={CurrencyDropdown.Type.PAYMENT}
                    error={errors?.currency}
                    enumKey={'currency'}
                    defaultValue={service.currency}
                    onChange={(e) => handleChange(e, 'currency')}
                  />
                </InputContainer>
              </InputGroup>
              <InputGroup>
                <InputContainer>
                  <TextInput
                    name='rate'
                    label='Rate'
                    type='number'
                    step='.01'
                    error={errors?.rate}
                    defaultValue={service.rate}
                    onChange={handleChange}
                  />
                </InputContainer>

                <InputContainer>
                  <Select
                    data-test-id='rateType'
                    className={Styles.select}
                    defaultValue={ServiceRateType.getEnum(service.rateType)}
                    classNamePrefix='s-contact'
                    name='rateType'
                    label='Per'
                    error={errors?.rateType}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    options={ServiceRateType.values}
                    onChange={handleEnumChange}
                  />
                </InputContainer>
              </InputGroup>
            </div>
          </div>
        </div>
      </Card.Item>
      <Card.Footer>
        {service.uuid && (
          <Button name='delete' type={Button.Type.WHITE} onClick={handleDelete}>
            {`Delete`}
          </Button>
        )}
        <Action>
          <Button name='cancel' type={Button.Type.WHITE_BLUE_OUTLINE} onClick={handleCancel}>
            {`Cancel`}
          </Button>
          <Button
            name='submit'
            type={Button.Type.BLUE}
            onClick={handleSubmit}
            isLoading={isLoading}>
            {`Save`}
          </Button>
        </Action>
      </Card.Footer>
    </>
  ) : (
    <Card.Item>
      <div className={Styles.listPreview}>
        <div className={Styles.row}>
          <div className={Styles.listItemText}>
            <p className={Styles.title}>{service.name ? service.name : 'New Rate'}</p>
            {service.rate && (
              <div className={Styles.rateType}>
                {`${service.currency} ${service.rate} ${
                  (service.rateType && ServiceRateType.getEnum(service.rateType).label) || ''
                }`}
              </div>
            )}
          </div>
          {service.status && (
            <div className={Styles.listItemText}>
              <p className={Styles.statusText}>
                {(ServiceRateStatus.getEnum(service.status) || {}).label || ''}
              </p>
            </div>
          )}
          <Action>
            <Button name='Edit' type={Button.Type.BLUE_OUTLINE} onClick={handleEdit}>
              {`Edit`}
            </Button>
          </Action>
        </div>
      </div>
    </Card.Item>
  );
};
ServiceRateItem.propTypes = {
  service: PropTypes.object,
  onUpdateCompletion: PropTypes.func,
  onDeleteCompletion: PropTypes.func,
  onCreateCompletion: PropTypes.func,
  onCancelCompletion: PropTypes.func,
};

export default ServiceRateItem;
