import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import FormLayout from '../StagesLayout/FormLayout';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Select from '@hiredigital/ui/Input/Select';

import { getTalentTypeSkills } from '@apis/common';
import { useCommon } from '@context/common';

const TalentRequirements = ({ titleNumber, highlightedText, data, onChange, uuid }) => {
  const common = useCommon();

  const [items, setItems] = useState({ uuid });

  const onSkillsLoadOptions = async (search, loadedOptions, { page }) => {
    const params = { params: { search, page, limit: 20 } };
    const {
      data: { results, meta },
    } = await getTalentTypeSkills(data?.talentType?.id, params);
    return {
      options: results,
      hasMore: meta.nextPage !== null,
      additional: {
        page: meta.nextPage,
      },
    };
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const payload = { ...items, [name]: value };
    setItems(payload);
    onChange?.(payload);
  };

  return (
    <FormLayout
      title={() => (
        <Fragment>
          {`2.${titleNumber} What requirements should the `}
          <span className={Styles.highlightType}>{highlightedText}</span>
          {` meet?`}
        </Fragment>
      )}
      subtitle={`Are you looking for a specific kind of talent or looking to complete a project?`}>
      <FormLayout.Row>
        <FormLayout.MainColumn>
          <InputGroup>
            <InputContainer>
              <Select
                label={`Preferred Skills`}
                name={`skills`}
                placeholder={`Select or add new skills`}
                onChange={handleChange}
                defaultValue={data?.skills || []}
                loadOptions={onSkillsLoadOptions}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ id }) => id}
                isMulti
                isPaginated
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <Select
                label={`Language`}
                name={`languages`}
                placeholder={`What language should the talent be fluent in`}
                onChange={handleChange}
                options={common?.languages}
                defaultValue={data?.languages || []}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                isMulti
              />
            </InputContainer>
            <InputContainer>
              <Select
                label={`Country`}
                name={`countries`}
                placeholder={`Where should we search for talents`}
                onChange={handleChange}
                options={common?.countries}
                defaultValue={data?.countries || []}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                isMulti
              />
            </InputContainer>
          </InputGroup>
        </FormLayout.MainColumn>
        <FormLayout.AsideColumn noBorder />
      </FormLayout.Row>
    </FormLayout>
  );
};

TalentRequirements.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string,
};

export default TalentRequirements;
