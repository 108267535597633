import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Styles from './PortfolioViewer.module.scss';

const ProfileRow = ({ data }) => {
  return (
    <div className={Styles.profileArea}>
      <img className={Styles.picture} src={data?.picture} alt={data?.name} />
      <div className={Styles.headerContent}>
        <a
          className={Styles.link}
          href={`/profiles/${data?.uuid}`}
          rel='nofollow noopener'
          target='_blank'>
          <div className={Styles.title}>{data?.name}</div>
        </a>
        <div className={Styles.headline}>{data?.headline || 'No Headline'}</div>
      </div>
    </div>
  );
};

const Title = ({ item, isPrivate }) => {
  // Case or Item
  // const url = item?.url || item?.image || item.attachment;
  // const caseUrl = currentCase?.url || currentCase?.image || currentCase?.attachment;

  try {
    if (isPrivate) {
      item.user.name = `${item?.user.firstName}  ${item?.user.lastName.charAt(0).toUpperCase()}.`;
    }
  } catch (e) {}

  return (
    <>
      {item?.user && <ProfileRow data={item?.user} />}
      {item?.teams?.[0] && <ProfileRow data={item?.teams?.[0]} />}
    </>
  );
};

Title.propTypes = {
  isPrivate: PropTypes.bool,
  item: PropTypes.object,
};

export default memo(Title);
