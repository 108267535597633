import { Fragment, useEffect, useState } from 'react';
import Styles from './Styles.module.scss';
import Section from '../StagesLayout/Section';
import FormLayout from '../StagesLayout/FormLayout';
import ActionBar from '../StagesLayout/ActionBar';
import Button from '@hiredigital/ui/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Select from '@hiredigital/ui/Input/Select';
import Dialog from '@hiredigital/ui/Dialog/Dialog';

import AvailableTimes from './components/times/AvailableTimes';
import { postCallDaysAvailable, patchBriefCall } from '@apis/calls';
import { patchOrganization } from '@apis/organizations';
import {
  patchBrief,
  postBriefFile,
  postBriefLink,
  deleteBriefFile,
  deleteBriefLink,
  getBrief,
} from '@apis/briefs';
import { CallPreferredPlatform, CallStatus } from '@hiredigital/lib/helpers/enum';
import ScheduledCall from './ScheduledCall';
import { useOrg } from '@context/org';
import { useCommon } from '@context/common';
import Quill from '@hiredigital/ui/Quill/Editor';
import References from '@hiredigital/ui/Form/References';
// import * as intercom from '../../lib/intercom';
import { useIntercom } from '@hiredigital/lib/providers/intercom';
import PhoneNumber from '@hiredigital/ui/PhoneNumber';

const BriefCall = ({ initialBrief, onBack }) => {
  const organization = useOrg();
  const common = useCommon();
  const intercom = useIntercom();

  const [brief, setBrief] = useState(initialBrief);
  const [timezone, setTimezone] = useState(null);
  const [daysAvailable, setDaysAvailable] = useState([]);
  const [timesAvailable, setTimesAvailable] = useState([]);
  const [isTimeLoading, setIsTimeLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [date, setDate] = useState(initialBrief?.call?.callDate);
  const [preferred, setPreferred] = useState(
    organization?.contact?.preferred || CallPreferredPlatform.WEB_CALL.id
  );
  const [phoneNumber, setPhoneNumber] = useState(organization?.contact?.phoneNumber);
  const [phoneCountry, setPhoneCountry] = useState(organization?.contact?.phoneCountry);
  const [timeSelected, setTimeSelected] = useState({
    startFormatted: initialBrief?.call?.startTimeFormatted,
  });
  const [description, setDescription] = useState(initialBrief?.description);
  const [isMounted, setIsMounted] = useState(false);
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isReferenceShown, setIsReferenceShown] = useState(
    initialBrief.attachments || initialBrief.links ? true : false
  );
  const [isValidPhone, setIsValidPhone] = useState(true);

  useEffect(() => {
    setTimezone(organization?.contact?.timezone);
  }, [organization]);

  useEffect(() => {
    setIsTimeLoading(true);
    Promise.all([postCallDaysAvailable({ timezone: timezone?.label || 'Asia/Singapore' })])
      .then((values) => {
        setDaysAvailable(values?.[0]?.data?.results);
        setFirstDateAvailable(values?.[0]?.data?.results?.[0]);
        setIsTimeLoading(false);
        setIsMounted(true);
      })
      .catch((error) => {
        console.log(error);
        setIsMounted(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFirstDateAvailable = (date) => {
    //setDaysAvailable(availableDays);
    //const firstDate = availableDays?.[0];
    setDate(date);
    setTimesAvailable(date?.available || []);
    const timeSelected = date?.available?.[0];
    setTimeSelected(
      timeSelected || {
        ...initialBrief?.call,
        start: initialBrief?.call?.startTime,
        end: initialBrief?.call?.endTime,
        startFormatted: initialBrief?.call?.startTimeFormatted,
        endFormatted: initialBrief?.call?.endFormatted,
      }
    );
  };

  const handleTimezoneChange = (event) => {
    const tz = event.target.value;
    setTimezone(tz);
    setIsTimeLoading(true);
    postCallDaysAvailable({ timezone: tz.label })
      .then(({ data }) => {
        setDaysAvailable(data?.results || []);
        setFirstDateAvailable(data?.results?.[0]);
        setIsTimeLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDateChange = (event) => {
    const d = event.target.value;
    setDate(d);
    //setTimesAvailable(d.available);
    setFirstDateAvailable(d);
  };

  const handleScheduleCall = async () => {
    setIsSaving(true);
    try {
      if (isValidPhone) {
        await patchOrganization(initialBrief?.organization?.uuid, {
          contact: { preferred, phoneNumber, phoneCountry, timezone },
        });
        await patchBrief(initialBrief?.uuid, { description });
        await updateBriefCall();

        const { data: briefData } = await getBrief(initialBrief?.uuid);
        setBrief({ brief: briefData, call: briefData.call });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => setIsSaving(false), 250);
    }
  };

  const updateBriefCall = () => {
    const callId = initialBrief?.call?.id;
    const briefUuid = initialBrief?.uuid;
    const { start, end } = timeSelected;
    const data = {
      status: CallStatus.SCHEDULED.id,
      timezone,
      startTime: start,
      endTime: end,
    };
    return patchBriefCall(briefUuid, callId, data);
  };

  const cancelBriefCall = () => {
    const callId = initialBrief?.call?.id;
    const briefUuid = initialBrief?.uuid;
    const data = {
      ...timeSelected,
      status: CallStatus.CANCELLED.id,
    };
    return patchBriefCall(briefUuid, callId, data);
  };

  const onTimeSelect = (data) => setTimeSelected(data);

  const handleReschedule = () => setIsDialogShown(true);

  const handleConfirmReschedule = () => {
    setIsConfirmLoading(true);
    cancelBriefCall()
      .then(({ data }) => {
        setBrief({ ...brief, call: data });
        setIsDialogShown(false);
        setIsConfirmLoading(false);
      })
      .catch((error) => {
        setIsDialogShown(false);
        setIsConfirmLoading(false);
      });
  };

  const handleBack = () => onBack?.(initialBrief.briefType === 1 ? '/start' : '/requirements');

  const handleChatLink = (e) => {
    e.preventDefault();
    intercom.toggle();
  };

  return (
    <div>
      <Dialog
        isOpen={isDialogShown}
        title='Reschedule Your Call'
        onClose={() => setIsDialogShown(false)}>
        <div style={{ marginBottom: 16 }}>
          {`Your call will be cancelled so you may select another time.`}
        </div>
        <div style={{ marginTop: '20px' }}>
          <Button
            type={Button.Type.BLUE}
            onClick={handleConfirmReschedule}
            isLoading={isConfirmLoading}>
            {'Reschedule Call'}
          </Button>
          <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsDialogShown(false)}>
            {'Cancel'}
          </Button>
        </div>
      </Dialog>

      {isMounted && (
        <Fragment>
          <button onClick={handleBack} className={Styles.backLink}>
            {`Back to ${brief.briefType === 1 ? 'Start' : 'Requirements'}`}
          </button>

          {(brief?.call?.status === CallStatus.SCHEDULED.id ||
            brief?.call?.status === CallStatus.RESCHEDULED.id) && (
            <Fragment>
              <ScheduledCall call={brief.call} />
              <ActionBar isWhite>
                <Button name='submit' type={Button.Type.BLUE_OUTLINE} onClick={handleReschedule}>
                  {`Reschedule Call`}
                </Button>
              </ActionBar>
            </Fragment>
          )}

          {(brief?.call?.status === CallStatus.CANCELLED.id ||
            brief?.call?.status === CallStatus.DRAFT.id) && (
            <Fragment>
              <Section
                title={`Call with Hire Digital`}
                subtitle={`A Hire Digital Talent Specialist will get in touch with you.`}
              />
              <div>
                <FormLayout title={`When should we call you?`}>
                  <FormLayout.Row>
                    <FormLayout.MainColumn>
                      <InputGroup>
                        <InputContainer>
                          <Select
                            label={`Date`}
                            getOptionLabel={({ day }) => day}
                            getOptionValue={({ day }) => day}
                            options={daysAvailable}
                            defaultValue={date}
                            onChange={handleDateChange}
                          />
                        </InputContainer>
                        <InputContainer>
                          {organization && (
                            <Select
                              name='timezone'
                              label='Timezone'
                              getOptionLabel={({ name, label, offset }) => {
                                return (name || label) + ' (GMT' + offset + ')';
                              }}
                              getOptionValue={({ id }) => id}
                              options={common?.timezones}
                              defaultValue={timezone}
                              onChange={handleTimezoneChange}
                            />
                          )}
                        </InputContainer>
                      </InputGroup>
                      <div>
                        <p>{`Available Times`}</p>
                        <div>
                          <AvailableTimes
                            data={timesAvailable}
                            isBusy={isTimeLoading}
                            onItemSelect={onTimeSelect}
                            selected={timeSelected}
                          />
                        </div>
                      </div>
                    </FormLayout.MainColumn>
                    <FormLayout.AsideColumn>
                      <p className={Styles.normalSubtitle} style={{ marginBottom: '15px' }}>
                        {`We'll review your brief with you over the phone to ensure that we've gotten all the requirements you need before proceeding to the match.`}
                      </p>
                      <p className={Styles.normalSubtitle}>
                        {`If none of these options work for you, please `}
                        <button onClick={handleChatLink} className={Styles.chatLink}>
                          {`click here to chat with Support.`}
                        </button>
                      </p>
                    </FormLayout.AsideColumn>
                  </FormLayout.Row>
                </FormLayout>
                <FormLayout title={`How can we reach you?`}>
                  <FormLayout.Row>
                    <FormLayout.MainColumn>
                      <InputGroup>
                        <InputContainer>
                          {common?.countries && (
                            <PhoneNumber
                              label={`Phone`}
                              phoneCountry={phoneCountry}
                              phoneNumber={phoneNumber}
                              onPhoneCountryChange={(v) => setPhoneCountry(v)}
                              onPhoneNumberChange={(v) => setPhoneNumber(v)}
                              getCountries={async () => ({ data: common.countries })}
                              onValidate={setIsValidPhone}
                            />
                          )}
                        </InputContainer>
                        <InputContainer>
                          {common?.isOrgRequested && (
                            <Select
                              label={`Preference`}
                              name={`preferred`}
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={CallPreferredPlatform.values}
                              defaultValue={CallPreferredPlatform.getEnum(preferred)}
                              onChange={({ target }) => setPreferred(target.value)}
                            />
                          )}
                        </InputContainer>
                      </InputGroup>
                      <InputGroup>
                        <InputContainer>
                          <Quill
                            legacyCompat
                            name={`description`}
                            label={`Requirements (Optional)`}
                            placeholder={`Describe any project requirements`}
                            onChange={(e) => setDescription(e.target.value)}
                            defaultValue={description}
                          />
                        </InputContainer>
                      </InputGroup>
                      <InputGroup>
                        <InputContainer>
                          {isReferenceShown ? (
                            <References
                              attachments={initialBrief.attachments}
                              links={initialBrief.links}
                              postLinkRequest={(data, config) =>
                                postBriefLink(initialBrief?.uuid, data, config)
                              }
                              postFileRequest={(data, config) =>
                                postBriefFile(initialBrief?.uuid, data, config)
                              }
                              deleteLinkRequest={(id) => deleteBriefLink(initialBrief?.uuid, id)}
                              deleteFileRequest={(id) => deleteBriefFile(initialBrief?.uuid, id)}
                            />
                          ) : (
                            <button
                              onClick={() => setIsReferenceShown(true)}
                              className={Styles.btnLink}
                              style={{ fontSize: '15px' }}>
                              {`+ Add additional files`}
                            </button>
                          )}
                        </InputContainer>
                      </InputGroup>
                    </FormLayout.MainColumn>
                    <FormLayout.AsideColumn noBorder />
                  </FormLayout.Row>
                </FormLayout>
                <ActionBar isWhite>
                  <Button
                    name='submit'
                    type={Button.Type.BLUE}
                    onClick={handleScheduleCall}
                    isLoading={isSaving}>
                    {`Schedule Call`}
                  </Button>
                </ActionBar>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

BriefCall.propTypes = {};

export default BriefCall;
