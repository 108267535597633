import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ServiceRateItem from './ServiceRateItem';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import { getServiceRates } from '@apis/serviceRates';
import { getTeamServiceRates } from '@apis/teams';
import Link from '../Link';
import Styles from './Styles.module.scss';

const Type = {
  TALENT: 1,
  TEAM: 2,
};

class ServiceRateList extends Component {
  static propTypes = {
    resourceUuid: PropTypes.string,
    type: PropTypes.number,
  };

  static defaultProps = {
    type: Type.TALENT,
  };

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      hasInitLoad: false,
    };
  }

  componentDidMount = () => {
    Promise.all([
      this.props.type === Type.TALENT
        ? getServiceRates()
        : getTeamServiceRates(this.props.resourceUuid),
    ]).then(
      ([
        {
          data: { results },
        },
      ]) => {
        this.setState({ list: results, hasInitLoad: true });
      }
    );
  };

  handleNewService = () => {
    this.setState({
      list: [...this.state.list, {}],
    });
  };

  handleUpdateCompletion = (service) => {
    const list = this.state.list.map((v) => (v.uuid === service.uuid ? service : v));
    this.setState({ list });
  };

  handleCreateCompletion = (service) => {
    let list = this.state.list;
    if (service) {
      list = this.state.list.filter((el) => {
        return el && el.uuid;
      });
      list.push(service);
    } else {
      list.pop({});
    }
    this.setState({ list });
  };

  handleDeleteCompletion = (serviceUuId) => {
    this.setState({
      list: this.state.list.filter(({ uuid }) => uuid !== serviceUuId),
    });
  };

  render() {
    return (
      <Card>
        <Card.Header>
          <span>{`Rates`}</span>
          <div className={Styles.action}>
            <Button name='add new' type={Button.Type.BLUE} onClick={this.handleNewService}>
              {`Add New Rate`}
            </Button>
          </div>
        </Card.Header>
        <Card.Note>
          {`To add and view bank accounts and invoices, please continue to the `}
          <Link href={`/settings/payments`} className={Styles.link}>
            {`Payment Settings`}
          </Link>
          {` section`}
        </Card.Note>
        <Fragment>
          {this.state.list.length === 0 && (
            <Card.Item>
              <p className={Styles.empty}>{`No rates have been added.`}</p>
            </Card.Item>
          )}
          <div>
            {this.state.hasInitLoad &&
              !!this.state.list.length &&
              this.state.list.map((service, index) => (
                <ServiceRateItem
                  key={service.uuid || index}
                  service={service}
                  onUpdateCompletion={this.handleUpdateCompletion}
                  onCreateCompletion={this.handleCreateCompletion}
                  onDeleteCompletion={this.handleDeleteCompletion}
                  onCancelCompletion={this.handleCreateCompletion}
                  type={this.props.type}
                  resourceUuid={this.props.resourceUuid}
                />
              ))}
          </div>
        </Fragment>
      </Card>
    );
  }
}

ServiceRateList.Type = Type;

export default ServiceRateList;
