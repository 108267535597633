import Styles from './ActionRow.module.scss';
import Button from '@hiredigital/ui/Button';

const ActionRow = ({ onContinue }) => {
  return (
    <div className={Styles.container}>
      <Button
        name='submit'
        type={Button.Type.BLUE_OUTLINE}
        onClick={() => onContinue?.()}>{`Continue`}</Button>
    </div>
  );
};

export default ActionRow;
