// Shortcut for <MultiLineEllipsis truncateLine={1} />. To use:
// import Ellipsis from '@hiredigital/ui/MultiLineEllipsis/SingleLine';

import MultiLineEllipsis from './';

const Ellipsis = ({ children, ...props }) => (
  <MultiLineEllipsis truncateLine={1} {...props}>
    {children}
  </MultiLineEllipsis>
);

export default Ellipsis;
