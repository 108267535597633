import { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button/Button';

import Item from './Company';
import Invitation from './Invitation';
import { getUserOrganizations, getUserOrgInvitations, postUserOrgLeave } from '@apis/users';
import Styles from './Styles.module.scss';

import { patchUserOrgInvitation } from '@apis/users';
import { InvitationStatus } from '@hiredigital/lib/helpers/enum';

const List = ({ userUuid }) => {
  const [organizations, setOrganizations] = useState([]);
  const [org, setOrg] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [busyInvitation, setBusyInvitation] = useState([]);

  useEffect(() => {
    if (userUuid) {
      loadList();
    }
  }, [userUuid, loadList]);

  const loadList = useCallback(() => {
    Promise.all([getUserOrganizations(userUuid), getUserOrgInvitations(userUuid)])
      .then(
        ([
          {
            data: { results: orgs = [] },
          },
          {
            data: { results: invs = [] },
          },
        ]) => {
          setOrganizations(orgs);
          setInvitations(invs);
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }, [userUuid]);

  const handleAccept = (invitation) => {
    setBusyInvitation([invitation?.id]);
    const status = InvitationStatus.ACCEPTED.id;
    patchUserOrgInvitation(userUuid, invitation?.id, { status })
      .then(() => {
        setBusyInvitation([]);
        loadList();
      })
      .catch((error) => {
        console.error(error);
        setBusyInvitation([]);
      });
  };

  const handleConfirmLeave = () => {
    setIsConfirmLoading(true);
    postUserOrgLeave(userUuid, org?.uuid)
      .then(() => {
        setIsConfirmLoading(false);
        setIsShown(false);
        loadList();
      })
      .catch((error) => {
        setIsShown(false);
        setIsConfirmLoading(false);
        console.error(error);
      });
  };

  const handleSelectOrg = (item) => {
    setOrg(item);
    setIsShown(true);
  };

  return (
    <Card>
      <Fragment>
        {organizations?.map((org, index) => (
          <Item key={index} org={org} onLeave={handleSelectOrg} />
        )) || ''}

        {invitations?.map((invitation, index) => (
          <Invitation
            key={index}
            invitation={invitation}
            onAccept={handleAccept}
            isBusy={busyInvitation.includes(invitation?.id)}
          />
        )) || ''}

        {organizations?.length === 0 && invitations?.length === 0 && (
          <Card.Item>
            <div className={Styles.empty}>{`No client teams added`}</div>
          </Card.Item>
        )}

        <Dialog
          isOpen={isShown}
          title={`Leave ${org?.name || 'Client Team'}`}
          onClose={() => setIsShown(false)}
          description={`Are you sure you wish to leave ${org?.name || 'this client team'}?`}>
          <div style={{ marginTop: '20px' }}>
            <Button
              type={Button.Type.BLUE}
              onClick={handleConfirmLeave}
              isLoading={isConfirmLoading}>
              {'Confirm'}
            </Button>
            <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsShown(false)}>
              {'Cancel'}
            </Button>
          </div>
        </Dialog>
      </Fragment>
    </Card>
  );
};

List.propTypes = {
  orgUuid: PropTypes.string,
};

export default List;
