import Styles from './ExpandIcon.module.scss';
import classNames from 'classnames';

const ExpandIcon = ({ onClick, isOpen, className, ...props }) => {
  return (
    <span
      onClick={() => onClick?.()}
      className={classNames(Styles.icon, isOpen && Styles.open, className)}
    />
  );
};

ExpandIcon.propTypes = {};

export default ExpandIcon;
