import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';

import { validateEmail } from '@helpers/forms';
import { simplifyErrors } from '@hiredigital/lib/helpers/utils';

import Member from './Member';
import Invitation from './Invitation';
import {
  putOrgInvitation,
  deleteOrgInvitation,
  deleteOrgUser,
  postOrgInvitation,
  getOrgUsers,
  getOrgInvitations,
} from '@apis/organizations';
import {
  putTeamInvitation,
  deleteTeamInvitation,
  deleteTeamUser,
  postTeamInvitation,
  getTeamUsers,
  getTeamInvitations,
} from '@apis/teams';
import Styles from './Styles.module.scss';

const Type = {
  ORGANIZATION: 0,
  TEAM: 1,
};

const MemberList = ({ resourceUuid, type = Type.ORGANIZATION }) => {
  const [members, setMembers] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [busyInvitations, setBusyInvitations] = useState([]);

  useEffect(() => {
    const userRequests = [getOrgUsers, getTeamUsers];
    const invRequests = [getOrgInvitations, getTeamInvitations];

    Promise.all([userRequests[type](resourceUuid), invRequests[type](resourceUuid)]).then(
      (values) => {
        setMembers(values?.[0]?.data?.results || []);
        setInvitations(values?.[1]?.data?.results || []);
      }
    );
  }, []);

  const handleUpdateInvitation = (invitation, status) => {
    const requests = [putOrgInvitation, putTeamInvitation];
    setBusyInvitations([invitation.id]);
    requests[type](resourceUuid, invitation.id, { status: status.id })
      .then(() => {
        setBusyInvitations([]);
        const items = invitations.map((item) => {
          if (item.id === ((invitation && invitation.id) || '')) {
            item.status = status.id;
          }
          return item;
        });
        setInvitations(items);
      })
      .catch((error) => {
        console.error(error);
        setBusyInvitations([]);
      });
  };

  const handleDeleteInvitation = (invitation, index) => {
    const requests = [deleteOrgInvitation, deleteTeamInvitation];
    requests[type](resourceUuid, invitation.id).then((response) => {
      let items = [...invitations];
      items.splice(index, 1);
      setInvitations(items);
    });
  };

  const validateFields = () => {
    let err = {};
    setErrors({});
    const isEmailValid = validateEmail(email);

    if (!isEmailValid) {
      err = { ...err, email: 'Invalid email address.' };
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return false;
    }

    return true;
  };

  const sendInvitation = () => {
    // setSubmitted(true);

    // if (email) {
    //   setEmailValid(validateEmail(email));
    // }

    if (validateFields()) {
      setLoading(true);

      const data = {
        email,
        name,
        role,
      };

      const requests = [postOrgInvitation, postTeamInvitation];
      requests[type](resourceUuid, data)
        .then(({ data }) => {
          setInvitations([...invitations, data]);
          setEmail('');
          setName('');
          setRole('');
          setLoading(false);
          setShowInviteForm(false);
          // setShowMemberForm(false);
          // setSubmitted(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors(simplifyErrors(error?.response?.data));
        });
    }
  };

  const handleToggleInvitationForm = () => {
    setShowInviteForm(!showInviteForm);
    // setSubmitted(false);
  };

  const deleteMember = (member) => {
    setLoading(true);
    const requests = [deleteOrgUser, deleteTeamUser];
    requests[type](resourceUuid, member.id)
      .then((response) => {
        setMembers(members.filter((v) => v.id !== member.id));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Card>
      <Card.Header>
        <span>
          {type === Type.TEAM ? `Team Members & ` : 'Company Members & '}
          {'Invitations'}
        </span>
        <div className={Styles.action}>
          {!showInviteForm && (
            <Button name='add new' type={Button.Type.BLUE} onClick={handleToggleInvitationForm}>
              {`Invite New Member`}
            </Button>
          )}
        </div>
      </Card.Header>
      <Fragment>
        {showInviteForm && (
          <Card.Item>
            <div>
              <InputGroup>
                <InputContainer>
                  <TextInput
                    name='email'
                    label='Email'
                    error={errors?.email}
                    defaultValue={email}
                    onChange={(e) => {
                      setErrors({});
                      setEmail(e.target.value);
                    }}
                  />
                </InputContainer>
                <InputContainer>
                  <TextInput
                    className={'highlight-block'}
                    name='name'
                    label='Name'
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputContainer>
                {type === Type.TEAM && (
                  <InputContainer>
                    <TextInput
                      name='role'
                      label='Role'
                      defaultValue={role}
                      onChange={(e) => setRole(e.target.value)}
                    />
                  </InputContainer>
                )}
              </InputGroup>

              <div className={Styles.row}>
                <div className={Styles.action}>
                  <Button
                    name='cancel'
                    type={Button.Type.WHITE_BLUE_OUTLINE}
                    onClick={handleToggleInvitationForm}>
                    {`Cancel`}
                  </Button>
                  <Button
                    name='submit'
                    type={Button.Type.BLUE}
                    onClick={sendInvitation}
                    isLoading={loading}>
                    {`Send Invitation`}
                  </Button>
                </div>
              </div>
            </div>
          </Card.Item>
        )}

        {members?.map((member, index) => (
          <Member
            member={member}
            type={type}
            deleteMember={deleteMember}
            key={`${member.uuid}-${index}`}
            resourceUuid={resourceUuid}
          />
        )) || ''}
        {invitations?.map((invitation, index) => (
          <Invitation
            className={Styles.invitation}
            isBusy={busyInvitations.includes(invitation.id)}
            type={type}
            invitation={invitation}
            key={invitation.id}
            resourceUuid={resourceUuid}
            onUpdateInvitation={handleUpdateInvitation}
            onDeleteInvitation={(invitation) => handleDeleteInvitation(invitation, index)}
          />
        )) || ''}

        {members?.length === 0 && invitations?.length === 0 && (
          <Card.Item>
            <div className={Styles.empty}>{`No members added`}</div>
          </Card.Item>
        )}
      </Fragment>
    </Card>
  );
};

MemberList.propTypes = {
  resourceUuid: PropTypes.string,
};

MemberList.Type = Type;

export default MemberList;
