import { Tab as BaseTab } from '@headlessui/react';
import classNames from 'classnames';
import Styles from './Tab.module.scss';

export const Group = ({ isEnterprise, buttonStyle, className, children, ...props }) => {
  return (
    <BaseTab.Group
      as='div'
      {...props}
      className={classNames(
        isEnterprise && Styles.isEnterprise,
        buttonStyle && Styles.buttonStyle,
        className
      )}>
      {children}
    </BaseTab.Group>
  );
};

export const Tab = ({ className, selectedClassName, children, ...props }) => {
  return (
    <BaseTab
      className={({ selected }) =>
        classNames(Styles.tab, className, selected && [Styles.selectedTab, selectedClassName])
      }
      {...props}>
      {children}
    </BaseTab>
  );
};

export const List = ({ className, children, ...props }) => {
  return (
    <BaseTab.List className={classNames(Styles.list, className)} {...props}>
      {children}
    </BaseTab.List>
  );
};

export const Panel = ({ className, children, ...props }) => {
  return (
    <BaseTab.Panel className={classNames(Styles.panel, className)} {...props}>
      {children}
    </BaseTab.Panel>
  );
};

Tab.Group = Group;
Tab.List = List;
Tab.Panels = BaseTab.Panels;
Tab.Panel = Panel;

export default Tab;
