import Styles from './BriefDetail.module.scss';

const Item = ({ title, action, content, children }) => {
  return (
    <div className={Styles.item}>
      {title && (
        <div className={Styles.title}>
          {title} {action}
        </div>
      )}
      <div className={Styles.content}>{children || content}</div>
    </div>
  );
};

export default Item;
