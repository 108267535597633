import { useState, useEffect } from 'react';
import Item from './BriefItem';

import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';

import DeadlineChecklist from '@hiredigital/ui/DeadlineChecklist';
import {
  patchProject,
  getProjectFiles,
  postProjectContent,
  deleteProjectContent,
} from '@apis/projects';
import ProjectTeam from '../../ProjectTeam/List';
import convert from 'htmr';
import Quill from '@hiredigital/ui/Quill/Editor';
import References from '@hiredigital/ui/Form/References';
import Styles from './BriefDetail.module.scss';
import ClientGuidelines from './ClientGuidelines';
import { useUser } from '@context/user';

import { authRequest } from '@apis/utils';
import { ProjectDeadlineApiManager } from '@hiredigital/lib/apis/class/deadlines';

const Detail = ({ project, brand }) => {
  const user = useUser();

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState(project);
  const [attachments, setAttachments] = useState([]);
  const [links, setLinks] = useState([]);
  const [title, setTitle] = useState(null);
  const [requirements, setRequirements] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [isReferenceReady, setIsReferenceReady] = useState(false);

  const loadProjectContents = (uuid, page = 1, reset = true) => {
    const params = { isReference: true, page };
    getProjectFiles(uuid, { params })
      .then(({ data }) => {
        const itemLinks = data?.results.filter((v) => !!v?.url);
        const itemAttachments = data?.results.filter((v) => !!v?.attachment);

        setLinks(reset ? itemLinks : [...links, ...itemLinks]);
        setAttachments(reset ? itemAttachments : [...attachments, ...itemAttachments]);

        setNextPage(data?.meta?.nextPage);
        setIsReferenceReady(true);
      })
      .catch((error) => {
        setNextPage(null);
        // setIsLoadingFiles(false);
        setIsReferenceReady(true);
        console.warn('Error while loading project content!');
      });
  };

  useEffect(() => {
    setIsReferenceReady(false);
    loadProjectContents(project?.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveBrief = () => {
    setIsSaving(true);
    patchProject(project?.uuid, { title, requirements })
      .then((response) => {
        setData(response?.data);
        setIsEditing(false);
        setIsSaving(false);
      })
      .catch((error) => {
        console.warn(error);
        setIsEditing(false);
        setIsSaving(false);
      });
  };

  const handleEditBrief = () => {
    setIsEditing(true);
    setTitle(data?.title);
    setRequirements(data?.requirements);
  };

  const handleLoreMore = () => loadProjectContents(project?.uuid, nextPage, false);

  return (
    <div className={Styles.container}>
      <Item
        title={`Title`}
        action={
          (user?.isClient || (user?.isTalent && brand?.isProjectManager)) && (
            <Button onClick={handleEditBrief} className={Styles.btnSmall} type={Button.Type.BLUE}>
              {`Edit Brief`}
            </Button>
          )
        }>
        {isEditing ? (
          <div className={Styles.editTitleForm}>
            <TextInput
              label={`Title`}
              type={`text`}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        ) : (
          <div className={Styles.editableContent}>{data?.title}</div>
        )}
      </Item>
      <Item title={`Requirements`}>
        {isEditing ? (
          <Quill
            legacyCompat
            name='requirements'
            label='Requirements'
            placeholder='Requirements'
            defaultValue={requirements}
            onChange={(e) => setRequirements(e.target.value)}
          />
        ) : data?.requirements ? (
          convert(data?.requirements)
        ) : (
          'No requirements'
        )}
        <div className={Styles.fileContainer}>
          {isReferenceReady && (
            <References
              showActions={isEditing}
              attachments={attachments}
              links={links}
              postLinkRequest={(data, config) => {
                const payload = { ...data, type: 1, isReference: true };
                return postProjectContent(project?.uuid, payload, config);
              }}
              postFileRequest={(data, config) => {
                data.append('type', 2);
                data.append('isReference', true);
                return postProjectContent(project?.uuid, data, config);
              }}
              deleteLinkRequest={(id) => deleteProjectContent(project?.uuid, id)}
              deleteFileRequest={(id) => deleteProjectContent(project?.uuid, id)}
              onLoadMore={handleLoreMore}
              hasMore={!!nextPage}
            />
          )}
        </div>

        {isEditing ? (
          <div className={Styles.briefAction}>
            <Button
              onClick={() => setIsEditing(false)}
              className={Styles.btnSmall}
              type={Button.Type.GRAY}>
              {`Cancel`}
            </Button>
            <Button
              onClick={handleSaveBrief}
              className={Styles.btnSmall}
              isLoading={isSaving}
              type={Button.Type.BLUE}>
              {`Save`}
            </Button>
          </div>
        ) : (
          ''
        )}
      </Item>

      <div className={Styles.separator} />
      <Item>
        <DeadlineChecklist
          projectUuid={data?.uuid}
          apiManager={new ProjectDeadlineApiManager(authRequest)}
        />
      </Item>
      <div className={Styles.separator} />
      <Item title={`Project Team`}>
        <ProjectTeam
          project={data}
          isEditable={user?.isClient || (user?.isTalent && brand?.isProjectManager)}
        />
      </Item>
      {brand && (
        <Item title={`Client Guidelines`}>
          <ClientGuidelines brand={brand} />
        </Item>
      )}
    </div>
  );
};

export default Detail;
