import { stripIndent } from 'common-tags';

const SETTINGS = {
  app_id: `${process.env.INTERCOM_APP_ID}`,
  custom_launcher_selector: '#intercom-chat',
  alignment: 'left',
  horizontal_padding: 20,
  vertical_padding: 20,
  hide_default_launcher: true,
};

export const sendEvent = (e = 'update', settings = SETTINGS) => {
  window && window.Intercom && window.Intercom(e);
  return e;
};

export const boot = () => {
  return sendEvent('boot');
};

export const show = () => {
  showLauncher();
  return sendEvent('show');
};

export const hide = () => {
  hideLauncher();
  return sendEvent('hide');
};

export const autoHide = () => {
  // will auto hide launcher once messenger is close
  window.Intercom('onHide', hideLauncher);
};

export const toggle = () => (isVisible() ? hide() : show());

const isVisible = () => {
  return !!document.querySelector('body.show-intercom');
};

const showLauncher = () => {
  const body = document.querySelector('body');
  if (body) {
    body.classList.add('show-intercom');
  }
};

const hideLauncher = () => {
  const body = document.querySelector('body');
  if (body) {
    body.classList.remove('show-intercom');
  }
};

export const script = () => {
  return (
    <script
      key={`intercom-script`}
      dangerouslySetInnerHTML={{
        __html: stripIndent`
            window.intercomSettings = ${JSON.stringify(SETTINGS)};
          (function(){
            var w=window;var ic=w.Intercom;
            if(typeof ic==="function"){
              ic('reattach_activator');
              ic('update',w.intercomSettings);}
            else{
              var d=document;var i=function(){i.c(arguments);};
              i.q=[];i.c=function(args){i.q.push(args);};
              w.Intercom=i;
              var l=function(){
                setTimeout(function() {
                var s=d.createElement('script');s.type='text/javascript';
                s.async=true;s.src='https://widget.intercom.io/widget/${
                  process.env.INTERCOM_APP_ID
                }';
                var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);
              }, 2000);
              };
              if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            `,
      }}
    />
  );
};
