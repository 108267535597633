import Styles from './BasicForm.module.scss';
import ProfileImage from '@hiredigital/ui/ProfileImage';
import TextInput from '@hiredigital/ui/Input/SimpleTextInput';
import Select from '@hiredigital/ui/Input/Select';
import TextArea from '@hiredigital/ui/Input/TextArea';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import ActionRow from './ActionRow';

const BasicForm = ({ style, value, ...props }) => {
  return (
    <div className={Styles.container}>
      <ProfileImage />
      <div className={Styles.fields}>
        <InputGroup>
          <InputContainer>
            <TextInput label={`Name`} />
          </InputContainer>
          <InputContainer>
            <TextInput label={`Email Id`} />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <Select label={`Location`} />
          </InputContainer>
          <InputContainer>
            <Select label={`Primary Language`} />
          </InputContainer>
        </InputGroup>
        <InputContainer>
          <TextArea label={`Bio`} />
        </InputContainer>
        <p className={Styles.charCount}>{`0 / 500 characters`}</p>
        <InputGroup>
          <InputContainer>
            <Select label={`Field`} />
          </InputContainer>
          <InputContainer>
            <Select label={`Looking for`} />
          </InputContainer>
        </InputGroup>
        <InputContainer>
          <TextInput label={`Portfolio Links`} />
        </InputContainer>
        <ActionRow />
      </div>
    </div>
  );
};

export default BasicForm;
