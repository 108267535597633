import { memo } from 'react';
import Styles from './GuidelineItem.module.scss';

const GuidelineItem = ({ title, content, children }) => {
  return (
    <div className={Styles.item}>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.content}>{children || content}</div>
    </div>
  );
};

export default memo(GuidelineItem);
