import { memo } from 'react';

import Styles from './Styles.module.scss';

// eslint-disable-next-line react/display-name
const Upload = memo(({ message, ...props }) => {
  const { metaObject: attachment } = message;

  const Preview = ({ attachment }) => {
    switch (attachment.attachmentName.split('.').pop()) {
      case 'png':
      case 'jpeg':
      case 'jpg':
      case 'webp':
      case 'avif':
      case 'svg':
      case 'pdf':
        return (
          <div className={Styles.imageContainer} data-type='img'>
            <img className={Styles.image} src={attachment.preview} />
          </div>
        );
        break;
      case 'gif':
        return (
          <div className={Styles.imageContainer} data-type='gif'>
            <img className={Styles.image} src={attachment.attachment} />
          </div>
        );
        break;
      default:
        return <></>;
    }
  };

  const MemoPreview = memo(Preview);

  return (
    <div className={Styles.content} {...props}>
      <a
        className={Styles.attachmentLink}
        href={attachment.attachment}
        alt={attachment.attachmentName}
        target='_blank'
        rel='noopener noreferrer'>
        {attachment.attachmentName}
        <MemoPreview attachment={attachment} />
      </a>
    </div>
  );
});

export default Upload;
