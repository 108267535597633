import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import ShowAsIframe from '../ShowAsIframe/ShowAsIframe';
import ImageViewer from '../ImageViewer/ImageViewer';
import { checkImageUrl } from '@hiredigital/lib/helpers/utils';
import Styles from './FileViewer.module.scss';

const NewFileViewer = ({ title, url, className, force }) => {
  const imageViewerModalRef = useRef();
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    const renderUrl = (url) => {
      if (force) {
        setIsImage(true);
      } else {
        const isImage = checkImageUrl(url);
        setIsImage(isImage);
      }
    };
    renderUrl(url);
  }, [url, force]);

  const handleOpenImageViewer = () => {
    imageViewerModalRef.current.show(url, title);
  };

  return (
    <div className={className}>
      <ImageViewer ref={imageViewerModalRef} />

      {isImage && url && (
        <div className={Styles.imageContainer}>
          <img src={url} className={Styles.image} onClick={handleOpenImageViewer} alt={url} />
        </div>
      )}

      {!isImage && url && <ShowAsIframe className={Styles.docContainer} url={url} />}
    </div>
  );
};

NewFileViewer.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
  force: PropTypes.bool,
};

export default NewFileViewer;
