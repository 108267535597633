import { useEffect, useState, useId } from 'react';
import classNames from 'classnames';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import _startCase from 'lodash/startCase';
import Toggle from 'react-toggle';
import axios from 'axios';
import formatDate from 'date-fns/format';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Loader from '@hiredigital/ui/Loader';
import Tab from '@hiredigital/ui/Tab/Tab';

import CurrencyDropdown from '@hiredigital/ui/CurrencyDropdown';
import { PaymentType, BankAccountCurrency } from '@hiredigital/lib/helpers/enum';
import { PayPalDataItems, SimpleRowDetailMap } from './constants';
import Styles from './BankAccount.module.scss';
import FlagStyles from '../Flags.module.scss';
import 'react-toggle/style.css'; // for ES6 modules

import { getRequirements, postRequirements } from '@hiredigital/lib/apis/wise';

const PaymentMethod = ({
  apiManager: { postBank, putBank, deleteBank },
  bankAccount,
  loadBankAccountList,
  showWiseId,
  simpleRow,
}) => {
  const uniqueId = useId();

  const getDetailsWithoutNulls = (details) => {
    if (!details) return details;

    const detailKeys = Object.keys(details || {});
    if (!detailKeys.length) return details;

    // Avoid directly altering details object
    const final = { ...details };

    // Remove all null values so the generated Wise ID won't change for same valid details
    detailKeys.forEach((key) => {
      if (final[key] === null) delete final[key];
    });
    return final;
  };

  const [acct, setAcct] = useState({
    id: bankAccount.id,
    isDefault: bankAccount.isDefault,
    paypalEmail: bankAccount.paypalEmail,
    wiseId: bankAccount.transferwiseId,
    requirements: {
      accountHolderName: bankAccount.accountHolderName,
      currency: bankAccount.currency,
      country: bankAccount.country,
      type: bankAccount.wiseType,
      details: getDetailsWithoutNulls(bankAccount.details),
    },
  });
  const [paymentType, setPaymentType] = useState(bankAccount.type);
  const [lastFour, setLastFour] = useState();
  const [simpleRowDetail, setSimpleRowDetail] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabledEdit, setDisabledEdit] = useState(true);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingReqs, setLoadingReqs] = useState(false);
  const [requirements, setRequirements] = useState();
  const [acctType, setAcctType] = useState();
  const [refreshKey, setRefreshKey] = useState();

  useEffect(() => {
    if (!bankAccount.id) setIsEdit(true);

    if (bankAccount.type === PaymentType.PAYPAL.id) {
      setAcct((acct) => {
        // In case the user changes to Bank Account, these values
        // shouldn't be present as empty strings in the submit request.
        delete acct.requirements.currency;
        delete acct.requirements.country;
        return acct;
      });
      setDisabledEdit(false);
      return;
    }

    const details = bankAccount.details;
    if (!details) return;

    setLastFour(
      (
        details.accountNumber ||
        details.iban ||
        details.clabe ||
        details.bban ||
        details.bankgiroNumber ||
        details.interacAccount ||
        ''
      )?.slice(-4)
    );

    // Get requirements on first load (edit)
    setRefreshKey(`componentDidMount-${Math.random()}`);
  }, []);

  const setError = (key, error) => {
    setErrors((errors) => {
      if (error === undefined) delete errors[key];
      else errors[key] = error;
      // Re-construct object to force useEffect to run
      return { ...errors };
    });
  };

  useEffect(() => {
    setDisabledSubmit(errors ? !!Object.keys(errors).length : false);
  }, [errors]);

  const handleChange = (event, path) => {
    let { name, value, type, checked } = event.target;

    if (type === 'radio' && !isNaN(parseInt(value, 10))) {
      value = parseInt(value, 10);
    }

    value = type === 'checkbox' ? checked : path ? _get(value, path) : value;

    setAcct((acct) => {
      value === undefined ? _unset(acct, name) : _set(acct, name, value);
      return acct;
    });
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    setLoadingCancel(true);
    loadBankAccountList?.(() => {
      setIsEdit(false);
      setLoadingCancel(false);
    });
  };

  const handleSubmit = () => {
    if (paymentType === PaymentType.TRANSFERWISE.id && !acct?.requirements) return;
    setLoadingSubmit(true);

    const data =
      paymentType === PaymentType.PAYPAL.id
        ? {
            paypalEmail: acct.paypalEmail,
            isDefault: !!acct.isDefault || false,
            type: paymentType,
          }
        : {
            ...acct.requirements,
            isDefault: !!acct.isDefault || false,
            // Replace type and wiseType
            type: paymentType,
            wiseType: acct.requirements?.type,
          };

    (acct.id ? putBank(acct.id, data) : postBank(data))
      .then(() =>
        loadBankAccountList?.(() => {
          setIsEdit(false);
          setLoadingSubmit(false);
        })
      )
      .catch(() => setLoadingSubmit(false));
  };

  const handleDelete = () => {
    if (!acct?.id) return;
    setLoadingDelete(true);
    deleteBank(acct.id)
      .then(() =>
        loadBankAccountList?.(() => {
          setIsEdit(false);
          setLoadingDelete(false);
        })
      )
      .catch((err) => {
        console.error(err);
        setLoadingDelete(false);
      });
  };

  const handleCurrencyUpdate = (e) => {
    const currency = e.target.value?.currencyCode;
    if (!currency) return;
    setLoadingReqs(true);
    handleChange(e, 'currencyCode');
    setAcctType();
    clearDetailsAndErrors();
    getRequirements(currency)
      .then(({ data }) => {
        // To include email option, remove the filter:
        const reqs = data?.filter?.((v) => v?.type !== 'email');
        setRequirements(reqs); // always set it
        // Auto-select if there's only one account type
        setNewAcctType(reqs?.length === 1 ? reqs[0] : null);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadingReqs(false));
  };

  useEffect(() => {
    // prevent empty run on initial load
    if (!refreshKey) return;

    // Refresh Requirements On Change
    // - we cannot use a direct function as state changes will not reflect immediately
    // - a useEffect hook with a random key ensures all state changes complete first
    const currency = acct?.requirements?.currency;
    const data = { details: acct?.requirements?.details };
    if (!currency || !data) return;
    postRequirements(currency, data)
      .then(({ data }) => {
        // To include email option, remove the filter:
        const reqs = data?.filter?.((v) => v?.type !== 'email');

        // During initial load of component, this fetches and populates fields from Wise API
        componentDidMount: if (refreshKey.startsWith('componentDidMount-')) {
          setRequirements(reqs);

          // Fetch the account type in the props
          const newAcctType = reqs?.find((v) => v.type === bankAccount.wiseType);
          setAcctType(newAcctType);

          // All fields and values allowed are loaded. We can enable editing.
          setDisabledEdit(false);

          // Find and set the simple row detail
          const details = bankAccount.details;
          if (!details) break componentDidMount;

          let found = false;
          let simpleRowDetail;

          for (const item of SimpleRowDetailMap) {
            // Loop through every field in every group
            newAcctType?.fields?.forEach?.((field) => {
              field?.group?.forEach?.((group) => {
                if (found || group?.key !== item.key) return;
                found = true;

                const foundValue = group.valuesAllowed?.find?.(
                  (value) => value?.key === details[item.key]
                );

                if (foundValue) simpleRowDetail = foundValue.name;
                else simpleRowDetail = `${group.name}: ${details[item.key]}`;
              });
            });

            // If no field was found, but the value exists in details
            if (!simpleRowDetail && details[item.key]) {
              found = true;
              simpleRowDetail = `${item.prefix}: ${details[item.key]}`;
            }

            if (found) break;
          }

          // If none of the items in SimpleRowDetailMap exists in details
          if (!simpleRowDetail) {
            const country = bankAccount.country;
            const currency = bankAccount.currency;
            if (!currency) break componentDidMount;
            simpleRowDetail =
              (currency === 'XOF' && country && `Country: ${country}`) ||
              (currency && BankAccountCurrency.getEnum(currency)?.name) ||
              '';
          }

          setSimpleRowDetail(simpleRowDetail);
        } else {
          if (reqs?.length) setRequirements(reqs);

          // Update the selected account type with new fields
          setNewAcctType(reqs?.length ? reqs.find((v) => v?.type === acctType?.type) : null);
        }
      })
      .catch((error) => console.error(error));
  }, [refreshKey]);

  const handleTypeUpdate = (e) => {
    setAcctType(e.target.value);
    handleChange(e, 'type');
  };

  const setNewAcctType = (newAcctType) => {
    handleTypeUpdate({
      target: {
        name: 'requirements.type',
        value: newAcctType,
      },
    });
  };

  const clearDetailsAndErrors = () => {
    setErrors({});
    setAcct((acct) => {
      _unset(acct, 'requirements.details');
      _unset(acct, 'requirements.accountHolderName');
      return acct;
    });
  };

  if (!isEdit)
    return (
      <Card.Item>
        <div className={Styles.listPreview}>
          <div
            className={classNames(Styles.row, simpleRow ? Styles.simpleRow : Styles.fullDetailRow)}>
            {simpleRow ? (
              <>
                <div className={Styles.paymentType}>
                  {acct.id && paymentType === PaymentType.TRANSFERWISE.id && (
                    <div>
                      <p className={Styles.title}>
                        {`Bank Account${lastFour ? ` ending in ${lastFour}` : ''} `}
                        {acct.requirements.currency && (
                          <span
                            title={BankAccountCurrency.getEnum(acct.requirements.currency).name}
                            className={classNames(Styles.currencyBadge, FlagStyles.f16)}>
                            <span
                              className={classNames(
                                FlagStyles.flag,
                                FlagStyles?.[
                                  BankAccountCurrency.getEnum(
                                    acct.requirements.currency
                                  ).country?.toLowerCase()
                                ]
                              )}
                            />{' '}
                            {acct.requirements.currency}
                          </span>
                        )}
                      </p>
                      <span className={Styles.default}>{simpleRowDetail}</span>
                    </div>
                  )}
                  {acct.id && paymentType === PaymentType.PAYPAL.id && acct.paypalEmail && (
                    <div>
                      <p className={Styles.title}>{`PayPal Account`}</p>
                      <span className={Styles.default}>{acct.paypalEmail}</span>
                    </div>
                  )}
                </div>
                {acct.isDefault && (
                  <div className={Styles.details}>
                    <span className={Styles.default}>{`Default Account`}</span>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={Styles.paymentType}>
                  {acct.id && paymentType === PaymentType.TRANSFERWISE.id && (
                    <div>
                      <p className={Styles.title}>{`Bank Account`}</p>
                      {acct.isDefault && (
                        <span className={Styles.default}>{`Default Account`}</span>
                      )}
                    </div>
                  )}
                  {acct.id && paymentType === PaymentType.PAYPAL.id && acct.paypalEmail && (
                    <div>
                      <p className={Styles.title}>{`PayPal Account`}</p>
                      {acct.isDefault && (
                        <span className={Styles.default}>{`Default Account`}</span>
                      )}
                    </div>
                  )}
                </div>
                <div className={Styles.details}>
                  {acct.id &&
                    paymentType === PaymentType.TRANSFERWISE.id &&
                    Object.entries(acct.requirements || {}).map(([label, value], index) => (
                      <DetailField key={index} label={_startCase(label)} value={value} />
                    ))}
                  {acct.id &&
                    paymentType === PaymentType.PAYPAL.id &&
                    acct.paypalEmail &&
                    PayPalDataItems.map(
                      (item, index) =>
                        item.visible(acct) && (
                          <DetailField key={index} label={item.label} value={item.field(acct)} />
                        )
                    )}
                  {showWiseId && <DetailField label='Wise ID' value={acct.wiseId || 'None'} />}
                </div>
              </>
            )}

            <div className={Styles.action}>
              <Button
                data-test-id='edit-button-bank-item'
                name='Edit'
                type={Button.Type.WHITE}
                onClick={handleEdit}
                disabled={disabledEdit}>
                {`Edit`}
              </Button>
            </div>
          </div>
        </div>
      </Card.Item>
    );

  return (
    <>
      <Card.Item>
        <div className={Styles.switch}>
          <div className={classNames(Styles.row, Styles.center)}>
            <p className={Styles.switchTitle}>{`Select method:`}</p>
            <Tab.Group
              buttonStyle
              manual
              selectedIndex={PaymentType.values.findIndex((type) => paymentType === type.id)}
              // non-standard onChange behavior
              onChange={(index) => setPaymentType(PaymentType.values[index]?.id)}>
              <Tab.List>
                {PaymentType.values.map((type, index) => (
                  <Tab key={index}>{type.label}</Tab>
                ))}
              </Tab.List>
            </Tab.Group>
          </div>
        </div>
      </Card.Item>
      <Card.Note className={Styles.cardNote}>
        {paymentType === PaymentType.TRANSFERWISE.id ? (
          <>
            Payments to international bank accounts will be made via{' '}
            <a target='_blank' content='nofollow noopener noreferrer' href='https://wise.com'>
              Wise
            </a>
            . You will receive the stated rate on the platform less conversion and transaction fees.
            See{' '}
            <a
              target='_blank'
              content='nofollow noopener noreferrer'
              href='https://wise.com/pricing/'>
              Wise fees
            </a>{' '}
            for more information on conversion rates and fee structure. Payments may take up to 5
            additional business days for your funds to be reflected in your bank account.
          </>
        ) : (
          <>
            Payments will be made via{' '}
            <a href='https://paypal.com/' target='_blank' content='nofollow noopener noreferrer'>
              PayPal
            </a>
            . You will receive the stated rate on the platform less conversion and transaction fees.
            See{' '}
            <a
              href='https://www.paypal.com/sg/webapps/mpp/ua/useragreement-full#transaction-fees'
              target='_blank'
              content='nofollow noopener noreferrer'>
              PayPal fees
            </a>{' '}
            for more information on conversion rates and fee structure. Payments may take up to 5
            additional business days for your funds to be reflected in your bank account.
          </>
        )}
      </Card.Note>
      <Card.Item>
        <div className={Styles.form} data-test-id='currency-dropdown'>
          {paymentType === PaymentType.TRANSFERWISE.id && (
            <>
              <InputGroup className={Styles.multiRow}>
                <InputContainer className={Styles.bankFields}>
                  <CurrencyDropdown
                    name='requirements.currency'
                    type={CurrencyDropdown.Type.WISE_API}
                    defaultValue={acct?.requirements?.currency}
                    onChange={handleCurrencyUpdate}
                  />
                </InputContainer>
                {loadingReqs ? (
                  <InputContainer className={Styles.bankFields}>
                    <Loader size={Loader.Size.SMALL} type={Loader.Type.FULL} />
                  </InputContainer>
                ) : (
                  requirements?.length && (
                    <InputContainer className={Styles.bankFields}>
                      <Select
                        className={Styles.select}
                        value={acctType || ''} // empty string makes sure it's controlled
                        name='requirements.type'
                        label='Pay to'
                        error={errors.type}
                        getOptionLabel={({ title }) => title}
                        getOptionValue={({ type }) => type}
                        options={requirements}
                        onChange={(e) => {
                          clearDetailsAndErrors();
                          handleTypeUpdate(e);
                        }}
                      />
                    </InputContainer>
                  )
                )}
                {acctType && (
                  <>
                    {acctType.usageInfo && (
                      <Card.Note className={Styles.usageInfo}>{acctType.usageInfo}</Card.Note>
                    )}
                    {acctType.fields?.length &&
                      acctType.fields.map(
                        (field, fIndex) =>
                          field?.group?.length &&
                          field.group.map((group, gIndex) => (
                            <InputContainer
                              // This ensures the component is always refreshed.
                              key={`${_get(acct, 'requirements.currency')}-${_get(
                                acct,
                                'requirements.type'
                              )}-f${fIndex}-g${gIndex}`}
                              className={Styles.bankFields}>
                              <WiseField
                                field={group}
                                onChange={handleChange /* (event, path) */}
                                setRefreshKey={setRefreshKey}
                                getDetailValue={(path) => _get(acct, path)}
                                setError={setError}
                              />
                            </InputContainer>
                          ))
                      )}
                  </>
                )}
              </InputGroup>
            </>
          )}
          {paymentType === PaymentType.PAYPAL.id && (
            <InputGroup>
              <InputContainer>
                <TextInput
                  name='paypalEmail'
                  label='PayPal E-mail'
                  error={errors.paypalEmail}
                  defaultValue={acct.paypalEmail}
                  onChange={handleChange}
                />
              </InputContainer>
            </InputGroup>
          )}
        </div>
      </Card.Item>
      <Card.Footer>
        {acct.id && (
          <Button
            name='delete'
            type={Button.Type.WHITE}
            onClick={handleDelete}
            isLoading={loadingDelete}>
            {`Delete`}
          </Button>
        )}
        <div className={Styles.action}>
          <div
            style={{
              padding: '3px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: '15px',
            }}>
            <Toggle
              id={`is-default-${uniqueId}`}
              name='isDefault'
              defaultChecked={acct.isDefault}
              onChange={handleChange}
              disabled={bankAccount.isDefault}
            />
            <label
              htmlFor={`is-default-${uniqueId}`}
              className={Styles.toggleLabel}>{`Set as Default Account`}</label>
          </div>

          <Button
            name='cancel'
            type={Button.Type.WHITE_BLUE_OUTLINE}
            onClick={handleCancel}
            isLoading={loadingCancel}>
            {`Cancel`}
          </Button>

          <Button
            name='submit'
            type={Button.Type.BLUE}
            onClick={handleSubmit}
            isLoading={loadingSubmit}
            disabled={disabledSubmit}>
            {`Save`}
          </Button>
        </div>
      </Card.Footer>
    </>
  );
};

const DetailField = ({ label, value }) => {
  if (!value) return null;

  // Check for JSON objects with key-value pairs
  if (value.constructor == Object)
    return Object.entries(value).map(([childLabel, childValue], index) => (
      <DetailField key={index} label={_startCase(childLabel)} value={childValue} />
    ));

  // else
  return (
    <div className={Styles.field}>
      <div className={Styles.label}>{`${label}`}</div>
      <div className={Styles.content}>{`${value}`}</div>
    </div>
  );
};

const WiseField = ({
  field: {
    key: fieldKey,
    name,
    type,
    refreshRequirementsOnChange,
    required,
    displayFormat,
    example, // TODO: Show as a hint
    minLength,
    maxLength,
    validationRegexp,
    validationAsync,
    valuesAllowed,
  },
  onChange: handleChangeInParent,
  setRefreshKey,
  getDetailValue,
  setError: setErrorInParent,
}) => {
  const key = ['BIC', 'IBAN'].includes(fieldKey) ? fieldKey.toLowerCase() : fieldKey;
  const path = `requirements.${key === 'accountHolderName' ? key : `details.${key}`}`;
  const multiChoice = ['radio', 'select'].includes(type);
  const dateFormat = 'yyyy-MM-dd';
  const label = type === 'date' ? `${name} [${dateFormat.toUpperCase()}]` : name;
  const invalidMessage = `Invalid ${label}`;
  const requiredMessage = required ? `${label} is required` : undefined;

  const [error, setErrorState] = useState();
  const setError = (error) => {
    // simple string errors only. functions are not supported.
    setErrorInParent(key, error);
    setErrorState(error);
  };

  useEffect(() => {
    setError();
  }, [name]);

  useEffect(() => {
    if (!multiChoice) return;
    // remove non-existent value from the detail store
    const currentValue = valuesAllowed?.find?.((v) => v?.key === getDetailValue?.(path));
    if (!currentValue) handleChangeInParent?.({ target: { name: path, value: undefined } });
  }, [valuesAllowed]);

  const handleValidation = (e) => {
    setError();

    if (multiChoice) {
      // Select doesn't have value in onBlur, so we check what was stored in onChange
      if (!getDetailValue?.(path)) return setError(requiredMessage);
    } else {
      if (!e.target.value) return setError(requiredMessage);

      if (validationRegexp && !RegExp(validationRegexp).test(e.target.value))
        return setError(invalidMessage);

      if (validationAsync) {
        if (!validationAsync.url) return;

        const param = validationAsync.params?.[0]?.parameterName;
        if (!param) return;

        axios
          .get(validationAsync.url, { params: { [param]: e.target.value } })
          .then(({ data: { validation } }) => validation !== 'success' && setError(invalidMessage))
          .catch(() => setError(invalidMessage));
      }
    }
  };

  const handleChange = (e) => {
    setError();

    // propagate to parent
    const path = multiChoice ? 'key' : undefined;
    handleChangeInParent?.(e, path);
    if (refreshRequirementsOnChange === true) {
      // similar to a component key, this randomized "hook key"
      // triggers the useEffect hook in the parent to refresh data
      setRefreshKey?.(`${key}-${Math.random()}`);
    }
  };

  const handleDateChange = (d) => {
    const date = d ? formatDate(d, dateFormat) : undefined;
    handleChange({ target: { name: path, value: date } });
  };

  switch (type) {
    case 'radio':
    case 'select':
      return (
        <Select
          // This key resets the component when valuesAllowed changes. (Not same as field.key)
          // Example: Branch List is reset when Bank choice changes.
          key={`${key}-${Math.random()}`}
          className={Styles.select}
          value={valuesAllowed?.find?.((v) => v?.key === getDetailValue?.(path)) || ''} // empty string makes sure it's controlled
          name={path}
          label={label}
          error={error}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ key }) => key}
          options={valuesAllowed || []}
          onChange={handleChange}
          onBlur={handleValidation}
          required={required || false}
        />
      );
    case 'date':
      return (
        <DatePicker
          dateFormat={dateFormat}
          value={getDetailValue?.(path) || ''} // empty string makes sure it's controlled
          name={path}
          label={label}
          error={error}
          // non-standard onChange behavior
          onChange={handleDateChange}
          onBlur={handleValidation}
          required={required || false}
          showPopperArrow={false}
          showMonthDropdown
          useShortMonthInDropdown
          showYearDropdown
          dropdownMode='select'
          openToDate={getDetailValue?.(path) ? undefined : new Date('2000-01-01')}
        />
      );
    case 'text':
      const textProps = {};
      // Disabled due to length-related conflicts with validation, and the use of non-standard masks
      // if (displayFormat) textProps.mask = displayFormat;
      if (minLength) textProps.minLength = minLength;

      // Warning: react-input-mask: maxLength property shouldn't be passed to the masked input.
      // It breaks masking and is unnecessary because length is limited by the mask length.
      if (maxLength && !textProps.mask) textProps.maxLength = maxLength;

      return (
        <TextInput
          type='text'
          value={getDetailValue?.(path) || ''} // empty string makes sure it's controlled
          name={path}
          label={label}
          error={error}
          onChange={handleChange}
          onBlur={handleValidation}
          required={required || false}
          {...textProps}
        />
      );
    default:
      console.error(`Unknown field type ${type} from Wise API`);
      return "A field couldn't be shown. Please contact support.";
  }
};

export default PaymentMethod;
