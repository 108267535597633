import { Fragment, useState, useCallback, memo } from 'react';
import Styles from './AttachmentViewer.module.scss';
import { checkImageUrl } from '@hiredigital/lib/helpers/utils';
import ShowAsIframe from '@hiredigital/ui/ShowAsIframe/ShowAsIframe';
import FormArea from './FormArea';

import Button from '@hiredigital/ui/Button';

import { useDropzone } from 'react-dropzone';
import { useProjects } from '@context/projects';
import { ContentType } from '@hiredigital/lib/helpers/enum';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import FilePreview from '@hiredigital/ui/FilePreview/FilePreview';
import { useEffect } from 'react';

const TitleForm = ({ content, projectUuid }) => {
  const { deleteFile } = useProjects();
  const [title, setTitle] = useState(content?.title);

  const handleDelete = () => deleteFile(projectUuid, content?.id);

  const openUrl = (url, { target, download }) => {
    const a = document.createElement('a');
    a.href = url;
    if (target) a.target = target;
    if (download) a.download = download;
    a.click();
    a.remove();
  };

  const handleDownloadAll = () => {
    const url = `${process.env.API_ENDPOINT}/projects/${projectUuid}/content/generate-zip/`;
    openUrl(url, { target: '_blank' });
  };

  const handleDownload = () => {
    if (!content?.attachment) return;
    openUrl(content.attachment, { download: content?.title, target: '_blank' });
  };

  const handleOpenNewTab = () => {
    openUrl(content?.url, { target: '_blank' });
  };

  useEffect(() => {
    setTitle(content?.title || '');
  }, [content]);

  return (
    <div className={Styles.inputArea}>
      <div className={Styles.detailArea}>
        <input
          className={Styles.inputTitle}
          value={title}
          placeholder='Add a title'
          onChange={(e) => setTitle(e.target.value)}
        />
        {content?.url && <p className={Styles.contentUrl}>{content.url}</p>}
      </div>
      <SplitButton
        more={[
          {
            ...(content.type === ContentType.ATTACHMENT.id
              ? { text: 'Download', onClick: handleDownload }
              : { text: 'Open in New Tab', onClick: handleOpenNewTab }),
          },
          {
            text: 'Download All Files',
            onClick: handleDownloadAll,
          },
          {
            text: 'Delete',
            onClick: handleDelete,
          },
        ]}
      />
    </div>
  );
};

const MemoTitleForm = memo(TitleForm);

const FileContent = ({ url, content, isLink = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isViewingScreenshot, setIsViewingScreenshot] = useState(true);

  const handleImagePreview = () => setIsOpen(true);

  return (
    <div className={Styles.fileContainer}>
      {isLink && (
        <div className={Styles.actionContainer}>
          <Button
            onClick={() => setIsViewingScreenshot(!isViewingScreenshot)}
            className={Styles.actionToggle}
            type={Button.Type.LIGHT_GRAY}>
            {isViewingScreenshot ? `View Live Site` : `View Screenshot`}
          </Button>
        </div>
      )}

      {isViewingScreenshot ? (
        <Fragment>
          <img src={url} className={Styles.image} onClick={handleImagePreview} />
          <FilePreview
            title={content?.title || 'Untitled Content'}
            preview={url}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </Fragment>
      ) : (
        <ShowAsIframe url={content?.url} className={Styles.docContainer} />
      )}
    </div>
  );
};

const AttachmentViewer = ({ projectUuid, content }) => {
  const { updateFile, currentFile, setFiles, files } = useProjects();
  const [uploadProgress, setUploadProgress] = useState(undefined);

  const isLink = content?.type === ContentType.LINK.id;

  const url = isLink
    ? content?.pageScreenshot || content?.url
    : content?.attachment || content?.preview;
  const isImage = checkImageUrl(url);
  const isOnGoogleDoc = url?.indexOf('docs.google.com') >= 0;

  const onAddAttachment = () => open();

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const formData = new FormData();
        formData.append('attachment', file, file.fileName || file.name);

        const config = {
          onUploadProgress: (progressEvent) => {
            const prog = parseInt((100.0 * progressEvent.loaded) / progressEvent.total, 10);
            setUploadProgress(prog);
          },
        };
        updateFile(projectUuid, currentFile?.id, formData, config).then((updated) =>
          setFiles(files.map((f) => (f.id === updated.id ? updated : f)))
        );
        setUploadProgress(undefined);
      });
    },
    [currentFile?.id, projectUuid, updateFile]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
  });

  return (
    <div className={Styles.container}>
      <MemoTitleForm {...{ content, projectUuid }} />
      {url ? (
        <Fragment>
          {isImage && !isOnGoogleDoc ? (
            <FileContent {...{ content, url, isLink }} />
          ) : (
            <ShowAsIframe url={url} className={Styles.docContainer} />
          )}
        </Fragment>
      ) : (
        <div className={Styles.emptyContainer}>
          <FormArea
            title={`Upload a File`}
            description={() => (
              <Fragment>
                {`Drag here or click`}
                <b>{` Browse Files `}</b>
                {`to upload (Maximum 30MB)`}
              </Fragment>
            )}
            {...getRootProps()}>
            <input {...getInputProps()} />
            <Button type={Button.Type.LIGHT_GRAY} onClick={onAddAttachment}>
              {`Browse Files`}
            </Button>
            {/* {uploadProgress && (<div className={Styles.progressBar} style={{ width: `${uploadProgress}%` }} />)} */}
          </FormArea>
        </div>
      )}
    </div>
  );
};

export default memo(AttachmentViewer);
