/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext, useEffect } from 'react';
import useLocalStorage from '@hooks/useLocalStorage';

const ChatSettingsContext = createContext();

export const ChatSettingsProvider = ({ children }) => {
  const [enterToSendStoreValue, setEnterToSendStoreValue] = useLocalStorage('enterToSend');
  const [isEnterToSend, setIsEnterToSend] = useState(Boolean(enterToSendStoreValue));

  useEffect(() => {
    setEnterToSendStoreValue(isEnterToSend);
  }, [isEnterToSend]);

  return (
    <ChatSettingsContext.Provider value={{ isEnterToSend, setIsEnterToSend }}>
      {children}
    </ChatSettingsContext.Provider>
  );
};

export const useChatSettings = () => useContext(ChatSettingsContext);
