import Button from '@hiredigital/ui/Button';
import Styles from './OnboardingWelcome.module.scss';

const OnboardingWelcome = ({ message }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>{`Welcome to Hire Digital`}</div>
      <div className={Styles.description}>{message}</div>
      <Button element='a' type={Button.Type.BLUE} href={'/guide'}>{`Complete Onboarding`}</Button>
    </div>
  );
};

export default OnboardingWelcome;
