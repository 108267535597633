import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.STRIPE_KEY);

const withStripe = (BaseComponent) => {
  const WrappedComponent = ({ className, ...props }) => {
    return (
      <Elements stripe={stripePromise}>
        <BaseComponent {...props} />
      </Elements>
    );
  };
  return WrappedComponent;
};

export default withStripe;
