import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import convert from 'htmr';
import classNames from 'classnames';

import { getDefaultCurrencyByCountry } from '@hiredigital/lib/helpers/rates';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import DualInput from '@hiredigital/ui/Input/DualInput';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import MultilineEllipsis from '@hiredigital/ui/MultiLineEllipsis';

import {
  Currency,
  ServiceRateType,
  ServiceRateStatus,
  ContractTypes,
} from '@hiredigital/lib/helpers/enum';

import Quill from '@hiredigital/ui/Quill/Editor';
import SortableSelect from '@hiredigital/ui/Input/SortableSelect';

import { TalentRateType as Type } from './index';
import Styles from './Styles.module.scss';

const RateDualInput = ({ service, handleChange }) => {
  return (
    <DualInput
      input={(Input) => (
        <Input
          type='text'
          name='maxRate'
          label={'Rate'}
          defaultValue={service.maxRate}
          onChange={handleChange}
        />
      )}
      select={(Select) => (
        <Select
          id='currencyCodeID'
          name='currencyCode'
          label='Currency'
          style={{ flexBasis: '150px' }}
          value={Currency.getEnum(service.currencyCode) || service.currencyCode}
          // getOptionLabel={({ code, label }) => `${code} - ${label}`}
          formatOptionLabel={({ code, label }, { context }) => (context === 'menu' ? label : code)}
          getOptionValue={({ id }) => id}
          options={Currency.values}
          onChange={handleChange}
        />
      )}
      secondSelect={(Select) => (
        <Select
          id='rateTypeID'
          name='rateType'
          label='Period'
          style={{ flexBasis: '150px' }}
          value={ServiceRateType.getEnum(service.rateType) || service.rateType}
          getOptionLabel={({ label }) => label}
          getOptionValue={({ id }) => id}
          options={ServiceRateType.values}
          onChange={handleChange}
        />
      )}
    />
  );
};

const TalentRateItem = ({
  type,
  apiManager,
  index,
  service: initialService,
  userSkills,
  resourceUuid,
  resource,
  onUpdateServiceRate,
  onDeleteService,
  talentPermission,
  showCopySkillsOption,
  common,
}) => {
  const isTeam = type === Type.TEAM;
  const isAdmin = type === Type.ADMIN;

  const [service, setService] = useState(initialService || {});
  const [isEdit, setIsEdit] = useState(!initialService?.uuid);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAdmin || service?.currencyCode || !common) return;
    getDefaultCurrencyByCountry(common, (currency) =>
      handleChange({ target: { name: 'currencyCode', value: currency } })
    );
  }, [service?.currencyCode, common]);

  useEffect(() => {
    if (isAdmin || service?.rateType) return;
    handleChange({ target: { name: 'rateType', value: ServiceRateType.HOUR } });
  }, [service?.rateType]);

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    if (isEdit) {
      setIsEdit(false);
    }

    if (!service.uuid) {
      onUpdateServiceRate?.();
    }
  };

  const handleChange = (event, valueKey) => {
    const { name, value } = event.target;
    setService((s) => ({
      ...s,
      [name]: valueKey ? value[valueKey] || value['id'] || value : value,
    }));
  };

  const handleSubmit = () => {
    const skills = service.orderedSkills;
    const data = {
      name: service.name,
      description: service.description,
      internalNotes: service.internalNotes,
      rateType: service.rateType?.id,
      status: service.status?.id,
      contractType: service.contractType?.id,
      currencyCode: service.currencyCode?.code,
      orderedSkills: (skills && !!skills.length && skills.map((skill) => skill.id)) || [],
      maxRate: service.maxRate || 0,
    };

    setLoading(true);
    (service.uuid
      ? isTeam
        ? updateTeamTalentRate
        : updateTalentRate
      : isTeam
      ? addTeamTalentRate
      : addTalentRate)(data)
      .then(({ data }) => {
        setIsEdit(false);
        setService((s) => ({ ...s, ...data }));
        if (!service.uuid) onUpdateServiceRate?.(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    if (service.uuid) {
      (isTeam ? deleteTeamTalentRate : deleteTalentRate)().then(() => {
        onDeleteService?.(service.uuid, index);
      });
    } else {
      onDeleteService?.(service.uuid, index);
    }
  };

  const addTalentRate = (data) => {
    return isAdmin
      ? apiManager.postAdminTalentRate(resource, resourceUuid, data)
      : apiManager.postTalentRate(data);
  };

  const updateTalentRate = (data) => {
    return isAdmin
      ? apiManager.putAdminTalentRate(resource, resourceUuid, service.uuid, data)
      : apiManager.putTalentRate(service.uuid, data);
  };

  const deleteTalentRate = () => {
    return isAdmin
      ? apiManager.deleteAdminTalentRate(resource, resourceUuid, service.uuid)
      : apiManager.deleteTalentRate(service.uuid);
  };

  const updateTeamTalentRate = (data) => {
    return apiManager.putTeamTalentRate(resourceUuid, service.uuid, data);
  };

  const addTeamTalentRate = (data) => {
    return apiManager.postTeamTalentRate(resourceUuid, data);
  };

  const deleteTeamTalentRate = () => {
    return apiManager.deleteTeamTalentRate(resourceUuid, service.uuid);
  };

  const handleCopySkills = () => {
    if (userSkills?.length) {
      setService((s) => ({ ...s, orderedSkills: [...userSkills] }));
    }
  };

  const handleCreateSkill = async (value) => {
    const { data } = await apiManager.postAdminSkill({
      label: value,
      adminGenerated: true,
    });
    setService((s) => ({ ...s, orderedSkills: (service.orderedSkills || []).concat([data]) }));
  };

  const NameWrapper = isAdmin ? 'p' : MultilineEllipsis;

  return (
    <Card.Item>
      {isEdit && (
        <div className={Styles.form}>
          <div className={Styles.formFieldsRow}>
            <div className={Styles.fieldsLeft}>
              <InputContainer>
                <TextInput
                  name='name'
                  label='Title'
                  type='text'
                  placeholder={isAdmin ? null : 'Add a title for your services'}
                  defaultValue={service.name}
                  onChange={handleChange}
                />
              </InputContainer>
              <InputGroup mobileOneColumn>
                <InputContainer>
                  <SortableSelect
                    className={Styles.select}
                    value={service.orderedSkills || []}
                    name='orderedSkills'
                    label='Skills'
                    placeholder={isAdmin ? null : 'Choose a set of skills'}
                    isClearable={false}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    isMulti
                    onChange={handleChange}
                    isPaginated
                    isCreatable={isAdmin}
                    onCreateOption={handleCreateSkill}
                    loadOptions={
                      isAdmin ? apiManager.onAdminSkillsLoadOptions : apiManager.onSkillsLoadOptions
                    }
                  />
                </InputContainer>
                {!isAdmin && (
                  <InputContainer rateInput>
                    <RateDualInput service={service} handleChange={handleChange} />
                  </InputContainer>
                )}
              </InputGroup>

              <InputContainer>
                <Quill
                  name='description'
                  label='Details'
                  placeholder={
                    isAdmin
                      ? null
                      : 'Add details about your services. Include any specific types of work or other relevant information.'
                  }
                  defaultValue={service.description || ''}
                  onChange={handleChange}
                  className={Styles.fieldDescription}
                  legacyCompat
                />
              </InputContainer>
            </div>
            {isAdmin && (
              <div className={Styles.fieldsRight}>
                {talentPermission && (
                  <InputContainer rateInput>
                    <RateDualInput service={service} handleChange={handleChange} />
                  </InputContainer>
                )}

                <InputGroup>
                  <InputContainer>
                    <Select
                      className={Styles.select}
                      name='contractType'
                      label='Contract Type'
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      options={ContractTypes.values}
                      required={true}
                      defaultValue={ContractTypes.getEnum(service.contractType)}
                      onChange={handleChange}
                      isClearable
                    />
                  </InputContainer>
                  <InputContainer>
                    <Select
                      data-test-id='status'
                      className={Styles.select}
                      defaultValue={ServiceRateStatus.getEnum(service.status) || service.status}
                      classNamePrefix='s-contact'
                      name='status'
                      label='Status'
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      options={ServiceRateStatus.values}
                      onChange={handleChange}
                    />
                  </InputContainer>
                </InputGroup>

                <InputContainer style={{ flex: '100%' }}>
                  <Quill
                    name='internalNotes'
                    label='Internal Notes'
                    defaultValue={service.internalNotes || ''}
                    onChange={handleChange}
                    className={Styles.internalNotes}
                    legacyCompat
                  />
                </InputContainer>
              </div>
            )}
          </div>
          <div className={Styles.formFieldsRow}></div>
          <div className={Styles.row}>
            {isAdmin && showCopySkillsOption && (
              <Button name='Copy' type={Button.Type.BLUE_OUTLINE} onClick={handleCopySkills}>
                {`Copy Skills from Profile`}
              </Button>
            )}
            {service.uuid && (
              <Button name='delete' type={Button.Type.WHITE} onClick={handleDelete}>
                {`Delete`}
              </Button>
            )}

            <div className={Styles.action}>
              <Button name='cancel' type={Button.Type.WHITE_BLUE_OUTLINE} onClick={handleCancel}>
                {`Cancel`}
              </Button>

              <Button
                name='submit'
                type={Button.Type.BLUE}
                onClick={handleSubmit}
                isLoading={loading}>
                {`Save`}
              </Button>
            </div>
          </div>
        </div>
      )}
      {!isEdit && (
        <div className={Styles.listPreview}>
          <div className={Styles.row}>
            <div className={Styles.listItemText}>
              <NameWrapper className={Styles.title}>
                {service.name ? service.name : 'New Rate'}
              </NameWrapper>
              {((isAdmin && talentPermission) || !isAdmin) && !!service.maxRate && (
                <div className={Styles.rateType}>
                  {`${service.maxRate} ${service.currencyCode?.code || service.currencyCode} ${
                    (ServiceRateType.getEnum(service.rateType) || service.rateType)?.label || ''
                  }`}
                </div>
              )}
              {!!service.orderedSkills?.length && (
                <div className={Styles.orderedSkills}>
                  {service.orderedSkills.map((e, i) => (
                    <span key={i}>{e?.label || e || ''}</span>
                  ))}
                </div>
              )}
              {service.description && (
                <div className={Styles.description}>{convert(service.description)}</div>
              )}
            </div>
            {isAdmin && (
              <div className={Styles.listItemText}>
                <p className={Styles.statusText}>
                  {ContractTypes.getEnum(service.contractType)?.label || ''}
                </p>
                <p className={Styles.statusText}>
                  {(ServiceRateStatus.getEnum(service.status) || service.status || {}).label || ''}
                </p>
                {service.internalNotes && (
                  <div className={classNames(Styles.description, Styles.statusText)}>
                    {convert(service.internalNotes)}
                  </div>
                )}
              </div>
            )}
            <div className={Styles.action}>
              <Button name='Edit' type={Button.Type.BLUE_OUTLINE} onClick={handleEdit}>
                {`Edit`}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Card.Item>
  );
};

TalentRateItem.propTypes = {
  type: PropTypes.number,
  apiManager: PropTypes.object.isRequired,
  index: PropTypes.number,
  service: PropTypes.object,
  userSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  resourceUuid: PropTypes.string,
  resource: PropTypes.string,
  onUpdateServiceRate: PropTypes.func,
  onDeleteService: PropTypes.func,
  talentPermission: PropTypes.bool,
  showCopySkillsOption: PropTypes.bool,
};

export default TalentRateItem;
