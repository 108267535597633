import { memo } from 'react';
import PropTypes from 'prop-types';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import Styles from './ButtonFilter.module.scss';

const ButtonFilter = ({ label, children, ...props }) => {
  return (
    <button className={Styles.actionFilter} {...props}>
      {children || label}
      <IconDown className={Styles.iconDown} />
    </button>
  );
};

ButtonFilter.propTypes = {
  label: PropTypes.string,
};

const MemoButtonFilter = memo(ButtonFilter);

export default MemoButtonFilter;
