import Styles from './Styles.module.scss';
import Section from './Section';
import classNames from 'classnames';

const MainContainer = ({ children, onBack, backLabel, title, subtitle, className, ...props }) => (
  <div className={classNames(Styles.mainContainer, className)} {...props}>
    <button onClick={onBack} className={Styles.backLink}>
      {backLabel}
    </button>
    <Section title={title} subtitle={subtitle}>
      {children}
    </Section>
  </div>
);

MainContainer.propTypes = {};

export default MainContainer;
