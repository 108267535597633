import { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getCurrencies } from '@hiredigital/lib/apis/wise';
import { PaymentCurrency, BankAccountCurrency, Currency } from '@hiredigital/lib/helpers/enum';
import Select from '@hiredigital/ui/Input/Select';

import Styles from './Currency.module.scss';
import FlagStyles from '../Flags.module.scss';

const Type = {
  DEFAULT: 0,
  BANK: 1,
  PAYMENT: 2,
  WISE_API: 999, // not valid as index for sourceEnum
};

const sourceEnum = [Currency, BankAccountCurrency, PaymentCurrency];

const Flag = ({ flag }) => (
  <span className={classNames(FlagStyles.flag, FlagStyles?.[flag?.toLowerCase()])} />
);

const CurrencyOption = ({ data, innerRef, innerProps, isSelected }) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={classNames(Styles.optionItem, FlagStyles.f16, {
        [Styles.isSelected]: isSelected,
      })}>
      <Option {...data} />
    </div>
  );
};

const SelectedOption = (data) => {
  return (
    <div className={classNames(Styles.selectedOption, FlagStyles.f16)}>
      <Option {...data} />
    </div>
  );
};

const Option = ({ name, label, country, id, flag, currencyCode }) => {
  return (
    <>
      <Flag
        flag={
          flag ||
          country ||
          id ||
          BankAccountCurrency.getEnum(currencyCode)?.country ||
          currencyCode?.slice(0, 2)
        }
      />
      <span className={Styles.name}>
        {name || label || BankAccountCurrency.getEnum(currencyCode)?.name || currencyCode}
      </span>
    </>
  );
};

const CurrencyDropdown = ({
  label = 'Currency',
  name = 'currency',
  enumKey = 'id',
  type = Type.DEFAULT,
  defaultValue,
  ...props
}) => {
  const [wiseCurrencies, setWiseCurrencies] = useState([]);

  useEffect(() => {
    if (!wiseCurrencies.length) getCurrencies().then(({ data }) => setWiseCurrencies(data));
  }, []);

  const getOptions = (type) => {
    if (type === Type.WISE_API) return wiseCurrencies;

    if (type === Type.BANK) return BankAccountCurrency.values?.filter((v) => v?.enabled);

    return sourceEnum[type].values;
  };

  const getDefaultValue = (type, defaultValue, enumKey) => {
    // Looking up in wiseCurrencies is not needed here. A dummy object will suffice.
    if (type === Type.WISE_API) return defaultValue ? { currencyCode: defaultValue } : undefined;

    return sourceEnum[type].getEnumByKey(defaultValue, enumKey);
  };

  return (
    <Select
      getOptionValue={({ id, currencyCode }) => id || currencyCode}
      getOptionLabel={({ label, name, currencyCode }) => label || name || currencyCode}
      options={getOptions(type)}
      label={label}
      name={name}
      defaultValue={getDefaultValue(type, defaultValue, enumKey)}
      {...props}
      components={{ Option: CurrencyOption }}
      formatOptionLabel={SelectedOption}
    />
  );
};

CurrencyDropdown.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

CurrencyDropdown.Type = Type;

export default CurrencyDropdown;
