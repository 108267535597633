import { useState, useEffect, useMemo, forwardRef } from 'react';

import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import parseISO from 'date-fns/parseISO';
import Styles from './Styles.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
// import 'react-datepicker/dist/react-datepicker.css';

/* Note - The following are not added yet
    - Error Validation
    - Empty State Placeholder use
*/
const convertToDate = (value) => {
  if (value instanceof Date) return value;
  return parseISO(value);
};

const DateSelector = forwardRef(
  (
    {
      name,
      label,
      placeholder,
      value,
      onChange,
      error,
      dateFormat = 'MM/dd/yyyy',
      className,
      containerClassName,
      ...restProps
    },
    ref
  ) => {
    const [startDate, setStartDate] = useState(value ? convertToDate(value) : null);
    const handleChange = (date) => {
      setStartDate(date);
      onChange(date);
    };

    useEffect(() => {
      if (value) {
        setStartDate(convertToDate(value));
      }
    }, [value]);

    const Input = useMemo(
      () =>
        forwardRef(({ name, error, label, ...props }, ref) => {
          return (
            <>
              <input {...props} ref={ref} />
              <label htmlFor={name} className={classNames(Styles.label, error && Styles.error)}>
                {error || label}
              </label>
            </>
          );
        }),
      []
    );

    return (
      <div
        className={classNames(
          containerClassName,
          Styles.container,
          error && Styles.containerError
        )}>
        <DatePicker
          name={name}
          calendarClassName={Styles.calendar}
          dateFormat={dateFormat}
          wrapperClassName={Styles.wrapper}
          dayClassName={Styles.day}
          className={classNames(Styles.base, className)}
          selected={startDate}
          placeholderText={placeholder || label}
          onChange={handleChange}
          customInput={<Input name={name} error={error} label={label} ref={ref} />}
          {...restProps}></DatePicker>
      </div>
    );
  }
);

export default DateSelector;
