import { memo } from 'react';
import Avatar from '@hiredigital/ui/Avatar';
import Styles from './MessageLayout.module.scss';
import Time from './Time';

const MessageLayout = ({ sender, created, showDetails = true, children, timeClass }) => {
  return (
    <div className={Styles.messageItem}>
      {showDetails && !!Object.keys(sender || {})?.length && (
        <div className={Styles.sender}>
          <Avatar className={'highlight-block'} src={sender?.picture} name={sender?.name} />
          <div className={Styles.name}>{sender?.name}</div>
          {created && <Time className={timeClass} date={created} />}
        </div>
      )}
      {children}
    </div>
  );
};

export default memo(MessageLayout);
