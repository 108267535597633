import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Styles from '../../Styles.module.scss';
import classNames from 'classnames';

import Loader from '@hiredigital/ui/Loader';

const Option = ({
  title,
  subtitle,
  isActive,
  direction = 'column',
  isBusy,
  className,
  renderIcon,
  ...props
}) => (
  <div className={classNames(Styles.option, className, { [Styles.active]: isActive })} {...props}>
    {isBusy ? (
      <Loader color={Loader.Color.BLUE} size={Loader.Size.SMALL} />
    ) : (
      <Fragment>
        {renderIcon?.()}
        <p className={Styles.smallTitle}>{title}</p>
        <p className={Styles.smallSubtitle}>{subtitle}</p>
      </Fragment>
    )}
  </div>
);

Option.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isActive: PropTypes.bool,
};

Option.Direction = {
  ROW: 'row',
  COLUMN: 'column',
};

export default Option;
