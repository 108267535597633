import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Loader from '@hiredigital/ui/Loader';

import { useOrg } from '@context/org';
import { postOrgProject } from '@apis/organizations';

import TitleBar from '@components/Layout/DetailTitleBar';
import Layout from '@components/Layout/AppLayout';
import { useQuery } from '@hiredigital/lib/hooks/useQuery';

const NewProject = ({ ...props }) => {
  const params = useParams();
  const query = useQuery();
  const history = useHistory();
  const org = useOrg();

  useEffect(() => {
    const orgUuid = params?.orgUuid || org?.uuid || query.get('orgUuid');
    if (orgUuid) {
      postOrgProject(orgUuid, {})
        .then(({ data }) => {
          history.replace(`/projects/new/${data?.uuid}?orgUuid=${orgUuid}`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [org, query, history]);

  return (
    <Layout
      style={{ backgroundColor: 'white' }}
      header={
        <TitleBar title={`Projects`} titleLink={`/projects`} subtitle={'New Project'}></TitleBar>
      }>
      <Loader type={Loader.Type.FULL} />
    </Layout>
  );
};

export default NewProject;
