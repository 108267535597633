import { memo } from 'react';
import Styles from './Header.module.scss';

const Header = ({ mainArea, navArea, title, menus }) => {
  // console.log('children', children);
  return (
    <div className={Styles.header}>
      <div className={Styles.mainArea}>
        <p className={Styles.title}>{title}</p>
        {mainArea?.()}
      </div>
      {navArea?.()}
    </div>
  );
};

export default memo(Header);
