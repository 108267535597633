import { useState, useEffect, useCallback } from 'react';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button/Button';

import { useOrg } from '@context/org';
import withStripe from '../../shared/hoc/withStripe';
import { postOrgStripeCustomerDeposit } from '@apis/customers';
import { getCreditPackages } from '@apis/common';
import { formatCurrency } from '@hiredigital/lib/helpers/utils';
import { getOrgStripeCustomer } from '@apis/customers';

import PackageItem from './PackageItem';

import Styles from './Styles.module.scss';

const CreditTopUpDialog = ({ isShown: initialIsShown, onClose, onCloseComplete }) => {
  const [isShown, setIsShown] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [packages, setPackages] = useState([]);
  const [customer, setCustomer] = useState();
  const [card, setCard] = useState();
  const [selectedPackage, setSelectedPackage] = useState();

  const org = useOrg();

  const loadCustomers = useCallback(async () => {
    const { data } = await getOrgStripeCustomer(org.uuid);
    setCustomer(data);
    setCard(data.cards.find((c) => c.id === data.defaultSource));
  }, [org]);

  useEffect(() => {
    if (org) {
      loadCustomers();
    }
  }, [org, loadCustomers]);
  useEffect(() => {
    setIsShown(initialIsShown);
  }, [initialIsShown]);

  useEffect(() => {
    getCreditPackages().then(({ data }) => {
      setPackages(data.results);
    });
  }, []);

  const handleClose = (paymentSuccess) => {
    setIsShown(false);
    onClose?.(paymentSuccess);
  };

  const handleConfirmPayment = async () => {
    setIsSaving(true);

    try {
      const response = await postOrgStripeCustomerDeposit(org?.uuid, {
        amount: selectedPackage.amount,
        initial: false,
      });
      handleClose(response);
      setIsSaving(false);
    } catch (e) {}
  };

  const handlePurchase = (data) => {
    setSelectedPackage(data);
  };

  return (
    <Dialog
      isOpen={isShown}
      width={450}
      title='Top Up Credits'
      onClose={handleClose}
      onCloseComplete={onCloseComplete}>
      <div>
        <p
          className={
            Styles.description
          }>{`Select how many credits you would like to add. Credits can be used across all of your talent engagments.`}</p>

        <div className={Styles.packages}>
          {packages.map((v, idx) => (
            <PackageItem
              key={idx}
              stripeCurrency={org?.payment?.stripeCurrency}
              data={v}
              selectedPackage={selectedPackage}
              onPurchase={handlePurchase}
            />
          ))}
          <PackageItem
            isCustom={true}
            data={{}}
            stripeCurrency={org?.payment?.stripeCurrency}
            selectedPackage={selectedPackage}
            onPurchase={handlePurchase}
          />
        </div>

        {selectedPackage && (
          <>
            <div className={Styles.amountTotal}>
              <div className={Styles.totalLabel}>{`Total Amount`}</div>
              <div className={Styles.total}>
                <div className={Styles.amount}>
                  {`${['SGD', 'sgd'].includes(org?.payment?.stripeCurrency) ? 'S' : ''}`}
                  {formatCurrency(selectedPackage.amount || 0, 0)}
                </div>
                <div
                  className={Styles.paymentBy}>{`via ${card.brand} ending in ${card.last4}`}</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div style={{ marginTop: '20px' }}>
        <Button
          disabled={!selectedPackage?.amount}
          type={Button.Type.BLUE}
          onClick={handleConfirmPayment}
          isLoading={isSaving}>
          {'Confirm Payment'}
        </Button>
        <Button type={Button.Type.LIGHT_GRAY} onClick={handleClose}>
          {'Cancel'}
        </Button>
      </div>
    </Dialog>
  );
};
export default withStripe(CreditTopUpDialog);
