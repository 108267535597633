import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import Styles from './Layout.module.scss';

const TitleBar = ({
  title,
  children,
  onSearchChange,
  searchPlaceholder,
  inputRef,
  onClearSearch,
  showSearch,
  filterComponent,
  noWrap = true,
}) => {
  const handleSearchChange = (e) => onSearchChange?.(e.target.value);
  const handleClearSearch = () => onClearSearch?.();

  return (
    <div className={classNames(Styles.header, !noWrap && Styles.wrap)}>
      <h1 className={Styles.headerTitle}>{title}</h1>
      <div className={Styles.searchArea}>
        <div className={Styles.inputContainer}>
          <input
            type={`text`}
            name={`search`}
            placeholder={searchPlaceholder || `Search ${title?.toLowerCase()}`}
            onChange={handleSearchChange}
            className={Styles.headerSearch}
            ref={inputRef}
          />
          {showSearch && (
            <button onClick={handleClearSearch} className={Styles.actionClear}>
              <IconClose className={Styles.iconClose} />
            </button>
          )}
        </div>
        {filterComponent && <div className={Styles.filters}>{filterComponent()}</div>}
      </div>
      {children && <div className={Styles.actions}>{children}</div>}
    </div>
  );
};

TitleBar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onSearchChange: PropTypes.func,
  searchPlaceholder: PropTypes.string,
};

export default TitleBar;
