import Styles from './RateForm.module.scss';
import SimpleTab from '@hiredigital/ui/SimpleTab';
import TextInput from '@hiredigital/ui/Input/SimpleTextInput';
import Select from '@hiredigital/ui/Input/Select';
import TextArea from '@hiredigital/ui/Input/TextArea';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import Toggle from '@hiredigital/ui/Toggle';

const RateForm = ({ style, value, ...props }) => {
  return (
    <div className={Styles.container}>
      <SimpleTab>
        <SimpleTab.Panel title={`Full time`}>
          <div className={Styles.fields}>
            <InputGroup>
              <InputContainer>
                <Select label={`Currency`} />
              </InputContainer>
              <InputContainer>
                <TextInput label={`Salary`} />
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <TextInput label={`Notice Period`} />
              </InputContainer>
              <InputContainer />
            </InputGroup>
            <Toggle id='is-default' name='isDefault' label={`Yes, I am willing to relocate`} />
          </div>
        </SimpleTab.Panel>
        <SimpleTab.Panel title={`Part time`}>{`Test 2`}</SimpleTab.Panel>
      </SimpleTab>
    </div>
  );
};

export default RateForm;
