// import { Dialog } from 'evergreen-ui/esm/dialog';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button/Button';
import convert from 'htmr';

const ConfirmCandidate = ({
  title,
  description,
  isLoading,
  isShown,
  onConfirm,
  onClose,
  onCloseComplete,
}) => {
  const handleClose = () => onClose?.();
  return (
    <Dialog
      isOpen={isShown}
      title={title}
      onClose={handleClose}
      onCloseComplete={onCloseComplete}
      width={700}>
      {description && <div style={{ marginBottom: '20px' }}>{convert(description)}</div>}
      {`Any changes made after confirmation may be subjected to additional fees.`}
      <div style={{ marginTop: '30px' }}>
        <Button onClick={onConfirm} type={Button.Type.BLUE} isLoading={isLoading}>
          {`Submit`}
        </Button>
        <Button onClick={handleClose} type={Button.Type.LIGHT_GRAY}>
          {`Cancel`}
        </Button>
      </div>
    </Dialog>
  );
};

ConfirmCandidate.propTypes = {};

export default ConfirmCandidate;
