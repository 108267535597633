import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { getProject } from '@apis/projects';
import { ProjectProvider } from '@context/projects';

import Layout from '@components/Layout/AppLayout';
import NewProject from '@components/Projects/NewProject';
import Loader from '@hiredigital/ui/Loader';

import TitleBar from '@components/Layout/DetailTitleBar';
import Styles from '../Styles.module.scss';

const ProjectDetail = ({ ...props }) => {
  const { uuid } = useParams();
  const history = useHistory();

  const [project, setProject] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const loadData = async (projectUuid) => {
    if (!projectUuid) return;
    try {
      setIsLoading(true);
      const response = await getProject(projectUuid);
      setProject(response.data);
    } catch (e) {
      toast.error('Project not found.', { id: 'notFound' });
      history.push('/projects');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData(uuid);
  }, [uuid]);

  const handleBack = () => history.push('/projects');

  return (
    <ProjectProvider>
      <Layout
        style={{ backgroundColor: 'white' }}
        bodyClass={Styles.newWrapper}
        header={
          <TitleBar title={`Projects`} titleLink={`/projects`} subtitle={'New Project'}></TitleBar>
        }>
        {isLoading ? (
          <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
        ) : (
          project && <NewProject project={project} onBack={handleBack} onAfterSubmit={setProject} />
        )}
      </Layout>
    </ProjectProvider>
  );
};

export default ProjectDetail;
