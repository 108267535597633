import { useState } from 'react';
import PropTypes from 'prop-types';
import ApplicationOverview from './ApplicationOverview';
import InvitationOverview from './InvitationOverview';

const Detail = ({ engagement }) => {
  const [data, setData] = useState(engagement);

  const handleApplication = (application) => {
    setData({ ...data, hasApplication: application?.uuid });
  };

  return data?.hasApplication ? (
    <ApplicationOverview engagement={data} />
  ) : (
    <InvitationOverview engagement={data} onApplicationCreated={handleApplication} />
  );
};

Detail.propTypes = {
  children: PropTypes.node,
};

export default Detail;
