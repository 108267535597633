import Position from 'evergreen-ui/esm/constants/src/Position';
import { Tooltip } from 'evergreen-ui/esm/tooltip';
import Badge from '@hiredigital/ui/Badge/Badge';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import Ellipsis from '@hiredigital/ui/MultiLineEllipsis/SingleLine';
import IconCheckCircle from '@hiredigital/ui/Icon/icons/check-circle.inline.svg';
import Styles from './DurationItem.module.scss';

const DurationItem = ({ data = {}, onEdit, onDelete }) => {
  const { duration, manual, locked } = data;

  return (
    <div className={Styles.container}>
      {manual && <Badge color='neutral' className={Styles.badge}>{`Manual`}</Badge>}
      <Ellipsis className={Styles.title}>{duration}</Ellipsis>
      <span className={Styles.moreContainer}>
        {locked ? (
          <Tooltip content={'Processed'} position={Position.RIGHT}>
            <div>
              <IconCheckCircle style={{ display: 'block' }} />
            </div>
          </Tooltip>
        ) : (
          <SplitButton
            direction='right_top'
            mainButtonContainerClass={Styles.moreButton}
            more={[
              {
                text: 'Edit',
                onClick: () => onEdit?.(data),
              },
              {
                text: 'Delete',
                onClick: () => onDelete?.(data),
              },
            ]}
          />
        )}
      </span>
    </div>
  );
};

export default DurationItem;
