import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Styles from './Detail.module.scss';
import { getEngagementApplication } from '@apis/engagements';
import BrandProfile from '../BrandProfile/BrandProfile';
import ApplicationProposal from './ApplicationProposal';
import { ApplicationStatus } from '@hiredigital/lib/helpers/enum';
import DiscussionArea from '../Discussions/ContentArea/DiscussionArea';
import { DiscussionProvider } from '@context/discussion';
import Engagement from './Engagement';
import Loader from '@hiredigital/ui/Loader';
import classNames from 'classnames';

const Container = ({ children, hasContainer }) => {
  return hasContainer ? (
    <div className={Styles.centerContainer}>{children}</div>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

const ApplicationOverview = ({ engagement }) => {
  const [applicationUuid, setApplicationUuid] = useState(engagement?.hasApplication);
  const [application, setApplication] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setApplicationUuid(engagement?.hasApplication);
  }, [engagement]);

  useEffect(() => {
    if (applicationUuid) {
      getEngagementApplication(engagement?.uuid, applicationUuid)
        .then(({ data }) => {
          setApplication(data);
          setIsLoaded(true);
        })
        .catch((error) => {
          console.error(error);
          setIsLoaded(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationUuid]);

  const hasApplicationDiscussion =
    application?.discussion?.id &&
    [
      ApplicationStatus.INTERVIEW.id,
      ApplicationStatus.INTERVIEW_SELECTED.id,
      ApplicationStatus.ACCEPTED.id,
    ].includes(application?.status);

  const hasOpenProposal = [
    ApplicationStatus.DRAFT.id,
    ApplicationStatus.SUBMITTED.id,
    ApplicationStatus.SUBMITTED_HOLD.id,
    ApplicationStatus.SUBMITTED_REVIEWED.id,
  ].includes(application?.status);

  const hasRightContent = hasApplicationDiscussion || hasOpenProposal;

  return (
    <div className={Styles.container}>
      {isLoaded ? (
        <Fragment>
          <div className={classNames(Styles.contentLeft)}>
            <Container hasContainer={!hasRightContent}>
              <Engagement engagement={engagement} application={application} />
              <BrandProfile
                orgUuid={application?.organization?.uuid}
                engagement={engagement || {}}
                organization={application?.organization}
                style={{ marginTop: '50px' }}
              />
            </Container>
          </div>
          {hasRightContent && (
            <div className={Styles.contentRight}>
              {hasOpenProposal && (
                <Fragment>
                  {application && engagement && (
                    <ApplicationProposal
                      application={application}
                      engagement={engagement}
                      onAfterSubmit={setApplication}
                    />
                  )}
                </Fragment>
              )}
              {hasApplicationDiscussion && (
                <DiscussionProvider>
                  <DiscussionArea
                    discussion={application?.discussion}
                    allowSend={[
                      ApplicationStatus.INTERVIEW.id,
                      ApplicationStatus.INTERVIEW_SELECTED.id,
                    ].includes(application?.status)}
                  />
                </DiscussionProvider>
              )}
            </div>
          )}
        </Fragment>
      ) : (
        <Loader color={Loader.Color.BLUE} style={{ margin: 'auto' }} />
      )}
    </div>
  );
};

ApplicationOverview.propTypes = {
  children: PropTypes.node,
};

export default ApplicationOverview;
