import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { getUserNotificationSettings, updateUserNotificationSettings } from '@apis/users';
import { isTalent, isClient } from '@helpers/permissions';
import { NotificationChoice } from '@hiredigital/lib/helpers/enum';

import Action from '../Layout/Action';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Text from '@hiredigital/ui/Text';

import { useUser } from '@context/user';

import toast from 'react-hot-toast';

import Styles from './UserNotificationSettings.module.scss';

const UserNotificationSettings = ({ ...props }) => {
  const user = useUser();
  const [settings, setSettings] = useState();

  useEffect(() => {
    const setData = async () => {
      if (user?.uuid) {
        const { data } = await getUserNotificationSettings(user?.uuid);
        setSettings(data);
      }
    };

    setData();
  }, [user]);

  const handleSelectOption = async (e) => {
    try {
      const { name, value } = e.target;
      const updatedSettings = { ...settings, [name]: parseInt(value, 10) };
      setSettings(updatedSettings);
      await updateUserNotificationSettings(user?.uuid, updatedSettings);
      toast.success('Your notification settings were updated successfully.', {
        id: 'notifications',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getAction = (choiceId) => {
    return choiceId === NotificationChoice.NEVER.id ? 'will not' : 'will';
  };

  const getFrequency = (choiceId) => {
    return choiceId === NotificationChoice.DAILY.id ? 'daily' : '';
  };

  return (
    <Card>
      <Card.Header title={`Notification Settings`}></Card.Header>

      <Card.Item className={Styles.container} flex>
        <div>
          <Text type={Text.Type.PRIMARY}>{`Workspace & Discussions`}</Text>
          <Text type={Text.Type.SECONDARY}>
            {`You ${getAction(settings?.activity)} be notified ${getFrequency(
              settings?.activity
            )} when work is submitted or when someone messages you.`}
          </Text>
        </div>
        <Action className={Styles.action}>
          {NotificationChoice.values.map((choice, idx) => (
            <Button
              key={idx}
              name='activity'
              value={choice.id}
              className={classNames(
                Styles.option,
                settings?.activity === choice.id && Styles.active
              )}
              onClick={handleSelectOption}>
              {choice.label}
            </Button>
          ))}
        </Action>
      </Card.Item>
      <Card.Item className={Styles.container} flex>
        <div>
          <Text type={Text.Type.PRIMARY}>{isTalent(user) ? `New Engagements` : `Briefs`}</Text>

          <Text type={Text.Type.SECONDARY}>
            {`You ${getAction(settings?.marketplace)} be notified ${getFrequency(
              settings?.marketplace
            )} when ${
              isTalent(user)
                ? 'relevant engagements are open for applications.'
                : 'your brief has been reviewed or talents applied to your engagements.'
            }`}
          </Text>
        </div>
        <Action className={Styles.action}>
          {NotificationChoice.values.map((choice, idx) => (
            <Button
              key={idx}
              name='marketplace'
              value={choice.id}
              className={classNames(
                Styles.option,
                settings?.marketplace === choice.id && Styles.active
              )}
              onClick={handleSelectOption}>
              {choice.label}
            </Button>
          ))}
        </Action>
      </Card.Item>
      {isClient(user) && (
        <Card.Item className={Styles.container} flex>
          <div>
            <Text type={Text.Type.PRIMARY}>{`Billing`}</Text>
            <Text type={Text.Type.SECONDARY}>
              {`You ${getAction(settings?.payments)} be notified ${getFrequency(
                settings?.payments
              )} when there are billing or credits updates.`}
            </Text>
          </div>
          <Action className={Styles.action}>
            {NotificationChoice.values.map((choice, idx) => (
              <Button
                key={idx}
                name='payments'
                value={choice.id}
                className={classNames(
                  Styles.option,
                  settings?.payments === choice.id && Styles.active
                )}
                onClick={handleSelectOption}>
                {choice.label}
              </Button>
            ))}
          </Action>
        </Card.Item>
      )}
    </Card>
  );
};

export default UserNotificationSettings;
