import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import { COOKIE_DOMAIN } from '@helpers/utils';
import BaseLayout from '@components/Layout/Layout';
import { useUserDispatch } from '@context/user';
import { useOrgDispatch } from '@context/org';
import { useTeamDispatch } from '@context/team';
import { userSocket } from '@helpers/wsConnection';

const cookies = new Cookies();

const LogoutPage = () => {
  const history = useHistory();
  const dispatchOrg = useOrgDispatch();
  const dispatchUser = useUserDispatch();
  const dispatchTeam = useTeamDispatch();

  useEffect(() => {
    cookies.remove('token', COOKIE_DOMAIN);
    cookies.remove('user_uuid', COOKIE_DOMAIN);
    cookies.remove('org_uuid', COOKIE_DOMAIN);
    cookies.remove('team_uuid', COOKIE_DOMAIN);
    userSocket.close();
    if (process.env.ENV !== 'local') {
      window.location.href = `${process.env.MARKETING_URL}/login`;
    } else {
      history.push(`/login`);
    }
    dispatchOrg({ type: 'UPDATE', payload: null });
    dispatchUser({ type: 'UPDATE', payload: null });
    dispatchTeam({ type: 'UPDATE', payload: null });
  });

  return (
    <BaseLayout
      pageHead={{
        title: `Logout | Hire Digital`,
      }}></BaseLayout>
  );
};

export default LogoutPage;
