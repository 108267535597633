import { Fragment, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { PortfolioItemType } from '@hiredigital/lib/helpers/enum';
import { getTeams } from '@apis/teams';
import { authRequest, authReqNoIntercept } from '@apis/utils';

import PortfolioItem from './PortfolioItem';
import PortfolioEdit from './PortfolioEdit';
import Packery from '@hiredigital/ui/Packery/NewPackery';
import PortfolioViewer from '@hiredigital/ui/PortfolioViewer/PortfolioViewer';

import {
  getPortfolioItemsRequests,
  postPortfolioOrderRequests,
  PortfolioType,
  postPortfolioRequests,
  patchPortfolioRequests,
  deletePortfolioRequests,
  patchPortfolioCaseRequests,
  postPortfolioCaseRequests,
  deletePortfolioCaseRequests,
  getPortfolioItemRequests,
} from './apiMapper';

import Styles from './PortfolioList.module.scss';

export const Type = PortfolioType;

const PortfolioList = ({
  useContentWrapper,
  useScrollContainer,
  onLoad,
  resourceUuid,
  type,
  data: origData, // for undefined values we're going to load items internally. for 0 or more array elements no need to load items
  onNavigate,
  // custom props
  onListChange,
  viewOnly = false,
}) => {
  const [items, setItems] = useState(origData || []);
  const [nextPage, setNextPage] = useState(null);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [teams, setTeams] = useState([]);

  const refContainer = useRef();
  const itemRef = useRef();

  let contentWrapper = null;
  let scrollerContainer = null;

  const openViewer = (item) => {
    itemRef.current.open(item);
  };

  const openEditPortfolio = (item) => {
    setSelectedItem(item || { type: PortfolioItemType.URL.id });
    setShowEdit(true);
  };

  const dragOptions = {
    handle: `.${Styles.portfolioHandle}`,
  };

  const packeryOptions = {
    transitionDuration: '0.5s',
    gutter: `.${Styles.portfolioGutter}`,
    stamp: `.${Styles.portfolioStamp}`,
    gutterClass: Styles.portfolioGutter,
    originLeft: true,
    itemSelector: `.${Styles.portfolioItem}`,
  };

  const portfolioItems = items.map((item) => (
    <div key={item.id} id={item.id} className={Styles.portfolioItem}>
      <PortfolioItem
        handleClass={Styles.portfolioHandle}
        items={items}
        item={item}
        type={type}
        resourceUuid={resourceUuid}
        onEdit={openEditPortfolio}
        onPreview={openViewer}
        viewOnly={viewOnly}
        showExcerpt={false}
      />
    </div>
  ));

  const loadItems = () => {
    getPortfolioItemsRequests[type](resourceUuid).then(
      ({ data }) => {
        setItems(data.results);
        setNextPage(data.meta.next);
      },
      (error) => {
        onLoad?.();
      }
    );
  };

  const onScroll = () => {
    const scrollDiff = Math.abs(contentWrapper.clientHeight - scrollerContainer.scrollTop);
    if (nextPage && !nextPageLoading && scrollDiff < 1600) {
      setNextPageLoading(true);
      authRequest.get(nextPage).then(({ data }) => {
        if (!data.meta.next) {
          console.log('No more pages');
        } else {
          setNextPageLoading(false);
          setNextPage(data.meta.next);
        }
        setItems([...items, ...data.results]);
      });
    }
  };

  const handleOrderChange = (updatedOrder) => {
    const data = { ids: updatedOrder };
    postPortfolioOrderRequests[type](resourceUuid, data).then(
      (response) => {},
      (error) => {}
    );
  };

  const validate = (item) => {
    const err = {};
    let hasError = false;

    if (item?.type === PortfolioItemType.CASE_STUDY.id) {
      err.caseItems = [];
      item?.caseItems?.forEach((item, idx) => {
        err.caseItems[idx] = {};
        if (!item?.title) {
          err.caseItems[idx].title = 'Title is required';
          hasError = true;
        }
      });
    }

    if (hasError) {
      setErrors(err);
      return false;
    }

    return true;
  };

  const handleSave = (item) => {
    if (item.type === PortfolioItemType.CASE_STUDY.id) {
      if (validate(item)) {
        saveCases(item);
      }
    } else {
      savePortfolioItem(item);
    }
  };

  const savePortfolioItem = (item) => {
    const { title, content, skills, publication, url, teams } = item;

    const data = {
      title,
      content,
      showThumbnail: item.showThumbnail,
      private: item.private,
      url,
      skills,
      publication,
      teams,
    };

    setIsSaving(true);
    patchPortfolioRequests[type](resourceUuid, item.id, data).then(
      (response) => {
        setIsSaving(false);
        setShowEdit(false);
        updateItems(items, response.data);
      },
      (error) => {
        setIsSaving(false);
      }
    );
  };

  const saveCases = (item) => {
    const caseRequests = [];
    const newCases = [];
    for (let x = 0; x < item?.caseItems.length; x++) {
      const data = {
        title: item.caseItems[x].title,
        description: item.caseItems[x].description,
      };

      const request = patchPortfolioCaseRequests[type](
        resourceUuid,
        item.id,
        item.caseItems[x].id,
        data
      ).then((response) => {
        newCases[x] = response.data;
      });

      caseRequests.push(request);
    }

    setIsSaving(true);
    caseRequests.push(
      patchPortfolioRequests[type](resourceUuid, item.id, { private: item.private })
    );
    Promise.all(caseRequests)
      .then(() => {
        savePortfolioItem(item);
        setIsSaving(false);
        setShowEdit(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
      });
  };

  const handleDelete = (item) => {
    setIsSaving(true);
    deletePortfolioRequests[type](resourceUuid, item.id).then(
      (response) => {
        setIsSaving(false);
        setShowEdit(false);
        setItems(items.filter((v) => v.id !== item.id));
      },
      (error) => {
        setIsSaving(false);
      }
    );
  };

  const handleCancel = () => {
    setShowEdit(false);
    setErrors({});
  };

  const handleUrlSave = (item) => {
    setIsSaving(true);
    postPortfolioRequests[type](resourceUuid, { url: item.url }).then(
      ({ data }) => {
        setIsSaving(false);
        setItems([data, ...items]);
        setShowEdit(false);
      },
      (error) => {
        handleSubmitError(error);
        setIsSaving(false);
      }
    );
  };

  const handleCaseUpload = (files, item) => {
    setIsSaving(true);
    if (files.length) {
      if (item.id) {
        uploadCases(files, item);
      } else {
        const data = { type: item.type };
        postPortfolioRequests[type](resourceUuid, data).then(
          ({ data }) => {
            uploadCases(files, data);
          },
          (error) => {
            setIsSaving(false);
          }
        );
      }
    }
  };

  const uploadCases = (files, portfolioItem) => {
    const caseRequests = [];
    setIsSaving(true);
    const newCases = portfolioItem.caseItems || [];
    for (let x = 0; x < files.length; x++) {
      const data = new FormData();
      data.append('attachment', files[x]);
      const request = postPortfolioCaseRequests[type](resourceUuid, portfolioItem.id, data).then(
        (response) => {
          newCases.push(response.data);
        }
      );
      caseRequests.push(request);
    }

    Promise.all(caseRequests)
      .then(() => {
        setIsSaving(false);
        setSelectedItem({ ...portfolioItem, caseItems: newCases });
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  const handleCaseDelete = (item, caseItem) => {
    setIsSaving(true);
    deletePortfolioCaseRequests[type](resourceUuid, item.id, caseItem.id).then(
      (response) => {
        setIsSaving(false);
        const remainingCaseItems = item.caseItems.filter((v) => v.id !== caseItem.id);
        setSelectedItem({ ...item, caseItems: remainingCaseItems });
        // If its the last case item then delete the item itself.
        if (remainingCaseItems.length === 0) {
          handleDelete(item);
        }
      },
      (error) => {
        setIsSaving(false);
      }
    );
  };

  const handleCaseUpdate = (file, item, caseItem) => {
    setIsSaving(true);
    const data = new FormData();
    data.append('image', file);
    patchPortfolioCaseRequests[type](resourceUuid, item.id, caseItem.id, data).then(
      (response) => {
        const updatedCases = item.caseItems;
        const index = item.caseItems.findIndex((v) => v.id === caseItem.id);
        updatedCases[index] = response.data;
        setSelectedItem({ ...item, caseItems: updatedCases });
        // updateItems(items, updatedItem);
        setIsSaving(false);
      },
      (error) => {
        setIsSaving(false);
      }
    );
  };

  const handleScreenshotUpload = (file, item) => {
    if (file) {
      const fData = new FormData();
      fData.append('page_screenshot', file);
      patchPortfolioRequests[type](resourceUuid, item.id, fData)
        .then(({ data }) => {
          const updatedItem = { ...item, pageScreenshot: data.pageScreenshot };
          setSelectedItem(updatedItem);
          updateItems(items, updatedItem);
          setIsSaving(false);
        })
        .catch((error) => {
          setIsSaving(false);
        });
    }
  };

  const handleImageUpload = (file, item) => {
    if (file) {
      const fData = new FormData();
      fData.append('image', file);
      setIsSaving(true);
      patchPortfolioRequests[type](resourceUuid, item.id, fData)
        .then(({ data }) => {
          const updatedItem = { ...item, image: data.image };
          setSelectedItem(updatedItem);
          updateItems(items, updatedItem);
          setIsSaving(false);
        })
        .catch((error) => {
          setIsSaving(false);
        });
    }
  };

  const updateItems = (items, item) => {
    if (items.findIndex((e) => e.uuid === item.uuid) === -1) {
      setItems([item, ...items]);
    } else {
      setItems(items.map((v) => (v.uuid === item.uuid ? item : v)));
    }
  };

  const handleSubmitError = (error) => {
    const errorFallback = 'There was a problem saving this portfolio item.';
    try {
      const errorData = (error.response && error.response.data) || {};
      const getErrorKey = (v) => Object.keys(v)[0];
      const errorKey = (getErrorKey(errorData) || '').toLowerCase();
      const errorMessage = (errorData && errorKey && errorData[errorKey]) || errorFallback;
      setErrors({ [{ 0: 'url' }[errorKey] || errorKey]: errorMessage });
    } catch (error) {
      //onError(errorFallback);
    }
  };

  const loadCurrentUserTeams = () => {
    getTeams()
      .then(({ data }) => {
        setTeams(data?.results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFormChange = () => setErrors({});

  useEffect(() => {
    // Fixed: default value for data or origData props is removed because empty array is treated as truthy value
    // making the below condition impossible to satisfy
    const hasNoInitialData = !origData;
    if (resourceUuid && hasNoInitialData) {
      loadItems();
      loadCurrentUserTeams();
    }
  }, []);

  useEffect(() => {
    onListChange?.(items);
  }, [items]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/rules-of-hooks
    contentWrapper = useContentWrapper?.() || refContainer?.current?.parentNode;
    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/rules-of-hooks
    scrollerContainer = useScrollContainer?.() || contentWrapper?.parentNode;
    scrollerContainer.addEventListener('scroll', onScroll);
    return () => scrollerContainer.removeEventListener('scroll', onScroll);
  }, [nextPage, nextPageLoading]); // Re-subscribing on each render - this would keep state dependencies updated everytime
  return (
    <Fragment>
      <div ref={refContainer} className={Styles.portfolioGrid}>
        <div>
          {showEdit && (
            <PortfolioEdit
              type={type}
              item={selectedItem}
              onSave={handleSave}
              onDelete={handleDelete}
              onCancel={handleCancel}
              onCaseUpload={handleCaseUpload}
              onCaseDelete={handleCaseDelete}
              onCaseUpdate={handleCaseUpdate}
              onScreenshotUpload={handleScreenshotUpload}
              onImageUpload={handleImageUpload}
              onUrlSave={handleUrlSave}
              resourceUuid={resourceUuid}
              isLoading={isSaving}
              showTagTeam={!!teams?.length}
              onFormChange={handleFormChange}
              errors={errors}
            />
          )}

          {!viewOnly && (
            <div className={Styles.addItemRow}>
              <div
                className={classNames(Styles.portfolioNew)}
                onClick={() => openEditPortfolio({ type: PortfolioItemType.URL.id })}>
                {`Add Link`}
              </div>
              <div
                className={classNames(Styles.portfolioNew)}
                onClick={() => openEditPortfolio({ type: PortfolioItemType.UPLOAD.id })}>
                {`Add Upload`}
              </div>
              <div
                className={classNames(Styles.portfolioNew)}
                onClick={() => openEditPortfolio({ type: PortfolioItemType.CASE_STUDY.id })}>
                {`Add Case Study`}
              </div>
            </div>
          )}

          {items &&
            (viewOnly ? (
              <div className={Styles.viewScroll}>
                <div className={Styles.viewList}>{portfolioItems}</div>
              </div>
            ) : (
              <Packery
                className={Styles.portfolioList}
                options={packeryOptions}
                dragOptions={dragOptions}
                items={items}
                allowDrag={true}
                onOrderChange={handleOrderChange}>
                <div>{portfolioItems}</div>
              </Packery>
            ))}
        </div>
      </div>
      <PortfolioViewer
        request={authReqNoIntercept}
        items={items}
        ref={itemRef}
        type={type}
        getItem={getPortfolioItemRequests[type]}
        onNavigate={onNavigate}
      />
    </Fragment>
  );
};

PortfolioList.Type = PortfolioType;

PortfolioList.propTypes = {
  useContentWrapper: PropTypes.func,
  useScrollContainer: PropTypes.func,
  onLoad: PropTypes.func,
  resourceUuid: PropTypes.string,
  type: PropTypes.number,
  data: PropTypes.array,
};

export default PortfolioList;
