import { createContext, useState, useContext, useEffect, useReducer } from 'react';
import { getUserNotifications } from '../apis/users';
import { useUser } from './user';

const NotificationsContext = createContext();
const NotificationsDispatchContext = createContext();

const initialState = {
  notifications: [],
  meta: {},
  isLoading: false,
  unread: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONCAT_NOTIFICATIONS':
      return { ...state, notifications: [...state.notifications, ...action.payload] };
    case 'UPDATE_NOTIFICATIONS':
      return { ...state, notifications: action.payload };
    case 'UPDATE_META':
      return { ...state, meta: action.payload };
    case 'UPDATE_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'UPDATE_UNREAD':
      return { ...state, unread: action.payload };
    case 'INCREMENT_UNREAD':
      return { ...state, unread: (state.unread || 0) + action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const user = useUser();

  const concatNotifications = (results) => {
    dispatch({
      type: 'CONCAT_NOTIFICATIONS',
      payload: results,
    });
  };

  const updateNotifications = (results) => {
    dispatch({
      type: 'UPDATE_NOTIFICATIONS',
      payload: results,
    });
  };

  const updateMeta = (meta) => {
    dispatch({
      type: 'UPDATE_META',
      payload: meta,
    });
  };

  const updateLoading = (isLoading) => {
    dispatch({
      type: 'UPDATE_IS_LOADING',
      payload: isLoading,
    });
  };

  const updateUnread = (unread) => {
    dispatch({
      type: 'UPDATE_UNREAD',
      payload: unread,
    });
  };

  const incrementUnread = (increment = 1) => {
    dispatch({
      type: 'INCREMENT_UNREAD',
      payload: increment,
    });
  };

  const loadNotifications = async (params, reset = false) => {
    if (user?.uuid) {
      updateLoading(true);
      const config = { params };
      const {
        data: { meta, results },
        data,
      } = await getUserNotifications(user?.uuid, config);
      if (reset) {
        updateNotifications(results);
      } else {
        concatNotifications(results);
      }
      updateMeta(meta);
      updateUnread(meta?.unreadCount || 0);
      updateLoading(false);
      return { data };
    }
    return { data: null };
  };

  return (
    <NotificationsDispatchContext.Provider value={dispatch}>
      <NotificationsContext.Provider
        value={{
          state,
          loadNotifications,
          updateLoading,
          updateMeta,
          updateUnread,
          incrementUnread,
        }}>
        {children}
      </NotificationsContext.Provider>
    </NotificationsDispatchContext.Provider>
  );
};

const useNotifications = () => useContext(NotificationsContext);
const useNotificationsDispatch = () => useContext(NotificationsDispatchContext);

export { NotificationsProvider, NotificationsContext, useNotifications, useNotificationsDispatch };
