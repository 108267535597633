import { authRequest } from './utils';
import { getCities } from './common';
import { ProjectStatus } from '@hiredigital/lib/helpers/enum';
import qs from 'qs';

export const onBriefLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-briefs`;
  return loadOptions(url, data);
};

export const onOrgsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/orgs`;
  return loadOptions(url, data);
};

export const onOrgLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-orgs`;
  return loadOptions(url, data);
};

export const onAssignmentsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-assignments`;
  return loadOptions(url, data);
};

export const onAllUsersLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-all-users/`;
  return loadOptions(url, data);
};

export const onClientUsersLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-client-users`;
  return loadOptions(url, data);
};

export const onUsersLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-talent-users/`;
  return loadOptions(url, data);
};

export const onAssignmentUsersLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20, showAll: true } };
  const url = `admin/dropdown-talent-users?type=assignment`;
  return loadOptions(url, data);
};

export const onTeamsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `admin/dropdown-teams/`;
  return loadOptions(url, data);
};

export const onSkillsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `skills/`;
  return loadOptions(url, data);
};

export const onSkillsTreeLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `skills-tree/`;
  return loadOptions(url, data);
};

export const onPublicationsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `publications`;
  return loadOptions(url, data);
};

export const onCurrentUserTeamsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `teams`;
  return loadOptions(url, data);
};

export const onTalentTypesLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `talent-types`;
  return loadOptions(url, data);
};

export const onFormatLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `formats`;
  return loadOptions(url, data);
};

export const onHubspotDealsLoadOptions = (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `hubspot-deals`;
  return loadOptions(url, data);
};

export const onLocationLoadOptions = async (search, loadedOptions, { page }) => {
  const response = await getCities(search);
  return {
    options: response.data.predictions,
  };
};

export const onCurrentProjectsLoadOptions = (search, loadedOptions, { page }) => {
  const status = [
    ProjectStatus.IN_PROGRESS.id,
    ProjectStatus.IN_REVIEW.id,
    ProjectStatus.COMPLETED.id,
  ];
  const config = {
    params: { search, page, limit: 20, status },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }), // allow query string with same key
  };
  const url = `projects/`;
  return loadOptions(url, config);
};

const loadOptions = async (url, data, filter) => {
  const {
    data: { results, meta },
  } = await authRequest.get(url, data);
  return {
    options: filter ? results.filter(filter) : results,
    hasMore: meta.nextPage !== null,
    additional: {
      page: meta.nextPage,
    },
  };
};
