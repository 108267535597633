import TreeItem from './TreeItem';
import Styles from './TreeView.module.scss';

const TreeView = ({ children }) => {
  return <ul className={Styles.container}>{children}</ul>;
};

TreeView.Item = TreeItem;

export default TreeView;
