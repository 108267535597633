import Styles from './GroupBox.module.scss';
import classNames from 'classnames';

const GroupBox = ({ header: Header, children, className }) => {
  return (
    <div className={classNames(Styles.card, className)}>
      {Header && <Header />}
      <div>{children}</div>
    </div>
  );
};

export default GroupBox;
