import Progress from '../components/Progress';
import Styles from './Layout.module.scss';

const Header = ({ progressValue = 0 }) => {
  return (
    <div className={Styles.header}>
      <Progress value={progressValue} />
    </div>
  );
};

export default Header;
