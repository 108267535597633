import { useState, useEffect } from 'react';
// import { generateUserAgreement } from '@apis/users';
import {
  // getAgreementLink,
  getBoldAgreementLink,
  getBoldAgreements,
  generateBoldAgreement,
  getBoldDownloadLink,
} from '@apis/agreements';
import { BoldAgreementStatus } from '@hiredigital/lib/helpers/enum';
import { useUser } from '@context/user';
import { openUrl } from '@hiredigital/lib/helpers/utils';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
// import toast from 'react-hot-toast';

const UserAgreement = ({ ...props }) => {
  const user = useUser();
  const [agreement, setAgreement] = useState();
  const [agreementLink, setAgreementLink] = useState();
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAgreements = async () => {
      try {
        const { data } = await getBoldAgreements(user?.uuid);
        const agr = data.results[0];
        setAgreement(agr);
        if (data.results.length === 0) {
          // const resp = await generateBoldAgreement(user?.uuid);
          // setAgreementLink(resp.data.signLink);
        } else {
          const resp = await getBoldAgreementLink(user?.uuid, agr.documentId);
          setAgreementLink(resp.data.signLink);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchAgreements();
  }, []);

  const handleCreateBoldAgreement = async () => {
    const resp = await generateBoldAgreement(user?.uuid);
    setAgreementLink(resp.data.signLink);
    openUrl(resp.data.signLink, { target: '_blank' });
  };

  return (
    <Card>
      <Card.Header title={`Platform Agreement`}></Card.Header>
      <Card.Item>
        {agreement?.status
          ? `The platform agreement is required for all talents on Hire Digital. ${
              BoldAgreementStatus.getEnum(agreement.status).userMessage
            }`
          : `The platform agreement is required for all talents on Hire Digital. Please take a moment to review the terms and sign the agreement.`}
      </Card.Item>
      <Card.Footer right>
        {agreement?.documentId && (
          <Button
            element='a'
            type={agreementLink ? Button.Type.LIGHT_GRAY : Button.Type.BLUE}
            href={getBoldDownloadLink(user?.uuid, agreement?.documentId)}
            target='_blank'>{`Download PDF`}</Button>
        )}

        {agreementLink && (
          <Button
            element='a'
            type={Button.Type.BLUE}
            href={agreementLink}
            target='_blank'>{`Sign Agreement`}</Button>
        )}
        {!agreement?.documentId && !agreementLink && (
          <Button
            type={Button.Type.BLUE}
            onClick={handleCreateBoldAgreement}>{`View Agreement`}</Button>
        )}
      </Card.Footer>
    </Card>
  );
};

export default UserAgreement;
