import { Fragment, useState, useEffect } from 'react';
import Button from '@hiredigital/ui/Button';
import { useUser } from '@context/user';
import { useHistory } from 'react-router-dom';
import {
  patchEngagementApplication,
  postEngagementApplicationSubmit,
  postEngagementApplicationFile,
  deleteEngagementApplicationFile,
  getEngagementApplication,
} from '@apis/engagements';
import QuillEditor from '@hiredigital/ui/Quill/Editor';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import { ApplicationStatus, UserStatus } from '@hiredigital/lib/helpers/enum';
import ApplicationNotes from './ApplicationNotes';
import Instructions from './Instructions';
import ConfirmationNotes from './ConfirmationNotes';
import References from '@hiredigital/ui/Form/References';
import OverviewContent from '../Briefs/components/overview/OverviewContent';
import OverviewHeader from '../Briefs/components/overview/OverviewHeader';
import Badge from '@hiredigital/ui/Badge/Badge';

import Styles from './Detail.module.scss';

const Application = ({ engagement, application, onAfterSubmit }) => {
  const user = useUser();
  const history = useHistory();

  const [content, setContent] = useState(application?.content || '');
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [attachments, setAttachments] = useState(application?.attachments || []);
  const [links, setLinks] = useState(application?.links || []);

  const handleConfirm = () => {
    updateApplication(() => {
      setIsSubmitting(true);
      postEngagementApplicationSubmit(engagement?.uuid, application?.uuid)
        .then(({ data }) => {
          setIsSubmitting(false);
          setIsShown(false);
          onAfterSubmit?.(data);
        })
        .catch((error) => {
          setIsSubmitting(false);
          setIsShown(false);
        });
    });
  };

  const updateApplication = (completion) => {
    setIsSaving(true);
    patchEngagementApplication(engagement?.uuid, application?.uuid, { content })
      .then(({ data }) => {
        setIsSaving(false);
        completion?.();
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  const handleSave = () => updateApplication();

  const handleSaveFile = async (engagementUuid, applicationUuid, fileData, config) => {
    const { data } = await postEngagementApplicationFile(
      engagementUuid,
      applicationUuid,
      fileData,
      config
    );
    setAttachments([...attachments, data]);
    return { data };
  };

  const handleDeleteFile = async (engagementUuid, applicationUuid, id) => {
    const { data } = await deleteEngagementApplicationFile(engagementUuid, applicationUuid, id);
    const updatedAttachments = attachments.filter((att, ind) => {
      return att.id !== id;
    });
    setAttachments([...updatedAttachments]);
    return { data };
  };

  return (
    <div className={Styles.applicationContainer}>
      <Dialog
        isOpen={isShown}
        width={400}
        title={`Submit Application`}
        onClose={() => setIsShown(false)}>
        <ConfirmationNotes />
        <div style={{ marginTop: '30px' }}>
          <Button onClick={handleConfirm} type={Button.Type.BLUE} isLoading={isSubmitting}>
            {`Submit Application`}
          </Button>
          <Button onClick={() => setIsShown(false)} type={Button.Type.LIGHT_GRAY}>
            {`Cancel`}
          </Button>
        </div>
      </Dialog>

      <div className={Styles.body}>
        <OverviewContent>
          <OverviewHeader withBorder enableEdit={false} title={'Your Application'}>
            <Badge color='neutral' marginLeft='auto'>
              {ApplicationStatus.getEnum(application?.status).statusLabel}
            </Badge>
          </OverviewHeader>

          <Instructions />
          <QuillEditor
            className={Styles.quill}
            inputClassName={Styles.input}
            name='guidelines'
            placeholder={`Enter past projects, roles and relevant URLs here (Optional)`}
            onChange={(e) => setContent(e.target.value)}
            toolbar={[
              ['bold', 'italic', 'underline'],
              ['blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              ['link', 'video', 'image'],
            ]}
            defaultValue={content}
            theme={'snow'}
            plugins={{
              fileDrop: true,
              magicUrl: true,
            }}
          />
          <References
            attachments={attachments}
            links={[]}
            postFileRequest={(data, config) =>
              handleSaveFile(engagement?.uuid, application?.uuid, data, config)
            }
            deleteFileRequest={(id) => handleDeleteFile(engagement?.uuid, application?.uuid, id)}
            disableAddLink={true}
          />
        </OverviewContent>
        <ApplicationNotes appStatus={application?.status} userStatus={user?.status} />
      </div>

      <div className={Styles.footer}>
        {application?.status === ApplicationStatus.DRAFT.id ? (
          <Fragment>
            <Button onClick={handleSave} type={Button.Type.BLUE_OUTLINE} isLoading={isSaving}>
              {`Save`}
            </Button>
            <Button onClick={() => setIsShown(true)} type={Button.Type.BLUE}>
              {`Submit Application`}
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Button onClick={handleSave} type={Button.Type.BLUE_OUTLINE} isLoading={isSaving}>
              {`Update`}
            </Button>
            {user?.status === UserStatus.ONBOARDING.id && (
              <Button onClick={() => history.push('/guide')} type={Button.Type.BLUE}>
                {`Continue Onboarding`}
              </Button>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Application;
