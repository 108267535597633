/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Loader from '@hiredigital/ui/Loader';
import Layout from '@components/Layout/AppLayout';

import { useOrg } from '@context/org';

const Index = () => {
  const history = useHistory();
  const org = useOrg();

  useEffect(() => {
    if (org?.uuid) {
      history.push(`/settings/clients/${org.uuid}/billing`);
    }
  }, [org]);

  return (
    <Layout>
      <Loader />
    </Layout>
  );
};

export default Index;
