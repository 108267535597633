import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { putTeam } from '@apis/teams';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import CurrencyDropdown from '@hiredigital/ui/CurrencyDropdown';

import Quill from '@hiredigital/ui/Quill/Editor';

const TeamProfile = ({ data }) => {
  const [team, setTeam] = useState(data);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setTeam(data);
  }, [data]);

  const handleSubmit = () => {
    const { name, description, social, languages, countries, confidential, topics, payment } = team;

    const payload = {
      name,
      description,
      social,
      languages,
      countries,
      confidential,
      topics,
      payment,
    };

    setIsSaving(true);
    putTeam(data?.uuid, payload)
      .then(({ data }) => {
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTeam({
      ...team,
      [name]: value,
    });
  };

  const handleSocialChange = (event) => {
    const { name, value } = event.target;
    setTeam({
      ...team,
      social: {
        ...team?.social,
        [name]: value,
      },
    });
  };

  const handleCurrencyChange = (event) => {
    const { value } = event.target;
    setTeam({
      ...team,
      payment: {
        currency: value,
      },
    });
  };

  return (
    <Card>
      <Card.Header title={`Team Profile`} />
      <Card.Item>
        <InputGroup>
          <InputContainer>
            <TextInput
              onChange={handleChange}
              label={`Team Name`}
              name={`name`}
              defaultValue={team?.name}
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Team Website`}
              name={`website`}
              defaultValue={team?.social?.website}
            />
          </InputContainer>
          <InputContainer>
            {team?.uuid && (
              <CurrencyDropdown
                type={CurrencyDropdown.Type.DEFAULT}
                defaultValue={team?.payment?.currency}
                onChange={handleCurrencyChange}
              />
            )}
          </InputContainer>
        </InputGroup>
        <InputContainer>
          <Quill
            legacyCompat
            onChange={handleChange}
            label={`Team Description`}
            name={`description`}
            defaultValue={team?.description}
          />
        </InputContainer>
        <InputGroup>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Facebook Page`}
              name={`facebook`}
              defaultValue={team?.social?.facebook}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Twitter Profile`}
              name={`twitter`}
              defaultValue={team?.social?.twitter}
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`LinkedIn Page`}
              name={`linkedin`}
              defaultValue={team?.social?.linkedin}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Google+ Profile`}
              name={`googlePlus`}
              defaultValue={team?.social?.googlePlus}
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Instagram Profile`}
              name={`instagram`}
              defaultValue={team?.social?.instagram}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Youtube Channel`}
              name={`youtube`}
              defaultValue={team?.social?.youtube}
            />
          </InputContainer>
        </InputGroup>
      </Card.Item>
      <Card.Footer right>
        <Button name='submit' onClick={handleSubmit} type={Button.Type.BLUE}>
          {isSaving && (
            <Loader
              style={{ marginRight: '10px' }}
              color={Loader.Color.WHITE}
              size={Loader.Size.SMALL}
            />
          )}
          {`Save Profile`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

TeamProfile.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    languages: PropTypes.array,
    topics: PropTypes.array,
    countries: PropTypes.array,
    social: PropTypes.object,
    confidential: PropTypes.bool,
  }),
};

export default TeamProfile;
