export const customStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  input: (styles) => ({
    ...styles,
    margin: 0,
    padding: 0,
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '100',
    margin: '2px 0',
  }),
  placeholder: (styles) => ({
    ...styles,
  }),
  control: (styles) => ({
    ...styles,
    boxShadow: 'none',
    ':hover': {
      borderColor: '#a1aab3',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '2px 15px',
    fontSize: '16px',
  }),
  singleValue: (styles) => ({
    ...styles,
    marginLeft: '0',
    fontSize: '16px',
  }),
};
