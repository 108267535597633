import { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import Menu from '@hiredigital/ui/Menu/Menu';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import { addHttpPrefix } from '@hiredigital/lib/helpers/utils';
import NewSingle from '@hiredigital/ui/Form/File/NewSingle';

import Toggle from 'react-toggle';
import { postPublication } from '@apis/publications';
import { PortfolioItemType } from '@hiredigital/lib/helpers/enum';
import { portfolioAcceptedFiles, getPortfolioThumbnail } from '@hiredigital/lib/helpers/utils';

import { PortfolioType, PortfolioResource } from './apiMapper';
import { onPublicationsLoadOptions, onCurrentUserTeamsLoadOptions } from '@apis/dropdown';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import TextInput from '@hiredigital/ui/Input/TextInput';
import TextArea from '@hiredigital/ui/Input/TextArea';
import Select from '@hiredigital/ui/Input/Select';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';

import Styles from './PortfolioEdit.module.scss';
import EditHeader from './EditHeader';
import EditFooter from './EditFooter';
import { authRequest } from '@apis/utils';

const MemoIconMoreVertical = memo(IconMoreVertical);

const PortfolioEdit = ({
  type,
  resourceUuid,
  item: initialItem,
  onSave,
  isLoading,
  onCancel,
  onDelete,
  onUrlSave,
  onCaseUpload,
  onCaseUpdate,
  onCaseDelete,
  onScreenshotUpload,
  onImageUpload,
  onFormChange,
  errors,
  showTagTeam = false,
}) => {
  const [item, setItem] = useState(initialItem);
  const [submitted, setSubmitted] = useState(false);
  // const [errors, setErrors] = useState({});

  const caseUploadElement = useRef();
  const urlRef = useRef();
  const caseImageElement = [];

  useEffect(() => {
    setItem(initialItem);
  }, [initialItem]);

  const addCustomPublication = (value) => {
    const data = { name: value };

    postPublication(data).then(({ data }) => {
      setItem({ ...item, publication: data });
    });
  };

  const setItemType = (type) => setItem({ ...item, type });

  const handleUrlChange = (event, prefix) => {
    const { name, value } = event.target;
    addPrefix(name, value, prefix);
  };

  const addPrefix = (name, value, prefix) => {
    const link = value ? addHttpPrefix(value, prefix) : '';
    if (urlRef && urlRef.current) {
      urlRef.current.value = link;
    }
    setItem({ ...item, [name]: link });
  };

  const handleUrlKeyPress = (event) => {
    // For passing up keypresses + autoselect
    // console.log('handleKeyPress');
    if (event.which === 13) {
      handleUrlSubmit(event);
    }
  };

  const handleUrlSubmit = (event) => {
    if (validateAddUrlForm()) {
      onUrlSave?.(item);
    }
  };

  const validateAddUrlForm = () => {
    let valid = true;
    if (!item.url) {
      setSubmitted(true);
      valid = false;
    }
    return valid;
  };

  const handleUploadError = (event) => {
    toast.error(
      'Select a valid file format. Only Images, PDFs, Documents and Videos formats allowed.'
    );
  };

  const handleUploadResponse = (data) => setItem(data);

  const handleChange = (event) => {
    const target = event.target;

    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const data = { ...item, [name]: value };
    onFormChange?.(data);
    setItem(data);
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    const data = { ...item, [name]: value };
    onFormChange?.();
    setItem(data);
  };

  const handleCaseChange = (event, caseItem) => {
    const { name, value } = event.target;
    const newCases = item.caseItems;
    caseItem[name] = value;

    const data = { ...item, caseItems: newCases };
    onFormChange?.(data);
    setItem(data);
  };

  const handleSave = () => {
    setSubmitted(true);
    console.log('handleSave', item);
    onSave?.(item);
    setSubmitted(false);
  };

  const handleCancel = () => {
    setSubmitted(false);
    onCancel?.();
  };

  const handleCaseUpload = (event) => {
    const files = event.target.files;
    onCaseUpload?.(files, item);
  };

  const handleCaseUpdate = (event, caseItem, index) => {
    const file = event.target.files[0];
    onCaseUpdate?.(file, item, caseItem);
  };

  const handleCaseDelete = (caseItem) => {
    setItem({ ...item, caseItems: item.caseItems.filter((v) => v.id !== caseItem.id) });
    onCaseDelete?.(item, caseItem);
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    onImageUpload?.(file, item);
  };

  const handleScreenshotUpload = (event) => {
    const file = event.target.files[0];
    onScreenshotUpload?.(file, item);
  };

  // const imageUrl = item?.image || item?.attachment || item?.pageScreenshot;
  const imageUrl = getPortfolioThumbnail(item);
  return (
    <div>
      <Dialog
        isOpen={true}
        topOffset={`auto`}
        padding={0}
        dialogProps={{ style: { overflow: 'auto' } }}
        hasClose={false}>
        <div className={Styles.modalContentArea}>
          <EditHeader
            item={item}
            type={type}
            onMenuSelect={setItemType}
            onImageUpload={handleImageUpload}
            onScreenshotUpload={handleScreenshotUpload}
          />

          <div className={Styles.modalFormArea}>
            {item.id && item.type !== PortfolioItemType.CASE_STUDY.id && (
              <div className={Styles.thumbnail}>
                <img src={imageUrl} alt='' className={Styles.thumbnailImage} />
              </div>
            )}

            <div
              className={`${Styles.content} ${
                item.type === PortfolioItemType.CASE_STUDY.id ? Styles.caseContent : ''
              }`}>
              {!item.id && (
                <div>
                  {item.type === PortfolioItemType.URL.id && (
                    <div className={Styles.urlForm}>
                      <div className={Styles.urlInputContainer}>
                        <TextInput
                          ref={urlRef}
                          name='url'
                          label='URL'
                          defaultValue={item.url}
                          onKeyPress={handleUrlKeyPress}
                          onChange={(e) => handleUrlChange(e, 'http://')}
                          error={(submitted && !item.url && 'Add a URL') || errors.url}
                        />
                      </div>
                      <Button
                        className={Styles.urlButton}
                        name='add'
                        type={Button.Type.BLUE}
                        onClick={handleUrlSubmit}>
                        {`Add Url`}
                      </Button>
                    </div>
                  )}
                  {item.type === PortfolioItemType.UPLOAD.id && (
                    <div>
                      <NewSingle
                        name='attachment'
                        label='Attachment'
                        httpRequest={authRequest}
                        server={`${process.env.API_ENDPOINT}/${PortfolioResource[type]}/${resourceUuid}/items/`}
                        idleLabel='Upload'
                        method='post'
                        accept={portfolioAcceptedFiles}
                        value={item.attachment}
                        onResponse={handleUploadResponse}
                        onDropRejected={handleUploadError}
                      />
                    </div>
                  )}
                </div>
              )}
              {item.id && item.type !== 3 && (
                <div>
                  <div className={Styles.editThumb}>
                    <div className={Styles.editCheckbox}>
                      <Toggle
                        id={`toggle_${item.id}`}
                        name='showThumbnail'
                        defaultChecked={item.showThumbnail}
                        onChange={handleChange}
                      />
                      <label className={Styles.editLabel} htmlFor={`toggle_${item.id}`}>
                        {`Show Thumbnail`}
                      </label>
                    </div>

                    <div className={Styles.editCheckbox}>
                      <Toggle
                        id={`toggle_pr_${item.id}`}
                        name='private'
                        defaultChecked={item.private}
                        onChange={handleChange}
                      />

                      <label className={Styles.editLabel} htmlFor={`toggle_pr_${item.id}`}>
                        {`Keep Private`}
                      </label>
                    </div>
                  </div>

                  {item.type === PortfolioItemType.UPLOAD.id && (
                    <InputContainer>
                      <NewSingle
                        name='attachment'
                        label='Attachment'
                        httpRequest={authRequest}
                        server={`${process.env.API_ENDPOINT}/${PortfolioResource[type]}/${resourceUuid}/items/${item.id}/`}
                        idleLabel='Upload'
                        method='patch'
                        value={item?.attachment}
                        onResponse={handleUploadResponse}
                        onDropRejected={handleUploadError}
                        accept={portfolioAcceptedFiles}
                        hideRemove
                      />
                    </InputContainer>
                  )}

                  {item.type === PortfolioItemType.URL.id && (
                    <InputContainer>
                      <TextInput
                        name='url'
                        label='URL'
                        onChange={(e) => handleUrlChange(e, 'http://')}
                        error={(submitted && !item.url && 'Add a URL') || errors.url}
                        value={item.url}
                      />
                    </InputContainer>
                  )}
                  <InputContainer>
                    <TextInput
                      name='title'
                      label='Title'
                      defaultValue={item.title}
                      onChange={handleChange}
                    />
                  </InputContainer>
                  <InputContainer>
                    <TextArea
                      name='content'
                      label='Description'
                      defaultValue={item.content}
                      onChange={handleChange}
                    />
                  </InputContainer>

                  {item.type === PortfolioItemType.UPLOAD.id && (
                    <InputContainer data-test-id='publication-id'>
                      <Select
                        className={Styles.select}
                        value={item.publication}
                        classNamePrefix='s-contact'
                        name='publication'
                        label='Publication'
                        getOptionLabel={(item) => {
                          return item.name || item.label;
                        }}
                        menuPlacement='top'
                        getOptionValue={({ id }) => id}
                        onChange={handleSelectChange}
                        onCreateOption={addCustomPublication}
                        isPaginated
                        isCreatable
                        loadOptions={onPublicationsLoadOptions}
                      />
                    </InputContainer>
                  )}
                  {/* 
                  <InputContainer>
                    <Select
                      className={Styles.select}
                      isClearable={false}
                      value={item.skills}
                      classNamePrefix='s-contact'
                      name='skills'
                      label='Skills'
                      menuPlacement='top'
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      //options={skills}
                      isMulti
                      onChange={handleSelectChange}
                      isPaginated
                      loadOptions={onSkillsLoadOptions}
                    />
                  </InputContainer> */}

                  {(type === PortfolioType.TEAM ||
                    (showTagTeam &&
                      [PortfolioItemType.UPLOAD.id, PortfolioItemType.URL.id].includes(
                        item.type
                      ))) && (
                    <InputContainer>
                      <Select
                        className={Styles.select}
                        isClearable={true}
                        value={item.teams}
                        classNamePrefix='s-contact'
                        name='teams'
                        label='Tag to a team'
                        menuPlacement='top'
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ uuid }) => uuid}
                        isMulti
                        onChange={handleSelectChange}
                        isPaginated
                        loadOptions={onCurrentUserTeamsLoadOptions}
                      />
                    </InputContainer>
                  )}
                </div>
              )}

              {item.type === PortfolioItemType.CASE_STUDY.id && (
                <div className={Styles.caseContainer}>
                  <div
                    className={Styles.caseUploader}
                    onClick={() => caseUploadElement.current.click()}>
                    <p>
                      <strong>Browse</strong> to upload
                    </p>
                    <input
                      className={Styles.hiddenInput}
                      type='file'
                      ref={caseUploadElement}
                      accept='image/*,application/pdf'
                      onChange={handleCaseUpload}
                      multiple
                    />
                  </div>
                  {item.id && (
                    <div className={Styles.casePrivate}>
                      <div className={Styles.editCheckbox}>
                        <Toggle
                          id={`toggle_pr_${item.id}`}
                          name='private'
                          defaultChecked={item.private}
                          onChange={handleChange}
                        />

                        <label className={Styles.editLabel} htmlFor={`toggle_pr_${item.id}`}>
                          {`Keep Private`}
                        </label>
                      </div>
                    </div>
                  )}

                  {item.caseItems && item.caseItems.length > 0 && (
                    <div className={Styles.caseList}>
                      {item.caseItems.map((caseItem, index) => (
                        <div key={caseItem.id} className={Styles.case}>
                          <div className={Styles.caseContent}>
                            <div>
                              <div className={Styles.caseUpload}>
                                <img src={caseItem.image} alt='' />
                                <input
                                  className={Styles.hiddenInput}
                                  type='file'
                                  ref={(input) => (caseImageElement[index] = input)}
                                  accept='image/*,application/pdf'
                                  onChange={(e) => handleCaseUpdate(e, caseItem, index)}
                                />
                              </div>
                              <div>
                                <Menu>
                                  <Menu.Button as='div'>
                                    <Button
                                      type={Button.Type.BLUE_OUTLINE}
                                      className={Styles.moreAction}>
                                      {`Modify`}
                                    </Button>
                                  </Menu.Button>
                                  <Menu.Items className={Styles.menuItems}>
                                    <Menu.Item
                                      as='a'
                                      target='_blank'
                                      href={caseItem.attachment}
                                      className={Styles.menuItem}>
                                      {'Download'}
                                    </Menu.Item>
                                    <Menu.Item
                                      as='div'
                                      onClick={() => caseImageElement[index].click()}
                                      className={Styles.menuItem}>
                                      {'Update'}
                                    </Menu.Item>
                                    <Menu.Item
                                      as='div'
                                      onClick={() => handleCaseDelete(caseItem)}
                                      className={Styles.menuItem}>
                                      {'Delete'}
                                    </Menu.Item>
                                  </Menu.Items>
                                </Menu>
                              </div>
                            </div>
                            <div className={Styles.caseDetail}>
                              <InputContainer>
                                <TextInput
                                  name='title'
                                  label='Title'
                                  value={caseItem.title}
                                  error={errors?.caseItems?.[index]?.title}
                                  onChange={(e) => handleCaseChange(e, caseItem)}
                                />
                              </InputContainer>
                              <InputContainer>
                                <TextArea
                                  name='description'
                                  label='Description'
                                  defaultValue={caseItem.description}
                                  onChange={(e) => handleCaseChange(e, caseItem)}
                                />
                              </InputContainer>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {isLoading && (
            <div className={Styles.overlayLoader}>
              <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} />
            </div>
          )}
          <EditFooter item={item} onDelete={onDelete} onCancel={handleCancel} onSave={handleSave} />
        </div>
      </Dialog>
    </div>
  );
};

PortfolioEdit.propTypes = {
  resourceUuid: PropTypes.string,
  item: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onUrlSave: PropTypes.func,
  onCaseUpload: PropTypes.func,
  onCaseDelete: PropTypes.func,
  onScreenshotUpload: PropTypes.func,
  onImageUpload: PropTypes.func,
  errors: PropTypes.object,
};

export default PortfolioEdit;
