import PropTypes from 'prop-types';
import Card from '@hiredigital/ui/Card';

import { convertToCurrency } from '@hiredigital/lib/helpers/utils';
import Styles from './Styles.module.scss';

const ListItem = ({ data, currency }) => {
  return (
    <Card.Item flex>
      <div className={Styles.contentLeft}>
        <p className={Styles.textBold}>{data.description || `Credits`}</p>
        <p className={Styles.reference}>{data.reference}</p>
      </div>
      <div className={Styles.contentRight}>
        <p className={Styles.textBold}>{convertToCurrency(data.remainingCredit, currency)}</p>
      </div>
    </Card.Item>
  );
};

ListItem.propTypes = {
  data: PropTypes.object,
  currency: PropTypes.shape({
    rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.string,
  }),
};

export default ListItem;
