/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { getProject } from '@apis/projects';
import { ProjectProvider } from '@context/projects';
import { DiscussionProvider } from '@context/discussion';
import Layout from '@components/Layout/AppLayout';
import Project from '@components/Projects/Project';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import Loader from '@hiredigital/ui/Loader';
import Styles from './Styles.module.scss';

const ProjectDetail = ({ ...props }) => {
  const { uuid } = useParams();
  const history = useHistory();

  const [project, setProject] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const loadData = async (projectUuid) => {
    if (!projectUuid) return;
    try {
      setIsLoading(true);
      const response = await getProject(projectUuid);
      setProject(response.data);
      setPageTitle(`${response?.data?.title || 'New Project'} - Hire Digital`);
    } catch (e) {
      toast.error('Project not found.', { id: 'notFound' });
      history.push('/projects');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData(uuid);
  }, [uuid]);

  return (
    <ProjectProvider>
      <DiscussionProvider>
        <Layout
          style={{ backgroundColor: 'white' }}
          scrollContainerClass={Styles.fullPageLayout}
          bodyClass={Styles.wrapper}>
          <div style={{ display: 'flex', width: '100%', height: '100%', position: 'relative' }}>
            {isLoading ? (
              <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} type={Loader.Type.FULL} />
            ) : (
              project && <Project project={project} onAfterSubmit={setProject} />
            )}
          </div>
        </Layout>
      </DiscussionProvider>
    </ProjectProvider>
  );
};

export default ProjectDetail;
