import { authRequest } from './utils';

const serializerAssignment = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

const serializeProject = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

export const postAssignmentNewProject = async (
  uuid,
  data,
  serialize = serializeProject,
  config = undefined
) => {
  const url = `assignments/${uuid}/new-project/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postAssignmentRehire = async (uuid, config = undefined) => {
  const url = `assignments/${uuid}/rehire/`;
  return await authRequest.post(url, {}, config);
};

export const getAssignment = async (uuid, config = undefined) => {
  const url = `assignments/${uuid}/`;
  return await authRequest.get(url, config);
};

export const postAssignment = async (
  data,
  serialize = serializerAssignment,
  config = undefined
) => {
  const url = `assignments/`;
  return await authRequest.post(url, serialize(data), config);
};

export const patchAssignment = async (
  uuid,
  data,
  serialize = serializerAssignment,
  config = undefined
) => {
  const url = `assignments/${uuid}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const putAssignment = async (
  uuid,
  data,
  serialize = serializerAssignment,
  config = undefined
) => {
  const url = `assignments/${uuid}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const deleteAssignment = async (uuid, data, config = undefined) => {
  const url = `assignments/${uuid}/`;
  return await authRequest.delete(url, config);
};
