import { memo } from 'react';

import Styles from './Styles.module.scss';

const Text = ({ message, ...props }) => {
  return (
    <div className={Styles.ql}>
      <div className={Styles.content} dangerouslySetInnerHTML={{ __html: message?.content }} />
    </div>
  );
};

export default memo(Text);
