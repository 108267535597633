import React, { memo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Position from 'evergreen-ui/esm/constants/src/Position';
import { Tooltip } from 'evergreen-ui/esm/tooltip';
import Menu from '@hiredigital/ui/Menu/Menu';

// import Icon from '@hiredigital/ui/Icon/Icon';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import IconNotification from '@hiredigital/ui/Icon/icons/notification.inline.svg';

import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';

import { postUserNotificationsMarkRead } from '@apis/users';
import { useUser } from '@context/user';
import { useNotifications } from '@context/notifications';

import Notification from './Notification';
import Styles from './Notifications.module.scss';
import SideStyles from '../Nav/Nav.module.scss';

const NotificationIcon = memo(IconNotification);

const Notifications = memo(({ open, mobileOpen }) => {
  const {
    state: { notifications, meta, isLoading, unread },
    updateLoading,
    loadNotifications,
    updateUnread,
  } = useNotifications();

  const user = useUser();

  // NEW
  const listRef = useRef({});
  const rowHeights = useRef({});

  const [loadNotifLater, setLoadNotifLater] = useState(false);

  const loadNotifNextPage = async () => {
    updateLoading(true);
    try {
      await loadNotifications({ page: meta.nextPage });
    } catch (error) {
      console.error(error);
    }
    updateLoading(false);
  };

  useEffect(() => {
    if (loadNotifLater && !isLoading) {
      loadNotifNextPage();
      setLoadNotifLater(false);
    }
  }, [loadNotifLater, isLoading]);

  function getRowHeight(index) {
    return rowHeights.current[index] || 80;
  }

  function setRowHeight(index, size) {
    listRef.current._listRef.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  const loadMoreNotifications = async (startIndex, stopIndex) => {
    if (stopIndex + 10 >= notifications.length && meta.nextPage) {
      if (isLoading) {
        setLoadNotifLater(true);
      } else {
        await loadNotifNextPage();
      }
    }
  };

  const isItemLoaded = (index) => {
    if (notifications?.[index]?.id) {
      return true;
    } else {
      return false;
    }
  };

  const handleNotificationOpen = () => {
    // reload the notifications
    loadNotifications(/* params= */ undefined, /* reset= */ true)
      .catch((e) => console.error(e))
      .finally(() => {
        // mark all unread as read
        if (unread > 0) {
          postUserNotificationsMarkRead(user?.uuid)
            .then(() => updateUnread(0))
            .catch((e) => console.error(e));
        }
      });
  };

  return (
    <Menu className={SideStyles.nonCritical}>
      <Menu.Button as='div' onClick={handleNotificationOpen} className={Styles.fullWidth}>
        <Tooltip
          content={'Notifications'}
          position={Position.RIGHT}
          isShown={open || mobileOpen ? false : undefined}>
          <div className={classNames(SideStyles.item, Styles.fullWidth)}>
            {unread > 0 ? (
              <span className={Styles.count}>{unread > 99 ? '99+' : unread}</span>
            ) : (
              <NotificationIcon className={SideStyles.icon} />
            )}
            <div
              className={classNames(
                SideStyles.label,
                open && SideStyles.open
              )}>{`Notifications`}</div>
          </div>
        </Tooltip>
      </Menu.Button>
      <Menu.Items
        direction='right'
        className={classNames(Styles.notifMenuItems, mobileOpen && Styles.mobileOpen)}>
        <div className={Styles.container}>
          <div className={Styles.headline}>
            {`Notifications`}
            <Menu.Button className={Styles.close}>
              <IconClose className={Styles.closeIcon} />
            </Menu.Button>
          </div>
          <div className={Styles.body}>
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  ref={listRef}
                  isItemLoaded={isItemLoaded}
                  itemCount={notifications.length + 1}
                  loadMoreItems={loadMoreNotifications}>
                  {({ onItemsRendered, ref }) => (
                    <List
                      className={Styles.list}
                      height={height}
                      itemCount={notifications.length + 1}
                      ref={ref}
                      width={width}
                      onItemsRendered={onItemsRendered}
                      overscanCount={10}
                      itemSize={getRowHeight}>
                      {({ index, style }) => (
                        <Notification
                          style={style}
                          index={index}
                          notification={notifications?.[index]}
                          setRowHeight={setRowHeight}
                        />
                      )}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </div>
        </div>
      </Menu.Items>
    </Menu>
  );
});
Notifications.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
};

export default Notifications;
