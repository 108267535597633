import Styles from './MultiLineEllipsis.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const MultiLineText = ({
  children,
  truncateLine = 2,
  elem: Elem = 'div',
  style,
  className,
  ...props
}) => (
  <Elem
    className={classNames(Styles.multilineText, className)}
    style={{ WebkitLineClamp: truncateLine, ...style }}
    {...props}>
    {children}
  </Elem>
);

MultiLineText.propTypes = {
  children: PropTypes.node,
  truncateLine: PropTypes.number,
  Elem: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default MultiLineText;
