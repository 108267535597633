import { Fragment, useEffect, useState, memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import IconMenu from '@hiredigital/ui/Icon/icons/menu.inline.svg';
import IconLogout from '@hiredigital/ui/Icon/icons/logout.inline.svg';
import IconQuestion from '@hiredigital/ui/Icon/icons/question.inline.svg';
import IconTimesheet from '@hiredigital/ui/Icon/icons/timesheet.inline.svg';
import IconChat from '@hiredigital/ui/Icon/icons/chat.inline.svg';
import IconGuide from '@hiredigital/ui/Icon/icons/guide.inline.svg';
import IconBrief from '@hiredigital/ui/Icon/icons/brief.inline.svg';
import IconProject from '@hiredigital/ui/Icon/icons/project.inline.svg';

import Logo from '@hiredigital/ui/Logo';
import SmallLogo from '@hiredigital/ui/Logo/SmallLogo';
import { useUser } from '@context/user';
import { useOrg } from '@context/org';
import { useTeam } from '@context/team';
import { useCommon } from '@context/common';
import { useChatController, useChatControllerDispatch } from '@context/chatController';
import { isTalent } from '@helpers/permissions';
import { UserStatus } from '@hiredigital/lib/helpers/enum';
import useWindowSize from '../../../shared/hooks/useWindowSize';

import Item from './Item';
import UserTeamDropdown from '../UserDropdown/UserTeamDropdown';
import ProfileDropdown from '../UserDropdown/ProfileDropdown';
import OrgDropdown from '../TeamOrgDropdown/OrgDropdown';
import TeamDropdown from '../TeamOrgDropdown/TeamDropdown';
import SettingsDropdown from '../SettingsDropdown/SettingsDropdown';
import CompanyDropdown from '../CompanyDropdown/CompanyDropdown';
import Notifications from '../Notifications/Notifications';

import * as intercom from '../../../../archive/lib/intercom';

import Styles from './Nav.module.scss';

const MemoIconClose = memo(IconClose);
const MemoIconMenu = memo(IconMenu);
const MemoIconDown = memo(IconDown);

const Side = ({ ...props }) => {
  const size = useWindowSize();
  const user = useUser();
  const org = useOrg();
  const team = useTeam();
  const common = useCommon();
  const [open, setOpen] = useState(
    common?.sidebarOpen || localStorage.getItem('isSidebarOpen') === 'true' ? true : false
  );
  const [mobileOpen, setMobileOpen] = useState(false);
  const [intercomShown, setIntercomShown] = useState(false);
  const chatListDispatch = useChatControllerDispatch();
  const chatList = useChatController();

  const handleOpen = (e) => {
    const newOpen = !open;
    localStorage.setItem('isSidebarOpen', newOpen);
    // dispatch({ type: 'UPDATE', payload: { ...common, sidebarOpen: newOpen } });
    setOpen(newOpen);
  };

  const toggleChatList = (e) => {
    if (chatList.isChatListShown) {
      chatListDispatch({ type: 'HIDE_LIST' });
    } else {
      chatListDispatch({ type: 'SHOW_LIST' });
    }
  };

  const handleMenuToggle = () => {
    const newOpen = !mobileOpen;
    setMobileOpen(newOpen);
  };

  const toggleSupport = () => {
    if (intercomShown) {
      intercom.hide();
      setIntercomShown(false);
    } else {
      intercom.show();
      setIntercomShown(true);
    }
  };

  useEffect(() => {
    if (size.width) {
      if (size.width > 599) {
        // Close automatically if window gets too large
        setMobileOpen(false);
      }
    }
  }, [size]);

  const isActive = (user) => {
    if (!user) return false;
    return [
      UserStatus.ACTIVE.id,
      UserStatus.LEGACY_ACTIVE.id,
      UserStatus.NETWORK_ACTIVE.id,
    ].includes(user?.status);
  };

  return (
    <div
      className={classNames(
        Styles.container,
        open ? Styles.open : 'not_opened',
        mobileOpen && Styles.mobileOpen
      )}>
      <div className={Styles.menu}>
        <div className={Styles.mobileMenuBar} onClick={handleMenuToggle}>
          <Link className={Styles.menuLogoLink} to='/' title='Hire Digital'>
            <Logo className={Styles.menuLogo} site={'2'} />
          </Link>
          {mobileOpen ? (
            <MemoIconClose className={Styles.menuIcon} />
          ) : (
            <MemoIconMenu className={Styles.menuIcon} />
          )}
        </div>
        <div className={Styles.menuItems}>
          <Link to='/' title='Hire Digital'>
            <Logo className={classNames(Styles.nonCritical, Styles.logo)} site={'2'} />
            <SmallLogo className={classNames(Styles.nonCritical, Styles.smallLogo)} />
          </Link>

          {common?.isOrgRequested && org ? (
            <CompanyDropdown open={open} mobileOpen={mobileOpen} />
          ) : (
            common?.isUserRequested &&
            user && (
              <UserTeamDropdown open={open} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
            )
          )}

          {common?.isUserRequested &&
            isTalent(user) &&
            user?.status === UserStatus.ONBOARDING.id && (
              <Item href='/guide' icon={IconGuide} label={`Getting Started`} open={open} />
            )}

          {common?.isUserRequested && isTalent(user) && user?.teams?.length > 0 && (
            <ProfileDropdown open={open} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
          )}

          {org ? (
            <Item href='/briefs' icon={IconBrief} label={`Briefs`} open={open} />
          ) : (
            <Item href='/engagements' icon={IconBrief} label={`Engagements`} open={open} />
          )}

          <Item href='/projects' icon={IconProject} label={`Projects`} open={open} />

          {(common?.isUserRequested || common?.isOrgRequested) && (
            <Fragment>
              {((user?.isTalent && user?.hasAssignments) ||
                (org && org.viewTimesheets && org.isManager)) && (
                <Item href='/timesheets' icon={IconTimesheet} label={`Timesheets`} open={open} />
              )}
            </Fragment>
          )}
          <Item icon={IconChat} label={`Discussion`} open={open} onClick={() => toggleChatList()} />
        </div>
        <div className={Styles.menuSpacer} onClick={handleOpen} />
        <div className={Styles.menuItems} onClick={(e) => e.stopPropagation()}>
          {common?.isUserRequested && user && <Notifications open={open} mobileOpen={mobileOpen} />}
          {common?.isOrgRequested && org && <OrgDropdown open={open} mobileOpen={mobileOpen} />}
          {common?.isTeamRequested && team && (
            <TeamDropdown open={open} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
          )}
          <SettingsDropdown open={open} mobileOpen={mobileOpen} />
          <Item onClick={toggleSupport} icon={IconQuestion} label={`Ask Support`} open={open} />

          <Item href='/logout' icon={IconLogout} label={`Sign Out`} open={open} isMobile={true} />
        </div>
      </div>
      <div className={Styles.toggle} onClick={handleOpen}>
        <MemoIconDown
          className={classNames(Styles.toggleIcon, open ? Styles.left : Styles.right)}
        />
      </div>
    </div>
  );
};

Side.propTypes = {
  currentUser: PropTypes.object,
};

export default memo(Side);
