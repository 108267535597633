import PropTypes from 'prop-types';
import Styles from './ConfirmAvailability.module.scss';

const Footer = ({ children }) => {
  return <div className={Styles.footer}>{children}</div>;
};

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
