import { useState, memo } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'universal-cookie';

import Nav from './Nav/Nav';
import Header from './Header/Header';

import ChatManager from '@components/Chat/ChatManager';
import UserSocketManager from '@components/UserSocket/UserSocketManager';

import Styles from './Layout.module.scss';

const cookies = new Cookies();

const AppLayout = ({
  location,
  children,
  bodyClass,
  bodyClassName,
  header,
  footer,
  onLoad,
  getScrollContainer,
  scrollContainerClass,
  headerContainerClass,
  style,
  className,
}) => {
  const [isLoaded, setIsLoaded] = useState(true);

  cookies.set('platform', 'application');

  return (
    <div className={classNames(Styles.container, className)} style={style}>
      <Nav />
      <div
        id='bodyContainer'
        className={classNames(Styles.body, scrollContainerClass)}
        ref={getScrollContainer}>
        {header && <Header containerClass={headerContainerClass}>{header}</Header>}
        <div
          id='scrollContainer'
          className={classNames(Styles.main, Styles.children, bodyClass, bodyClassName)}>
          {isLoaded && children}
        </div>
        {footer}
      </div>
      <UserSocketManager />
      <ChatManager />
      {/* <ChatList /> */}
    </div>
  );
};

AppLayout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node,
  bodyClass: PropTypes.string,
  header: PropTypes.node,
  onLoad: PropTypes.func,
};

export default memo(AppLayout);
