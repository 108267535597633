import format from 'date-fns/format';
import { Currency } from '@hiredigital/lib/helpers/enum';

export const acceptedFiles = [
  'image/*',
  'application/zip',
  'application/pdf',
  'video/*',
  'application/vnd.ms-excel',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/docx',
  'application/doc',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const portfolioAcceptedFiles = [
  'image/*',
  'application/pdf',
  'video/*',
  'application/msword', //.doc
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/docx',
  'application/doc',
];

export const COOKIE_DOMAIN = {
  domain: process.env.COOKIE_DOMAIN ? process.env.COOKIE_DOMAIN : '',
  httpOnly: false,
  sameSite: false,
  path: '/',
};

export const PROFILE_PATH = 'settings';
export const ONBOARDING_PATH = 'onboarding';

export const isOfTypeUuId = (value) => {
  const accessKeyRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
  return accessKeyRegex.test(value);
};

// export const toReadableDate = (date, fmt) => {
//   return format(new Date(date), fmt || 'dd MMM yyyy');
// };

export const dateSort = (a, b) => {
  const bEnd = b.endYear ? new Date(b.endYear, b.endMonth).valueOf() : new Date().valueOf();
  const aEnd = a.endYear ? new Date(a.endYear, a.endMonth).valueOf() : new Date().valueOf();
  return bEnd < aEnd ? -1 : 1;
};

export const setValue = (entry, value) => {
  if (entry === undefined) {
    // eslint-disable-next-line
    console.warn({ err: new Error('entry is undefined on setValue') });
    return '';
  }
  // eslint-disable-next-line
  entry = entry.replace(/(\{(\w+)\})/g, (match, grp, key) => {
    const ret = value[key];
    if (ret !== null && ret !== undefined) {
      return ret;
    }
    return match;
  });

  return entry;
};

export const replaceJson = (jsonFile) => {
  let entry = JSON.stringify(jsonFile);
  const replacement = {
    siteName: `${process.env.SITE_NAME}`,
    contactEmail: `${process.env.CONTACT_EMAIL}`,
    month: format(new Date(), 'MMM yyyy'),
  };

  // const replacementKeys = Object.keys(replacement);
  entry = entry.replace(/(\{(\w+)\})/g, (match, grp, key) => {
    const ret = replacement[key];

    if (ret !== null && ret !== undefined) {
      return ret;
    }
    return match;
  });
  return JSON.parse(entry);
};

export const isValidImage = (imageUrl) => {
  return imageUrl.match(/\.(jpeg|JPEG|JPG|GIF|PNG|svg|SVG|WEBP|webp|jpg|gif|png)$/) != null;
};

export const removeNullProps = (obj) => {
  const returnObj = {};
  Object.entries(obj).forEach(([idx, v]) => {
    if (Array.isArray(v)) {
      if (v.length) returnObj[idx] = v;
    } else {
      if (!!v) returnObj[idx] = v;
    }
  });
  return returnObj;
};

// export const formatNumber = (v, decimalPlaces = 2) => {
//   return parseFloat(v)
//     .toFixed(decimalPlaces)
//     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
// };

// export const formatCurrency = (v, decimalPlaces = 2, symbol = '$') => {
//   return `${symbol}${formatNumber(v, decimalPlaces)}`;
// };

// export const calculateConversion = (amount, rate) => {
//   Math.log10 =
//     Math.log10 ||
//     function (x) {
//       return Math.log(x) * Math.LOG10E;
//     };

//   let decimalPlaces = 0;
//   let baseAmount = 1 * rate;
//   let roundingDigits = parseInt(Math.log10(baseAmount));
//   if (Math.abs(baseAmount) < 1) {
//     roundingDigits -= 1;
//   }
//   let roundingDigitsAmount = Math.pow(10, roundingDigits);
//   let roundingAmount = Math.round(baseAmount / roundingDigitsAmount) * roundingDigitsAmount;

//   if (rate < 1.5 && rate > 0.5 && (amount * rate) % 1 !== 0) {
//     decimalPlaces = 2;
//     roundingAmount = 0.01;
//   }

//   return formatNumber(
//     Math.abs(Math.round((amount * rate) / roundingAmount) * roundingAmount),
//     decimalPlaces
//   );
// };

// export const getCurrencyIcon = (code) => Currency.getEnumByKey(code, 'code')?.icon || '';

// export const convertToCurrency = (amount = 0, currency = {}, showCode = false) => {
//   const code = showCode ? currency?.code || '' : '';
//   if (Object.keys(currency).length) {
//     if (amount) {
//       return `${currency?.icon || getCurrencyIcon(currency?.code)}${calculateConversion(
//         amount,
//         currency?.rate
//       )} ${code}`.trim();
//     }
//     return `${currency?.icon || getCurrencyIcon(currency?.code)}0 ${code}`.trim();
//   }

//   return `S$${formatNumber(amount, 0)} ${code}`.trim();
// };

// export const convertNumberToText = (v) => {
//   const special = [
//     'zeroth',
//     'first',
//     'second',
//     'third',
//     'fourth',
//     'fifth',
//     'sixth',
//     'seventh',
//     'eighth',
//     'ninth',
//     'tenth',
//     'eleventh',
//     'twelfth',
//     'thirteenth',
//     'fourteenth',
//     'fifteenth',
//     'sixteenth',
//     'seventeenth',
//     'eighteenth',
//     'nineteenth',
//   ];
//   const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

//   if (v < 20) return special[v];
//   if (v % 10 === 0) return deca[Math.floor(v / 10) - 2] + 'ieth';

//   return deca[Math.floor(v / 10) - 2] + 'y-' + special[v % 10];
// };

// export const addHttpPrefix = (value, prefix) => {
//   // Need to add prefix if we don't have http:// prefix already AND we don't have part of it
//   if (
//     value &&
//     !/^(https?):\/\//i.test(value) &&
//     `http://${prefix}`.indexOf(value) !== 0 &&
//     `https://${prefix}`.indexOf(value) !== 0
//   ) {
//     return `${prefix}${value}`;
//   }
//   return value;
// };

// export const socialPrefixes = {
//   linkedin: 'https://www.linkedin.com/in/',
//   twitter: 'https://twitter.com/',
//   facebook: 'https://www.facebook.com/',
//   instagram: 'https://www.instagram.com/',
// };

export const removeEmptyProps = (params) => {
  const newParams = {};
  Object.keys(params).forEach((v) => {
    if (!!params[v] && params[v] !== 'undefined' && params[v] !== 'null') {
      newParams[v] = params[v];
    }
  });
  return newParams;
};

// export const formatBytes = (size, decimals = 2) => {
//   if (!size) return '0 Bytes';

//   const k = 1024;
//   const dm = decimals < 0 ? 0 : decimals;
//   const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

//   const i = Math.floor(Math.log(size) / Math.log(k));

//   return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + units[i];
// };

// export const openUrl = (url, target = '_blank') => {
//   const a = document.createElement('a');
//   a.setAttribute('href', url);
//   a.setAttribute('target', target);
//   document.body.appendChild(a);
//   a.click();
//   a.remove();
// };
