import { removeBaseUrl } from '@hiredigital/lib/helpers/utils';

export const transformToValidPath = (url) => {
  const path = removeBaseUrl(url).replace('/api/v1', '');
  return path;
};

export const viewNotification = (notification) => {
  console.log('notification', notification);
  if (notification.organization) {
    // FIXME: Add org change
    console.log(notification.organization);
  }
  window.location.href = transformToValidPath(notification.url);
};
