import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import ImageDrop from '../Form/File/ImageDrop';
import { putOrgPicture } from '@apis/organizations';

const CompanyLogo = ({ orgUuid, data }) => {
  const refDropzone = useRef();
  const [picture, setPicture] = useState(data?.picture);
  const [isSaving, setIsSaving] = useState(false);

  const handleRemoveLogo = () => {
    setIsSaving(true);
    putOrgPicture(orgUuid, { picture: null })
      .then(({ data }) => {
        setIsSaving(false);
        setPicture(data?.picture);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  const handleOpenDialog = () => refDropzone?.current?.open();

  return (
    <Card>
      <Card.Header title={`Company Logo`} />
      <Card.Item>
        <div>
          <ImageDrop
            ref={refDropzone}
            className={Styles.logo}
            name='picture'
            label='Picture'
            server={`orgs/${orgUuid}/`}
            idleLabel='Update Picture'
            value={picture}
            onResponse={({ data }) => setPicture(data?.picture)}
          />
        </div>
      </Card.Item>
      <Card.Footer right>
        <Button name='submit' onClick={handleRemoveLogo} type={Button.Type.WHITE}>
          {isSaving && (
            <Loader
              style={{ marginRight: '10px' }}
              color={Loader.Color.WHITE}
              size={Loader.Size.SMALL}
            />
          )}
          {`Remove Logo`}
        </Button>
        <Button name='submit' onClick={handleOpenDialog} type={Button.Type.BLUE}>
          {`Update Logo`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

CompanyLogo.propTypes = {
  orgUuid: PropTypes.string,
  data: PropTypes.shape({
    picture: PropTypes.string,
  }),
};

export default CompanyLogo;
