/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import Menu from '@hiredigital/ui/Menu/Menu';

import Button from '@hiredigital/ui/Button';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import IconAttachment from '@hiredigital/ui/Icon/icons/attachment.inline.svg';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';

import QuillEditor from '@hiredigital/ui/Quill/Editor';

import { useDiscussion } from '@context/discussion';
import { useUser } from '@context/user';
import Styles from './NewMessage.module.scss';

import IconCheck from '@hiredigital/ui/Icon/icons/check-normal.inline.svg';
import { useChatSettings } from '@context/chatSettings';

const NewMessage = ({
  discussion,
  sendMessageClass,
  onAddAttachment,
  isBottomPadded = false,
  listRef,
  ...props
}) => {
  const { isEnterToSend, setIsEnterToSend } = useChatSettings();

  const { addMessage } = useDiscussion();
  const user = useUser();
  const ref = useRef();
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState('');
  const [plainText, setPlainText] = useState('');
  const [isTall, setIsTall] = useState(false);

  const removeLineBreak = (v) => v.replace(/<p><br><\/p>$/, '');

  const submitMessage = async () => {
    if (isSending || !message.trim()) return;
    if (!plainText.trim()) {
      setMessage('');
      return;
    }
    setIsSending(true);
    const data = {
      content: isEnterToSend ? removeLineBreak(message) : message,
    };
    setMessage('');
    await addMessage(discussion.id, data);
    setIsSending(false);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    let { current: scrollContainer } = listRef;
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
    setPlainText(event.target.plainText);
  };

  const onEnterDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      submitMessage();
      return false;
    }
  };

  useEffect(() => {
    const { current: editorRef } = ref;
    if (isEnterToSend && editorRef) {
      editorRef.addEventListener('keydown', onEnterDown);
      return () => editorRef.removeEventListener('keydown', onEnterDown);
    }
  }, [ref, isEnterToSend, message]);

  return (
    <div className={classNames(Styles.newContainer, sendMessageClass)} ref={ref}>
      <IconDown
        className={classNames(Styles.arrow, isTall && Styles.up)}
        onClick={() => setIsTall(!isTall)}
      />
      <QuillEditor
        className={classNames(Styles.newMessage, isTall && Styles.tall)}
        inputClassName={Styles.input}
        name='message'
        placeholder={`Add your message here`}
        onChange={handleChange}
        onSubmit={submitMessage}
        value={message}
        mentionList={discussion.participants}
        theme={'snow'}
        plugins={{
          mention: true,
          fileDrop: true,
          magicUrl: true,
        }}
        currentUser={user}
        disableEnter={isEnterToSend}
      />

      <div className={classNames(Styles.actionRow, isBottomPadded && Styles.bottomPadding)}>
        <Button className={Styles.smallButton} onClick={onAddAttachment}>
          <IconAttachment className={Styles.upload} />
        </Button>
        <div style={{ marginLeft: 'auto' }}>
          {isEnterToSend ? (
            `Press Enter to Send`
          ) : (
            <Button type={Button.Type.BLUE} className={Styles.submitButton} onClick={submitMessage}>
              {`Send`}
            </Button>
          )}
        </div>
        <Menu>
          <Menu.Button className={Styles.smallButton}>
            <IconMoreVertical className={Styles.more} />
          </Menu.Button>
          <Menu.Items as='div' direction='top' className={Styles.menuItems}>
            <Menu.Item
              as='div'
              className={classNames(Styles.menuItem, isEnterToSend && Styles.active)}
              onClick={() => setIsEnterToSend(true)}>
              <IconCheck className={Styles.icon} />
              {'Press Enter To Send'}
            </Menu.Item>
            <Menu.Item
              as='div'
              className={classNames(Styles.menuItem, !isEnterToSend && Styles.active)}
              onClick={() => setIsEnterToSend(false)}>
              <IconCheck className={Styles.icon} />
              {'Click To Send'}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  );
};

export default NewMessage;
