import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';

import Styles from './PortfolioViewer.module.scss';

const Footer = memo(({ item, isFirst, isLast, onPrev, onNext }) => {
  return (
    <div className={Styles.navFooter}>
      <button onClick={onPrev} className={Styles.nav}>
        <IconDown className={classNames(Styles.navIcon, Styles.prev)} />
        {`Previous Project`}
      </button>
      <button onClick={onNext} className={Styles.nav}>
        {`Next Project`}
        <IconDown className={classNames(Styles.navIcon, Styles.next)} />
      </button>
    </div>
  );
});

Footer.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
};

export default Footer;
