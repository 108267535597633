import { memo } from 'react';
import classNames from 'classnames';
import Styles from './Styles.module.scss';

import { createSelectable } from 'react-selectable-fast';

const Item = memo(({ selectableRef, isSelected, isSelecting, isDisabled, day, currentDay }) => {
  return (
    <div
      ref={selectableRef}
      className={classNames(
        Styles.day,
        isSelected && Styles.isSelected,
        isSelecting && Styles.isSelecting,
        currentDay && Styles.currentDate,
        isDisabled ? `not-selectable ${Styles.disabled}` : ''
      )}>
      <div className={classNames(Styles.dateText, currentDay && Styles.currentDateText)}>{day}</div>
      {isSelected && <div className={Styles.selectedText}>Available</div>}
    </div>
  );
});

export default createSelectable(Item);
