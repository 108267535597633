import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCommon } from '@context/common';
import Layout from '@components/Layout/AppLayout';
import { getTeam } from '@apis/teams';
import TeamLogo from '@components/TeamLogo/TeamLogo';
import TeamProfile from '@components/TeamProfile/TeamProfile';
import ServiceRateList from '@components/ServiceRate/ServiceRateList';
import TalentRateList from '@hiredigital/ui/TalentRateList';
import { RatesApiManager } from '@hiredigital/lib/apis/class/rates';
import { authRequest } from '@apis/utils';
import PortfolioList from '@components/PortfolioList/PortfolioList';

const TeamDetailPage = ({ ...props }) => {
  const common = useCommon();
  const { uuid } = useParams();
  const [team, setTeam] = useState();
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getTeam(uuid);
        setTeam(response?.data);
      } catch (e) {
        console.error(e);
      }
    };
    if (uuid) {
      loadData();
    }
  }, [uuid]);

  return (
    <Layout>
      <div id='profile' />
      <TeamLogo data={team} />
      <TeamProfile data={team} />
      {team && (
        <>
          <div id='service-rate' />
          {/^true$/i.test(process.env.NEW_RATES_FLAG) ? (
            <TalentRateList
              type={TalentRateList.Type.TEAM}
              apiManager={new RatesApiManager(authRequest)}
              resourceUuid={team?.uuid}
              common={common}
            />
          ) : (
            <ServiceRateList resourceUuid={team?.uuid} type={ServiceRateList.Type.TEAM} />
          )}
          <div>
            <h2>{`Portfolio`}</h2>
            <PortfolioList type={PortfolioList.Type.TEAM} resourceUuid={team?.uuid} />
          </div>
        </>
      )}
    </Layout>
  );
};

export default TeamDetailPage;
