import { Fragment, useState, useEffect } from 'react';
import Styles from './TalentSelector.module.scss';
import Avatar from '@hiredigital/ui/Avatar';
import classNames from 'classnames';
import Badge from '@hiredigital/ui/Badge/Badge';

import Loader from '@hiredigital/ui/Loader';
// import Icon from '@hiredigital/ui/Icon/Icon';

import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';

import { getOrgTalentAssignments } from '@apis/organizations';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { AssignmentStatus } from '@hiredigital/lib/helpers/enum';

const AssignmentsList = ({ orgUuid, isShown, talent, onAssignmentSelect, ...props }) => {
  const [assignments, setAssignments] = useState([]);
  const [meta, setMeta] = useState({ nextPage: 1 });
  const [isNextLoading, setIsNextLoading] = useState(false);

  const loadAssignments = () => {
    const params = { page: meta?.nextPage, isProjectView: true };
    setIsNextLoading(true);
    getOrgTalentAssignments(orgUuid, talent?.uuid, { params })
      .then(({ data }) => {
        setIsNextLoading(false);
        setMeta(data?.meta);
        setAssignments([...assignments, ...data?.results]);
      })
      .catch((error) => {
        console.error(error);
        setIsNextLoading(false);
      });
  };

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index) => !meta?.nextPage || index < assignments.length;

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = meta?.nextPage ? assignments.length + 1 : assignments.length;

  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextLoading ? () => {} : loadAssignments;

  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    if (!isItemLoaded(index)) {
      return (
        <div className={Styles.assignmentItem} style={style}>
          <Loader color={Loader.Color.BLUE} size={Loader.Size.SMALL} />
        </div>
      );
    }

    const row = assignments[index];
    return (
      <div className={Styles.assignmentItem} onClick={() => onAssignmentSelect(row)} style={style}>
        <p>{row?.title || 'Untitled'}</p>
      </div>
    );
  };

  return (
    <div
      className={Styles.assignmentsList}
      style={{ display: isShown ? 'block' : 'none' }}
      {...props}>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}>
        {({ onItemsRendered, ref }) => (
          <List
            height={120}
            itemCount={itemCount}
            itemSize={50}
            onItemsRendered={onItemsRendered}
            ref={ref}>
            {Item}
          </List>
        )}
      </InfiniteLoader>
    </div>
  );
};

const AssignmentDropdown = ({ orgUuid, talent, onAssignmentSelect }) => {
  const [isListShown, setIsListShown] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    if (isListShown) {
      setShouldFetch(true); // this is to prevent the http request from sending while dropdown list is not yet visible
    }

    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isListShown]);

  const handleClickOutside = () => {
    if (isListShown) {
      setIsListShown(false);
    }
  };

  return (
    <div className={Styles.assignmentsContainer} onClick={(e) => e.stopPropagation()}>
      <button onClick={() => setIsListShown(!isListShown)} className={Styles.actionDropdown}>
        <IconDown className={Styles.moreIcon} />
      </button>
      {shouldFetch && (
        <AssignmentsList
          isShown={isListShown}
          onAssignmentSelect={onAssignmentSelect}
          talent={talent}
          onClick={() => setIsListShown(!isListShown)}
          orgUuid={orgUuid}
        />
      )}
    </div>
  );
};

const Item = ({
  orgUuid,
  item,
  isSelected,
  onSelect,
  onDeselect,
  onAssignmentSelect,
  showAssignments = true,
  isClickable = true,
  className,
}) => {
  const [assignment, setAssignment] = useState(item?.assignment);

  const handleItemClick = () => {
    if (isSelected) {
      onDeselect?.(item);
      return;
    }

    if (assignment?.uuid) {
      item.assignmentUuid = assignment?.uuid;
    }

    onSelect?.(item);
  };

  const handleSelectAssignment = (assignment) => {
    setAssignment(assignment);
    onAssignmentSelect?.(assignment, item);
  };

  return (
    <div
      onClick={handleItemClick}
      className={classNames(
        Styles.item,
        isSelected && Styles.selected,
        !item.isActive && Styles.inactive,
        !isClickable && Styles.notClickable,
        className
      )}>
      <div className={Styles.detailArea}>
        <Avatar
          style={{ marginRight: '10px', height: '38px', width: '38px' }}
          name={item?.team?.name || item?.user?.name}
          hash={item?.team?.uuid || item?.user?.uuid}
          src={item?.team?.picture || item?.user?.picture}
          tooltipText={item?.user?.isProjectManager ? `Project Manager` : `Talent`}
          tooltipPosition={`top`}
          showTooltip
        />
        <div className={Styles.itemText}>
          <span className={Styles.itemName}>{item?.team?.name || item?.user?.name}</span>
          {item?.assignmentCount > 1 && !!assignment?.uuid && (
            <span className={Styles.assignmentTitle}>{assignment?.title || 'Untitled'}</span>
          )}
        </div>
      </div>
      {showAssignments && item?.isActive ? (
        <Fragment>
          {item?.assignmentCount > 1 && (
            <AssignmentDropdown
              orgUuid={orgUuid}
              talent={item}
              onAssignmentSelect={handleSelectAssignment}
            />
          )}
        </Fragment>
      ) : (
        <Fragment>
          {item?.assignment?.status === AssignmentStatus.INACTIVE.id && (
            <Badge color='neutral' margin='auto 10px auto auto'>
              {`Inactive`}
            </Badge>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Item;
