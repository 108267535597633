import PropTypes from 'prop-types';
import Button from '@hiredigital/ui/Button';
import ModalStyles from '../../styles/Modal.module.scss';

const EditFooter = ({ item, onDelete, onCancel, onSave }) => {
  return (
    <div className={ModalStyles.modalFooter}>
      {item.id && (
        <Button name='save' type={Button.Type.BLUE} onClick={onSave}>
          {`Save`}
        </Button>
      )}

      <Button name='cancel' type={Button.Type.GRAY} onClick={() => onCancel?.()}>
        {`Cancel`}
      </Button>

      <div className={ModalStyles.modalAction}>
        {item.id && (
          <Button name='delete' type={Button.Type.WHITE} onClick={() => onDelete?.(item)}>
            {`Delete`}
          </Button>
        )}
      </div>
    </div>
  );
};

EditFooter.propTypes = {
  item: PropTypes.object,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default EditFooter;
