import { memo, useState, useEffect } from 'react';
import classNames from 'classnames';

import { useUser } from '@context/user';
import { getOrgTimetrackerTimesheet, getOrgTimetrackerTask } from '@apis/organizations';

import { secondsToDuration, toReadableMedDateTime } from '@hiredigital/lib/helpers/date';
import Button from '@hiredigital/ui/Button';
import Caret from '@hiredigital/ui/Icon/icons/caret.inline.svg';
import Loader from '@hiredigital/ui/Loader';
import Ellipsis from '@hiredigital/ui/MultiLineEllipsis/SingleLine';

import Styles from './Styles.module.scss';

const CaretButton = ({ style, visible, onClick }) => (
  <Button style={style} className={Styles.caretButton} onClick={onClick} type={Button.Type.WHITE}>
    <Caret className={classNames(Styles.iconExpand, visible || Styles.collapsed)} />
  </Button>
);

const Row = ({ children, gray }) => (
  <div
    style={{ display: 'flex' }}
    className={classNames(Styles.fd, Styles.fd4, gray && Styles.gray)}>
    {children}
  </div>
);

const LoaderRow = () => (
  <Row>
    <Loader size={Loader.Size.SMALL} type={Loader.Type.FULL} />
  </Row>
);

const BlankRow = ({ style }) => (
  <Row gray={true}>
    <p style={{ width: '100%', ...style }} className={Styles.fd1}>
      No entries found
    </p>
  </Row>
);

const EntryRow = ({ title, duration, gray }) => (
  <Row gray={gray}>
    <div style={{ width: '80%' }} className={Styles.fd1}>
      {title}
    </div>
    <div style={{ width: '20%', justifyContent: 'center' }} className={Styles.fd1}>
      {duration}
    </div>
  </Row>
);

const TimesheetColumn = ({ data }) => {
  const talentName = data?.userName || 'No talent name';
  return (
    <div className={Styles.timesheetColumn}>
      <img src={data?.userPicture} alt={talentName} className={Styles.picture} />
      <Ellipsis>{talentName}</Ellipsis>
    </div>
  );
};

const TimesheetRow = ({ data, filter }) => {
  const user = useUser();
  const timesheetUuid = data?.uuid;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  // Note: When the time filter changes, we will reload the task list but keep it open.
  useEffect(() => {
    if (!visible) return setList([]);

    setLoading(true);
    const uuid = user?.orgs?.[0]?.uuid || user?.uuid;
    if (!uuid) return setLoading(false);

    getOrgTimetrackerTimesheet(uuid, timesheetUuid, { params: filter })
      .then((response) => setList(response?.data?.tasks || []))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [visible, timesheetUuid, user, filter]);

  const handleShow = (e) => {
    setVisible((v) => !v);
  };

  return (
    <>
      <EntryRow
        title={
          <>
            <CaretButton visible={visible} onClick={handleShow} />
            <Ellipsis>{data?.title}</Ellipsis>
          </>
        }
        duration={secondsToDuration(data?.totalDuration)}
        gray={(data?.totalDuration || 0) == 0}
      />
      {visible &&
        (loading ? (
          <LoaderRow />
        ) : list?.length ? (
          list?.map((data, idx) => <TimesheetSubRow key={idx} data={data || {}} filter={filter} />)
        ) : (
          <BlankRow style={{ marginLeft: '42px' }} />
        ))}
    </>
  );
};

const TimesheetSubRow = ({ data, filter }) => {
  const user = useUser();
  const taskUuid = data?.uuid;

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  // Note: When the time filter changes, we will close the list of events.
  useEffect(() => {
    setVisible(false);
  }, [filter]);

  useEffect(() => {
    if (!visible) return setList([]);

    setLoading(true);
    const uuid = user?.orgs?.[0]?.uuid || user?.uuid;
    if (!uuid) return setLoading(false);

    getOrgTimetrackerTask(uuid, taskUuid, { params: filter })
      .then((response) => setList(response?.data?.events || []))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [visible, taskUuid, user]);

  const handleShow = (e) => {
    setVisible((v) => !v);
  };

  return (
    <>
      <EntryRow
        title={
          <>
            <CaretButton style={{ marginLeft: '20px' }} visible={visible} onClick={handleShow} />
            <Ellipsis>{data?.name}</Ellipsis>
          </>
        }
        duration={<span>{secondsToDuration(data?.totalDuration)}</span>}
        gray={(data?.totalDuration || 0) == 0}
      />
      {visible &&
        (loading ? (
          <LoaderRow />
        ) : list?.length ? (
          list?.map((data, idx) => <TaskSubRow key={idx} data={data} />)
        ) : (
          <BlankRow style={{ marginLeft: '62px', fontWeight: 400 }} />
        ))}
    </>
  );
};

const TaskSubRow = ({ data }) => (
  <EntryRow
    title={
      <span style={{ marginLeft: '62px', fontWeight: 400 }}>
        <Ellipsis>{toReadableMedDateTime(data.startTime)}</Ellipsis>
      </span>
    }
    duration={<span style={{ fontWeight: 400 }}>{secondsToDuration(data?.duration)}</span>}
    gray={(data?.duration || 0) == 0}
  />
);

const MemoTimesheetColumn = memo(TimesheetColumn);

const ClientTimesheetEntry = ({ data, filter }) => (
  <>
    <EntryRow
      title={<MemoTimesheetColumn data={data} />}
      duration={secondsToDuration(data?.totalDuration)}
      gray={(data?.totalDuration || 0) == 0}
    />
    {data?.timesheets?.length ? (
      data?.timesheets.map((data, idx) => (
        <TimesheetRow key={idx} data={data || {}} filter={filter} />
      ))
    ) : (
      <BlankRow style={{ marginLeft: '42px' }} />
    )}
  </>
);

export default ClientTimesheetEntry;
