import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'evergreen-ui/esm/tooltip';
import { Link } from 'react-router-dom';

import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { convertToCurrency } from '@hiredigital/lib/helpers/utils';
import { RateType } from '@hiredigital/lib/helpers/enum';
import References from '@hiredigital/ui/Form/References';

import { useCommon } from '@context/common';

import OverviewHeader from '../Briefs/components/overview/OverviewHeader';
import OverviewItem from '../Briefs/components/overview/OverviewItem';
import OverviewContent from '../Briefs/components/overview/OverviewContent';
import OverviewBody from '../Briefs/components/overview/OverviewBody';
import OverviewColumn from '../Briefs/components/overview/OverviewColumn';

import Styles from './Detail.module.scss';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

const Engagement = ({ engagement, application, leftWidth = 65, rightWidth = 35 }) => {
  const common = useCommon();
  const [showRateTip, setShowRateTip] = useState(false);

  const formatItems = (items, key) => {
    return !!items?.length ? items?.map((v) => v[key || 'label'])?.join(', ') : 'No preference';
  };

  const formatTimeLine = (e) => {
    const start = e?.estimatedStartDate ? `${toReadableDate(e?.estimatedStartDate)} - ` : '';
    const end = e?.estimatedEndDate ? toReadableDate(e?.estimatedEndDate) : 'Ongoing';
    return `${start}${end}`;
  };

  const formatRate = (rate = 0, rateType) => {
    return `${convertToCurrency(parseFloat(rate), common?.defaultCurrency, true)} ${
      RateType.getEnum(rateType).value
    }`;
  };

  return (
    <OverviewContent>
      <OverviewHeader
        title={engagement?.title || engagement?.altTitle || `New Engagement`}
        enableEdit={false}
        withBorder
      />
      <OverviewBody>
        <OverviewColumn width={leftWidth}>
          {engagement?.responsibilities && (
            <OverviewItem
              title={`Responsibilities`}
              content={engagement?.responsibilities}
              isHtml
            />
          )}
          {engagement?.requirements && (
            <OverviewItem title={`Requirements`} content={engagement?.requirements} isHtml />
          )}
          <References
            className={Styles.attachments}
            attachments={engagement.attachments}
            links={engagement.links}
            showActions={false}
          />

          {engagement?.talentTerms && (
            <OverviewItem title={`Engagement Terms`} content={engagement?.talentTerms} isHtml />
          )}
        </OverviewColumn>
        <OverviewColumn width={rightWidth}>
          {common?.isReady &&
            ((!isNewRates && application?.talentRate > 0) ||
              (isNewRates && application?.talentEngagementRate?.rateDisplayText)) && (
              <OverviewItem title={`Rate`}>
                <Tooltip
                  isShown={showRateTip}
                  appearance='card'
                  content={
                    <div className={Styles.rateTip}>
                      <p>
                        {`If your profile matches the engagement requirements closely, but your rate `}
                        {`does not meet the stated rate, please update your `}
                        <Link to={`/profile/availability/settings`}>
                          <a className={Styles.link}>{`Rates`}</a>
                        </Link>
                        {` and submit your application. `}
                        {`If necessary, our talent team will get in touch to discuss further.`}
                      </p>
                      <p style={{ marginTop: '20px' }}>
                        {`See `}
                        <a
                          href={`https://hiredigital.com/talent/faq`}
                          className={Styles.link}>{`FAQ`}</a>
                        {` for more information on Rates.`}
                      </p>
                    </div>
                  }>
                  <span
                    onClick={() => {
                      setShowRateTip(!showRateTip);
                    }}
                    style={{ cursor: 'pointer' }}>
                    {isNewRates
                      ? application?.talentEngagementRate?.rateDisplayText
                      : formatRate(application?.talentRate, application?.talentRateType)}
                  </span>
                </Tooltip>
              </OverviewItem>
            )}

          {(engagement?.estimatedStartDate || engagement?.estimatedEndDate) && (
            <OverviewItem title={`Timeline`} content={formatTimeLine(engagement)} />
          )}
          {engagement?.skills?.length > 0 && (
            <OverviewItem title={`Experience`} content={formatItems(engagement?.skills)} />
          )}
          <OverviewItem
            title={`Language`}
            content={
              engagement?.languages?.length > 0
                ? formatItems(engagement?.languages, 'name')
                : 'No Preference'
            }
          />
          <OverviewItem
            title={`Country`}
            content={
              engagement?.countries?.length > 0
                ? formatItems(engagement?.countries, 'name')
                : 'No Preference'
            }
          />
          {engagement?.location && (
            <OverviewItem title={`Location`} content={engagement?.location} isHtml />
          )}
        </OverviewColumn>
      </OverviewBody>
    </OverviewContent>
  );
};

Engagement.propTypes = {
  children: PropTypes.node,
};

export default Engagement;
