import { useEffect, useState, memo, useCallback } from 'react';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import format from 'date-fns/format';
import EditorStatusBar from './components/EditorStatusBar';
import { useQuill } from 'react-quilljs';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import { saveAttachment } from '@apis/common';
import debounce from 'lodash/debounce';
import { useProjects } from '@context/projects';
import Toolbar from './components/Toolbar';
import Styles from './TextEditor.module.scss';
import { useNetworkStatus } from '@context/network';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'align',
];

const TextEditor = memo(({ content, projectUuid }) => {
  const {
    updateFile,
    deleteFile,
    setHistoryContentId,
    files,
    setFiles,
    filesEdited,
    setFilesEdited,
  } = useProjects();
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: '#toolbar',
    },
    formats,
  });

  const history = useHistory();
  const { isOnline } = useNetworkStatus();

  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [isFresh, setIsFresh] = useState(true);

  // lastUpdated field seems to have delay issue so modified field was used here instead
  const [lastSaved, setLastSaved] = useState(content?.modified);

  // Insert Image(selected by user) to quill
  const insertLinkToEditor = (url, preview) => {
    const range = quill.getSelection();

    const fileName = url.substr(url.lastIndexOf('/') + 1);
    quill.insertText(range.index, fileName, 'link', url);

    if (preview.match(/\.(gif|jpe?g|a?png|svg|webp|bmp)/i)) {
      // Show preview if preview is image
      quill.insertText(range.index, '\n');
      quill.insertEmbed(range.index, 'image', preview);
    }
  };

  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
  const saveToServer = async (file) => {
    try {
      const { data } = await saveAttachment(file);
      insertLinkToEditor(data.attachment, data.preview);
    } catch (err) {
      console.error(err);
    }
  };

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', 'true');
    input.setAttribute('accept', 'image/*, application/pdf');
    input.click();
    input.onchange = () => {
      [].forEach.call(input.files, function (file) {
        saveToServer(file);
      });
    };
  };

  const fileDropHandler = (files, callback) => {
    [].forEach.call(files, function (file) {
      if (file) {
        const blob = file.getAsFile ? file.getAsFile() : file;
        saveToServer(blob);
      }
    });
  };

  const registerFileHandlers = useCallback(() => {
    // Add custom handler for Image Upload
    quill.getModule('toolbar').addHandler('image', selectLocalImage);
    if (quill.getModule('fileDrop')) {
      quill.getModule('fileDrop').options.fileHandler = fileDropHandler;
    }
  }, [quill]);

  const calculateWords = useCallback(() => {
    const text = quill.getText()?.trim();
    const count = text?.length > 0 ? text.split(/\s+/).length : 0;
    setWordCount(count);
  }, [quill]);

  const pasteContent = useCallback(
    (data) => {
      // Paste initial value
      if (quill && data) {
        quill.clipboard.dangerouslyPasteHTML(data);
        calculateWords();
      }
    },
    [quill, calculateWords]
  );

  const saveContent = useCallback(
    debounce(async (contentId, payload) => {
      try {
        setIsSaving(true);
        const data = await updateFile(projectUuid, contentId, payload, {}, false);
        setHasChanges(!data?.id);
        setLastSaved(data?.modified);
      } catch (err) {
        console.error(err);
      } finally {
        setIsSaving(false);
      }
    }, 500),
    [quill, updateFile, files, setIsSaving, setFiles, projectUuid]
  );

  const handleViewHistory = () => setHistoryContentId(content.id);

  const handleDownloadAll = () => {
    const url = `${process.env.API_ENDPOINT}/projects/${projectUuid}/content/generate-zip/`;
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
    a.remove();
  };

  const handleDownload = () => {
    const url = `${process.env.API_ENDPOINT}/projects/${projectUuid}/content/${content?.id}/generate-docx/`;
    const a = document.createElement('a');
    a.href = url;
    a.download = content?.title;
    a.target = '_blank';
    a.click();
    a.remove();
  };

  const handleDelete = () => deleteFile(projectUuid, content?.id);

  const saveHotKey = (e) => {
    const shouldSave = ['s', 'S'].includes(e.key) && (e.metaKey || e.ctrlKey);
    if (shouldSave) {
      e.preventDefault();
      const payload = generateEditorPayload();
      saveContent(content.id, payload);
    }
  };

  const handleRouteChange = useCallback(
    (location, action) => {
      if (hasChanges) {
        return window.confirm(
          'You have unsaved changes. Are you sure you want to leave this page?'
        );
      }
      return true;
    },
    [hasChanges]
  );

  const generateEditorPayload = () => {
    const title = quill.getText()?.split('\n')[0];
    const html = quill.container.firstChild.innerHTML;
    return { content: html, title };
  };

  useEffect(() => {
    if (quill) {
      registerFileHandlers();
    }
  }, [quill, registerFileHandlers]);

  useEffect(() => {
    if (quill) {
      pasteContent(content?.content);
      setLastSaved(content.modified);
      setIsLoaded(true);
    }
  }, [quill, content, pasteContent]);

  useEffect(() => {
    if (quill) {
      quill.on('text-change', (e, v, source) => {
        if (source === 'user') {
          // trigger auto save on user input only
          setHasChanges(true);
          setIsSaving(true);
          const payload = generateEditorPayload();
          saveContent(content?.id, payload);
          calculateWords();
        }
      });

      return () => quill.off('text-change');
    }
  }, [quill]);

  useEffect(() => {
    if (isLoaded) {
      window.addEventListener('keydown', saveHotKey);
    }

    return () => {
      window.removeEventListener('keydown', saveHotKey);
    };
  }, [isLoaded]);

  useEffect(() => {
    if (!isSaving) return;
    if (!filesEdited.includes(content.id)) {
      setFilesEdited((prev) => [...prev, content.id]);
    }
  }, [isSaving]);

  useEffect(() => {
    setIsFresh(!filesEdited.includes(content.id));
  }, [filesEdited, content]);

  useEffect(() => {
    if (!quill) return;

    quill.enable();

    if (!isOnline) {
      quill.disable();
      return;
    }
  }, [quill, isOnline, content?.id]);

  useEffect(() => {
    const unblock = history.block(handleRouteChange);
    return () => {
      unblock();
    };
  }, [handleRouteChange]);

  useEffect(() => {
    if (!hasChanges) return;
    const handleBeforeUnload = (evt) => {
      evt.preventDefault();
      evt.returnValue = ''; // Chrome requires setting this.
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasChanges]);

  return (
    <div className={classNames(Styles.editorContainer, { [Styles.disabled]: !isOnline })}>
      <div className={Styles.toolsContainer}>
        <Toolbar />
        <SplitButton
          more={[
            {
              text: 'View History',
              onClick: handleViewHistory,
            },
            {
              text: 'Download Original',
              onClick: handleDownload,
            },
            {
              text: 'Download All Files',
              onClick: handleDownloadAll,
            },
            {
              text: 'Delete',
              onClick: handleDelete,
            },
          ]}
        />
      </div>
      <div ref={quillRef} />
      <EditorStatusBar
        {...{ wordCount, isSaving, isOnline }}
        savedText={
          isFresh
            ? `Last saved at ${format(new Date(lastSaved), 'h:mm a EEE, d MMM')}`
            : `Saved ${formatDistanceToNow(new Date(lastSaved), {
                addSuffix: true,
                includeSeconds: true,
              })}`
        }
      />
    </div>
  );
});

export default TextEditor;
