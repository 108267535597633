import { Fragment, useState, useEffect } from 'react';
import Item from './Item';
import Styles from './List.module.scss';

import { postProjectMember, deleteProjectMember, postProjectAddTalent } from '@apis/projects';
import { postAssignmentRehire } from '@apis/assignments';
import { getOrgTalents, getOrgTalentAssignments } from '@apis/organizations';
import Dialog from '@hiredigital/ui/Dialog/Dialog';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import Select from '@hiredigital/ui/Input/Select';
import Badge from '@hiredigital/ui/Badge/Badge';

import classNames from 'classnames';
import { AssignmentStatus } from '@hiredigital/lib/helpers/enum';
import { useOrg } from '@context/org';
import { useCommon } from '@context/common';
import { useHistory } from 'react-router-dom';

const TalentOption = ({ data: { isProjectManager, user }, innerRef, innerProps, isSelected }) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={classNames(Styles.option, { [Styles.selected]: isSelected })}>
      {user?.name || ''}
      {isProjectManager && <Badge color='green' className={Styles.badge}>{`Manager`}</Badge>}
    </div>
  );
};

const TalentForm = ({ project, onSelectTalent }) => {
  const onOrgTalentOptions = async (search, loadedOptions, { page }) => {
    const data = { params: { search, page, limit: 20 } };
    const response = await getOrgTalents(project?.organization?.uuid, data);
    return {
      options: response.data.results,
      hasMore: response.data.meta.nextPage !== null,
      additional: {
        page: response.data.meta.nextPage,
      },
    };
  };

  return (
    <div>
      <Select
        label={`Talents`}
        name={`talents`}
        className={Styles.selectOrgTalent}
        placeholder={`Select an existing talent`}
        loadOptions={onOrgTalentOptions}
        getOptionLabel={({ user, team }) => team?.name || user?.name}
        getOptionValue={({ user, team }) => team?.uuid || user?.uuid}
        hideSelectedOptions={false}
        onChange={onSelectTalent}
        value={null}
        menuPlacement={`top`}
        components={{ Option: TalentOption }}
        isPaginated
      />
    </div>
  );
};

const AssigmentList = ({ project, talent, onItemSelect }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [assignment, setAssignment] = useState({});

  useEffect(() => {
    getOrgTalentAssignments(project?.organization?.uuid, talent?.uuid)
      .then(({ data }) => {
        setAssignments(data?.results || []);
        setIsLoaded(true);
      })
      .catch((error) => {
        setIsLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (v) => {
    setAssignment(v);
    onItemSelect?.(v);
  };

  return (
    <div className={Styles.assignmentList}>
      {isLoaded && (
        <Fragment>
          {assignments?.map((v) => (
            <div
              key={v?.uuid}
              onClick={() => handleSelect(v)}
              className={classNames(
                Styles.assignmentItem,
                v?.uuid === assignment?.uuid && Styles.selected,
                v?.status === AssignmentStatus.INACTIVE.id && Styles.inactive
              )}>
              <p>{v?.title}</p>
              <p className={Styles.assignmentLabel}>{v?.engagement?.talentType?.label}</p>
            </div>
          ))}
        </Fragment>
      )}
      {!isLoaded && <Loader color={Loader.Color.BLUE} size={Loader.Size.SMALL} />}
    </div>
  );
};

const List = ({ project, isEditable = true }) => {
  const common = useCommon();
  const org = useOrg();
  const history = useHistory();
  const [isShownAssignments, setIsShownAssignments] = useState(false);
  const [isShownRehire, setIsShownRehire] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isRehiring, setIsRehiring] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [members, setMembers] = useState(project?.members?.filter((v) => !!v?.assignment) || []);
  const [member, setMember] = useState(null);
  const [assignment, setAssignment] = useState({});

  const hasNoOrgTalents =
    !org || Object.keys(org).length === 0 || (common?.isOrgRequested && !org.hasTalents);

  const handleRemove = (data) => {
    deleteProjectMember(project?.uuid, data?.id)
      .then(() => {
        setMembers(members.filter((v) => v.id !== data?.id));
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const handleSelectTalent = (talent) => {
    const hasMultipleAssignment = talent?.assignmentCount > 1;
    setMember(talent);

    if (hasMultipleAssignment) {
      setIsShownAssignments(true);
      return;
    }

    const assign = { uuid: talent?.assignmentUuid };
    setAssignment(assign);
    addMember(talent, assign);
  };

  const postProjectTalent = (talent, assign) => {
    const payload = { assignment: assign?.uuid };
    if (talent?.team) payload.talent = talent?.uuid;
    if (talent?.user) payload.user = talent?.user?.uuid;

    const request = talent?.team ? postProjectAddTalent : postProjectMember;

    setIsSaving(true);
    request(project?.uuid, payload)
      .then(({ data }) => {
        setMembers([...members, data]);
        setIsAdding(false);
        setIsSaving(false);
        setIsShownAssignments(false);
      })
      .catch((error) => {
        console.warn(error);
        setIsSaving(false);
        setIsShownAssignments(false);
      });
  };

  const addMember = (talent, assign) => {
    if (!talent?.isActive) {
      setIsShownAssignments(false);
      setIsShownRehire(true);
      return;
    }
    postProjectTalent(talent, assign);
  };

  const handleSelectAssignment = () => addMember(member, assignment);

  const handleRehire = () => {
    setIsRehiring(true);
    Promise.all([postProjectTalent(member, assignment), postAssignmentRehire(assignment?.uuid)])
      .then(() => {
        setIsShownRehire(false);
        setIsRehiring(false);
      })
      .catch((error) => {
        setIsShownRehire(false);
        setIsRehiring(false);
      });
  };

  const handleAddBrief = async () => {
    history.push(`/briefs/new`);
  };

  return members?.length === 0 && hasNoOrgTalents ? (
    <Fragment>
      <p className={Styles.emptyText}>{`You currently have no talents to add to this project.`}</p>
      <Button
        isLoading={isAdding}
        onClick={handleAddBrief}
        type={Button.Type.BLUE}
        className={Styles.actionHire}>
        {`Hire a New Talent`}
      </Button>
    </Fragment>
  ) : (
    <Fragment>
      <div className={Styles.container}>
        <Dialog
          isOpen={isShownAssignments}
          title={`${member?.team?.name || member?.user?.name} has multiple assignments`}
          description={`Select an assignment.`}
          onClose={() => setIsShownAssignments(false)}
          minWidth={500}>
          <div style={{ marginBottom: 16 }}>
            <AssigmentList
              project={project}
              talent={member}
              onItemSelect={(item) => setAssignment(item)}
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            <Button
              disabled={!assignment?.uuid}
              type={Button.Type.BLUE}
              onClick={handleSelectAssignment}
              isLoading={isSaving}>
              {'Select Assignment'}
            </Button>
            <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsShownAssignments(false)}>
              {'Cancel'}
            </Button>
          </div>
        </Dialog>

        <Dialog
          isOpen={isShownRehire}
          title={`Rehire Talent?`}
          onClose={() => setIsShownRehire(false)}
          minWidth={430}>
          <p>
            {`If you would like to work with `}
            <b>{member?.team?.name || member?.user?.name}</b>
            {`, you can request to Rehire them. We will check on the talent's availability and confirm with you shortly.`}
          </p>
          <p style={{ margin: '15px 0' }}>
            {`Meanwhile, you can continue to create your project.`}
          </p>
          <div style={{ marginTop: '20px' }}>
            <Button type={Button.Type.BLUE} onClick={handleRehire} isLoading={isRehiring}>
              {'Rehire'}
            </Button>
            <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsShownRehire(false)}>
              {'Cancel'}
            </Button>
          </div>
        </Dialog>

        {members?.map((v, idx) => (
          <Item key={idx} data={v} onRemove={handleRemove} isEditable={isEditable} />
        ))}

        {isAdding ? (
          <Fragment>
            {isSaving ? (
              <Loader color={Loader.Color.BLUE} size={Loader.Size.SMALL} />
            ) : (
              <TalentForm project={project} onSelectTalent={handleSelectTalent} />
            )}
            <button className={Styles.btnAction} onClick={() => setIsAdding(false)}>
              {`Cancel`}
            </button>
          </Fragment>
        ) : (
          <Fragment>
            {isEditable && (
              <button className={Styles.btnAction} onClick={() => setIsAdding(true)}>
                {`Add New Talent`}
              </button>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default List;
