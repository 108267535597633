/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Styles from './ShowAsIframe.module.scss';
import axios from 'axios';

const ShowAsIframe = ({ url, size = 0, className }) => {
  const [previewExists, setPreviewExists] = useState(false);
  const [videoExists, setVideoExists] = useState(false);
  const [trustedUrl, setTrustedUrl] = useState(undefined);
  const [htmlPreview, setHtmlPreview] = useState('');
  const [htmlExists, setHtmlExists] = useState(false);

  useEffect(() => {
    renderUrl(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const renderUrl = (url) => {
    const t = /.+\.([^?]+)(\?|$)/;
    const matches = url.match(t);
    const extension = matches.length ? matches[1]?.toLowerCase() : null;

    const videoFile = ['mov', 'mp4', 'webm', 'ogg'];
    const googleFile = ['eps', 'ps', 'psd', 'psdc', 'ai', 'pages', 'dxf', 'svg', 'tiff'];
    const microsoftFile = ['docx', 'doc', 'ppt', 'pptx', 'xlsx', 'xls'];
    const browserFile = ['jpg', 'jpeg', 'pdf', 'png'];
    const downloadFile = ['zip'];

    const supportedFile = downloadFile
      .concat(googleFile)
      .concat(microsoftFile)
      .concat(browserFile)
      .concat(videoFile);
    // const officeMaxFileSize = 10485760; //10 MB

    const getIframe = (params) => {
      // url was hardcoded since this is just wrapper api from iframely, both dev and prod are using the same iframely api internally
      // no need to provide the http request(api function) externally because it may require a lot of updates
      return axios.get('https://api.hiredigital.com/api/v1/iframely/oembed/', { params });
    };

    if (supportedFile.indexOf(extension) === -1) {
      getIframe({ url, omit_script: 1 })
        .then(({ data }) => {
          setHtmlExists(!!data?.html);
          setHtmlPreview(data?.html);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (downloadFile.indexOf(extension) >= 0) {
      setPreviewExists(false);
    } else if (videoFile.indexOf(extension) >= 0) {
      setVideoExists(true);
    } else {
      setPreviewExists(true);
      if (googleFile.indexOf(extension) >= 0) {
        url = 'https://docs.google.com/gview?url=' + encodeURIComponent(url) + '&embedded=true';
        //scopeUrl = 'https://docs.google.com/viewerng/viewer?url='+scopeUrl+'&embedded=true';
      } else if (microsoftFile.indexOf(extension) >= 0) {
        url =
          'https://docs.google.com/viewerng/viewer?url=' +
          encodeURIComponent(url) +
          '&embedded=true';
        // if (size > officeMaxFileSize) {
        //   url =
        //     'https://docs.google.com/viewerng/viewer?url=' +
        //     encodeURIComponent(url) +
        //     '&embedded=true';
        // } else {
        //   url = 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(url);
        // }
      }
    }
    setTrustedUrl(url);
  };

  return (
    <div className={className}>
      {previewExists && <iframe title={trustedUrl} className={Styles.iframe} src={trustedUrl} />}

      {videoExists && (
        <video controls src={trustedUrl} style={{ maxWidth: '100%', maxHeight: '100%' }}>
          <div className={Styles.container}>
            <div className={Styles.frame}>
              <img
                style={{ width: '125px', height: '162px' }}
                src='https://cdn.content.co/filetypes/file.png'
              />

              <a
                className={Styles.urlButton}
                name='Download'
                href={trustedUrl}
                target='_blank'
                rel='noreferrer'>
                Download
              </a>
            </div>
          </div>
        </video>
      )}

      {htmlExists && (
        <div
          className={Styles.htmlPreviewContainer}
          dangerouslySetInnerHTML={{
            __html: htmlPreview,
          }}></div>
      )}

      {!previewExists && !videoExists && !htmlExists && (
        <div className={Styles.container}>
          <div className={Styles.frame}>
            <img
              style={{ width: '125px', height: '162px' }}
              src='https://cdn.content.co/filetypes/file.png'
            />

            <p className={Styles.name}>{url}</p>
            <a className={Styles.urlButton} href={trustedUrl} target='_blank' rel='noreferrer'>
              Download
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
ShowAsIframe.propTypes = {
  url: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default ShowAsIframe;
