import { useState, useEffect } from 'react';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button/Button';
import TextArea from '@hiredigital/ui/Input/TextArea';
import { useOrg } from '@context/org';
import { postInterest } from '@apis/common';

import Styles from './Styles.module.scss';

const OtherPaymentsDialog = ({ isShown: initialIsShown, onCloseComplete, onClose }) => {
  const [isShown, setIsShown] = useState(false);
  const [message, setMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const org = useOrg();

  useEffect(() => {
    setIsShown(initialIsShown);
  }, [initialIsShown]);

  const handleClose = () => {
    setIsShown(false);
    onClose?.();
  };
  const handleSubmitInterest = () => {
    setIsSubmitting(true);
    const data = {
      message,
      company: org?.name,
      email: org?.contact?.email,
      country: org?.address?.country || org?.country?.name,
      website: org?.social?.website,
      name: org?.contact?.name,
      phone:
        org?.contact?.phoneCountry && org?.contact?.phoneNumber
          ? org?.contact?.phoneCountry?.dialCode?.substr(1) + org?.contact?.phoneNumber
          : '',
      title: 'Other payment request',
    };
    postInterest(data)
      .then(() => {
        setIsSubmitting(false);
        setIsShown(false);
        onClose?.();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Dialog
      isOpen={isShown}
      title='Need other payment options?'
      onClose={handleClose}
      onCloseComplete={onCloseComplete}>
      <div style={{ marginBottom: '20px' }}>
        <p style={{ marginBottom: '20px' }}>
          {`We accept purchase orders for Enterprise clients. To request for our `}
          <a
            href={`https://hiredigital.com/enterprise`}
            target='_blank'
            rel='noreferrer'
            className={Styles.link}>
            {`Enterprise services`}
          </a>
          {`, please submit your interest below.`}
        </p>
        <TextArea
          name='notes'
          label='Add additional notes'
          defaultValue={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <Button type={Button.Type.BLUE} onClick={handleSubmitInterest} isLoading={isSubmitting}>
          {'Submit Interest'}
        </Button>
        <Button type={Button.Type.LIGHT_GRAY} onClick={handleClose}>
          {'Cancel'}
        </Button>
      </div>
    </Dialog>
  );
};
export default OtherPaymentsDialog;
