import { useState, useEffect } from 'react';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import qs from 'qs';
import TreeView from '@hiredigital/ui/TreeView';
import Loader from '@hiredigital/ui/Loader';
import GroupBox from './GroupBox';
import GroupHeader from './GroupHeader';
import ItemRow from './ItemRow';
import Styles from './PaginatedGroupTree.module.scss';

const TreeItems = ({
  data,
  levelIndex = 0,
  getChildrenProps,
  getExpandedData,
  getTreeItemRenderProps,
  params,
}) => {
  const [nestedData, setNestedData] = useState({});
  const [busyRows, setBusyRows] = useState([]);
  const [emptyRows, setEmptyRows] = useState([]);

  const { items, hasChildren, uniqueKey = 'uuid', columns } = getChildrenProps(data, levelIndex);
  const nextLevelIndex = levelIndex + 1;

  const loadChildren = async (parent) => {
    const parentKey = parent[uniqueKey];
    try {
      // while rows associated with this parent are currently being loaded
      // we'll show a spinner by adding the parent to busyRows state array
      setBusyRows((p) => [...p, parentKey]);
      const data = await getExpandedData({ item: parent, levelIndex: nextLevelIndex, params });
      setNestedData((p) => ({ ...p, [parentKey]: data }));

      const { items } = getChildrenProps(data, nextLevelIndex);
      if (!items?.length && !emptyRows.includes(parentKey)) {
        setEmptyRows((p) => [...p, parentKey]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      // regardless of whether an error occurred or not
      // remove the spinner by removing the parent in busyRows state array
      setBusyRows((p) => p.filter((v) => v !== parentKey));
    }
  };

  const handleOnExpand = ({ isExpanding, item, itemKey }) => {
    if (!isExpanding) {
      setEmptyRows((p) => p.filter((v) => v !== itemKey));
      setBusyRows((p) => p.filter((v) => v !== itemKey));
      return;
    }
    loadChildren(item);
  };

  return items?.map((item, idx) => {
    const itemKey = item[uniqueKey];
    const isLoaded = !!Object.keys(nestedData?.[itemKey] || {}).length;
    const { isDisabled = false, className } = getTreeItemRenderProps?.(item, levelIndex) || {};

    return (
      <TreeView.Item
        key={itemKey || idx}
        indent={levelIndex}
        className={classNames(Styles.item, isDisabled && Styles.disabled, className)}
        onExpand={(isExpanding) => handleOnExpand({ isExpanding, item, itemKey })}
        columns={columns.map(({ field, defaultText = '', ...rest }) => ({
          content:
            (typeof field === 'function'
              ? field(item)
              : item?.[field] || item?.title || item?.name || item?.description || 'No name') ||
            defaultText,
          fontWeight: 600,
          ...rest,
        }))}
        isExpandable={hasChildren}>
        {busyRows.includes(itemKey) ? (
          <ItemRow content={<Loader size={Loader.Size.SMALL} type={Loader.Type.FULL} />} />
        ) : (
          <>
            {isLoaded && (
              <TreeItems
                data={nestedData?.[itemKey]}
                params={params}
                levelIndex={nextLevelIndex}
                getExpandedData={getExpandedData}
                getChildrenProps={getChildrenProps}
                getTreeItemRenderProps={getTreeItemRenderProps}
              />
            )}
            {emptyRows.includes(itemKey) && <ItemRow content={`No entries found`} />}
          </>
        )}
      </TreeView.Item>
    );
  });
};

const PaginatedGroupTree = ({
  scrollParent,
  getListRequest,
  getChildrenProps,
  getTreeItemRenderProps,
  getExpandedData,
  filter,
  appendItems = [],
  groupColumns,
  emptyContainerClass,
  empyText = 'No Activity Found',
}) => {
  const [hasMore, setHasMore] = useState(true);
  const [list, setList] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [isResetting, setIsResetting] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadData = async (params) => {
    if (isLoadingMore) return;

    try {
      const config = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }), // allow query string with same key
      };
      setIsLoadingMore(true);
      setIsResetting(params?.page === 1);
      const {
        data: { results = [], meta = {} },
      } = (await getListRequest(config)) || {};
      setList(params?.page > 1 ? (p) => [...p, ...results] : results);
      setNextPage(meta?.nextPage);
      setHasMore(!!meta?.nextPage);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingMore(false);
      setIsResetting(false);
    }
  };

  const loadMoreItems = () => loadData({ page: nextPage, ...filter });

  useEffect(() => {
    if (!isMounted) return;
    loadData({ page: 1, ...filter });
  }, [filter]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const items = appendItems?.length ? appendItems.concat(list) : list;

  return (
    <InfiniteScroll
      initialLoad={true}
      pageStart={1}
      loadMore={loadMoreItems}
      hasMore={hasMore}
      useWindow={false}
      getScrollParent={() => scrollParent}>
      {isResetting ? (
        <Loader size={Loader.Size.FULL} type={Loader.Type.FULL} />
      ) : !!items?.length ? (
        items.map((v, idx) => (
          <GroupBox
            key={v?.uuid || v?.id || idx}
            header={() => <GroupHeader data={v} columns={groupColumns} />}>
            <TreeView>
              <TreeItems
                data={v}
                params={filter}
                getChildrenProps={getChildrenProps}
                getExpandedData={getExpandedData}
                getTreeItemRenderProps={getTreeItemRenderProps}
              />
            </TreeView>
          </GroupBox>
        ))
      ) : (
        <div className={classNames(Styles.empty, emptyContainerClass)}>{empyText}</div>
      )}
    </InfiniteScroll>
  );
};

export default PaginatedGroupTree;
