import {
  forwardRef,
  useState,
  useEffect,
  useRef,
  memo,
  useImperativeHandle,
  Fragment,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import FileViewer from '@hiredigital/ui/FileViewer/NewFileViewer';
import Iframe from '@hiredigital/ui/Iframe/Iframe';
import Portal from '@hiredigital/ui/Portal/Portal';
import IconLink from '@hiredigital/ui/Icon/icons/link.inline.svg';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import SideHeader from './SideHeader';
import SideFooter from './SideFooter';
import SideBody from './SideBody';
import ProfileArea from './ProfileArea';
import Carousel from '../Carousel/Carousel';

import Styles from './PortfolioViewer.module.scss';

const PortfolioItemType = {
  URL: { id: 1, label: 'URL', name: 'Link' },
  UPLOAD: { id: 2, label: 'Upload', name: 'Upload' },
  CASE_STUDY: { id: 3, label: 'Case Study', name: 'Case Study' },
};

const Viewer = memo(({ request, item, caseSliderIndex, handleCaseItem, isPrivate }) => {
  return (
    <div className={Styles.viewer}>
      {(item.type === PortfolioItemType.UPLOAD.id ||
        (item.type === PortfolioItemType.URL.id && !item.showLiveUrl && item.pageScreenshot)) && (
        <FileViewer
          className={`${Styles.uploadContainer} ${item.pageScreenshot ? Styles.screenshot : ''}`}
          title={item.title || item.attachmentName}
          url={item.pageScreenshot || item.attachment || item.image}
        />
      )}

      {item.type === PortfolioItemType.URL.id && (item.showLiveUrl || !item.pageScreenshot) && (
        <Iframe request={request} className={Styles.iframeContainer} iframe={item.url} />
      )}

      {item.type === PortfolioItemType.CASE_STUDY.id && (
        <Carousel
          activeIndex={caseSliderIndex}
          onSelect={handleCaseItem}
          navIndicatorClass={Styles.navIndicatorContainer}>
          {item.caseItems.map((caseItem) => (
            <Carousel.Item key={caseItem.id}>
              <FileViewer
                className={Styles.carouselImage}
                title={caseItem.title}
                url={caseItem.image}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
});

// eslint-disable-next-line react/display-name
const PortfolioViewer = forwardRef(
  ({ request, items, getItem, onClose, onNavigate, isAdmin, isPrivate, ...props }, ref) => {
    const [isOpen, setOpen] = useState(false);
    const [currentCase, setCurrentCase] = useState();
    const [caseSliderIndex, setCaseSliderIndex] = useState();
    const [currentItemIndex, setCurrentItemIndex] = useState();
    const [item, setItem] = useState();
    const itemIndexRef = useRef(currentItemIndex);

    const loadItem = async (uuid) => {
      try {
        const { data } = await getItem(uuid);
        setItem(data);
        onNavigate?.(data);
        const currentIndex = items.findIndex((i) => i.uuid === uuid);
        setCurrentItemIndex(currentIndex);
        // We set the ref because we can only access ref's during an event listener
        // not any state vars
        itemIndexRef.current = currentIndex;

        if (data.type === PortfolioItemType.CASE_STUDY.id) {
          setCurrentCase(data.caseItems[0]);
          setCaseSliderIndex(0);
        } else {
          setCurrentCase();
        }
      } catch (err) {
        console.error(err);
        setOpen(false);
      }
    };

    const openViewer = (item) => {
      setOpen(true);
      loadItem(item?.uuid);
    };

    const closeViewer = () => {
      setOpen(false);
      setItem();
      setCurrentCase();
      onNavigate?.();
    };

    const handleCaseItem = (selectedIndex) => {
      setCaseSliderIndex(selectedIndex);
      setCurrentCase(item.caseItems[selectedIndex]);
    };

    const showNextItem = () => {
      const nextItemIndex =
        itemIndexRef.current === items.length - 1 ? 0 : itemIndexRef.current + 1;
      loadItem(items?.[nextItemIndex]?.uuid);
    };

    const showPreviousItem = () => {
      const prevItemIndex =
        itemIndexRef.current === 0 ? items.length - 1 : itemIndexRef.current - 1;
      loadItem(items?.[prevItemIndex]?.uuid);
    };

    useEffect(() => {
      const handleArrowKey = (e) => {
        const isLeftArrow = e.keyCode === 37;
        const isRightArrow = e.keyCode === 39;
        if (isLeftArrow) {
          showPreviousItem();
          // navPrev.current.click();
        } else if (isRightArrow) {
          showNextItem();
          // navNext.current.click();
        }
      };
      if (isOpen) {
        document.addEventListener('keydown', handleArrowKey);
        document.body.style.overflow = 'hidden';
      } else {
        document.removeEventListener('keydown', handleArrowKey);
        document.body.style.overflow = 'auto';
      }
      return () => {
        document.removeEventListener('keydown', handleArrowKey);
        document.body.style.overflow = 'auto';
      };
    }, [isOpen]);

    useImperativeHandle(ref, () => ({
      open: openViewer,
    }));

    return (
      <Portal>
        {isOpen && item ? (
          <div className={Styles.entered}>
            <div className={Styles.container}>
              <div className={Styles.smallScreenHeader}>
                <ProfileArea isPrivate={isPrivate} item={item} currentCase={currentCase} />
                <button className={Styles.actionClose} onClick={closeViewer}>
                  <IconClose className={Styles.icon} />
                </button>
              </div>

              <div className={Styles.main}>
                <Viewer
                  request={request}
                  item={item}
                  caseSliderIndex={caseSliderIndex}
                  handleCaseItem={handleCaseItem}
                />
              </div>

              <div className={Styles.side}>
                <SideHeader onClose={closeViewer} />
                <SideBody>
                  <ProfileArea item={item} currentCase={currentCase} />
                  {isAdmin && (
                    <div
                      className={classNames(Styles.section, Styles.row, Styles.line)}
                      style={{ flexWrap: 'wrap' }}>
                      {/* Admin Only */}
                      {item?.user && (
                        <>
                          <a
                            className={classNames(Styles.link, Styles.adminLink)}
                            href={`/profiles/${item?.user?.uuid}`}
                            rel='nofollow noopener'
                            target='_blank'>
                            {`View User`}
                          </a>
                          <a
                            className={classNames(Styles.link, Styles.adminLink)}
                            href={`${process.env.MARKETING_ENDPOINT}/resumes/${item?.user?.username}`}
                            rel='nofollow noopener'
                            target='_blank'>
                            {`View Public Resume`}
                          </a>
                        </>
                      )}

                      {item?.teams?.[0] && (
                        <>
                          <a
                            className={classNames(Styles.link, Styles.adminLink)}
                            href={`/teams/${item?.teams?.[0]?.uuid}`}
                            rel='nofollow noopener'
                            target='_blank'>
                            {`View Team`}
                          </a>
                          <a
                            className={classNames(Styles.link, Styles.adminLink)}
                            href={`${process.env.MARKETING_ENDPOINT}/teams/${item?.teams?.[0]?.slug}`}
                            rel='nofollow noopener'
                            target='_blank'>
                            {`View Public Team Page`}
                          </a>
                        </>
                      )}
                    </div>
                  )}

                  <div className={classNames(Styles.section, Styles.line)}>
                    {item?.content || currentCase?.description ? (
                      <>
                        <h3 className={Styles.subtitle}>{`Description`}</h3>
                        <div className={Styles.description} style={{ marginBottom: '15px' }}>
                          {item?.content || currentCase?.description || 'No Description'}
                        </div>
                      </>
                    ) : (
                      ''
                    )}

                    <div className={Styles.linksContainer}>
                      {item.type === PortfolioItemType.CASE_STUDY.id ? (
                        <Fragment>
                          {item.caseItems?.map((v) => (
                            <a
                              key={v?.uuid}
                              href={v?.attachment || v?.image}
                              download={v?.attachmentName || v?.title}
                              target={`_blank`}
                              className={Styles.downloadLink}
                              rel='noopener noreferrer nofollow'>
                              <IconLink />
                              {v?.title}
                            </a>
                          ))}
                        </Fragment>
                      ) : (
                        <a
                          href={item?.attachment || item?.url || item?.image}
                          download={item?.attachmentName || item?.title}
                          target={`_blank`}
                          rel='noopener noreferrer nofollow'
                          className={Styles.downloadLink}>
                          <IconLink />
                          {item?.title}
                        </a>
                      )}
                    </div>
                  </div>

                  {item.skills.length > 0 && (
                    <div className={classNames(Styles.section, Styles.line)}>
                      <div className={Styles.keywords}>
                        {item.skills.map((skill, index) => (
                          <div key={index} className={Styles.keyword}>
                            {skill.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {currentCase && (
                    <div className={Styles.section}>
                      <div>
                        <div
                          className={Styles.byline}
                          style={{ paddingBottom: '10px', fontWeight: '600' }}>
                          {`${item.caseItems.length} Items`}
                        </div>
                        <div className={Styles.casePreviewContainer}>
                          {item.caseItems.map((caseItem, index) => (
                            <div key={caseItem.id} onClick={() => handleCaseItem(index)}>
                              <img
                                className={classNames(
                                  Styles.casePreview,
                                  caseSliderIndex === index && Styles.active
                                )}
                                title={caseItem.title}
                                alt={caseItem.title}
                                src={caseItem.image}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </SideBody>

                <SideFooter
                  item={currentCase || item}
                  isFirst={currentItemIndex === 0}
                  isLast={currentItemIndex === items.length - 1}
                  onNext={showNextItem}
                  onPrev={showPreviousItem}
                />
              </div>
            </div>
            <div className={Styles.mask} onClick={closeViewer}></div>
          </div>
        ) : (
          ''
        )}
      </Portal>
    );
  }
);

PortfolioViewer.propTypes = {
  isPrivate: PropTypes.bool,
  isAdmin: PropTypes.bool,
  items: PropTypes.array,
  initialItem: PropTypes.object,
  creator: PropTypes.object,
  onClose: PropTypes.func,
};

const MemoPortfolioViewer = memo(PortfolioViewer);

export default MemoPortfolioViewer;
