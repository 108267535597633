import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styles from './ResizablePane.module.scss';
import classNames from 'classnames';

const ResizablePane = ({
  name,
  style,
  minWidth,
  defaultWidth,
  onResize,
  onResizeCompletion,
  resizerClass,
  ...props
}) => {
  const refPane = useRef();
  const [width, setWidth] = useState(defaultWidth);
  const [dragging, setDragging] = useState(false);
  let startX, startWidth;

  useEffect(() => {
    const existing = parseInt(localStorage.getItem(`resize-${name}`));
    const w = existing || defaultWidth;
    setWidth(w < minWidth ? defaultWidth : w);
    onResize?.(w < minWidth ? defaultWidth : w);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem(`resize-${name}`, width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const onMouseDown = (e) => {
    setDragging(true);
    const container = refPane.current;
    startX = e.clientX;
    startWidth = parseInt(document.defaultView.getComputedStyle(container).width, 10);

    document.documentElement.addEventListener('mousemove', onMouseMove);
    document.documentElement.addEventListener('mouseup', onMouseUp);
  };

  const onTouchStart = (e) => {
    setDragging(true);
    const container = refPane.current;
    startX = e.touches[0].clientX;
    startWidth = parseInt(document.defaultView.getComputedStyle(container).width, 10);
    document.documentElement.addEventListener('touchmove', onTouchMove);
  };

  const onMouseMove = (e) => {
    const w = startWidth - e.clientX + startX;

    if (minWidth > w) {
      return;
    }

    onResize?.(w);
    resize(w);
  };

  const onTouchMove = (e) => {
    const w = startWidth - e.touches[0].clientX + startX;

    if (minWidth > w) {
      return;
    }

    onResize?.(w);
    resize(w);
  };

  const resize = (w) => {
    const container = refPane.current;
    container.style.width = `${w}px`;
  };

  const onMouseUp = (e) => {
    setDragging(false);
    const container = refPane.current;

    if (container) {
      const width = container.style.width;
      setWidth(parseInt(width));
      onResizeCompletion?.(width);
    }
    document.documentElement.removeEventListener('touchmove', onTouchMove);
    document.documentElement.removeEventListener('mousemove', onMouseMove);
    document.documentElement.removeEventListener('mouseup', onMouseUp);
  };

  useEffect(() => {
    // Loads at the start and end
    if (refPane.current) {
      resize(width);
    }

    return () => {
      document.documentElement.removeEventListener('touchmove', onTouchMove);
      document.documentElement.removeEventListener('mousemove', onMouseMove);
      document.documentElement.removeEventListener('mouseup', onMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refPane.current]);

  return (
    <div ref={refPane} style={{ position: 'relative', ...style }} {...props}>
      <div
        className={classNames(Styles.resizer, resizerClass)}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onMouseUp}
      />
      <div className={classNames(Styles.resizeMask, dragging && Styles.maskVisible)} />
      {props.children}
    </div>
  );
};

ResizablePane.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
  minWidth: PropTypes.number,
  defaultWidth: PropTypes.number,
  onResize: PropTypes.func,
  onResizeCompletion: PropTypes.func,
};

export default ResizablePane;
