import { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import { useHistory } from 'react-router-dom';

import Avatar from '@hiredigital/ui/Avatar';
import MultiLineEllipsis from '@hiredigital/ui/MultiLineEllipsis';

// import Icon from '@hiredigital/ui/Icon/Icon';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';

import Styles from './Notifications.module.scss';
// import { removeBaseUrl } from '@hiredigital/lib/helpers/utils';
import { viewNotification } from '@helpers/notifications';

// const transformToValidPath = (url) => {
//   const path = removeBaseUrl(url).replace('/api/v1', '');
//   return path;
// };

const Notifications = memo(({ style, index, notification, setRowHeight }) => {
  const rowRef = useRef({});
  const history = useHistory();

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [rowRef]);

  // const viewNotification = (notification) => {
  //   console.log('notification', notification);
  //   if (notification.organization) {
  //     // FIXME: Add org change
  //     console.log(notification.organization);
  //   }
  //   window.location.href = transformToValidPath(notification.url);
  // };

  return (
    <div style={style} className={Styles.itemContainer}>
      <div
        ref={rowRef}
        onClick={() => viewNotification(notification)}
        className={classNames(Styles.item, !notification?.read && Styles.unread)}>
        {notification && (
          <>
            <Avatar
              className={'highlight-block'}
              style={{ marginRight: '20px' }}
              name={notification?.actor?.name}
              src={notification?.actor?.picture}
            />
            <div
              className={classNames(Styles.itemContent, 'highlight-block')}
              data-test-id='notification-id'>
              <MultiLineEllipsis
                elem='span'
                truncateLine={4}
                dangerouslySetInnerHTML={{ __html: notification?.summary }}
              />
              <div className={Styles.date}>
                {formatDistanceToNow(new Date(notification?.created || null), {
                  includeSeconds: true,
                  addSuffix: true,
                })}
              </div>
            </div>
            <div className={Styles.moreIconContainer}>
              <IconDown className={Styles.moreIcon} />
            </div>
          </>
        )}
      </div>
    </div>
  );
});

Notifications.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
};

export default Notifications;
