import { authRequest } from './utils';

export const getChats = async (config = undefined) => {
  const url = `chats/`;
  return await authRequest.get(url, config);
};

export const getOrgChats = async (orgUuid, config = undefined) => {
  const url = `orgs/${orgUuid}/chats/`;
  return await authRequest.get(url, config);
};
