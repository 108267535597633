import { Fragment } from 'react';
import Styles from './FileList.module.scss';
import Item from './Item';
import { formatBytes } from '@hiredigital/lib/helpers/utils';
import Loader from '@hiredigital/ui/Loader';

const FileList = ({ attachments = [], links = [], isBusy, itemClass }) => {
  const getFileName = (v) => v?.attachment?.split('/').pop() || '';

  return (
    <div className={Styles.container} data-test-id='attachments'>
      {isBusy ? (
        <Loader color={Loader.Color.BLUE} size={Loader.Size.SMALL} />
      ) : (
        <Fragment>
          {attachments
            .filter((v) => !!v?.attachment)
            .map((v, idx) => (
              <Item
                key={idx}
                title={getFileName(v)}
                description={v?.size && formatBytes(v?.size)}
                url={v?.attachment}
                isLink={false}
                className={itemClass}
              />
            ))}
          {links
            .filter((v) => !!v?.url)
            .map((v, idx) => (
              <Item
                key={idx}
                title={v.title}
                description={v?.url}
                url={v?.url}
                className={itemClass}
              />
            ))}
        </Fragment>
      )}
    </div>
  );
};

FileList.propTypes = {};

export default FileList;
