import { memo, useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import MessageList from '../MessageList';
import NewMessage from '../NewMessage';
import SearchBar from '../../SearchBar/SearchBar';

import { postDiscussionAttachment } from '@apis/discussions';
import { useDiscussion } from '@context/discussion';
import { useChatController } from '@context/chatController';

import Styles from './DiscussionArea.module.scss';

const DiscussionArea = memo(
  ({ className, sendMessageClass, discussion, showSearch, allowSend = true, ...props }) => {
    const controller = useChatController();
    const listRef = useRef();
    const { loadMessages } = useDiscussion();
    let timeout = null;

    const [search, setSearch] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(undefined);
    const [activeDiscussion, setActiveDiscussion] = useState(discussion || {});

    const onAddAttachment = () => {
      open();
    };

    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const formData = new FormData();
        formData.append('attachment', file, file.fileName || file.name);

        const config = {
          onUploadProgress: (progressEvent) => {
            const prog = parseInt((100.0 * progressEvent.loaded) / progressEvent.total, 10);
            setUploadProgress(prog);
          },
        };
        // authRequest.post(`/activeDiscussions/${activeDiscussion.id}/attachments/`, formData, config).then(
        postDiscussionAttachment(activeDiscussion.id, formData, config).then(
          (response) => {
            setUploadProgress(undefined);
            loadMessages(activeDiscussion.id, { params: { limit: 75 } });
          },
          (error) => {
            // pass
          }
        );
      });

      // Do something with the files
    }, []);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      noDragEventsBubbling: true,
    });

    const handleSearchChange = ({ target: { value } }) => {
      debounceSearch(() => setSearch(value || undefined));
    };

    const debounceSearch = (callback, wait = 500) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(callback, wait);
    };

    return (
      <div className={classNames(Styles.discussionArea, className)} {...props}>
        {showSearch && <SearchBar defaultValue={search} onSearch={handleSearchChange} />}

        <div className={Styles.messageArea}>
          <div className={Styles.dropContainer} {...getRootProps()}>
            <div className={classNames(Styles.dragContainer, isDragActive && Styles.active)}>
              {`Drop files here to add them to the discussion.`}
            </div>

            <MessageList discussion={activeDiscussion} ref={listRef} search={search} />
            <input {...getInputProps()} />
          </div>
          {uploadProgress ? (
            <div className={Styles.progressBar} style={{ width: `${uploadProgress}%` }} />
          ) : (
            ''
          )}
        </div>

        {allowSend && (
          <NewMessage
            sendMessageClass={sendMessageClass}
            discussion={activeDiscussion}
            onAddAttachment={onAddAttachment}
            isBottomPadded={
              controller.isChatListShown || Object.keys(controller.activeChats || {})?.length > 0
            }
            listRef={listRef}
          />
        )}
      </div>
    );
  }
);

export default DiscussionArea;
