import { authRequest } from './utils';

const serializeConference = (data) => {
  const serializedData = {
    ...data,
    user: data?.user?.uuid,
    discussion: data?.discussion?.id,
  };
  return serializedData;
};

const serializeConferenceUser = (data) => {
  const serializedData = {
    ...data,
    user: data?.user?.uuid,
  };
  return serializedData;
};

const serializeConferenceTime = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

export const getConference = async (uuid, config = undefined) => {
  const url = `conferences/${uuid}`;
  return await authRequest.get(url, config);
};

export const postConferenceAddTime = async (uuid, timeId, config = undefined) => {
  const url = `conferences/${uuid}/times/${timeId}/add/`;
  return await authRequest.post(url, {}, config);
};

export const postConferenceRemoveTime = async (uuid, timeId, config = undefined) => {
  const url = `conferences/${uuid}/times/${timeId}/remove/`;
  return await authRequest.post(url, {}, config);
};

export const postConference = async (data, serialize = serializeConference, config = undefined) => {
  const url = `conferences/`;
  return await authRequest.post(url, serialize(data), config);
};

export const putConference = async (
  uuid,
  data,
  serialize = serializeConference,
  config = undefined
) => {
  const url = `conferences/${uuid}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const patchConference = async (
  uuid,
  data,
  serialize = serializeConference,
  config = undefined
) => {
  const url = `conferences/${uuid}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const postConferenceSubmit = async (uuid, config = undefined) => {
  const url = `conferences/${uuid}/submit/`;
  return await authRequest.post(url, {}, config);
};

export const postConferenceReschedule = async (uuid, config = undefined) => {
  const url = `conferences/${uuid}/reschedule/`;
  return await authRequest.post(url, {}, config);
};

export const putConferenceUser = async (
  uuid,
  userUuid,
  data,
  serialize = serializeConferenceUser,
  config = undefined
) => {
  const url = `conferences/${uuid}/users/${userUuid}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const postConferenceUser = async (
  uuid,
  data,
  serialize = serializeConferenceUser,
  config = undefined
) => {
  const url = `conferences/${uuid}/users/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postConferenceTime = async (
  uuid,
  data,
  serialize = serializeConferenceTime,
  config = undefined
) => {
  const url = `conferences/${uuid}/times/`;
  return await authRequest.post(url, serialize(data), config);
};

export const deleteConferenceTime = async (uuid, timeId, config = undefined) => {
  const url = `conferences/${uuid}/times/${timeId}/`;
  return await authRequest.delete(url, config);
};

export const deleteConferenceUser = async (
  uuid,
  userUuid,
  serialize = serializeConferenceTime,
  config = undefined
) => {
  const url = `conferences/${uuid}/users/${userUuid}/`;
  return await authRequest.delete(url, config);
};
