import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { authRequest } from '@apis/utils';
import EducationItem from './EducationItem';
import { dateSort } from '@hiredigital/lib/helpers/utils';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import Styles from '../Resume.module.scss';

const Education = ({ user, onListChange, noBorder = false, title, addText }) => {
  const [educationList, setEducationList] = useState(user.education || []);
  const [itemDeleting, setItemDeleting] = useState([]);
  // const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    onListChange?.(educationList);
  }, [educationList, onListChange]);

  // const getEducationList = () => {
  //   const url = `users/${user?.uuid}/education/`;
  //   authRequest.get(url).then(({ data }) => {
  //     setEducationList(data.results);
  //   });
  // };

  const handleNewEducation = () => {
    setEducationList([...educationList, {}]);
    // setIsEditing(true);
  };

  const updateEducation = (education) => {
    const list = educationList.filter((el) => el?.id);

    if (education) {
      list.push(education);
    }

    setEducationList(list);
  };

  const deleteEducation = (education) => {
    if (education.id) {
      setItemDeleting([education.id]);
      const url = `users/${user?.uuid}/education/${education.id}/`;
      authRequest.delete(url).then(
        () => {
          // getEducationList();
          setEducationList(educationList.filter((v) => v.id !== education.id));
          setItemDeleting([]);
        },
        (error) => {
          setItemDeleting([]);
        }
      );
    } else {
      updateEducation();
    }
  };

  return (
    <Card noBorder={noBorder}>
      <Card.Header>
        <span>{title || `Education`}</span>
        <div className={Styles.action}>
          <Button name='add new' type={Button.Type.BLUE} onClick={handleNewEducation}>
            {addText || `Add New`}
          </Button>
        </div>
      </Card.Header>
      <Fragment>
        {educationList.length === 0 && (
          <Card.Item>
            <p className={Styles.empty}>{`No education history has been added.`}</p>
          </Card.Item>
        )}
        <div>
          {educationList &&
            educationList
              .sort(dateSort)
              .map((education, index) => (
                <EducationItem
                  user={user}
                  education={education}
                  isDeleting={itemDeleting.includes(education.id)}
                  updateEducation={updateEducation}
                  deleteEducation={deleteEducation}
                  key={education.id || index}
                />
              ))}
        </div>
      </Fragment>
    </Card>
  );
};

Education.propTypes = {
  user: PropTypes.object,
  onListChange: PropTypes.func,
};

export default Education;
