import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from '../context/user';

import toast from 'react-hot-toast';

const withOrgGuard = (BaseComponent) => {
  const WrappedComponent = ({ className, ...props }) => {
    const user = useUser();
    const history = useHistory();
    const [isUserRequested, setIsUserRequested] = useState(false);

    useEffect(() => {
      if (!!user?.hasOwnProperty('isClient')) {
        setIsUserRequested(true);
      }
    }, [user]);

    useEffect(() => {
      if (isUserRequested && !user.isClient) {
        toast.error('You do not have access to this page.');
        history.replace('/');
      }
    }, [isUserRequested]);

    return (
      <>
        <BaseComponent {...props} />
      </>
    );
  };
  return WrappedComponent;
};

export default withOrgGuard;
