import { Fragment, useState, useEffect } from 'react';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Styles from './ItemForm.module.scss';

const ItemForm = ({
  projectUuid,
  index,
  apiManager,
  onCancel,
  onCreate,
  onUpdate,
  onDelete,
  item,
}) => {
  const [name, setName] = useState(item?.name);
  const [dueDate, setDueDate] = useState(item?.dueDate || new Date());
  const [isDeleting, setIsDeleting] = useState(null);
  const [isSaving, setIsSaving] = useState(null);

  useEffect(() => {
    setDueDate(item?.dueDate || new Date());
    setName(item?.name);
  }, [item]);

  const handleDelete = () => {
    setIsDeleting(true);
    const { deleteDeadline } = apiManager;
    deleteDeadline(projectUuid, item?.id)
      .then(() => {
        setIsDeleting(false);
        onDelete?.(item);
      })
      .catch((error) => {
        setIsDeleting(false);
        onDelete?.(item);

        console.warn(error);
      });
  };

  const handleCancel = () => onCancel?.();

  const handleSave = () => {
    setIsSaving(true);

    const payload = {
      dueDate,
      name,
    };

    const { createDeadline, updateDeadline } = apiManager;
    const isEdit = !!item?.id;

    if (isEdit) {
      updateDeadline(projectUuid, item?.id, payload)
        .then(({ data }) => {
          setIsSaving(false);
          onUpdate?.({ ...data, isEdit: false });
        })
        .catch((error) => {
          setIsSaving(false);
          console.warn(error);
        });
      return;
    }

    createDeadline(projectUuid, payload)
      .then(({ data }) => {
        setIsSaving(false);
        onCreate?.({ ...data, isNew: false }, index);
      })
      .catch((error) => {
        setIsSaving(false);
        console.warn(error);
      });
  };

  return (
    <>
      <div className={Styles.form}>
        <div className={Styles.row}>
          <TextInput
            className={Styles.left}
            name={`name`}
            label={`Task`}
            placeholder={`Add a task or milestone`}
            onChange={(e) => setName(e?.target?.value)}
            defaultValue={name}
          />
          <DatePicker
            containerClassName={Styles.right}
            name='deadline'
            label={`Deadline`}
            placeholder={`Add a date`}
            dateFormat='MM/dd/yyyy hh:mm aa'
            value={dueDate}
            onChange={setDueDate}
            showTimeInput
          />
        </div>
        <div className={Styles.actionRow}>
          <div className={Styles.actionLeft}>
            <Button onClick={handleSave} type={Button.Type.BLUE} isLoading={isSaving}>
              {`Save`}
            </Button>
            <Button onClick={handleCancel} type={Button.Type.GRAY}>
              {`Cancel`}
            </Button>
          </div>
          {!!item?.id && (
            <Button onClick={handleDelete} type={Button.Type.RED_OUTLINE} isLoading={isDeleting}>
              {`Delete`}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default ItemForm;
