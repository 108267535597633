import PropTypes from 'prop-types';
import Styles from './Card.module.scss';
import classNames from 'classnames';

const CardFooter = ({ className, children, right, sticky, ...props }) => {
  return (
    <div
      className={classNames(
        Styles.footer,
        right && Styles.right,
        sticky && Styles.sticky,
        className
      )}
      {...props}>
      {children}
    </div>
  );
};

CardFooter.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  sticky: PropTypes.bool,
  right: PropTypes.bool,
};

export default CardFooter;
