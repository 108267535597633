import classNames from 'classnames';
import Styles from './OverviewStyles.module.scss';

const OverviewContent = ({ children, className, ...props }) => (
  <div className={classNames(Styles.content, className)} {...props}>
    {children}
  </div>
);

OverviewContent.propTypes = {};

export default OverviewContent;
