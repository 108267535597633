import { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getNotification } from '@apis/notifications';
// import { removeBaseUrl } from '@hiredigital/lib/helpers/utils';
import Loader from '@hiredigital/ui/Loader';
import { viewNotification } from '@helpers/notifications';

// const transformToValidPath = (url) => {
//   const path = removeBaseUrl(url).replace('/api/v1', '');
//   return path;
// };

const EmailRedirect = () => {
  const { id } = useParams();
  const location = useLocation();
  const { source, url_auth_token } = queryString.parse(location.search);
  console.log(id, source, url_auth_token);
  // Get notification

  // const viewNotification = (notification) => {
  //   console.log('notification', notification);
  //   if (notification.organization) {
  //     // FIXME: Add org change
  //     console.log(notification.organization);
  //   }
  //   // history.push(transformToValidPath(notification.url));
  //   window.location.href = transformToValidPath(notification.url);
  // };

  useEffect(() => {
    const getData = async (id) => {
      const { data } = await getNotification(id);
      console.log(data);
      viewNotification(data);
    };

    if (id) {
      getData(id);
    }
    // get notification
  }, [id, source, url_auth_token]);

  return (
    <>
      <Loader style={{ marginRight: '10px' }} color={Loader.Color.WHITE} size={Loader.Size.SMALL} />
    </>
  );
};

export default EmailRedirect;
