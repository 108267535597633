import { useState } from 'react';
import PropTypes from 'prop-types';
import Styles from '../../Styles.module.scss';
import classNames from 'classnames';

const TimeItem = ({ isActive, label, ...props }) => {
  return (
    <div className={classNames(Styles.timeItem, { [Styles.active]: isActive })} {...props}>
      {label}
    </div>
  );
};

TimeItem.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string,
};

export default TimeItem;
