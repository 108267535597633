import { useState } from 'react';
import OnboardingLayout from '@components/Onboarding/Layout';
import Header from '@components/Onboarding/Layout/Header';
import TalentOnboarding from '@components/Onboarding';

const IndexPage = () => {
  const progress = [25, 50, 75, 100];
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <OnboardingLayout header={() => <Header progressValue={progress[activeIndex]} />}>
      <TalentOnboarding selectedIndex={activeIndex} onChange={setActiveIndex} />
    </OnboardingLayout>
  );
};

export default IndexPage;
