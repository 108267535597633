import PropTypes from 'prop-types';
import Button from '@hiredigital/ui/Button';
import Styles from './Detail.module.scss';

const StartApplication = ({ onStart }) => {
  return (
    <div className={Styles.newApplication}>
      <div className={Styles.notes}>
        <p className={Styles.noteTitle}>{`When you select Start Application, you agree to`}</p>
        <ul className={Styles.noteList}>
          <li>{`Keep the engagement and application process strictly confidential.`}</li>
          <li>{`Meet the Timeline, Requirements and Terms of the engagement.`}</li>
        </ul>
      </div>
      <Button type={Button.Type.BLUE} onClick={onStart}>
        {`Start Application`}
      </Button>
    </div>
  );
};

StartApplication.propTypes = {
  children: PropTypes.node,
};

export default StartApplication;
