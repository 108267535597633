import { Fragment } from 'react';
import { ApplicationStatus, UserStatus } from '@hiredigital/lib/helpers/enum';
import Styles from './Detail.module.scss';

function isSubmittedAndActive(appStatus, userStatus) {
  return (
    (appStatus === ApplicationStatus.SUBMITTED.id ||
      appStatus === ApplicationStatus.SUBMITTED_HOLD.id ||
      appStatus === ApplicationStatus.SUBMITTED_REVIEWED.id) &&
    userStatus !== UserStatus.ONBOARDING.id
  );
}

function isSubmittedAndOnboarding(appStatus, userStatus) {
  return (
    (appStatus === ApplicationStatus.SUBMITTED.id ||
      appStatus === ApplicationStatus.SUBMITTED_HOLD.id ||
      appStatus === ApplicationStatus.SUBMITTED_REVIEWED.id) &&
    userStatus === UserStatus.ONBOARDING.id
  );
}

const OnboardingNotes = ({ appStatus, userStatus }) => {
  if (isSubmittedAndOnboarding(appStatus, userStatus)) {
    return (
      <div className={Styles.onboardingNotes}>
        {`Before your application is processed, you will need to complete your talent onboarding. `}
        <a href={`/guide`} className={Styles.link}>
          {`Click here to continue your onboarding`}
        </a>
      </div>
    );
  } else if (isSubmittedAndActive(appStatus, userStatus)) {
    return (
      <div className={Styles.onboardingNotes}>
        {`Your application has been submitted. You can continue to update it if you need to make changes.`}
      </div>
    );
  } else {
    return <Fragment />;
  }
};

export default OnboardingNotes;
