import { authRequest } from './utils';

export const postOrgStripeCard = async (uuid, data, config = undefined) => {
  const url = `customers/${uuid}/cards/`;
  return await authRequest.post(url, data, config);
};

export const postOrgStripeCustomerDeposit = async (uuid, data, config = undefined) => {
  const url = `customers/${uuid}/deposit/`;
  return await authRequest.post(url, data, config);
};

export const getOrgStripeCustomer = async (uuid, config = undefined) => {
  const url = `customers/${uuid}/`;
  return await authRequest.get(url, config);
};

export const getOrgStripeCards = async (uuid, config = undefined) => {
  const url = `customers/${uuid}/cards/`;
  return await authRequest.get(url, config);
};

export const setOrgStripeCardDefault = async (uuid, cardId, config = undefined) => {
  const url = `customers/${uuid}/cards/${cardId}/set-default/`;
  return await authRequest.post(url, config);
};

export const deleteOrgStripeCard = async (uuid, cardId, config = undefined) => {
  const url = `customers/${uuid}/cards/${cardId}/`;
  return await authRequest.delete(url, config);
};
