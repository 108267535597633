import { useReducer, useState, createContext, useContext, useEffect } from 'react';
import { DiscussionsListProvider } from './discussionsList';
import { useUser } from './user';

const ChatControllerContext = createContext();
const ChatControllerDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIATE_LIST':
      const initial = localStorage.getItem('isChatListShown') === 'true';
      return { ...state, isChatListShown: initial };
    case 'SHOW_LIST':
      localStorage.setItem('isChatListShown', true);
      return { ...state, isChatListShown: true };
    case 'HIDE_LIST':
      localStorage.setItem('isChatListShown', false);
      return { ...state, isChatListShown: false };
    case 'UPDATE_ACTIVE_CHATS':
      return { ...state, activeChats: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
export const ChatControllerProvider = ({ children, isChatShown }) => {
  const user = useUser();
  const [controller, dispatch] = useReducer(reducer, { isChatListShown: isChatShown });

  useEffect(() => {
    if (user?.uuid) {
      const active = controller?.activeChats || {};
      const raw = localStorage.getItem('activeChats');
      const existing = raw ? JSON.parse(raw) : {};
      const chats = { ...existing, [user?.uuid]: active };

      if (!active || active.length === 0) {
        delete chats[user?.uuid];
      }

      localStorage.setItem('activeChats', JSON.stringify(chats));
    }
  }, [controller?.activeChats, user]);

  return (
    <ChatControllerDispatchContext.Provider value={dispatch}>
      <ChatControllerContext.Provider value={controller}>
        <DiscussionsListProvider>{children}</DiscussionsListProvider>
      </ChatControllerContext.Provider>
    </ChatControllerDispatchContext.Provider>
  );
};

export const useChatController = () => useContext(ChatControllerContext);
export const useChatControllerDispatch = () => useContext(ChatControllerDispatchContext);
