import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SelectableGroup } from 'react-selectable-fast';

import range from 'lodash/range';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import Button from '@hiredigital/ui/Button';
import { months, convertToDay } from '@hiredigital/lib/helpers/date';
import Card from '@hiredigital/ui/Card';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import Loader from '@hiredigital/ui/Loader';

import Styles from './Styles.module.scss';

import Item from './Item';

class MonthlyBusySchedule extends Component {
  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      month: null,
      year: null,
      isFinished: false,
      hasBusy: true,
    };
    this.sendRequestBusyScheduleDebounce = debounce(this.sendRequestBusySchedule, 400);
  }

  componentDidMount = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();
    this.setState({
      currentDay: day,
      currentMonth: month,
      currentYear: year,
    });
    this.loadMonth(month, year);
  };

  handleNextMonth = () => {
    // TODO Tim: There must be a more elegant way to do this
    let month, year;
    if (this.state.month === 12) {
      month = 1;
      year = this.state.year + 1;
    } else {
      month = this.state.month + 1;
      year = this.state.year;
    }
    this.setState({ isFinished: false }, () => {
      this.loadMonth(month, year);
    });
  };

  handlePrevMonth = () => {
    // TODO Tim: There must be a more elegant way to do this
    let month, year;
    if (this.state.month === 1) {
      month = 12;
      year = this.state.year - 1;
    } else {
      month = this.state.month - 1;
      year = this.state.year;
    }
    this.setState({ isFinished: false }, () => {
      this.loadMonth(month, year);
    });
  };

  loadMonth = async (month, year) => {
    const payload = { params: { month, year } };
    const { isAdmin, getUserMonthlyBusySchedule } = this.props;

    try {
      const {
        data: { meta, results },
      } = await (isAdmin
        ? getUserMonthlyBusySchedule(this.state.user.uuid, payload)
        : getUserMonthlyBusySchedule(payload));

      this.setState({
        isFinished: true,
        month,
        year,
        firstDay: meta.firstDayOfMonth,
        numDays: meta.numberOfDays,
        availableDays: results || [],
        hasBusy: this.hasBusyDayThisMonth(
          results?.map((v) => v?.day),
          meta.numberOfDays
        ),
      });
    } catch (err) {
      console.error(err);
    }

    // if (isAdmin) {
    //   return getUserMonthlyBusySchedule(this.state.user.uuid, data).then((response) => {
    //     const data = response.data;
    //     const hasBusy = Array.from({ length: data.meta.numberOfDays }).some(
    //       (_, idx) => !data?.results?.map((v) => v.day).includes(idx + 1)
    //     );
    //     this.setState({
    //       isFinished: true,
    //       month,
    //       year,
    //       firstDay: data.meta.firstDayOfMonth,
    //       numDays: data.meta.numberOfDays,
    //       availableDays: data.results || [],
    //       hasBusy,
    //     });
    //   });
    // } else {
    //   return getUserMonthlyBusySchedule(data).then((response) => {
    //     const data = response.data;
    //     const hasBusy = Array.from({ length: data.meta.numberOfDays }).some(
    //       (_, idx) => !data?.results?.map((v) => v.day).includes(idx + 1)
    //     );
    //     this.setState({
    //       isFinished: true,
    //       month,
    //       year,
    //       firstDay: data.meta.firstDayOfMonth,
    //       numDays: data.meta.numberOfDays,
    //       availableDays: data.results || [],
    //       hasBusy,
    //     });
    //   });
    // }
  };

  setMonthlyBusySchedule = (data) => {
    if (this.props.isAdmin) {
      return this.props.postUserMonthlyBusyScheduleSet(
        this.state.user.uuid,
        this.state.year,
        this.state.month,
        data
      );
    } else {
      return this.props.postUserMonthlyBusyScheduleSet(this.state.year, this.state.month, data);
    }
  };

  sendRequestBusySchedule = (data) => this.setMonthlyBusySchedule(data);

  setDays = (daysIndexes) => {
    this.setState({ daysSeleted: daysIndexes }, () => {
      if (this.state.isFinished) {
        this.sendRequestBusyScheduleDebounce({
          days: this.state.daysSeleted,
        });
      }
    });
  };

  hasBusyDayThisMonth = (availableDays, numDays) => {
    const days = Array.from({ length: numDays || this.state.numDays });
    return days.some((_, idx) => !availableDays.includes(idx + 1));
  };

  handleSelectionFinish = (items, n) => {
    const availableDays = items.map((item) => ({
      day: item.props.day,
    }));
    const hasBusy = this.hasBusyDayThisMonth(
      availableDays.map((v) => v.day),
      this.state.numDays
    );
    this.setState({ hasBusy, availableDays });
    this.setDays(availableDays.map((v) => v.day));
  };

  handleToggleEntire = () => {
    if (this.state.hasBusy) {
      const availableDays = Array.from({ length: this.state.numDays }, (_, i) => ({ day: i + 1 }));
      this.setState({ availableDays, hasBusy: false });
      this.setDays(availableDays.map((v) => v.day));
    } else {
      this.setState({ availableDays: [], hasBusy: true });
      this.setDays([]);
    }
  };

  // convertToDay = (dayIndex, dayFormat = 'EEEE') => {
  //   return format(setDay(new Date(), dayIndex), dayFormat);
  // };

  render() {
    const daysLabel = Array.from({ length: 7 });
    const {
      month,
      year,
      firstDay,
      numDays,
      availableDays,
      isFinished,
      currentDay,
      currentYear,
      currentMonth,
    } = this.state;
    return (
      <Card>
        <Card.Header>
          <span className={Styles.title}>{`Monthly Availability`}</span>
          <Button
            onClick={this.handleToggleEntire}
            type={Button.Type.BLUE_OUTLINE}
            className={Styles.btnSetEntire}>
            {`Set Entire Month as ${this.state.hasBusy ? 'Available' : 'Busy'}`}
          </Button>
        </Card.Header>
        {this.props.showNotes && (
          <Card.Note>
            {`If you know your schedule for the coming months, fill in the days you will be `}
            {`free to take on work. We'll use this information to plan engagement lengths with clients. `}
            {`We'll remind you periodically to update this information.`}
          </Card.Note>
        )}
        <Card.Item noPadding>
          <div className={Styles.calendarContainer}>
            <div className={Styles.headline}>
              <div
                data-test-id='previousMonth'
                onClick={this.handlePrevMonth}
                className={classNames(Styles.icon, Styles.iconPrev)}>
                <IconDown />
              </div>
              <div className={Styles.monthTitle}>
                {months[this.state.month - 1]} {this.state.year}
              </div>
              <div
                data-test-id='nextMonth'
                onClick={this.handleNextMonth}
                className={classNames(Styles.icon, Styles.iconNext)}>
                <IconDown />
              </div>
            </div>
            <div className={Styles.dayHeadeSection}>
              {daysLabel.map((day, dayLabelIndex) => (
                // return (<div className={Styles.dayHeader} key={i}>{day}</div>)
                <div className={Styles.dayHeader} key={dayLabelIndex}>
                  <span className={Styles.dayAbbreviate}>{convertToDay(dayLabelIndex, 'EE')}</span>
                  <span className={Styles.dayNormal}>{convertToDay(dayLabelIndex)}</span>
                </div>
              ))}
            </div>
            <SelectableGroup
              clickableClassName={Styles.day}
              enableDeselect
              tolerance={5}
              globalMouse={false}
              allowClickWithoutSelected={true}
              // duringSelection={this.handleSelecting}
              // onSelectionClear={this.handleSelectionClear}
              onSelectionFinish={this.handleSelectionFinish}
              ignoreList={['.not-selectable']}>
              {/*          <SelectAll className="selectable-button">
                <button>Select all</button>
              </SelectAll>
              <DeselectAll className="selectable-button">
                <button>Clear selection</button>
              </DeselectAll>
    */}
              {isFinished ? (
                <Fragment>
                  <div className={classNames(Styles.wrapper)}>
                    {range((firstDay + 1) % 7).map((d, i) => {
                      return (
                        <div
                          key={`${month}${d}`}
                          className={classNames(Styles.prevMonthDay, 'c-month__ignore')}
                        />
                      );
                    })}{' '}
                    {range(numDays).map((d, i) => {
                      const day = d + 1;
                      const isAvailable = !!find(availableDays, { day: day });
                      // added isBusy key to force render Item component when busyDay changes
                      return (
                        <Item
                          key={`${month}${d}${i}${!!isAvailable}`}
                          day={day}
                          currentDay={
                            year === currentYear && month === currentMonth && day === currentDay
                          }
                          isSelected={isAvailable}
                          isDisabled={
                            (day < currentDay && month === currentMonth && year === currentYear) ||
                            (month < currentMonth && year === currentYear) ||
                            year < currentYear
                          }
                        />
                      );
                    })}
                  </div>
                </Fragment>
              ) : (
                <Loader type={Loader.Type.FULL} className={Styles.loader} />
              )}
            </SelectableGroup>
          </div>
        </Card.Item>
      </Card>
    );
  }
}

export default MonthlyBusySchedule;
