import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// Custom Link component that offers backwards compatibility with GatsbyJS Link component

const CustomLink = ({ href: to, children, className, activeClassName, ...props }) => {
  return (
    // TODO add isACtive router.asPath === href && activeClassName
    <Link className={className} to={to} {...props}>
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
};

export default CustomLink;
