import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '@hiredigital/ui/Button';

import { formatCurrency } from '@hiredigital/lib/helpers/utils';
import { useUser } from '@context/user';
import Styles from './Styles.module.scss';

const PackageItem = ({
  data = {},
  stripeCurrency,
  isCustom = false,
  onPurchase,
  selectedPackage,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [customAmount, setCustomAmount] = useState(0);
  const user = useUser();
  const toggleSelector = (is) => {
    if (is) {
      setIsSelected(false);
      onPurchase?.();
    } else {
      setIsSelected(true);
      if (isCustom) {
        onPurchase?.({ amount: customAmount });
      } else {
        onPurchase?.(data);
      }
    }
  };

  useEffect(() => {
    if (selectedPackage?.id === data?.id) {
    } else {
      setIsSelected(false);
    }
  }, [selectedPackage]);

  return (
    <div className={classNames(Styles.packageItem, isSelected && Styles.selectedPackage)}>
      {isCustom ? (
        <input
          type={`text`}
          placeholder={`Custom`}
          className={Styles.customAmount}
          onChange={(e) => setCustomAmount(e.target.value)}
        />
      ) : (
        <p className={Styles.packageAmount}>
          {`${['SGD', 'sgd'].includes(stripeCurrency) ? 'S' : ''}`}
          {formatCurrency(data.amount || 0, 0)}
        </p>
      )}
      {user?.isClient && (
        <Button
          type={isSelected ? Button.Type.BLUE : Button.Type.BLUE_OUTLINE}
          onClick={() => toggleSelector(isSelected)}>
          {`Select`}
        </Button>
      )}
    </div>
  );
};

PackageItem.propTypes = {
  data: PropTypes.object,
  isCustom: PropTypes.bool,
  onPurchase: PropTypes.func,
  stripeCurrency: PropTypes.string,
};

export default PackageItem;
