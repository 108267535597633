import Styles from './ConfirmationNotes.module.scss';

const ConfirmationNotes = () => {
  return (
    <p className={Styles.intructions}>
      {`By confirming, you agree to:`}
      <ol>
        <li>{`Commit the estimated time required for the assignment.`}</li>
        <li>{`Produce top quality, original work.`}</li>
        <li>
          {`Abide by Hire Digital's `}
          <a
            className={Styles.link}
            href='https://hiredigital.com/terms'
            target='_blank'
            rel='noopener noreferrer'>
            {`Terms and Conditions`}
          </a>
          {`.`}
        </li>
      </ol>
    </p>
  );
};

export default ConfirmationNotes;
