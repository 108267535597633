import PropTypes from 'prop-types';
import Styles from './ChatContainer.module.scss';
import classNames from 'classnames';

const ChatContainer = ({ children, className }) => {
  return <div className={classNames(Styles.chatBoxList, className)}>{children}</div>;
};

ChatContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default ChatContainer;
