import { memo, useEffect } from 'react';
import { ContentType } from '@hiredigital/lib/helpers/enum';
import AttachmentViewer from './AttachmentViewer';
import LinkViewer from './LinkViewer';
import TextEditor from './TextEditor';
import Loader from '@hiredigital/ui/Loader/Loader';
import { useProjects } from '@context/projects';

const Viewer = ({ contentId, projectUuid }) => {
  const { loadFile, isLoading, currentFile } = useProjects();

  useEffect(() => {
    if (!projectUuid) return;
    if (contentId) {
      loadFile(projectUuid, contentId);
    }
  }, [contentId, projectUuid]);

  if (isLoading) {
    return <Loader type={Loader.Type.FULL} />;
  }

  switch (currentFile?.type) {
    case ContentType.LINK.id:
      return <LinkViewer projectUuid={projectUuid} content={currentFile} />;
    case ContentType.ATTACHMENT.id:
      return <AttachmentViewer projectUuid={projectUuid} content={currentFile} />;
    case ContentType.TEXT.id:
      return <TextEditor projectUuid={projectUuid} content={currentFile} />;
    default:
      return null;
  }
};

export default memo(Viewer);
