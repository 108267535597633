import { authRequest } from './utils';

const serializeOrganization = (data) => {
  const serializedData = {
    ...data,
    countries: data?.countries?.map((obj) => obj?.id),
    languages: data?.languages?.map((obj) => obj?.id),
    topics: data?.topics?.map((obj) => obj?.id),
    contact: {
      ...data.contact,
      timezone: data?.contact?.timezone?.id,
      user: data?.contact?.user?.uuid,
      preferred: data?.contact?.preferred?.id,
      conferencePlatform: data?.contact?.conferencePlatform?.id,
    },
    payment: {
      ...data.payment,
      currency: data?.payment?.currency?.id,
    },
  };
  return serializedData;
};

const serializeInvitation = (data) => {
  return {
    ...data,
  };
};

const serializeUser = (data) => {
  return {
    ...data,
    user: data?.user?.uuid,
  };
};

const serializeAgreement = (data) => {
  return {
    ...data,
  };
};

const serializerProject = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

export const getOrganization = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/`;
  return await authRequest.get(url, config);
};

export const patchOrganization = async (
  uuid,
  data,
  serialize = serializeOrganization,
  config = undefined
) => {
  const url = `orgs/${uuid}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const putOrganization = async (
  uuid,
  data,
  serialize = serializeOrganization,
  config = undefined
) => {
  const url = `orgs/${uuid}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const putOrgPicture = async (
  uuid,
  data,
  serialize = serializeOrganization,
  config = undefined
) => {
  const url = `orgs/${uuid}/reset-picture/`;
  return await authRequest.put(url, serialize(data), config);
};

export const getOrgProjects = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/projects/`;
  return await authRequest.get(url, config);
};

export const getOrgInvoices = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/xero-invoices/`;
  return await authRequest.get(url, config);
};

export const getOrgPrepayments = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/xero-prepayments/`;
  return await authRequest.get(url, config);
};

export const getOrgCreditNotes = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/xero-creditnotes/`;
  return await authRequest.get(url, config);
};

export const refreshOrgInvoices = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/xero-invoices/refresh/`;
  return await authRequest.post(url, config);
};

export const getOrgAgreements = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/agreements/`;
  return await authRequest.get(url, config);
};

export const generateOrgAgreement = async (uuid, data, config = undefined) => {
  const url = `orgs/${uuid}/agreements/generate/`;
  return await authRequest.post(url, data, config);
};

export const getOrgAgreementLink = async (uuid, agreementId, data, config = undefined) => {
  const url = `orgs/${uuid}/agreements/${agreementId}/get-agreement-link/`;
  return await authRequest.get(url, data, config);
};

export const resetOrgAgreements = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/agreements/reset-agreements/`;
  return await authRequest.post(url, config);
};

export const deleteOrgAgreement = async (uuid, agreementId, config = undefined) => {
  const url = `orgs/${uuid}/agreements/${agreementId}/`;
  return await authRequest.delete(url, config);
};

export const getOrgInvitations = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/invitations/`;
  return await authRequest.get(url, config);
};

export const putOrgInvitation = async (
  uuid,
  invitationId,
  data,
  serialize = serializeInvitation,
  config = undefined
) => {
  const url = `orgs/${uuid}/invitations/${invitationId}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const postOrgInvitation = async (
  uuid,
  data,
  serialize = serializeInvitation,
  config = undefined
) => {
  const url = `orgs/${uuid}/invitations/`;
  return await authRequest.post(url, serialize(data), config);
};

export const deleteOrgInvitation = async (uuid, invitationId, config = undefined) => {
  const url = `orgs/${uuid}/invitations/${invitationId}/`;
  return await authRequest.delete(url, config);
};

export const getOrgUsers = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/users/`;
  return await authRequest.get(url, config);
};

export const postOrgUser = async (uuid, data, serialize = serializeUser, config = undefined) => {
  const url = `orgs/${uuid}/users/`;
  return await authRequest.post(url, serialize(data), config);
};

export const putOrgUser = async (
  uuid,
  userUuid,
  data,
  serialize = serializeUser,
  config = undefined
) => {
  const url = `orgs/${uuid}/users/${userUuid}`;
  return await authRequest.put(url, serialize(data), config);
};

export const deleteOrgUser = async (uuid, userUuid, config = undefined) => {
  const url = `orgs/${uuid}/users/${userUuid}/`;
  return await authRequest.delete(url, config);
};

export const getOrgBriefs = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/briefs/`;
  return await authRequest.get(url, config);
};

export const postOrgAgreement = async (
  uuid,
  data,
  serialize = serializeAgreement,
  config = undefined
) => {
  const url = `orgs/${uuid}/agreements/generate/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postOrgProject = async (
  uuid,
  data,
  serialize = serializerProject,
  config = undefined
) => {
  const url = `orgs/${uuid}/projects/`;
  return await authRequest.post(url, serialize(data), config);
};

export const getOrgTalents = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/talents/`;
  return await authRequest.get(url, config);
};

export const getOrgTalent = async (uuid, talentUuid, config = undefined) => {
  const url = `orgs/${uuid}/talents/${talentUuid}`;
  return await authRequest.get(url, config);
};

export const getOrgTalentAssignments = async (uuid, talentUuid, config = undefined) => {
  const url = `orgs/${uuid}/talents/${talentUuid}/assignments/`;
  return await authRequest.get(url, config);
};

export const getOrgTimetrackerRelationships = async (uuid, config = undefined) => {
  const url = `orgs/${uuid}/timetracker/relationships/`;
  return await authRequest.get(url, config);
};

export const getOrgTimetrackerTimesheet = async (uuid, timesheetUuid, config = undefined) => {
  const url = `orgs/${uuid}/timetracker/timesheets/${timesheetUuid}/`;
  return await authRequest.get(url, config);
};

export const getOrgTimetrackerTask = async (uuid, taskUuid, config = undefined) => {
  const url = `orgs/${uuid}/timetracker/tasks/${taskUuid}/`;
  return await authRequest.get(url, config);
};

export const postOrgLink = async (uuid, data, config = undefined) => {
  const url = `orgs/${uuid}/links/`;
  return await authRequest.post(url, data, config);
};

export const postOrgFile = async (uuid, data, config = undefined) => {
  const url = `orgs/${uuid}/attachments/`;
  return await authRequest.post(url, data, config);
};

export const deleteOrgLink = async (uuid, linkId, config = undefined) => {
  const url = `orgs/${uuid}/links/${linkId}/`;
  return await authRequest.delete(url, config);
};

export const deleteOrgFile = async (uuid, fileId, config = undefined) => {
  const url = `orgs/${uuid}/attachments/${fileId}/`;
  return await authRequest.delete(url, config);
};
