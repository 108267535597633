/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import IconTimes from '@hiredigital/ui/Icon/icons/times.inline.svg';
import { Dialog, Transition } from '@headlessui/react';

import Styles from './Dialog.module.scss';

const DialogComponent = ({
  title,
  description,
  isOpen,
  onClose,
  onCloseComplete,
  onCancel,
  children,
  className,
  dialogProps,
  containerProps,
  titleProps,
  descriptionProps,
  bodyClass,
  // hasActions = true,
  // hasCancel = true,
  hasClose = true,
  // isConfirmLoading,
  // confirmLabel,
  // cancelLabel,
  // onConfirm,
  ...rest
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) return;
    if (!isOpen) {
      onCloseComplete?.();
    }
  }, [isMounted, isOpen]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Transition show={isOpen} appear as={Fragment}>
      <Dialog
        as='div'
        onClose={(event) => onClose?.(event)}
        className={Styles.container}
        {...containerProps}>
        <Transition.Child
          as={Fragment}
          enter={Styles.transition}
          enterFrom={Styles.enterFrom}
          enterTo={Styles.enterTo}
          leave={Styles.transition}
          leaveFrom={Styles.enterTo}
          leaveTo={Styles.enterFrom}>
          <Dialog.Overlay className={Styles.overlay} />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter={Styles.transition}
          enterFrom={Styles.leaveTo}
          enterTo={Styles.leaveFrom}
          leave={Styles.transition}
          leaveFrom={Styles.leaveFrom}
          leaveTo={Styles.leaveTo}>
          <Dialog.Panel className={classNames(Styles.panel, className)} {...dialogProps}>
            <div className={classNames(Styles.body, bodyClass)} style={{ ...rest }}>
              {hasClose && (
                <button onClick={(event) => onClose?.(event)} className={Styles.close}>
                  <IconTimes />
                </button>
              )}

              {title && (
                <Dialog.Title className={Styles.title} as='h3' {...titleProps}>
                  {title}
                </Dialog.Title>
              )}
              {description && (
                <Dialog.Description
                  className={Styles.description}
                  {...{ as: 'div', ...descriptionProps }}>
                  {description}
                </Dialog.Description>
              )}
              {children}
            </div>
            {/* {hasActions && (
              <div className={Styles.actions}>
                {hasCancel && (
                  <Button onClick={() => onCancel?.()} type={Button.Type.GRAY}>
                    {cancelLabel || `Cancel`}
                  </Button>
                )}
                <Button
                  onClick={() => onConfirm?.()}
                  type={Button.Type.BLUE}
                  isLoading={isConfirmLoading}>
                  {confirmLabel || `Confirm`}
                </Button>
              </div>
            )} */}
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default DialogComponent;
