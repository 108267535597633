import { authRequest } from './utils';

const serializerEvent = (data) => {
  const serializedData = {
    ...data,
    user: data?.user?.uuid,
    task: {
      ...data.task,
      timesheet: data?.task?.timesheet?.uuid || data?.task?.timesheet,
      user: data?.task?.user?.uuid,
    },
  };
  return serializedData;
};

export const getEvents = async (config = undefined) => {
  const url = `tracking/events/`;
  return await authRequest.get(url, config);
};

export const getOverlappingEvents = async (data, config = undefined) => {
  const url = `tracking/get-overlapping-events/`;
  return await authRequest.post(url, data, config);
};

export const postEvent = async (data, config = undefined, serialize = serializerEvent) => {
  const url = `tracking/events/`;
  return authRequest.post(url, serialize(data), config);
};

export const putEvent = async (uuid, data, config = undefined, serialize = serializerEvent) => {
  const url = `tracking/events/${uuid}/`;
  return authRequest.put(url, serialize(data), config);
};

export const deleteEvent = async (uuid, config = undefined) => {
  const url = `tracking/events/${uuid}/`;
  return await authRequest.delete(url, config);
};

export const getTrackingOrgs = async (config = undefined) => {
  const url = `tracking/relationship/orgs/`;
  return await authRequest.get(url, config);
};

export const getTrackingTimesheet = async (uuid, config = undefined) => {
  const url = `tracking/relationship/timesheets/${uuid}/`;
  return await authRequest.get(url, config);
};

export const getTrackingTask = async (uuid, config = undefined) => {
  const url = `tracking/relationship/tasks/${uuid}/`;
  return await authRequest.get(url, config);
};

export const getTrackingUnlinkedTasksEvents = async (config = undefined) => {
  const url = `tracking/relationship/get-unlinked-tasks-events/`;
  return await authRequest.get(url, config);
};
