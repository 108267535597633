import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import Item from './Item';
import NavIndicator from './NavIndicator';

const Carousel = ({ activeIndex, onSelect, children, navIndicatorClass }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.scrollContainer}>
        {children.map((child, idx) => (idx === activeIndex ? child : null))}
      </div>
      {children?.length > 1 && (
        <NavIndicator
          length={children?.length}
          activeIndex={activeIndex || 0}
          onSelect={onSelect}
          navIndicatorClass={navIndicatorClass}
        />
      )}
    </div>
  );
};

Carousel.Item = Item;

Carousel.propTypes = {
  activeIndex: PropTypes.number,
  onSelect: PropTypes.func,
  children: PropTypes.node,
  navIndicatorClass: PropTypes.string,
};

export default Carousel;
