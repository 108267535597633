import { Fragment, useState } from 'react';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Loader from '@hiredigital/ui/Loader';
import Dialog from '@hiredigital/ui/Dialog/Dialog';

import FormLayout from '../StagesLayout/FormLayout';
import MainContainer from '../StagesLayout/MainContainer';
import {
  patchBrief,
  postBriefEngagement,
  postBriefSubmit,
  postBriefConfirmUpdate,
  postBriefFile,
  postBriefLink,
  deleteBriefFile,
  deleteBriefLink,
} from '@apis/briefs';
import { putEngagement, deleteEngagement } from '@apis/engagements';
import Quill from '@hiredigital/ui/Quill/Editor';
import { BriefStage } from '@hiredigital/lib/helpers/enum';
import ActionBar from '../StagesLayout/ActionBar';
import { useCommon } from '@context/common';
import OverviewHeader from './components/overview/OverviewHeader';
import OverviewItem from './components/overview/OverviewItem';
import OverviewContent from './components/overview/OverviewContent';
import OverviewBody from './components/overview/OverviewBody';
import OverviewContainer from './components/overview/OverviewContainer';
import AdditionalForm from './components/overview/AdditionalForm';
import EngagementForm from './components/overview/EngagementForm';
import InfoAlert from './components/alert/InfoAlert';
import Styles from './Styles.module.scss';
import References from '@hiredigital/ui/Form/References';

const BriefForm = ({ brief, onAfterSubmit }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(brief?.title);
  const [description, setDescription] = useState(brief?.description);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsSaving(true);
    patchBrief(brief?.uuid, {
      title,
      description,
    })
      .then(({ data }) => {
        setIsSaving(false);
        setIsEditing(false);
        onAfterSubmit(data);
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  return (
    <OverviewContent>
      <OverviewHeader
        title={`Requirements`}
        onEdit={handleEdit}
        onSave={handleSave}
        isEditing={isEditing}
        isSaving={isSaving}
        enableCancel={false}
        enableDelete={false}
      />
      <OverviewBody direction={`column`}>
        {isEditing ? (
          <div style={{ margin: '30px 0' }}>
            <InputGroup>
              <InputContainer>
                <TextInput
                  label={`Title`}
                  name={`title`}
                  placeholder={`Give your project a descriptive title`}
                  defaultValue={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <Quill
                  legacyCompat
                  name='description'
                  label='Requirements'
                  placeholder={`Describe any project requirements`}
                  onChange={(e) => setDescription(e.target.value)}
                  defaultValue={description}
                />
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <References
                  attachments={brief.attachments}
                  links={brief.links}
                  postLinkRequest={(data, config) => postBriefLink(brief?.uuid, data, config)}
                  postFileRequest={(data, config) => postBriefFile(brief?.uuid, data, config)}
                  deleteLinkRequest={(id) => deleteBriefLink(brief?.uuid, id)}
                  deleteFileRequest={(id) => deleteBriefFile(brief?.uuid, id)}
                  // server={`briefs/${brief?.uuid}`}
                />
              </InputContainer>
            </InputGroup>
          </div>
        ) : (
          <Fragment>
            <OverviewItem title={`Title`} content={brief?.title || `Untitled Brief`} />
            <OverviewItem
              title={`Requirements`}
              content={brief?.description || `No requirements provided`}
              isHtml
            />
          </Fragment>
        )}
      </OverviewBody>
    </OverviewContent>
  );
};

const Overview = ({ initialBrief, onBack, onAfterSubmit }) => {
  const common = useCommon();

  const [engagements, setEngagements] = useState(initialBrief?.engagements);
  const [isAdding, setIsAdding] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isAdditionalShown, setIsAdditionalShown] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [engagement, setEngagement] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);
  const [isOtherBusy, setIsOtherBusy] = useState(false);

  const handleBack = () => onBack?.('/call');

  const handleEngagementAfterSave = (data) => {
    setEngagements(engagements.map((e) => (e.uuid === data.uuid ? data : e)));
    confirmBriefUpdate();
  };

  const confirmBriefUpdate = () => {
    if (initialBrief?.stage === BriefStage.CONFIRM.id) {
      postBriefConfirmUpdate(initialBrief?.uuid, {})
        .then(({ data }) => {
          onAfterSubmit?.(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleEngagementAfterDelete = (data) => {
    setEngagements(engagements.filter((e) => e.uuid !== data.uuid));
  };

  const handleAdditional = () => {
    setIsAdditionalShown(true);
    setIsAdding(true);
    postBriefEngagement(initialBrief?.uuid, {})
      .then(({ data }) => {
        setEngagements([...engagements, data]);
        setEngagement(data);
        setIsAdding(false);
      })
      .catch(() => {
        setIsAdding(false);
      });
  };

  const handleAdditionalSubmit = () => {
    setIsConfirmLoading(true);
    putEngagement(engagement?.uuid, { ...additionalData })
      .then(({ data }) => {
        setIsConfirmLoading(false);
        setIsAdditionalShown(false);
        setEngagements(
          engagements?.map((v) => {
            return v?.uuid === engagement?.uuid ? { ...data } : v;
          })
        );
      })
      .catch((error) => {
        setIsConfirmLoading(false);
        setIsAdditionalShown(false);
      });
  };

  const handleAdditionalChange = (data) => setAdditionalData(data);

  const handleSubmit = () => {
    setIsSaving(true);
    const payload = { page: initialBrief?.stage };
    postBriefSubmit(initialBrief?.uuid, payload)
      .then(({ data }) => {
        onAfterSubmit?.(data);
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  const handleCancelAdditional = () => {
    deleteEngagement(engagement?.uuid)
      .then(() => {
        setIsAdditionalShown(false);
        setEngagements(engagements.filter((v) => v?.uuid !== engagement?.uuid));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveOther = (uuid, payload) => {
    setIsOtherBusy(true);
    putEngagement(uuid, payload)
      .then(({ data }) => {
        setEngagement(data);
        setIsOtherBusy(false);
      })
      .catch((error) => {
        console.error(error);
        setIsOtherBusy(false);
      });
  };

  return (
    <Fragment>
      <Dialog
        isOpen={isAdditionalShown}
        title='Add Additional Talent or Format'
        onClose={() => setIsAdditionalShown(false)}
        maxWidth={750}>
        <div>
          <AdditionalForm
            isOtherBusy={isOtherBusy}
            engagement={engagement}
            onChange={handleAdditionalChange}
            onOtherTalentAdd={(t, { uuid }) => saveOther(uuid, { otherTalentType: t })}
            onOtherFormatAdd={(t, { uuid }) => saveOther(uuid, { otherFormat: t })}
          />
        </div>
        <div style={{ marginTop: '30px' }}>
          <Button
            onClick={handleAdditionalSubmit}
            type={Button.Type.BLUE}
            isLoading={isConfirmLoading}>
            {`Submit`}
          </Button>
          <Button onClick={() => setIsAdditionalShown(false)} type={Button.Type.LIGHT_GRAY}>
            {`Cancel`}
          </Button>
        </div>
        {/* <Button
          type={Button.Type.BLUE}
          onClick={handleAdditionalSubmit}
          isLoading={isConfirmLoading}>
          Submit
        </Button>
        <Button type={Button.Type.LIGHT_GRAY} onClick={handleCancelAdditional}>
          Cancel
        </Button> */}
      </Dialog>
      <MainContainer
        title={`Confirm Your Brief`}
        subtitle={`Review your talent and project requirements. After you confirm, we will match you to relevant talents in our network.`}
        onBack={handleBack}
        backLabel={`Back to Call`}>
        <FormLayout>
          <FormLayout.Row>
            <FormLayout.MainColumn>
              {initialBrief?.stage === BriefStage.EDIT_REVIEW.id && (
                <Fragment>
                  <p className={Styles.alertTitle}>
                    {`The changes to your brief have been submitted.`}
                  </p>
                  <InfoAlert content={`We will review the changes and get back to you shortly.`} />
                </Fragment>
              )}
              <OverviewContainer>
                <BriefForm brief={initialBrief} onAfterSubmit={onAfterSubmit} />
                {engagements?.map(
                  (e) =>
                    (e.talentType || e.otherTalentType || e.format || e.otherFormat) && (
                      <EngagementForm
                        key={e.uuid}
                        engagement={e}
                        onAfterSave={handleEngagementAfterSave}
                        onAfterDelete={handleEngagementAfterDelete}
                        enableEdit={
                          initialBrief?.stage === BriefStage.CONFIRM.id ||
                          initialBrief?.stage === BriefStage.EDIT_REVIEW.id
                        }
                      />
                    )
                )}
                <div style={{ marginTop: '40px' }}>
                  <Button onClick={handleAdditional} type={Button.Type.BLUE_OUTLINE}>
                    {`Add Additional Talent or Format`}
                  </Button>
                </div>
              </OverviewContainer>
            </FormLayout.MainColumn>
            <FormLayout.AsideColumn noBorder />
          </FormLayout.Row>
        </FormLayout>
        <ActionBar isWhite>
          <Button
            name='submit'
            type={
              initialBrief?.stage === BriefStage.EDIT_REVIEW.id
                ? Button.Type.LIGHT_GRAY
                : Button.Type.BLUE
            }
            onClick={handleSubmit}
            disabled={initialBrief?.stage === BriefStage.EDIT_REVIEW.id}>
            {isSaving && (
              <Loader
                style={{ marginRight: '10px' }}
                color={Loader.Color.WHITE}
                size={Loader.Size.SMALL}
              />
            )}
            {initialBrief?.stage === BriefStage.EDIT_REVIEW.id && `Changes Under Review`}
            {initialBrief?.stage === BriefStage.CONFIRM.id && `Confirm Brief`}
            {![BriefStage.EDIT_REVIEW.id, BriefStage.CONFIRM.id].includes(initialBrief?.stage) &&
              `Save and Continue`}
          </Button>
        </ActionBar>
      </MainContainer>
    </Fragment>
  );
};

Overview.propTypes = {};

export default Overview;
