import { memo } from 'react';
import classNames from 'classnames';
import Position from 'evergreen-ui/esm/constants/src/Position';
import { Tooltip } from 'evergreen-ui/esm/tooltip';

import { toReadableMedDateTime } from '@hiredigital/lib/helpers/date';
import Badge from '@hiredigital/ui/Badge/Badge';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import IconCheckCircle from '@hiredigital/ui/Icon/icons/check-circle.inline.svg';
import Ellipsis from '@hiredigital/ui/MultiLineEllipsis/SingleLine';

import Styles from './Styles.module.scss';

const TimeEntryColumn = ({ data, showActionColumn = true }) => {
  const title = data?.task?.name;
  const subtitle = data?.task?.timesheet?.talentTitle;
  return (
    <div className={Styles.timesheetColumn}>
      <div className={Styles.timeEntryColumn}>
        <Ellipsis className={classNames(Styles.title, !title && Styles.blank)}>
          {title || 'No task name'}
        </Ellipsis>
        <Ellipsis className={classNames(Styles.subtitle, !subtitle && Styles.blank)}>
          {subtitle || 'No assignment'}
        </Ellipsis>
      </div>
      {data?.manual && (
        <Badge color={data?.locked && showActionColumn ? 'white' : 'gray'} marginLeft='auto'>
          {`Manual`}
        </Badge>
      )}
    </div>
  );
};

const MemoTimeEntryColumn = memo(TimeEntryColumn);

const TalentTimesheetEntry = ({
  className,
  data,
  handleEdit,
  handleDelete,
  showActionColumn = true,
}) => (
  <div
    style={{ display: 'flex' }}
    className={classNames(
      className,
      Styles.fd,
      Styles.fd4,
      data?.locked && showActionColumn && Styles.locked
    )}>
    <div style={{ width: showActionColumn ? '60%' : '65%' }} className={Styles.fd1}>
      <MemoTimeEntryColumn data={data} showActionColumn={showActionColumn} />
    </div>
    <div style={{ width: '20%' }} className={Styles.fd1}>
      {data?.startTime && toReadableMedDateTime(data.startTime)}
    </div>
    <div style={{ width: '15%' }} className={Styles.fd1}>
      {data?.duration}
    </div>
    {showActionColumn && (
      <div
        style={{ width: '5%' }}
        className={classNames(Styles.actionColumn, data?.locked && Styles.locked)}>
        {!data?.locked ? (
          handleEdit &&
          handleDelete && (
            <SplitButton
              more={[
                { text: 'Edit', onClick: () => handleEdit?.(data) },
                { text: 'Delete', onClick: () => handleDelete?.(data) },
              ]}
            />
          )
        ) : (
          <Tooltip content={'Processed'} position={Position.LEFT}>
            <div>
              <IconCheckCircle style={{ display: 'block' }} />
            </div>
          </Tooltip>
        )}
      </div>
    )}
  </div>
);

export default TalentTimesheetEntry;
