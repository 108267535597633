import { useReducer, useContext, createContext } from 'react';
import Cookies from 'universal-cookie';
import { COOKIE_DOMAIN } from '../helpers/utils';

const OrgContext = createContext();
const OrgDispatchContext = createContext();

const cookies = new Cookies();

const reducer = (org, action) => {
  switch (action.type) {
    case 'UPDATE':
      if (action?.payload?.uuid) {
        cookies.set('org_uuid', action.payload.uuid, COOKIE_DOMAIN);
      } else {
        cookies.remove('org_uuid');
      }
      return action.payload;
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const OrgProvider = ({ children, initial }) => {
  const [org, dispatch] = useReducer(reducer, initial);
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <OrgDispatchContext.Provider value={dispatch}>
      {/*eslint-disable-next-line react/react-in-jsx-scope*/}
      <OrgContext.Provider value={org}>{children}</OrgContext.Provider>
    </OrgDispatchContext.Provider>
  );
};

export const setOrgUuid = (uuid) => {
  cookies.set('org_uuid', uuid, COOKIE_DOMAIN);
};
export const useOrg = () => useContext(OrgContext);
export const useOrgDispatch = () => useContext(OrgDispatchContext);
