import { useState, useRef, useMemo } from 'react';
import debounce from 'lodash/debounce';

import TextInput from '@hiredigital/ui/Input/TextInput';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import { addHttpPrefix, socialPrefixes } from '@hiredigital/lib/helpers/utils';

const Social = ({ social: initialSocial, onChangeSocial }) => {
  const [social, setSocial] = useState(initialSocial);

  const webRef = useRef();
  const linkedinRef = useRef();
  const twitterRef = useRef();
  const facebookRef = useRef();
  const instagramRef = useRef();
  const otherRef = useRef();

  const generateSocialUrl = (event, prefix = 'https://', removeBaseUrl = false) => {
    const { name, value } = event.target;
    const link =
      (removeBaseUrl
        ? value && value.replace(prefix, '')
        : value && addHttpPrefix(value, prefix)) || '';
    return link;
  };

  const handleDebounceStateChange = (value, key) => {
    const updatedSocial = { ...social, [key]: value };
    setSocial(updatedSocial);
    onChangeSocial?.(updatedSocial);
  };

  const debouncedChangeHandler = useMemo(() => debounce(handleDebounceStateChange, 500), [social]);

  const handleChangeClosure = (ref) => {
    const func = (e) => {
      const updated = generateSocialUrl(e, socialPrefixes[e.target.name]);
      ref.current.value = updated;
      debouncedChangeHandler(updated, e.target.name);
    };
    return func;
  };

  const handleChangeWebsite = handleChangeClosure(webRef);
  const handleChangeLinkedin = handleChangeClosure(linkedinRef);
  const handleChangeTwitter = handleChangeClosure(twitterRef);
  const handleChangeFacebook = handleChangeClosure(facebookRef);
  const handleChangeInstagram = handleChangeClosure(instagramRef);
  const handleChangeOther = handleChangeClosure(otherRef);

  return (
    <>
      <InputGroup>
        <InputContainer>
          <TextInput
            className={'highlight-block'}
            ref={webRef}
            name='website'
            label='Website'
            defaultValue={social?.website || ''}
            onChange={handleChangeWebsite}
          />
        </InputContainer>
      </InputGroup>
      <InputGroup>
        <InputContainer>
          <TextInput
            className={'highlight-block'}
            ref={linkedinRef}
            name='linkedin'
            label='LinkedIn'
            defaultValue={social?.linkedin || ''}
            onChange={handleChangeLinkedin}
          />
        </InputContainer>
      </InputGroup>
      <InputGroup>
        <InputContainer>
          <TextInput
            className={'highlight-block'}
            ref={twitterRef}
            name='twitter'
            label='Twitter'
            defaultValue={social?.twitter || ''}
            onChange={handleChangeTwitter}
          />
        </InputContainer>
      </InputGroup>
      <InputGroup>
        <InputContainer>
          <TextInput
            className={'highlight-block'}
            ref={facebookRef}
            name='facebook'
            label='Facebook'
            defaultValue={social?.facebook || ''}
            onChange={handleChangeFacebook}
          />
        </InputContainer>
      </InputGroup>
      <InputGroup>
        <InputContainer>
          <TextInput
            className={'highlight-block'}
            ref={instagramRef}
            name='instagram'
            label='Instagram'
            defaultValue={social?.instagram || ''}
            onChange={handleChangeInstagram}
          />
        </InputContainer>
      </InputGroup>
      <InputGroup>
        <InputContainer>
          <TextInput
            className={'highlight-block'}
            ref={otherRef}
            name='other'
            label='Other'
            defaultValue={social?.googlePlus || ''}
            onChange={handleChangeOther}
          />
        </InputContainer>
      </InputGroup>
    </>
  );
};

export default Social;
