import Styles from './NavMenu.module.scss';
import classNames from 'classnames';

const Item = ({ isActive = false, children, ...rest }) => (
  <li className={classNames(Styles.item, { [Styles.active]: isActive })} {...rest}>
    {children}
  </li>
);

const NavMenu = ({ className, activeIndex = 0, children, items }) => {
  return (
    <div className={classNames(Styles.container, className)}>
      <ul className={Styles.menus}>
        {items.map(({ caption, onClick }, idx) => (
          <Item key={idx} isActive={idx === activeIndex} onClick={() => onClick?.(idx)}>
            {caption}
          </Item>
        ))}
        {children}
      </ul>
    </div>
  );
};

export default NavMenu;
