import { Fragment, memo } from 'react';
import Styles from './Avatar.module.scss';
import { Tooltip } from 'evergreen-ui/esm/tooltip';
import Position from 'evergreen-ui/esm/constants/src/Position';
import classNames from 'classnames';

const MemberTooltip = ({
  children,
  showTooltip = false,
  content,
  tooltipPosition = Position.BOTTOM,
  tooltipText,
}) => {
  return showTooltip ? (
    <Tooltip {...{ content, position: tooltipPosition }}>{tooltipText || children}</Tooltip>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

const Type = {
  SQUARE: 0,
  CIRCLE: 1,
};

const Avatar = ({
  className,
  showTooltip,
  tooltipPosition,
  tooltipText,
  showWholeName = false,
  type = Type.SQUARE,
  size = 24,
  ...props
}) => {
  const texts = props?.name?.toUpperCase().split(' ') || [];

  return (
    <MemberTooltip {...{ showTooltip, tooltipPosition, content: tooltipText || props?.name || '' }}>
      {props.src ? (
        <img
          alt='avatar'
          className={classNames(Styles.avatar, type === Type.CIRCLE && Styles.circle, className)}
          {...props}
          style={{ height: `${size}px`, width: `${size}px`, ...props?.style }}
        />
      ) : (
        <div
          className={classNames(
            Styles.avatar,
            Styles.blank,
            type === Type.CIRCLE && Styles.circle,
            className
          )}
          {...props}
          style={{ height: `${size}px`, width: `${size}px`, ...props?.style }}>
          {showWholeName ? (
            <Fragment>{props?.name}</Fragment>
          ) : (
            <Fragment>
              {texts.length === 1 ? (
                <Fragment>{texts[0]?.slice(0, 2)}</Fragment>
              ) : (
                <Fragment>
                  {texts.length > 1 && (
                    <Fragment>
                      {texts[0]?.charAt(0)}
                      {texts[texts.length - 1]?.charAt(0)}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      )}
    </MemberTooltip>
  );
};

const MemoAvatar = memo(Avatar);

MemoAvatar.Tooltip = { Position };
MemoAvatar.Type = Type;

export default MemoAvatar;
