import { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';

import { useUser } from '@context/user';
import { getUser, putUser, postUserProfileUpdate } from '@apis/users';
import { PreferredPlatform } from '@hiredigital/lib/helpers/enum';

import Note from '../Note/Note';
import { noteLinks } from './data';

import Section from '@components/StagesLayout/Section';
import FormLayout from '@components/StagesLayout/FormLayout';
import Education from '@components/Resume/Education/Education';
import WorkExperience from '@components/Resume/Employment/Employment';
import PortfolioList from '@components/PortfolioList/PortfolioList';
import ActionBar from '@components/StagesLayout/ActionBar';

import ResumeSettings from './ResumeSettings';
import Social from '@components/Profile/Social';
import ProfileSettings from './ProfileSettings';

import Styles from './Profile.module.scss';

const PORTFOLIO_REQUIRED_COUNT = 3;
const BASIC_INFO_REQUIRED_COUNT = 4;
const RESUME_REQUIRED_COUNT = 1;

const SectionNotes = ({ isOnboarding }) => (
  <FormLayout>
    <FormLayout.Row noBottomMargin>
      <FormLayout.MainColumn>
        <Note
          description={() => (
            <Fragment>
              <p>
                {`Your profile should adhere to Hire Digital guidelines. It may be reviewed and edited by our `}
                {`moderators for presentation and clarity.`}
              </p>
            </Fragment>
          )}>
          <ul className={Styles.noteLinkList}>
            {noteLinks?.map((v, i) => (
              <li key={i}>
                <a className={Styles.noteLink} href={v.to} rel='noreferrer' target='_blank'>
                  {v.text}
                </a>
              </li>
            ))}
          </ul>
        </Note>
      </FormLayout.MainColumn>
      {isOnboarding && (
        <>
          <FormLayout.AsideColumn noBorder />{' '}
        </>
      )}
    </FormLayout.Row>
  </FormLayout>
);

const Profile = ({ scrollContainer, isOnboarding = false }) => {
  const user = useUser();

  const history = useHistory();

  const [data, setData] = useState({});

  // section required counts
  const [basicCount, setBasicCount] = useState(BASIC_INFO_REQUIRED_COUNT);
  const [resumeCount, setResumeCount] = useState(RESUME_REQUIRED_COUNT);
  const [portfolioCount, setPortfolioCount] = useState(PORTFOLIO_REQUIRED_COUNT);
  const [social, setSocial] = useState();
  const [profile, setProfile] = useState();
  const [contact, setContact] = useState();
  const [portfolio, setPortfolio] = useState();

  const [educationList, setEducationList] = useState([]);
  const [employmentList, setEmploymentList] = useState([]);
  const [itemList, setItemList] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isScrollerReady, setIsScrollerReady] = useState(false);
  const [contentWrapper, setContentWrapper] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (user) {
      getUser(user?.uuid)
        .then(({ data }) => {
          setData(data);
          setSocial(data?.social);
          setProfile(data?.profile);
          setPortfolio(data?.portfolio);
          setContact(data?.contact);
          setEducationList(data?.education);
          setEmploymentList(data?.employment);
          setIsLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setIsLoaded(true);
        });
    }
  }, [user]);

  useEffect(() => {
    const { firstName, lastName } = data;
    const phone = contact?.preferred === PreferredPlatform.PHONE.id ? +!contact?.preferred : 0;
    setBasicCount(
      +!firstName?.trim() + +!lastName?.trim() + +!profile?.description?.trim() + phone
    );
  }, [data, contact, profile]);

  useEffect(() => {
    setResumeCount(
      +!(educationList?.filter((e) => e.id).length + employmentList?.filter((e) => e.id).length)
    );
  }, [educationList, employmentList]);

  useEffect(() => {
    const len = itemList?.length || 0;
    if (len > PORTFOLIO_REQUIRED_COUNT) {
      setPortfolioCount(0);
    } else if (len <= PORTFOLIO_REQUIRED_COUNT) {
      setPortfolioCount(PORTFOLIO_REQUIRED_COUNT - len);
    }
  }, [itemList]);

  useEffect(() => {
    if (scrollContainer && contentWrapper) {
      setIsScrollerReady(true);
    }
  }, [scrollContainer, contentWrapper]);

  const isProfileCompleted = () => basicCount === 0;
  const isResumeCompleted = () => resumeCount === 0;

  const markProfileCompletion = () => {
    if (isProfileCompleted() && isResumeCompleted()) {
      postUserProfileUpdate(user?.uuid);
    }
  };

  const validate = () => {
    const err = {};

    if (!data?.firstName) {
      err.firstName = 'Enter your first name';
    }

    if (!data?.lastName) {
      err.lastName = 'Enter your last name';
    }

    if (!profile?.description) {
      err.profile = { description: 'Add your professional bio' };
    }

    if (contact?.preferred === PreferredPlatform.PHONE.id && !contact?.phoneNumber) {
      err.contact = { phoneNumber: 'Enter your phone number' };
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validate()) return;

    setIsSaving(true);
    const d = { ...data, social, profile, contact, portfolio };
    putUser(user?.uuid, d)
      .then(({ data }) => {
        setIsSaving(false);
        markProfileCompletion();
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
      });
  };

  const handleChangeUser = (user) => {
    setErrors({});
    setData(user);
  };

  const handleChangeSocial = (social) => {
    setErrors({});
    setSocial(social);
  };

  const handleChangeProfile = (profile) => {
    setErrors({});
    setProfile(profile);
  };

  const handleChangePortfolio = (portfolio) => {
    setErrors({});
    setPortfolio(portfolio);
  };

  const handleChangeContact = (contact) => {
    setErrors({});
    setContact(contact);
  };

  return (
    <Section
      getContentWrapper={setContentWrapper}
      title={user?.isTalent ? `Profile` : ''}
      subtitle={
        user?.isTalent
          ? `To increase your chances of being invited to new engagements, please submit a complete profile.`
          : ''
      }
      className={classNames(Styles.onboardingContainer)}>
      <div id={'profile'} />
      {user?.isTalent && <SectionNotes isOnboarding={isOnboarding} />}
      {isLoaded ? (
        <Fragment>
          <FormLayout>
            <FormLayout.Row noBottomMargin>
              <FormLayout.MainColumn>
                <FormLayout.LayoutHeader
                  title={`Basic Info`}
                  pillNumber={basicCount}
                  pillDescription={`Add bio and contact details`}
                  hidePill={!isOnboarding}
                />
                <ProfileSettings
                  user={data}
                  profile={profile}
                  contact={contact}
                  portfolio={portfolio}
                  onChangeUser={handleChangeUser}
                  onChangeProfile={handleChangeProfile}
                  onChangePortfolio={handleChangePortfolio}
                  onChangeContact={handleChangeContact}
                  errors={errors}
                  isOnboarding
                />
              </FormLayout.MainColumn>
              {isOnboarding && (
                <FormLayout.AsideColumn className={Styles.nameAsideColumn}>
                  <p className={Styles.labelMuted}>
                    {`Your `}
                    <i>{`First Name `}</i>
                    {`and `}
                    <i>{`Last Name `}</i>
                    {`should be in Title Case.`}
                  </p>
                </FormLayout.AsideColumn>
              )}{' '}
            </FormLayout.Row>
            <FormLayout.Row>
              <FormLayout.MainColumn>
                <Social social={social} onChangeSocial={handleChangeSocial} />
              </FormLayout.MainColumn>
              {isOnboarding && <FormLayout.AsideColumn noBorder />}
            </FormLayout.Row>

            <Fragment>
              <div id={'resume'} />
              <FormLayout.Row>
                <FormLayout.MainColumn>
                  <FormLayout.LayoutHeader
                    title={`Resume`}
                    subtitle={() => (
                      <span>
                        {`We recommend that you add your `}
                        <b>{`complete `}</b>
                        {`resume. This will increase your chances of being matched to engagements.`}
                      </span>
                    )}
                    pillNumber={resumeCount}
                    pillDescription={`Add Work Experience`}
                    hidePill={!isOnboarding}
                  />
                  <div>
                    <WorkExperience user={data} onListChange={setEmploymentList} />
                  </div>
                  <div>
                    <Education user={data} onListChange={setEducationList} />
                  </div>
                </FormLayout.MainColumn>
                {isOnboarding && (
                  <FormLayout.AsideColumn>
                    <ul className={Styles.instructionList}>
                      <li className={Styles.labelMuted}>
                        {`The `}
                        <b>
                          <i>{`Job Title `}</i>
                        </b>
                        {`and `}
                        <b>
                          <i>{`Company `}</i>
                        </b>
                        {`should be stated in Title Case, in full. No abbreviation.`}
                      </li>
                      <li className={Styles.labelMuted}>
                        {`The `}
                        <b>
                          <i>{`Job Description`}</i>
                        </b>
                        {` field is the best section to tell clients about what you've done. Our most successful talents write about 150-200 words per job entry.`}
                      </li>
                      <li className={Styles.labelMuted}>
                        {`Open the`}
                        <b>
                          <i>{`Job Description`}</i>
                        </b>
                        {`with a summary of the greatest impact you achieved on the job.`}
                      </li>
                      <li className={Styles.labelMuted}>
                        {`In order of priority, use`}
                        <i>{`“-”`}</i>
                        {`(bullet points) to list each major job scope in detail. State your achievements. Where possible, indicate key results achieved.`}
                      </li>
                    </ul>
                  </FormLayout.AsideColumn>
                )}
              </FormLayout.Row>

              <ResumeSettings
                isOnboarding={isOnboarding}
                requestHidden={portfolio?.requestHidden}
                onChange={(v) => setPortfolio({ ...portfolio, requestHidden: v })}
              />

              <div id={'portfolio'} />
              <FormLayout.Row>
                <FormLayout.MainColumn>
                  <FormLayout.LayoutHeader
                    title={`Portfolio`}
                    subtitle={() => (
                      <span>
                        {`We recommend that you add `}
                        <b>{`at least ${PORTFOLIO_REQUIRED_COUNT} works `}</b>
                        {`cross various formats to your portfolio. The more works you add and the better you describe them, the higher your chances of receiving project opportunities.`}
                      </span>
                    )}
                    pillNumber={portfolioCount}
                    pillDescription={`Add Portfolio Items`}
                    hidePill={!isOnboarding}
                  />
                  <div>
                    {isScrollerReady && (
                      <PortfolioList
                        useContentWrapper={() => contentWrapper}
                        useScrollContainer={() => scrollContainer}
                        type={PortfolioList.Type.USER}
                        resourceUuid={user?.uuid}
                        onListChange={setItemList}
                      />
                    )}
                  </div>
                </FormLayout.MainColumn>
                {isOnboarding && (
                  <FormLayout.AsideColumn>
                    <ul className={Styles.instructionList}>
                      <li className={Styles.labelMuted}>
                        {`Check that each item has an `}
                        <b>
                          <i>{`Job Title `}</i>
                        </b>
                        {`Image.`}
                      </li>
                      <li className={Styles.labelMuted}>
                        {`Add a `}
                        <b>
                          <i>{`Description `}</i>
                        </b>
                        {`o each item to explain the objective and how your work achieves it.`}
                      </li>
                      <li className={Styles.labelMuted}>
                        {`The `}
                        <b>
                          <i>{`Title `}</i>
                        </b>
                        {`of each item is usually the project headline. It should be in Title Case.`}
                      </li>
                      <li className={Styles.labelMuted}>
                        {`Move the items that best represent your specialisation to the top.`}
                      </li>
                      <li className={Styles.labelMuted}>
                        {`To ensure that clients have immediate access to your past works, author pages, external portfolio links or short URLs will not be accepted.`}
                      </li>
                      <li className={Styles.labelMuted}>
                        {`Items that you check `}
                        <b>
                          <i>{`Keep Private `}</i>
                        </b>
                        {`will be visible only to Hire Digital and to clients whose briefs match your profile.`}
                      </li>
                    </ul>
                  </FormLayout.AsideColumn>
                )}
              </FormLayout.Row>
            </Fragment>
          </FormLayout>

          <ActionBar>
            <Button type={Button.Type.BLUE} onClick={handleSubmit} isLoading={isSaving}>
              {`Save Profile`}
            </Button>
            {isOnboarding && (
              <Button type={Button.Type.BLUE_OUTLINE} onClick={() => history.push('/guide')}>
                {`Return to Onboarding`}
              </Button>
            )}
          </ActionBar>
        </Fragment>
      ) : (
        <Loader />
      )}
    </Section>
  );
};

export default Profile;
