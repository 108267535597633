/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Tab from '@hiredigital/ui/TabVertical';
import Styles from './Styles.module.scss';

import UserIcon from '@hiredigital/ui/Icon/tritone/user.inline.svg';
import CertificateIcon from '@hiredigital/ui/Icon/tritone/certificate.inline.svg';
import MoneyIcon from '@hiredigital/ui/Icon/tritone/money.inline.svg';
import CheckIcon from '@hiredigital/ui/Icon/tritone/check.inline.svg';

import NewAccountToast from './components/NewAccountToast';
import BasicForm from './components/BasicForm';
import EducationForm from './components/EducationForm';
import WorkForm from './components/WorkForm';
import RateForm from './components/RateForm';

import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button';
import Password from '@hiredigital/ui/Input/Password/Password';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import { toast, Toaster } from 'react-hot-toast';

const IndexForm = ({ selectedIndex, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasUserUuid = false;

  const handleNewAccount = () => {
    setIsOpen(true);
  };

  const handleSaveNewAccount = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (hasUserUuid) return;
    if (selectedIndex > 0) {
      toast(<NewAccountToast onLinkClick={handleNewAccount} />, {
        id: 'new-account',
      });
    }
  }, [selectedIndex]);

  return (
    <Tab selectedIndex={selectedIndex} onChange={(c) => onChange?.(c)} vertical>
      <Toaster />
      <Dialog
        style={{ minWidth: 500 }}
        isOpen={isOpen}
        title='Create Account'
        onClose={() => setIsOpen(false)}>
        <p
          style={{
            marginBottom: '15px',
          }}>
          {`Creating an account will help you... Lorem ipsum dolor sit amet, consectetur adipiscing elit, `}
          {`sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
        </p>
        <InputContainer>
          <Password label={`Password`} />
        </InputContainer>
        <div className={Styles.modalFooter}>
          <Button onClick={() => setIsOpen(false)}>{`Continue`}</Button>
          <Button type={Button.Type.BLUE} onClick={handleSaveNewAccount}>
            {`Create Account`}
          </Button>
        </div>
      </Dialog>
      <Tab.List>
        <Tab.Item
          icon={UserIcon}
          completedIcon={CheckIcon}
          caption={`Basic Details`}
          isCompleted={selectedIndex > 0}
        />
        <Tab.Item
          icon={UserIcon}
          completedIcon={CheckIcon}
          caption={`Education`}
          isCompleted={selectedIndex > 1}
        />
        <Tab.Item
          icon={CertificateIcon}
          completedIcon={CheckIcon}
          caption={`Work Experience`}
          isCompleted={selectedIndex > 2}
        />
        <Tab.Item
          icon={MoneyIcon}
          completedIcon={CheckIcon}
          caption={`Rate and Availability`}
          isCompleted={selectedIndex > 3}
        />
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <BasicForm />
        </Tab.Panel>
        <Tab.Panel>
          <EducationForm />
        </Tab.Panel>
        <Tab.Panel>
          <WorkForm />
        </Tab.Panel>
        <Tab.Panel>
          <RateForm />
        </Tab.Panel>
      </Tab.Panels>
    </Tab>
  );
};

export default IndexForm;
