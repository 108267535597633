import { useState, useEffect, useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Cookies from 'universal-cookie';
import TagManager from 'react-gtm-module';

import { useCommon, useCommonDispatch } from '@context/common';
import { useUser, useUserDispatch } from '@context/user';
import { useOrg, useOrgDispatch } from '@context/org';
import { useTeamDispatch } from '@context/team';
import { useNotifications } from '@context/notifications';
import { useChatControllerDispatch } from '@context/chatController';
import {
  getDemographics,
  // getCountries,
  // getLanguages,
  getFormats,
  // getTimezones,
  getTalentTypes,
  // getTopics,
  getCurrency,
} from '@apis/common';
import { getMe } from '@apis/users';
import { getOrganization, getOrgBriefs } from '@apis/organizations';
import { getTeam, getTeamClients, getTeams } from '@apis/teams';
import { getClients } from '@apis/users';
import { postBrief } from '@apis/briefs';

import { countryList } from '../../data/countryList';
import { timezoneList } from '../../data/timezoneList';
import { languageList } from '../../data/languageList';
import { topicList } from '../../data/topicList';

import { UserStatus, OrganizationStatus, BriefStatus } from '@hiredigital/lib/helpers/enum';

import * as device from '../../lib/device';
import * as gtm from '../../lib/gtm';

import { COOKIE_DOMAIN } from '@helpers/utils';

const cookies = new Cookies();
const tagManagerArgs = {
  gtmId: process.env.GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const Helper = () => {
  const commonDispatch = useCommonDispatch();
  const userDispatch = useUserDispatch();
  const orgDispatch = useOrgDispatch();
  const teamDispatch = useTeamDispatch();
  const chatListDispatch = useChatControllerDispatch();
  const { loadNotifications } = useNotifications();
  const history = useHistory();
  const org = useOrg();
  const common = useCommon();
  const user = useUser();
  const [isLoaded, setIsLoaded] = useState(false);

  // const createInitialBrief = useCallback(async () => {
  //   const data = {
  //     organization: org?.uuid,
  //     status: BriefStatus.DRAFT.id,
  //     creator: user?.uuid,
  //     currencyCode: org?.payment?.currency || 'SGD',
  //     languages: [{ id: 19, name: 'English (UK)' }],
  //   };
  //   try {
  //     const { data } = await postBrief(data);
  //     history.push(`/briefs/${data?.uuid}`);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [org, user, history]);

  const handleInitialBriefRedirect = useCallback(() => {
    if (org?.uuid) {
      getOrgBriefs(org.uuid)
        .then(({ data }) => {
          if (data?.results?.length > 0) {
            history.replace(`/briefs/${data.results[0].uuid}`);
          } else {
            history.replace(`/briefs/new`);
            // createInitialBrief();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [org, history]);

  const loadAuthenticatedUser = async () => {
    try {
      const userResponse = await getMe();
      userDispatch({
        type: 'UPDATE',
        payload: userResponse?.data,
      });
      const userUuid = userResponse?.data?.uuid;
      cookies.set('user_uuid', userUuid, COOKIE_DOMAIN);
    } catch (e) {
      if (e.response?.status === 401) {
        cookies.remove('user_uuid');
        try {
          history.push(`/logout`);
        } catch (e) {
          // Force logout
          window.location.href = '/logout';
        }
      } else {
        console.error(e);
      }
    }
  };

  const loadTeamData = async () => {
    const teamUuid = cookies.get('team_uuid');
    if (teamUuid) {
      try {
        const teamResponse = await getTeam(teamUuid);
        cookies.set('team_uuid', teamResponse?.data?.uuid, COOKIE_DOMAIN);
        teamDispatch({
          type: 'UPDATE',
          payload: teamResponse?.data,
        });
      } catch (error) {
        cookies.remove('team_uuid');
        console.error(error);
      }
    }

    commonDispatch({
      type: 'UPDATE_TEAM_COMPLETION',
      payload: true,
    });
  };

  const getOrgUuid = () => cookies.get('org_uuid') || user?.orgs?.[0]?.uuid;

  const loadOrgData = async () => {
    const orgUuid = getOrgUuid();
    if (orgUuid) {
      try {
        const orgResponse = await getOrganization(orgUuid);
        // cookies.set('org_uuid', orgUuid, COOKIE_DOMAIN);
        orgDispatch({
          type: 'UPDATE',
          payload: orgResponse?.data,
        });
      } catch (error) {
        cookies.remove('org_uuid');
        console.error(error);
      }
    }

    commonDispatch({
      type: 'UPDATE_ORG_COMPLETION',
      payload: true,
    });
  };

  const loadCommonData = async () => {
    try {
      const orgUuid = getOrgUuid();
      const userUuid = user?.uuid;
      const reponses = await Promise.all([
        getDemographics({
          params: {
            org_uuid: orgUuid,
            user_uuid: userUuid,
          },
        }),
        // getCountries(),
        // getLanguages(),
        // getTimezones(),
        getFormats(),
        getTalentTypes(),
        // getTopics(),
      ]);

      const demographics = reponses?.[0]?.data || {};
      dispatchCurrency(demographics.currency);

      commonDispatch({
        type: 'UPDATE',
        payload: {
          ...common,
          ...demographics,
          countries: countryList,
          languages: languageList,
          timezones: timezoneList,
          formats: reponses?.[1]?.data?.results,
          talentTypes: reponses?.[2]?.data?.results,
          topics: topicList,
        },
      });

      commonDispatch({
        type: 'UPDATE_OTHER_DATA_COMPLETION',
        payload: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const loadDemographicsAndCurrency = async () => {
    try {
      const orgUuid = getOrgUuid();
      const userUuid = user?.uuid;
      const response = await getDemographics({
        params: {
          org_uuid: orgUuid,
          user_uuid: userUuid,
        },
      });

      const demographics = response?.data || {};
      dispatchCurrency(demographics.currency);

      commonDispatch({
        type: 'UPDATE',
        payload: demographics,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Only run when isCurrencyLoaded is reset to false after initial load.
    if (!common?.isOthersLoaded) return;
    if (!common?.isCurrencyLoaded) loadDemographicsAndCurrency();
  }, [common?.isCurrencyLoaded]);

  const loadTeamsData = async () => {
    try {
      const reponses = await getTeams();
      userDispatch({
        type: 'UPDATE_TEAMS',
        payload: reponses?.data?.results,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const loadClientsData = async () => {
    const teamUuid = cookies.get('team_uuid');
    try {
      const params = { limit: 30, ordering: 'is_project_manager' };
      if (teamUuid) {
        const reponses = await getTeamClients(teamUuid, { params });
        teamDispatch({
          type: 'UPDATE_CLIENTS',
          payload: reponses?.data?.results,
        });
      } else {
        const reponses = await getClients({ params });
        userDispatch({
          type: 'UPDATE_CLIENTS',
          payload: reponses?.data?.results,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const dispatchCurrency = (data) => {
    commonDispatch({
      type: 'UPDATE_USER_CURRENCY',
      payload: { ...data },
    });

    commonDispatch({
      type: 'UPDATE_CURRENCY_COMPLETION',
      payload: true,
    });
  };

  useEffect(() => {
    if (!common?.isUserRequested) return;
    if (user?.isTalent && user?.status === UserStatus.ONBOARDING.id) {
      history.push('/guide');
      return;
    }

    if (
      user?.isClient &&
      common?.isOrgRequested &&
      org?.status === OrganizationStatus.ONBOARDING.id
    ) {
      handleInitialBriefRedirect();
      return;
    }

    if (!user?.isTalent && !user?.isClient) {
      // toast.error(`You do not have access to this page.`);
      history.push(`/logout`);
      return;
    }

    // history.push('/projects');
    // Removed projects pushed, fallback handled in App Routing
  }, [history, common, user, org, common?.isUserRequested]);

  useEffect(() => {
    if (cookies.get('token')) {
      loadAuthenticatedUser();
    }
    chatListDispatch({ type: 'INITIATE_LIST' });
    setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user.uuid) {
      commonDispatch({
        type: 'UPDATE_AUTH_USER_COMPLETION',
        payload: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // useEffect(() => {
  //   if (!user?.uuid) return;
  //   loadUserCurrency(user?.profile?.currency || 'SGD');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user?.profile?.currency, user?.uuid]);

  useEffect(() => {
    if (!common?.isUserRequested) {
      return;
    }

    if (user?.isTalent) {
      loadTeamData();
      loadTeamsData();
      loadClientsData();
    }

    if (user?.isClient) {
      loadOrgData(user);
    }

    loadCommonData();
    loadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uuid, common?.isUserRequested]);

  useEffect(() => {
    if (!common?.isUserRequested) {
      return;
    }

    if (
      user?.isClient &&
      common?.isOrgRequested &&
      common?.isCurrencyLoaded &&
      common?.isOthersLoaded
    ) {
      commonDispatch({
        type: 'UPDATE_SHARED_DATA_READY',
        payload: true,
      });
      return;
    }

    if (
      user?.isTalent &&
      common?.isTeamRequested &&
      common?.isCurrencyLoaded &&
      common?.isOthersLoaded
    ) {
      commonDispatch({
        type: 'UPDATE_SHARED_DATA_READY',
        payload: true,
      });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    common?.isUserRequested,
    common?.isOrgRequested,
    common?.isTeamRequested,
    common?.isCurrencyLoaded,
    common?.isOthersLoaded,
  ]);

  // const loadUserCurrency = async (code = 'SGD') => {
  //   if (!Currency?.values?.some((v) => v.id === code)) {
  //     code = 'SGD';
  //   }

  //   const { data } = await getCurrency(code);
  //   commonDispatch({
  //     type: 'UPDATE_USER_CURRENCY',
  //     payload: { ...data, code },
  //   });

  //   commonDispatch({
  //     type: 'UPDATE_CURRENCY_COMPLETION',
  //     payload: true,
  //   });
  // };

  // This helper util will pull some async app data

  return (
    <>
      <Toaster /> {isLoaded && device.load()}
    </>
  );
};

export default memo(Helper);
