import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@hiredigital/ui/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';
import TextInput from '@hiredigital/ui/Input/TextInput';

// import { authRequest } from '@apis/utils';
import { postUserEducation, putUserEducation } from '@apis/users';

//import DateInput from '@components/Form/Input/DateInput';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import Quill from '@hiredigital/ui/Quill/Editor';
import Styles from '../Resume.module.scss';
import convert from 'htmr';
import { getDuration, getDates } from '@hiredigital/lib/helpers/date';
import isAfter from 'date-fns/isAfter';
import { checkDateValidity } from '@hiredigital/lib/helpers/date';
import format from 'date-fns/format';

const makeValidDate = (month, year) => {
  return month && year ? new Date(year, month - 1, 1) : null; // month is zero-based
};

const EducationItem = (props) => {
  const [institution, setInstitution] = useState(props.education?.institution || '');
  const [startMonth, setStartMonth] = useState(props.education?.startMonth || null);
  const [startYear, setStartYear] = useState(props.education?.startYear || null);
  const [endMonth, setEndMonth] = useState(props.education?.endMonth || null);
  const [endYear, setEndYear] = useState(props.education?.endYear || null);
  const [description, setDescription] = useState(props.education?.description || null);
  const [major, setMajor] = useState(props.education?.major || null);
  const [isEdit, setIsEdit] = useState(!props.education?.id);
  const [isMounted, setIsMounted] = useState(false);
  const [errors, setErrors] = useState({});
  const [startDate, setStartDate] = useState(
    makeValidDate(props.education?.startMonth, props.education?.startYear)
  );
  const [endDate, setEndDate] = useState(
    makeValidDate(props.education?.endMonth, props.education?.endYear)
  );
  const [qualification, setQualification] = useState(props.education?.qualification || null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const populateFields = (data) => {
    setQualification(data?.qualification || '');
    setInstitution(data?.institution || '');
    setStartMonth(data?.startMonth || null);
    setEndMonth(data?.endMonth || null);
    setStartYear(data?.startYear || null);
    setEndYear(data?.endYear || null);
    setMajor(data?.major || '');
    setDescription(data?.description || '');
    setStartDate(makeValidDate(data?.startMonth, data?.startYear));
    setEndDate(makeValidDate(data?.endMonth, data?.endYear));
  };

  const handleEdit = () => setIsEdit(true);

  useEffect(() => {
    if (!startDate) return;
    setErrors({});
    setStartYear(startDate ? format(startDate, 'yyyy') : null);
    setStartMonth(startDate ? format(startDate, 'M') : null);
  }, [startDate]);

  useEffect(() => {
    if (!endDate) return;
    setErrors({});
    setEndYear(endDate ? format(endDate, 'yyyy') : null);
    setEndMonth(endDate ? format(endDate, 'M') : null);
  }, [endDate]);

  const handleCancel = () => {
    if (isEdit) {
      populateFields(props.education);
      setIsEdit(false);
    }

    if (!props.education?.id) {
      props.updateEducation();
    }
  };

  const validate = () => {
    let err = {};
    if (!qualification) {
      err = { ...err, qualification: 'Enter the name of the degree.' };
    }

    if (!institution) {
      err = { ...err, institution: 'Enter the name of the institution.' };
    }

    if (!major) {
      err = { ...err, major: 'Enter the name of major.' };
    }

    if (!startDate) {
      err = { ...err, startDate: 'Enter start date.' };
    }

    if (isAfter(startDate, new Date())) {
      err = { ...err, startDate: 'Invalid start date.' };
    }

    if (!checkDateValidity(startDate)) {
      err = { ...err, startDate: 'Invalid start date.' };
    }

    if (startDate && endDate && isAfter(startDate, endDate)) {
      err = { ...err, startDate: 'End date should be later than Start date.' };
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const payload = {
      qualification,
      institution,
      major,
      startMonth,
      startYear,
      endMonth,
      endYear,
      description,
    };

    if (validate()) {
      setIsLoading(true);
      try {
        let response;
        if (props.education?.id) {
          response = await putUserEducation(props.user?.uuid, props.education?.id, payload);
          props.updateEducation?.();
        } else {
          response = await postUserEducation(props.user?.uuid, payload);
          props.updateEducation?.(response.data);
        }
        populateFields(response.data);
      } catch (e) {
      } finally {
        setIsLoading(false);
        setIsEdit(false);
      }
    }
  };

  return (
    <Card.Item>
      {isMounted && (
        <div>
          {isEdit && (
            <div>
              <div className={Styles.form}>
                <InputGroup>
                  <InputContainer>
                    <TextInput
                      name='qualification'
                      label='Degree'
                      error={errors.qualification}
                      defaultValue={qualification}
                      onChange={(e) => setQualification(e.target.value)}
                    />
                  </InputContainer>

                  <InputContainer>
                    <TextInput
                      name='institution'
                      label='Institution'
                      error={errors.institution}
                      defaultValue={institution}
                      onChange={(e) => setInstitution(e.target.value)}
                    />
                  </InputContainer>
                </InputGroup>
                <InputGroup>
                  <InputContainer>
                    <TextInput
                      name='major'
                      label='Major'
                      error={errors.major}
                      defaultValue={major}
                      onChange={(e) => setMajor(e.target.value)}
                    />
                  </InputContainer>
                  <InputGroup>
                    <InputContainer>
                      {/* <DateInput
                        name='startDate'
                        label='From'
                        error={(() => {
                          if (!this.state.dateInOrder) return 'Start date is after end date';
                          if (startMonth && startYear) {
                            return (
                              this.state.submitted && isDateValid(startMonth, startYear).error
                            );
                          }
                          return this.state.submitted && 'Enter a start date';
                        })()}
                        month={this.state.startMonth}
                        year={this.state.startYear}
                        onChange={this.handleStartDateChange}
                      /> */}
                      <DatePicker
                        id='edStartDate'
                        name='startDate'
                        label='From'
                        dateFormat='MM/yyyy'
                        error={errors.startDate}
                        onChange={setStartDate}
                        // maxDate={this.state.endDate}
                        value={startDate}
                        showMonthYearPicker
                      />
                    </InputContainer>

                    <InputContainer>
                      {/* <DateInput
                        name='endDate'
                        label='To'
                        error={
                          endMonth && endYear
                            ? this.state.submitted && isDateValid(endMonth, endYear).error
                            : null
                        }
                        month={this.state.endMonth}
                        year={this.state.endYear}
                        onChange={this.handleEndDateChange}
                      /> */}
                      <DatePicker
                        id='edEndDate'
                        name='endDate'
                        label='To'
                        dateFormat='MM/yyyy'
                        onChange={setEndDate}
                        value={endDate}
                        showMonthYearPicker
                      />
                    </InputContainer>
                  </InputGroup>
                </InputGroup>
                <InputContainer>
                  <Quill
                    legacyCompat
                    name='description'
                    label='Description'
                    placeholder='Description'
                    defaultValue={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </InputContainer>
              </div>

              <div className={Styles.row}>
                <Button
                  name='delete'
                  type={Button.Type.WHITE}
                  isLoading={props.isDeleting}
                  onClick={() => props.deleteEducation?.(props.education)}>
                  {`Delete`}
                </Button>

                <div className={Styles.action}>
                  <Button
                    name='cancel'
                    type={Button.Type.WHITE_BLUE_OUTLINE}
                    onClick={handleCancel}>
                    {`Cancel`}
                  </Button>

                  <Button
                    name='submit'
                    type={Button.Type.BLUE}
                    onClick={handleSubmit}
                    isLoading={isLoading}>
                    {`Save`}
                  </Button>
                </div>
              </div>
            </div>
          )}

          {!isEdit && (
            <div className={Styles.listPreview}>
              <div className={Styles.row}>
                <div>
                  <p className={Styles.title}>{`${qualification}${major && `, ` + major}`}</p>
                  <p className={Styles.institution}>{institution}</p>
                  <p className={Styles.timeframe}>
                    {`${getDates(startMonth, startYear, endMonth, endYear)} ${getDuration(
                      startMonth,
                      startYear,
                      endMonth,
                      endYear
                    )}`}
                  </p>
                </div>
                <div className={Styles.action}>
                  <Button name='Edit' type={Button.Type.BLUE_OUTLINE} onClick={handleEdit}>
                    {`Edit`}
                  </Button>
                </div>
              </div>
              {description && <div className={Styles.description}>{convert(description)}</div>}
            </div>
          )}
        </div>
      )}
    </Card.Item>
  );
};

EducationItem.propTypes = {
  user: PropTypes.object,
  education: PropTypes.object,
  isDeleting: PropTypes.bool,
  updateEducation: PropTypes.func,
  deleteEducation: PropTypes.func,
};

export default EducationItem;
