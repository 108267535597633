import { useState } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import TextArea from '@hiredigital/ui/Input/TextArea';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import { patchUser } from '@apis/users';
import { useCommon } from '@context/common';

import PhoneNumber from '@hiredigital/ui/PhoneNumber';
import toast from 'react-hot-toast';

const PaymentDetails = ({ user: initialUser }) => {
  const common = useCommon();
  const [isSaving, setIsSaving] = useState(false);
  const [user, setUser] = useState(initialUser);
  const [isValidPhone, setIsValidPhone] = useState(true);

  let saveTimeout;

  const handleContactChange = (event, valueKey) => {
    const { name, value } = event.target;
    setUser({ ...user, contact: { ...user.contact, [name]: valueKey ? value[valueKey] : value } });
  };

  const handlePhoneChange = (value, valueKey) => {
    setUser({ ...user, contact: { ...user.contact, [valueKey]: value } });
  };

  const handleAddressChange = (event, valueKey) => {
    const { name, value } = event.target;
    setUser({ ...user, address: { ...user.address, [name]: valueKey ? value[valueKey] : value } });
  };

  const handleAutoSave = (timeout = 3000) => {
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }

    saveTimeout = setTimeout(() => {
      handleSubmit();
    }, timeout);
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      if (isValidPhone) {
        await patchUser(user?.uuid, user);
        toast.success('Your payment details was updated successfully.', {
          id: 'payment',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => setIsSaving(false), 250);
    }
  };

  return (
    <Card>
      <Card.Header title={`Payment Details`} />
      <Card.Note>{`Your payment details will be used for bank verification and invoices.`}</Card.Note>
      <Card.Item>
        <InputContainer>
          <TextInput
            className={'highlight-block'}
            name='name'
            label='Full Name'
            defaultValue={user?.contact?.name}
            onChange={handleContactChange}
          />
        </InputContainer>
        <InputGroup>
          <InputContainer>
            {common?.countries && (
              <PhoneNumber
                className={'highlight-block'}
                phoneCountry={user?.contact?.phoneCountry}
                phoneNumber={user?.contact?.phoneNumber}
                onPhoneCountryChange={(v) => handlePhoneChange(v, 'phoneCountry')}
                onPhoneNumberChange={(v) => handlePhoneChange(v, 'phoneNumber')}
                getCountries={async () => ({ data: common.countries })}
                onValidate={setIsValidPhone}
              />
            )}
          </InputContainer>
        </InputGroup>
        <InputContainer>
          <TextArea
            className={'highlight-block'}
            name='address'
            label='Address'
            placeholder='Address'
            defaultValue={user?.address?.address}
            onChange={handleAddressChange}
          />
        </InputContainer>
        <InputGroup>
          <InputContainer>
            <TextInput
              className={'highlight-block'}
              name='postalCode'
              label='Postal Code'
              defaultValue={user?.address?.postalCode}
              onChange={handleAddressChange}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              className={'highlight-block'}
              name='city'
              label='City'
              defaultValue={user?.address?.city}
              onChange={handleAddressChange}
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <TextInput
              className={'highlight-block'}
              name='region'
              label='State/Region'
              defaultValue={user?.address?.region}
              onChange={handleAddressChange}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              className={'highlight-block'}
              name='country'
              label='Country'
              defaultValue={user?.address?.country}
              onChange={handleAddressChange}
            />
          </InputContainer>
        </InputGroup>
      </Card.Item>
      <Card.Footer right>
        <Button
          onClick={() => handleAutoSave(0)}
          name='submit'
          type={Button.Type.BLUE}
          isLoading={isSaving}>
          {`Save Details`}
        </Button>
      </Card.Footer>
    </Card>
  );
};
PaymentDetails.propTypes = {
  user: PropTypes.object,
};

export default PaymentDetails;
