import convert from 'htmr';
import Styles from './OverviewStyles.module.scss';

const OverviewItem = ({ title, content, children, isHtml = false, isLink = false, ...props }) => (
  <div className={Styles.item} {...props}>
    <p className={Styles.label}>{title}</p>
    {isHtml && <div>{convert(content)}</div>}
    {isLink && (
      <a href={content} className={Styles.link}>
        {content}
      </a>
    )}
    {!isHtml && !isLink && !children && <p>{content}</p>}
    {children}
  </div>
);

OverviewItem.propTypes = {};

export default OverviewItem;
