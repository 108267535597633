import { useState, memo } from 'react';
import classNames from 'classnames';

import Portal from '../Portal/Portal';
import Loader from '../Loader/Loader';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import Styles from './FilePreview.module.scss';

const MemoLoader = memo(Loader);

// This should replace ImageViewer

const FilePreview = memo(({ title, description, preview, isOpen, setIsOpen, ...props }) => {
  const [isImageRendering, setIsImageRendering] = useState(true);
  const [isZoomed, setIsZoomed] = useState(false);
  const [clickPos, setClickPos] = useState({});

  const handleImageLoad = (e) => {
    setTimeout(() => setIsImageRendering(false), 500);
  };

  const zoomStyle = {
    cursor: isZoomed ? 'zoom-out' : 'zoom-in',
    transform: `${isZoomed ? 'scale(2,2)' : 'scale(1,1)'} translate3d(${clickPos.x}px, ${
      clickPos.y
    }px, 0px)`,
    transition: 'transform 200ms ease 0s',
  };

  const handleClickPosition = (e) => {
    if (isZoomed) {
      setClickPos({ x: 0, y: 0 });
    } else {
      const top = e.target.offsetTop + 80;
      const left = e.target.offsetLeft;
      const dx = (e.target.width / 2 - (e.clientX - left)) / 2;
      const dy = (e.target.height / 2 - (e.clientY - top)) / 2;
      setClickPos({ x: dx, y: dy });
    }
    setIsZoomed(!isZoomed);
  };

  const handleDrag = (e) => {
    const dx = clickPos.x + e.movementX;
    const dy = clickPos.y + e.movementY;
    setClickPos({ x: dx, y: dy });
  };

  return (
    <Portal>
      {isOpen && (
        <div className={Styles.container} {...props}>
          <div className={Styles.header}>
            <div className={Styles.title}>{title}</div>

            <div onClick={() => setIsOpen(false)} className={Styles.close}>
              <IconClose className={Styles.closeIcon} />
            </div>
          </div>
          <div className={Styles.body}>
            <div className={Styles.row}>
              <img
                className={Styles.image}
                style={zoomStyle}
                src={preview}
                alt={title}
                onMouseDown={handleDrag}
                onClick={handleClickPosition}
                onLoad={handleImageLoad}
                draggable={false}
              />
              <div
                className={classNames(
                  Styles.imageLoaderContainer,
                  isImageRendering && Styles.isVisible
                )}>
                <div className={Styles.imageLoader}>
                  <MemoLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
});

export default FilePreview;
