import { memo } from 'react';
import PropTypes from 'prop-types';
import Styles from './Table.module.scss';
import classNames from 'classnames';

const PageNavIcon = memo(({ icon, repeat = 1 }) => {
  return Array.from({ length: repeat }).map((item, idx) => {
    return <span className={classNames(Styles.pageNavigationIcon, Styles[icon])} key={idx} />;
  });
});

PageNavIcon.propTypes = {
  icon: PropTypes.string,
  repeat: PropTypes.number,
};

export default PageNavIcon;
