import { Fragment, useState, useEffect } from 'react';
import Styles from './ProjectsList.module.scss';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { getOrgProjects } from '@apis/organizations';
import { postProjectAddTalent } from '@apis/projects';
import GroupPicture from '@hiredigital/ui/GroupPicture';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';

const ProjectsList = ({ orgUuid, talentUuid, assignment }) => {
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [items, setItems] = useState([]);
  const [isBusyProjects, setIsBusyProjects] = useState([]);
  const [newlyAddedProjects, setNewlyAddedProjects] = useState([]);

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = () => {
    const params = { page: meta?.nextPage };
    getOrgProjects(orgUuid, { params })
      .then(({ data }) => {
        setMeta(data?.meta);
        setItems(data?.results || []);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index) => !meta?.nextPage || index < items.length;

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = meta?.nextPage ? items.length + 1 : items.length;

  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextLoading ? () => {} : loadItems;

  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    if (!isItemLoaded(index)) {
      return <Loader color={Loader.Color.BLUE} size={Loader.Size.SMALL} />;
    }

    const row = items[index];
    const isAdded = isProjectAdded(row);
    return (
      <div style={style} className={Styles.projectRow}>
        <GroupPicture images={row?.members?.map((v) => v?.team?.picture || v?.user?.picture)} />
        <span className={Styles.title}>{row?.title || 'Untitled Project'}</span>
        <Button
          className={isAdded && Styles.btnAdded}
          onClick={() => (isAdded ? false : handleAdd(row?.uuid))}
          isLoading={isBusyProjects.includes(row?.uuid)}
          type={Button.Type.BLUE}>
          {isAdded ? `Added` : `Add`}
        </Button>
      </div>
    );
  };

  const isProjectAdded = (project) => {
    if (newlyAddedProjects.includes(project?.uuid)) {
      return true;
    }

    const key = !!assignment?.team ? 'team' : 'user';
    return project?.members?.some((v) => v?.[key]?.uuid === assignment?.[key]?.uuid);
  };

  const handleAdd = (projectUuid) => {
    const data = { talent: talentUuid, assignment: assignment?.uuid };
    setIsBusyProjects([projectUuid]);
    postProjectAddTalent(projectUuid, data)
      .then(({ data }) => {
        setIsBusyProjects([]);
        setNewlyAddedProjects([...newlyAddedProjects, projectUuid]);
      })
      .catch((error) => {
        setIsBusyProjects([]);
        console.log(error);
      });
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader color={Loader.Color.BLUE} style={{ margin: 'auto' }} size={Loader.Size.LARGE} />
      ) : (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}>
          {({ onItemsRendered, ref }) => (
            <List
              height={300}
              itemCount={itemCount}
              itemSize={50}
              onItemsRendered={onItemsRendered}
              ref={ref}>
              {Item}
            </List>
          )}
        </InfiniteLoader>
      )}
    </Fragment>
  );
};

ProjectsList.propTypes = {};

export default ProjectsList;
