import { useState, useEffect } from 'react';
import classNames from 'classnames';
import Styles from './Item.module.scss';

const Item = ({ data, onItemSelect }) => {
  const [hasUnread, setHasUnread] = useState(data?.hasUnread);

  useEffect(() => {
    setHasUnread(!!data?.hasUnread);
  }, [data]);

  const handleItemSelect = () => {
    setHasUnread(false);
    onItemSelect?.(data);
  };

  return (
    <div
      onClick={handleItemSelect}
      className={classNames(Styles.discussionItem, hasUnread && Styles.dot)}
      data-test-id={`discussion-item-${hasUnread ? 'with' : 'without'}-dot`}>
      <img
        className={classNames(Styles.picture, Styles.small, 'highlight-block')}
        src={data?.picture}
        name={data?.title?.charAt(0)?.toUpperCase()}
        alt=''
      />
      <div className={Styles.name} title={data?.title}>
        {data?.title}
      </div>
    </div>
  );
};

Item.propTypes = {};

export default Item;
