import { useEffect, useState } from 'react';
import Styles from './HistoryViewer.module.scss';
import { useQuill } from 'react-quilljs';

import SplitButton from '@hiredigital/ui/Button/SplitButton';

import { useProjects } from '@context/projects';
import { getProjectFileHistory } from '@apis/projects';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

const HistoryViewer = ({ projectUuid, contentId }) => {
  const { restoreHistory, cancelHistory, history } = useProjects();
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: false,
    },
  });
  const [data, setData] = useState(history);

  useEffect(() => {
    if (history && quill) {
      showHistoryDifference();
    }
  }, [history, quill]);

  const showHistoryDifference = () => {
    getProjectFileHistory(projectUuid, contentId, history?.id)
      .then((response) => {
        setData(response?.data);
        if (quill) {
          const diff = findDiff(response?.data?.text || '', response?.data?.previousText || '');
          quill.setContents(diff);
        }
      })
      .catch((error) => {
        console.warn('error', error);
      });
  };

  const findDiff = (newText, oldText) => {
    // const editor = quill.editor;
    const oldContent = quill.clipboard.convert(oldText);
    const newContent = quill.clipboard.convert(newText);
    const diff = oldContent.diff(newContent);

    for (let i = 0; i < diff.ops.length; i++) {
      const op = diff.ops[i];
      // if the change was an insertion
      if (op.hasOwnProperty('insert')) {
        // color it green
        op.attributes = {
          background: '#cce8cc',
          color: '#003700',
        };
      }
      // if the change was a deletion
      if (op.hasOwnProperty('delete')) {
        // keep the text
        op.retain = op.delete;
        delete op.delete;
        // but color it red and struckthrough
        op.attributes = {
          background: '#e8cccc',
          color: '#370000',
          strike: true,
        };
      }
    }

    const adjusted = oldContent.compose(diff);
    return adjusted;
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <p className={Styles.title}>{data.title}</p>

        <p className={Styles.description}>
          {`Last saved ${formatDistanceToNow(new Date(history?.modified), {
            addSuffix: true,
          })}`}
        </p>
        <SplitButton
          mainButtonContainerClass={Styles.moreDropdown}
          more={[
            {
              text: 'Restore this Version',
              onClick: () => restoreHistory(projectUuid, contentId, history?.id),
            },
            {
              text: 'Cancel',
              onClick: cancelHistory,
            },
          ]}
        />
      </div>
      <div ref={quillRef} />
    </div>
  );
};

export default HistoryViewer;
