import { useEffect, useState, memo } from 'react';
// import { Dialog } from 'evergreen-ui/esm/dialog';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Header from './Header';
import Body from './Body';
import CallTimes from './CallTimes';
import Footer from './Footer';
import Button from '@hiredigital/ui/Button';
import {
  postConferenceAddTime,
  postConferenceRemoveTime,
  getConference,
  postConferenceSubmit,
} from '@apis/conferences';
import { useUser } from '@context/user';
import { useDiscussion } from '@context/discussion';

const ConfirmAvailability = ({
  discussion,
  message,
  isShown,
  onCancel,
  conferenceUuid,
  onClose,
  onCloseComplete,
  onSubmit,
}) => {
  const user = useUser();
  const { updateMessage } = useDiscussion();

  const [selected, setSelected] = useState([]);
  const [times, setTimes] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = () => {
    setIsLoading(true);
    postConferenceSubmit(conferenceUuid)
      .then(({ data }) => {
        if (message?.id) {
          updateMessage(discussion.id, { id: message.id });
        }
        setIsLoading(false);
        onSubmit?.(data);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleSelectTime = (data) => {
    const timeId = data?.id;
    if (!timeId) return;

    try {
      if (selected.includes(timeId)) {
        setSelected(selected.filter((v) => v !== timeId));
        postConferenceRemoveTime(conferenceUuid, timeId);
        return;
      }

      setSelected([...selected, timeId]);
      postConferenceAddTime(conferenceUuid, timeId);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isShown) return;
    setShouldFetch(true);
  }, [isShown]);

  useEffect(() => {
    if (shouldFetch) {
      getConference(conferenceUuid)
        .then(({ data }) => {
          setTimes(data?.times || []);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useEffect(() => {
    setSelected(
      times
        .filter(({ availableUsers }) => availableUsers?.some((v) => v?.uuid === user?.uuid))
        .map((v) => v.id)
    );
  }, [times, user]);

  return (
    <Dialog
      isOpen={isShown}
      onClose={() => onClose?.()}
      onCloseComplete={onCloseComplete}
      width={700}>
      <Header title={`Select your Call Time`} />
      <Body>
        <CallTimes {...{ selected, times }} onSelectTime={handleSelectTime} />
      </Body>
      <Footer>
        <Button isLoading={isLoading} type={Button.Type.BLUE} onClick={handleConfirm}>
          {`Confirm Availability`}
        </Button>
        <Button onClick={onCancel} type={Button.Type.LIGHT_GRAY}>
          {`Cancel`}
        </Button>
      </Footer>
    </Dialog>
  );
};

ConfirmAvailability.propTypes = {};

export default memo(ConfirmAvailability);
