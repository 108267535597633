import { useRef } from 'react';
import PropTypes from 'prop-types';

import Button from '@hiredigital/ui/Button';

import Styles from './PortfolioEdit.module.scss';

import Menu from '@hiredigital/ui/Menu/Menu';

const EditHeader = ({ item, type, onMenuSelect, onImageUpload, onScreenshotUpload }) => {
  const imageUploadElement = useRef();
  const imageScreenshotElement = useRef();

  const portfolioTypesArr = ['URL', 'Upload', 'Case Study'];

  return (
    <div className={Styles.modalHeader}>
      {!item?.id && (
        <div>
          <Menu>
            <Menu.Button as='div'>
              <p className={Styles.titleAction}>{`Add ${portfolioTypesArr[item.type - 1]}`}</p>
            </Menu.Button>
            <Menu.Items className={Styles.menuItems}>
              {portfolioTypesArr.map((type, index) => (
                <Menu.Item
                  key={index}
                  as='div'
                  className={Styles.menuItem}
                  onClick={() => onMenuSelect(index + 1)}>
                  {type}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
        </div>
      )}

      {item.id && <p className={Styles.editTitle}>{`Edit ${portfolioTypesArr[item.type - 1]}`}</p>}

      <input
        className={Styles.hiddenInput}
        type='file'
        ref={imageUploadElement}
        accept='image/*'
        onChange={onImageUpload}
      />

      <input
        className={Styles.hiddenInput}
        type='file'
        ref={imageScreenshotElement}
        accept='image/*'
        onChange={onScreenshotUpload}
      />

      <div className={Styles.headerActions}>
        {item.id && item.type !== 3 && (
          <Button
            className={Styles.editImageButton}
            name='change'
            type={Button.Type.BLUE_OUTLINE}
            onClick={() => imageUploadElement.current.click()}>
            {`Change Thumbnail`}
          </Button>
        )}

        {item.id && item.type === 1 && (
          <Button
            className={Styles.editImageButton}
            name='change'
            type={Button.Type.BLUE_OUTLINE}
            onClick={() => imageScreenshotElement.current.click()}>
            {`Change Screenshot`}
          </Button>
        )}
      </div>
    </div>
  );
};

EditHeader.propTypes = {
  item: PropTypes.object,
  type: PropTypes.number,
  onMenuSelect: PropTypes.func,
  onImageUpload: PropTypes.func,
  onScreenshotUpload: PropTypes.func,
};

export default EditHeader;
