import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Position from 'evergreen-ui/esm/constants/src/Position';
import { Tooltip } from 'evergreen-ui/esm/tooltip';

import classNames from 'classnames';
import Styles from './Nav.module.scss';

const MemoTooltip = memo(Tooltip);

const Item = ({ className, icon: Icon, href, label, open, isMobile = false, onClick }) => {
  const MemoIcon = memo(Icon);

  return (
    <MemoTooltip content={label} position={Position.RIGHT} isShown={open ? false : undefined}>
      <span>
        {href ? (
          <NavLink
            to={href}
            className={classNames(Styles.item, className, isMobile && Styles.mobile)}
            activeClassName={Styles.active}
            strict={false}
            exact={false}>
            <MemoIcon className={Styles.icon} />
            <div className={classNames(Styles.label, open && Styles.open)}>{label}</div>
          </NavLink>
        ) : (
          <div
            className={classNames(Styles.item, className, isMobile && Styles.mobile)}
            onClick={onClick}>
            <MemoIcon className={Styles.icon} />
            <div className={classNames(Styles.label, open && Styles.open)}>{label}</div>
          </div>
        )}
      </span>
    </MemoTooltip>
  );
};

Item.propTypes = {
  icon: PropTypes.func,
  to: PropTypes.string,
  label: PropTypes.string,
};

export default memo(Item);
