import { Redirect, Route } from 'react-router-dom';
import { isLoggedIn } from './auth';
import PropTypes from 'prop-types';

const ExternalRedirect = ({ to, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={() => {
        window.location = to;
        return null;
      }}
    />
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn() ? (
        <Component {...props} />
      ) : process.env.ENV !== 'local' ? (
        <ExternalRedirect
          to={`${process.env.MARKETING_ENDPOINT}/login?next=${
            process.env.APP_ENDPOINT
          }/${encodeURIComponent(props.location.pathname?.replace(/^\//, ''))}`}
        />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default PrivateRoute;
