import { useState, useEffect } from 'react';
import { authRequest } from '@apis/utils';
import { useUser } from '@context/user';
import throttle from 'lodash/throttle';

export const useOnlineStatus = () => {
  const [isNavigatorOnline, setIsNavigatorOnline] = useState(navigator.onLine);
  const [isOnline, setIsOnline] = useState(true);
  const currentUser = useUser();

  const verifyConnection = throttle(async () => {
    if (!currentUser) return setIsOnline(isNavigatorOnline);
    try {
      const response = await authRequest.head('/me');
      setIsOnline(response?.status === 200);
    } catch (err) {
      setIsOnline(false);
    }
  }, 5000);

  const handleNavigatorOnline = () => setIsNavigatorOnline(true);
  const handleNavigatorOffline = () => setIsNavigatorOnline(false);

  useEffect(() => {
    if (isNavigatorOnline) {
      // verify if we can establish connection to server
      verifyConnection();
    } else {
      // its guaranteed to be offline if LAN connection is not available, no need to verify
      setIsOnline(false);
    }
  }, [isNavigatorOnline]);

  useEffect(() => {
    window.addEventListener('online', handleNavigatorOnline);
    window.addEventListener('offline', handleNavigatorOffline);

    return () => {
      window.removeEventListener('online', handleNavigatorOnline);
      window.removeEventListener('offline', handleNavigatorOffline);
    };
  }, []);

  useEffect(() => {
    if (isOnline) return;
    window.addEventListener('mousemove', verifyConnection);

    return () => {
      window.removeEventListener('mousemove', verifyConnection);
    };
  }, [isOnline]);

  return { isOnline, setIsOnline };
};
