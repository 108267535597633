/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';
import Item from './Item';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';

import Styles from './List.module.scss';

const List = ({
  projectUuid,
  apiManager,
  hasTitle = true,
  initialData = [],
  initialLoad = true,
}) => {
  const [deadlines, setDeadlines] = useState(initialData || []);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadDeadlines = async (uuid) => {
    try {
      const { getDeadlines } = apiManager;
      const { data } = await getDeadlines(uuid);
      setDeadlines(data?.results || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoaded(true);
    }
  };

  // useEffect(() => {
  //   console.log('deadlines', deadlines);
  // }, [deadlines]);

  const handleNewDeadline = useCallback(() => {
    const items = [...deadlines, { isNew: true }];
    setDeadlines(items);
  });

  const handleItemUpdate = useCallback((deadline) => {
    setDeadlines(deadlines.map((v) => (v?.id === deadline?.id ? deadline : v)));
  });

  const handleItemCreate = useCallback((deadline, idx) => {
    deadlines.splice(idx, 1, deadline);
    // const items = [...deadlines, deadline].filter((v) => !!v.id);
    const items = [...deadlines];
    console.log(items);
    setDeadlines(items);
  });

  const handleItemDelete = useCallback((deadline) => {
    setDeadlines(deadlines.filter((v) => v.id !== deadline?.id));
  });

  useEffect(() => {
    if (!initialLoad) {
      setIsLoaded(true);
      return;
    }

    if (projectUuid) {
      loadDeadlines(projectUuid);
    }
  }, [projectUuid]);

  return (
    <div className={Styles.container}>
      {!isLoaded && <Loader color={Loader.Color.BLUE} />}
      <div className={Styles.header}>
        {hasTitle && <p className={Styles.title}>{`Deadlines`}</p>}
        <Button onClick={handleNewDeadline} type={Button.Type.BLUE}>
          {`Add Deadline`}
        </Button>
      </div>

      <div className={Styles.list}>
        {deadlines?.map((v, idx) => (
          <Item
            key={idx}
            index={idx}
            projectUuid={projectUuid}
            item={v}
            onUpdate={handleItemUpdate}
            onDelete={handleItemDelete}
            onCreate={handleItemCreate}
            apiManager={apiManager}
          />
        ))}
      </div>
      {/* <button className={Styles.actionAdd} onClick={handleNewDeadline}>
        {`Add New Deadline`}
      </button> */}
    </div>
  );
};

export default List;
