import { Fragment, useState, useEffect, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InitialSelect, { components } from 'react-select';
import InitialAsyncSelect from 'react-select/async';
import InitialCreatableSelect from 'react-select/creatable';
import InitialAsyncCreatableSelect from 'react-select/async-creatable';
import { withAsyncPaginate } from 'react-select-async-paginate';

import { DndContext, DragEndEvent, useDroppable } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { customStyles } from './SelectStyles';
import Styles from './Input.module.scss';

const Select = memo(InitialSelect);
const AsyncSelect = memo(InitialAsyncSelect);
const CreatableSelect = memo(InitialCreatableSelect);
const AsyncCreatableSelect = memo(InitialAsyncCreatableSelect);

const newList = (list, active, over) => {
  const oldIndex = list.findIndex((item) => item.id === active.id);
  const newIndex = list.findIndex((item) => item.id === over.id);
  return arrayMove(list, oldIndex, newIndex);
};

const MultiValue = (props) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.data.id,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <components.MultiValue {...props} innerProps={innerProps} />
    </div>
  );
};

const MultiValueContainer = (props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: 'droppable',
  });

  const style = {
    color: isOver ? 'green' : undefined,
  };

  return (
    <div style={style} ref={setNodeRef}>
      <components.MultiValueContainer {...props} />
    </div>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove
      {...props}
      innerProps={{
        onPointerDown: (e) => e.stopPropagation(),
        ...props.innerProps,
      }}
    />
  );
};

// END NEW ITEMS

// BY DEFAULT IT IS MULTI

const SelectInput = ({
  disabled,
  name,
  className,
  label,
  placeholder,
  onChange,
  classNamePrefix,
  value: initialValue,
  defaultValue: initialDefaultValue,
  onCreateOption,
  icon: Icon,
  error,
  innerProps,
  isAsync,
  isCreatable,
  isPaginated,
  data,
  options = [],
  loadOptions,
  ...props
}) => {
  const [items, setItems] = useState(initialValue || initialDefaultValue);
  const containerClass = classNames(
    Styles.container,
    className,
    items?.length && Styles.active,
    disabled && Styles.locked,
    error && Styles.containerError,
    Styles.multiContainer,
    !label && Styles.noLabel
  );

  useEffect(() => {
    if (initialValue) {
      setItems(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (initialValue && initialDefaultValue) {
      console.warn('Its not recommended to use defaultValue and value prop at the same time!');
    }
  }, [initialValue, initialDefaultValue]);

  const fieldClass = classNames(
    Styles.select,
    Styles.multi,
    Styles.sortable,
    error && Styles.error,
    !!Icon && Styles.hasIcon
  );

  const labelClass = classNames(
    Styles.label,
    error && Styles.error,
    items?.length && Styles.hasValue
  );

  const handleChange = (list, { action, name }) => {
    setItems([...list]);
    onChange?.({ target: { name, value: list }, action });
  };

  // const handleSortStart = ({}) => {};

  const handleSortEnd = ({ active, over }) => {
    if (!active || !over) return;

    const newItems = newList(items, active, over);
    setItems(newItems);

    const action = 'sort-end';
    onChange?.({ target: { name, value: newItems }, action });
    // onSortEnd?.(newValue, oldIndex, newIndex);
  };

  let CustomSelect;
  if (isPaginated) {
    if (isCreatable) {
      // console.log('creatable select');
      CustomSelect = CreatableSelect;
    } else {
      CustomSelect = Select;
    }
    CustomSelect = withAsyncPaginate(CustomSelect);
  } else {
    if (isAsync && isCreatable) {
      // console.log('creatable async select');
      CustomSelect = AsyncCreatableSelect;
    } else if (isAsync) {
      // console.log('async select');
      CustomSelect = AsyncSelect;
    } else if (isCreatable) {
      // console.log('creatable select');
      CustomSelect = CreatableSelect;
    } else {
      CustomSelect = Select;
    }
  }

  return (
    <DndContext modifiers={[restrictToParentElement]} onDragEnd={handleSortEnd}>
      <SortableContext items={items} strategy={horizontalListSortingStrategy}>
        <div className={containerClass} {...{ 'data-test-id': props['data-test-id'] || name }}>
          {Icon && <Icon className={Styles.inputIcon} />}
          <CustomSelect
            {...props}
            distance={4}
            className={fieldClass}
            styles={customStyles}
            name={name}
            placeholder={placeholder || label}
            value={items}
            isMulti
            onChange={handleChange}
            additional={{ page: 1 }}
            onCreateOption={onCreateOption}
            createOptionPosition='first'
            isDisabled={disabled}
            cacheOptions
            options={options}
            loadOptions={loadOptions}
            debounceTimeout={500}
            classNamePrefix={classNamePrefix}
            components={{
              MultiValue,
              MultiValueContainer,
              MultiValueRemove,
            }}
            closeMenuOnSelect={false}
          />
          <label htmlFor={name} className={labelClass}>
            {error || label}
          </label>
        </div>
      </SortableContext>
    </DndContext>
  );
};

SelectInput.propTypes = {
  classNamePrefix: PropTypes.string,
  'data-test-id': PropTypes.string,
  getOptionChildren: PropTypes.func,
};

SelectInput.defaultProps = {
  menuPlacement: 'auto',
  classNamePrefix: 's-contact',
  isAsync: false,
  isPaginated: false,
};

export default SelectInput;
