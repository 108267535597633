import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';

import Menu from '@hiredigital/ui/Menu/Menu';

import CommonStyles from '../Common.module.scss';

const label = 'Filter by Time';

const TimeFilter = ({ options, filter, onFilter }) => {
  const [time, setTime] = useState(filter);

  useEffect(() => {
    if (!time) {
      // Clear
      setTime(filter);
    }
  }, [filter]);

  const handleSelect = (item) => {
    setTime(item);
    onFilter?.(item);
  };

  return (
    <>
      <Menu className={CommonStyles.marginLeft}>
        <Menu.Button className={CommonStyles.buttonFilter}>
          {time ? (
            <div className={CommonStyles.selectedFilter}>{time.label}</div>
          ) : (
            <Fragment>{label}</Fragment>
          )}
        </Menu.Button>
        <Menu.Items>
          <div className={CommonStyles.filterItems}>
            {options.map((s, index) => (
              <Menu.Item
                as='div'
                key={index}
                className={classNames(
                  CommonStyles.filterItem,
                  time?.value === s?.value && CommonStyles.selected
                )}
                onClick={() => handleSelect(s)}>
                {s.label}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
};

TimeFilter.LABEL = label;

export default TimeFilter;
