import Cookies from 'universal-cookie';

const cookies = new Cookies();
export const COOKIE_DOMAIN = {
  domain: process.env.COOKIE_DOMAIN ? process.env.COOKIE_DOMAIN : '',
  path: '/',
};

export const isLoggedIn = () => {
  if (cookies.get('token')) {
    return true;
  }
  return false;
};
