import Styles from './Guide.module.scss';

const BlogTitleItem = ({ title, href }) => {
  return (
    <a className={Styles.blogTitleItem} href={href} target={`_blank`}>
      <svg
        className={Styles.blogTitleIcon}
        viewBox='0 0 1792 1792'
        xmlns='http://www.w3.org/2000/svg'
        icon-text=''>
        <path d='M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-1024-864q0-14 9-23t23-9h704q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64zm736 224q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h704zm0 256q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h704z'></path>
      </svg>
      <p className={Styles.blogTitle}>{title}</p>
    </a>
  );
};

export default BlogTitleItem;
