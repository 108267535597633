/*
This JavaScript function, called `generateRateDisplayText`, is used to generate a human-readable 
string representing the rate information for a service. The function takes an object with several 
properties as input, such as maximum rate, currency code, rate type, hours, and duration 
information. It then processes these properties and returns a formatted string displaying the rate 
information in a user-friendly way.

Here's a detailed breakdown of the function:

1. Imports the necessary enums and utility functions.
2. The function receives an object with the following properties:
   - maxRate
   - currencyCode (currencyCodeId)
   - rateType (rateTypeId)
   - minHours
   - maxHours
   - hoursType (hoursTypeId)
   - duration
   - durationType (durationTypeId)
   - showDuration
   - showHours
3. Determines if the rate type is hourly (rateTypeIsHour).
4. Retrieves the corresponding enumeration values for currencyCode, rateType, hoursType, and 
   durationType using their respective IDs.
5. Determines the maximum type ID between rateTypeId, hoursTypeId, and durationTypeId.
6. Calculates the multiplier based on the maximum type ID and the provided hours type and rate type.
7. If the multiplier is undefined, it is set to 1.
8. Defines a function `multiplyRate` that takes hours as input and calculates the multiplied rate 
   value.
9. Calculates the multiplied minimum and maximum rates using the `multiplyRate` function.
10. Constructs a string for the minimum rate label (minRateLabel) if minHours is present and the 
    rate type is hourly.
11. Constructs the computed rate string using the minimum rate label, maximum rate, and the maximum 
    rate type's short label.
12. Constructs an hours label string (hoursLabel) if showHours is true, maxHours is present, and 
    hoursType is present.
13. Constructs a duration label string (durationLabel) if showDuration is true, duration is present, 
    and durationType is present.
14. Returns the final formatted rate string, which includes the currency icon, computed rate, hours 
    label, and duration label.

The generated rate string provides a human-readable representation of the rate information for a 
service, which can be displayed to users. This function is particularly useful for applications 
that involve service pricing, such as booking or reservation systems.

~ Written by GPT-4 in ChatGPT Plus
*/

// (1)
import {
  Currency,
  ServiceRateType,
  NeededHoursType,
  DurationUnits,
  BankAccountCurrency,
} from './enum';
import { formatNumber } from './utils';

// (2)
export const generateRateDisplayText = ({
  maxRate,
  currencyCode: currencyCodeId,
  rateType: rateTypeId,
  minHours,
  maxHours,
  hoursType: hoursTypeId,
  duration,
  durationType: durationTypeId,
  showDuration,
  showHours,
}) => {
  // (3)
  const rateTypeIsHour = rateTypeId === ServiceRateType.HOUR.id;

  // (4)
  const currencyCode = Currency.getEnum(currencyCodeId);
  const rateType = ServiceRateType.getEnum(rateTypeId);
  const hoursType = NeededHoursType.getEnum(hoursTypeId);
  const durationType = DurationUnits.getEnum(durationTypeId);

  // (5)
  const maxTypeId = Math.max(
    rateTypeId || 0,
    (maxHours && hoursTypeId) || 0,
    (duration && durationType?.perId) || 0
  );
  const maxType = ServiceRateType.getEnum(maxTypeId);

  // (6)
  let multiplier =
    rateTypeIsHour && maxHours && hoursTypeId < maxTypeId
      ? hoursType?.multiplier[maxTypeId]
      : rateType?.multiplier[maxTypeId];

  // (7)
  // Note: Do not test `if (!multiplier)` as the zero value should remain unchanged.
  if (multiplier === undefined) multiplier = 1;

  // (8)
  // The maxRate is multiplied with either the multiplier or the given hours if multiplier is zero.
  const multiplyRate = (hours) => {
    const numMaxRate = Number(maxRate || 0);
    const numHours = Number(hours || 0);
    return formatNumber(
      numMaxRate *
        (rateTypeIsHour && hoursType ? numHours || 1 : 1) *
        (multiplier || (rateTypeIsHour ? 1 : numHours) || 1),
      0
    );
  };

  // (9)
  const minRateMultiplied = multiplyRate(minHours);
  const maxRateMultiplied = multiplyRate(maxHours);

  // (10)
  // If minHours is present and multiplier is zero, we add maxRate * minHours with a dash after it.
  const minRateLabel = minHours && minHours != 0 && rateTypeIsHour ? `${minRateMultiplied}-` : '';

  // (11)
  const computedRate = `${minRateLabel || ''}${maxRateMultiplied || ''}${
    maxType?.shortLabel || ''
  }`;

  // (12)
  const hoursLabel =
    showHours && maxHours && hoursType
      ? ` for ${minHours && minHours != 0 ? `${minHours}-` : ''}${maxHours} hrs${
          hoursType.shortLabel
        }`
      : '';

  // (13)
  const durationLabel =
    showDuration && duration && durationType
      ? // We use .name for singular values, and .label for plural values of duration.
        ` for ${duration} ${duration == 1 ? durationType.name : durationType.label}`
      : '';

  // (14)
  return `${currencyCode?.icon || ''}${computedRate || ''}${hoursLabel || ''}${
    durationLabel || ''
  }`;
};

export const getDefaultCurrencyByCountry = (common, callback) => {
  if (!common || !callback) return;
  // At least one should be present, otherwise this function does nothing.
  if (!(common.currency || common.location || common.systemCountry)) return;

  const currencyCode = common.currency?.currencyCode;
  const countryCode = common.location?.countryCode || common.systemCountry?.cca2;
  let currency;

  if (currencyCode) currency = currencyCode;
  else if (countryCode)
    currency = BankAccountCurrency.getEnumByKey(countryCode, 'country')?.currency;

  // Ensure that currency is in the list of allowed values.
  currency = currency && Currency.getEnum(currency)?.code;

  // Currency is set only after we're able to check the common object.
  // It will default to USD if the currency is missing or not in list.
  callback(currency || 'USD');
};
