import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import classNames from 'classnames';

const AsideColumn = ({ children, noBorder, className }) => (
  <div className={classNames(Styles.asideColumn, className, { [Styles.noBorder]: noBorder })}>
    {children}
  </div>
);

AsideColumn.propTypes = {
  children: PropTypes.node,
  noBorder: PropTypes.bool,
};

export default AsideColumn;
