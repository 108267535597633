import { useEffect, useState } from 'react';
import Styles from './Candidates.module.scss';
import Item from './Item';

const List = ({
  engagement,
  onAcceptCandidate,
  onAddTalentToProject,
  onRequestInterview,
  onRescheduleInterview,
  onCancelInterview,
  onViewDiscussion,
  busyApplications = [],
}) => {
  const [applications, setApplications] = useState(engagement?.applications || []);

  useEffect(() => {
    setApplications(engagement?.applications || []);
  }, [engagement]);

  return (
    <div className={Styles.container}>
      {applications.map((application) => (
        <Item
          key={application?.uuid}
          application={application}
          engagement={engagement}
          onAcceptCandidate={onAcceptCandidate}
          onAddTalentToProject={onAddTalentToProject}
          onRequestInterview={onRequestInterview}
          onRescheduleInterview={onRescheduleInterview}
          onCancelInterview={onCancelInterview}
          onViewDiscussion={onViewDiscussion}
          isBusy={busyApplications.includes(application?.uuid)}
        />
      ))}
    </div>
  );
};

List.propTypes = {};

export default List;
