import { useReducer, useContext, createContext } from 'react';

const CommonContext = createContext();
const CommonDispatchContext = createContext();

const reducer = (common, action) => {
  switch (action.type) {
    case 'UPDATE_LANGUAGE':
      return { ...common, languages: action.payload };
    case 'UPDATE_TIMEZONE':
      return { ...common, timezones: action.payload };
    case 'UPDATE_FORMATS':
      return { ...common, formats: action.payload };
    case 'UPDATE_COUNTRIES':
      return { ...common, countries: action.payload };
    case 'UPDATE_TALENT_TYPES':
      return { ...common, talentTypes: action.payload };
    case 'UPDATE_TOPICS':
      return { ...common, topics: action.payload };
    case 'UPDATE':
      return { ...common, ...action.payload };
    case 'UPDATE_TEAM_COMPLETION':
      return { ...common, isTeamRequested: action.payload };
    case 'UPDATE_AUTH_USER_COMPLETION':
      return { ...common, isUserRequested: action.payload };
    case 'UPDATE_ORG_COMPLETION':
      return { ...common, isOrgRequested: action.payload };
    case 'UPDATE_CURRENCY_COMPLETION':
      return { ...common, isCurrencyLoaded: action.payload };
    case 'UPDATE_SHARED_DATA_READY':
      return { ...common, isReady: action.payload }; // all shared data are ready if true
    case 'UPDATE_USER_CURRENCY':
      return { ...common, defaultCurrency: action.payload };
    case 'UPDATE_OTHER_DATA_COMPLETION':
      return { ...common, isOthersLoaded: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const CommonProvider = ({ children, initial }) => {
  const [common, dispatch] = useReducer(reducer, initial);
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <CommonDispatchContext.Provider value={dispatch}>
      {/*eslint-disable-next-line react/react-in-jsx-scope*/}
      <CommonContext.Provider value={common}>{children}</CommonContext.Provider>
    </CommonDispatchContext.Provider>
  );
};

export const useCommon = () => useContext(CommonContext);
export const useCommonDispatch = () => useContext(CommonDispatchContext);
