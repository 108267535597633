import { useState } from 'react';
import { ContentType } from '@hiredigital/lib/helpers/enum';
import FilePreview from '@hiredigital/ui/FilePreview/FilePreview';
import { useHistory, useParams } from 'react-router-dom';

import IconLink from '@hiredigital/ui/Icon/icons/link.inline.svg';
import IconFile from '@hiredigital/ui/Icon/icons/file.inline.svg';
import Styles from './Styles.module.scss';

const Content = ({ message, ...props }) => {
  const history = useHistory();
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);

  switch (message.metaObject.type) {
    case ContentType.ATTACHMENT.id:
      return (
        <div className={Styles.content}>
          {/*Icon*/}

          <a
            className={Styles.attachmentLink}
            href={message.metaObject.attachment}
            alt={message.metaObject.attachmentName}
            name={message.metaObject.attachmentName}
            target='_blank'
            rel='noopener noreferrer'>
            {message.metaObject.attachmentName}
          </a>
          {/*Link*/}
          {message.metaObject.preview && (
            <div className={Styles.imageContainer} onClick={() => setIsOpen(!isOpen)}>
              <img className={Styles.image} src={message.metaObject.preview} alt='attachment' />
            </div>
          )}
          <FilePreview
            title={message.metaObject.title}
            preview={message.metaObject?.preview}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
      );
    case ContentType.LINK.id:
      return (
        <div className={Styles.content}>
          <div className={Styles.linkContent}>
            <IconLink className={Styles.iconLink} />
            <a
              className={Styles.attachmentLink}
              href={message.metaObject.url}
              alt={message.metaObject.url}
              name={
                message.metaObject?.title ||
                message.metaObject?.attachmentName ||
                'Untitled Content'
              }
              target='_blank'
              rel='noopener noreferrer'>
              {message.metaObject.url}
            </a>
          </div>
          {!!message?.metaObject?.pageScreenshot && (
            <div className={Styles.imageContainer}>
              <img src={message.metaObject.pageScreenshot} alt='' className={Styles.image} />
            </div>
          )}
        </div>
      );
    case ContentType.TEXT.id:
      return (
        <div className={Styles.content}>
          <div className={Styles.linkContent}>
            <IconFile className={Styles.iconLink} />
            <a
              className={Styles.attachmentLink}
              {...(params.slug?.[0]
                ? {
                    onClick: (e) => {
                      e.preventDefault();
                      history.replace(
                        `/projects/${params.slug?.[0]}/content/${message?.metaObject?.id}`
                      );
                    },
                  }
                : { href: `content/${message?.metaObject?.id}` })}
              alt={message.metaObject.title}
              name={
                message.metaObject?.title ||
                message.metaObject?.attachmentName ||
                'New Text Document'
              }>
              {message.metaObject.title ||
                message.metaObject?.attachmentName ||
                'New Text Document'}
            </a>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default Content;
