import { Fragment, useState, useEffect } from 'react';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { getUser } from '@apis/users';
import { isTalent } from '@helpers/permissions';
import { useUser } from '@context/user';
import Layout from '@components/Layout/AppLayout';

import Loader from '@hiredigital/ui/Loader';
import Section from '@components/StagesLayout/Section';
import UserSettings from '@components/UserSettings/UserSettings';
import UserPassword from '@components/UserPassword/UserPassword';
import UserAgreement from '@components/UserAgreement/UserAgreement';
import Styles from './Styles.module.scss';
const SettingIndexPage = () => {
  const user = useUser();
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setPageTitle('Account Settings - Hire Digital');
  }, []);

  useEffect(() => {
    if (user?.uuid) {
      getUser(user?.uuid)
        .then((response) => {
          setData(response?.data);
          setIsLoaded(true);
        })
        .catch((error) => {
          console.warn(error);
          setIsLoaded(true);
        });
    }
  }, [user]);

  return (
    <Layout style={{ background: 'white' }}>
      <Section
        title={`Account Settings`}
        subtitle={`Update your personal preferences and review any platform agreements.`}
        className={Styles.settingsContainer}>
        {isLoaded ? (
          <Fragment>
            <UserSettings user={data} />
            <UserPassword />
            {isTalent(user) && user?.agreementRequired && <UserAgreement />}
          </Fragment>
        ) : (
          <Loader color={Loader.Color.BLUE} />
        )}
      </Section>
    </Layout>
  );
};

export default SettingIndexPage;
