import { authRequest } from './utils';

export const getBoldAgreements = async (uuid) => {
  const url = `users/${uuid}/bold-agreements/`;
  return await authRequest.get(url);
};

export const getBoldAgreement = async (uuid, agreementId) => {
  const url = `users/${uuid}/bold-agreements/${agreementId}`;
  return await authRequest.get(url);
};

export const generateBoldAgreement = async (uuid) => {
  const url = `users/${uuid}/bold-agreements/generate/`;
  return await authRequest.post(url);
};

export const getBoldAgreementLink = async (uuid, agreementId) => {
  const url = `users/${uuid}/bold-agreements/${agreementId}/link`;
  return await authRequest.get(url);
};

export const getBoldDownloadLink = (uuid, agreementId) => {
  return `${process.env.API_ENDPOINT}/users/${uuid}/bold-agreements/${agreementId}/download`;
};

export const downloadBoldAgreement = async (uuid, agreementId) => {
  const url = `users/${uuid}/bold-agreements/${agreementId}/download`;
  return await authRequest.get(url);
};

export const getAgreementLink = async (parent, uuid, agreementId) => {
  const url = `${process.env.MARKETING_ENDPOINT}/api/docusign`;
  const data = {
    resource: parent,
    resourceUuid: uuid,
    agreementId: agreementId,
  };
  const response = await authRequest.post(url, data);
  return `${process.env.MARKETING_ENDPOINT}/api/docusign?agreement=${response.data}`;
};
