import Styles from './ConfirmAvailability.module.scss';
import CallItem from './CallItem';

const CallTimes = ({ times, onSelectTime, selected }) => {
  return (
    <div className={Styles.list}>
      <div className={Styles.listHeader}>
        <div className={Styles.headerCell}>{`Date`}</div>
        <div className={Styles.headerCell}>{`Time`}</div>
        <div className={Styles.headerCell}>{`Available`}</div>
        <div className={Styles.headerCell} style={{ justifyContent: 'flex-end' }}>
          {`You`}
        </div>
      </div>
      {times?.map((v, idx) => (
        <CallItem
          item={v}
          key={idx}
          onSelect={onSelectTime}
          isSelected={selected.includes(v?.id)}
        />
      ))}
    </div>
  );
};

CallTimes.propTypes = {};

export default CallTimes;
