import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Styles.module.scss';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

const ScheduleItem = ({ data, isSelected, onSelect }) => {
  return (
    <div
      className={classNames(Styles.listItem, isSelected && Styles.selected)}
      onClick={() => onSelect(data)}>
      <div className={Styles.listItemCell}>{toReadableDate(data?.date)}</div>
      <div className={Styles.listItemCell}>{`${data?.startFormatted}-${data?.endFormatted}`}</div>
      <div className={Styles.listItemCell} style={{ justifyContent: 'flex-end' }}>
        {isSelected && (
          <svg className={Styles.iconCheck} viewBox='0 0 50 50' version='1.1' icon-ok=''>
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
              <g id='check-thin' fill='currentColor'>
                <path
                  d='M12.875574,30.5881184 C12.1067942,30.0178994 11.9460418,28.9338621 12.516211,28.1651495 C13.0859669,27.396994 14.1708903,27.2362937 14.9394787,27.8061122 L20.7174682,32.0923169 L22.2218755,33.2083102 L23.4339155,31.7801501 L37.1304706,15.6413473 C37.7501116,14.9122018 38.8432372,14.8226081 39.5725046,15.4413011 C40.3018981,16.0603193 40.3913164,17.1534496 39.7722881,17.8831122 L23.8136993,36.6873139 C23.4115197,37.1610146 22.7930433,37.3782431 22.1889667,37.2717281 C21.9288872,37.225869 21.6816373,37.1200483 21.45984,36.9557939 L12.875574,30.5881184 Z'
                  id='Fill-5'></path>
              </g>
            </g>
          </svg>
        )}
      </div>
    </div>
  );
};

ScheduleItem.propTypes = {
  data: PropTypes.object,
};

export default ScheduleItem;
