import { useState, useEffect } from 'react';
import classNames from 'classnames';

import Styles from './SegmentedControl.module.scss';

const Segment = ({
  className,
  options,
  height = 36,
  width = 100,
  onChange,
  defaultValue,
  filled,
  ...props
}) => {
  const [segmentValue, setSegmentValue] = useState();
  const handleChange = (v) => {
    setSegmentValue(v);
    onChange?.(v);
  };

  useEffect(() => {
    setSegmentValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className={classNames(Styles.groupContainer, className)} {...props}>
      {options?.map((opt, key) => (
        <button
          key={key}
          className={classNames(
            filled ? Styles.optionFilled : Styles.option,
            opt.value === segmentValue && Styles.selected
          )}
          style={{ height, width }}
          onClick={() => handleChange(opt.value)}>
          {opt.label}
        </button>
      ))}
    </div>
  );
};

export default Segment;
