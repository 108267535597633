import { Fragment, useState, useEffect } from 'react';
import Portal from '@hiredigital/ui/Portal/Portal';

import { useUser } from '@context/user';
import { useChatController, useChatControllerDispatch } from '@context/chatController';

import ChatContainer from './ChatContainer';
import ChatList from './List';
import Chat from './Chat';
import { useQuery } from '@hiredigital/lib/hooks/useQuery';

const ChatManager = () => {
  const user = useUser();
  const query = useQuery();
  // const { chat, discussion, minimizeChat, chatList } = query;
  const chat = query.get('chat');
  const discussion = query.get('discussion');
  const minimizeChat = query.get('minimizeChat');
  const chatList = query.get('chatList');

  const controller = useChatController();
  const controllerDispatch = useChatControllerDispatch();

  const [isRouterReady, setIsRouterReady] = useState(false);

  useEffect(() => {
    setIsRouterReady(true);
  }, [query]);

  useEffect(() => {
    if (user?.uuid) {
      showActiveChats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const showActiveChats = () => {
    const raw = localStorage.getItem('activeChats');
    const users = raw ? JSON.parse(raw) : {};

    const paramId = getParamsChat();
    const chats = paramId ? { [paramId]: {} } : users?.[user?.uuid];

    controllerDispatch({ type: 'UPDATE_ACTIVE_CHATS', payload: chats || {} });
  };

  const getParamsChat = () => {
    if (chat) {
      return parseInt(chat);
    }

    if (discussion && !isNaN(discussion)) {
      return parseInt(discussion);
    }

    return null;
  };

  const handleDiscussionSelect = (item) => {
    if (controller.activeChats?.hasOwnProperty(item.id)) return;

    const chats = { ...controller.activeChats, [item.id]: {} };
    controllerDispatch({ type: 'UPDATE_ACTIVE_CHATS', payload: chats });
  };

  const handleChatClose = (id) => {
    const chats = { ...controller.activeChats };
    delete chats[id];
    controllerDispatch({ type: 'UPDATE_ACTIVE_CHATS', payload: chats });
  };

  const isCollapse = (discussionId) =>
    minimizeChat ? minimizeChat === 'true' : controller.activeChats?.[discussionId]?.isCollapse;

  const isChatListShown = () => {
    if (chatList) {
      return chatList.toLowerCase() === 'true';
    }

    if (discussion && isNaN(discussion)) {
      return discussion.toLowerCase() === 'true';
    }

    return controller.isChatListShown;
  };

  return (
    <Portal>
      {isRouterReady && user?.uuid ? (
        <Fragment>
          <ChatContainer>
            {Object.keys(controller.activeChats || {})?.map((discussionId) => (
              <Chat
                key={discussionId}
                discussionId={discussionId}
                onClose={handleChatClose}
                minimize={controller.activeChats?.[discussionId]?.isMinimize}
                collapse={isCollapse(discussionId)}
              />
            ))}
            <ChatList
              onDiscussionSelect={handleDiscussionSelect}
              onClose={() => controllerDispatch({ type: 'HIDE_LIST' })}
              isShown={isChatListShown()}
            />
          </ChatContainer>
        </Fragment>
      ) : null}
    </Portal>
  );
};

ChatManager.propTypes = {};

export default ChatManager;
