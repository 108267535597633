import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@hiredigital/ui/Card';
import Loader from '@hiredigital/ui/Loader';
import Select from '@hiredigital/ui/Input/Select';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import PhoneNumber from '@hiredigital/ui/PhoneNumber';
import { useCommon } from '@context/common';
import { patchOrganization } from '@apis/organizations';
import { PreferredPlatform, ConferencePlatform } from '@hiredigital/lib/helpers/enum';
import CurrencyDropdown from '@hiredigital/ui/CurrencyDropdown';
import Styles from './Styles.module.scss';

import toast from 'react-hot-toast';

let saveTimeout = null;

const ContactPreferences = ({ orgUuid, data, type }) => {
  const common = useCommon();
  const [isSaving, setIsSaving] = useState(false);
  const [email, setEmail] = useState(data.email);
  const [username, setUsername] = useState(data.username);
  const [preferred, setPreferred] = useState(data?.contact?.preferred);
  const [skype, setSkype] = useState(data?.contact?.skype);
  const [zoom, setZoom] = useState(data?.contact?.zoom);
  const [phoneCountry, setPhoneCountry] = useState(data?.contact?.phoneCountry);
  const [phoneNumber, setPhoneNumber] = useState(data?.contact?.phoneNumber);
  const [timezone, setTimezone] = useState(data?.contact?.timezone);
  const [currency, setCurrency] = useState(null);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [conferencePlatform, setConferencePlatform] = useState(
    data?.contact?.conferencePlatform || ConferencePlatform.GOOGLE.id
  );
  const [isMounted, setIsMounted] = useState(false);

  const saveWithDelay = (timeout = 3000) => {
    if (saveTimeout) clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      savePreferences();
    }, timeout);
  };

  const savePreferences = async () => {
    try {
      setIsSaving(true);

      if (isValidPhone) {
        const data = {
          contact: {
            preferred,
            skype,
            zoom,
            phoneCountry,
            phoneNumber,
            timezone,
            conferencePlatform,
          },
          profile: {
            currency,
          },
          email,
        };

        await patchOrganization(orgUuid, data);
        toast.success('Your contact details was updated successfully.', {
          id: 'contact',
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setIsSaving(false), 250);
    }
  };

  useEffect(() => {
    // Prevent automatic saving during the component initial mount
    if (isMounted) {
      saveWithDelay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    email,
    username,
    preferred,
    skype,
    zoom,
    phoneCountry,
    phoneNumber,
    timezone,
    conferencePlatform,
  ]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Card>
      <Card.Header title={`Contact Settings`} />
      <Card.Item>
        <div className={Styles.form}>
          {type === 'users' && (
            <InputGroup>
              <InputContainer>
                <TextInput
                  name='username'
                  label='Username'
                  disabled
                  defaultValue={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <TextInput
                  name='email'
                  label='E-mail'
                  disabled
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputContainer>
            </InputGroup>
          )}
          <InputGroup>
            <InputContainer>
              <Select
                defaultValue={timezone}
                classNamePrefix='s-contact'
                name='timezone'
                label='Timezone'
                getOptionLabel={({ name, label, offset }) => `${name || label} (GMT${offset})`}
                getOptionValue={({ id }) => id}
                options={common?.timezones}
                onChange={(e) => setTimezone(e.target.value)}
              />
            </InputContainer>
            {type === 'users' && (
              <InputContainer>
                <CurrencyDropdown
                  type={CurrencyDropdown.Type.DEFAULT}
                  defaultValue={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                />
              </InputContainer>
            )}
          </InputGroup>
          <InputGroup>
            <InputContainer>
              {common?.countries && (
                <PhoneNumber
                  phoneCountry={phoneCountry}
                  phoneNumber={phoneNumber}
                  onPhoneCountryChange={setPhoneCountry}
                  onPhoneNumberChange={setPhoneNumber}
                  getCountries={async () => ({ data: common.countries })}
                  onValidate={setIsValidPhone}
                />
              )}
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <TextInput
                className={`highlight-block`}
                name='skype'
                label='Skype'
                defaultValue={skype}
                onChange={(e) => setSkype(e.target.value)}
              />
            </InputContainer>
            <InputContainer>
              <TextInput
                className={`highlight-block`}
                name='zoom'
                label='Zoom'
                defaultValue={zoom}
                onChange={(e) => setZoom(e.target.value)}
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <Select
                defaultValue={PreferredPlatform.getEnum(preferred)}
                classNamePrefix='s-contact'
                name='preferred'
                label='Call Preference'
                getOptionLabel={({ label }) => label}
                getOptionValue={({ id }) => id}
                options={PreferredPlatform.values}
                onChange={(e) => setPreferred(e.target.value)}
              />
            </InputContainer>
            <InputContainer>
              <Select
                defaultValue={ConferencePlatform.getEnum(conferencePlatform)}
                classNamePrefix='s-contact'
                name='platform'
                label='Conference Platform'
                getOptionLabel={({ label }) => label}
                getOptionValue={({ id }) => id}
                options={ConferencePlatform.values}
                onChange={(e) => setConferencePlatform(e.target.value)}
              />
            </InputContainer>
          </InputGroup>
        </div>
      </Card.Item>
      <Card.Footer right>
        <Button onClick={() => saveWithDelay(0)} name='submit' type={Button.Type.BLUE}>
          {isSaving && (
            <Loader
              style={{ marginRight: '10px' }}
              color={Loader.Color.WHITE}
              size={Loader.Size.SMALL}
            />
          )}
          {`Save Settings`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

ContactPreferences.propTypes = {
  orgUuid: PropTypes.string,
  data: PropTypes.shape({
    contact: PropTypes.object,
    username: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default ContactPreferences;
