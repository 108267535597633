import { authRequest } from './utils';

const serializerBrief = (data) => {
  const serializedData = {
    ...data,
    languages: data?.languages?.map((obj) => obj?.id),
  };
  return serializedData;
};

const serializerBriefTalent = (data) => {
  const serializedData = {
    ...data,
    talentType: data?.talentType?.id,
    countries: data?.countries?.map(({ id }) => id),
    languages: data?.languages?.map(({ id }) => id),
    skills: data?.skills?.map(({ id }) => id),
  };
  return serializedData;
};

const serializerBriefFormat = (data) => {
  const serializedData = {
    ...data,
    format: data?.format?.id,
  };
  return serializedData;
};

const serializerBriefEngagement = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

export const getBriefs = async (config = undefined) => {
  const url = `briefs/`;
  return await authRequest.get(url, config);
};

export const getBrief = async (uuid, config = undefined) => {
  const url = `briefs/${uuid}/`;
  return await authRequest.get(url, config);
};

export const postBrief = async (data, serialize = serializerBrief, config = undefined) => {
  const url = `briefs/`;
  return await authRequest.post(url, serialize(data), config);
};

export const patchBrief = async (uuid, data, serialize = serializerBrief, config = undefined) => {
  const url = `briefs/${uuid}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const putBrief = async (uuid, data, serialize = serializerBrief, config = undefined) => {
  const url = `briefs/${uuid}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const postBriefConfirmUpdate = async (
  uuid,
  data,
  serialize = serializerBrief,
  config = undefined
) => {
  const url = `briefs/${uuid}/confirm-update/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postBriefSubmit = async (
  uuid,
  data,
  serialize = serializerBrief,
  config = undefined
) => {
  const url = `briefs/${uuid}/submit/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postBriefTalent = async (
  uuid,
  data,
  serialize = serializerBriefTalent,
  config = undefined
) => {
  const url = `briefs/${uuid}/talents/`;
  return await authRequest.post(url, serialize(data), config);
};

export const putBriefTalent = async (
  uuid,
  talentUuid,
  data,
  serialize = serializerBriefTalent,
  config = undefined
) => {
  const url = `briefs/${uuid}/talents/${talentUuid}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const deleteBriefTalent = async (uuid, talentUuid, config = undefined) => {
  const url = `briefs/${uuid}/talents/${talentUuid}/`;
  return await authRequest.delete(url, config);
};

export const postBriefFormat = async (
  uuid,
  data,
  serialize = serializerBriefFormat,
  config = undefined
) => {
  const url = `briefs/${uuid}/formats/`;
  return await authRequest.post(url, serialize(data), config);
};

export const deleteBriefFormat = async (uuid, formatUuid, config = undefined) => {
  const url = `briefs/${uuid}/formats/${formatUuid}/`;
  return await authRequest.delete(url, config);
};

export const postBriefEngagement = async (
  uuid,
  data,
  serialize = serializerBriefEngagement,
  config = undefined
) => {
  const url = `briefs/${uuid}/engagements/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postBriefLink = async (uuid, data, config = undefined) => {
  const url = `briefs/${uuid}/links/`;
  return await authRequest.post(url, data, config);
};

export const postBriefFile = async (uuid, data, config = undefined) => {
  const url = `briefs/${uuid}/attachments/`;
  return await authRequest.post(url, data, config);
};

export const deleteBriefLink = async (uuid, linkId, config = undefined) => {
  const url = `briefs/${uuid}/links/${linkId}/`;
  return await authRequest.delete(url, config);
};

export const deleteBriefFile = async (uuid, fileId, config = undefined) => {
  const url = `briefs/${uuid}/attachments/${fileId}/`;
  return await authRequest.delete(url, config);
};
