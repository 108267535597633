import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Styles from './Layout.module.scss';

const TitleBar = ({
  title,
  subtitle,
  titleLink = '',
  children,
  onSearchChange,
  searchPlaceholder,
}) => {
  return (
    <div className={Styles.header}>
      <Link to={titleLink}>
        <h1 className={classNames(Styles.headerTitle, Styles.headerLink)}>{title}</h1>
      </Link>
      {Array.isArray(subtitle) ? (
        subtitle.map((t, index) => {
          return (
            <>
              <span className={Styles.caretIcon}>{`›`}</span>
              <h2 className={Styles.headerTitle}>{t}</h2>
            </>
          );
        })
      ) : (
        <>
          <span className={Styles.caretIcon}>{`›`}</span>
          <h2 className={Styles.headerTitle}>{subtitle}</h2>
        </>
      )}
      {children}
    </div>
  );
};

TitleBar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default TitleBar;
