import BlogTitleItem from './BlogTitleItem';
import Styles from './Guide.module.scss';

const BlogTitleContainer = ({ children }) => {
  return <div className={Styles.item}>{children}</div>;
};

BlogTitleContainer.Item = BlogTitleItem;

export default BlogTitleContainer;
