import Styles from './Layout.module.scss';
import Header from './Header';
import Body from './Body';
import ContentArea from './ContentArea';

const Main = ({ children, header }) => {
  return (
    <div className={Styles.container}>
      {typeof header === 'function' ? header() : <Header></Header>}
      <Body>
        <ContentArea>{children}</ContentArea>
      </Body>
    </div>
  );
};

export default Main;
