import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { getEngagement } from '@apis/engagements';
import Layout from '@components/Layout/AppLayout';
import TitleBar from '@components/Layout/DetailTitleBar';

import Detail from '@components/Engagements/Detail';
import Styles from './Styles.module.scss';

const EngagementDetail = ({ ...props }) => {
  const [engagement, setEngagement] = useState();
  const { uuid } = useParams();

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getEngagement(uuid);
        setEngagement(response?.data);
        setPageTitle(`${response?.data?.title || 'New Engagement'} - Hire Digital`);
      } catch (e) {
        console.error(e);
      }
    };
    if (uuid) {
      loadData();
    }
  }, [uuid]);

  return (
    <Layout
      bodyClass={Styles.wrapper}
      scrollContainerClass={Styles.scrollContainer}
      headerContainerClass={Styles.headerClass}
      header={
        <TitleBar
          title={`Engagements`}
          titleLink={`/engagements`}
          subtitle={engagement?.title || 'New Engagement'}></TitleBar>
      }>
      {engagement && <Detail engagement={engagement} />}
    </Layout>
  );
};

export default EngagementDetail;
