import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';

const Body = ({ children }) => {
  return <div className={Styles.body}>{children}</div>;
};

Body.propTypes = {
  children: PropTypes.node,
};

export default Body;
