import { useEffect, useCallback } from 'react';
import { userSocket } from '@helpers/wsConnection';
import { useUser, useUserDispatch } from '@context/user';
import { useChatController, useChatControllerDispatch } from '@context/chatController';
import { useNetworkStatus } from '@context/network';
import { useNotifications } from '@context/notifications';
import { toast, useToasterStore } from 'react-hot-toast';
import { viewNotification } from '@helpers/notifications';
import MultiLineEllipsis from '@hiredigital/ui/MultiLineEllipsis';

import Styles from './UserSocketManager.module.scss';

const NOTIFICATION_LIMIT = 4;

const UserSocketManager = () => {
  const user = useUser();
  const userDispatch = useUserDispatch();

  const controller = useChatController();
  const controllerDispatch = useChatControllerDispatch();

  const { isOnline } = useNetworkStatus();
  const { incrementUnread: incrementUnreadNotification } = useNotifications();
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((t) => t.visible && t.toastType === 'new_notification')
      .filter((_, i) => i >= NOTIFICATION_LIMIT)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  const onDataReceive = useCallback(
    (data) => {
      switch (data?.type) {
        case 'user_updated':
          const payload = { ...user, ...data?.user };
          userDispatch({
            type: 'UPDATE',
            payload,
          });
          break;
        case 'new_direct_message':
          if (
            data?.discussion?.id &&
            !controller?.activeChats?.hasOwnProperty(data.discussion.id)
          ) {
            const chats = { ...controller?.activeChats, [data.discussion.id]: {} };
            controllerDispatch({ type: 'UPDATE_ACTIVE_CHATS', payload: chats });
          }
          break;
        case 'discussion_updated':
          break;
        case 'new_notification':
          toast(
            (t) => {
              const viewNotificationAction = () => {
                viewNotification(data.notification);
                toast.dismiss(t.id);
              };

              return (
                <div className={Styles.notificationContent} onClick={viewNotificationAction}>
                  <img className={Styles.actorPicture} src={data.notification.actor.picture} />
                  <MultiLineEllipsis
                    elem='div'
                    truncateLine={4}
                    dangerouslySetInnerHTML={{ __html: data.notification.summary }}
                  />
                </div>
              );
            },
            {
              duration: 10000,
              position: 'top-right',
              className: Styles.toastNotification,
              toastType: 'new_notification',
              id: data.notification?.id,
            }
          );
          incrementUnreadNotification();
          break;
        default:
      }
    },
    [controller, controllerDispatch, user, userDispatch]
  );

  const openConnection = () => {
    if (isOnline && user?.uuid) {
      userSocket
        .open(`users/${user?.uuid}`)
        .onMessage(onDataReceive)
        .onOpen(onOpen)
        .onClose(onClose);
    } else {
      userSocket.close();
    }
  };

  const onOpen = () => console.log('user socket connection opened!');

  const onClose = () => {
    console.log('user socket connection closed.');
    if (!isOnline) return;
    setTimeout(openConnection, 5000); // check again in 5 seconds
  };

  useEffect(openConnection, [isOnline, user?.uuid, onDataReceive]);

  return null;
};

export default UserSocketManager;
