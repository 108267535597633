import Styles from './ClientGuidelines.module.scss';
import Item from './GuidelineItem';
import References from '@hiredigital/ui/Form/References';
import convert from 'htmr';
import { postOrgFile, postOrgLink, deleteOrgFile, deleteOrgLink } from '@apis/organizations';

const ClientGuidelines = ({ brand }) => {
  const formatItems = (items, key) => {
    return !!items?.length ? items?.map((v) => v[key || 'label'])?.join(', ') : 'No preference';
  };

  return (
    <div className={Styles.container}>
      {brand?.name && <Item title={`Client Name`}>{brand.name}</Item>}
      {brand?.description && <Item title={`Client Details`}>{convert(brand.description)}</Item>}
      {brand?.guidelines && <Item title={`Style Guide`}>{convert(brand.guidelines)}</Item>}
      {brand?.topics?.length > 0 && <Item title={`Verticals`}>{formatItems(brand.topics)}</Item>}
      {(brand?.attachments?.length > 0 || brand?.links?.length > 0) && (
        <References
          className={Styles.attachments}
          attachments={brand?.attachments || []}
          links={brand?.links || []}
          postLinkRequest={(data, config) => postOrgLink(brand.uuid, data, config)}
          postFileRequest={(data, config) => postOrgFile(brand.uuid, data, config)}
          deleteLinkRequest={(id) => deleteOrgLink(brand.uuid, id)}
          deleteFileRequest={(id) => deleteOrgFile(brand.uuid, id)}
          // server={`orgs/${brand.uuid}`}
          showActions={false}
        />
      )}
    </div>
  );
};

export default ClientGuidelines;
