import { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import imagesloaded from 'imagesloaded';

// import { connect } from 'react-redux';
// import { portfolioActions } from '../actions';

// const refName = 'packeryContainer';
let Draggabilly, Packery;

try {
  Packery = require('packery');
  Draggabilly = require('draggabilly');
} catch (e) {
  // console.log(e);
}

let packery = null;

const PackeryComponent = (props) => {
  // Note! should use useCallback and debounce instead - to use the original reference of debounce function
  const refContainer = useRef();
  const refDragTimeout = useRef();
  const refPositionTimeout = useRef();

  const [draggiesArr, setDraggiesArr] = useState([]);

  const updatePosition = useCallback(() => {
    if (refPositionTimeout.current) clearTimeout(refPositionTimeout.current);
    refPositionTimeout.current = setTimeout(() => {
      const updatedItemOrder = [];
      packery.getItemElements().forEach((itemElem) => {
        if (isNaN(parseInt(itemElem.id, 10)) === false) {
          updatedItemOrder.push(parseInt(itemElem.id, 10));
        }
      });
      performLayout();
      props?.onOrderChange(updatedItemOrder);
    }, 100);
  }, [props]);

  const makeDraggable = useCallback(
    (itemElem) => {
      setTimeout(() => {
        if (!itemElem.classList.contains(props.dragOptions.dragClass) && props?.allowDrag) {
          itemElem.classList.add(props.dragOptions.dragClass);
          const draggie = new Draggabilly(itemElem, props.dragOptions);
          packery.bindDraggabillyEvents(draggie);
          // draggiesArr.push(draggie);
          setDraggiesArr([...draggiesArr, draggie]);
          // draggie.on('dragEnd', updatePosition);
        } else if (itemElem.classList.contains(props.dragOptions.dragClass) && props?.allowDrag) {
          draggiesArr.forEach((draggie) => {
            draggie.enable();
            // draggie.on('dragEnd', updatePosition);
          });
        }
        packery.on('dragItemPositioned', updatePosition);
      }, 0);
    },
    [draggiesArr, props?.allowDrag, props.dragOptions, updatePosition]
  );

  const makeItemElementsDraggable = useCallback(() => {
    if (props?.allowDrag) {
      packery.getItemElements().forEach((v) => makeDraggable(v));
    } else {
      draggiesArr.forEach((draggie) => {
        draggie.disable();
      });
    }
  }, [draggiesArr, makeDraggable, props?.allowDrag]);

  // const getNewDomChildren = useCallback(() => {
  //   const node = refContainer?.current;
  //   const children = props.options.itemSelector
  //     ? node.querySelectorAll(props.options.itemSelector)
  //     : node.children;
  //   return Array.prototype.slice.call(children);
  // }, [props.options.itemSelector]);

  const loadImages = useCallback(() => {
    if (props.disableImagesLoaded || !packery) return;
    imagesloaded(refContainer.current, (instance) => {
      packery.layout();
    });
  }, [props.disableImagesLoaded]);

  const performLayout = () => {
    setTimeout(() => packery.layout(), 500);
  };

  useEffect(() => {
    if (!refContainer?.current) return;
    packery = new Packery(refContainer.current, props.options);
    // domChildren = getNewDomChildren();
    loadImages();
    makeItemElementsDraggable();
    performLayout();
    window.addEventListener('resize', performLayout);

    return () => {
      window.removeEventListener('resize', performLayout);
      clearTimeout(refDragTimeout.current);
      packery.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // setAllowDrag(props?.allowDrag);
    refDragTimeout.current = setTimeout(() => {
      makeItemElementsDraggable();
      // forceUpdate();
    }, 100);
  }, [makeItemElementsDraggable]);

  useEffect(() => {
    packery.reloadItems();
    performLayout();
  }, [props.items]);

  return (
    <div className={props.className} ref={refContainer}>
      <div className={props.options.gutterClass} />
      {props.children}
    </div>
  );
};

PackeryComponent.propTypes = {
  allowDrag: PropTypes.bool,
  items: PropTypes.array,
  options: PropTypes.shape({
    transitionDuration: PropTypes.string,
    gutter: PropTypes.string,
    stamp: PropTypes.string,
    gutterClass: PropTypes.string,
    originLeft: PropTypes.bool,
    itemSelector: PropTypes.string,
  }),
  dragOptions: PropTypes.shape({
    handle: PropTypes.string,
    dragClass: PropTypes.string,
  }),
  onOrderChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  disableImagesLoaded: PropTypes.bool,
};

export default PackeryComponent;
