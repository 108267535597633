/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useContext, useEffect } from 'react';
import {
  getProject,
  getProjects,
  getProjectFile,
  getProjectFiles,
  putProjectFile,
  postProjectContent,
  deleteProjectContent,
  putProjectFileHistoryRevert,
} from '../apis/projects';
const ProjectContext = createContext();

const ProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [projectsMeta, setProjectsMeta] = useState([]);
  const [files, setFiles] = useState([]);
  const [hasLoadedFiles, setHasLoadedFiles] = useState(false);
  const [filesMeta, setFilesMeta] = useState({ nextPage: 1 });
  const [currentFile, setCurrentFile] = useState({});
  const [isHistoryShown, setIsHistoryShown] = useState(false);
  const [historyContentId, setHistoryContentId] = useState();
  const [history, setHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filesEdited, setFilesEdited] = useState([]);

  const addFile = async (uuid, payload, config) => {
    const { data } = await postProjectContent(uuid, payload, config);
    const newFiles = [data, ...files];
    setFiles(newFiles);
    loadFile(uuid, data.id);
    return data;
  };

  const updateFile = async (uuid, fileId, payload, config, selectFile = true) => {
    const { data } = await putProjectFile(uuid, fileId, payload, config);
    if (selectFile) {
      setCurrentFile(data);
    }

    return data;
  };

  const deleteFile = async (uuid, fileId, config) => {
    await deleteProjectContent(uuid, fileId, config);
    const list = files.filter((v) => v.id !== fileId);
    setFiles(list);
  };

  const loadFile = async (projectUuid, fileId) => {
    try {
      setIsLoading(true);
      const { data } = await getProjectFile(projectUuid, fileId);
      if (data) {
        setCurrentFile(data);
      } else {
        console.warn(`File with id: ${fileId} is not found!`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadFiles = async (uuid, config, reset = false) => {
    // config.params.limit = 2;
    const { data } = await getProjectFiles(uuid, config);
    setFilesMeta(data.meta);
    setHasMore(!!data?.meta?.nextPage);

    if (reset) {
      setFiles(data.results);
    } else {
      const newFiles = [...files, ...data.results];
      setFiles(newFiles);
    }
    setHasLoadedFiles(true);
  };

  const selectHistory = (history, content) => {
    setIsHistoryShown(true);
    setCurrentFile(content);
    setHistory(history);
  };

  const restoreHistory = async (projectUuid, contentId, historyId) => {
    const { data } = await putProjectFileHistoryRevert(projectUuid, contentId, historyId);
    setCurrentFile(data);
    setIsHistoryShown(false);
    setHistory(undefined);
    setHistoryContentId(undefined);
  };

  const cancelHistory = () => {
    setIsHistoryShown(false);
    setHistory(undefined);
    setHistoryContentId(undefined);
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        setProjects,
        projectsMeta,
        setProjectsMeta,
        files,
        currentFile,
        setCurrentFile,
        setFiles,
        filesMeta,
        setFilesMeta,
        loadFiles,
        loadFile,
        isLoading,
        addFile,
        deleteFile,
        updateFile,
        history,
        isHistoryShown,
        setIsHistoryShown,
        historyContentId,
        setHistoryContentId,
        selectHistory,
        restoreHistory,
        cancelHistory,
        setHasLoadedFiles,
        hasLoadedFiles,
        hasMore,
        setHasMore,
        filesEdited,
        setFilesEdited,
      }}>
      {children}
    </ProjectContext.Provider>
  );
};

const useProjects = () => useContext(ProjectContext);

export { ProjectProvider, ProjectContext, useProjects };
