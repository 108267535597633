import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { SelectableGroup } from 'react-selectable-fast';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import WeekHourItem from './WeekHourItem';
import Styles from './Styles.module.scss';
import { convertToDay, convertToHour } from '@hiredigital/lib/helpers/date';

const Weekly = (props) => {
  const [isExpandedShown, setIsExpandedShown] = useState(false);
  const [weekHours, setWeekHours] = useState([]);
  const [isWeekHourReady, setIsWeekHourReady] = useState(false);

  useEffect(() => {
    props.getUserDailyBusySchedule().then(({ data: { results } }) => {
      setWeekHours(results.map(({ weekhour }) => weekhour));
      setIsWeekHourReady(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isExtendedHour = (weekHourIndex) => {
    const remainder = weekHourIndex % 24;
    return remainder < 8 || remainder > 18; // these are hour index between 12AM-7AM and 6PM-11PM
  };

  const handleHideExtendedHours = () => setIsExpandedShown(!isExpandedShown);

  const toHour = (hourIndex) => {
    const offsetHourIndex = isExpandedShown ? 0 : 8;
    return convertToHour(hourIndex + offsetHourIndex);
  };

  const sendRequestBusySchedule = debounce((data) => props.postUserDailyBusyScheduleSet(data), 400);

  const handleSelectionFinish = (e) => {
    const hours = e.map(({ props }) => props.weekHourIndex);
    const weekhours = isExpandedShown
      ? hours
      : [...hours, ...hours.filter((weekhour) => isExtendedHour(weekhour))];

    setWeekHours(weekhours);
    sendRequestBusySchedule({ weekhours });
  };

  const daysLabel = Array.from({ length: 7 }); //['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const hoursLabel = Array.from({
    length: isExpandedShown ? 24 : 11,
  });
  const weekHoursItem = Array.from({ length: 168 });
  const weekHourFlexBasis = 100 / hoursLabel.length;

  return (
    <Card>
      <Card.Header>
        <span className={Styles.title}>{`Daily Availability`}</span>
        <Button
          data-test-id='hideExtendedHours'
          onClick={handleHideExtendedHours}
          type={Button.Type.BLUE_OUTLINE}
          className={Styles.btnHideExtendedHours}>
          {`${isExpandedShown ? `Hide` : `Show`} Extended Hours`}
        </Button>
      </Card.Header>
      {props.showNotes && (
        <Card.Note>
          {`Let us know you're generally available on a week-to-week basis. We'll use `}
          {`this information to better plan calls, discussions and schedules with clients.`}
        </Card.Note>
      )}
      <Card.Item noPaddingLeft>
        <div className={Styles.weekContainer}>
          <div className={Styles.daysLabelContainer}>
            {daysLabel.map((day, dayLabelIndex) => (
              <div key={dayLabelIndex} className={Styles.dayLabel}>
                <span className={Styles.dayAbbreviate}>{convertToDay(dayLabelIndex, 'EE')}</span>
                <span className={Styles.dayNormal}>{convertToDay(dayLabelIndex)}</span>
              </div>
            ))}
          </div>
          <div
            className={Styles.hoursLabelContainer}
            style={{ height: `${hoursLabel.length * 30}px` }}>
            <div className={Styles.sideHoursList}>
              {hoursLabel.map((value, hourLabelIndex) => (
                <div key={hourLabelIndex} className={Styles.hourLabel}>
                  {toHour(hourLabelIndex)}
                </div>
              ))}
            </div>

            <SelectableGroup
              className={Styles.selectableGroup}
              clickableClassName={Styles.weekHourItem}
              enableDeselect
              tolerance={5}
              globalMouse={false}
              allowClickWithoutSelected={true}
              onSelectionFinish={handleSelectionFinish}>
              {isWeekHourReady && (
                <Fragment>
                  <div className={Styles.weekHoursContainer}>
                    {weekHoursItem.map((value, weekHourIndex) =>
                      !isExpandedShown && isExtendedHour(weekHourIndex) ? null : (
                        <WeekHourItem
                          data-test-id='weekHour'
                          key={weekHourIndex}
                          isSelected={weekHours.includes(weekHourIndex)}
                          style={{ flexBasis: `${weekHourFlexBasis}%` }}
                          weekHourIndex={weekHourIndex}
                        />
                      )
                    )}
                  </div>
                </Fragment>
              )}
            </SelectableGroup>
          </div>
        </div>
      </Card.Item>
    </Card>
  );
};

Weekly.propTypes = {
  getUserDailyBusySchedule: PropTypes.func,
  postUserDailyBusyScheduleSet: PropTypes.func,
};

export default Weekly;
