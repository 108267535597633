import { memo, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import Position from 'evergreen-ui/esm/constants/src/Position';
import Menu from '@hiredigital/ui/Menu/Menu';
import { Tooltip } from 'evergreen-ui/esm/tooltip';
import { SearchInput } from 'evergreen-ui/esm/search-input';
import classNames from 'classnames';

import { useOrg, useOrgDispatch } from '@context/org';
import { useUser } from '@context/user';
import { getOrganization } from '@apis/organizations';

import Avatar from '@hiredigital/ui/Avatar';

import Styles from '../Dropdown.module.scss';

import ExpandIcon from '../ExpandIcon/ExpandIcon';

const CompanyRow = ({ org }) => {
  return (
    <>
      <Avatar className={Styles.icon} name={org?.name || 'Company'} src={org?.picture} />
      <div style={{ overflow: 'hidden' }}>
        <div className={Styles.title}>{org?.name}</div>
        {/*<div className={Styles.subTitle}>{`Company`}</div>*/}
      </div>
    </>
  );
};

const MemoCompanyRow = memo(CompanyRow);

const CompanyMenu = ({ user, setOrgFilter, orgFilter, selectOrg, org }) => {
  return (
    <Fragment>
      <Menu.Item as={Link} to={`/settings/account`} className={classNames(Styles.menuItem)}>
        <Avatar
          style={{ marginRight: '10px' }}
          name={user.name}
          hash={user.uuid}
          src={user.picture}
        />
        {user?.name}
      </Menu.Item>
      <div className={Styles.lineDivider} />
      {user?.orgs?.length < 5 ? (
        <div className={Styles.menuGroupTitle}>{`Your Client Teams`}</div>
      ) : (
        <div className={Styles.searchRow}>
          <SearchInput
            placeholder='Filter client teams...'
            width='100%'
            onChange={(e) => setOrgFilter(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()} // prevent conflicts with Headless UI keyboard navigation
            value={orgFilter}
          />
        </div>
      )}
      <div className={Styles.scroller}>
        {user?.orgs
          ?.filter((o) =>
            orgFilter ? o?.name?.toLowerCase()?.includes(orgFilter?.toLowerCase()) : true
          )
          .map((o) => (
            <Menu.Item
              as='div'
              key={o.uuid}
              className={Styles.menuItem}
              onClick={() => selectOrg(o)}>
              <div className={Styles.row}>
                <Avatar
                  style={{ marginRight: '10px' }}
                  name={o.name}
                  hash={o.uuid}
                  src={o.picture}
                />
                {o.name}
              </div>
            </Menu.Item>
          ))}
      </div>
      <div className={Styles.lineDivider} />
      <Menu.Item as={Link} to={`/logout`} className={Styles.menuItem}>
        {' '}
        {/* <Link to={`/logout`}>{`Sign Out`}</Link> */}
        {`Sign Out`}
      </Menu.Item>
    </Fragment>
  );
};

const MemoCompanyMenu = memo(CompanyMenu);

const CompanyDropdown = ({ open, mobileOpen }) => {
  const history = useHistory();
  const org = useOrg();
  const user = useUser();
  const [mobileDropdown, setMobileDropdown] = useState(false);
  const [orgFilter, setOrgFilter] = useState('');
  const dispatch = useOrgDispatch();
  useEffect(() => {
    setMobileDropdown(false);
  }, [mobileOpen]);

  useEffect(() => {}, [mobileDropdown]);

  const selectOrg = async (selectedOrg) => {
    try {
      const response = await getOrganization(selectedOrg.uuid);
      const newOrg = response.data;
      // cookies.set('org_uuid', selectedOrg.uuid, COOKIE_DOMAIN);
      dispatch({ type: 'UPDATE', payload: newOrg });
      history.push('/');
    } catch (error) {
      console.error(error);
    }
  };

  return mobileOpen ? (
    <Menu className={Styles.fullWidth}>
      <Menu.Button
        as='div'
        className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}
        onClick={(e) => {
          e.stopPropagation();
          mobileOpen && setMobileDropdown(!mobileDropdown);
        }}>
        <MemoCompanyRow org={org} />
        <ExpandIcon isOpen={mobileDropdown} style={{ marginLeft: 'auto' }} />
      </Menu.Button>

      <Menu.Items
        className={classNames(Styles.mobileList, mobileDropdown && Styles.mobileListOpen)}>
        <MemoCompanyMenu
          user={user}
          setOrgFilter={setOrgFilter}
          orgFilter={orgFilter}
          selectOrg={selectOrg}
          org={org}
        />
      </Menu.Items>
    </Menu>
  ) : (
    <Menu>
      {({ open: isShown }) => (
        <Fragment>
          <Menu.Button as='div' className={Styles.fullWidth}>
            <Tooltip
              content={org?.name || 'Company'}
              position={Position.RIGHT}
              isShown={isShown || open || mobileOpen ? false : undefined}>
              <div className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}>
                <MemoCompanyRow org={org} />
                <IconDown className={Styles.sideArrow} />
              </div>
            </Tooltip>
          </Menu.Button>
          <Menu.Items direction='right' className={Styles.menuItems} style={{ width: '280px' }}>
            <MemoCompanyMenu
              user={user}
              setOrgFilter={setOrgFilter}
              orgFilter={orgFilter}
              selectOrg={selectOrg}
              org={org}
            />
          </Menu.Items>
        </Fragment>
      )}
    </Menu>
  );
};

CompanyDropdown.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
};

export default CompanyDropdown;
