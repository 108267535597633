import PropTypes from 'prop-types';
import Styles from './ConfirmAvailability.module.scss';

const Header = ({ title, children }) => {
  return (
    <div className={Styles.header}>
      <p className={Styles.headline}>{title}</p>
      {children}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Header;
