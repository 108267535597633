import { useMemo, useState, useRef, useEffect } from 'react';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { useParams } from 'react-router-dom';

import { getOrgBriefs } from '@apis/organizations';
import { useUser } from '@context/user';
import { useOrg } from '@context/org';

import { BriefFilter, BriefStage, OrganizationStatus } from '@hiredigital/lib/helpers/enum';
import { toReadableDate, toScheduledCallDateTime } from '@hiredigital/lib/helpers/date';

import withOrgGuard from '../../shared/hoc/withOrgGuard';

import Layout from '@components/Layout/AppLayout';
import Link from '@components/Link';
import TitleBar from '@components/Layout/TitleBar';
import CreateNew from '@components/CreateNew/CreateNew';

import StatusFilter from '@components/ListFilters/StatusFilter/StatusFilter';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import Styles from './Styles.module.scss';

const BriefList = () => {
  const user = useUser();
  const org = useOrg();
  const searchRef = useRef();
  const { search: initialSearch, stage: initialStage, ordering } = useParams();
  // setFilter is stage
  const [filter, setFilter] = useState(initialStage || undefined);
  const [search, setSearch] = useState(initialSearch || undefined);

  let timeout = null;
  useEffect(() => {
    setPageTitle('Talent Briefs - Hire Digital');
  }, []);
  useEffect(() => {
    if (initialSearch) {
      searchRef.current.value = initialSearch;
    }
  }, [searchRef, initialSearch]);

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        id: 'title',
        canSort: true,
        minWidth: 300,
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original: brief } }) => {
          return <Link href={`/briefs/${brief.uuid}`}>{brief.title || 'Untitled Brief'}</Link>;
        },
      },
      {
        Header: 'Last Updated',
        id: 'modified',
        width: 100,
        accessor: (b) => toReadableDate(b.modified || b.created),
      },
      {
        // eslint-disable-next-line react/display-name
        Header: () => (
          <div className={Styles.rightAlign} style={{ marginRight: '10px' }}>{`Status`}</div>
        ),
        id: 'status',
        width: 150,
        className: Styles.rightAlign,
        canSort: true,
        truncate: false,
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original: brief } }) => {
          return (
            <div className={Styles.rightAlign}>
              {BriefStage.getEnum(brief.stage).dashboardLabel}
              <div className={Styles.smallText}>
                {brief.stage === BriefStage.CALL_REVIEW.id &&
                  (brief.call?.startTime
                    ? toScheduledCallDateTime(brief.call?.startTime)
                    : `Schedule your Call`)}

                {brief.stage === BriefStage.CONFIRM.id &&
                  (!brief.flagged ? BriefStage.getLabel(brief.stage) : `Waiting for Review`)}

                {brief.stage === BriefStage.MATCH.id &&
                  (brief.numberOfApplicants === 0
                    ? `Waiting for Applicants`
                    : brief.numberOfApplicants === 1
                    ? `${brief.numberOfApplicants} Applicant`
                    : `${brief.numberOfApplicants} Applicants`)}
                {[
                  BriefStage.INFO.id,
                  BriefStage.PROJECT.id,
                  BriefStage.CALL.id,
                  BriefStage.AGREEMENT.id,
                  BriefStage.EDIT_REVIEW.id,
                  BriefStage.DEPOSIT.id,
                ].includes(brief.stage) && BriefStage.getLabel(brief.stage)}
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleFilterSelect = (item) => setFilter(item?.id);
  // const handleFilterDeselect = (item) => setFilter(null);

  const query = () => BriefFilter.getEnumByKey(filter, 'label')?.id || undefined;

  const handleSearchChange = (value) => {
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleClearFilters = () => {
    setFilter(null);
    setSearch(null);
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  const handleClearSearch = () => {
    setSearch(null);
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  const getEmptySubtitle = () => {
    if (search) {
      // eslint-disable-next-line react/display-name
      return () => (
        <span>
          {`No briefs matching `}
          <strong>{`${search}`}</strong>
          {` could be found.`}
        </span>
      );
    }

    return `No briefs matched that filter.`;
  };

  const commonProps = {
    columns,
    rowClass: Styles.row,
    cellClass: Styles.cell,
    tableClass: Styles.table,
    headerCellClass: Styles.headerCell,
    headerRowClass: Styles.headerRow,
    paginationClass: Styles.pagination,
    emptyContainerClass: Styles.emptyContainer,
    loadingContainerClass: Styles.loadingContainer,

    search,
    onClearSearch: handleClearFilters,
    emptyTitle: `No Briefs Found`,
    emptySubtitle: getEmptySubtitle(),
    defaultOrdering: ordering || '-modified',
  };

  return (
    <Layout
      header={
        <TitleBar
          inputRef={searchRef}
          title={`Talent Briefs`}
          searchPlaceholder={`Search your existing talent briefs`}
          onClearSearch={handleClearSearch}
          onSearchChange={handleSearchChange}
          showSearch={!!search}
          noWrap={false}
          filterComponent={() => (
            <StatusFilter
              initialStatus={filter}
              optionEnum={BriefFilter}
              onSelect={handleFilterSelect}
            />
          )}>
          {user?.isClient && org?.status === OrganizationStatus.ACTIVE.id && (
            <CreateNew orgUuid={org?.uuid} />
          )}
        </TitleBar>
      }>
      <div className={Styles.tableContainer}>
        {org?.uuid && (
          <PaginatedTable
            getListData={async (config) => await getOrgBriefs(org.uuid, config)}
            filters={{ stage: BriefFilter.getEnum(filter)?.stage || undefined }}
            {...commonProps}
          />
        )}
      </div>
    </Layout>
  );
};

export default withOrgGuard(BriefList);
