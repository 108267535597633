import { Fragment, useState, useEffect } from 'react';
import Styles from '../../Styles.module.scss';

import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import SegmentedControl from '../../../SegmentedControl/SegmentedControl';
import { onSkillsTreeLoadOptions } from '@apis/dropdown';
import TileSelector from '../tiles/TileSelector';
import { BriefSearchType } from '@hiredigital/lib/helpers/enum';
import { useCommon } from '@context/common';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import addDays from 'date-fns/addDays';

const SearchType = [
  { label: 'Skill', value: BriefSearchType.TALENT.id },
  { label: 'Format', value: BriefSearchType.FORMAT.id },
];

const AdditionalForm = ({
  onChange,
  engagement,
  onOtherTalentAdd,
  onOtherFormatAdd,
  isOtherBusy,
}) => {
  const common = useCommon();
  const dateNow = addDays(new Date(), 2);

  const hasOtherTalentType = engagement?.otherTalentType;
  const hasOtherFormat = engagement?.otherFormat;

  const [isEngagementShown, setIsEngagementShown] = useState(false);
  const [other, setOther] = useState(null);
  const [searchType, setSearchType] = useState(BriefSearchType.TALENT.id);
  const [talentTypes, setTalentTypes] = useState([]);
  const [formats, setFormats] = useState([]);
  const [startTime, setStartTime] = useState(dateNow);
  const [endTime, setEndTime] = useState(dateNow);
  const [data, setData] = useState({
    startTime: dateNow,
    endTime: dateNow,
  });
  const [isOtherShown, setIsOtherShown] = useState(false);
  const setter = { startTime: setStartTime, endTime: setEndTime };

  useEffect(() => {
    if (hasOtherTalentType || hasOtherFormat) {
      setIsEngagementShown(true);
      setIsOtherShown(false);
    }
  }, [engagement]);

  const handleTalentTypeAdd = (data) => {
    setTalentTypes([data]);
    if (!data?.id && !hasOtherTalentType) {
      setIsOtherShown(true);
      setIsEngagementShown(false);
      return;
    }

    setData({ ...data, talentType: data?.id });
    setIsEngagementShown(true);
  };

  const handleFormatAdd = (data) => {
    setFormats([data]);
    if (!data?.id && !hasOtherFormat) {
      setIsOtherShown(true);
      setIsEngagementShown(false);
      return;
    }

    setData({ ...data, format: data?.id });
    setIsEngagementShown(true);
  };

  const handleSearchTypeChange = (v) => {
    setSearchType(v);
    setData({ ...data, searchType: v });
  };

  const handleDateChange = (name, value) => {
    setter[name](value);
    setData({ ...data, [name]: value });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    onChange?.(data);
  }, [data]);

  const talentTypesList = common?.talentTypes?.filter((v) => v?.companyVisible);
  const formatsList = common?.formats?.filter((v) => v?.companyVisible);

  const otherTalentLabel = engagement?.other || 'Other';
  const otherFormatLabel = engagement?.otherFormat || 'Other';

  return (
    <Fragment>
      <div className={Styles.searchTypeContainer}>
        <p className={Styles.searchTypeTitle}>{`Search by Skill or Format`}</p>
        <SegmentedControl
          name={`searchType`}
          options={SearchType}
          height={40}
          width={130}
          style={{ marginLeft: 'auto' }}
          onChange={handleSearchTypeChange}
        />
      </div>
      <div className={Styles.projectKindsContainer}>
        <p className={Styles.searchTypeTitle}>{`What kind of project do you need?`}</p>
        <div className={Styles.projectKindsList}>
          {searchType === BriefSearchType.TALENT.id ? (
            <TileSelector
              className={Styles.projectKindItems}
              list={[...talentTypesList, { id: 0, label: otherTalentLabel }]}
              getIdentifier={(v) => v?.id}
              onItemAdd={handleTalentTypeAdd}
              data={talentTypes}
              isMulti={false}
            />
          ) : (
            <TileSelector
              className={Styles.projectKindItems}
              list={[...formatsList, { id: 0, label: otherFormatLabel }]}
              getIdentifier={(v) => v?.id}
              onItemAdd={handleFormatAdd}
              data={formats}
              isMulti={false}
            />
          )}
        </div>
        {isOtherShown && (
          <div className={Styles.otherContainer}>
            <TextInput
              label={`What ${
                searchType === BriefSearchType.TALENT.id ? 'talents' : 'other formats'
              } are you looking for?`}
              onChange={(e) => setOther(e.target.value)}
            />
            <Button
              isLoading={isOtherBusy}
              onClick={() => {
                searchType === BriefSearchType.TALENT.id
                  ? onOtherTalentAdd?.(other, engagement)
                  : onOtherFormatAdd?.(other, engagement);
              }}
              type={Button.Type.BLUE}
              className={Styles.actionAddOther}>
              {`Add`}
            </Button>
          </div>
        )}
        {isEngagementShown && (
          <Fragment>
            <p className={Styles.searchTypeTitle}>
              {searchType === BriefSearchType.TALENT.id ? (
                <Fragment>{engagement?.otherTalentType || talentTypes?.[0]?.label}</Fragment>
              ) : (
                <Fragment>{engagement?.otherFormat || talentTypes?.[0]?.label}</Fragment>
              )}
            </p>
            <div className={Styles.projectKindForm}>
              <InputGroup>
                <InputContainer>
                  <Select
                    label={`Skills`}
                    name={`skills`}
                    placeholder={`Select or add new skills`}
                    loadOptions={onSkillsTreeLoadOptions}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    hideSelectedOptions={false}
                    onChange={handleChange}
                    isMulti
                    isMultiLevel
                    isPaginated
                  />
                </InputContainer>
              </InputGroup>
              <InputGroup>
                <InputContainer>
                  <Select
                    label={`Country`}
                    name={`countries`}
                    options={common?.countries}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    onChange={handleChange}
                    isMulti
                  />
                </InputContainer>
                <InputContainer>
                  <Select
                    label={`Language`}
                    name={`languages`}
                    options={common?.languages}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    onChange={handleChange}
                    isMulti
                  />
                </InputContainer>
              </InputGroup>
              <InputGroup>
                <InputContainer>
                  <DatePicker
                    name='startTime'
                    label='Start Time'
                    value={startTime}
                    dateFormat='MM/dd/yyyy hh:mm aa'
                    onChange={(d) => handleDateChange('startTime', d)}
                    minDate={addDays(new Date(), 2)}
                    showTimeSelect
                  />
                </InputContainer>
                <InputContainer>
                  <DatePicker
                    name='endTime'
                    label='End Time'
                    value={endTime}
                    dateFormat='MM/dd/yyyy hh:mm aa'
                    onChange={(d) => handleDateChange('endTime', d)}
                    minDate={addDays(new Date(), 2)}
                    showTimeSelect
                  />
                </InputContainer>
              </InputGroup>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default AdditionalForm;
