import Styles from './Toggle.module.scss';
import PropTypes from 'prop-types';
import BaseToggle from 'react-toggle';

const Toggle = ({ label, ...props }) => {
  return (
    <div className={Styles.container}>
      <BaseToggle {...props} />
      {label && (
        <label htmlFor='activity-tracking' className={Styles.label}>
          {label}
        </label>
      )}
    </div>
  );
};

Toggle.propTypes = {
  label: PropTypes.string,
};

export default Toggle;
