import { memo } from 'react';
import SegmentedControl from '../SegmentedControl/SegmentedControl';

const ResumeOptions = ({ requestHidden, onChange }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <p style={{ fontWeight: 600 }}>{`Profile Viewing Options`}</p>
        <p>
          {requestHidden
            ? `Only visible to companies you have applied to.`
            : `Visible to all, to get the most engagement invites. (Recommended)`}
        </p>
      </div>
      <div>
        <SegmentedControl
          name={`confidential`}
          options={[
            { label: 'Private', value: true },
            { label: 'Default', value: false },
          ]}
          height={36}
          width={120}
          defaultValue={requestHidden}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default memo(ResumeOptions);
