import { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import Text from '@hiredigital/ui/Text/Text';

import { useUser } from '@context/user';
import { getUser, putUser } from '@apis/users';
import { PreferredPlatform } from '@hiredigital/lib/helpers/enum';

import { noteLinks, profileOptionsNotes } from './data';

import Section from '@components/StagesLayout/Section';
import Education from '@components/Resume/Education/Education';
import WorkExperience from '@components/Resume/Employment/Employment';
import PortfolioList from '@components/PortfolioList/PortfolioList';
import ActionBar from '@components/StagesLayout/ActionBar';

import IconExternal from '@hiredigital/ui/Icon/icons/external.inline.svg';

import { openUrl } from '@hiredigital/lib/helpers/utils';
import Social from '@components/Profile/Social';
import ProfileSettings from './ProfileSettings';

import Styles from './Profile.module.scss';

import Card from '@hiredigital/ui/Card';
import ResumeOption from './ResumeOption';

const Profile = ({ scrollContainer, isOnboarding }) => {
  // Only used for not onboarding
  const user = useUser();

  const [data, setData] = useState({});

  const [social, setSocial] = useState();
  const [profile, setProfile] = useState();
  const [contact, setContact] = useState();
  const [portfolio, setPortfolio] = useState();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isScrollerReady, setIsScrollerReady] = useState(false);
  const [contentWrapper, setContentWrapper] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (user) {
      getUser(user?.uuid)
        .then(({ data }) => {
          setData(data);
          setSocial(data?.social);
          setProfile(data?.profile);
          setPortfolio(data?.portfolio);
          setContact(data?.contact);
          setIsLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setIsLoaded(true);
        });
    }
  }, [user]);

  useEffect(() => {
    if (scrollContainer && contentWrapper) {
      setIsScrollerReady(true);
    }
  }, [scrollContainer, contentWrapper]);

  const validate = () => {
    const err = {};

    if (!data?.firstName) {
      err.firstName = 'Enter your first name';
    }

    if (!data?.lastName) {
      err.lastName = 'Enter your last name';
    }

    if (!profile?.description) {
      err.profile = { description: 'Add your professional bio' };
    }

    if (contact?.preferred === PreferredPlatform.PHONE.id && !contact?.phoneNumber) {
      err.contact = { phoneNumber: 'Enter your phone number' };
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validate()) return;

    setIsSaving(true);
    const d = { ...data, social, profile, contact, portfolio };
    putUser(user?.uuid, d)
      .then(({ data }) => {
        setIsSaving(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
      });
  };

  const handleChangeUser = (user) => {
    setErrors({});
    setData(user);
  };

  const handleChangeSocial = (social) => {
    setErrors({});
    setSocial(social);
  };

  const handleChangeProfile = (profile) => {
    setErrors({});
    setProfile(profile);
  };

  const handleChangePortfolio = (portfolio) => {
    setErrors({});
    setPortfolio(portfolio);
  };

  const handleChangeContact = (contact) => {
    setErrors({});
    setContact(contact);
  };

  return (
    <Section
      title={`Profile`}
      subtitle={`Add details about your professional experience to share with clients.`}
      getContentWrapper={setContentWrapper}
      className={classNames(Styles.profileContainer)}>
      {isLoaded ? (
        <Fragment>
          <div id={'profile'} />
          <Card>
            <Card.Header>{'Basic Info'}</Card.Header>
            <Card.Note>
              <p>
                {`Your profile should adhere to Hire Digital guidelines. It may be reviewed and edited by our `}
                {`moderators for presentation and clarity.`}
              </p>
              <ul className={Styles.noteLinkList}>
                {noteLinks?.map((v, i) => (
                  <li key={i}>
                    <a className={Styles.noteLink} href={v.to} rel='noreferrer' target='_blank'>
                      {v.text}
                    </a>
                  </li>
                ))}
              </ul>
            </Card.Note>
            <Card.Item>
              <ProfileSettings
                user={data}
                profile={profile}
                contact={contact}
                portfolio={portfolio}
                onChangeUser={handleChangeUser}
                onChangeProfile={handleChangeProfile}
                onChangePortfolio={handleChangePortfolio}
                onChangeContact={handleChangeContact}
                errors={errors}
                isOnboarding={isOnboarding}
              />
              <Social social={social} onChangeSocial={handleChangeSocial} />
            </Card.Item>
          </Card>
          <Card>
            <Card.Header>
              {'Profile Options'}
              <Button
                style={{ marginLeft: 'auto' }}
                type={Button.Type.LIGHT_GRAY}
                onClick={() => openUrl(`${process.env.MARKETING_URL}/resume-preview`)}>
                {`View Profile`}
                <IconExternal className={Styles.iconLink} />
              </Button>
            </Card.Header>
            <Card.Note>
              <p style={{ marginBottom: '10px' }}>{profileOptionsNotes[0]}</p>
              <p>{profileOptionsNotes[1]}</p>
            </Card.Note>
            <Card.Item>
              <ResumeOption
                requestHidden={portfolio?.requestHidden}
                onChange={(v) => setPortfolio({ ...portfolio, requestHidden: v })}
              />
            </Card.Item>
          </Card>
          <div id={'resume'} />
          <Text
            element='h2'
            size={Text.Size.SUBHEADLINE_LARGE}
            style={{ marginTop: '48px', marginBottom: '10px' }}>
            {`Resume`}
          </Text>
          <WorkExperience user={data} />
          <Education user={data} />

          <div id={`portfolio`}>
            {isScrollerReady && (
              <PortfolioList
                useContentWrapper={() => contentWrapper}
                useScrollContainer={() => scrollContainer}
                type={PortfolioList.Type.USER}
                resourceUuid={user?.uuid}
              />
            )}
          </div>

          <ActionBar isWhite={true}>
            <Button type={Button.Type.BLUE} onClick={handleSubmit} isLoading={isSaving}>
              {`Save Profile`}
            </Button>
          </ActionBar>
        </Fragment>
      ) : (
        <Loader />
      )}
    </Section>
  );
};

export default Profile;
