/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, memo, useState, useEffect, Fragment, useCallback } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';

import Loader from '@hiredigital/ui/Loader';
import IconCaret from '@hiredigital/ui/Icon/icons/caret.inline.svg';
import { useTable, useSortBy, useExpanded } from 'react-table';

import SplitButton from '@hiredigital/ui/Button/SplitButton';

import { moveProjectFiles } from '@apis/projects';

import { ContentType } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import { useProjects } from '@context/projects';
import FileItemRow from './FileItemRow';
import SearchBar from '../../SearchBar/SearchBar';

import Styles from './FileItems.module.scss';

import IconText from '@hiredigital/ui/Icon/icons/text.inline.svg';
import IconLink from '@hiredigital/ui/Icon/icons/link.inline.svg';
import FileIcon from '@hiredigital/ui/Icon/FileIcon';

const getListStyle = (isDraggingOver) => ({
  //background: isDraggingOver ? '#d2e5f8' : 'none',
});

const FileItems = ({
  project,
  onItemSelect,
  onSelectHistory,
  showSearch,
  isCollapse,
  toggleCollapse,
  listItemClass,
}) => {
  const {
    loadFiles,
    files,
    filesMeta,
    setFiles,
    deleteFile,
    currentFile,
    setCurrentFile,
    isHistoryShown,
    historyContentId,
    setHistoryContentId,
    hasMore,
    hasLoadedFiles,
  } = useProjects();
  // const [hasMore, setHasMore] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [selectedHistoryId, setSelectedHistoryId] = useState(null);

  let timeout = null;

  // useEffect(() => {
  //   console.log(currentFile);
  // }, [currentFile]);

  const handleDelete = (item) => {
    deleteFile(project.uuid, item?.id);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        id: 'title',
        canSort: true,
        // accessor: (e) => e?.title || 'Untitled Content',
        Cell: ({ row: { original: file } }) => (
          <Fragment>
            {file?.type === ContentType.TEXT.id && <IconText className={Styles.fileIcon} />}
            {file?.type === ContentType.LINK.id && <IconLink className={Styles.fileIcon} />}
            {file?.type === ContentType.ATTACHMENT.id && (
              <FileIcon
                className={Styles.fileIcon}
                url={file?.attachment || file?.pageScreenshot || ''}
              />
            )}
            <span className={Styles.textTitle}>{file?.title || 'Untitled Content'}</span>
          </Fragment>
        ),
      },
      {
        Header: 'Date',
        id: 'last_updated',
        width: 100,
        canSort: true,
        Cell: ({ row: { original: e } }) =>
          e?.lastUpdated ? (
            <div className={Styles.date}>{toReadableDate(e.lastUpdated)}</div>
          ) : null,
      },
      {
        Header: '',
        id: 'action',
        width: 30,
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => (
          <div className={Styles.moreContainer} onClick={(e) => e.stopPropagation()}>
            <SplitButton
              style={{ margin: 0 }}
              mainButtonContainerProps={{ style: { minHeight: 0 } }}
              more={[
                {
                  ...(row?.original?.type === ContentType.TEXT.id && {
                    text: 'View History',
                    ...row.getToggleRowExpandedProps(),
                  }),
                },
                {
                  ...(row?.original?.type === ContentType.ATTACHMENT.id &&
                    !!row?.original?.attachment && {
                      text: 'Download Original',
                      as: 'a',
                      target: '_blank',
                      href: row?.original?.attachment || row?.original?.preview,
                      download: row?.original?.attachmentName || row?.original?.title,
                    }),
                },
                {
                  ...(row?.original?.type === ContentType.LINK.id &&
                    !!row?.original?.url && {
                      text: 'Open In New Window',
                      as: 'a',
                      target: '_blank',
                      href: row?.original?.url,
                      download: row?.original?.title,
                    }),
                },
                { text: 'Delete', onClick: () => handleDelete(row?.original) },
              ]}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files]
    // Note for the bug fix that causes entire list to be cleared when deleting a single file:
    // since we memoize column function here to retrieve it without repeating the computation, the event handler attach for deleting a row should be re-calculated to retrieve its updated dependencies - in this case the files context
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: files,
      manualSortBy: true,
      initialState: { sortBy: [] },
    },
    useSortBy,
    useExpanded
  );

  useEffect(() => {
    if (hasLoadedFiles) {
      const ordering = sortBy.map((sort) => `${sort.desc ? '-' : ''}${sort.id}`);
      const params = { search, ordering: ordering?.[0] };
      loadFiles(project?.uuid, { params }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sortBy]);

  // useEffect(() => {
  //   setHasMore(!!filesMeta?.nextPage);
  // }, [filesMeta?.nextPage]);

  const loadMoreItems = useCallback(
    async (page) => {
      const nextPage = filesMeta?.nextPage;
      if (isNextPageLoading) return;
      if (nextPage) {
        setIsNextPageLoading(true);
        await loadFiles(project?.uuid, { params: { page: nextPage } });
        setIsNextPageLoading(false);
      }
    },
    [filesMeta, isNextPageLoading]
  );

  const onDragEnd = async ({ source, destination }) => {
    try {
      const newItems = move(source.index, destination.index);
      const ids = newItems.map((v) => v.id);
      await moveProjectFiles(project.uuid, { ids });
      // await patchProjectFile(project.uuid, item.id, { position: item.position });
    } catch (e) {
      console.log(e);
    }
  };

  const move = (sourceIdx, destinationIdx) => {
    const newItems = [...files];

    const source = {
      ...newItems.splice(sourceIdx, 1)[0],
      position: files[destinationIdx].position,
    };
    newItems.splice(destinationIdx, 0, source);

    // const isUpward = destinationIdx - sourceIdx; // if less than 0, movement is upward in the list
    // const offsetter = isUpward ? 1 : -1;

    // let endIdx = isUpward ? sourceIdx : destinationIdx + offsetter;
    // let startIdx = isUpward ? destinationIdx + offsetter : sourceIdx;

    // while (startIdx <= endIdx) {
    //   newItems[startIdx].position = newItems[startIdx].position + offsetter;
    //   startIdx++;
    // }

    setFiles(newItems);
    return newItems;
  };

  const handleSearchChange = ({ target: { value } }) => {
    debounceSearch(() => setSearch(value || undefined));
  };

  const handleSearchClear = () => {
    setSearch();
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleItemSelect = (item) => {
    setCurrentFile(item);
    onItemSelect?.(item);
  };

  const handleSelectHistory = (history, item) => {
    onSelectHistory?.(history, item);
    setSelectedHistoryId(history?.id);
  };

  useEffect(() => {
    if (!isHistoryShown) {
      toggleAllRowsExpanded(false);
      setHistoryContentId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHistoryShown, toggleAllRowsExpanded]);

  useEffect(() => {
    if (historyContentId) {
      let row = rows.find((r) => r.original.id === historyContentId);
      row.toggleRowExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyContentId]);

  return (
    <div className={classNames(Styles.container, isCollapse && Styles.collapse)}>
      <SearchBar
        defaultValue={search}
        placeholder={`Search Files`}
        onSearch={handleSearchChange}
        onClear={handleSearchClear}
        className={classNames({ [Styles.hide]: isCollapse || !showSearch })}
      />
      <div className={Styles.table} {...getTableProps()}>
        <div className={Styles.header}>
          {headerGroups.map((headerGroup, index) => (
            <div key={index} className={Styles.row} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <div
                  key={index}
                  className={classNames(Styles.cell, Styles[column.id])}
                  id={Styles[column.id]}
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span className={Styles.sortAction}>
                    {column.isSorted &&
                      (column.isSortedDesc ? (
                        <IconCaret className={classNames(Styles.toggle, Styles.down)} />
                      ) : (
                        <IconCaret className={classNames(Styles.toggle, Styles.up)} />
                      ))}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`list`}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  ...getListStyle(snapshot.isDraggingOver),
                  display: 'flex',
                  flexDirection: 'column',
                }}
                {...getTableBodyProps()}
                className={Styles.body}>
                <InfiniteScroll
                  initialLoad={true}
                  pageStart={0}
                  loadMore={loadMoreItems}
                  hasMore={hasMore}
                  threshold={100}
                  loader={
                    <Loader
                      key={1}
                      style={{ margin: 'auto' }}
                      color={Loader.Color.WHITE}
                      size={Loader.Size.SMALL}
                    />
                  }
                  useWindow={false}>
                  {rows.map((row, idx) => {
                    prepareRow(row);
                    return (
                      <FileItemRow
                        className={
                          ({ [Styles.active]: currentFile.id === row?.original.id }, listItemClass)
                        }
                        key={idx}
                        row={row}
                        index={idx}
                        projectUuid={project?.uuid}
                        onItemSelect={handleItemSelect}
                        onSelectHistory={handleSelectHistory}
                        selectedHistoryId={selectedHistoryId}
                        isCollapse={isCollapse}
                      />
                    );
                  })}
                  {provided.placeholder}
                </InfiniteScroll>
                <div
                  style={{ flexGrow: 1 }}
                  onClick={() => {
                    toggleCollapse();
                  }}></div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default memo(FileItems);
