import { useState } from 'react';
import { getDiscussionMessages } from '@apis/discussions';
import Styles from './Styles.module.scss';

import Loader from '@hiredigital/ui/Loader';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';

import classNames from 'classnames';
import convert from 'htmr';
import MessageContent from '../MessageBody';

const NestedDiscussion = ({ message, ...props }) => {
  const [isCollapse, setIsCollapse] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isNestLoaded, setIsNestLoaded] = useState(false);
  const [limit, setLimit] = useState(10);
  const [messages, setMessages] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [isLastPage, setIsLastPage] = useState(true);

  const loadNestedMessages = (params) => getDiscussionMessages(message?.metaObject, { params });

  const showMoreMessages = () => {
    if (!message?.metaObject) {
      return null;
    }
    setIsLoading(true);
    loadNestedMessages({
      limit,
      page: nextPage,
    })
      .then(({ data }) => {
        const { meta, results } = data;
        setIsNestLoaded(true);
        setIsLoading(false);
        setMessages([...messages, ...results]);
        setNextPage(meta?.nextPage);
        setIsLastPage(!meta?.nextPage);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleExpand = () => {
    setIsCollapse(false);
    if (!isNestLoaded) {
      showMoreMessages();
    }
  };

  const handleCollapse = () => setIsCollapse(true);

  const handleShowMore = (e) => {
    e.preventDefault();
    showMoreMessages();
  };

  return (
    <div className={Styles.nestContainer}>
      <div
        key={message?.id}
        className={Styles.messageItem}
        onClick={isCollapse ? handleExpand : handleCollapse}>
        <div className={Styles.content}>{convert(message?.content)} </div>
        <div className={Styles.messageItemOptions}>
          <div className={Styles.btnToggleExpand}>
            <IconDown className={classNames(Styles.icon, isCollapse ? Styles.up : Styles.down)} />
          </div>
        </div>
      </div>
      {!isCollapse && (
        <div className={Styles.nestedMessageContainer}>
          {!isLastPage && (
            <div className={Styles.showMoreContainer}>
              <button className={Styles.showMoreLink} onClick={handleShowMore}>
                {`View More`}
              </button>
            </div>
          )}

          {isLoading && (
            <Loader className={Styles.loader} color={Loader.Color.BLUE} size={Loader.Size.SMALL} />
          )}

          {!!messages?.length &&
            messages.map((message) => <MessageContent key={message.id} message={message} />)}
        </div>
      )}
    </div>
  );
};

export default NestedDiscussion;
