import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAssignment } from '@apis/assignments';
import Layout from '@components/Layout/AppLayout';
import TitleBar from '@components/Layout/DetailTitleBar';
import Detail from '@components/Assignments/Detail';

const AssignmentDetail = ({ ...props }) => {
  const { uuid } = useParams();
  const [assignment, setAssignment] = useState();
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getAssignment(uuid);
        setAssignment(response?.data);
      } catch (e) {
        console.error(e);
      }
    };
    if (uuid) {
      loadData();
    }
  }, [uuid]);

  return (
    <Layout
      style={{ backgroundColor: 'white' }}
      header={
        <TitleBar
          title={`Assignments`}
          titleLink={`/engagements`}
          subtitle={assignment?.title || 'Untitled Assignment'}></TitleBar>
      }>
      {assignment && <Detail assignment={assignment} />}
    </Layout>
  );
};

export default AssignmentDetail;
