import { useState, useEffect, useCallback } from 'react';
import BriefStart from './Start';
import BriefCall from './Call';
import BriefRequirements from './Requirements';
import BriefOverview from './Overview';
import BriefAgreement from './Agreement';
import BriefEngagement from './Engagement';
import BriefDeposit from './Deposit';
import { BriefStage } from '@hiredigital/lib/helpers/enum';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '@hiredigital/ui/Loader';

const Detail = ({ initialBrief }) => {
  const history = useHistory();
  const params = useParams();
  const { uuid, stage } = params;
  const [brief, setBrief] = useState(initialBrief);

  const getPage = (stage) => BriefStage.getEnum(stage).path;

  const loadNextPage = useCallback(
    (b) => {
      const { currentStage } = b.state;
      const page = getPage(currentStage);
      history.replace(`/briefs/${uuid}${page}`, undefined, { shallow: true });
    },
    [uuid, history]
  );

  const handleAfterSubmit = (data) => setBrief(data);

  const handleBack = (path) => history.push(`/briefs/${uuid}${path}`, undefined, { shallow: true });

  useEffect(() => {
    loadNextPage(brief);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brief]);

  switch (stage) {
    case 'start':
      return (
        <BriefStart
          briefUuid={uuid}
          initialBrief={brief}
          onAfterSubmit={handleAfterSubmit}
          onBack={handleBack}
        />
      );
    case 'call':
      return <BriefCall briefUuid={uuid} initialBrief={brief} onBack={handleBack} />;
    case 'requirements':
      return (
        <BriefRequirements
          briefUuid={uuid}
          initialBrief={brief}
          onBack={handleBack}
          onAfterSubmit={handleAfterSubmit}
        />
      );
    case 'overview':
    case 'edit-review':
      return (
        <BriefOverview
          briefUuid={uuid}
          initialBrief={brief}
          onBack={handleBack}
          onAfterSubmit={handleAfterSubmit}
        />
      );
    case 'agreement':
      return (
        <BriefAgreement
          briefUuid={uuid}
          initialBrief={brief}
          onBack={handleBack}
          onAfterSubmit={handleAfterSubmit}
        />
      );
    case 'deposit':
      return (
        <BriefDeposit
          briefUuid={uuid}
          initialBrief={brief}
          onBack={handleBack}
          onAfterSubmit={handleAfterSubmit}
        />
      );
    case 'engagements':
      return (
        <BriefEngagement
          briefUuid={uuid}
          initialBrief={brief}
          onBack={handleBack}
          onAfterSubmit={handleAfterSubmit}
        />
      );
    default:
      return <Loader type={Loader.Type.BLUE} style={{ margin: 'auto' }} />;
  }
};

Detail.propTypes = {};

export default Detail;
