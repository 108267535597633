import { useMemo, useRef, useEffect, useState, memo } from 'react';
import classNames from 'classnames';
import { useTable, useFlexLayout, useSortBy, usePagination, useExpanded } from 'react-table';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';
import qs from 'qs';

import Table from './Table';
import PageNavIcon from './PageNavIcon';

import { CancelToken } from '@apis/utils';

import Styles from './Table.module.scss';

// This component will handle the retrieval of the data

const TableContainer = memo(
  ({
    columns,
    getListData,
    listDataCallback,
    defaultPage,
    defaultLimit,
    defaultOrdering,
    defaultPaginationOptions = [20, 50, 100, 200, 500],
    search,
    filters,
    data,
    hiddenColumns = [],
    updateRow = undefined,
    deleteRow = undefined,
    rowAccessor = 'id',
    renderRowSubComponent,
    paginationClass,
    onSaveCallback,
    onDeleteCallback,
    excludeParams = [],
    showParams = true,
    emptyTitle,
    emptySubtitle,
    onClearSearch,
    getTrProps,
    paginator,
    ...props
  }) => {
    const location = useLocation();
    let cancel;
    const [limit, setPageSize] = useState(defaultLimit);
    const [page, setPage] = useState(defaultPage);
    const [ordering, setOrdering] = useState(defaultOrdering);

    const [list, setList] = useState([]);
    const [meta, setMeta] = useState({ page: 1 });
    const [query, setQuery] = useState({});
    const [isLoading, setLoading] = useState(false);
    const listRef = useRef({});
    const [isInitial, setIsInitial] = useState(true);

    const defaultColumn = useMemo(() => ({ width: 150 }), []);
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,

      state: { sortBy },
    } = useTable(
      {
        columns,
        data: list,
        defaultColumn,
        manualSortBy: true,
        autoResetPage: false,
        autoResetSortBy: true,
        initialState: {
          hiddenColumns,
          sortBy: [
            defaultOrdering?.includes('-')
              ? { desc: true, id: defaultOrdering?.substr(1) }
              : { desc: false, id: defaultOrdering },
          ],
        },
      },
      useSortBy,
      useExpanded,
      usePagination,
      useFlexLayout
    );

    const removeEmptyProps = (params) => {
      const newParams = {};
      Object.keys(params).forEach((v) => {
        if (!!params[v] && params[v] !== 'undefined' && params[v] !== 'null') {
          newParams[v] = params[v];
        }
      });
      return newParams;
    };

    const updateUrlParams = (params) => {
      if (!!excludeParams?.length) excludeParams.map((v) => delete params[v]);
      const urlSearchParam = queryString.stringify(removeEmptyProps(params), {
        parseBooleans: true,
        arrayFormat: 'comma',
      });
      window.history.replaceState(
        null,
        '',
        location.pathname + (urlSearchParam ? '?' + urlSearchParam : '')
      );
    };

    useEffect(() => {
      setList(data || []);
    }, [data]);

    const loadData = async (params, reset = true) => {
      // setLoading(true);
      if (reset) {
        if (cancel) {
          cancel();
        }
      }

      setQuery(params);

      const config = {
        params,
        cancelToken: new CancelToken((c) => {
          cancel = c;
          return;
        }),
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }), // allow query string with same key
      };

      try {
        const {
          data: { meta, results },
        } = await getListData(config);
        const resultList = reset ? results : [...list, ...results];
        setList(resultList);
        setMeta(meta);
        setLoading(false);
        listDataCallback?.(resultList, meta);
      } catch (err) {
        setPage(1);
        setLoading(false);
      }

      try {
        listRef.current._listRef._outerRef.scrollTop = 0;
      } catch (err) {}
    };

    useEffect(() => {
      if (!isInitial) {
        const ordering = sortBy.map((sort) => `${sort.desc ? '-' : ''}${sort.id}`);
        if (ordering) setOrdering(ordering);
      }
    }, [sortBy]);

    useEffect(() => {
      setLoading(true);
      loadData({ page, limit, ordering, search, ...filters }, true);
      setIsInitial(false);
    }, [search, filters, limit, page, ordering]);

    useEffect(() => {
      if (showParams) updateUrlParams({ ...query });
    }, [query]);

    useEffect(() => {
      if (updateRow) {
        const newList = list.map((origItem) =>
          origItem[rowAccessor] === updateRow[rowAccessor] ? updateRow : origItem
        );
        setList(newList);
      }
    }, [updateRow]);

    useEffect(() => {
      if (deleteRow) {
        console.log('delete row called');

        let newList = [...list];
        const deletedMsgIndex = list.findIndex(
          (origItem) => origItem[rowAccessor] === deleteRow[rowAccessor]
        );
        console.log('deletedMsgIndex', deletedMsgIndex);
        newList.splice(deletedMsgIndex, 1);
        setList(newList);
      }
    }, [deleteRow]);

    return (
      <>
        <Table
          listRef={listRef}
          list={list}
          meta={meta}
          loadData={loadData}
          isLoading={isLoading}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          getTrProps={getTrProps}
          headerGroups={headerGroups}
          rows={rows}
          columns={columns}
          renderRowSubComponent={renderRowSubComponent}
          onSaveCallback={onSaveCallback}
          onDeleteCallback={onDeleteCallback}
          emptyTitle={emptyTitle}
          emptySubtitle={emptySubtitle}
          onClearSearch={() => onClearSearch?.()}
          showClearSearch={
            list?.length === 0 && (!!search || Object.values(filters || {})?.some((v) => v))
          }
          {...props}
        />

        {paginator && typeof paginator === 'function' ? (
          paginator({ setPage, setPageSize, meta })
        ) : (
          <div className={classNames(Styles.pagination, paginationClass)}>
            <button
              data-test-id='first-button'
              onClick={() => setPage(1)}
              disabled={meta.page === 1}
              className={Styles.pageNavigation}>
              <PageNavIcon icon={`left`} repeat={2} />
              <span className={Styles.pageNavigationLeft}>First</span>
            </button>

            <button
              data-test-id='prev-button'
              onClick={() => setPage(meta.prevPage)}
              disabled={!meta.prevPage}
              className={Styles.pageNavigation}>
              <PageNavIcon icon={`left`} />
              <span className={Styles.pageNavigationLeft}>Prev</span>
            </button>

            <button
              data-test-id='next-button'
              onClick={() => setPage(meta.nextPage)}
              disabled={!meta.nextPage}
              className={Styles.pageNavigation}>
              <span className={Styles.pageNavigationRight}>Next</span>
              <PageNavIcon icon={`right`} />
            </button>

            <button
              data-test-id='last-button'
              onClick={() => setPage(Math.ceil(meta.count / meta.itemsPerPage))}
              disabled={meta.page == Math.ceil(meta.count / meta.itemsPerPage) || !meta?.count}
              className={Styles.pageNavigation}>
              <span className={Styles.pageNavigationRight}>Last</span>
              <PageNavIcon icon={`right`} repeat={2} />
            </button>

            <select
              className={Styles.pageNavDropdown}
              value={meta.page}
              onChange={(e) => {
                setPage(Number(e.target.value));
              }}>
              {(meta?.count &&
                [...Array(Math.ceil(meta.count / meta.itemsPerPage)).keys()].map((pg) => (
                  <option key={pg + 1} value={pg + 1}>
                    Page {pg + 1} of {Math.ceil(meta.count / meta.itemsPerPage)}
                  </option>
                ))) || (
                <option key={0} value={0}>
                  No Pages
                </option>
              )}
            </select>
            {meta.itemsPerPage && (
              <select
                className={Styles.pageNavDropdown}
                value={meta.itemsPerPage}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}>
                {defaultPaginationOptions.map((limit) => (
                  <option key={limit} value={limit}>
                    {limit} items per page
                  </option>
                ))}
              </select>
            )}
          </div>
        )}
      </>
    );
  }
);

export default TableContainer;
