import { Fragment, memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import IconSettings from '@hiredigital/ui/Icon/icons/settings.inline.svg';

import Menu from '@hiredigital/ui/Menu/Menu';
import Position from 'evergreen-ui/esm/constants/src/Position';
import { Tooltip } from 'evergreen-ui/esm/tooltip';

import NavStyles from '../Nav/Nav.module.scss';
import Styles from '../Dropdown.module.scss';

import { UserStatus } from '@hiredigital/lib/helpers/enum';
import { useUser } from '@context/user';
import { isTalent } from '@helpers/permissions';

import ExpandIcon from '../ExpandIcon/ExpandIcon';

const Item = () => {
  return (
    <>
      <IconSettings className={NavStyles.icon} />
      <div className={Styles.title}>Settings</div>
    </>
  );
};

const MemoItem = memo(Item);

const SettingsMenu = () => {
  const user = useUser();

  return (
    <Fragment>
      <div className={Styles.menuGroupTitle}>{`Settings`}</div>
      <Menu.Item
        as={Link}
        to={`/settings/account`}
        data-test-id='account settings'
        className={Styles.menuItem}>
        {`Account`}
      </Menu.Item>
      <Menu.Item
        as={Link}
        to={`/settings/notifications`}
        data-test-id='notification settings'
        className={Styles.menuItem}>
        {`Notifications`}
      </Menu.Item>

      {isTalent(user) ? (
        <>
          {/*user?.teams?.length > 0 && (
            <Menu.Item
              as={Link}
              to={`/settings/teams`}
              data-test-id='user teams'
              className={Styles.menuItem}>
              {`Teams`}
            </Menu.Item>
          )*/}
          <Menu.Item
            as={Link}
            to={`/settings/payments`}
            data-test-id='payment settings'
            className={Styles.menuItem}>
            {`Payments`}
          </Menu.Item>
        </>
      ) : (
        <Menu.Item
          as={Link}
          to={`/settings/clients`}
          data-test-id='user orgs'
          className={Styles.menuItem}>
          {`Client Teams`}
        </Menu.Item>
      )}
    </Fragment>
  );
};

const MemoSettingsMenu = memo(SettingsMenu);

const ProfileDropdown = ({ open, mobileOpen }) => {
  const [mobileDropdown, setMobileDropdown] = useState(false);

  useEffect(() => {
    setMobileDropdown(false);
  }, [mobileOpen]);

  useEffect(() => {}, [mobileDropdown]);

  return (
    <Fragment>
      {mobileOpen ? (
        <Menu className={Styles.fullWidth}>
          <Menu.Button
            as='div'
            className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}
            onClick={() => mobileOpen && setMobileDropdown(!mobileDropdown)}>
            <MemoItem />
            <ExpandIcon isOpen={mobileDropdown} style={{ marginLeft: 'auto' }} />
          </Menu.Button>
          <Menu.Items
            className={classNames(Styles.mobileList, mobileDropdown && Styles.mobileListOpen)}>
            <MemoSettingsMenu />
          </Menu.Items>
        </Menu>
      ) : (
        <Menu>
          {({ open: isShown }) => (
            <Fragment>
              <Menu.Button as='div' className={Styles.fullWidth}>
                <Tooltip
                  content={'Settings'}
                  position={Position.RIGHT}
                  isShown={isShown || open || mobileOpen ? false : undefined}>
                  <div className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}>
                    <MemoItem />
                    <IconDown className={Styles.sideArrow} />
                  </div>
                </Tooltip>
              </Menu.Button>
              <Menu.Items
                className={Styles.menuItems}
                direction='right_bottom'
                style={{ minWidth: 'max-content' }}>
                <MemoSettingsMenu />
              </Menu.Items>
            </Fragment>
          )}
        </Menu>
      )}
    </Fragment>
  );
};
ProfileDropdown.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
};

export default ProfileDropdown;
