import { memo, useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextInput from '@hiredigital/ui/Input/SimpleTextInput';
import Button from '@hiredigital/ui/Button';
import Styles from './CallGuest.module.scss';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import { validateEmail } from '@helpers/forms';
import Loader from '@hiredigital/ui/Loader';

const EmailItem = ({ data, onRemove, isBusy = false }) => {
  return (
    <div className={Styles.emailItem}>
      {isBusy ? (
        <Loader size={Loader.Size.SMALL} style={{ margin: 'auto' }} />
      ) : (
        <>
          <p>{data}</p>
          <button onClick={onRemove} className={Styles.actionRemove}>
            <IconClose className={Styles.iconClose} />
          </button>
        </>
      )}
    </div>
  );
};

const CallGuest = memo(
  ({
    data = [],
    className,
    titleClassName,
    children,
    onAdd,
    onRemove,
    busyIndexes,
    isAdding = false,
    isRemoving = false,
    showGuests = true,
    ...props
  }) => {
    const [email, setEmail] = useState(null);
    const [error, setError] = useState({});

    const validate = (e) => {
      // console.log(e);
      if (!e) {
        setError({ email: 'Enter a guest email.' });
        return false;
      }
      if (!validateEmail(e)) {
        setError({ email: 'Invalid email.' });
        return false;
      }

      return true;
    };

    const handleAddGuest = () => {
      if (validate(email.split(','))) {
        const newItems = [...data, email.split(',')[0].toLowerCase()];
        const hasDuplicates = newItems.length !== new Set(newItems).size;
        if (hasDuplicates) {
          toast.error('Email address already added');
          setEmail('');
        } else {
          onAdd?.(newItems);
          setEmail('');
        }
      }
    };

    const handleRemove = (idx) => {
      const newItems = data.filter((_, i) => i !== idx);
      onRemove?.(idx, newItems);
      // setTimeout(() => setItems(newItems), 500);
    };

    const handleKeyPress = (event) => {
      // For passing up keypresses + autoselect

      setError({});
      if (event.which === 13 || event.which === 44 || event.which === 32) {
        handleAddGuest();
      }
    };
    const handleChange = (e) => {
      if (e.target.value === ',' || e.target.value === ' ' || e.target.value === '/n') {
        // console.log('');
      } else {
        setEmail(e.target.value);
      }
    };
    return (
      <div className={classNames(Styles.container, className)} {...props}>
        <div className={classNames(Styles.title, titleClassName)}>{`Add Guests`}</div>
        <div className={Styles.item}>
          <TextInput
            onChange={(e) => handleChange(e)}
            name='email'
            value={email}
            label='Add one or more emails to invite'
            className={Styles.addInput}
            error={error?.email}
            onKeyPress={handleKeyPress}
          />
          <Button
            isLoading={isAdding}
            onClick={handleAddGuest}
            name='Add'
            type={Button.Type.BLUE}
            className={Styles.addButton}>
            {`Add Email`}
          </Button>
        </div>
        {showGuests && (
          <div className={Styles.list}>
            {data.map((v, idx) => (
              <EmailItem
                key={idx}
                isBusy={busyIndexes.includes(idx)}
                data={v}
                onRemove={() => handleRemove(idx)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
);

CallGuest.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  data: PropTypes.array,
  onAdd: PropTypes.func,
  isAdding: PropTypes.bool,
  isRemoving: PropTypes.bool,
  busyIndexes: PropTypes.array,
};

export default CallGuest;
