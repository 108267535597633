import { memo } from 'react';
import ResizablePane from '@hiredigital/ui/ResizablePane';
import Styles from './ContentRight.module.scss';
import classNames from 'classnames';

const ContentRight = ({ children, onResize, className }) => {
  return (
    <ResizablePane
      name='discussionWidth'
      resizerClass={Styles.hideOnMobile}
      className={classNames(Styles.contentRight, className)}
      defaultWidth={450}
      minWidth={180}
      onResize={onResize}>
      {children}
    </ResizablePane>
  );
};

export default memo(ContentRight);
