import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import Loader from '@hiredigital/ui/Loader';

import Layout from '@components/Layout/AppLayout';
import { useOrg } from '@context/org';
const Members = ({ settingsUser }) => {
  const history = useHistory();
  const org = useOrg();

  useEffect(() => {
    if (history && org) {
      history.replace(`/settings/clients/${org.uuid}/profile`);
    }
    setPageTitle('Company Profile - Hire Digital');
  }, [history, org]);

  return (
    <Layout>
      <Loader />
    </Layout>
  );
};

export default Members;
