import {
  postTeamPortfolioItem,
  postUserPortfolioItem,
  patchTeamPortfolioItem,
  patchUserPortfolioItem,
  deleteTeamPortfolioItem,
  deleteUserPortfolioItem,
  patchTeamPortfolioCaseItem,
  patchUserPortfolioCaseItem,
  postTeamPortfolioCaseItem,
  postUserPortfolioCaseItem,
  deleteTeamPortfolioCaseItem,
  deleteUserPortfolioCaseItem,
  getTeamPortfolioItems,
  getUserPortfolioItems,
  postTeamPortfolioOrder,
  postUserPortfolioOrder,
  // getUserPortfolioItem,
  // getTeamPortfolioItem,
  getUserPortfolioItemByUuid,
} from '@apis/portfolios';

export const PortfolioType = {
  USER: 0,
  TEAM: 1,
};

export const PortfolioResource = ['users', 'teams'];

export const getPortfolioItemRequests = {
  [PortfolioType.USER]: getUserPortfolioItemByUuid,
  [PortfolioType.TEAM]: getUserPortfolioItemByUuid,
};

export const getPortfolioItemsRequests = {
  [PortfolioType.USER]: getUserPortfolioItems,
  [PortfolioType.TEAM]: getTeamPortfolioItems,
};

export const postPortfolioOrderRequests = {
  [PortfolioType.USER]: postUserPortfolioOrder,
  [PortfolioType.TEAM]: postTeamPortfolioOrder,
};

export const postPortfolioRequests = {
  [PortfolioType.USER]: postUserPortfolioItem,
  [PortfolioType.TEAM]: postTeamPortfolioItem,
};

export const patchPortfolioRequests = {
  [PortfolioType.USER]: patchUserPortfolioItem,
  [PortfolioType.TEAM]: patchTeamPortfolioItem,
};

export const deletePortfolioRequests = {
  [PortfolioType.USER]: deleteUserPortfolioItem,
  [PortfolioType.TEAM]: deleteTeamPortfolioItem,
};

export const patchPortfolioCaseRequests = {
  [PortfolioType.USER]: patchUserPortfolioCaseItem,
  [PortfolioType.TEAM]: patchTeamPortfolioCaseItem,
};

export const postPortfolioCaseRequests = {
  [PortfolioType.USER]: postUserPortfolioCaseItem,
  [PortfolioType.TEAM]: postTeamPortfolioCaseItem,
};

export const deletePortfolioCaseRequests = {
  [PortfolioType.USER]: deleteUserPortfolioCaseItem,
  [PortfolioType.TEAM]: deleteTeamPortfolioCaseItem,
};
