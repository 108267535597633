import { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';

import { onLocationLoadOptions } from '@apis/dropdown';
import { useCommon } from '@context/common';

import Note from '../Note/Note';

import TextArea from '@hiredigital/ui/Input/TextArea';
import PhoneNumber from '@hiredigital/ui/PhoneNumber';

import ImageDrop from '@components/Form/File/ImageDrop';

import Styles from './Profile.module.scss';
import { PreferredPlatform } from '@hiredigital/lib/helpers/enum';

const ProfileSettings = ({
  user: initialUser,
  profile: initialProfile,
  contact: initialContact,
  portfolio: initialPortfolio,
  onChangeUser,
  onChangeProfile,
  onChangeContact,
  onChangePortfolio,
  isOnboarding,
  errors = {},
  // Notes
}) => {
  const common = useCommon();

  const [user, setUser] = useState(initialUser);
  const [profile, setProfile] = useState(initialProfile);
  const [contact, setContact] = useState(initialContact);
  const [portfolio, setPortfolio] = useState(initialPortfolio);

  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    setLanguages(common?.languages || []);
  }, [common]);

  const debouncedUserChangeHandler = debounce((u) => {
    setUser(u);
    onChangeUser?.(u);
  }, 500);

  const debouncedProfileChangeHandler = debounce((u) => {
    setProfile(u);
    onChangeProfile?.(u);
  }, 500);

  const debouncedPortfolioChangeHandler = debounce((u) => {
    setPortfolio(u);
    onChangePortfolio?.(u);
  }, 500);

  const debouncedContactChangeHandler = debounce((u) => {
    setContact(u);
    onChangeContact?.(u);
  }, 500);

  const handleLocationChange = (option) => {
    if (option.action === 'remove-value' || option.action === 'clear') {
      debouncedPortfolioChangeHandler({ ...portfolio, currentLocation: null });
    } else if (option.action === 'select-option') {
      debouncedPortfolioChangeHandler({ ...portfolio, currentLocation: option });
    }
  };

  const handlePhoneNumberChange = (value, valueKey) => {
    debouncedContactChangeHandler({ ...contact, [valueKey]: value });
  };

  const handleLanguageChange = (e) => {
    debouncedPortfolioChangeHandler({ ...portfolio, languages: [e?.target?.value] });
  };

  const handleChangePicture = ({ data }) => {
    debouncedUserChangeHandler({ ...user, picture: data?.picture });
  };

  const handleChangeUser = ({ target: { value, name } }) => {
    debouncedUserChangeHandler({ ...user, [name]: value });
  };

  const handleChangeProfile = ({ target: { value, name }, ...e }) => {
    debouncedProfileChangeHandler({ ...profile, [name]: value });
  };

  return (
    <>
      <div className={Styles.photoRow}>
        <ImageDrop
          className={classNames(Styles.profilePhoto, 'highlight-block')}
          name='picture'
          label='Picture'
          server={`users/${user?.uuid}/`}
          idleLabel='Update Picture'
          value={user?.picture}
          onResponse={handleChangePicture}
        />
        {isOnboarding && (
          <div style={{ marginLeft: '20px' }}>
            <Note
              description={() => (
                <Fragment>
                  {`Add a professional `}
                  <b>{`color`}</b>
                  {` profile photo (minimum 150*150px). Please ensure that your face is in focus, is `}
                  {`centered and fills at least 50% of the photo (`}
                  <a
                    style={{ textDecoration: 'underline' }}
                    href='https://prodcdn.content.co/p/2/common/414051107/exampleprofilephoto.jpg'
                    target='_blank'
                    rel='noreferrer'>
                    {`See an example`}
                  </a>
                  {`).`}
                </Fragment>
              )}
            />
          </div>
        )}
      </div>
      <InputGroup>
        <InputContainer>
          <TextInput
            name='firstName'
            label='First Name *'
            defaultValue={user.firstName}
            onChange={handleChangeUser}
            error={errors?.firstName}
          />
        </InputContainer>
        <InputContainer>
          <TextInput
            name='lastName'
            label='Last Name *'
            className={'highlight-block'}
            defaultValue={user.lastName}
            onChange={handleChangeUser}
            error={errors?.lastName}
          />
        </InputContainer>
      </InputGroup>
      {!isOnboarding ? (
        <>
          <InputGroup>
            <InputContainer>
              <Select
                className={'highlight-block'}
                defaultValue={portfolio?.currentLocation}
                classNamePrefix='s-contact'
                name='currentLocation'
                label='Location'
                isSavingMessage={() => 'Searching...'}
                noOptionsMessage={() => 'Start typing to search for a location'}
                getOptionLabel={({ description }) => description}
                getOptionValue={({ placeId }) => placeId}
                onChange={handleLocationChange}
                loadOptions={onLocationLoadOptions}
                isPaginated
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <Select
                className={'highlight-block'}
                defaultValue={portfolio?.languages?.[0]}
                classNamePrefix='s-contact'
                name='language'
                label='Primary Language'
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                options={languages}
                onChange={handleLanguageChange}
              />
            </InputContainer>
          </InputGroup>
        </>
      ) : (
        ''
      )}

      <InputGroup>
        <InputContainer>
          <TextArea
            name='description'
            label='Professional Bio *'
            placeholder='In 2-3 sentences, explain why you would be a good fit for projects within specialization.'
            defaultValue={profile?.description}
            onChange={handleChangeProfile}
            error={errors?.profile?.description}
          />
        </InputContainer>
      </InputGroup>
      {isOnboarding ? (
        <InputGroup>
          <InputContainer>
            {common?.countries && (
              <PhoneNumber
                label={`Mobile Number ${
                  contact?.preferred === PreferredPlatform.PHONE.id ? '*' : ''
                }`}
                className={'highlight-block'}
                phoneCountry={contact?.phoneCountry}
                phoneNumber={contact?.phoneNumber}
                onPhoneCountryChange={(v) => handlePhoneNumberChange(v, 'phoneCountry')}
                onPhoneNumberChange={(v) => handlePhoneNumberChange(v, 'phoneNumber')}
                getCountries={async () => ({ data: common.countries })}
                error={errors?.contact?.phoneNumber}
              />
            )}
          </InputContainer>
        </InputGroup>
      ) : (
        ''
      )}
    </>
  );
};

export default ProfileSettings;
