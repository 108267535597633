import { useState } from 'react';
// import { Dialog } from 'evergreen-ui/esm/dialog';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Badge from '@hiredigital/ui/Badge/Badge';
import Button from '@hiredigital/ui/Button/Button';

import OverviewHeader from './components/overview/OverviewHeader';
import OverviewItem from './components/overview/OverviewItem';
import OverviewContent from './components/overview/OverviewContent';
import OverviewBody from './components/overview/OverviewBody';
import OverviewColumn from './components/overview/OverviewColumn';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import FileList from '../Files/FileList';
import CandidateList from './components/candidates/List';
import DiscussionSidePane from '../DiscussionSidePane/DiscussionSidePane';
import {
  postAcceptEngagementApplication,
  postEngagementApplicationInterview,
  postEngagementApplicationConference,
} from '@apis/engagements';
import { getBrief } from '@apis/briefs';
import { patchConference } from '@apis/conferences';
import ConfirmCandidate from './components/candidates/ConfirmCandidate';
import AddTalent from './components/candidates/AddTalent';
import ConferenceSchedule from '../DiscussionSchedule/ConferenceSchedule';
// import Badge from '../Badge/Badge';
import { ConferenceStatus, FrontendEngagementStatus } from '@hiredigital/lib/helpers/enum';
import Styles from './Styles.module.scss';

const BriefEngagement = ({ initialBrief, onOptionSelect, briefUuid }) => {
  const [brief, setBrief] = useState(initialBrief);
  const [isShown, setIsShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [engagement, setEngagement] = useState({});
  const [application, setApplication] = useState({});
  const [assignment, setAssignment] = useState({});
  const [engagements, setEngagements] = useState(initialBrief?.engagements || []);
  const [isTalentShown, setIsTalentShown] = useState(false);
  const [isTalentLoading, setIsTalentLoading] = useState(false);
  const [conference, setConference] = useState({});
  const [isConferenceShown, setIsConferenceShown] = useState(false);
  const [scheduleType, setScheduleType] = useState(ConferenceSchedule.Type.NEW_SCHEDULE);
  const [busyApplications, setBusyApplications] = useState([]);
  const [isDiscussionOpen, setIsDiscussionOpen] = useState(false);
  const [isConfirmShown, setIsConfirmShown] = useState(false);

  const getEngagementTitle = (engagement) => {
    return (
      engagement?.talentType?.label ||
      engagement?.otherTalentType ||
      (engagement?.formatQty === 1
        ? engagement?.format?.label || engagement?.otherFormat
        : engagement?.formatQty +
          ' ' +
          (engagement?.format?.pluralLabel || engagement?.otherFormat))
    );
  };

  const formatItems = (items, key) => {
    return !!items?.length ? items?.map((v) => v[key || 'label'])?.join(', ') : 'No preference';
  };

  // const formatTimeLine = (brief) => {
  //   return brief?.estimatedStartDate && brief?.estimatedEndDate
  //     ? `${toReadableDate(brief?.estimatedStartDate)} - ${toReadableDate(brief?.estimatedEndDate)}`
  //     : `Ongoing`;
  // };

  const formatTimeLine = (b) => {
    const start = `${b?.estimatedStartDate ? `${toReadableDate(b?.estimatedStartDate)} - ` : ''}`;
    const end = `${b?.estimatedEndDate ? toReadableDate(b?.estimatedEndDate) : 'Ongoing'}`;
    return `${start}${end}`;
  };

  const handleConfirmAccept = () => {
    const applicationUuid = application?.uuid;
    setIsLoading(true);
    setBusyApplications([applicationUuid]);
    postAcceptEngagementApplication(engagement?.uuid, applicationUuid, {})
      .then(({ data }) => {
        setApplication(data);
        refreshBrief(briefUuid, engagement.uuid, () => {
          setIsShown(false);
          setIsTalentShown(true);
          setIsLoading(false);
        });
      })
      .catch((error) => {
        setIsLoading(false);
        setBusyApplications([]);
      });
  };

  const handleAcceptCandidate = (app, eng) => {
    setApplication(app);
    setEngagement(eng);
    setIsShown(true);
  };

  const handleAddTalentToProject = (ass, app) => {
    setApplication(app);
    setAssignment(ass);
    setIsTalentShown(true);
  };

  const handleRequestIntervew = (app, eng) => {
    setBusyApplications([app?.uuid]);
    setApplication(app);
    setEngagement(eng);
    setScheduleType(ConferenceSchedule.Type.NEW_SCHEDULE);
    postEngagementApplicationInterview(eng?.uuid, app?.uuid)
      .then(({ data }) => {
        postEngagementApplicationConference(eng?.uuid, app?.uuid).then((response) => {
          setConference(response?.data);
          setIsConferenceShown(true);
        });
      })
      .catch((error) => {
        setBusyApplications([]);
        console.log(error);
      });
  };

  const handleRescheduleInterview = (app, eng) => {
    setBusyApplications([app?.uuid]);
    setApplication(app);
    setEngagement(eng);
    setScheduleType(ConferenceSchedule.Type.RESCHEDULE);
    setConference(app?.discussion?.conference || {});
    setIsConferenceShown(true);
  };

  const handleCancelInterview = (app, eng) => {
    setBusyApplications([app?.uuid]);
    setApplication(app);
    setEngagement(eng);
    patchConference(app?.discussion?.conference?.uuid, { status: ConferenceStatus.CANCELLED.id })
      .then(({ data }) => {
        refreshBrief(briefUuid, eng?.uuid);
      })
      .catch((error) => {
        setBusyApplications([]);
        console.log(error);
      });
  };

  const refreshBrief = (briefUuid, engagementUuid, completion) => {
    getBrief(briefUuid).then(({ data }) => {
      const currentEngagement = data?.engagements.find((v) => v?.uuid === engagementUuid);
      setEngagement(currentEngagement);
      setAssignment(currentEngagement?.assignments?.[0] || {});
      setEngagements(data?.engagements || []);
      setBusyApplications([]);
      completion?.(data, currentEngagement);
    });
  };

  const handleConferenceSubmitCompletion = () => {
    setIsConferenceShown(false);
    setIsConfirmShown(true);
    refreshBrief(briefUuid, engagement?.uuid);
  };

  const handleViewDiscussion = (app, eng) => {
    setApplication(app);

    if (!app?.discussion?.id) {
      console.warn('No Discussion found!');
      return;
    }

    setIsDiscussionOpen(true);
  };

  return (
    <div>
      {isDiscussionOpen && (
        <DiscussionSidePane
          talent={application?.team || application?.user}
          discussion={application?.discussion}
          onMinimize={() => setIsDiscussionOpen(false)}
        />
      )}

      <Dialog
        // isShown={isConfirmShown}
        isOpen={isConfirmShown}
        title={``}
        width={330}>
        <p className={Styles.confirmationText}>
          {`We will reach out to ${
            application?.team?.name || application?.user?.name || ''
          } to confirm their availability for the interview.`}
        </p>
        <div style={{ marginTop: '30px' }}>
          <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsConfirmShown(false)}>
            {`Close`}
          </Button>
        </div>
      </Dialog>

      <ConferenceSchedule
        conference={conference}
        discussion={application?.discussion}
        isShown={isConferenceShown}
        onCancel={() => setIsConferenceShown(false)}
        onClose={() => setIsConferenceShown(false)}
        onCloseComplete={() => setBusyApplications([])}
        allowManageParticipants={false}
        onSubmit={handleConferenceSubmitCompletion}
        confirmScheduleLabel={`Notify to Confirm Availability`}
        type={scheduleType}
      />

      <ConfirmCandidate
        isShown={isShown}
        isLoading={isLoading}
        title={`Review Terms for ${application?.team?.name || application?.user?.name || ''}`}
        description={engagement?.clientTerms}
        onClose={() => setIsShown(false)}
        onConfirm={handleConfirmAccept}
      />

      <AddTalent
        isShown={isTalentShown}
        isLoading={isTalentLoading}
        title={`Add ${assignment?.team?.name || assignment?.user?.name || ''} to a Project`}
        assignment={assignment}
        talentUuid={application?.talentUuid}
        orgUuid={brief?.organization?.uuid}
        onClose={() => setIsTalentShown(false)}
      />

      <OverviewContent className={Styles.engagementSection}>
        <OverviewHeader title={`Brief`} enableEdit={false} withBorder />
        <OverviewBody>
          <OverviewColumn width={70}>
            <OverviewItem
              title={`Requirements`}
              content={brief?.description || 'No requirements added'}
              isHtml
            />
            <FileList attachments={brief?.attachments} links={brief?.links} />
          </OverviewColumn>
          <OverviewColumn width={30}>
            {(brief?.estimatedStartDate || brief?.estimatedEndDate) && (
              <OverviewItem title={`Timeline`} content={formatTimeLine(brief)} />
            )}
            {!!brief?.languages?.length && (
              <OverviewItem title={`Language`} content={formatItems(brief?.languages, 'name')} />
            )}
            {brief?.location && <OverviewItem title={`Location`} content={brief?.location} />}
          </OverviewColumn>
        </OverviewBody>
      </OverviewContent>

      {engagements?.map((engagement) => (
        <OverviewContent key={engagement?.uuid} className={Styles.engagementSection}>
          <OverviewHeader
            title={() => (
              <div className={Styles.header}>
                {getEngagementTitle(engagement) || 'Engagement'}
                {engagement.status === FrontendEngagementStatus.MATCH.id &&
                  engagement?.applications?.length > 0 && (
                    <Badge color='blue' marginLeft='10px'>{`${
                      engagement?.applications?.length
                    } Applicant${engagement?.applications?.length > 1 ? 's' : ''}`}</Badge>
                  )}
                {engagement.status === FrontendEngagementStatus.ACTIVE.id && (
                  <Badge color='green' marginLeft='10px'>
                    {`Active`}{' '}
                  </Badge>
                )}
                {engagement.status === FrontendEngagementStatus.COMPLETED.id && (
                  <Badge color='neutral' marginLeft='10px'>{`Completed`}</Badge>
                )}
              </div>
            )}
            enableEdit={false}
            withBorder
          />
          <OverviewBody direction={'column'}>
            {engagement?.applications?.length > 0 ? (
              <CandidateList
                engagement={engagement}
                onAcceptCandidate={handleAcceptCandidate}
                onAddTalentToProject={handleAddTalentToProject}
                onRequestInterview={handleRequestIntervew}
                onRescheduleInterview={handleRescheduleInterview}
                onCancelInterview={handleCancelInterview}
                onViewDiscussion={handleViewDiscussion}
                busyApplications={busyApplications}
              />
            ) : (
              <div className={Styles.emptyNote}>
                {`Hang in tight! Your Talent Specialist is looking for the best candidate for you.`}
              </div>
            )}
            <OverviewBody>
              <OverviewColumn width={70}>
                {!!engagement?.clientTerms && (
                  <OverviewItem
                    title={`Engagement Terms`}
                    content={engagement?.clientTerms}
                    isHtml
                  />
                )}
                {!!engagement?.skills?.length && (
                  <OverviewItem title={`Experience`} content={formatItems(engagement?.skills)} />
                )}
              </OverviewColumn>
              <OverviewColumn width={30}>
                {(engagement?.estimatedStartDate || engagement?.estimatedEndDate) && (
                  <OverviewItem title={`Timeline`} content={formatTimeLine(engagement)} />
                )}
                {!!engagement?.languages?.length && (
                  <OverviewItem
                    title={`Language`}
                    content={formatItems(engagement?.languages, 'name')}
                  />
                )}
                {!!engagement?.countries?.length && (
                  <OverviewItem
                    title={`Country`}
                    content={formatItems(engagement?.countries, 'name')}
                  />
                )}
                {!!engagement?.location && (
                  <OverviewItem title={`Location`} content={engagement?.location} />
                )}
              </OverviewColumn>
            </OverviewBody>
          </OverviewBody>
        </OverviewContent>
      ))}
    </div>
  );
};

BriefEngagement.propTypes = {};

export default BriefEngagement;
