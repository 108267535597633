import { Fragment, useState, useEffect } from 'react';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { getUser } from '@apis/users';
import { isTalent } from '@helpers/permissions';
import { useUser } from '@context/user';
import { authRequest, request } from '@apis/utils';

import Loader from '@hiredigital/ui/Loader';
import { BankApiManager, BankValidator } from '@hiredigital/lib/apis/class/banks';
import Layout from '@components/Layout/AppLayout';

import Section from '@components/StagesLayout/Section';

import BankAccounts from '@hiredigital/ui/BankAccounts';
import InvoiceList from '@components/Invoices/List';
import UserPaymentDetails from '@components/UserPaymentDetails/PaymentDetails';

import Styles from './Styles.module.scss';

const SettingIndexPage = () => {
  const user = useUser();
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setPageTitle('Payment Settings - Hire Digital');
  }, []);

  useEffect(() => {
    if (user?.uuid) {
      getUser(user?.uuid)
        .then((response) => {
          setData(response?.data);
          setIsLoaded(true);
        })
        .catch((error) => {
          console.warn(error);
          setIsLoaded(true);
        });
    }
  }, [user]);

  return (
    <Layout style={{ background: 'white' }}>
      <Section
        title={`Payment Settings`}
        subtitle={`Update your payment details, bank accounts, and view past invoices.`}
        className={Styles.settingsContainer}>
        {isLoaded ? (
          <Fragment>
            {isTalent(user) ? (
              <>
                <UserPaymentDetails user={data} />
                <BankAccounts
                  apiManager={new BankApiManager(authRequest, user.uuid)}
                  oldApiManager={
                    new BankApiManager(authRequest, user.uuid, { subResource: 'bank-accounts' })
                  }
                  validator={new BankValidator(request)}
                  defaultEmail={user?.email}
                  simpleRow
                />
                <InvoiceList resource={'users'} resourceUuid={user?.uuid} />
              </>
            ) : (
              ''
            )}
          </Fragment>
        ) : (
          <Loader color={Loader.Color.BLUE} />
        )}
      </Section>
    </Layout>
  );
};

export default SettingIndexPage;
