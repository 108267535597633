import { useState, useEffect, useCallback } from 'react';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import { useOrg } from '@context/org';
import { getOrgStripeCustomer } from '@apis/customers';
import { OrganizationType } from '@hiredigital/lib/helpers/enum';
import OtherPaymentsDialog from './OtherPaymentsDialog';
import NewStripeCardDialog from './NewStripeCardDialog';
import CreditCardItem from './CreditCardItem';
import Styles from './Styles.module.scss';

const CompanyCreditCardList = () => {
  const [showOtherPayments, setShowOtherPayments] = useState(false);
  const [customer, setCustomer] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const org = useOrg();

  const loadCustomers = useCallback(async () => {
    const { data } = await getOrgStripeCustomer(org.uuid);
    setCustomer(data);
  }, [org]);

  useEffect(() => {
    if (org) {
      loadCustomers();
    }
  }, [org, loadCustomers]);

  const handleCloseDialog = () => {
    setShowOtherPayments(false);
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleCloseEdit = async () => {
    setIsEdit(false);
    await loadCustomers();
  };

  return (
    <>
      {org?.type === OrganizationType.PROFESSIONAL.id &&
        (!org?.depositRequired || org?.depositPaid) && (
          <>
            <OtherPaymentsDialog isShown={showOtherPayments} onClose={handleCloseDialog} />
            <NewStripeCardDialog isShown={isEdit} onClose={handleCloseEdit} />
            <Card>
              <Card.Header>
                <span className={Styles.title}>{`Payment Methods`}</span>
                <Button
                  data-test-id='addPaymentMethod'
                  onClick={handleEdit}
                  type={Button.Type.BLUE}>
                  {`Add Payment Method`}
                </Button>
              </Card.Header>
              <Card.Note>
                {`Need other payment options? `}
                <span
                  className={Styles.link}
                  onClick={() => setShowOtherPayments(true)}>{`Submit a request`}</span>
                {` to find out more.`}
              </Card.Note>

              {customer?.cards.map((card, index) => {
                return (
                  <CreditCardItem
                    key={card.id}
                    card={card}
                    org={org}
                    loadCustomers={loadCustomers}
                  />
                );
              })}
            </Card>
          </>
        )}
    </>
  );
};

export default CompanyCreditCardList;
