import { memo } from 'react';
import Body from './Body';
import Styles from './Container.module.scss';

const Container = ({ children, header, ...props }) => {
  return (
    <div className={Styles.container} {...props}>
      {header()}
      <Body>{children}</Body>
    </div>
  );
};

export default memo(Container);
