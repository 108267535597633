import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';

import Team from './Team';
import Invitation from './Invitation';
import { getTeams } from '@apis/teams';
import { getUserTeamInvitations, patchUserTeamInvitation } from '@apis/users';
import Styles from './Styles.module.scss';
import { useHistory } from 'react-router-dom';
import { useUser, useUserDispatch } from '@context/user';
import { InvitationStatus } from '@hiredigital/lib/helpers/enum';

const List = () => {
  const user = useUser();
  const history = useHistory();
  const userDispatch = useUserDispatch();
  const [invitations, setInvitations] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [busyInvitation, setBusyInvitation] = useState([]);

  useEffect(() => {
    loadList(user?.uuid);
  }, [user]);

  const loadList = (uuid) => {
    if (!uuid) return;
    setIsLoading(true);
    getUserTeamInvitations(uuid)
      .then((data) => {
        setInvitations(data?.results);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleAdd = (item) => {
    userDispatch({
      type: 'UPDATE_TEAMS',
      payload: [item, ...user?.teams],
    });
    setIsAdding(false);
  };

  const handleEdit = (item) => history.push(`/settings/teams/${item?.uuid}`);

  const handleDelete = (item) =>
    userDispatch({
      type: 'UPDATE_TEAMS',
      payload: user?.teams?.filter((v) => v?.uuid !== item?.uuid),
    });

  const handleAcceptInvitation = ({ id }) => {
    setBusyInvitation([id]);
    patchUserTeamInvitation(user?.uuid, id, { status: InvitationStatus.ACCEPTED.id })
      .then(() => {
        setBusyInvitation([]);
        loadList(user?.uuid);
      })
      .catch((error) => {
        console.error(error);
        setBusyInvitation([]);
      });
  };

  return (
    <Card>
      <Card.Header>
        <span>{`Teams`}</span>
        <div className={Styles.action}>
          <Button name='add new' type={Button.Type.BLUE} onClick={() => setIsAdding(true)}>
            {`Add New Team`}
          </Button>
        </div>
      </Card.Header>
      <Fragment>
        {user?.teams?.map((team, index) => (
          <Team key={index} team={team} onDelete={handleDelete} onEdit={handleEdit} />
        ))}

        {invitations?.map((invitation, index) => (
          <Invitation
            key={index}
            invitation={invitation}
            onAccept={handleAcceptInvitation}
            isBusy={busyInvitation.includes(invitation?.id)}
          />
        ))}

        {isAdding && <Team onAdd={handleAdd} onCancel={() => setIsAdding(false)} isAdding />}

        {isLoading ? (
          <Card.Item>
            <Loader style={{ margin: 'auto' }} color={Loader.Color.BLUE} />
          </Card.Item>
        ) : (
          <Fragment>
            {user?.teams?.length === 0 && (
              <Card.Item>
                <div className={Styles.empty}>{`No teams added`}</div>
              </Card.Item>
            )}
          </Fragment>
        )}
      </Fragment>
    </Card>
  );
};

List.propTypes = {
  orgUuid: PropTypes.string,
};

export default List;
