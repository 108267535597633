import Note from '../Note/Note';

const Instructions = () => {
  return (
    <Note
      description={() => (
        <>
          {`Review the engagement requirements before submitting your `}
          {`application. To increase your chances of being selected for this engagement, `}
          {`we advise talents to include the following in their application:`}
        </>
      )}>
      <ol>
        <li>{`Highlight past roles or projects relevant to this engagement.`}</li>
        <li>{`Provide examples of your work by appending links or attachments that demonstrate your expertise.`}</li>
      </ol>
    </Note>
  );
};

export default Instructions;
