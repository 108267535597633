const SmallLogo = (props) => {
  return (
    <svg viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M51.16 45.76H28.84V65.2H17.32V14.8h11.52v20.52h22.32V14.8h11.52v50.4H51.16z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </svg>
  );
};

export default SmallLogo;
