import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Styles.module.scss';
import { createSelectable } from 'react-selectable-fast';

const WeekHourItem = memo(
  ({ className, isSelected, weekHourIndex, isSelecting, selectableRef, ...props }) => {
    return (
      <div
        ref={selectableRef}
        className={classNames(
          Styles.weekHourItem,
          isSelected && Styles.active,
          isSelecting && Styles.isSelecting,
          className
        )}
        {...props}>
        <p>{isSelected ? `Available` : ``}</p>
      </div>
    );
  }
);

WeekHourItem.propTypes = {
  className: PropTypes.string,
  weekHourIndex: PropTypes.number,
};

export default createSelectable(WeekHourItem);
