import { Fragment, useState, useEffect } from 'react';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { useParams } from 'react-router-dom';
import Layout from '@components/Layout/AppLayout';
import CompanyProfile from '@components/CompanyProfile/CompanyProfile';
import BrandGuidelines from '@components/BrandGuidelines/BrandGuidelines';
import Section from '@components/StagesLayout/Section';
import CompanyLogo from '@components/CompanyLogo/CompanyLogo';
import { setOrgUuid } from '@context/org';
import { getOrganization } from '@apis/organizations';
import Styles from '../Styles.module.scss';

const Profile = ({ settingsUser }) => {
  const params = useParams();
  const [org, setOrg] = useState(undefined);
  const { uuid } = params;

  useEffect(() => {
    setPageTitle('Client Profile & Guidelines - Hire Digital');
  }, []);
  useEffect(() => {
    const getData = async () => {
      const res = await getOrganization(uuid);
      setOrg(res.data);
    };
    if (uuid) {
      setOrgUuid(uuid);
      getData();
    }
  }, [uuid]);

  return (
    <Layout style={{ background: 'white' }}>
      <Section
        title={`Client Profile & Guidelines`}
        subtitle={`Update your client settings and brand guidelines.`}
        className={Styles.container}>
        {org && (
          <Fragment>
            <CompanyLogo orgUuid={org.uuid} data={org} />
            <CompanyProfile orgUuid={org.uuid} data={org} />
            <BrandGuidelines orgUuid={org.uuid} data={org} />
          </Fragment>
        )}
      </Section>
    </Layout>
  );
};

export default Profile;
