import { useEffect } from 'react';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import Layout from '@components/Layout/AppLayout';
import Teams from '@components/Teams/List';

const TeamSettingsPage = () => {
  useEffect(() => {
    setPageTitle('Team Settings - Hire Digital');
  }, []);

  return (
    <Layout>
      <Teams />
    </Layout>
  );
};

export default TeamSettingsPage;
