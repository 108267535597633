import { authRequest } from './utils';

const serializeUser = (data) => {
  const serializedData = {
    ...data,

    contact: {
      ...data.contact,
      timezone: data?.contact?.timezone?.id,
      phoneCountry: data?.contact?.phoneCountry?.id,
      preferred: data?.contact?.preferred?.id,
    },

    profile: {
      ...data.profile,
      timezone: data?.profile?.timezone?.id,
      currency: data?.profile?.currency?.id,
    },
    payment: {
      ...data.payment,
      currency: data?.payment?.currency?.id,
    },
    portfolio: {
      ...data.portfolio,
      countries: data?.portfolio?.countries?.map((obj) => obj?.id),
      languages: data?.portfolio?.languages?.map((obj) => obj?.id),
      currentLocation: data?.portfolio?.currentLocation?.placeId,
      talentTypes: undefined,
      // timezone: data?.portfolio?.timezone?.id,
      resume: undefined,
    },
    attachment: undefined,
    picture: undefined,
  };
  return serializedData;
};

export const getMe = async (config = undefined) => {
  const url = `me/`;
  return await authRequest.get(url, config);
};

export const getClients = async (config = undefined) => {
  const url = `clients/`;
  return await authRequest.get(url, config);
};

export const putUser = async (uuid, data, serialize = serializeUser, config = undefined) => {
  const url = `users/${uuid}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const patchUser = async (uuid, data, serialize = serializeUser, config = undefined) => {
  const url = `users/${uuid}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const getUser = async (uuid, config = undefined) => {
  const url = `users/${uuid}/`;
  return await authRequest.get(url, config);
};

export const getUserNotifications = async (uuid, config = undefined) => {
  const url = `users/${uuid}/app-notifications/`;
  return await authRequest.get(url, config);
};

export const postUserNotificationsMarkRead = async (uuid, config = undefined) => {
  const url = `users/${uuid}/app-notifications/mark-all-read/`;
  return await authRequest.post(url, config);
};

export const changeUserPassword = async (data, config = undefined) => {
  const url = `accounts/password/change/`;
  return await authRequest.post(url, data, config);
};

export const generateUserAgreement = async (uuid, data, config = undefined) => {
  const url = `users/${uuid}/agreements/generate/`;
  return await authRequest.post(url, data, config);
};

export const getUserAgreementLink = async (uuid, agreementId, data, config = undefined) => {
  const url = `users/${uuid}/agreements/${agreementId}/get-agreement-link/`;
  return await authRequest.get(url, data, config);
};

export const getUserNotificationSettings = async (uuid, config = undefined) => {
  const url = `email-settings/${uuid}/`;
  return await authRequest.get(url, config);
};

export const updateUserNotificationSettings = async (uuid, data, config = undefined) => {
  const url = `email-settings/${uuid}/`;
  return await authRequest.put(url, data, config);
};

export const getUserEngagements = async (uuid, config = undefined) => {
  const url = `users/${uuid}/engagements/`;
  return await authRequest.get(url, config);
};

export const postUserProfileUpdate = async (uuid) => {
  const url = `users/${uuid}/profile-updated/`;
  return await authRequest.post(url);
};

export const getUserTimesheets = async (uuid, config = undefined) => {
  const url = `users/${uuid}/timesheets/`;
  return await authRequest.get(url, config);
};

export const getUserOrganizations = async (uuid, config = undefined) => {
  const url = `users/${uuid}/orgs/`;
  return await authRequest.get(url, config);
};

export const getUserOrgInvitations = async (uuid, config = undefined) => {
  const url = `users/${uuid}/org-invitations/`;
  return await authRequest.get(url, config);
};

export const patchUserOrgInvitation = async (uuid, invitationId, config = undefined) => {
  const url = `users/${uuid}/org-invitations/${invitationId}/`;
  return await authRequest.patch(url, config);
};

export const postUserOrgLeave = async (uuid, orgUuid, config = undefined) => {
  const url = `users/${uuid}/orgs/${orgUuid}/leave/`;
  return await authRequest.post(url, config);
};

export const getUserTeamInvitations = async (uuid, config = undefined) => {
  const url = `users/${uuid}/team-invitations/`;
  return await authRequest.get(url, config);
};

export const patchUserTeamInvitation = async (uuid, invitationId, data, config = undefined) => {
  const url = `users/${uuid}/team-invitations/${invitationId}/`;
  return await authRequest.patch(url, data, config);
};

export const getUserDailyBusySchedule = async () => {
  const url = `daily-busy-schedule/`;
  return await authRequest.get(url);
};

export const postUserDailyBusyScheduleSet = async (uuid, data) => {
  const url = `daily-busy-schedule/set/`;
  return await authRequest.post(url, data);
};

export const getUserMonthlyBusySchedule = async (data) => {
  const url = `monthly-busy-schedule/`;
  return await authRequest.get(url, data);
};

export const postUserMonthlyBusyScheduleSet = async (year, month, data) => {
  const url = `monthly-busy-schedule/set/${year}/${month}/`;
  return await authRequest.post(url, data);
};

export const getUserEducation = async (uuid, educationId, config = undefined) => {
  const url = `users/${uuid}/education/${educationId}/`;
  return await authRequest.get(url, config);
};

export const putUserEducation = async (uuid, educationId, data, config = undefined) => {
  const url = `users/${uuid}/education/${educationId}/`;
  return await authRequest.put(url, data, config);
};

export const patchUserEducation = async (uuid, educationId, data, config = undefined) => {
  const url = `users/${uuid}/education/${educationId}/`;
  return await authRequest.patch(url, data, config);
};

export const deleteUserEducation = async (uuid, educationId, config = undefined) => {
  const url = `users/${uuid}/education/${educationId}/`;
  return await authRequest.delete(url, config);
};

export const postUserEducation = async (uuid, data, config = undefined) => {
  const url = `users/${uuid}/education/`;
  return await authRequest.post(url, data, config);
};

export const getUserInvoices = async (uuid, config = undefined) => {
  const url = `users/${uuid}/xero-invoices/`;
  return await authRequest.get(url, config);
};
