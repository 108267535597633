import { Fragment } from 'react';
import classNames from 'classnames';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';

import Styles from './OverviewStyles.module.scss';

const OverviewHeader = ({
  title,
  onEdit,
  onSave,
  onCancel,
  onDelete,
  isEditing,
  isSaving,
  enableCancel = true,
  enableDelete = true,
  enableEdit = true,
  withBorder = false,
  children,
}) => (
  <div className={classNames(Styles.header, { [Styles.border]: withBorder })}>
    {title && <h4 className={Styles.title}>{typeof title === 'function' ? title() : title}</h4>}
    {children}
    {isEditing ? (
      <Fragment>
        {enableDelete && (
          <Button
            onClick={() => onDelete?.()}
            type={Button.Type.LIGHT_GRAY}
            className={Styles.action}>
            {`Delete`}
          </Button>
        )}
        {enableCancel && (
          <Button onClick={() => onCancel?.()} type={Button.Type.GRAY} className={Styles.action}>
            {`Cancel`}
          </Button>
        )}
        <Button onClick={() => onSave?.()} type={Button.Type.BLUE} className={Styles.action}>
          {isSaving && (
            <Loader
              style={{ marginRight: '10px' }}
              color={Loader.Color.WHITE}
              size={Loader.Size.SMALL}
            />
          )}
          {`Save Changes`}
        </Button>
      </Fragment>
    ) : (
      enableEdit && (
        <Button
          onClick={() => onEdit?.()}
          type={Button.Type.BLUE_OUTLINE}
          className={Styles.action}>
          {`Edit`}
        </Button>
      )
    )}
  </div>
);

OverviewHeader.propTypes = {};

export default OverviewHeader;
