import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconDrag from '@hiredigital/ui/Icon/icons/drag.inline.svg';
import Styles from './PortfolioItem.module.scss';
import { getPortfolioThumbnail, checkImageUrl } from '@hiredigital/lib/helpers/utils';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const PortfolioItem = ({
  item,
  handleClass,
  onEdit,
  onPreview,
  viewOnly = false,
  showExcerpt = true,
}) => {
  const [showDesc, setShowDesc] = useState(showExcerpt);
  const [itemThumbnail, setItemThumbnail] = useState();
  const [itemImage, setItemImage] = useState();
  const title =
    item.title || (item.caseItems && item.caseItems[0] && item.caseItems[0].title) || '';
  const description = (item.caseItems && item.caseItems[0] && item.caseItems[0].description) || '';

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit?.(item);
  };

  const handlePreview = (e) => {
    e.stopPropagation();
    onPreview?.(item);
  };
  useEffect(() => {
    const thumbnail = getPortfolioThumbnail(item);
    setItemThumbnail(thumbnail);
    const image = checkImageUrl(thumbnail);
    setItemImage(image);
    if (!image) {
      setShowDesc(true);
    }
  }, []);

  return (
    <div className={Styles.container}>
      {!viewOnly && (
        <div className={classNames(Styles.header, handleClass)}>
          <button className={Styles.viewButton} onClick={handlePreview}>
            {`View`}
          </button>
          <button className={Styles.openButton} onClick={handleEdit}>
            {`Edit`}
          </button>
          <IconDrag className={Styles.dragIcon} />
        </div>
      )}

      <div style={{ position: 'relative', lineHeight: 0 }} onClick={handlePreview}>
        {item.showThumbnail && itemImage ? (
          <img className={Styles.thumbnail} alt='' src={itemThumbnail} />
        ) : (
          ''
        )}

        {!viewOnly && (
          <>
            {((!description && !item.content) || !title || item.private) && (
              <div className={Styles.notifications}>
                {item.private && (
                  <div className={classNames(Styles.notify, Styles.isPrivate)}>{`Private`}</div>
                )}
              </div>
            )}
          </>
        )}

        <div className={classNames(viewOnly && Styles.viewOnlyDetails, Styles.details)}>
          {item.publication && (item.publication.name || item.publication.url) && (
            <p className={Styles.label}>{item.publication.name || item.publication.url}</p>
          )}

          <ResponsiveEllipsis
            className={Styles.title}
            text={title || `Untitled Content`}
            maxLine='3'
            ellipsis='&hellip;'
            trimRight
            basedOn='letters'
            component='h3'
          />

          {showDesc && (
            <>
              {item.content && (
                <ResponsiveEllipsis
                  className={Styles.content}
                  text={item.content ? item.content : ''}
                  maxLine='6'
                  ellipsis='&hellip;'
                  trimRight
                  basedOn='letters'
                  component='p'
                />
              )}

              {description && (
                <ResponsiveEllipsis
                  className={Styles.content}
                  text={description}
                  maxLine='6'
                  ellipsis='&hellip;'
                  trimRight
                  basedOn='letters'
                  component='p'
                />
              )}
            </>
          )}

          {item?.skills?.length > 0 && (
            <div>
              <div className={Styles.keywords}>
                {item.skills.map((skill, index) => (
                  <div className={Styles.keyword} key={index}>
                    {skill.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PortfolioItem.propTypes = {
  item: PropTypes.object,
  handleClass: PropTypes.string,
  onEdit: PropTypes.func,
  onPreview: PropTypes.func,
};

export default PortfolioItem;
