import { authRequest } from './utils';

const serializerProject = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

const serializerDeadline = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

export const getProjects = async (config = undefined) => {
  const url = `projects/`;
  return await authRequest.get(url, config);
};

export const getProject = async (uuid, config = undefined) => {
  const url = `projects/${uuid}/`;
  return await authRequest.get(url, config);
};

export const deleteProject = async (uuid, config = undefined) => {
  const url = `projects/${uuid}/`;
  return await authRequest.delete(url, config);
};

export const patchProject = async (
  uuid,
  data,
  serialize = serializerProject,
  config = undefined
) => {
  const url = `projects/${uuid}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const getOrgProjects = async (orgUuid, config = undefined) => {
  const url = `orgs/${orgUuid}/projects/`;
  return await authRequest.get(url, config);
};

export const getProjectFiles = async (uuid, config = undefined) => {
  const url = `projects/${uuid}/content/`;
  return await authRequest.get(url, config);
};

export const getProjectFile = async (uuid, contentId, config = undefined) => {
  const url = `projects/${uuid}/content/${contentId}/`;
  return await authRequest.get(url, config);
};

export const getProjectFileHistories = async (uuid, contentId, config = undefined) => {
  const url = `projects/${uuid}/content/${contentId}/history/`;
  return await authRequest.get(url, config);
};

export const getProjectFileHistory = async (uuid, contentId, historyId, config = undefined) => {
  const url = `projects/${uuid}/content/${contentId}/history/${historyId}/`;
  return await authRequest.get(url, config);
};

export const putProjectFileHistoryRevert = async (
  uuid,
  contentId,
  historyId,
  config = undefined
) => {
  const url = `projects/${uuid}/content/${contentId}/history/${historyId}/revert/`;
  return await authRequest.put(url, config);
};

export const postProjectContent = async (uuid, data, config = undefined) => {
  const url = `projects/${uuid}/content/`;
  return await authRequest.post(url, data, config);
};

export const putProjectFile = async (uuid, contentUuid, data, config = undefined) => {
  const url = `projects/${uuid}/content/${contentUuid}/`;
  return await authRequest.put(url, data, config);
};

export const patchProjectFile = async (uuid, contentUuid, data, config = undefined) => {
  const url = `projects/${uuid}/content/${contentUuid}/`;
  return await authRequest.patch(url, data, config);
};

export const moveProjectFiles = async (uuid, data, config = undefined) => {
  const url = `projects/${uuid}/content/move-all/`;
  return await authRequest.post(url, data, config);
};

export const deleteProjectContent = async (uuid, contentUuid, config = undefined) => {
  const url = `projects/${uuid}/content/${contentUuid}/`;
  return await authRequest.delete(url, config);
};

export const postProjectDeadline = async (
  uuid,
  data,
  serialize = serializerDeadline,
  config = undefined
) => {
  const url = `projects/${uuid}/deadlines/`;
  return await authRequest.post(url, serialize(data), config);
};

export const putProjectDeadline = async (
  uuid,
  projectId,
  data,
  serialize = serializerDeadline,
  config = undefined
) => {
  const url = `projects/${uuid}/deadlines/${projectId}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const postProjectDeadlineComplete = async (uuid, projectId, data, config = undefined) => {
  const url = `projects/${uuid}/deadlines/${projectId}/complete/`;
  return await authRequest.post(url, data, config);
};

export const postProjectDeadlineActive = async (uuid, projectId, data, config = undefined) => {
  const url = `projects/${uuid}/deadlines/${projectId}/active/`;
  return await authRequest.post(url, data, config);
};

export const deleteProjectDeadline = async (uuid, projectId, config = undefined) => {
  const url = `projects/${uuid}/deadlines/${projectId}/`;
  return await authRequest.delete(url, config);
};

export const getProjectDeadlines = async (uuid, config = undefined) => {
  const url = `projects/${uuid}/deadlines/`;
  return await authRequest.get(url, config);
};

export const postConvertProjectToBrief = async (uuid, data = {}, config = undefined) => {
  const url = `projects/${uuid}/convert-to-brief/`;
  return await authRequest.post(url, data, config);
};

export const postProjectStart = async (
  uuid,
  data,
  serialize = serializerProject,
  config = undefined
) => {
  const url = `projects/${uuid}/start/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postProjectCopyReference = async (uuid, data, config = undefined) => {
  const url = `projects/${uuid}/copy-references/`;
  return await authRequest.post(url, data, config);
};

export const postProjectAddTalent = async (uuid, data, config = undefined) => {
  const url = `projects/${uuid}/add-talent/`;
  return await authRequest.post(url, data, config);
};

export const getProjectMembers = async (uuid, config = undefined) => {
  const url = `projects/${uuid}/members/`;
  return await authRequest.get(url, config);
};

export const postProjectMember = async (uuid, data, config = undefined) => {
  const url = `projects/${uuid}/members/`;
  return await authRequest.post(url, data, config);
};

export const putProjectMember = async (uuid, memberId, data, config = undefined) => {
  const url = `projects/${uuid}/members/${memberId}/`;
  return await authRequest.put(url, data, config);
};

export const deleteProjectMember = async (uuid, memberId, config = undefined) => {
  const url = `projects/${uuid}/members/${memberId}/`;
  return await authRequest.delete(url, config);
};

export const postProjectComplete = async (uuid, data, config = undefined) => {
  const url = `projects/${uuid}/complete/`;
  return await authRequest.post(url, data, config);
};

export const postProjectSendBack = async (uuid, data, config = undefined) => {
  const url = `projects/${uuid}/send-back/`;
  return await authRequest.post(url, data, config);
};

export const postProjectSubmit = async (uuid, data, config = undefined) => {
  const url = `projects/${uuid}/submit/`;
  return await authRequest.post(url, data, config);
};

export const postProjectLink = async (uuid, data, config = undefined) => {
  const url = `projects/${uuid}/links/`;
  return await authRequest.post(url, data, config);
};

export const postProjectFile = async (uuid, data, config = undefined) => {
  const url = `projects/${uuid}/attachments/`;
  return await authRequest.post(url, data, config);
};

export const deleteProjectLink = async (uuid, linkId, config = undefined) => {
  const url = `projects/${uuid}/links/${linkId}/`;
  return await authRequest.delete(url, config);
};

export const deleteProjectFile = async (uuid, fileId, config = undefined) => {
  const url = `projects/${uuid}/attachments/${fileId}/`;
  return await authRequest.delete(url, config);
};
