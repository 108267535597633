import Styles from './WorkForm.module.scss';
import Employment from '@components/Resume/Employment/Employment';

const WorkForm = ({ style, value, ...props }) => {
  return (
    <div className={Styles.container}>
      <Employment
        user={{}}
        title={`Add Work Experience`}
        addText={`Add Work Experience`}
        noBorder
      />
    </div>
  );
};

export default WorkForm;
