import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class Layout extends Component {
  static propTypes = {
    location: PropTypes.object,
    children: PropTypes.node,
  };
  constructor(props) {
    super(props);
  }
  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

export default Layout;
