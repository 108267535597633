// import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import format from 'date-fns-tz/format';
import setDay from 'date-fns/setDay';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import setHours from 'date-fns/setHours';
import subDays from 'date-fns/subDays';
import add from 'date-fns/add';
import formatISO from 'date-fns/formatISO';

export const timeFormatter = (date) => {
  return format(new Date(date), 'h:mm a');
};

export const callTimeFormatter = (date, offset) => {
  const zonedDate = utcToZonedTime(new Date(date), offset); // In June 10am UTC is 6am in New York (-04:00)
  return format(zonedDate, 'h:mm a', { timeZone: offset });
};

export const callDayFormatter = (date, offset) => {
  const zonedDate = utcToZonedTime(new Date(date), offset); // In June 10am UTC is 6am in New York (-04:00)
  return format(zonedDate, 'EEE, d MMM, yyyy', { timeZone: offset });
};

export const toReadableDate = (date, f = 'd MMM yyyy') => {
  return format(new Date(date), f);
};

export const toReadableDateTime = (date) => {
  return format(new Date(date), 'd MMM yyyy h:mm:ss a');
};

export const toReadableMedDateTime = (date) => {
  return format(new Date(date), 'd MMM yy h:mm a');
};

export const toReadableShortDateTime = (date) => {
  return format(new Date(date), 'd MMM h:mm a');
};

export const toFormattedDateRange = (
  start,
  end,
  { separator = '-', formatType = 'short' } = {}
) => {
  try {
    if (!start && !end) return;

    const [dateFormat, timeFormat] =
      {
        short: ['d MMM', 'h:mm a'],
        medium: ['d MMM yy', 'h:mm a'],
        long: ['d MMM yyyy', 'h:mm a'],
      }[formatType] || [];

    const dateTimeFormat = [dateFormat, timeFormat].join(' ');

    if (!end) return format(new Date(start), dateTimeFormat);
    if (!start) return format(new Date(end), dateTimeFormat);

    const sDate = new Date(start);
    const eDate = new Date(end);
    const formattedStart = format(sDate, dateTimeFormat);

    if (isSameDay(sDate, eDate)) {
      const formattedEndTime = format(eDate, timeFormat);
      return [formattedStart, separator, formattedEndTime].join(' ');
    }

    const formattedEnd = format(eDate, dateTimeFormat);
    return [formattedStart, separator, formattedEnd].join(' ');
  } catch (err) {
    console.error(err);
    return 'Invalid date value';
  }
};

export const toScheduledCallDateTime = (date) => {
  const d = new Date(date);
  return `${format(d, 'h:mm a')} on ${format(d, 'dd MMM yyyy')}`;
};

export const getDateFromMonthYear = (m, y) => {
  return new Date(y, m, 0, 0, 0, 0, 0);
};

export const isDateValid = (month, year) => {
  let isValid = true;
  let error = null;
  if (year < 1900 || year > 2030) {
    isValid = true;
    error = 'Enter a valid year';
  } else if (month > 12) {
    isValid = true;
    error = 'Enter a valid month';
  }
  return { isValid, error };
};

export const daysOfWeek = [
  { short: 'Su', long: 'Sunday' },
  { short: 'Mo', long: 'Monday' },
  { short: 'Tu', long: 'Tuesday' },
  { short: 'We', long: 'Wednesday' },
  { short: 'Th', long: 'Thursday' },
  { short: 'Fr', long: 'Friday' },
  { short: 'Sa', long: 'Saturday' },
];

export const hoursInDay = [
  { value: '12 AM', extended: true },
  { value: '1 AM', extended: true },
  { value: '2 AM', extended: true },
  { value: '3 AM', extended: true },
  { value: '4 AM', extended: true },
  { value: '5 AM', extended: true },
  { value: '6 AM', extended: true },
  { value: '7 AM', extended: true },
  { value: '8 AM', extended: false },
  { value: '9 AM', extended: false },
  { value: '10 AM', extended: false },
  { value: '11 AM', extended: false },
  { value: '12 PM', extended: false },
  { value: '1 PM', extended: false },
  { value: '2 PM', extended: false },
  { value: '3 PM', extended: false },
  { value: '4 PM', extended: false },
  { value: '5 PM', extended: false },
  { value: '6 PM', extended: false },
  { value: '7 PM', extended: true },
  { value: '8 PM', extended: true },
  { value: '9 PM', extended: true },
  { value: '10 PM', extended: true },
  { value: '11 PM', extended: true },
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getDuration = (startMonth, startYear, endMonth, endYear) => {
  if (startMonth) {
    let ret, endDate;
    const startDate = subDays(new Date(startYear, startMonth - 1), 1);

    if (endMonth && endYear) {
      endDate = new Date(endYear, endMonth - 1);
    } else {
      endDate = new Date();
    }
    const diff = differenceInCalendarMonths(endDate, startDate);

    const years = Math.floor(diff / 12);
    const months = diff % 12;

    if (years || months) {
      ret =
        ' (' +
        (years ? years + (years === 1 ? ' year' : ' years') : '') +
        (years === 0 ? '' : months ? ' ' : '') +
        (months ? String(months) + (months === 1 ? ' month' : ' months') : '') +
        ')';
    }

    return ret || '';
  }
  return '';
};

export const monthNumToName = (monthnum) => {
  return months[monthnum - 1] || '';
};

export const getDates = (startMonth, startYear, endMonth, endYear) => {
  if (!startMonth && !startYear) {
    return '';
  }
  const start = `${monthNumToName(startMonth)} ${startYear}`;
  const end = endMonth ? `${monthNumToName(endMonth)} ${endYear}` : `Present`;
  return `${start} - ${end}`;
};

// ie. 120 -> 00:02:00
export const secondsToDuration = (seconds) => {
  let h = String(Math.floor(seconds / 3600));
  let m = String(Math.floor((seconds % 3600) / 60));
  let s = String(Math.floor((seconds % 3600) % 60));

  h = h.length === 1 ? '0' + h : h;
  m = m.length === 1 ? '0' + m : m;
  s = s.length === 1 ? '0' + s : s;

  return h + ':' + m + ':' + s;
};

// ie. 11:11:11 to 40271
export const durationToSeconds = (duration) => {
  const d = String(duration.replaceAll('_', '')) || '';
  const [h = 0, m = 0, s = 0] = d.split(':').map((v) => v.padStart(2, '0'));
  const seconds = parseInt(h) * 60 * 60 + parseInt(m) * 60 + parseInt(s);
  return seconds;
};

export const addDurationToTime = (duration, time) => {
  const seconds = durationToSeconds(duration);
  const end = add(new Date(time), { seconds });
  return end;
};

export const checkDateValidity = (date) => {
  return !isAfter(new Date('01/01/1900'), date);
};

export const convertToDay = (dayIndex, dayFormat = 'EEEE') => {
  return format(setDay(new Date(), dayIndex), dayFormat);
};

export const convertToHour = (hour, hourFormat = 'h a') => {
  return format(setHours(new Date(), hour), hourFormat);
};

export const hoursToDaysMonthsYears = (h) => {
  let value = h;

  let units = {
    years: 24 * 30.5 * 12,
    months: 24 * 30.5,
    days: 24,
  };

  let result = {};
  for (let u in units) {
    let p = Math.floor(value / units[u]);
    result[u] = p > 0 ? p : 0;
    value = value % units[u];
  }

  return result;
};

export const convertToDuration = (v) => {
  if (!v) return {};

  let [h, minutes, s] = v.split(':');
  let [seconds, milliseconds] = s.includes('.') ? parseFloat(s).toFixed(3).split('.') : [s, 0];
  let [days, hours] = h.includes(' ') ? h.split(' ') : [0, h];

  const convertToInt = (v) => parseInt(v || 0);

  milliseconds = convertToInt(milliseconds);
  seconds = convertToInt(seconds);
  minutes = convertToInt(minutes);
  hours = convertToInt(hours);
  days = convertToInt(days);

  const values = {
    milliseconds,
    seconds,
    minutes,
    hours,
    days,
  };

  const units = {
    milliseconds: 1000, // to seconds
    seconds: 60, // to minutes
    minutes: 60, // to hours
    hours: 24, // to days
  };

  let value = {};
  let nextValue = 0;
  for (let u in units) {
    let rem = values[u] % units[u];
    value[u] = rem + nextValue;
    nextValue = Math.floor(values[u] / units[u]);
  }

  const dmy = hoursToDaysMonthsYears(value.hours + days * 24);
  return { ...value, ...dmy };
};

export const convertNumericToDuration = (duration) => {
  let hours = String(Math.floor(duration / 3600));
  let mins = String(Math.floor((duration % 3600) / 60));
  let secs = String(Math.floor((duration % 3600) % 60));

  hours = hours.length === 1 ? '0' + hours : hours;
  mins = mins.length === 1 ? '0' + mins : mins;
  secs = secs.length === 1 ? '0' + secs : secs;

  return hours + ':' + mins + ':' + secs;
};

export const millisecondsToSeconds = (ms) => {
  if (!ms) return 0;
  return ms / 1000;
};

export const secsToHours = (s) => {
  if (!s) return 0;
  return s / 3600;
};

export const minsToHours = (m) => {
  if (!m) return 0;
  return m / 60;
};

export const daysToHours = (d) => {
  if (!d) return 0;
  return d * 24;
};

export const monthsToHours = (mo) => {
  if (!mo) return 0;
  return mo * 30.5 * 24;
};

export const yearsToHours = (y) => {
  if (!y) return 0;
  return y * 30.5 * 24 * 12;
};

export const toDurationString = (duration) => {
  let hours = String(Math.floor(duration / 3600));
  let mins = String(Math.floor((duration % 3600) / 60));
  let secs = String(Math.floor((duration % 3600) % 60));

  hours = hours.length === 1 ? '0' + hours : hours;
  mins = mins.length === 1 ? '0' + mins : mins;
  secs = secs.length === 1 ? '0' + secs : secs;
  return hours + ':' + mins + ':' + secs;
};

export const durationToHours = (duration) => {
  const total =
    secsToHours(duration.seconds + millisecondsToSeconds(duration.milliseconds)) +
    minsToHours(duration.minutes) +
    duration.hours +
    daysToHours(duration.days) +
    monthsToHours(duration.months) +
    yearsToHours(duration.years);

  return parseFloat(total);
};

export const addZero = (v) => {
  return `${v}`.length === 1 ? `0${v}`.slice(-2) : v;
};

export const durationToTimestamps = (duration) => {
  const h =
    daysToHours(duration.days) +
    monthsToHours(duration.months) +
    yearsToHours(duration.years) +
    duration.hours;
  return `${addZero(h) || '00'}:${addZero(duration.minutes) || '00'}:${
    addZero(duration.seconds) || '00'
  }`;
};

export const toISOExactDateTime = (d, isStart) =>
  formatISO(new Date(d).setHours(...(isStart ? [0, 0, 0, 0] : [23, 59, 59, 999])));
