/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from 'react';

const IntercomContext = createContext();

const IntercomProvider = ({
  children,
  appId,
  settings,
  timeout = 1000,
  autoCloseLauncher = true,
}) => {
  const initialize = (id, settings) => {
    window.intercomSettings = settings;
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      const i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        setTimeout(function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + id;
          const x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);

          if (autoCloseLauncher) {
            s.onload = () => {
              w.Intercom('onHide', hideLauncher);
            };
          }
        }, timeout);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  };

  const sendEvent = (e = 'update') => {
    window && window.Intercom && window.Intercom(e);
    return e;
  };

  const boot = () => {
    return sendEvent('boot');
  };

  const show = () => {
    showLauncher();
    return sendEvent('show');
  };

  const hide = () => {
    hideLauncher();
    return sendEvent('hide');
  };

  const toggle = () => (isVisible() ? hide() : show());

  const isVisible = () => {
    return !!document.querySelector('body.show-intercom');
  };

  const showLauncher = () => {
    const body = document.querySelector('body');
    if (body) {
      body.classList.add('show-intercom');
    }
  };

  const hideLauncher = () => {
    const body = document.querySelector('body');
    if (body) {
      body.classList.remove('show-intercom');
    }
  };

  useEffect(() => {
    initialize(appId, settings);
  }, []);

  return (
    <IntercomContext.Provider value={{ toggle, boot, hide }}>{children}</IntercomContext.Provider>
  );
};

const useIntercom = () => useContext(IntercomContext);

export { IntercomProvider, IntercomContext, useIntercom };
