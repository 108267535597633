import { useReducer, useContext, createContext } from 'react';

const TeamContext = createContext();
const TeamDispatchContext = createContext();

const reducer = (team, action) => {
  switch (action.type) {
    case 'UPDATE':
      return action.payload;
    case 'UPDATE_COMPLETION':
      return { ...team, ...action.payload };
    case 'UPDATE_CLIENTS':
      return { ...team, clients: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const TeamProvider = ({ children, initial }) => {
  const [team, dispatch] = useReducer(reducer, initial);
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <TeamDispatchContext.Provider value={dispatch}>
      {/*eslint-disable-next-line react/react-in-jsx-scope*/}
      <TeamContext.Provider value={team}>{children}</TeamContext.Provider>
    </TeamDispatchContext.Provider>
  );
};

export const useTeam = () => useContext(TeamContext);
export const useTeamDispatch = () => useContext(TeamDispatchContext);
