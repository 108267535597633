import { Fragment, useState, useEffect, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InitialSelect from 'react-select';
import InitialAsyncSelect from 'react-select/async';
import InitialCreatableSelect from 'react-select/creatable';
import InitialAsyncCreatableSelect from 'react-select/async-creatable';
import { customStyles } from './SelectStyles';

import { withAsyncPaginate, useAsyncPaginate, useComponents } from 'react-select-async-paginate';

import Styles from './Input.module.scss';

const Select = InitialSelect;
const AsyncSelect = InitialAsyncSelect;
const CreatableSelect = InitialCreatableSelect;
const AsyncCreatableSelect = InitialAsyncCreatableSelect;

const RightArrow = () => <span className={Styles.rightArrow} />;
const DownArrow = () => <span className={Styles.downArrow} />;

const Option = (props) => {
  const { data, innerProps, indentBy = 0, selectOption, selectProps } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const newInnerProps = { ...innerProps, onClick: () => selectOption(data) };
  const children = (data && selectProps?.getOptionChildren?.(data)) || data?.children || [];
  return (
    <Fragment>
      <div className={Styles.multiLevelOption}>
        <div className={Styles.actions}>
          {data?.children?.length > 0 && (
            <button className={Styles.action} onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <RightArrow /> : <DownArrow />}
            </button>
          )}
        </div>
        <div
          {...newInnerProps}
          style={{ marginLeft: `${20 * indentBy}px` }}
          className={Styles.optionItem}>
          {selectProps?.getOptionLabel?.(data)}
        </div>
      </div>
      {isExpanded && children?.length > 0
        ? children?.map((v, idx) => (
            <Option key={idx} {...props} data={v} indentBy={indentBy + 1} />
          ))
        : null}
    </Fragment>
  );
};

const SelectInput = ({
  disabled,
  name,
  className,
  label,
  placeholder,
  onChange,
  classNamePrefix,
  value: initialValue,
  defaultValue: initialDefaultValue,
  loadOptionsOnMenuOpen = true,
  options,
  filterOption,
  reduceOptions,
  shouldLoadMore,
  icon: Icon,
  components,
  additional = { page: 1 },
  data,
  error,
  isAsync,
  isCreatable,
  isPaginated,
  isMultiLevel,
  isMulti,
  ...props
}) => {
  const selectRef = useRef();
  const [value, setValue] = useState(initialValue || initialDefaultValue);
  const containerClass = classNames(
    Styles.container,
    className,
    isMulti && value?.length && Styles.active,
    !isMulti && Object.keys(value || {}).length && Styles.active,
    disabled && Styles.locked,
    error && Styles.containerError,
    isMulti && Styles.multiContainer,
    !label && Styles.noLabel
  );

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    } else if (
      (initialValue === null || initialValue === undefined) &&
      selectRef.current?.state?.value?.value
    ) {
      selectRef.current?.select?.clearValue();
    } else if (
      !initialDefaultValue &&
      (initialValue === null || initialValue === undefined) &&
      selectRef.current?.state?.selectValue?.length
    ) {
      // This checks for the case where we are using a controlled component and
      // the value is cleared. this will check for a value and clear it if it exists
      selectRef.current?.clearValue();
    }
  }, [initialValue]);

  useEffect(() => {
    if (initialValue && initialDefaultValue) {
      console.warn('Its not recommended to use defaultValue and value prop at the same time!');
    }
  }, [initialValue, initialDefaultValue]);

  const fieldClass = classNames(
    Styles.select,
    isMulti && Styles.multi,
    error && Styles.error,
    !!Icon && Styles.hasIcon
  );

  const labelClass = classNames(
    Styles.label,
    error && Styles.error,
    isMulti && value?.length && Styles.hasValue,
    !isMulti && Object.keys(value || {}).length && Styles.hasValue
  );

  const handleChange = (event, { action, name }) => {
    setValue(event);
    onChange?.({ ...event, target: { name, value: event }, action });
  };

  let CustomSelect;
  if (isAsync && isCreatable) {
    CustomSelect = AsyncCreatableSelect;
  } else if (isAsync) {
    CustomSelect = AsyncSelect;
  } else if (isCreatable) {
    CustomSelect = CreatableSelect;
  } else {
    CustomSelect = Select;
  }

  // let asyncPaginateProps;
  if (isPaginated) {
    if (isCreatable) {
      CustomSelect = CreatableSelect;
    } else {
      CustomSelect = Select;
    }
    CustomSelect = withAsyncPaginate(CustomSelect);
  } else {
    if (isAsync && isCreatable) {
      CustomSelect = AsyncCreatableSelect;
    } else if (isAsync) {
      CustomSelect = AsyncSelect;
    } else if (isCreatable) {
      CustomSelect = CreatableSelect;
    } else {
      CustomSelect = Select;
    }
  }

  // components = useComponents(components);

  // const CustomSelect = isPaginated ? AsyncPaginate : isAsync ? AsyncSelect : Select;
  // const SelectComponent = isCreatable ? CreatableSelect : Select;
  // if value prop is provided we should bind it
  // otherwise just make it as uncontrolled component
  const valueProps = initialValue === undefined ? { defaultValue: value } : { value };

  return (
    <div className={containerClass} {...{ 'data-test-id': props['data-test-id'] || name }}>
      {Icon && <Icon className={Styles.inputIcon} />}
      <CustomSelect
        {...(isPaginated ? { selectRef: selectRef } : { ref: selectRef })}
        className={fieldClass}
        styles={customStyles}
        name={name}
        placeholder={placeholder || label}
        onChange={handleChange}
        additional={additional}
        createOptionPosition='first'
        isDisabled={disabled}
        options={options}
        classNamePrefix={classNamePrefix}
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        components={components}
        debounceTimeout={500}
        {...valueProps}
        {...props}
        {...(isMultiLevel && {
          components: { ...components, Option },
        })}
      />
      <label htmlFor={name} className={labelClass}>
        {error || label}
      </label>
    </div>
  );
};

SelectInput.propTypes = {
  classNamePrefix: PropTypes.string,
  'data-test-id': PropTypes.string,
  isMultiLevel: PropTypes.bool,
  getOptionChildren: PropTypes.func,
};

SelectInput.defaultProps = {
  menuPlacement: 'auto',
  classNamePrefix: 's-contact',
  isAsync: false,
  isPaginated: false,
};
const MemoSelectInput = memo(SelectInput);

export default MemoSelectInput;
