import { memo } from 'react';

import Button from '@hiredigital/ui/Button';

import IconExternal from '@hiredigital/ui/Icon/icons/external.inline.svg';

import { openUrl } from '@hiredigital/lib/helpers/utils';

import FormLayout from '../StagesLayout/FormLayout';
import SegmentedControl from '../SegmentedControl/SegmentedControl';
import Note from '../Note/Note';
import ResumeOption from './ResumeOption';
import { profileOptionsNotes } from './data';
import Styles from './Profile.module.scss';

const ResumeSettings = ({ isOnboarding, requestHidden, onChange }) => {
  const handleOpenProfile = () => openUrl(`${process.env.MARKETING_URL}/resume-preview`);

  return (
    <FormLayout.Row>
      <FormLayout.MainColumn>
        <FormLayout.LayoutHeader
          title={() => (
            <div className={Styles.header}>
              <span className={Styles.title}>{`Profile Options`}</span>
              <Button type={Button.Type.LIGHT_GRAY} onClick={handleOpenProfile}>
                {`View Profile`}
                <IconExternal className={Styles.iconLink} />
              </Button>
            </div>
          )}
        />
        <Note>
          <p style={{ marginBottom: '10px' }}>{profileOptionsNotes[0]}</p>
          <p>{profileOptionsNotes[1]}</p>
        </Note>

        <ResumeOption {...{ requestHidden, onChange }} />
      </FormLayout.MainColumn>
      {isOnboarding && <FormLayout.AsideColumn noBorder />}
    </FormLayout.Row>
  );
};

export default memo(ResumeSettings);
