import { Fragment, useState, useEffect } from 'react';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { useParams } from 'react-router-dom';
import Layout from '@components/Layout/AppLayout';
import PointContact from '@components/PointContact/PointContact';
import Agreement from '@components/Agreement/Agreement';
import Section from '@components/StagesLayout/Section';
import ContactPreferences from '@components/ContactPreferences/ContactPreferences';
import { setOrgUuid } from '@context/org';

import { getOrganization } from '@apis/organizations';
import Styles from '../Styles.module.scss';

const OrgSettings = () => {
  const params = useParams();
  const [org, setOrg] = useState(undefined);
  const { uuid } = params;
  useEffect(() => {
    setPageTitle('Client Settings - Hire Digital');
  }, []);
  useEffect(() => {
    const getData = async () => {
      const res = await getOrganization(uuid);
      setOrg(res.data);
    };
    if (uuid) {
      setOrgUuid(uuid);
      getData();
    }
  }, [uuid]);

  return (
    <Layout style={{ background: 'white' }}>
      <Section
        title={`Client Settings`}
        subtitle={`Update your company details and agreements.`}
        className={Styles.container}>
        {org && (
          <Fragment>
            <PointContact resourceUuid={uuid} data={org.contact} />
            <ContactPreferences orgUuid={uuid} data={org} />
            <Agreement resourceUuid={uuid} data={org} />
          </Fragment>
        )}
      </Section>
    </Layout>
  );
};

export default OrgSettings;
