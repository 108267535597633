import Styles from './Checkbox.module.scss';
import classNames from 'classnames';

const Checkbox = ({ label, onChange, labelClass, ...props }) => {
  const handleChange = (e) => {
    e.persist();
    onChange?.(e);
  };

  return (
    <label className={Styles.container}>
      <input type={`checkbox`} {...props} onChange={handleChange} />
      <span className={classNames(Styles.text, labelClass)}>{label}</span>
      <span className={Styles.checkmark} />
    </label>
  );
};

export default Checkbox;
