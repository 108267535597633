import { authRequest } from './utils';

const serializePortfolioOrder = (data) => {
  return {
    ...data,
  };
};

const serializePortfolioItem = (data) => {
  if (data instanceof FormData) return data;
  return {
    ...data,
    skills: data?.skills?.map((obj) => obj.id),
    teams: data?.teams?.map((obj) => obj.uuid),
    publication: data?.publication?.id,
  };
};

export const getUserPortfolioItemByUuid = async (uuid, config = undefined) => {
  const url = `portfolio-items/${uuid}/`;
  return await authRequest.get(url, config);
};

export const getUserPortfolioItems = async (uuid, config = undefined) => {
  const url = `users/${uuid}/items/`;
  return await authRequest.get(url, config);
};

export const getTeamPortfolioItems = async (uuid, config = undefined) => {
  const url = `teams/${uuid}/items/`;
  return await authRequest.get(url, config);
};

export const getUserPortfolioItem = async (uuid, itemId, config = undefined) => {
  const url = `users/${uuid}/items/${itemId}/`;
  return await authRequest.get(url, config);
};

export const getTeamPortfolioItem = async (uuid, itemId, config = undefined) => {
  const url = `teams/${uuid}/items/${itemId}/`;
  return await authRequest.get(url, config);
};

export const postUserPortfolioItem = async (
  uuid,
  data,
  serialize = serializePortfolioItem,
  config = undefined
) => {
  const url = `users/${uuid}/items/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postTeamPortfolioItem = async (
  uuid,
  data,
  serialize = serializePortfolioItem,
  config = undefined
) => {
  const url = `teams/${uuid}/items/`;
  return await authRequest.post(url, serialize(data), config);
};

export const patchUserPortfolioItem = async (
  uuid,
  itemId,
  data,
  serialize = serializePortfolioItem,
  config = undefined
) => {
  const url = `users/${uuid}/items/${itemId}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const patchTeamPortfolioItem = async (
  uuid,
  itemId,
  data,
  serialize = serializePortfolioItem,
  config = undefined
) => {
  const url = `teams/${uuid}/items/${itemId}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const deleteUserPortfolioItem = async (uuid, itemId, config = undefined) => {
  const url = `users/${uuid}/items/${itemId}/`;
  return await authRequest.delete(url, config);
};

export const deleteTeamPortfolioItem = async (uuid, itemId, config = undefined) => {
  const url = `teams/${uuid}/items/${itemId}/`;
  return await authRequest.delete(url, config);
};

export const patchUserPortfolioCaseItem = async (
  uuid,
  itemId,
  caseId,
  data,
  config = undefined
) => {
  const url = `users/${uuid}/items/${itemId}/case-items/${caseId}/`;
  return await authRequest.patch(url, data, config);
};

export const patchTeamPortfolioCaseItem = async (
  uuid,
  itemId,
  caseId,
  data,
  config = undefined
) => {
  const url = `teams/${uuid}/items/${itemId}/case-items/${caseId}/`;
  return await authRequest.patch(url, data, config);
};

export const postUserPortfolioCaseItem = async (uuid, itemId, data, config = undefined) => {
  const url = `users/${uuid}/items/${itemId}/case-items/`;
  return await authRequest.post(url, data, config);
};

export const postTeamPortfolioCaseItem = async (uuid, itemId, data, config = undefined) => {
  const url = `teams/${uuid}/items/${itemId}/case-items/`;
  return await authRequest.post(url, data, config);
};

export const deleteUserPortfolioCaseItem = async (uuid, itemId, caseId, config = undefined) => {
  const url = `users/${uuid}/items/${itemId}/case-items/${caseId}/`;
  return await authRequest.delete(url, config);
};

export const deleteTeamPortfolioCaseItem = async (uuid, itemId, caseId, config = undefined) => {
  const url = `teams/${uuid}/items/${itemId}/case-items/${caseId}/`;
  return await authRequest.delete(url, config);
};

export const postUserPortfolioOrder = async (
  uuid,
  data,
  serialize = serializePortfolioOrder,
  config = undefined
) => {
  const url = `users/${uuid}/items/move-all/`;
  return await authRequest.post(url, serialize(data), config);
};

export const postTeamPortfolioOrder = async (
  uuid,
  data,
  serialize = serializePortfolioOrder,
  config = undefined
) => {
  const url = `teams/${uuid}/items/move-all/`;
  return await authRequest.post(url, serialize(data), config);
};

export const getTalentPortfolioItemByUuid = async (uuid, config = undefined) => {
  const url = `portfolio-items/${uuid}/`;
  return await authRequest.get(url, config);
};
