import { createContext, useState, useContext } from 'react';
import { getChats, getOrgChats } from '../apis/chats';
const DiscussionsListContext = createContext();

const DiscussionsListProvider = ({ children }) => {
  const [discussions, setDiscussions] = useState([]);
  const [meta, setMeta] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const loadChats = async (params, reset = false, org = null) => {
    setIsLoading(true);
    const { data } = await (org ? getOrgChats(org, { params }) : getChats({ params }));
    setMeta(data?.meta);
    setIsLoading(false);

    if (reset) {
      setDiscussions(data?.results || []);
    } else {
      setDiscussions([...discussions, ...data?.results]);
    }
  };

  return (
    <DiscussionsListContext.Provider
      value={{
        loadChats,
        setDiscussions,
        discussions,
        meta,
        isLoading,
      }}>
      {children}
    </DiscussionsListContext.Provider>
  );
};

const useDiscussionsList = () => useContext(DiscussionsListContext);

export { DiscussionsListProvider, DiscussionsListContext, useDiscussionsList };
