import axios from 'axios';
import applyConverters from 'axios-case-converter';
import toast from 'react-hot-toast';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const token = cookies.get('token');

export const CancelToken = axios.CancelToken;

const API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT || process.env.API_ENDPOINT;

export const appRequest = applyConverters(
  axios.create({
    baseURL: process.env.NEXT_PUBLIC_SITE_API_ENDPOINT || `${process.env.MARKETING_ENDPOINT}/api/`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

export const request = applyConverters(
  axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

export const authRequest = applyConverters(
  axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  })
);

export const authReqNoIntercept = applyConverters(
  axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  })
);

export const baseAuthRequest = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
  },
});

export const ssrAuthRequest = (backendToken) => {
  return applyConverters(
    axios.create({
      baseURL: API_ENDPOINT,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${backendToken}`,
      },
      withCredentials: true,
    })
  );
};

export const toCamelCase = (str) => {
  return str.replace(/([:\-_]+(.))/g, (_, separator, letter, offset) => {
    return offset ? letter.toUpperCase() : letter;
  });
};

export const toCamelCaseDict = (dict) => {
  const isNull = (n) => {
    return n === null;
  };

  const isArray = (a) => {
    return Array.isArray(a);
  };

  const isObject = (o) => {
    return typeof o === 'object' && !isNull(o) && !isArray(o);
  };

  const ret = {};

  for (const prop in dict) {
    if (dict.hasOwnProperty(prop)) {
      ret[toCamelCase(prop)] = dict[prop];
      if (isObject(dict[prop])) {
        ret[toCamelCase(prop)] = toCamelCaseDict(dict[prop]);
      } else if (isArray(dict[prop])) {
        ret[toCamelCase(prop)] = ret[toCamelCase(prop)].map((i) => {
          return toCamelCaseDict(i);
        });
      }
    }
  }
  return ret;
};

export const checkStatus = async (response) => {
  const data = await response.clone().json();
  if (!response.ok) {
    return response
      .clone()
      .json()
      .then((err, resp) => {
        console.log(err);
        console.log(resp);
        console.log(response);
        Promise.reject(toCamelCaseDict(err));
      });
  }
  return toCamelCaseDict(data);
};

const toSentenceCase = (_string) => {
  return (
    _string.charAt(0).toUpperCase() +
    _string.slice(1).replace(/([:\-_]+(.))/g, function (_, separator, letter, offset) {
      return offset ? ' ' + letter.toUpperCase() : letter;
    })
  );
};

const generateErrorArr = (val, errors) => {
  let updatedVal = val;
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      if (typeof errors[key] == 'string' || typeof errors[key]?.[0] == 'string') {
        updatedVal.push(
          toSentenceCase(key === 'nonFieldErrors' ? '' : key) +
            (key === 'nonFieldErrors' ? '' : ': ') +
            errors[key]
        );
      } else {
        updatedVal = generateErrorArr(val, errors[key]);
      }
    }
  }

  return updatedVal;
};

const interceptorErrorHandler = (error, callback) => {
  const responseErrors = error?.response?.data;
  if (!responseErrors) return callback([error?.message || 'Unknown error.']);

  // field errors are stored in array { username: ["required"]}
  // global errors are stored as string { detail: "permission denied" }
  if (typeof responseErrors.detail == 'string') {
    return callback([responseErrors.detail]);
  } else if (responseErrors.length === 1) {
    return callback(responseErrors);
  }
  // If there are field errors
  const val = generateErrorArr([], responseErrors);
  if (Array.isArray(val) && val.length > 10) {
    // Rather than up the entire space with errors
    return callback(['There was an error with your request.']);
  }
  return callback(val);
};

[request, authRequest, baseAuthRequest].forEach((item) => {
  item.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (!axios.isCancel(error)) {
        interceptorErrorHandler(error, async (errList) => {
          errList.forEach((err) => {
            try {
              toast.error(err, { id: err });
            } catch (nestedError) {
              console.log('error', nestedError);
            }
          });
        });
      }
      return Promise.reject(error);
    }
  );
});

// configAxiosErrorInterceptor((error) => {
//   console.log('configAxiosErrorInterceptor called');
// });
