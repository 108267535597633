import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import { AgreementStatus } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { generateOrgAgreement } from '@apis/organizations';
import { generateTeamAgreement } from '@apis/teams';
import { getAgreementLink } from '@apis/agreements';
import { openUrl } from '@hiredigital/lib/helpers/utils';
import toast from 'react-hot-toast';

const Type = {
  ORGANIZATION: 0,
  TEAM: 1,
};

const Agreement = ({ resourceUuid, data, type = Type.ORGANIZATION }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [agreement, setAgreement] = useState(data?.agreement);

  const texts = ['clients', 'teams'];

  useEffect(() => {
    if (data?.agreement) {
      setAgreement(data.agreement);
    }
  }, [data]);

  const handleViewAgreement = async () => {
    setIsLoading(true);
    try {
      const callback = window.location.href;
      if (!agreement?.uuid) {
        const requests = [generateOrgAgreement, generateTeamAgreement];
        const { data: docusignUrl } = await requests[type](resourceUuid, { callback });

        if (!docusignUrl) {
          console.warning('No url found in newly generated agreement! ');
          return;
        }

        await openUrl(docusignUrl, { target: '_self', verifyUrl: true });
        return;
      }

      const pdfUrl = agreement?.attachment;
      if (pdfUrl) {
        await openUrl(pdfUrl, { verifyUrl: true });
        return;
      }

      const resources = ['orgs', 'teams'];
      const docusignUrl = await getAgreementLink(resources[type], resourceUuid, agreement.id, {
        callback,
      });
      await openUrl(docusignUrl, { target: '_self', verifyUrl: true });
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 404) {
        toast.error("Agreement couldn't be found!");
      } else {
        toast.error("Something's wrong! Please contact us to investigate.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header title={`Platform Agreement`} />
      <Card.Item>
        {agreement ? (
          <p>
            {type === Type.TEAM && (
              <Fragment>
                {`A platform agreement is required for all ${texts[type]} on Hire Digital.`}
              </Fragment>
            )}
            {[
              AgreementStatus.SENT.id,
              AgreementStatus.IN_PROCESS.id,
              AgreementStatus.CANCELLED.id,
            ].includes(agreement.status) && (
              <Fragment>
                {`The platform agreement is required for all ${texts[type]} on Hire Digital. `}
                {`Please take a moment to review the terms and sign the agreement.`}
              </Fragment>
            )}
            {agreement.status === AgreementStatus.SIGNED.id && (
              <Fragment>
                {type === Type.ORGANIZATION ? (
                  <Fragment>
                    {`Your platform agreement was signed on `}
                    {`${toReadableDate(agreement.dateReceived)}.`}
                  </Fragment>
                ) : (
                  <Fragment>{`You have already signed your platform agreement. `}</Fragment>
                )}
                {`You can view your copy here.`}
              </Fragment>
            )}
          </p>
        ) : (
          <Fragment>
            <p>
              {`The platform agreement is required for all ${texts[type]} on Hire Digital. `}
              {`Please take a moment to review the terms and sign the agreement.`}
            </p>
          </Fragment>
        )}
      </Card.Item>
      <Card.Footer right>
        <Button
          type={Button.Type.BLUE}
          onClick={handleViewAgreement}
          isLoading={isLoading}>{`View Agreement`}</Button>
      </Card.Footer>
    </Card>
  );
};

Agreement.propTypes = {
  data: PropTypes.object,
};

Agreement.Type = Type;

export default Agreement;
