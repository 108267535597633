import Styles from './Group.module.scss';

const Group = ({ title, children }) => {
  return (
    <div className={Styles.dicussionGroup}>
      <div className={Styles.groupHeader}>
        <div className={Styles.groupTitle}>{title}</div>
      </div>
      {children}
    </div>
  );
};

Group.propTypes = {};

export default Group;
