import classNames from 'classnames';

const containerStyle = {
  display: 'flex',
  width: '100%',
};

const nameStyle = {
  flexGrow: '1',
  marginRight: '7px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '55%',
};

const emailStyle = {
  marginLeft: 'auto',
  color: '#c3ced9',
  flexGrow: '1',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'right',
  marginRight: '15px',
  maxWidth: '45%',
};

const handleOptionLabel = ({ name, email }) => {
  return (
    <div style={containerStyle}>
      <div style={nameStyle}>{name}</div>
      {email && <div style={emailStyle}>{email}</div>}
    </div>
  );
};

const withEmailLabel = (BaseComponent) => {
  const WrappedComponent = ({ className, ...props }) => {
    return (
      <BaseComponent
        {...props}
        className={classNames(`select-email-label`, className)}
        getOptionLabel={handleOptionLabel}
      />
    );
  };
  return WrappedComponent;
};

export default withEmailLabel;
