import { useState, useEffect } from 'react';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { getUser } from '@apis/users';

import Layout from '@components/Layout/AppLayout';
import Section from '@components/StagesLayout/Section';
import Loader from '@hiredigital/ui/Loader';

import { useUser } from '@context/user';
import CompanyList from '@components/UserCompanies/List';
import Styles from './Styles.module.scss';

const CompanyPage = () => {
  const user = useUser();
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setPageTitle('Client Teams - Hire Digital');
  }, []);

  useEffect(() => {
    if (user?.uuid) {
      getUser(user?.uuid)
        .then((response) => {
          setData(response?.data);
          setIsLoaded(true);
        })
        .catch((error) => {
          console.warn(error);
          setIsLoaded(true);
        });
    }
  }, [user]);

  return (
    <Layout style={{ background: 'white' }}>
      <Section
        title={`Client Teams`}
        subtitle={`View the list of client teams your account is linked to.`}
        className={Styles.container}>
        {isLoaded ? <CompanyList userUuid={user?.uuid} /> : <Loader type={Loader.Color.BLUE} />}
      </Section>
    </Layout>
  );
};

export default CompanyPage;
