import classNames from 'classnames';
import Styles from './FileList.module.scss';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';
import IconLink from '@hiredigital/ui/Icon/icons/link.inline.svg';
import IconPicture from '@hiredigital/ui/Icon/icons/picture.inline.svg';

import SplitButton from '@hiredigital/ui/Button/SplitButton';

const Item = ({ title, description, url, isLink = true, className }) => {
  return (
    <div className={classNames(Styles.item, className)}>
      {isLink ? <IconLink className={Styles.icon} /> : <IconPicture className={Styles.icon} />}
      <div className={Styles.detail}>
        <a className={Styles.title} href={url} target={`_blank`}>
          {title || 'Untitled File'}
        </a>
        {description && <span className={Styles.subText}>{description}</span>}
      </div>
      <SplitButton
        more={[{ text: isLink ? `View` : `Download`, as: 'a', href: url, target: '_blank' }]}
      />
    </div>
  );
};

Item.propTypes = {};

export default Item;
