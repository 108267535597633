import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import ParticipantItem from './ParticipantItem';
import { useUser } from '@context/user';
import CallGuest from '@hiredigital/ui/CallGuest';

const requestAvailabilityOptions = [
  {
    label: `Request Availability`,
    id: 1,
  },
  {
    label: `Don't Request Availability`,
    id: 3,
  },
];

const ParticipantList = ({
  participants,
  conference,
  onParticipantsChange,
  onRemoveConferenceUser,
  onAddConferenceGuest,
  onRemoveConferenceGuest,
  callType,
  isReschedule,
}) => {
  const user = useUser();

  const users = conference?.users.map(({ user }) => user).flat();
  const [guests, setGuests] = useState(conference?.users.filter((ele) => ele?.email != null));
  // const [guestList,setGuestList] = useState([])
  const conferenceUsers = [...(conference?.users || [])]?.reverse();
  const [isAdding, setIsAdding] = useState(false);
  const [busyRemoving, setBusyRemoving] = useState([]);
  const [addedItems, setAddedItems] = useState([]);
  const [notAddedItems, setNotAddedItems] = useState(
    participants.filter(({ uuid }) => !users?.some((v) => v?.email == null && v?.uuid === uuid)) ||
      []
  );

  useEffect(() => {
    setGuests(
      conference?.users.filter((ele) => {
        if (ele?.email !== null) return ele;
      })
    );
  }, [conference]);

  const handleAdd = (user) => {
    const addedParticipants = [...addedItems, user];
    setAddedItems(addedParticipants);
    setNotAddedItems(notAddedItems.filter((v) => v?.uuid !== user?.uuid));
    onParticipantsChange?.(addedParticipants);
  };

  const handleRemove = (user) => {
    const addedParticipants = addedItems.filter((v) => v?.uuid !== user?.uuid);
    setNotAddedItems([...notAddedItems, user]);
    setAddedItems(addedParticipants);
    onParticipantsChange?.(addedParticipants);
  };

  const handleRequestAvailability = (value, user) => {
    const addedParticipants = addedItems.map((v) => {
      if (user?.uuid === v?.uuid) {
        v.status = value?.id;
        return v;
      } else {
        v.status = value?.id;
        return v;
      }
    });

    onParticipantsChange?.(addedParticipants);
  };

  const handleRemoveConferenceUser = (conferenceUser) => {
    setNotAddedItems([...notAddedItems, conferenceUser?.user]);
    onRemoveConferenceUser?.(conferenceUser);
  };
  const handleAddGuest = async (items) => {
    setIsAdding(true);
    const newGuest = items.pop();
    onAddConferenceGuest?.(newGuest);
    setIsAdding(false);
  };

  const handleRemoveGuest = async (idx, items, removedGuest = null) => {
    setIsAdding(true);
    if (removedGuest === null) {
      const removedGuestUser = guests.at(idx);
      onRemoveConferenceGuest?.(removedGuestUser);
    } else {
      onRemoveConferenceGuest?.(removedGuest);
    }
    setIsAdding(false);
  };
  return (
    <div className={Styles.participantList}>
      {((conferenceUsers?.length || addedItems?.length) && (
        <div className={Styles.participantCategory}>{`Added`}</div>
      )) ||
        ''}
      {conferenceUsers.map((v) => (
        <ParticipantItem
          key={v?.uuid}
          email={v?.email}
          user={v?.user}
          type={ParticipantItem.Type.ADDED}
          showAction={v?.user?.uuid !== user?.uuid}
          onRemove={() => handleRemoveConferenceUser(v)}
          handleRemoveGuest={() => handleRemoveGuest(0, [], v)}
          callType={callType}
          isReschedule={isReschedule}
        />
      ))}
      {addedItems.map(
        (v) =>
          v?.email == null && (
            <ParticipantItem
              key={v?.uuid}
              email={v?.email}
              user={v}
              type={ParticipantItem.Type.ADDED}
              showAction={v?.uuid !== user?.uuid}
              onRemove={handleRemove}
              onRequestAvailabilityChange={handleRequestAvailability}
              callType={callType}
              isReschedule={isReschedule}
            />
          )
      )}
      {!!notAddedItems?.length && <div className={Styles.participantCategory}>{`Not Added`}</div>}
      {notAddedItems.map(
        (v) => v?.email == null && <ParticipantItem key={v?.uuid} user={v} onAdd={handleAdd} />
      )}
      <CallGuest
        onAdd={handleAddGuest}
        titleClassName={Styles.participantCategory}
        onRemove={handleRemoveGuest}
        isAdding={isAdding}
        busyIndexes={busyRemoving}
        data={guests?.map((ele) => {
          return ele?.email;
        })}
        showGuests={false}
      />
    </div>
  );
};

ParticipantList.propTypes = {
  children: PropTypes.node,
};

export default ParticipantList;
