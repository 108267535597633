import { authRequest } from './utils';

const serializerEngagement = (data) => {
  const serializedData = {
    ...data,
    countries: data?.countries?.length === 0 ? [] : data?.countries?.map((obj) => obj?.id),
    languages: data?.languages?.length === 0 ? [] : data?.languages?.map((obj) => obj?.id),
    skills: data?.skills?.length === 0 ? [] : data?.skills?.map((obj) => obj?.id),
  };
  return serializedData;
};

export const putEngagement = async (
  uuid,
  data,
  serialize = serializerEngagement,
  config = undefined
) => {
  const url = `engagements/${uuid}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const deleteEngagement = async (uuid, config = undefined) => {
  const url = `engagements/${uuid}/`;
  return await authRequest.delete(url, config);
};

export const postAcceptEngagementApplication = async (
  uuid,
  applicationUuid,
  data,
  config = undefined
) => {
  const url = `engagements/${uuid}/applications/${applicationUuid}/accept/`;
  return await authRequest.post(url, data, config);
};

export const postEngagementApplicationInterview = async (
  uuid,
  applicationUuid,
  data,
  config = undefined
) => {
  const url = `engagements/${uuid}/applications/${applicationUuid}/interview/`;
  return await authRequest.post(url, data, config);
};

export const postEngagementApplicationConference = async (
  uuid,
  applicationUuid,
  data,
  config = undefined
) => {
  const url = `engagements/${uuid}/applications/${applicationUuid}/conference/`;
  return await authRequest.post(url, data, config);
};

export const getEngagement = async (uuid, config = undefined) => {
  const url = `engagements/${uuid}/`;
  return await authRequest.get(url, config);
};

export const getEngagementApplication = async (uuid, applicationUuid, config = undefined) => {
  const url = `engagements/${uuid}/applications/${applicationUuid}/`;
  return await authRequest.get(url, config);
};

export const patchEngagementApplication = async (
  uuid,
  applicationUuid,
  data,
  config = undefined
) => {
  const url = `engagements/${uuid}/applications/${applicationUuid}/`;
  return await authRequest.patch(url, data, config);
};

export const postEngagementApplication = async (uuid, data, config = undefined) => {
  const url = `engagements/${uuid}/applications/`;
  return await authRequest.post(url, data, config);
};

export const postEngagementApplicationSubmit = async (
  uuid,
  applicationUuid,
  config = undefined
) => {
  const url = `engagements/${uuid}/applications/${applicationUuid}/submit/`;
  return await authRequest.post(url, {}, config);
};

export const postEngagementApplicationLink = async (
  uuid,
  applicationUuid,
  data,
  config = undefined
) => {
  const url = `engagements/${uuid}/applications/${applicationUuid}/links/`;
  return await authRequest.post(url, data, config);
};

export const postEngagementApplicationFile = async (
  uuid,
  applicationUuid,
  data,
  config = undefined
) => {
  const url = `engagements/${uuid}/applications/${applicationUuid}/attachments/`;
  return await authRequest.post(url, data, config);
};

export const deleteEngagementApplicationLink = async (
  uuid,
  applicationUuid,
  linkId,
  config = undefined
) => {
  const url = `engagements/${uuid}/applications/${applicationUuid}/links/${linkId}/`;
  return await authRequest.delete(url, config);
};

export const deleteEngagementApplicationFile = async (
  uuid,
  applicationUuid,
  fileId,
  config = undefined
) => {
  const url = `engagements/${uuid}/applications/${applicationUuid}/attachments/${fileId}/`;
  return await authRequest.delete(url, config);
};
