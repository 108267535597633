import { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { getCurrency } from '@apis/common';
import { Currency } from '@hiredigital/lib/helpers/enum';
import Layout from '@components/Layout/AppLayout';
import InvoiceList from '@components/Invoices/List';
import CreditList from '@components/Credits/List';
import Section from '@components/StagesLayout/Section';
import CompanyCreditCardList from '@components/CompanyPayments/CreditCardList';
import CompanyBillingDetails from '@components/CompanyBillingDetails/CompanyBillingDetails';
import SecurityDeposit from '@components/CompanyPayments/SecurityDeposit';

import { setOrgUuid } from '@context/org';
import { getOrganization } from '@apis/organizations';
import Styles from '../Styles.module.scss';

const SettingIndexPage = () => {
  const params = useParams();
  const [org, setOrg] = useState(undefined);
  const { uuid } = params;
  const [currency, setCurrency] = useState(null);
  const [isDepositRequired, setIsDepositRequired] = useState(false);
  const [isPaid, setIsPaid] = useState(false);

  useEffect(() => {
    setPageTitle('Client Billing Settings - Hire Digital');
  }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await getOrganization(uuid);
      setOrg(res.data);
      loadCurrency(res.data.payment?.currency);
    };

    const loadCurrency = async (code) => {
      const { data } = await getCurrency(code || 'SGD');
      setCurrency({ ...data, icon: Currency[data.currency].icon });
    };

    if (uuid) {
      setOrgUuid(uuid);
      getData();
    }
  }, [uuid]);

  useEffect(() => {
    setIsDepositRequired(org?.depositRequired);
    setIsPaid(org?.depositPaid);
  }, [org]);

  return (
    <Layout style={{ background: 'white' }}>
      <Section
        title={`Client Billing Settings`}
        subtitle={`Update and view your payment methods, billing details, and invoices.`}
        className={Styles.container}>
        {org && (
          <Fragment>
            <CompanyBillingDetails organization={org} />
            {isDepositRequired && !isPaid && (
              <SecurityDeposit
                onDepositSuccess={() => setIsPaid(true)}
                cardContainer={Styles.depositContainer}
              />
            )}

            <CompanyCreditCardList />

            {currency && (
              <CreditList
                orgUuid={org.uuid}
                stripeCurrency={org?.payment?.stripeCurrency}
                currency={currency}
              />
            )}
            <InvoiceList resource={'orgs'} resourceUuid={org.uuid} />
          </Fragment>
        )}
      </Section>
    </Layout>
  );
};

export default SettingIndexPage;
