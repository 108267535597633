import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import ShowAsIframe from '../ShowAsIframe/ShowAsIframe';
import ImageViewer from '../ImageViewer/ImageViewer';
import { checkImageUrl } from '@hiredigital/lib/helpers/utils';
import Styles from './FileViewer.module.scss';

class FileViewer extends Component {
  static propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    className: PropTypes.string,
    force: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.imageViewerModalRef = createRef();
    this.state = {
      title: '',
      url: '',
      isPdf: false,
      isImage: false,
      force: false,
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        title: this.props.title,
        url: this.props.url,
        force: this.props.force,
      },
      () => {
        if (this.state.force) {
          this.setState({
            isImage: true,
          });
        } else {
          this.renderUrl(this.state.url);
        }
      }
    );
  };

  renderUrl = (url) => {
    const isImage = checkImageUrl(url);
    this.setState({
      isImage: isImage,
    });
  };

  handleOpenImageViewer = () => {
    this.imageViewerModalRef.current.show(this.state.url, this.state.title);
  };

  closeopenImageViewer = () => {
    this.imageViewerModalRef.current.close();
  };

  render() {
    return (
      <div className={this.props.className}>
        <ImageViewer ref={this.imageViewerModalRef} />

        {this.state.isImage && this.state.url && (
          <div className={Styles.imageContainer}>
            <img
              src={this.state.url}
              className={Styles.image}
              onClick={this.handleOpenImageViewer}
              alt={this.state.url}
            />
          </div>
        )}

        {!this.state.isImage && this.state.url && (
          <ShowAsIframe className={Styles.docContainer} url={this.state.url} />
        )}
      </div>
    );
  }
}

export default FileViewer;
