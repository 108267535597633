import { memo } from 'react';

import Text from './Types/Text';
import Activity from './Types/Activity';
import Upload from './Types/Upload';
import Content from './Types/Content';
import Conference from './Types/Conference';
import NestedDiscussion from './Types/NestedDiscussion';
import { MessageType } from '@hiredigital/lib/helpers/enum';
import MessageLayout from './components/MessageLayout';

const BodyContent = ({ message, discussion }) => {
  switch (message?.type) {
    case MessageType.TEXT.id:
    case MessageType.CONTENT_ANNOTATION.id:
      return <Text message={message} />;
    case MessageType.UPLOAD.id:
      return <Upload message={message} />;
    case MessageType.ACTION.id:
      return <div>ACTION</div>;
    case MessageType.ACTIVITY.id:
      return message.metaObject ? (
        <Conference {...{ message, discussion }} />
      ) : (
        <Activity message={message} />
      );
    case MessageType.CONTENT.id:
      return <Content message={message} />;
    case MessageType.NESTED_DISCUSSION.id:
      return <NestedDiscussion message={message} />;
    default:
      return null;
  }
};

const MessageBody = ({ message, discussion, showDetails = true, timeClass }) => {
  return (
    <MessageLayout
      sender={message?.sender}
      timeClass={timeClass}
      created={message?.created}
      showDetails={showDetails}>
      <BodyContent message={message} discussion={discussion} />
    </MessageLayout>
  );
};

export default memo(MessageBody);
