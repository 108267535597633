import BriefDetail from '../Brief/BriefDetail';
import classNames from 'classnames';
import Styles from './BriefArea.module.scss';

const BriefArea = ({ project, brand, className, ...props }) => {
  return (
    <div className={classNames(Styles.container, className)} {...props}>
      <BriefDetail project={project} brand={brand} />
    </div>
  );
};

export default BriefArea;
