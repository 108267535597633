export class RatesApiManager {
  constructor(request) {
    this.request = request;
  }

  // (Admin) Talent Rates

  getAdminTalentRates = async (resource, resourceUuid, config = undefined) => {
    const url = `admin/${resource}/${resourceUuid}/talent-rates/`;
    return await this.request.get(url, config);
  };

  postAdminTalentRate = async (resource, resourceUuid, data, config = undefined) => {
    const url = `admin/${resource}/${resourceUuid}/talent-rates/`;
    return await this.request.post(url, data, config);
  };

  putAdminTalentRate = async (
    resource,
    resourceUuid,
    serviceRateUuid,
    data,
    config = undefined
  ) => {
    const url = `admin/${resource}/${resourceUuid}/talent-rates/${serviceRateUuid}/`;
    return await this.request.put(url, data, config);
  };

  deleteAdminTalentRate = async (resource, resourceUuid, serviceRateUuid, config = undefined) => {
    const url = `admin/${resource}/${resourceUuid}/talent-rates/${serviceRateUuid}/`;
    return await this.request.delete(url, config);
  };

  // (Frontend) Talent Rates

  getTalentRates = async (config = undefined) => {
    const url = `talent-rates/`;
    return await this.request.get(url, config);
  };

  postTalentRate = async (data, serialize = serializerTalentRate, config = undefined) => {
    const url = `talent-rates/`;
    return await this.request.post(url, serialize(data), config);
  };

  putTalentRate = async (uuid, data, serialize = serializerTalentRate, config = undefined) => {
    const url = `talent-rates/${uuid}/`;
    return await this.request.put(url, serialize(data), config);
  };

  deleteTalentRate = async (uuid, config = undefined) => {
    const url = `talent-rates/${uuid}/`;
    return await this.request.delete(url, config);
  };

  // (Frontend) Team Talent Rates

  getTeamTalentRates = async (uuid, config = undefined) => {
    const url = `teams/${uuid}/talent-rates/`;
    return await this.request.get(url, config);
  };

  postTeamTalentRate = async (uuid, data, config = undefined) => {
    const url = `teams/${uuid}/talent-rates/`;
    return await this.request.post(url, data, config);
  };

  putTeamTalentRate = async (uuid, rateUuid, data, config = undefined) => {
    const url = `teams/${uuid}/talent-rates/${rateUuid}/`;
    return await this.request.put(url, data, config);
  };

  deleteTeamTalentRate = async (uuid, rateUuid, config = undefined) => {
    const url = `teams/${uuid}/talent-rates/${rateUuid}/`;
    return await this.request.delete(url, config);
  };

  // FIXME: Move the skills API later to its own API Manager.

  // (Admin) Skills

  postAdminSkill = async (data, config = undefined) => {
    const url = `admin/skills/`;
    return await this.request.post(url, data, config);
  };

  onAdminSkillsLoadOptions = (search, loadedOptions, { page }) => {
    const data = { params: { search, page, limit: 20 } };
    const url = `admin/skills/`;
    return loadOptions(this.request, url, data);
  };

  // (Frontend) Skills

  onSkillsLoadOptions = (search, loadedOptions, { page }) => {
    const data = { params: { search, page, limit: 20 } };
    const url = `skills/`;
    return loadOptions(this.request, url, data);
  };
}

const serializerTalentRate = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

const loadOptions = async (request, url, data, filter) => {
  const {
    data: { results, meta },
  } = await request.get(url, data);
  return {
    options: filter ? results.filter(filter) : results,
    hasMore: meta.nextPage !== null,
    additional: {
      page: meta.nextPage,
    },
  };
};
