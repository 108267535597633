/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@hiredigital/ui/Button';

// import { Dialog } from 'evergreen-ui/esm/dialog';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Combobox from 'evergreen-ui/esm/combobox/src/Combobox';
import Select from '@hiredigital/ui/Input/Select';

import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import ScheduleList from './ScheduleList';
import ParticipantList from './ParticipantList';

import { useCommon } from '@context/common';
import { postCallDaysAvailable } from '@apis/calls';
import {
  postConferenceTime,
  postConferenceUser,
  putConferenceUser,
  postConferenceSubmit,
  postConferenceReschedule,
  deleteConferenceUser,
  deleteConferenceTime,
  getConference,
} from '@apis/conferences';
import { ConferenceStatus, ConferenceUserStatus } from '@hiredigital/lib/helpers/enum';
import { useUser } from '@context/user';
import OverlayLoader from './OverlayLoader';
import Styles from './Styles.module.scss';
import toast from 'react-hot-toast';

const Type = {
  NEW_SCHEDULE: 1,
  RESCHEDULE: 2,
};

const ConferenceSchedule = ({
  isShown,
  conference,
  discussion,
  onCancel,
  onClose,
  onCloseComplete,
  onSubmit,
  type = Type.NEW_SCHEDULE,
  isReschedule = false,
  stage = 1,
  allowManageParticipants = true,
  confirmScheduleLabel,
}) => {
  const common = useCommon();
  const user = useUser();
  // const { loadMessages } = useDiscussion();

  const [timezone, setTimezone] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState(conference?.times || []);
  const [addedParticipants, setAddedParticipants] = useState([]);
  const [removeParticipants, setRemoveParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStage, setCurrentStage] = useState(stage);
  const [currentConference, setCurrentConference] = useState(conference);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [deletedTimeIds, setDeletedTimeIds] = useState([]);

  useEffect(() => {
    setCurrentConference(conference);
  }, [conference]);

  useEffect(() => {
    setSelectedTimes(currentConference?.times);
  }, [currentConference?.times]);

  useEffect(() => {
    if (isShown) {
      setShouldFetch(true);
    }
  }, [isShown]);

  useEffect(() => {
    if (!shouldFetch) return;
    if (!common?.isReady) return;
    const tz = common?.timezones.filter((time) => time.name === common?.timezone.label);

    // const tz = common?.timezones?.[SG_TIMEZONE_IDX]
    if (!tz) {
      console.warn('Timezones not yet available!');
      return;
    }

    setTimezone(...tz);
    loadAvailableTimes(tz);
  }, [common, shouldFetch]);

  useEffect(() => {
    setCurrentStage(stage);
  }, [stage]);

  useEffect(() => {
    console.log(timezone);
  }, [timezone]);

  const loadAvailableTimes = (tz) => {
    setIsLoading(true);
    postCallDaysAvailable({
      timezone: tz?.label,
      skip: 'True',
      call_id: currentConference?.call?.id,
    })
      .then(({ data }) => {
        setIsLoading(false);
        const times = data.results
          ?.map((v) => {
            return v.available;
          })
          .flat();
        setAvailableTimes(times);
        setShouldFetch(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setShouldFetch(false);
        console.log(error);
      });
  };

  const handleConfirmSchedule = () => {
    // if managing participants is disabled, submit the conference without going to stage 2 to manage the participants
    if (!allowManageParticipants) {
      handleSubmitConference();
      return;
    }

    if (validateSchedule()) {
      setCurrentStage(2);
    }
  };

  const validateSchedule = () => {
    if (!selectedTimes || selectedTimes.length === 0) {
      toast.error('Please select schedule.', { id: 'invalidSchedule' });
      return false;
    }

    return true;
  };

  const validateParticipants = () => {
    // if managing participants is disabled, no need to check if there are new participants that were added
    if (!allowManageParticipants) {
      return true;
    }

    // if current mode is for rescheduling, no need for new participants we just need to make sure that there are 2 or more existing participants
    if (type === Type.RESCHEDULE && currentConference?.users?.length > 1) {
      return true;
    }

    if (addedParticipants < 2) {
      toast.error('Please select participant.', { id: 'missingParticipants' });
      return false;
    }

    return true;
  };

  const handleSubmitConference = async () => {
    if (!validateParticipants()) return;
    if (!validateSchedule()) return;

    setIsLoading(true);
    const conferenceUuid = currentConference?.uuid;

    removeParticipants.map((item) => {
      deleteConferenceUser(conferenceUuid, item?.uuid)
        .then(({ data }) => {
          // const users = currentConference.users.filter((v) => v?.uuid !== item?.uuid);
          // setCurrentConference({ ...currentConference, users });
        })
        .catch((error) => {
          console.error('Error has occured! ', error);
        });
    });
    Promise.all([
      // ...deleteConferenceParticipants(),
      ...getConferenceUserPromises(),
      ...getParticipantPromises(),
      ...getSeletedTimePromises(),
      ...getDeletedTimePromises(),
    ])
      .then(() => {
        const conferenceUuid = currentConference?.uuid;
        const request =
          type === Type.NEW_SCHEDULE ? postConferenceSubmit : postConferenceReschedule;
        if (!isReschedule && type === Type.NEW_SCHEDULE) {
          request(conferenceUuid).then(({ data }) => {
            setIsLoading(false);
            // loadMessages(discussion?.id, null, true);
            setCurrentConference({
              ...currentConference,
              times:
                currentConference?.times?.filter((v) => !deletedTimeIds?.includes(v?.id)) || [],
            });
            setDeletedTimeIds([]);
            setShouldFetch(false);
            onSubmit?.(data);
          });
        } else if (isReschedule && type === Type.RESCHEDULE) {
          request(conferenceUuid).then(({ data }) => {
            setIsLoading(false);
            // loadMessages(discussion?.id, null, true);
            setCurrentConference({
              ...currentConference,
              times:
                currentConference?.times?.filter((v) => !deletedTimeIds?.includes(v?.id)) || [],
            });
            setDeletedTimeIds([]);
            setShouldFetch(false);
            onSubmit?.(data);
          });
        } else {
          getConference(conferenceUuid).then(({ data }) => {
            setIsLoading(false);
            // loadMessages(discussion?.id, null, true);
            setCurrentConference({
              ...currentConference,
              times:
                currentConference?.times?.filter((v) => !deletedTimeIds?.includes(v?.id)) || [],
            });
            setDeletedTimeIds([]);
            setShouldFetch(false);
            onSubmit?.(data);
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getConferenceUserPromises = () => {
    const conferenceUuid = currentConference?.uuid;
    return currentConference?.users?.map((v) => {
      const conferenceUserUuid = v?.uuid;
      const status =
        type === Type.RESCHEDULE && v?.user?.uuid !== user?.uuid
          ? ConferenceStatus.PENDING.id
          : v.status;
      return putConferenceUser(conferenceUuid, conferenceUserUuid, {
        status,
        user: v?.user,
        uuid: conferenceUserUuid,
      });
    });
  };

  const conferenceUsers = currentConference?.users?.map(({ user }) => user).flat();

  const getParticipantPromises = () => {
    const conferenceUuid = currentConference?.uuid;

    if (!addedParticipants || addedParticipants.length === 0) {
      return [];
    }

    const newParticipants = addedParticipants.filter(
      (v) => !conferenceUsers.some((d) => d?.uuid === v?.uuid)
    ); // return only those users that were not yet added before

    if (!newParticipants || newParticipants.length === 0) {
      return [];
    }

    return newParticipants.map((v) =>
      postConferenceUser(conferenceUuid, { user: v, status: v.status })
    );
  };

  const getSeletedTimePromises = () => {
    const conferenceUuid = currentConference?.uuid;
    if (!selectedTimes || selectedTimes.length === 0) {
      return [];
    }

    const newConferenceTimes = selectedTimes.filter((v) => !v?.id); // return times without id yet, these means that these are not yet added

    if (!newConferenceTimes || newConferenceTimes.length === 0) {
      return [];
    }

    return newConferenceTimes.map((v) =>
      postConferenceTime(conferenceUuid, {
        startTime: v?.startTime || v?.start,
        endTime: v?.endTime || v?.end,
      })
    );
  };

  const getDeletedTimePromises = () => {
    const conferenceUuid = currentConference?.uuid;

    if (!deletedTimeIds || deletedTimeIds.length === 0) {
      return [];
    }

    const deleted = deletedTimeIds.filter((v) => !v?.id); // return times that were not selected, id may have been added to deleted items but has been re-selected after

    if (!deleted || deleted.length === 0) {
      return [];
    }

    return deleted.map((timeId) => deleteConferenceTime(conferenceUuid, timeId));
  };

  const handleParticipantsChange = (participants) => setAddedParticipants(participants);

  const handleSelectedTimeChange = (times) => setSelectedTimes(times);

  const handleRemoveConferenceUser = (item) => {
    const removeConferenceUser = [...removeParticipants, item];
    setRemoveParticipants(removeConferenceUser);
    const users = currentConference.users.filter((v) => v?.uuid !== item?.uuid);
    setCurrentConference({ ...currentConference, users });
  };

  const handleCloseComplete = () => {
    reset();
    onCloseComplete?.();
  };

  const reset = () => {
    setSelectedTimes([]);
    setAddedParticipants([]);
    setCurrentStage(stage);
  };

  const handleGuestDelete = (guest) => {
    deleteConferenceUser(currentConference?.uuid, guest?.uuid)
      .then(({ data }) => {
        const users = currentConference.users.filter((v) => v?.uuid !== guest?.uuid);
        setCurrentConference({ ...currentConference, users });
      })
      .catch((error) => {
        console.error('Error has occured while removing guest! ', error);
      });
  };

  const handleGuestAdd = (guest) => {
    postConferenceUser(currentConference?.uuid, {
      email: guest,
      status: ConferenceUserStatus.CONFIRMED.id,
    })
      .then((response) => {
        const users = [...currentConference.users, response.data];
        setCurrentConference({ ...currentConference, users });
      })
      .catch((err) => {
        console.error('Error has occured while Adding guest! ', err);
      });
  };

  const handleDeSelectTime = (item) => {
    if (!item?.id) {
      return;
    }

    if (deletedTimeIds?.includes(item.id)) {
      return;
    }

    setDeletedTimeIds([...deletedTimeIds, item.id]);
    setSelectedTimes(selectedTimes.filter((t) => t?.id !== item.id));
  };

  const handleTimezoneChange = (event) => {
    const tz = event.target.value;
    setTimezone(tz);
    setIsLoading(true);
    postCallDaysAvailable({ timezone: tz.label || tz.name })
      .then(({ data }) => {
        const times = data.results
          ?.map((v) => {
            return v.available;
          })
          .flat();
        setAvailableTimes(times);
        setShouldFetch(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Dialog
      bodyClass={Styles.conferenceDialog}
      isOpen={isShown}
      onClose={onClose}
      onCloseComplete={handleCloseComplete}
      width={700}
      padding={0}>
      <div>
        {currentStage === 1 ? (
          <Fragment>
            <Header title={`Select Your Availability`}>
              <div className={Styles.filterContainer}>
                {timezone && (
                  <Select
                    name='timezone'
                    label='Timezone'
                    getOptionLabel={({ name, label, offset }) => {
                      return (name || label) + ' (GMT' + offset + ')';
                    }}
                    getOptionValue={({ id }) => id}
                    options={common?.timezones}
                    defaultValue={timezone}
                    onChange={handleTimezoneChange}
                  />
                )}
              </div>
            </Header>
            <Body>
              <ScheduleList
                data={availableTimes}
                onSelectedTimeChange={handleSelectedTimeChange}
                onDeSelect={handleDeSelectTime}
                selected={selectedTimes}
              />
              <OverlayLoader isLoading={isLoading} />
            </Body>
            <Footer>
              <Button type={Button.Type.BLUE} onClick={handleConfirmSchedule}>
                {confirmScheduleLabel || `Next: Select Participants`}
              </Button>
              <Button onClick={onCancel} type={Button.Type.LIGHT_GRAY}>
                {`Cancel`}
              </Button>
            </Footer>
          </Fragment>
        ) : (
          <Fragment>
            <Header title={`Select the Participants for the Call`}></Header>
            <Body>
              <ParticipantList
                conference={currentConference}
                participants={discussion?.participants}
                onParticipantsChange={handleParticipantsChange}
                onRemoveConferenceUser={handleRemoveConferenceUser}
                type={type}
                onAddConferenceGuest={handleGuestAdd}
                onRemoveConferenceGuest={handleGuestDelete}
                callType={currentConference?.call ? 'reschedule' : 'newCall'}
                isReschedule={isReschedule}
              />
              <OverlayLoader isLoading={isLoading} />
            </Body>
            <Footer>
              <Button type={Button.Type.BLUE} onClick={handleSubmitConference}>
                {`Submit Conference`}
              </Button>
              <Button onClick={onCancel} type={Button.Type.LIGHT_GRAY}>
                {`Cancel`}
              </Button>
            </Footer>
          </Fragment>
        )}
      </div>
    </Dialog>
  );
};

ConferenceSchedule.propTypes = {
  isShown: PropTypes.bool,
  type: PropTypes.number,
};

ConferenceSchedule.Type = Type;

export default ConferenceSchedule;
