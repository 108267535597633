import Styles from './Styles.module.scss';
import ScheduleItem from './ScheduleItem';

import Loader from '@hiredigital/ui/Loader';

const ScheduleList = ({ data, isLoading, onSelectedTimeChange, onDeSelect, selected = [] }) => {
  const handleSelect = (item, idx) => {
    item.index = idx;
    let items = [];
    const found = (v) =>
      isSameTime(v?.start || v?.startTime, item?.start || item?.startTime) &&
      isSameTime(v?.end || v?.endTime, item?.end || item?.endTime);
    if (selected.some((v) => found(v))) {
      items = selected.filter((v) => {
        if (!found(v)) {
          return true;
        }
        onDeSelect?.(v);
        return false;
      });
    } else {
      items = [...selected, item];
    }
    onSelectedTimeChange?.(items);
  };

  const isSameTime = (time, otherTime) => `${new Date(time)}` === `${new Date(otherTime)}`;

  return (
    <div className={Styles.list}>
      <div className={Styles.listHeader}>
        <div className={Styles.headerCell}>{`Date`}</div>
        <div className={Styles.headerCell}>{`Time`}</div>
        <div className={Styles.headerCell} style={{ justifyContent: 'flex-end' }}>
          {`You`}
        </div>
      </div>

      <div className={Styles.listBody}>
        {data.map((v, idx) => (
          <ScheduleItem
            key={v?.id || idx}
            data={v}
            onSelect={(item) => handleSelect(item, idx)}
            isSelected={selected.some(
              (s) =>
                isSameTime(s?.start || s?.startTime, v?.start || v?.startTime) &&
                isSameTime(s?.end || s?.endTime, v?.end || v?.endTime)
            )}
          />
        ))}

        {isLoading && <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} />}
      </div>
    </div>
  );
};

ScheduleList.Item = ScheduleItem;
ScheduleList.propTypes = {};

export default ScheduleList;
