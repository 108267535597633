import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';

import Menu from '@hiredigital/ui/Menu/Menu';
import CommonStyles from '../Common.module.scss';
import Styles from './StatusFilter.module.scss';

const StatusFilter = ({ initialStatus, optionEnum, onSelect }) => {
  const [status, setStatus] = useState(initialStatus);

  useEffect(() => {
    if (!initialStatus) {
      // Clear
      setStatus(initialStatus);
    }
  }, [initialStatus]);

  const handleSelect = (item) => {
    setStatus(item);
    onSelect?.(item);
  };

  return (
    <>
      <Menu className={classNames(CommonStyles.marginLeft, CommonStyles.filterContainer)}>
        <Menu.Button className={CommonStyles.buttonFilter}>
          {status ? (
            <div className={CommonStyles.selectedFilter}>{status.label}</div>
          ) : (
            <Fragment>{`Filter by Status`}</Fragment>
          )}
        </Menu.Button>
        <Menu.Items>
          {optionEnum.values.map((s, index) => (
            <Menu.Item
              as='div'
              key={index}
              className={classNames(
                CommonStyles.filterItem,
                status?.id === s?.id && CommonStyles.selected
              )}
              onClick={() => handleSelect(s)}>
              {s.label}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </>
  );
};

export default StatusFilter;
