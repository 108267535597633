/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import { getOrgPrepayments, getOrgCreditNotes } from '@apis/organizations';
import { convertToCurrency } from '@hiredigital/lib/helpers/utils';
import ListItem from './ListItem';
import ShowMore from './ShowMore';
import CreditTopUpDialog from './CreditTopUpDialog';

import Styles from './Styles.module.scss';

const CreditList = ({ orgUuid, currency, stripeCurrency }) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [hasMore, setHasMore] = useState([true, true]);
  const [isBusy, setIsBusy] = useState(false);
  // const [showDetails, setShowDetails] = useState(true);
  const [showCreditPackages, setShowCreditPackages] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const sum = (values) => {
    const items = values.map((v) => v?.data?.meta?.totalAmount || 0);
    return items.reduce((t, v) => t + v);
  };

  const loadData = useCallback(
    (page = 1, calculate = true, clearResults = false) => {
      const merge = (values) => {
        const [prepayments, creditNotes] = values;
        return [...getResult(prepayments), ...getResult(creditNotes)];
      };

      const params = { page };
      const [hasPrepayments, hasCreditNotes] = hasMore;
      setIsBusy(true);
      Promise.all([
        hasPrepayments ? getOrgPrepayments(orgUuid, { params }) : {},
        hasCreditNotes ? getOrgCreditNotes(orgUuid, { params }) : {},
        // hasPending ? getOrgPendingPrepayments(orgUuid, { params }) : {},
      ])
        .then((values) => {
          const items = [...merge(values)];
          setHasMore(getMorePageStatus(values));
          setData(clearResults ? items : [...data, ...items]);
          setIsBusy(false);
          if (calculate) {
            setTotal(sum(values));
          }
        })
        .catch((error) => {
          console.log('Error has occured during request!');
          setIsBusy(false);
        });
    },
    [hasMore, data]
  );

  const handleLoadMore = (page) => loadData(page, page === 1, page === 1);

  const getResult = (v) => (!!v?.data?.results ? v?.data?.results : []);

  const getMorePageStatus = (values) => values.map((v) => !!v?.data?.meta?.nextPage);

  // const toggleDetails = () => setShowDetails(!showDetails);

  const handleClosePackages = (paymentSuccess) => {
    if (paymentSuccess) {
      // FIXME: There needs to be some way to refresh after the credit is created. Right now what happens is it will clear everything out
    }
    setShowCreditPackages(false);
  };

  const handleOpenCreditSelection = () => {
    setShowCreditPackages(true);
  };

  return (
    <>
      <CreditTopUpDialog isShown={showCreditPackages} onClose={handleClosePackages} />
      <Card>
        <Card.Header>
          <span className={Styles.title}>{`Credits`}</span>
          {/*<Button
            data-test-id='topUpCredits'
            onClick={handleOpenCreditSelection}
            type={Button.Type.BLUE}>
            {`Top Up Credits`}
          </Button>*/}
        </Card.Header>
        {data.length ? (
          <>
            {data.map(
              (v, idx) => !!v.remainingCredit && <ListItem data={v} currency={currency} key={idx} />
            )}
            {hasMore.reduce((a, b) => a || b) && (
              <ShowMore
                initLoad={true}
                isBusy={isBusy}
                hasMore={true}
                onLoadMore={handleLoadMore}
              />
            )}
            <Card.Footer className={Styles.summary}>
              <div className={Styles.summaryTitle}>{`Total Credits`}</div>
              <div className={Styles.summaryValue}>{convertToCurrency(total, currency)}</div>
            </Card.Footer>
          </>
        ) : (
          <Card.Item>{`No Credits Have Been Added`}</Card.Item>
        )}
      </Card>
    </>
  );
};

CreditList.propTypes = {
  orgUuid: PropTypes.string,
  stripeCurrency: PropTypes.string,
  currency: PropTypes.shape({
    rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.string,
  }),
};

export default CreditList;
