import Styles from './Guide.module.scss';

const PaymentItem = ({ title, description, img }) => {
  return (
    <div className={Styles.paymentCardItem}>
      <img alt='Item Image' className={Styles.icon} src={img} />
      <p className={Styles.title}>{title}</p>
      <p className={Styles.description}>
        {typeof description === 'function' ? description() : description}
      </p>
    </div>
  );
};

export default PaymentItem;
