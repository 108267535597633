// import { DeviceUUID } from 'device-uuid';
import Cookies from 'universal-cookie';
import { COOKIE_DOMAIN } from '@helpers/utils';

export const load = () => {
  const cookies = new Cookies();
  const cookieExists = cookies.get('device');

  if (!cookieExists) {
    const { DeviceUUID } = require('device-uuid');

    const uuid = new DeviceUUID().get();
    cookies.set('device', uuid, COOKIE_DOMAIN);
  }
};
