import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Styles.module.scss';

import Select from '@hiredigital/ui/Input/Select';
import Loader from '@hiredigital/ui/Loader';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Tab from '@hiredigital/ui/Tab/Tab';

import { patchOrganization, getOrgUsers } from '@apis/organizations';
import { patchTeam } from '@apis/teams';
import withEmailLabel from '../../shared/hoc/withEmailLabel';
import { PointContactType } from '@hiredigital/lib/helpers/enum';
import { validateEmail } from '@helpers/forms';

const UserSelect = withEmailLabel(Select);

let saveTimeout = null;

const Type = {
  ORGANIZATION: 0,
  TEAM: 1,
};

const PointContact = ({ resourceUuid, data, type = Type.ORGANIZATION, users = [] }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [contactType, setContactType] = useState(data?.type);
  const [firstName, setFirstName] = useState(data?.firstName);
  const [lastName, setLastName] = useState(data?.lastName);
  const [email, setEmail] = useState(data?.email);
  const [user, setUser] = useState(data?.user);
  const [errors, setErrors] = useState({});

  const mappedMethods = {
    firstName: setFirstName,
    lastName: setLastName,
    email: setEmail,
    user: setUser,
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrors({});
    mappedMethods[name](value);
    saveContactWithDelay();
  };

  const saveContactWithDelay = (timeout = 2000) => {
    if (saveTimeout) clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      saveContact();
    }, timeout);
  };

  const validateFields = () => {
    if (contactType !== PointContactType.CUSTOM.id) {
      if (!user) {
        setErrors({ ...errors, user: 'Enter a valid user.' });
        return false;
      }
    } else {
      const isEmailValid = validateEmail(email);
      if (!isEmailValid) {
        setErrors({ ...errors, email: 'Enter a valid email address.' });
        return false;
      }
    }

    return true;
  };

  const saveContact = () => {
    if (!validateFields()) return;

    const contact =
      contactType === PointContactType.CUSTOM.id
        ? {
            firstName: firstName,
            lastName: lastName,
            email: email,
            type: PointContactType.CUSTOM.id,
          }
        : {
            user,
            type: PointContactType.EXISTING.id,
          };
    setIsSaving(true);
    setErrors({});
    const request = [patchOrganization, patchTeam];
    request[type](resourceUuid, { contact })
      .then(() => {
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
        handleError(error?.response?.data);
      });
  };

  const handleError = (errs) => {
    if (!errs) return;
    const email = errs?.contact?.email;
    if (email) {
      setErrors({ ...errors, email });
    }
  };

  const onUsersLoadOptions = async (search, loadedOptions, { page }) => {
    const { data } = await getOrgUsers(resourceUuid, { params: { search, page, limit: 20 } });
    return {
      options: data.results.map((v) => v.user),
      hasMore: data.meta.nextPage !== null,
      additional: {
        page: data.meta.nextPage,
      },
    };
  };

  const options = [
    { label: 'Select a team member', value: PointContactType.EXISTING.id },
    { label: 'Use a custom contact', value: PointContactType.CUSTOM.id },
  ];

  return (
    <Card>
      <Card.Header title={`Point of Contact`} />
      <Card.Note>{`Choose your point of contact for billing and agreements.`}</Card.Note>
      <Card.Item>
        <div className={Styles.contactForm}>
          <div className={Styles.contactInputContainer}>
            <Tab.Group
              buttonStyle
              manual
              selectedIndex={options.findIndex((o) => contactType === o.value)}
              onChange={(i) => setContactType(options[i].value)}>
              <Tab.List>
                {options.map((option, index) => (
                  <Tab key={index}>{option.label}</Tab>
                ))}
              </Tab.List>
            </Tab.Group>
          </div>

          {contactType === PointContactType.CUSTOM.id ? (
            <Fragment>
              <div className={Styles.contactInputGroup}>
                <div className={Styles.contactInputContainer} style={{ marginRight: '5px' }}>
                  <TextInput
                    name='firstName'
                    label='First Name'
                    defaultValue={firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className={Styles.contactInputContainer} style={{ marginLeft: '5px' }}>
                  <TextInput
                    className={'hightlight-block'}
                    name='lastName'
                    label='Last Name'
                    defaultValue={lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={Styles.contactInputContainer}>
                <TextInput
                  name='email'
                  label='Email'
                  defaultValue={email}
                  onChange={handleChange}
                  error={errors?.email}
                />
              </div>
            </Fragment>
          ) : (
            <div className={classNames(Styles.contactInputContainer, 'highlight-block')}>
              {type === Type.ORGANIZATION ? (
                <UserSelect
                  className={Styles.select}
                  defaultValue={data?.user}
                  classNamePrefix='s-contact'
                  name='user'
                  label='Team Member'
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ uuid }) => uuid}
                  onChange={handleChange}
                  isPaginated
                  loadOptions={onUsersLoadOptions}
                  error={errors?.user}
                />
              ) : (
                <UserSelect
                  className={Styles.select}
                  defaultValue={data?.user}
                  classNamePrefix='s-contact'
                  name='user'
                  label='Team Member'
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ uuid }) => uuid}
                  onChange={handleChange}
                  options={users}
                  error={errors?.user}
                />
              )}
            </div>
          )}
        </div>
      </Card.Item>
      <Card.Footer right>
        <Button onClick={() => saveContactWithDelay(0)} name='submit' type={Button.Type.BLUE}>
          {isSaving && (
            <Loader
              style={{ marginRight: '10px' }}
              color={Loader.Color.WHITE}
              size={Loader.Size.SMALL}
            />
          )}
          {`Update Point of Contact`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

PointContact.propTypes = {
  resourceUuid: PropTypes.string,
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    user: PropTypes.object,
  }),
  type: PropTypes.number,
};

PointContact.Type = Type;

export default PointContact;
