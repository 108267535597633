import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import TalentRateItem from './TalentRateItem';
import Styles from './Styles.module.scss';

const Type = {
  TALENT: 1,
  TEAM: 2,
  ADMIN: 3,
};

const TalentRateList = ({
  type = Type.TALENT,
  apiManager,
  resourceUuid,
  resource,
  talentPermission,
  userSkills,
  showCopySkillsOption = true,
  common,
}) => {
  const isTalent = type === Type.TALENT;
  const isAdmin = type === Type.ADMIN;

  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    (isAdmin
      ? apiManager.getAdminTalentRates(resource, resourceUuid)
      : isTalent
      ? apiManager.getTalentRates()
      : apiManager.getTeamTalentRates(resourceUuid)
    ).then((response) => setList(response?.data?.results || []));
  }, [resource, resourceUuid]);

  const handleCreateService = () => {
    if (showForm) return;
    setList([{}, ...list]);
    setShowForm(true);
  };

  const updateServiceRate = (service) => {
    let updatedList = list;
    if (service) {
      updatedList = list.filter((el) => {
        return el && el.uuid;
      });
      updatedList.unshift(service);
    } else {
      updatedList.shift();
    }
    setList(updatedList);
    setShowForm(false);
  };

  const handleDeleteServiceRate = (serviceUuid, serviceIndex) => {
    if (serviceUuid) {
      setList(list.filter(({ uuid }) => uuid !== serviceUuid));
    } else {
      setList(list.filter((e, index) => index !== serviceIndex));
    }
  };

  return (
    <Card>
      <Card.Header>
        <span>{`Rates`}</span>
        <div className={Styles.action}>
          <Button name='add new' type={Button.Type.BLUE} onClick={handleCreateService}>
            Add a New Rate
          </Button>
        </div>
      </Card.Header>
      {!isAdmin && (
        <Card.Note>
          {`To add bank accounts and view invoices, please continue to the `}
          <Link to={`/settings/payments`} className={Styles.link}>
            Payment Settings
          </Link>
          {` section.`}
        </Card.Note>
      )}
      <Fragment>
        {!list?.length && (
          <Card.Item>
            <p className={Styles.empty}>{`No rates have been added.`}</p>
          </Card.Item>
        )}
        <div>
          {!!list?.length &&
            list?.map((service, index) => (
              <TalentRateItem
                type={type}
                apiManager={apiManager}
                index={index}
                service={service}
                userSkills={userSkills}
                resourceUuid={resourceUuid}
                resource={resource}
                onUpdateServiceRate={updateServiceRate}
                onDeleteService={handleDeleteServiceRate}
                key={service.uuid || index}
                talentPermission={talentPermission}
                showCopySkillsOption={showCopySkillsOption}
                common={common}
              />
            ))}
        </div>
      </Fragment>
    </Card>
  );
};

TalentRateList.Type = Type;

TalentRateList.propTypes = {
  type: PropTypes.number,
  apiManager: PropTypes.object.isRequired,
  resourceUuid: PropTypes.string,
  resource: PropTypes.string,
  talentPermission: PropTypes.bool,
  userSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  showCopySkillsOption: PropTypes.bool,
};

export { Type as TalentRateType };

export default TalentRateList;
