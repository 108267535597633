import { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import TextArea from '@hiredigital/ui/Input/TextArea';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import { patchOrganization } from '@apis/organizations';

import Currency from '@hiredigital/ui/CurrencyDropdown';
import Styles from './Styles.module.scss';

const PaymentDetails = ({ organization: initialOrganization }) => {
  let saveTimeout;
  const [isEdit, setIsEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [organization, setOrganization] = useState(initialOrganization);

  // const handleContactChange = (event, valueKey) => {
  //   const { name, value } = event.target;
  //   setOrganization({
  //     ...organization,
  //     contact: { ...organization.contact, [name]: valueKey ? value[valueKey] : value },
  //   });
  // };

  const handleAddressChange = (event, valueKey) => {
    const { name, value } = event.target;
    setOrganization({
      ...organization,
      address: { ...organization.address, [name]: valueKey ? value[valueKey] : value },
    });
  };

  const handlePaymentChange = (event, valueKey) => {
    const { name, value } = event.target;
    setOrganization({
      ...organization,
      payment: { ...organization.payment, [name]: valueKey ? value[valueKey] : value },
    });
  };

  const handleAutoSave = (timeout = 3000) => {
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }

    saveTimeout = setTimeout(() => {
      handleSubmit();
    }, timeout);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setOrganization(initialOrganization);
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleSubmit = async () => {
    try {
      setIsSaving(true);
      const { address, payment, contact } = organization;
      const { data } = await patchOrganization(organization.uuid, { address, payment, contact });
      setOrganization(data);
      setIsEdit(false);
    } catch (err) {
    } finally {
      setIsSaving(false);
    }
  };
  const regionFields = () => {
    const data = organization?.address;
    if (!data) {
      return '';
    } else if (data.city && data.region && data.postalCode) {
      return (
        <p className={Styles.addressItem}>{`${data.city}, ${data.region}, ${data.postalCode}`}</p>
      );
    } else if (data.city && data.region) {
      return <p className={Styles.addressItem}>{`${data.city}, ${data.region}`}</p>;
    } else if (data.city && data.postalCode) {
      return <p className={Styles.addressItem}>{`${data.city}, ${data.postalCode}`}</p>;
    } else if (data.region && data.postalCode) {
      return <p className={Styles.addressItem}>{`${data.region}, ${data.postalCode}`}</p>;
    }
  };

  return (
    <Card>
      <Card.Header>
        <span className={Styles.title}>{`Billing Details`}</span>

        {!isEdit && (
          <Button data-test-id='editBillingDetails' onClick={handleEdit} type={Button.Type.BLUE}>
            {`Edit`}
          </Button>
        )}
      </Card.Header>
      {isEdit ? (
        <Card.Item>
          <InputContainer>
            <TextArea
              name='address'
              label='Address'
              placeholder='Address'
              defaultValue={organization?.address?.address}
              onChange={handleAddressChange}
            />
          </InputContainer>
          <InputGroup>
            <InputContainer>
              <TextInput
                name='city'
                label='City'
                defaultValue={organization?.address?.city}
                onChange={handleAddressChange}
              />
            </InputContainer>

            <InputContainer>
              <TextInput
                name='region'
                label='State'
                defaultValue={organization?.address?.region}
                onChange={handleAddressChange}
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <TextInput
                name='postalCode'
                label='Postal Code'
                defaultValue={organization?.address?.postalCode}
                onChange={handleAddressChange}
              />
            </InputContainer>

            <InputContainer>
              <TextInput
                name='country'
                label='Country'
                defaultValue={organization?.address?.country}
                onChange={handleAddressChange}
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <TextInput
                name='taxNumber'
                label='Tax Id Number'
                defaultValue={organization?.payment?.taxNumber}
                onChange={handlePaymentChange}
              />
            </InputContainer>
            <InputContainer>
              <Currency
                name='currency'
                label='Currency'
                defaultValue={organization?.payment?.currency}
                onChange={handlePaymentChange}
              />
            </InputContainer>
          </InputGroup>
        </Card.Item>
      ) : (
        <Card.Item>
          <div className={Styles.addressRow}>
            <div className={Styles.left}>
              <p className={Styles.addressLabel}>{`Address`}</p>
              <p className={Styles.addressItem}>{organization?.address?.address}</p>
              {regionFields()}
              <p className={Styles.addressItem}>{organization?.address?.country}</p>
            </div>
            <div className={Styles.right}>
              {organization?.payment?.taxNumber && (
                <>
                  <p className={Styles.addressLabel}>{`Tax ID Number`}</p>
                  <p className={Styles.addressItem}>{organization?.payment?.taxNumber}</p>
                </>
              )}
              <p className={Styles.addressLabel}>{`Currency`}</p>
              <p className={Styles.addressItem}>{organization?.payment?.currency}</p>
            </div>
          </div>
        </Card.Item>
      )}

      {isEdit && (
        <Card.Footer right>
          <Button onClick={handleCancel} name='submit' type={Button.Type.BLUE}>
            {`Cancel`}
          </Button>
          <Button
            onClick={() => handleAutoSave(0)}
            name='submit'
            type={Button.Type.BLUE}
            isLoading={isSaving}>
            {`Save Details`}
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};

PaymentDetails.propTypes = {
  organization: PropTypes.object,
};

export default PaymentDetails;
