import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import classNames from 'classnames';

const Section = ({
  title,
  subtitle,
  children,
  label,
  noTitleMarginBottom = false,
  action,
  onActionClick,
  titleFontSize,
  getContentWrapper,
  ...props
}) => (
  <div {...props} ref={getContentWrapper}>
    {(title || label || subtitle || action) && (
      <div className={classNames(Styles.titleContainer)}>
        {label && <p className={Styles.label}>{label}</p>}
        {title && (
          <div className={Styles.titleSubContainer}>
            <p className={Styles.title}>{title}</p>
            {action}
          </div>
        )}
        {subtitle && <p className={Styles.subtitle}>{subtitle}</p>}
      </div>
    )}
    {children}
  </div>
);

Section.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Section;
