import SplitButton from '@hiredigital/ui/Button/SplitButton';
import Badge from '@hiredigital/ui/Badge/Badge';
import Card from '@hiredigital/ui/Card';
import Text from '@hiredigital/ui/Text';

import { useOrg } from '@context/org';
import Styles from './Styles.module.scss';

const Item = ({ onLeave, org = {}, ...props }) => {
  const currentOrg = useOrg();

  return (
    <Card.Item {...props} style={{ padding: '12px 20px' }}>
      <div className={Styles.row}>
        <img className={Styles.picture} src={org?.picture} alt='' />
        <div style={{ overflow: 'hidden' }}>
          <Text type={Text.Type.PRIMARY}>{org?.name}</Text>
          <Text type={Text.Type.SECONDARY}>{org?.email}</Text>
        </div>

        {currentOrg?.uuid === org?.uuid ? (
          <Badge color='neutral' marginLeft='auto'>
            {`Current Client Team`}
          </Badge>
        ) : (
          <div className={Styles.action}>
            <SplitButton
              more={[
                { text: `Leave ${org?.name || 'Client Team'}`, onClick: () => onLeave?.(org) },
              ]}
            />
          </div>
        )}
      </div>
    </Card.Item>
  );
};

Item.propTypes = {};

export default Item;
