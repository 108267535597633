import PropTypes from 'prop-types';
import DiscussionArea from '../Discussions/ContentArea/DiscussionArea';
import { DiscussionProvider } from '@context/discussion';
import Styles from './DiscussionSidePane.module.scss';

const Item = ({ onMinimize, discussion, talent }) => {
  return (
    <DiscussionProvider>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <img src={talent?.picture} className={Styles.picture} />
          <div className={Styles.userDetails}>
            <p className={Styles.name}>{talent?.name}</p>
            <p className={Styles.headline}>{talent?.country?.name || 'Remote'}</p>
          </div>
        </div>
        <div className={Styles.body}>
          <DiscussionArea
            className={Styles.discussionArea}
            discussion={discussion}
            showSearch={false}
          />
        </div>
        <div className={Styles.actionContainer}>
          <button onClick={onMinimize} className={Styles.actionMinimize} />
        </div>
      </div>
    </DiscussionProvider>
  );
};

Item.propTypes = {
  children: PropTypes.node,
};

export default Item;
