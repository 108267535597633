import { useState, useEffect, Fragment } from 'react';
import classNames from 'classnames';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useOrg } from '@context/org';
import {
  postOrgStripeCard,
  postOrgStripeCustomerDeposit,
  getOrgStripeCustomer,
} from '@apis/customers';
import withStripe from '../../shared/hoc/withStripe';
import OtherPaymentsDialog from './OtherPaymentsDialog';

import Styles from './SecurityDeposit.module.scss';

const SecurityDeposit = ({ onDepositSuccess, cardContainer }) => {
  const stripe = useStripe();
  const elements = useElements();

  const org = useOrg();

  const [isSaving, setIsSaving] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [showOtherPayments, setShowOtherPayments] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [depositAmount, setDepositAmount] = useState(null);
  const [defaultCard, setDefaultCard] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isCardEdit, setIsCardEdit] = useState(false);
  const [isDepositPaid, setIsDepositPaid] = useState(false);

  useEffect(() => {
    setShowCard(customer?.cards?.length === 0);
    setDefaultCard(customer?.cards?.find((v) => v.isDefault));
  }, [customer]);

  useEffect(() => {
    if (org) {
      setDepositAmount(org?.depositAmount);
      setIsDepositPaid(org?.depositPaid);
      getOrgStripeCustomer(org?.uuid).then(({ data }) => {
        setCustomer(data || {});
        setIsLoaded(true);
      });
    }
  }, [org]);

  const handlePlaceDeposit = () => {
    setIsSaving(true);
    const hasCustomerCard = customer?.defaultSource && customer?.cards.length > 0;

    if (isCardEdit || !hasCustomerCard) {
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
      stripe.createToken(cardElement).then(function (result) {
        createCardDeposit(org?.uuid, result?.token?.id, depositAmount);
      });
      return;
    }

    postOrgStripeCustomerDeposit(org?.uuid, { amount: depositAmount, initial: true })
      .then(({ data }) => {
        setIsSaving(false);
        setIsDepositPaid(true);
        setCustomer(data);
        onDepositSuccess?.(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const createCardDeposit = (uuid, tokenId, amount) => {
    Promise.all([
      postOrgStripeCard(uuid, { token: tokenId }),
      postOrgStripeCustomerDeposit(uuid, { amount, initial: true }),
    ])
      .then((values) => {
        setIsSaving(false);
        setIsCardEdit(false);
        setIsDepositPaid(true);

        const card = values?.[0].data;
        const customer = values?.[1].data;
        const data = {
          ...customer,
          cards: [...customer?.cards?.map((v) => ({ ...v, isDefault: false })), card],
        };

        setCustomer(data);
        onDepositSuccess?.(data);
      })
      .catch((error) => {
        console.warn(error);
        setIsSaving(false);
        setIsCardEdit(false);
      });
  };

  const handleUpdateCard = () => {
    setShowCard(true);
    setIsCardEdit(true);
  };

  const handleCloseDialog = () => {
    setShowOtherPayments(false);
  };

  return (
    <>
      <OtherPaymentsDialog isShown={showOtherPayments} onClose={handleCloseDialog} />
      <Card className={classNames(Styles.depositCard, cardContainer)}>
        <Card.Header title={`Security Deposit`} />
        <Card.Note>
          <div className={Styles.depositNote}>
            {`The deposit is fully refundable if we do not find you the right talent.`}
            {!isDepositPaid && (
              <button
                onClick={() => setShowOtherPayments(true)}
                className={Styles.btnLink}
                style={{ marginLeft: 'auto' }}>
                {`Other payment methods?`}
              </button>
            )}
          </div>
        </Card.Note>
        <Card.Item>
          {isLoaded && (
            <Fragment>
              {isDepositPaid ? (
                <Fragment>
                  <p>
                    {`Your deposit has been submitted and will be used toward your first talent engagement.`}
                  </p>
                </Fragment>
              ) : (
                <Fragment>
                  <div className={Styles.depositDetails}>
                    <p className={Styles.depositLabel}>{`Deposit Amount`}</p>
                    <p>
                      {org?.payment?.stripeCurrency === 'sgd' && <span>{`S`}</span>}
                      {`$${depositAmount}`}
                    </p>
                  </div>
                  {showCard ? (
                    <CardElement
                      className={Styles.depositInput}
                      options={{
                        classes: {
                          base: Styles.base,
                          complete: Styles.complete,
                          empty: Styles.empty,
                          focus: Styles.focus,
                          invalid: Styles.invalid,
                        },
                      }}
                    />
                  ) : (
                    <div>
                      <div>
                        <p className={Styles.depositLabel}>{`Credit Card Number`}</p>
                        <div className={Styles.cardDetails}>
                          <p className={Styles.depositLabel}>
                            {`${defaultCard?.brand} ending in ${defaultCard?.last4}`}
                          </p>
                          <p className={Styles.cardExpiry}>
                            {`Expires in ${`0${defaultCard?.expMonth}`.slice(-2)} / ${
                              defaultCard?.expYear
                            }`}
                          </p>
                          <button onClick={handleUpdateCard} className={Styles.btnLink}>
                            {`Update Card`}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
          {!isLoaded && <Loader color={Loader.Color.BLUE} />}
        </Card.Item>
        {isLoaded && !isDepositPaid && (
          <Card.Footer right>
            <Button onClick={handlePlaceDeposit} type={Button.Type.BLUE} isLoading={isSaving}>
              {`Place Deposit`}
            </Button>
          </Card.Footer>
        )}
      </Card>
    </>
  );
};

export default withStripe(SecurityDeposit);
