import { memo } from 'react';
import PropTypes from 'prop-types';
import Styles from '../../Styles.module.scss';
import TileItem from './TileItem';
import classNames from 'classnames';

const TileSelector = memo(
  ({
    list,
    data,
    onItemAdd,
    onItemSubtract,
    getIdentifier,
    className,
    isMulti = true,
    busyIndexes = [],
  }) => {
    const getItems = (id) => data.filter((v) => getIdentifier(v) === id);

    return (
      <div className={classNames(Styles.talentList, className)}>
        {list?.map((v, idx) => (
          <TileItem
            key={idx}
            onItemAdd={(v) => onItemAdd?.(v, idx)}
            onItemSubtract={(v) => onItemSubtract?.(v, idx)}
            data={{ ...v, items: getItems(v?.id) }}
            isMulti={isMulti}
            isBusy={busyIndexes.includes(idx)}
          />
        ))}
      </div>
    );
  }
);

TileSelector.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ),
};

export default TileSelector;
