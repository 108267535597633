import { authRequest } from './utils';

// const serializeDiscussion = (data) => {
//   const serializedData = {
//     ...data,
//   };
//   return serializedData;
// };

const serializeMessage = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

export const getDiscussions = async (config = undefined) => {
  const url = `discussions/`;
  return await authRequest.get(url, config);
};

export const getDiscussion = async (uuid, config = undefined) => {
  const url = `discussions/${uuid}/`;
  return await authRequest.get(url, config);
};

export const getDiscussionMessages = async (uuid, config = undefined) => {
  const url = `discussions/${uuid}/messages/`;
  return await authRequest.get(url, config);
};

export const putDiscussionMessage = async (
  uuid,
  messageId,
  data,
  serialize = serializeMessage,
  config
) => {
  const url = `discussions/${uuid}/messages/${messageId}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const createDiscussionMessage = async (uuid, data, serialize = serializeMessage, config) => {
  const url = `discussions/${uuid}/messages/`;
  return await authRequest.post(url, serialize(data), config);
};

export const deleteDiscussionMessage = async (uuid, messageId, config = undefined) => {
  const url = `discussions/${uuid}/messages/${messageId}/`;
  return await authRequest.delete(url, config);
};

export const postDiscussionAttachment = async (uuid, data, config = undefined) => {
  const url = `discussions/${uuid}/attachments/`;
  return await authRequest.post(url, data, config);
};

export const getDiscussionRedirectData = async (uuid, config = undefined) => {
  const url = `discussions/${uuid}/redirect-data`;
  return await authRequest.get(url, config);
};
