import { useState } from 'react';
import Button from '@hiredigital/ui/Button';
import { AgreementStatus } from '@hiredigital/lib/helpers/enum';
import { postOrgAgreement } from '@apis/organizations';
import { useOrg } from '@context/org';
import MainContainer from '../StagesLayout/MainContainer';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { postBriefSubmit } from '@apis/briefs';
import { openUrl } from '@hiredigital/lib/helpers/utils';
import toast from 'react-hot-toast';

const Agreement = ({ initialBrief, onBack, onAfterSubmit }) => {
  const org = useOrg();

  const [isSaving, setIsSaving] = useState(false);
  const [isViewing, setIsViewing] = useState(false);

  const handleBack = () => onBack?.('/overview');

  const handleGenerate = () => {
    setIsViewing(true);
    postOrgAgreement(org?.uuid, {})
      .then(({ data }) => {
        window.open(data, '_blank');
        setIsViewing(false);
      })
      .catch((error) => {
        setIsViewing(false);
      });
  };

  const handleSubmit = () => {
    setIsSaving(true);
    const payload = { page: initialBrief?.stage };
    postBriefSubmit(initialBrief?.uuid, payload)
      .then(({ data }) => {
        onAfterSubmit?.(data);
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  const handleViewAgreement = async (url) => {
    try {
      setIsViewing(true);
      await openUrl(url, { verifyUrl: true });
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 404) {
        toast.error("Agreement couldn't be found!");
      } else {
        toast.error("Something's wrong! Please contact us to investigate.");
      }
    } finally {
      setIsViewing(false);
    }
  };

  return (
    <MainContainer
      title={`Client Agreement`}
      subtitle={`The platform agreement is required for all clients on Hire Digital.`}
      onBack={handleBack}
      backLabel={`Back to Confirmation`}>
      <div>
        {org?.agreement ? (
          <div>
            {(org?.agreement?.status === AgreementStatus.SENT.id ||
              org?.agreement?.status === AgreementStatus.IN_PROCESS.id ||
              org?.agreement?.status === AgreementStatus.CANCELLED.id) && (
              <span>{`Please take a moment to review the terms and sign the agreement. You will be redirected to DocuSign to view the document.`}</span>
            )}
            {org?.agreement?.status === AgreementStatus.SIGNED.id && (
              <span>{`Your platform agreement was signed on ${toReadableDate(
                org?.agreement?.dateReceived
              )}. You can view your copy here.`}</span>
            )}
          </div>
        ) : (
          <div>
            {`Please take a moment to review the terms and sign the agreement. You will be redirected to DocuSign to view the document.`}
          </div>
        )}

        <div style={{ marginTop: '60px' }}>
          {org?.agreementSigned && (
            <Button
              isLoading={isSaving}
              name='submit'
              type={Button.Type.BLUE}
              onClick={handleSubmit}>
              {`Save and Continue`}
            </Button>
          )}
          {org?.agreement?.uuid && (org?.agreement?.attachment || org?.agreement?.url) ? (
            <Button
              isLoading={isViewing}
              name='submit'
              type={Button.Type.BLUE_OUTLINE}
              onClick={() =>
                handleViewAgreement(org?.agreement?.attachment || org?.agreement?.url)
              }>
              {`View Agreement`}
            </Button>
          ) : (
            <Button
              isLoading={isViewing}
              name='submit'
              type={Button.Type.BLUE_OUTLINE}
              onClick={handleGenerate}>
              {`View Agreement`}
            </Button>
          )}
        </div>
      </div>
    </MainContainer>
  );
};

Agreement.propTypes = {};

export default Agreement;
