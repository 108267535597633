import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Menu } from 'evergreen-ui/esm/menu';
import { Popover } from 'evergreen-ui/esm/popover';
import Position from 'evergreen-ui/esm/constants/src/Position';
import Badge from '@hiredigital/ui/Badge/Badge';

import { useUser } from '@context/user';

import Card from '@hiredigital/ui/Card';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';
import Text from '@hiredigital/ui/Text';

import Styles from './Styles.module.scss';

const Type = {
  ORGANIZATION: 0,
  TEAM: 1,
};

const MemberItem = ({ orgUuid, deleteMember, type, member, resourceUuid, ...props }) => {
  const currentUser = useUser();
  const [user, setUser] = useState();

  const handleDelete = () => {
    deleteMember(member);
  };

  useEffect(() => {
    setUser(member?.user);
  }, [member]);

  return (
    <Card.Item {...props}>
      <div className={Styles.row}>
        <img className={classNames(Styles.picture, 'highlight-block')} src={user?.picture} alt='' />
        <div style={{ overflow: 'hidden' }}>
          <Text type={Text.Type.PRIMARY} className={'highlight-block'}>
            {user?.name}
          </Text>
          <Text type={Text.Type.SECONDARY}>{user?.email}</Text>
        </div>

        <div className={Styles.action}>
          {type === Type.TEAM && user?.uuid === currentUser?.uuid && (
            <>
              <Badge color='neutral'>{`Owner`}</Badge>
            </>
          )}
          {user?.uuid !== currentUser?.uuid && (
            <Popover
              position={Position.BOTTOM_RIGHT}
              minWidth={150}
              content={
                <Menu>
                  <Menu.Group>
                    <Menu.Item onClick={handleDelete}>{`Remove Member`}</Menu.Item>
                  </Menu.Group>
                </Menu>
              }>
              <div className={Styles.moreActionOutline}>
                <IconMoreVertical className={Styles.moreIcon} />
              </div>
            </Popover>
          )}
        </div>
      </div>
    </Card.Item>
  );
};

MemberItem.propTypes = {
  orgUuid: PropTypes.string,
  member: PropTypes.shape({
    isManager: PropTypes.bool,
    briefNotifications: PropTypes.bool,
    user: PropTypes.object,
  }),
  deleteMember: PropTypes.func,
};

export default MemberItem;
