import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import SplitButton from '@hiredigital/ui/Button/SplitButton';
import Badge from '@hiredigital/ui/Badge/Badge';

import TextInput from '@hiredigital/ui/Input/TextInput';
import TextArea from '@hiredigital/ui/Input/TextArea';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';

import Text from '@hiredigital/ui/Text';

// import Badge from '../Badge/Badge';
import { postTeam, deleteTeam } from '@apis/teams';
import { useUser } from '@context/user';
import Styles from './Styles.module.scss';

const Team = ({ isAdding, onAdd, onEdit, onDelete, onCancel, team = {}, ...props }) => {
  const user = useUser();

  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    deleteTeam(team?.uuid)
      .then(() => {
        setIsDeleting(false);
        onDelete?.(team);
      })
      .catch((error) => {
        setIsDeleting(false);
        console.error(error);
      });
  };

  const handleSave = () => {
    setIsSaving(true);
    const data = { name, description };
    if (!team?.id) {
      postTeam(data)
        .then(({ data }) => {
          setIsSaving(false);
          onAdd?.(data);
        })
        .catch((error) => {
          setIsSaving(false);
          console.error(error);
        });
    }
  };

  return isAdding || isEditing ? (
    <Fragment>
      <Card.Item>
        <InputContainer>
          <TextInput
            name='name'
            label='Name'
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputContainer>

        <InputContainer>
          <TextArea
            name='description'
            label='Description'
            defaultValue={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </InputContainer>
      </Card.Item>
      <Card.Footer right>
        <Button onClick={onCancel} type={Button.Type.GRAY}>
          {`Cancel`}
        </Button>
        <Button onClick={handleSave} type={Button.Type.BLUE} isLoading={isSaving}>
          {`Save`}
        </Button>
      </Card.Footer>
    </Fragment>
  ) : (
    <Card.Item {...props}>
      <div className={Styles.row}>
        <img className={Styles.picture} src={team?.picture} alt='' />
        <div style={{ overflow: 'hidden', flex: 1 }}>
          <Text type={Text.Type.PRIMARY}>{team?.name}</Text>
          <Text type={Text.Type.SECONDARY}>{team?.email}</Text>
        </div>

        {user?.uuid === team?.owner?.uuid && <Badge color='neutral'>{`Owner`}</Badge>}

        <div className={Styles.action}>
          <SplitButton
            more={[
              { text: 'Edit', onClick: () => onEdit?.(team) },
              { text: 'Delete', onClick: () => handleDelete() },
            ]}
          />
        </div>
      </div>
    </Card.Item>
  );
};

Team.propTypes = {
  orgUuid: PropTypes.string,
  team: PropTypes.shape({
    isManager: PropTypes.bool,
    briefNotifications: PropTypes.bool,
    team: PropTypes.object,
  }),
  deleteTeam: PropTypes.func,
  currentUser: PropTypes.object,
};

export default Team;
