/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Loader from '@hiredigital/ui/Loader';
import Layout from '@components/Layout/AppLayout';

import { useUser } from '@context/user';

const Index = () => {
  const params = useParams();
  const history = useHistory();
  const user = useUser();

  useEffect(() => {
    if (!user?.uuid) return;
    const { uuid, engagementUuid } = params;
    if (user.isClient) {
      history.push(`/briefs/${uuid}/engagements`);
    }

    if (user.isTalent) {
      history.push(`/engagements/${engagementUuid}`);
    }
  }, [params, user]);

  return (
    <Layout>
      <Loader style={{ margin: 'auto' }} />
    </Layout>
  );
};

export default Index;
