import Button from '@hiredigital/ui/Button';
import AppLayout from '@components/Layout/AppLayout';
import { useHistory } from 'react-router-dom';
import Text from '@hiredigital/ui/Text/Text';

import Styles from '../styles/error.module.scss';

const Error = (props) => {
  const history = useHistory();
  const head = {
    title: `Page Not Found | Hire Digital`,
    meta: [
      {
        name: 'description',
        content:
          "The page you're looking for no longer exists. Please contact us if this is a mistake.",
      },
    ],
  };

  return (
    <AppLayout pageHead={head} className={Styles.body}>
      <div className={Styles.container}>
        <p className={Styles.headline}>{'Page Not Found'}</p>
        <p className={Styles.subheadline}>{"The page you're looking for no longer exists."}</p>
        <Button
          className={Styles.button}
          type={Button.Type.BLUE}
          onClick={() => history.push(`/`)}
          title='Back Dashboard'>{`Back to Dashboard`}</Button>
      </div>
    </AppLayout>
  );
};

export default Error;
