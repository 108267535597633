import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import classNames from 'classnames';

const FormLayoutRow = ({ children, noBottomMargin, ...props }) => (
  <div className={classNames(Styles.containerRow, noBottomMargin && Styles.noBottom)} {...props}>
    {children}
  </div>
);

FormLayoutRow.propTypes = {
  children: PropTypes.node,
};

export default FormLayoutRow;
