import { Fragment, memo } from 'react';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { ContentType } from '@hiredigital/lib/helpers/enum';
import TextHistory from './TextHistory';
import { Tooltip } from 'evergreen-ui/esm/tooltip';
import Position from 'evergreen-ui/esm/constants/src/Position';
import Styles from './FileItems.module.scss';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the files look a bit nicer
  userSelect: 'none',
  // change background colour if dragging
  background: isDragging ? '#d2e5f8' : undefined,
  // styles we need to apply on draggables
  ...draggableStyle,
});

// eslint-disable-next-line react/display-name
const InnerRow = memo(({ cell, isCollapse }) => {
  return (
    <div className={classNames(Styles.cell, Styles[cell.render('id')])} {...cell.getCellProps()}>
      {isCollapse ? (
        <Tooltip
          content={cell?.row?.original?.title || 'Untitled Content'}
          position={Position.RIGHT}>
          <>{cell.render('Cell')}</>
        </Tooltip>
      ) : (
        <>{cell.render('Cell')}</>
      )}
    </div>
  );
});

const Row = ({
  row,
  index,
  projectUuid,
  onSelectHistory,
  selectedHistoryId,
  onItemSelect,
  className,
  isCollapse,
  ...props
}) => {
  return (
    <Draggable index={index} key={index} draggableId={`${index}`}>
      {(provided, snapshot) => (
        <Fragment>
          <div
            className={classNames(Styles.row, className)}
            ref={provided.innerRef}
            {...props}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            {...row.getRowProps({ onClick: () => onItemSelect?.(row?.original) })}>
            {row.cells.map((cell, index) => (
              <InnerRow key={index} cell={cell} isCollapse={isCollapse} />
            ))}
          </div>
          {row?.original?.type === ContentType.TEXT.id && row.isExpanded && (
            <TextHistory row={row} projectUuid={projectUuid} />
          )}
        </Fragment>
      )}
    </Draggable>
  );
};

export default memo(Row);
