/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import Styles from './NewProject.module.scss';

import Select from '@hiredigital/ui/Input/Select';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';

import MainContainer from '../StagesLayout/MainContainer';
import FormLayout from '../StagesLayout/FormLayout';
import ActionBar from '../StagesLayout/ActionBar';
import DeadlineChecklist from '@hiredigital/ui/DeadlineChecklist';
import Quill from '@hiredigital/ui/Quill/Editor';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import classNames from 'classnames';

import IconCheck from '@hiredigital/ui/Icon/icons/check-normal.inline.svg';

import Callout from '@components/Callout';
import { formatWithComma } from '@hiredigital/lib/helpers/utils';

import { useHistory, useParams } from 'react-router-dom';
import {
  postConvertProjectToBrief,
  postProjectStart,
  getProjectFiles,
  postProjectCopyReference,
  getProject,
  postProjectMember,
  postProjectAddTalent,
  deleteProjectMember,
  putProjectMember,
  deleteProject,
  postProjectContent,
  deleteProjectContent,
  patchProject,
} from '@apis/projects';
import { postAssignmentRehire } from '@apis/assignments';
import { useUser } from '@context/user';
import { useTeam } from '@context/team';
import { authRequest } from '@apis/utils';
import References from '@hiredigital/ui/Form/References';
import convert from 'htmr';
import { onCurrentProjectsLoadOptions } from '@apis/dropdown';
import TalentSelector from '../TalentSelector/List';

import SplitButton from '@hiredigital/ui/Button/SplitButton';
import TalentItem from '../TalentSelector/Item';

import { ProjectDeadlineApiManager } from '@hiredigital/lib/apis/class/deadlines';
import { ProjectMemberStatus } from '@hiredigital/lib/helpers/enum';
import debounce from 'lodash/debounce';

const NewProject = ({ onBack, project, onAfterSubmit }) => {
  const titleRef = useRef();
  const history = useHistory();
  const params = useParams();
  const user = useUser();
  const team = useTeam();
  const getUuid = (v) => v?.team?.uuid || v?.user?.uuid;

  const [title, setTitle] = useState(project?.title);
  const [links, setLinks] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [requirements, setRequirements] = useState(project?.requirements);
  const [isStarting, setIsStarting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isCopyingReq, setIsCopyingReq] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [isReferenceLoaded, setIsReferenceLoaded] = useState(false);
  const [isDialogReplaceShown, setIsDialogReplaceShown] = useState(false);
  const [selectedTalents, setSelectedTalents] = useState([...project?.members]);
  const [isShownRehire, setIsShownRehire] = useState(false);
  const [isRehiring, setIsRehiring] = useState(false);
  const [lastTalentSelected, setLastTalentSelected] = useState(null);
  const [updatedTalent, setUpdatedTalent] = useState(null);
  const [isShownDelete, setIsShownDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [titleError, setTitleError] = useState(false);
  const [isMembersShown, setIsMemberShown] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [selectedCopy, setSelectedCopy] = useState({});
  // added a fallback to get the org uuid
  // project may be empty at this point because getting project may fail in SSR for some reasons
  // like in the case where client has no active assignment(/project/uuid returns permission error)
  const [projectOrgUuid] = useState(project?.organization?.uuid || params?.orgUuid);

  // let forConfirmRequirements = null;
  // const projectOrgUuid = project?.organization?.uuid;

  useEffect(() => {
    loadProjectContents(project?.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (user?.uuid || team?.uuid) {
  //     setSelectedTalents([...project?.members]);
  //   }
  // }, [user, team, project?.members]);

  useEffect(() => {
    if (!isMounted) return;

    if (title) {
      setTitleError(false);
    }

    setIsSaving(true);
    autoSave({ title, requirements });
  }, [title, requirements]);

  useEffect(() => {
    loadProjectContents(project?.uuid);
    setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const autoSave = useCallback(
    debounce((data) => {
      setIsSaving(true);
      patchProject(project?.uuid, data)
        .then(() => {
          setIsSaving(false);
          setIsSaved(true);
        })
        .catch((error) => {
          setIsSaving(false);
        });
    }, 750),
    []
  );

  const loadProjectContents = (uuid, page = 1, reset = true) => {
    const params = { isReference: true, page };
    setIsReferenceLoaded(false);
    setIsLoadingMore(true);
    getProjectFiles(uuid, { params })
      .then(({ data }) => {
        const itemLinks = data?.results.filter((v) => !!v?.url);
        const itemAttachments = data?.results.filter((v) => !!v?.attachment);

        setLinks(reset ? itemLinks : [...links, ...itemLinks]);
        setAttachments(reset ? itemAttachments : [...attachments, ...itemAttachments]);

        setNextPage(data?.meta?.nextPage);
        setIsReferenceLoaded(true);
        setIsLoadingMore(false);
      })
      .catch((error) => {
        setNextPage(null);
        setIsLoadingMore(false);
        console.warn('Error while loading project content!');
      });
  };

  const onOrgProjectsLoadOptions = async (search, loadedOptions, { page }) => {
    const data = { params: { search, page, limit: 20 } };
    const url = `orgs/${projectOrgUuid}/projects/`;
    const response = await authRequest.get(url, data);
    return {
      options: response.data.results,
      hasMore: response.data.meta.nextPage !== null,
      additional: {
        page: response.data.meta.nextPage,
      },
    };
  };

  const validateRequiredFields = () => {
    if (!title) {
      titleRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setTitleError(true);
      return false;
    } else {
      setTitleError(false);
      return true;
    }
  };

  const handleStartProject = () => {
    if (!validateRequiredFields()) return;

    if (selectedTalents?.filter((t) => t.assignment !== null).length > 0) {
      setIsMemberShown(true);
      return;
    }

    startProject();
  };

  const startProject = () => {
    setIsStarting(true);
    const payload = {
      title,
      requirements,
    };
    postProjectStart(project?.uuid, payload)
      .then(({ data }) => {
        setIsStarting(false);
        setIsConfirmLoading(false);
        onAfterSubmit?.(data);
        history.push(`/projects/${project?.uuid}`);
      })
      .catch((error) => {
        setIsStarting(false);
        setIsConfirmLoading(false);
      });
  };

  const handleConfirmNewTalent = () => {
    setIsConfirmLoading(true);
    postConvertProjectToBrief(project?.uuid)
      .then(({ data }) => {
        setIsConfirmLoading(false);
        setIsDialogShown(false);
        history.push(`/briefs/${data?.uuid}`);
      })
      .catch((error) => {
        setIsConfirmLoading(false);
      });
  };

  const handleCopyExisting = async ({ uuid }) => {
    try {
      const { data } = await getProject(uuid);
      if (!title) {
        setTitle(data?.title);
      }

      setSelectedCopy(data);
      if (!requirements) {
        setIsCopyingReq(true);
        setRequirements(data?.requirements);
      } else {
        setIsDialogReplaceShown(true);
      }

      await postProjectCopyReference(project?.uuid, { existing: uuid });
      loadProjectContents(project?.uuid);
    } catch (e) {
      console.warn(e);
    } finally {
      setIsCopyingReq(false);
    }
  };

  const handleConfirmReplace = () => {
    setIsCopyingReq(true);
    setIsDialogReplaceShown(false);
    setTitle(selectedCopy?.title);
    setTimeout(() => {
      setIsCopyingReq(false);
      setRequirements(selectedCopy?.requirements);
    }, 350);
  };

  const handleConfirmDelete = () => {
    setIsDeleting(true);
    deleteProject(project?.uuid)
      .then(() => {
        setIsDeleting(false);
        setIsShownDelete(false);
        history.push(`/projects`);
      })
      .catch((error) => {
        console.error(error);
        setIsDeleting(false);
        setIsShownDelete(false);
      });
  };

  const handleLoreMore = () => loadProjectContents(project?.uuid, nextPage, false);

  const handleTalentSelect = (talent) => {
    // set the selected talent in advance to highlight the talent card in ui with no delay while still posting to server
    selectTalent(talent);
    addMember(talent, { uuid: talent?.assignmentUuid }, (member) => {
      selectTalent({ ...talent, ...member }); // merge other data to selected talent such as (member id, assignment, member status)
    });
  };

  const selectTalent = (talent) => {
    setLastTalentSelected(talent);
    if (!talent?.isActive) return;
    const found = selectedTalents.some((v) => getUuid(v) === getUuid(talent));
    if (!found) {
      setSelectedTalents([...selectedTalents, talent]);
    }
  };

  const handleTalentDeselect = (talent) => {
    setSelectedTalents(selectedTalents.filter((v) => getUuid(v) !== getUuid(talent)));
    removeMember(talent);
  };

  const removeMember = (talent) => {
    // const memberId = project?.members?.find((v) => getUuid(v) === getUuid(talent))?.id;
    // if (!memberId) {
    //   console.warn('Member id not found in project members');
    //   return;
    // }
    const memberId = selectedTalents?.find((v) => getUuid(v) === getUuid(talent))?.id;
    setIsSaving(true);
    deleteProjectMember(project?.uuid, memberId)
      .then(() => {
        setTimeout(() => {
          setIsSaving(false);
          setIsSaved(true);
        }, 250);
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  const postProjectTalent = (talent, assignment, completion) => {
    const payload = { assignment: assignment?.uuid };
    if (talent?.team) payload.talent = talent?.uuid;
    if (talent?.user) payload.user = talent?.user?.uuid;

    const request = talent?.team ? postProjectAddTalent : postProjectMember;

    // setIsStarting(true);
    setIsSaving(true);
    request(project?.uuid, payload)
      .then(({ data }) => {
        completion?.(data);
        setTimeout(() => {
          setIsSaving(false);
          setIsSaved(true);
        }, 250);
      })
      .catch((error) => {
        console.warn(error);
        setIsSaving(false);
      });
  };

  const addMember = (talent, assignment, completion) => {
    if (!talent?.isActive) {
      setIsShownRehire(true);
      return;
    }
    postProjectTalent(talent, assignment, completion);
  };

  const handleRehire = () => {
    const assignUuid = lastTalentSelected?.assignmentUuid;
    setIsRehiring(true);
    Promise.all([
      postProjectTalent(lastTalentSelected, { uuid: assignUuid }),
      postAssignmentRehire(assignUuid),
    ])
      .then(() => {
        setIsShownRehire(false);
        setIsRehiring(false);
        const newTalent = { ...lastTalentSelected, isActive: true };
        selectTalent(newTalent);
        setUpdatedTalent(newTalent);
      })
      .catch((error) => {
        setIsShownRehire(false);
        setIsRehiring(false);
      });
  };

  const handleAssignmentSelect = (assignment, talent) => {
    const memberId = project?.members?.find((v) => getUuid(v) === getUuid(talent))?.id;
    if (!memberId) {
      console.warn('Member id not found in project memers');
      return;
    }
    setIsSaving(true);
    const payload = { assignment: assignment?.uuid };
    putProjectMember(project?.uuid, memberId, payload)
      .then(() => {
        setTimeout(() => {
          setIsSaving(false);
          setIsSaved(true);
        }, 250);
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  const handleConfirmMember = () => {
    setIsConfirmLoading(true);
    startProject();
  };

  const invitedMembers =
    selectedTalents
      .filter((t) => t?.status === ProjectMemberStatus.INVITED.id)
      .map((v) => v?.user?.name) || [];

  return (
    <MainContainer
      title={`Start a New Project`}
      subtitle={`Work with talents and teams you've hired through Hire Digital.`}
      className={Styles.projectMainContainer}>
      <Dialog
        isOpen={isMembersShown}
        title={`Confirm Project Team`}
        onClose={() => setIsMemberShown(false)}
        width={500}>
        {`Please confirm that you would like to start the project with the following talents:`}
        {selectedTalents?.length > 0 && (
          <div className={Styles.selectedTalentsContainer}>
            {selectedTalents
              .filter((t) => t.assignment !== null)
              .map((v, index) => (
                <TalentItem
                  item={v}
                  key={index}
                  className={Styles.talentItem}
                  showAssignments={false}
                />
              ))}
          </div>
        )}

        {invitedMembers?.length > 0 && (
          <Callout>
            {'Since '}
            {formatWithComma(invitedMembers)}
            {invitedMembers.length === 1 ? ' is ' : ' are '}
            {`currently inactive, we will confirm `}
            {'availability with them before assigning it directly to them. '}
            {'Please plan accordingly.'}
          </Callout>
        )}

        <div style={{ marginTop: '20px' }}>
          <Button
            type={Button.Type.BLUE}
            onClick={handleConfirmMember}
            isLoading={isConfirmLoading}>
            {'Confirm'}
          </Button>
          <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsMemberShown(false)}>
            {'Cancel'}
          </Button>
        </div>
      </Dialog>

      <Dialog
        isOpen={isShownDelete}
        title='Delete Project'
        onClose={() => setIsShownDelete(false)}
        width={500}>
        <div style={{ marginBottom: 16 }}>{`Are you sure you want to delete this project?`}</div>
        <div style={{ marginTop: '20px' }}>
          {' '}
          <Button type={Button.Type.BLUE} onClick={handleConfirmDelete} isLoading={isDeleting}>
            {'Confirm'}
          </Button>
          <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsShownDelete(false)}>
            {'Cancel'}
          </Button>
        </div>
      </Dialog>

      <Dialog
        isOpen={isDialogShown}
        title='New Talent Brief'
        onClose={() => setIsDialogShown(false)}
        width={500}>
        {`Confirm that you would like to start a new talent brief. Your project details will be saved.`}
        <div style={{ marginTop: '20px' }}>
          <Button
            type={Button.Type.BLUE}
            onClick={handleConfirmNewTalent}
            isLoading={isConfirmLoading}>
            {'Confirm'}
          </Button>
          <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsDialogShown(false)}>
            {'Cancel'}
          </Button>
        </div>
      </Dialog>

      <Dialog
        isOpen={isShownRehire}
        title={`Rehire Talent?`}
        onClose={() => setIsShownRehire(false)}
        width={500}>
        <p>
          {`If you would like to work with `}
          <b>{lastTalentSelected?.team?.name || lastTalentSelected?.user?.name}</b>
          {`, you can request to Rehire them. We will check on the talent's availability and confirm with you shortly.`}
        </p>
        <p style={{ margin: '15px 0' }}>{`Meanwhile, you can continue to create your project.`}</p>

        <div style={{ marginTop: '20px' }}>
          <Button type={Button.Type.BLUE} onClick={handleRehire} isLoading={isRehiring}>
            {'Rehire Talent'}
          </Button>
          <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsShownRehire(false)}>
            {'Cancel'}
          </Button>
        </div>
      </Dialog>

      <Dialog
        isOpen={isDialogReplaceShown}
        title='Replace Requirements'
        onClose={() => setIsDialogReplaceShown(false)}
        width={500}>
        <p
          style={{
            marginBottom: 16,
          }}>{`Your current project requirements text will be replaced with the copy from your other project.`}</p>
        {requirements && selectedCopy?.requirements && (
          <div className={Styles.confirmRequirements}>
            {selectedCopy?.requirements ? convert(selectedCopy?.requirements) : ''}
          </div>
        )}
        <div style={{ marginTop: '20px' }}>
          <Button
            type={Button.Type.BLUE}
            onClick={handleConfirmReplace}
            isLoading={isConfirmLoading}>
            {'Replace'}
          </Button>
          <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsDialogReplaceShown(false)}>
            {'Cancel'}
          </Button>
        </div>
      </Dialog>

      <FormLayout title={`1. Project Requirements`} style={{ marginBottom: '30px' }}>
        <div className={Styles.row}>
          <div className={Styles.largeArea}>
            <TextInput
              ref={titleRef}
              label={`Title`}
              name={`title`}
              placeholder={`Give your project a descriptive title`}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={titleError && 'Please add a title for your project.'}
            />
          </div>
          <div className={Styles.smallArea}>
            {user?.isClient && (
              <Fragment>
                <Select
                  label={`Projects`}
                  name={`project`}
                  placeholder={`Copy from existing projects`}
                  getOptionLabel={({ title }) => title || 'Untitled Project'}
                  getOptionValue={({ uuid }) => uuid}
                  loadOptions={onOrgProjectsLoadOptions}
                  onChange={handleCopyExisting}
                  isPaginated
                />
              </Fragment>
            )}

            {user?.isTalent && (
              <Select
                label={`Projects`}
                name={`project`}
                placeholder={`Copy from existing projects`}
                getOptionLabel={({ title }) => title || 'Untitled Project'}
                getOptionValue={({ uuid }) => uuid}
                loadOptions={onCurrentProjectsLoadOptions}
                onChange={handleCopyExisting}
                isPaginated
              />
            )}
          </div>
        </div>
        <InputGroup>
          <InputContainer>
            {isCopyingReq ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  minHeight: '135px',
                }}>
                <Loader color={Loader.Color.BLUE} size={Loader.Size.SMALL} />
              </div>
            ) : (
              <Quill
                legacyCompat
                name='requirements'
                label='Requirements'
                placeholder={`Add any specific requirements for your project. If you have an existing brief, you can copy it here or include it as a reference below.`}
                onChange={(e) => setRequirements(e.target.value)}
                defaultValue={requirements}
              />
            )}
          </InputContainer>
        </InputGroup>
        {isReferenceLoaded ? (
          <References
            attachments={attachments}
            links={links}
            postLinkRequest={(data, config) => {
              const payload = { ...data, type: 1, isReference: true };
              return postProjectContent(project?.uuid, payload, config);
            }}
            postFileRequest={(data, config) => {
              data.append('type', 2);
              data.append('isReference', true);
              return postProjectContent(project?.uuid, data, config);
            }}
            deleteLinkRequest={(id) => deleteProjectContent(project?.uuid, id)}
            deleteFileRequest={(id) => deleteProjectContent(project?.uuid, id)}
            isLoadingMore={isLoadingMore}
            onLoadMore={handleLoreMore}
            hasMore={!!nextPage}
          />
        ) : (
          <Loader color={Loader.Color.WHITE} size={Loader.Size.SMALL} />
        )}
      </FormLayout>
      <FormLayout
        title={`2. Select Talents`}
        style={{ marginBottom: '50px' }}
        subtitle={() => (
          <Fragment>
            <span style={{ marginRight: '5px' }}>
              {`You can select any talent that you have an active engagement with.`}
            </span>
            {user?.isClient && (
              <button className={Styles.newTalentLink} onClick={() => setIsDialogShown(true)}>
                {`Click here if you need a new talent.`}
              </button>
            )}
          </Fragment>
        )}>
        {projectOrgUuid && (
          <TalentSelector
            orgUuid={projectOrgUuid}
            project={project}
            onItemSelect={handleTalentSelect}
            onItemDeselect={handleTalentDeselect}
            onAssignmentSelect={handleAssignmentSelect}
            selected={selectedTalents?.map(getUuid) || []}
            updatedTalent={updatedTalent}
            currentUser={user}
          />
        )}
      </FormLayout>
      <FormLayout
        title={() => (
          <Fragment>
            <div className={Styles.titleRow}>
              <span className={Styles.title}>{`3. Tasks & Deadlines`}</span>
              <DeadlineChecklist
                projectUuid={project?.uuid}
                initialData={project?.deadlines}
                hasTitle={false}
                initialLoad={false}
                apiManager={new ProjectDeadlineApiManager(authRequest)}
              />
            </div>
          </Fragment>
        )}></FormLayout>
      <ActionBar isWhite>
        <div className={Styles.actionContainer}>
          <Button
            name='submit'
            type={Button.Type.BLUE}
            onClick={handleStartProject}
            isLoading={isStarting}>
            {`Start Project`}
          </Button>
          <div className={Styles.actionRight}>
            <span className={classNames(Styles.success, !isSaving && isSaved && Styles.visible)}>
              <IconCheck className={Styles.icon} />
              {'Saved'}
            </span>

            <span className={classNames(Styles.loaderArea, isSaving && Styles.visible)}>
              <Loader size={Loader.Size.SMALL} />
            </span>

            <SplitButton
              direction='top'
              more={[{ text: 'Delete', onClick: () => setIsShownDelete(true) }]}
            />
          </div>
        </div>
      </ActionBar>
    </MainContainer>
  );
};

NewProject.propTypes = {};

export default NewProject;
