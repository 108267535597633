import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import Button from '@hiredigital/ui/Button';

import { useUser } from '@context/user';
import { useCommon } from '@context/common';
import Styles from './Availability.module.scss';
import Section from '@components/StagesLayout/Section';
import FormLayout from '@components/StagesLayout/FormLayout';
import ServiceRateList from '@components/ServiceRate/ServiceRateList';
import WeeklyBusySchedule from '@hiredigital/ui/DailyBusySchedule/Weekly';
import MonthlyBusySchedule from '@hiredigital/ui/BusySchedule/Monthly';
import TalentRateList from '@hiredigital/ui/TalentRateList';
import { RatesApiManager } from '@hiredigital/lib/apis/class/rates';
import { authRequest } from '@apis/utils';
import {
  getUserDailyBusySchedule,
  postUserDailyBusyScheduleSet,
  getUserMonthlyBusySchedule,
  postUserMonthlyBusyScheduleSet,
} from '@apis/users';

import ActionBar from '../StagesLayout/ActionBar';

const Availability = ({ isOnboarding = false }) => {
  const user = useUser();
  const common = useCommon();
  const history = useHistory();

  return (
    <Section
      title={`Rates & Availability`}
      subtitle={`Add in any details about the services you provide and when you're able to take on client work.`}
      className={classNames(Styles.availabilityContainer, isOnboarding && Styles.isOnboarding)}>
      <FormLayout>
        <FormLayout.Row>
          <FormLayout.MainColumn>
            {/^true$/i.test(process.env.NEW_RATES_FLAG) ? (
              <TalentRateList apiManager={new RatesApiManager(authRequest)} common={common} />
            ) : (
              <ServiceRateList />
            )}
          </FormLayout.MainColumn>
          {isOnboarding && <FormLayout.AsideColumn noBorder />}
        </FormLayout.Row>
        <FormLayout.Row>
          <FormLayout.MainColumn>
            <WeeklyBusySchedule
              getUserDailyBusySchedule={getUserDailyBusySchedule}
              postUserDailyBusyScheduleSet={(data) =>
                postUserDailyBusyScheduleSet(user?.uuid, data)
              }
              showNotes={!isOnboarding}
            />
          </FormLayout.MainColumn>
          {isOnboarding && (
            <FormLayout.AsideColumn className={Styles.alignTopText}>
              <p>
                {`Let us know you're generally available on a week-to-week basis. We'll use `}
                {`this information to better plan calls, discussions and schedules with clients.`}
              </p>
            </FormLayout.AsideColumn>
          )}
        </FormLayout.Row>
        <FormLayout.Row>
          <FormLayout.MainColumn>
            <MonthlyBusySchedule
              user={user}
              isAdmin={false}
              getUserMonthlyBusySchedule={getUserMonthlyBusySchedule}
              postUserMonthlyBusyScheduleSet={postUserMonthlyBusyScheduleSet}
              showNotes={!isOnboarding}
            />
          </FormLayout.MainColumn>
          {isOnboarding && (
            <FormLayout.AsideColumn className={Styles.alignTopText}>
              <p>
                {`If you know your schedule for the coming months, fill in the days you will be free to take `}
                {`on work. We'll use this information to plan engagement lengths with clients. We'll remind `}
                {`you periodically to update this information.`}
              </p>
            </FormLayout.AsideColumn>
          )}
        </FormLayout.Row>
      </FormLayout>
      {isOnboarding && (
        <ActionBar>
          <Button type={Button.Type.BLUE_OUTLINE} onClick={() => history.push('/guide')}>
            {`Return to Onboarding`}
          </Button>
        </ActionBar>
      )}
    </Section>
  );
};

export default Availability;
