/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from 'react';
import Styles from './FileSideBar.module.scss';
import classNames from 'classnames';
import FileItems from './FileItems';
// import Position from 'evergreen-ui/esm/constants/src/Position';
// import { Menu } from 'evergreen-ui/esm/menu';
import { ContentType } from '@hiredigital/lib/helpers/enum';
import { useProjects } from '@context/projects';
import Menu from '@hiredigital/ui/Menu/Menu';
import IconAngle from '@hiredigital/ui/Icon/icons/angle.inline.svg';
import useLocalStorage from '@hooks/useLocalStorage';

const FileSideBar = ({ project, onItemSelect, className, listItemClass }) => {
  const { addFile } = useProjects();
  const [isCollapseStoreValue, setIsCollapseStoreValue] = useLocalStorage(
    'isProjectSidebarCollapse'
  );

  const [isCollapse, setIsCollapse] = useState(Boolean(isCollapseStoreValue));
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    setIsCollapseStoreValue(isCollapse);
  }, [isCollapse]);

  return (
    <div className={classNames(Styles.fileList, isCollapse && Styles.collapse, className)}>
      <div className={Styles.fileAction}>
        <Menu>
          <Menu.Button className={Styles.actionButton}>
            <svg className={Styles.iconPlus} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'>
              <path
                id='add-thin'
                fill='none'
                stroke='currentColor'
                strokeWidth='4'
                d='M26.99 15H3.4995M15 3.25475v23.4905'
                strokeLinecap='round'
                strokeLinejoin='round'></path>
            </svg>
            {/* {!isCollapse && <span style={{ marginLeft: '10px' }}>{`Add File`}</span>} */}
            <span className={Styles.addText}>{'Add File'}</span>
          </Menu.Button>
          <Menu.Items as='div' direction='right' className={Styles.menuItems}>
            <Menu.Item
              as='div'
              className={Styles.menuItem}
              onClick={() => addFile(project?.uuid, { type: ContentType.LINK.id })}>
              {'Link'}
            </Menu.Item>
            <Menu.Item
              as='div'
              className={Styles.menuItem}
              onClick={() => addFile(project?.uuid, { type: ContentType.ATTACHMENT.id })}>
              {'Attachment'}
            </Menu.Item>
            {project?.organization?.viewEditor && (
              <Menu.Item
                as='div'
                className={Styles.menuItem}
                onClick={() => addFile(project?.uuid, { type: ContentType.TEXT.id })}>
                {'Text'}
              </Menu.Item>
            )}
          </Menu.Items>
        </Menu>
        <button onClick={() => setShowSearch(!showSearch)} className={Styles.actionSearch}>
          <svg
            className={Styles.iconSearch}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 30 30'
            role='button'
            tabIndex='0'>
            <path
              id='search'
              fill='currentColor'
              fillRule='nonzero'
              d='M23.1 20.69a1.19 1.19 0 0 1-1.68 1.68l-4.1-4.1A6 6 0 1 1 19 16.6l4.1 4.1zM18 13.28a4 4 0 1 0-8 0 4 4 0 0 0 8 0z'></path>
          </svg>
        </button>
      </div>
      <FileItems
        project={project}
        onItemSelect={onItemSelect}
        showSearch={showSearch}
        isCollapse={isCollapse}
        toggleCollapse={() => setIsCollapse((v) => !v)}
        listItemClass={listItemClass}
      />
      <div className={Styles.actionBottom}>
        <button onClick={() => setIsCollapse((v) => !v)} className={Styles.actionButton}>
          <IconAngle
            className={classNames(Styles.iconAngle, isCollapse ? Styles.right : Styles.left)}
          />
          {!isCollapse && <span className={Styles.collapseText}>{`Collapse`}</span>}
        </button>
      </div>
    </div>
  );
};

export default memo(FileSideBar);
