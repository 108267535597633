/* eslint-disable jsx-a11y/img-redundant-alt */
import PropTypes from 'prop-types';
import Styles from './GroupPicture.module.scss';
import classNames from 'classnames';

const GroupPicture = ({ images }) => {
  const items = images?.filter((v) => !!v) || [];
  return (
    <div className={Styles.container}>
      <div
        className={classNames(
          Styles.grid,
          items.length === 1 && Styles.single,
          items.length === 2 && Styles.double,
          items.length >= 3 && Styles.quad
        )}>
        {items
          .slice(0, 4)
          .map((v) => v && <img alt='Group Picture' src={v} className={Styles.image} />)}
      </div>
    </div>
  );
};

GroupPicture.propTypes = {
  children: PropTypes.node,
};

export default GroupPicture;
