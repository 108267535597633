import { useState } from 'react';
import classNames from 'classnames';
import Badge from '@hiredigital/ui/Badge/Badge';
import Styles from './Styles.module.scss';

const ManagingOrgList = ({ orgList, onSelect }) => {
  const [selected, setSelected] = useState([]);

  const handleSelect = (idx, item) => {
    setSelected([idx]);
    onSelect?.(item);
  };

  return (
    <div className={Styles.list}>
      {orgList?.map((item, idx) => (
        <button
          key={item?.uuid}
          disabled={!item?.isActive}
          className={classNames(Styles.item, selected.includes(idx) && Styles.selected)}
          onClick={() => handleSelect(idx, item)}>
          <img
            alt={item?.organization?.name}
            className={Styles.picture}
            src={item?.organization?.picture}
          />
          <span>{item?.organization?.name}</span>
          {!item?.isActive && <Badge color='neutral' marginLeft='auto'>{`Inactive`}</Badge>}
        </button>
      ))}
    </div>
  );
};

export default ManagingOrgList;
