import Styles from './EditorStatusBar.module.scss';
import IconExclamation from '@hiredigital/ui/Icon/icons/exclamation.inline.svg';
import classNames from 'classnames';
import StatusText from './StatusText';

const EditorStatusBar = ({
  wordCount,
  savingText = 'Saving changes...',
  savedText,
  isSaving,
  isOnline = true,
}) => {
  return (
    <div className={Styles.container}>
      <span className={classNames(Styles.statusText, Styles.alignLeft)}>{`${wordCount} word${
        wordCount > 1 ? `s` : ``
      }`}</span>
      {!isOnline ? (
        <span className={classNames(Styles.statusText, Styles.alignRight)}>
          <IconExclamation className={Styles.iconOffline} />
          {`Connect to the internet`}
        </span>
      ) : (
        <StatusText {...{ isSaving, savingText, savedText }} />
      )}
    </div>
  );
};

export default EditorStatusBar;
