import classNames from 'classnames';
// import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';

const InputGroup = ({ className, children, mobileOneColumn, ...props }) => {
  return (
    <div
      className={classNames(
        Styles.inputGroup,
        mobileOneColumn && Styles.mobileOneColumn,
        className
      )}
      {...props}>
      {children}
    </div>
  );
};

export default InputGroup;
