import { Fragment, useState } from 'react';

import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';

import Styles from './LinkViewer.module.scss';
import AttachmentViewer from './AttachmentViewer';
import FormArea from './FormArea';
import { getOEmbed } from '@apis/common';
import { useProjects } from '@context/projects';

const LinkViewer = ({ projectUuid, content }) => {
  const { updateFile } = useProjects();
  const [newUrl, setNewUrl] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      handleFetchLink();
    }
  };

  const handleFetchLink = async () => {
    setIsFetching(true);

    try {
      const { data } = await getOEmbed({ url: newUrl });
      const payload = {
        title: data?.title,
        content: data?.description,
        url: data?.url,
      };
      updateFile(projectUuid, content?.id, payload);
      setIsFetching(false);
    } catch (error) {
      console.warn(error);
      setIsFetching(false);
    }
  };

  return content.url ? (
    <AttachmentViewer projectUuid={projectUuid} content={content} />
  ) : (
    <Fragment>
      <FormArea title={`Add a Link`} description={`Enter your URL above to import your link.`}>
        <div className={Styles.inputContainer}>
          <TextInput
            name={`url`}
            label={`Enter a URL`}
            onChange={(e) => setNewUrl(e.target.value)}
            onKeyDown={handleEnterPress}
          />
          <Button
            onClick={handleFetchLink}
            className={Styles.actionFetch}
            type={Button.Type.BLUE}
            isLoading={isFetching}>
            {`Fetch Link`}
          </Button>
        </div>
      </FormArea>
    </Fragment>
  );
};

export default LinkViewer;
