import { Fragment, useState } from 'react';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Select from '@hiredigital/ui/Input/Select';
import Button from '@hiredigital/ui/Button/Button';

// import { Dialog } from 'evergreen-ui/esm/dialog';
import Dialog from '@hiredigital/ui/Dialog/Dialog';

import { putEngagement, deleteEngagement } from '@apis/engagements';
import { onSkillsTreeLoadOptions } from '@apis/dropdown';
import { useCommon } from '@context/common';
import OverviewHeader from './OverviewHeader';
import OverviewItem from './OverviewItem';
import OverviewContent from './OverviewContent';
import OverviewBody from './OverviewBody';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import addDays from 'date-fns/addDays';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

const EngagementForm = ({ engagement, onAfterSave, onAfterDelete, enableEdit }) => {
  const common = useCommon();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [languages, setLanguages] = useState(engagement?.languages);
  const [countries, setCountries] = useState(engagement?.countries);
  const [skills, setSkills] = useState(engagement?.skills);
  const [estimatedEndDate, setEstimatedEndDate] = useState(engagement?.estimatedEndDate);
  const [estimatedStartDate, setEstimatedStartDate] = useState(engagement?.estimatedStartDate);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const formatTimeLine = (e) => {
    const start = `${e?.estimatedStartDate ? `${toReadableDate(e?.estimatedStartDate)} - ` : ''}`;
    const end = `${e?.estimatedEndDate ? toReadableDate(e?.estimatedEndDate) : 'Ongoing'}`;
    return `${start}${end}`;
  };

  const getEngagementTitle = (engagement) => {
    return (
      engagement?.talentType?.label ||
      engagement?.otherTalentType ||
      (engagement?.formatQty === 1
        ? engagement?.format?.label || engagement?.otherFormat
        : engagement?.formatQty +
          ' ' +
          (engagement?.format?.pluralLabel || engagement?.otherFormat))
    );
  };

  const handleSave = () => {
    setIsSaving(true);
    const payload = { countries, languages, skills, estimatedEndDate, estimatedStartDate };
    putEngagement(engagement.uuid, payload)
      .then(({ data }) => {
        setIsSaving(false);
        setIsEditing(false);
        onAfterSave?.(data);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  const handleDeleteConfirm = () => {
    setIsConfirmLoading(true);
    deleteEngagement(engagement.uuid)
      .then(() => {
        setIsConfirmLoading(false);
        setIsShown(false);
        onAfterDelete?.(engagement);
      })
      .catch((error) => {
        setIsConfirmLoading(false);
      });
  };

  const formatItems = (items, key) =>
    !!items?.length ? items?.map((v) => v[key || 'label'])?.join(', ') : 'No preference';

  return (
    <Fragment>
      <Dialog
        isOpen={isShown}
        width={350}
        title='Delete Engagement'
        onClose={() => setIsShown(false)}>
        {`Are you sure you want to delete this Infographic?`}
        <div style={{ marginTop: '30px' }}>
          <Button
            onClick={handleDeleteConfirm}
            type={Button.Type.BLUE}
            isLoading={isConfirmLoading}>
            {`Confirm`}
          </Button>
          <Button onClick={() => setIsShown(false)} type={Button.Type.LIGHT_GRAY}>
            {`Cancel`}
          </Button>
        </div>
      </Dialog>
      <hr />
      <OverviewContent>
        <OverviewHeader
          title={getEngagementTitle(engagement)}
          onEdit={() => setIsEditing(true)}
          onCancel={() => setIsEditing(false)}
          onSave={handleSave}
          onDelete={() => setIsShown(true)}
          isEditing={isEditing}
          isSaving={isSaving}
          enableEdit={enableEdit}
        />
        {isEditing ? (
          <Fragment>
            <div style={{ marginTop: '30px' }}>
              <InputGroup>
                <InputContainer>
                  <Select
                    label={`Skills`}
                    name={`skills`}
                    placeholder={`Select or add new skills`}
                    onChange={(e) => setSkills(e?.target?.value || [])}
                    defaultValue={engagement?.skills || []}
                    loadOptions={onSkillsTreeLoadOptions}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ id }) => id}
                    hideSelectedOptions={false}
                    isMulti
                    isMultiLevel
                    isPaginated
                  />
                </InputContainer>
                <InputContainer>
                  <Select
                    label={`Country`}
                    name={`countries`}
                    onChange={(e) => setCountries(e?.target?.value || [])}
                    options={common?.countries}
                    defaultValue={engagement?.countries || []}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    isMulti
                  />
                </InputContainer>
              </InputGroup>
              <InputGroup>
                <InputContainer>
                  <Select
                    label={`Language`}
                    name={`languages`}
                    onChange={(e) => setLanguages(e?.target?.value || [])}
                    options={common?.languages}
                    defaultValue={engagement?.languages || []}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    isMulti
                  />
                </InputContainer>
                <InputContainer>
                  <InputGroup style={{ marginLeft: 0 }}>
                    <InputContainer>
                      <DatePicker
                        name='startDaate'
                        label='Start Date'
                        minDate={addDays(new Date(), 2)}
                        value={engagement?.estimatedStartDate}
                        onChange={setEstimatedStartDate}
                      />
                    </InputContainer>
                    <InputContainer>
                      <DatePicker
                        name='endDate'
                        label='End Date'
                        minDate={addDays(new Date(), 2)}
                        value={engagement?.estimatedEndDate}
                        onChange={setEstimatedEndDate}
                      />
                    </InputContainer>
                  </InputGroup>
                </InputContainer>
              </InputGroup>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <OverviewBody>
              <OverviewItem title={`Skills`} content={formatItems(engagement?.skills)} />
              <OverviewItem
                title={`Country`}
                content={formatItems(engagement?.countries, 'name')}
              />
              <OverviewItem
                title={`Language`}
                content={formatItems(engagement?.languages, 'name')}
              />
              <OverviewItem title={`Timeline`} content={formatTimeLine(engagement)} />
            </OverviewBody>
          </Fragment>
        )}
        <OverviewBody direction={`column`}>
          <OverviewItem title={`Additional Terms`} content={engagement?.clientTerms} isHtml />
        </OverviewBody>
      </OverviewContent>
    </Fragment>
  );
};

export default EngagementForm;
