import { useState, useEffect } from 'react';
import classNames from 'classnames';
import Ellipsis from '@hiredigital/ui/MultiLineEllipsis/SingleLine';
import CaretIcon from '@hiredigital/ui/Icon/icons/caret.inline.svg';
import Styles from './TreeView.module.scss';

const TreeItem = ({
  children,
  content,
  columns = [],
  isExpandable,
  onExpand,
  className,
  indent,
  indentValue = 30,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const hasValidChildren = Array.isArray(children)
    ? children?.some((v) => !!v)
    : !!Object.keys(children || {})?.length;

  const shouldShowExpand = typeof isExpandable === 'boolean' ? isExpandable : hasValidChildren;

  useEffect(() => {
    if (isExpanded) onExpand?.(isExpanded);
  }, [isExpanded]);

  return (
    <li className={classNames(Styles.item, className)} style={{ ...props }}>
      <div className={Styles.row} style={{ ...(indent && { paddingLeft: indentValue * indent }) }}>
        {shouldShowExpand && (
          <button className={Styles.btnExpand} onClick={() => setIsExpanded((p) => !p)}>
            <CaretIcon className={classNames(Styles.icon, !isExpanded && Styles.collapsed)} />
          </button>
        )}

        {content && <Ellipsis>{content}</Ellipsis>}
        {columns.map(({ content, truncate, elem: Elem = Ellipsis, className, ...props }, idx) => {
          return (
            <Elem key={idx} className={className} style={{ ...props }}>
              {typeof content === 'function' ? content() : content}
            </Elem>
          );
        })}
      </div>
      {hasValidChildren && <ul className={Styles.children}>{isExpanded && children}</ul>}
    </li>
  );
};

export default TreeItem;
