import { authRequest } from './utils';

const serializerServiceRate = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

export const getServiceRates = async (config = undefined) => {
  const url = `service-rates/`;
  return await authRequest.get(url, config);
};

export const putServiceRate = async (
  uuid,
  data,
  serialize = serializerServiceRate,
  config = undefined
) => {
  const url = `service-rates/${uuid}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const postServiceRate = async (
  data,
  serialize = serializerServiceRate,
  config = undefined
) => {
  const url = `service-rates/`;
  return await authRequest.post(url, serialize(data), config);
};

export const deleteServiceRate = async (uuid, config = undefined) => {
  const url = `service-rates/${uuid}/`;
  return await authRequest.delete(url, config);
};
