import { useEffect, useState } from 'react';
import Styles from './Styles.module.scss';
import Button from '@hiredigital/ui/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Select from '@hiredigital/ui/Input/Select';
import PhoneNumber from '@hiredigital/ui/PhoneNumber';

import FormLayout from '../StagesLayout/FormLayout';
import Section from '../StagesLayout/Section';
import Option from './components/option/Option';
import HelpOptions from './components/help/HelpOptions';
import {
  BriefType,
  CallStatus,
  BriefStatus,
  PreferredPlatform,
} from '@hiredigital/lib/helpers/enum';
import { patchBrief } from '@apis/briefs';
import { useCommon } from '@context/common';
import { useHistory } from 'react-router-dom';
// import { Dialog } from 'evergreen-ui/esm/dialog';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import { postCallDaysAvailable, putBriefCall } from '@apis/calls';
import { patchOrganization } from '@apis/organizations';
// import * as intercom from '../../lib/intercom';
import { postBriefSubmit } from '@apis/briefs';

import { useIntercom } from '@hiredigital/lib/providers/intercom';

const options = [
  {
    title: 'Tell us over a call',
    subtitle: `Schedule a call with Hire Digital`,
    id: BriefType.CALL.id,
    // eslint-disable-next-line react/display-name
    renderIcon: () => (
      <svg
        className={Styles.optionIcon}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 30 30'
        icon-phone=''>
        <path
          fill='currentColor'
          fillRule='nonzero'
          d='M12.87972 11.6137c.22154-.51881.28634-1.14663.16647-1.75235l-1.0371-4.10653c-.12552-.4963-.60558-.81767-1.11227-.74457l-5.0396.72704c-.51533.07434-.88738.53166-.85532 1.05133.29058 4.71 2.24642 9.16614 5.6197 12.57396 3.3982 3.4324 7.97297 5.39215 12.78779 5.63614.53163.02694.99118-.3673 1.0454-.89684l.54-5.27283c.05126-.50043-.27722-.96104-.76706-1.0756l-3.76787-.88122c-.66948-.1364-1.33454-.05596-1.92626.22297l-1.36208.58699c-1.0857-.71808-1.82022-1.30535-2.62538-2.11868-.83498-.84345-1.42372-1.59309-2.124-2.6878l.45758-1.26202zm9.68478 11.31482c-3.97585-.41072-7.70505-2.12814-10.52156-4.97299-2.80917-2.83793-4.52261-6.48356-4.96459-10.3737l3.2126-.46347.80479 3.1823c.0306.16169.00784.38223-.07677.58176l-.6436 1.77089a1 1 0 0 0 .08967.86694c.89356 1.4445 1.60862 2.3885 2.65977 3.45032 1.02737 1.0378 1.95854 1.75392 3.4115 2.6841a1 1 0 0 0 .93518.07605l1.88452-.81274c.2325-.10898.45884-.13636.67709-.09203l2.88248.67449-.35108 3.42808z'></path>
      </svg>
    ),
  },
  {
    title: 'Fill out a brief',
    subtitle: 'Add your own requirements',
    id: BriefType.BRIEF.id,
    // eslint-disable-next-line react/display-name
    renderIcon: () => (
      <svg
        className={Styles.optionIcon}
        viewBox='0 0 30 30'
        xmlns='http://www.w3.org/2000/svg'
        icon-assessment=''>
        <g fill='none' fillRule='evenodd' stroke='currentColor' strokeWidth='2'>
          <path d='M8.5 6A1.5 1.5 0 0 0 7 7.5v15A1.5 1.5 0 0 0 8.5 24h13a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 21.5 6h-13z'></path>
          <path d='M12 11h6M12 15h6M12 19h6' strokeLinecap='round'></path>
        </g>
      </svg>
    ),
  },
];

const BriefStart = ({ initialBrief, onAfterSubmit, briefUuid }) => {
  const history = useHistory();
  const common = useCommon();
  const intercom = useIntercom();

  const [isSaving, setIsSaving] = useState(false);
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [activeOption, setActiveOption] = useState([initialBrief?.briefType]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneCountry, setPhoneCountry] = useState(null);
  const [timezone, setTimezone] = useState(
    common?.timezones?.find((v) => v?.label === 'Asia/Singapore')
  );
  const [daysAvailable, setDaysAvailable] = useState([]);
  const [timesAvailable, setTimesAvailable] = useState([]);
  const [date, setDate] = useState(null);
  const [timeSelected, setTimeSelected] = useState({});
  const [errors, setErrors] = useState({});

  const mappedMethods = {
    phoneCountry: setPhoneCountry,
    phoneNumber: setPhoneNumber,
  };

  const handleContinue = () => {
    if (activeOption?.length === 0) {
      console.warn('No selected option!');
      return;
    }
    saveBriefType(activeOption?.[0]);
  };

  const handleOptionSelect = (id) => {
    setActiveOption([id]);
    saveBriefType(id);
  };

  const saveBriefType = (id) => {
    setIsSaving(true);
    const isSameBriefType = initialBrief?.briefType === id;
    const payload = { briefType: id };
    if (!isSameBriefType) {
      payload.stage = 1;
      payload.status = 1;
    }
    patchBrief(briefUuid, payload)
      .then(({ data }) => {
        const isDraft = initialBrief?.status === BriefStatus.DRAFT.id;
        if (isDraft || !isSameBriefType) {
          submitBrief();
        } else {
          setIsSaving(false);
          onAfterSubmit?.(data);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  const submitBrief = () => {
    const payload = { page: initialBrief?.stage };
    setIsSaving(true);
    postBriefSubmit(initialBrief?.uuid, payload)
      .then(({ data }) => {
        onAfterSubmit?.(data);
        setIsSaving(false);
      })
      .catch(({ error }) => {
        console.error(error);
        setIsSaving(false);
      });
  };

  // const handleBack = () => history.push(`/briefs`);

  const handleEmailSelect = () => {};

  const handleChatSelect = () => intercom.toggle();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrors({});
    mappedMethods[name](value);
  };

  const handleTimezoneChange = (e) => {
    setTimezone(e.target.value);
  };

  const handleCallSelect = () => {
    setIsDialogShown(true);
    postCallDaysAvailable({ timezone: timezone?.label })
      .then(({ data }) => {
        setDaysAvailable(data?.results || []);
      })
      .catch((error) => {});
  };

  const handleDateChange = (event) => {
    const d = event.target.value;
    setDate(d);
    setTimesAvailable(d.available);
    setErrors({});
  };

  const updateOrganization = () => {
    const orgUuid = initialBrief?.organization?.uuid;
    return patchOrganization(orgUuid, {
      contact: { phoneNumber, phoneCountry, timezone },
    });
  };

  const updateBriefCall = () => {
    const callId = initialBrief?.call?.id;
    const briefUuid = initialBrief?.uuid;
    const { start, end } = timeSelected;
    const data = {
      status: CallStatus.SCHEDULED.id,
      timezone,
      startTime: start,
      endTime: end,
      preferred: PreferredPlatform.PHONE.id,
    };
    return putBriefCall(briefUuid, callId, data);
  };

  const handleConfirmSchedule = () => {
    if (validateCallFields()) {
      setIsSaving(true);
      updateOrganization()
        .then(() => {
          updateBriefCall().then(() => {
            setIsDialogShown(false);
            setIsSaving(false);
            submitBrief();
          });
        })
        .catch((error) => {
          console.log(error);
          setIsSaving(false);
        });
    }
  };

  const handleTimeChange = (event) => {
    setTimeSelected(event.target.value);
    setErrors({});
  };

  const validateCallFields = () => {
    if (!phoneNumber) {
      setErrors({ ...errors, phoneNumber: 'Phone number is required!' });
      return false;
    }

    if (!phoneCountry) {
      setErrors({ ...errors, phoneCountry: 'Dial code is required!' });
      return false;
    }

    if (!date) {
      setErrors({ ...errors, date: 'Date is required!' });
      return false;
    }

    if (!timeSelected?.start || !timeSelected?.end) {
      setErrors({ ...errors, time: 'Time is required!' });
      return false;
    }

    return true;
  };

  useEffect(() => {
    postCallDaysAvailable({ timezone: timezone?.label })
      .then(({ data }) => {
        setDaysAvailable(data?.results || []);
      })
      .catch((error) => {});
  }, [timezone]);

  return (
    <div>
      <Dialog
        isOpen={isDialogShown}
        onClose={() => setIsDialogShown(false)}
        title={`Schedule a Call with  Hire Digital`}>
        <div>
          <InputGroup>
            <InputContainer>
              <PhoneNumber
                phoneCountry={phoneCountry}
                phoneNumber={phoneNumber}
                onPhoneCountryChange={handleChange}
                onPhoneNumberChange={handleChange}
                getCountries={async () => ({ data: common?.countries })}
                error={errors?.phoneNumber}
                shouldValidate={false}
              />
              {/* <Select label={`Phone`} /> */}
              {/* <div className={Styles.phoneCountry}>
                <div className={Styles.phone} style={{ marginRight: '15px' }}>
                  <Select
                    className={Styles.select}
                    name='phoneCountry'
                    label='Code'
                    getOptionLabel={({ dialCode }) => dialCode}
                    getOptionValue={({ id }) => id}
                    options={common?.countries}
                    defaultValue={phoneCountry}
                    onChange={handleChange}
                    error={errors?.phoneCountry}
                  />
                </div>
                <TextInput
                  label={`Phone`}
                  name={`phoneNumber`}
                  onChange={handleChange}
                  error={errors?.phoneNumber}
                />
                <Select
                  label={`Preference`}
                  name={`preferred`}
                  options={PreferredPlatform.values}
                  defaultValue={PreferredPlatform.getEnum(preferred)}
                  onChange={handleChange}
                />
              </div> */}
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <Select
                name='timezone'
                label='Timezone'
                getOptionLabel={({ name, label, offset }) => {
                  return (name || label) + ' (GMT' + offset + ')';
                }}
                getOptionValue={({ id }) => id}
                options={common?.timezones}
                defaultValue={timezone}
                onChange={handleTimezoneChange}
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <Select
                label={`Date`}
                getOptionLabel={({ day }) => day}
                getOptionValue={({ day }) => day}
                options={daysAvailable}
                defaultValue={date}
                onChange={handleDateChange}
                error={errors?.date}
              />
            </InputContainer>
            <InputContainer>
              <Select
                name='time'
                label='Time'
                options={timesAvailable}
                getOptionLabel={({ startFormatted, endFormatted }) =>
                  `${startFormatted}-${endFormatted}`
                }
                getOptionValue={({ day }) => day}
                onChange={handleTimeChange}
                error={errors?.time}
              />
            </InputContainer>
          </InputGroup>
        </div>
        <div style={{ marginTop: '30px' }}>
          <Button onClick={handleConfirmSchedule} type={Button.Type.BLUE} isLoading={isSaving}>
            {`Confirm Call`}
          </Button>
          <Button onClick={() => setIsDialogShown(false)} type={Button.Type.LIGHT_GRAY}>
            {`Cancel Call`}
          </Button>
        </div>
      </Dialog>

      {/*<button onClick={handleBack} className={Styles.backLink}>
        {`Back to Dashboard`}
      </button>
*/}
      <Section
        title={`Work With a New Talent`}
        subtitle={`Let's find you the best solution for your project.`}
      />

      <div>
        <FormLayout
          title={`How would you like to start?`}
          subtitle={`You can come back to this question if you change your mind.`}>
          <FormLayout.Row>
            <FormLayout.MainColumn direction={FormLayout.MainColumn.Direction.ROW}>
              {options.map((v, idx) => (
                <Option
                  key={idx}
                  title={v.title}
                  className={Styles.startOption}
                  subtitle={v.subtitle}
                  isActive={activeOption.includes(v.id)}
                  onClick={() => handleOptionSelect(v.id)}
                  renderIcon={v.renderIcon}
                />
              ))}
            </FormLayout.MainColumn>
            <FormLayout.AsideColumn>
              <HelpOptions
                onEmailSelect={handleEmailSelect}
                onCallSelect={handleCallSelect}
                onChatSelect={handleChatSelect}
              />
            </FormLayout.AsideColumn>
          </FormLayout.Row>
        </FormLayout>
      </div>

      {activeOption?.length > 0 && (
        <Button name='submit' type={Button.Type.BLUE} isLoading={isSaving} onClick={handleContinue}>
          {`Save and Continue`}
        </Button>
      )}
    </div>
  );
};

BriefStart.propTypes = {};

export default BriefStart;
