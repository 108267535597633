import { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@hiredigital/ui/Card';
import Badge from '@hiredigital/ui/Badge/Badge';
import { setOrgStripeCardDefault, deleteOrgStripeCard } from '@apis/customers';
import MoreDropdown from '@hiredigital/ui/MoreDropdown';
import Styles from './Styles.module.scss';

const CreditCardItem = ({ org, card, loadCustomers }) => {
  const [isProcessingChanges, setIsProcessingChanges] = useState(false);

  const handleRemoveCard = async (card) => {
    setIsProcessingChanges(true);
    await deleteOrgStripeCard(org.uuid, card.id);
    await loadCustomers();
    setIsProcessingChanges(false);
  };

  const handleSetDefault = async (card) => {
    setIsProcessingChanges(true);
    await setOrgStripeCardDefault(org.uuid, card.id);
    await loadCustomers();
    setIsProcessingChanges(false);
  };

  return (
    <Card.Item flex style={{ alignItems: 'center' }}>
      <div className={Styles.left}>
        <div className={Styles.cardNumber}>{`${card.brand} ending in ${card.last4}`}</div>
        <div className={Styles.cardExp}>{`Exp ${card.expMonth}/${card.expYear}`}</div>
      </div>
      {card.isDefault ? (
        <Badge color='green'>{`Default Card`}</Badge>
      ) : (
        <MoreDropdown
          className={Styles.right}
          type={MoreDropdown.Type.WHITE}
          isLoading={isProcessingChanges}
          options={[
            {
              label: 'Set as Default',
              onSelect: () => handleSetDefault(card),
            },
            {
              label: 'Remove Card',
              onSelect: () => handleRemoveCard(card),
            },
          ]}></MoreDropdown>
      )}
    </Card.Item>
  );
};

CreditCardItem.propTypes = {
  org: PropTypes.object,
  card: PropTypes.object,
  loadCustomers: PropTypes.func,
};

export default CreditCardItem;
