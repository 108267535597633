import find from 'lodash/find';
// TODO Remove Lodash
import { BankAccountCurrency, BankAccountType } from '@hiredigital/lib/helpers/enum';

const PayPalDataItems = [
  {
    label: 'Currency',
    visible: () => false,
    field: ({ currency: v }) => (v && BankAccountCurrency.getEnum(v).name) || '',
  },
  {
    label: 'Paypal',
    visible: (v) => true,
    field: (v) => v.paypalEmail,
  },
];

const BankDataItems = [
  {
    label: 'Currency',
    visible: () => true,
    field: ({ currency: v }) => (v && BankAccountCurrency.getEnum(v).name) || '',
  },
  {
    label: 'Account Type',
    visible: (v) =>
      find(v.accountTypeCountries, {
        country: v.currency,
      }),
    field: ({ accountType: v }) => (BankAccountType.getEnum(v) || {}).label || '',
  },
  {
    label: 'Account Number',
    visible: (v) =>
      find(v.accountNumberCountries, {
        country: v.currency,
      }),
    field: (v) => v.accountNumber,
  },
  {
    label: 'Bank',
    visible: (v) =>
      find(v.bankCodeCountries, {
        country: v.currency,
      }),
    field: ({ newBankCode: v }) => (v && v.title) || '',
  },
  {
    label: 'Branch',
    visible: (v) =>
      find(v.branchCodeCountries, {
        country: v.currency,
      }),
    field: ({ newBranchCode: v }) => (v && v.title) || '',
  },
  {
    label: 'Bank Routing Number',
    visible: (v) =>
      find(v.abartnCountries, {
        country: v.currency,
      }),
    field: (v) => v.abartn,
  },
  {
    label: 'BBAN',
    visible: (v) =>
      find(v.bbanCountries, {
        country: v.currency,
      }),
    field: (v) => v.bban,
  },
  {
    label: 'BSB Code',
    visible: (v) =>
      find(v.bsbCodeCountries, {
        country: v.currency,
      }),
    field: (v) => v.bsbCode,
  },
  {
    label: 'Sort Code',
    visible: (v) =>
      find(v.sortCodeCountries, {
        country: v.currency,
      }),
    field: (v) => v.sortCode,
  },
  {
    label: 'IBAN',
    visible: (v) =>
      find(v.ibanCountries, {
        country: v.currency,
      }),
    field: (v) => v.iban,
  },
  {
    label: 'BIC Code',
    visible: (v) =>
      find(v.bicCodeCountries, {
        country: v.currency,
      }),
    field: (v) => v.swift,
  },
  {
    label: 'Swift Code',
    visible: (v) =>
      find(v.swiftCodeCountries, {
        country: v.currency,
      }),
    field: ({ newSwiftCode: v }) => (v && `${v.title} (${v.code})`) || '',
  },
  {
    label: 'Institution Number',
    visible: (v) =>
      find(v.institutionNumberCountries, {
        country: v.currency,
      }),
    field: (v) => v.institutionNumber,
  },
  {
    label: 'Transit Number',
    visible: (v) =>
      find(v.transitNumberCountries, {
        country: v.currency,
      }),
    field: (v) => v.transitNumber,
  },
  {
    label: 'UnionPay Card Number',
    visible: (v) =>
      find(v.unionPayCountries, {
        country: v.currency,
      }),
    field: (v) => v.cardNumber,
  },
  {
    label: 'IFSC Code',
    visible: (v) =>
      find(v.ifscCodeCountries, {
        country: v.currency,
      }),
    field: (v) => v.ifscCode,
  },
  {
    label: 'Tax ID Number',
    visible: (v) =>
      find(v.taxIdCountries, {
        country: v.currency,
      }),
    field: (v) => v.taxId,
  },
  {
    label: 'CPF',
    visible: (v) =>
      find(v.cpfCountries, {
        country: v.currency,
      }),
    field: (v) => v.cpf,
  },
  {
    label: 'RUT',
    visible: (v) =>
      find(v.rutCountries, {
        country: v.currency,
      }),
    field: (v) => v.rut,
  },
  {
    label: 'ID Document Type',
    visible: (v) =>
      find(v.idDocumentTypeCountries, {
        country: v.currency,
      }),
    field: ({ idDocumentType: v }) => (v && v.label) || '',
  },
  {
    label: 'ID Document Number',
    visible: (v) =>
      find(v.idDocumentNumberCountries, {
        country: v.currency,
      }),
    field: (v) => v.idDocumentNumber,
  },
  {
    label: 'Prefix',
    visible: (v) =>
      find(v.prefixCountries, {
        country: v.currency,
      }),
    field: (v) => v.prefix,
  },
  {
    label: 'Clabe',
    visible: (v) =>
      find(v.clabeCountries, {
        country: v.currency,
      }),
    field: (v) => v.clabe,
  },
  {
    label: 'Russia Region',
    visible: (v) =>
      find(v.russiaRegionCountries, {
        country: v.currency,
      }),
    field: (v) => v.russiaRegion,
  },
  {
    label: 'Phone Number',
    visible: (v) =>
      find(v.phoneNumberCountries, {
        country: v.currency,
      }),
    field: (v) => v.phoneNumber,
  },
  {
    label: 'Date of Birth',
    visible: (v) =>
      find(v.dobCountries, {
        country: v.currency,
      }),
    field: (v) => v.dateOfBirth,
  },
  {
    label: 'E-Mail',
    visible: (v) =>
      find(v.emailCountries, {
        country: v.currency,
      }),
    field: (v) => v.email,
  },
  {
    label: 'Address',
    visible: (v) =>
      find(v.addressCountries, {
        country: v.currency,
      }) ||
      find(v.cityCountries, {
        country: v.currency,
      }) ||
      find(v.postalCodeCountries, {
        country: v.currency,
      }) ||
      find(v.regionCountries, {
        country: v.currency,
      }) ||
      find(v.countryCountries, {
        country: v.currency,
      }),
    field: ({ address: v }) => {
      if (v) {
        const clean = (text) => (text && text.replace(/(<([^>]+)>)/gi, '')) || '';
        return [clean(v.address), v.city, v.region, v.country, v.postalCode]
          .filter((item) => !!item)
          .join(', ');
      }
      return '';
    },
  },
];

const defaultState = {
  accountNumberCountries: [
    { country: 'SGD', required: true },
    { country: 'MYR', required: true },
    { country: 'IDR', required: true },
    { country: 'AUD', required: true },
    { country: 'GBP', required: true },
    { country: 'USD', required: true },
    { country: 'JPY', required: true },
    { country: 'PHP', required: true },
    { country: 'VND', required: true },
    { country: 'THB', required: true },
    { country: 'CAD', required: true },
    { country: 'HKD', required: true },
    { country: 'KRW', required: true },
    { country: 'NZD', required: true },
    { country: 'INR', required: true },
    { country: 'BDT', required: true },
    { country: 'EGP', required: true },
    { country: 'GHS', required: true },
    { country: 'KES', required: true },
    { country: 'LKR', required: true },
    { country: 'MAD', required: true },
    { country: 'NGN', required: true },
    { country: 'NPR', required: true },
    { country: 'TZS', required: true },
    { country: 'UGX', required: true },
    { country: 'ZAR', required: true },
    { country: 'ARS', required: true },
    { country: 'BRL', required: true },
    { country: 'CLP', required: true },
    { country: 'CZK', required: true },
    { country: 'PEN', required: true },
    { country: 'RUB', required: true },
    { country: 'UAH', required: true },
    { country: 'UYU', required: true },
  ],
  bankNameCountries: [
    { country: 'SGD', required: true },
    { country: 'PHP', required: true },
  ],
  bankCodeCountries: [
    { country: 'SGD', required: true },
    { country: 'IDR', required: true },
    { country: 'JPY', required: true },
    { country: 'THB', required: true },
    { country: 'HKD', required: true },
    { country: 'KRW', required: true },
    { country: 'BDT', required: true },
    { country: 'EGP', required: true },
    { country: 'GHS', required: true },
    { country: 'KES', required: true },
    { country: 'LKR', required: true },
    { country: 'MAD', required: true },
    { country: 'NGN', required: true },
    { country: 'NPR', required: true },
    { country: 'TZS', required: true },
    { country: 'UGX', required: true },
    { country: 'BRL', required: true },
    { country: 'CLP', required: true },
    { country: 'CZK', required: true },
    { country: 'PEN', required: true },
    { country: 'RUB', required: true },
    { country: 'UYU', required: true },
    { country: 'PHP', required: true },
  ],
  bsbCodeCountries: [{ country: 'AUD', required: true }],
  sortCodeCountries: [{ country: 'GBP', required: true }],
  abartnCountries: [{ country: 'USD', required: true }],
  accountTypeCountries: [
    { country: 'USD', required: true },
    { country: 'CAD', required: true },
    { country: 'JPY', required: true },
    { country: 'BRL', required: true },
    { country: 'CLP', required: true },
    { country: 'PEN', required: true },
    { country: 'UYU', required: true },
  ],
  branchCodeCountries: [
    { country: 'JPY', required: true },
    { country: 'BDT', required: true },
    { country: 'LKR', required: true },
    { country: 'BRL', required: true },
    { country: 'UYU', required: false },
  ],
  swiftCodeCountries: [
    { country: 'MYR', required: true },
    { country: 'VND', required: true },
    { country: 'ZAR', required: true },
  ],
  bicCodeCountries: [
    { country: 'EUR', required: false },
    { country: 'CHF', required: false },
    { country: 'BGN', required: false },
    { country: 'DKK', required: false },
    { country: 'GEL', required: false },
    { country: 'NOK', required: false },
    { country: 'PKR', required: false },
    { country: 'PLN', required: false },
    { country: 'RON', required: false },
    { country: 'SEK', required: false },
    { country: 'AED', required: false },
    { country: 'HRK', required: false },
    { country: 'HUF', required: false },
    { country: 'ILS', required: false },
    { country: 'TRY', required: false },
  ],
  ibanCountries: [
    { country: 'EUR', required: true },
    { country: 'CHF', required: true },
    { country: 'BGN', required: true },
    { country: 'DKK', required: true },
    { country: 'GEL', required: true },
    { country: 'NOK', required: true },
    { country: 'PKR', required: true },
    { country: 'PLN', required: true },
    { country: 'RON', required: true },
    { country: 'SEK', required: true },
    { country: 'AED', required: true },
    { country: 'HRK', required: true },
    { country: 'HUF', required: true },
    { country: 'ILS', required: true },
    { country: 'TRY', required: true },
    { country: 'CRC', required: true },
  ],
  institutionNumberCountries: [{ country: 'CAD', required: true }],
  transitNumberCountries: [{ country: 'CAD', required: true }],
  unionPayCountries: [{ country: 'CNY', required: true }],
  ifscCodeCountries: [{ country: 'INR', required: true }],
  addressCountries: [
    { country: 'USD', required: true },
    { country: 'PHP', required: true },
    { country: 'THB', required: true },
    { country: 'RUB', required: true },
    { country: 'AUD', required: true },
  ],
  postalCodeCountries: [
    { country: 'USD', required: true },
    { country: 'PHP', required: true },
    { country: 'THB', required: true },
    { country: 'RUB', required: true },
    { country: 'CHF', required: true },
    { country: 'AUD', required: true },
  ],
  cityCountries: [
    { country: 'USD', required: true },
    { country: 'PHP', required: true },
    { country: 'THB', required: true },
    { country: 'RUB', required: true },
    { country: 'CHF', required: true },
    { country: 'AUD', required: true },
  ],
  regionCountries: [
    { country: 'USD', required: true },
    { country: 'AUD', required: true },
  ],
  countryCountries: [
    { country: 'RUB', required: true },
    { country: 'XOF', required: true },
  ],
  phoneNumberCountries: [
    { country: 'BRL', required: true },
    { country: 'CLP', required: true },
    { country: 'PEN', required: true },
    { country: 'UAH', required: true },
  ],
  taxIdCountries: [{ country: 'ARS', required: true }],
  cpfCountries: [{ country: 'BRL', required: true }],
  rutCountries: [{ country: 'CLP', required: true }],
  idDocumentTypeCountries: [
    { country: 'CRC', required: true },
    { country: 'PEN', required: true },
  ],
  idDocumentNumberCountries: [
    { country: 'CRC', required: true },
    { country: 'PEN', required: true },
    { country: 'UYU', required: true },
  ],
  prefixCountries: [{ country: 'CZK', required: true }],
  clabeCountries: [{ country: 'MXN', required: true }],
  russiaRegionCountries: [{ country: 'RUB', required: true }],
  bbanCountries: [{ country: 'XOF', required: true }],
  dobCountries: [{ country: 'KRW', required: true }],
  emailCountries: [{ country: 'KRW', required: true }],
};

export { BankDataItems, PayPalDataItems, defaultState };

export const SimpleRowDetailMap = [
  { key: 'bankCode', prefix: 'Bank code' },
  { key: 'swiftCode', prefix: 'Swift code' },
  { key: 'bic', prefix: 'BIC code' },
  { key: 'bsbCode', prefix: 'BSB code' },
  { key: 'sortCode', prefix: 'Sort code' },
  { key: 'ifscCode', prefix: 'IFSC code' },
  { key: 'abartn', prefix: 'Bank routing number' },
  { key: 'institutionNumber', prefix: 'Institution number' },
  { key: 'idDocumentNumber', prefix: 'ID document number' },
  { key: 'idNumber', prefix: 'ID number' },
  { key: 'taxId', prefix: 'Tax ID number' },
  { key: 'phoneNumber', prefix: 'Phone number' },
];
