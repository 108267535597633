import { useReducer, useContext, createContext } from 'react';

const UserContext = createContext();
const UserDispatchContext = createContext();

const reducer = (user, action) => {
  switch (action.type) {
    case 'UPDATE_CLIENTS':
      return { ...user, clients: action.payload };
    case 'UPDATE_TEAMS':
      return { ...user, teams: action.payload };
    case 'UPDATE':
      // Object assign doesnt do anything
      // const updatedUser = Object.assign(user, action.payload);
      // console.log('new user is', updatedUser);
      return action.payload;
    case 'UPDATE_COMPLETION':
      return { ...user, ...action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const UserProvider = ({ children, initial }) => {
  const [user, dispatch] = useReducer(reducer, initial);
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <UserDispatchContext.Provider value={dispatch}>
      {/*eslint-disable-next-line react/react-in-jsx-scope*/}
      <UserContext.Provider value={user}>{children}</UserContext.Provider>
    </UserDispatchContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
export const useUserDispatch = () => useContext(UserDispatchContext);
