export const noteLinks = [
  {
    to: `https://hiredigital.com/blog/profile-guidelines`,
    text: 'Profile Guidelines',
  },
  {
    to: `https://hiredigital.com/resumes/gon-fernandez`,
    text: 'Reference Profile',
  },
  {
    to: `https://drive.google.com/uc?id=1nC6B_aSfTWZtnHD7v4E_F69XDVSKKoMJ&export=download`,
    text: 'Case Study Template',
  },
];

export const profileOptionsNotes = [
  `To ensure that Hire Digital finds you as many relevant matches as possible, profiles set to Default view may be shared with current and prospective clients and listed on Hire Digital websites.`,
  `If you prefer to choose the clients who get to view your profile, please set it to Private. You can change this later. Private Profiles will only be visible to clients whose engagements you actively apply to.`,
];
