export class BankValidator {
  constructor(request) {
    this.request = request;
  }

  getRequest = () => this.request;

  validateUkSortCode = async (sortCode, config = undefined) => {
    const url = `public/bank-validations/validate-uk-sort-code?sort_code=${sortCode}`;
    return await this.request.get(url, config);
  };

  validateIban = async (iban, config = undefined) => {
    const url = `public/bank-validations/validate-iban?iban=${iban}`;
    return await this.request.get(url, config);
  };

  validateBic = async (swift, iban, config = undefined) => {
    const url = `public/bank-validations/validate-bic?bic=${swift}&iban=${iban}`;
    return await this.request.get(url, config);
  };

  validateBsbCode = async (bsbCode, config = undefined) => {
    const url = `public/bank-validations/validate-bsb-code?bsb_code=${bsbCode}`;
    return await this.request.get(url, config);
  };

  validateCanadianInstitution = async (institutionNumber, config = undefined) => {
    const url = `public/bank-validations/validate-canadian-institution?institution_number=${institutionNumber}`;
    return await this.request.get(url, config);
  };

  validateCanadianTransit = async (transitNumber, institutionNumber, config = undefined) => {
    const url = `public/bank-validations/validate-canadian-transit?transit_number=${transitNumber}&institution_number=${institutionNumber}`;
    return await this.request.get(url, config);
  };

  validateEmiratesBic = async (swift, iban, config = undefined) => {
    const url = `public/bank-validations/validate-emirates-bic?bic=${swift}&iban=${iban}`;
    return await this.request.get(url, config);
  };

  validateChineseUnionPay = async (cardNumber, config = undefined) => {
    const url = `public/bank-validations/validate-chinese-union-pay?card_number=${cardNumber}`;
    return await this.request.get(url, config);
  };

  validateUkAccountNumber = async (accountNumber, config = undefined) => {
    const url = `public/bank-validations/validate-uk-account-number?account_number=${accountNumber}`;
    return await this.request.get(url, config);
  };

  validateAustralianAccount = async (accountNumber, config = undefined) => {
    const url = `public/bank-validations/validate-australian-account?account_number=${accountNumber}`;
    return await this.request.get(url, config);
  };

  validateIndianAccount = async (accountNumber, config = undefined) => {
    const url = `public/bank-validations/validate-indian-account?account_number=${accountNumber}`;
    return await this.request.get(url, config);
  };

  validateIfscCode = async (ifscCode, config = undefined) => {
    const url = `public/bank-validations/validate-ifsc-code?ifsc_code=${ifscCode}`;
    return await this.request.get(url, config);
  };

  validateHungarianAccount = async (accountNumber, config = undefined) => {
    const url = `public/bank-validations/validate-hungarian-account?account_number=${accountNumber}`;
    return await this.request.get(url, config);
  };

  validateCanadianAccount = async (
    institutionNumber,
    transitNumber,
    accountNumber,
    config = undefined
  ) => {
    const url = `public/bank-validations/validate-canadian-account?institution_number=${institutionNumber}&transit_number=${transitNumber}&account_number=${accountNumber}`;
    return await this.request.get(url, config);
  };

  validateNewZealandAccount = async (accountNumber, config = undefined) => {
    const url = `public/bank-validations/validate-new-zealand-account?account_number=${accountNumber}`;
    return await this.request.get(url, config);
  };

  validateThaiAccount = async (accountNumber, newBankCode, config = undefined) => {
    const url = `public/bank-validations/validate-thailand-account?account_number=${accountNumber}&bank_code=${newBankCode}`;
    return await this.request.get(url, config);
  };

  validateAbaRouting = async (abartn, config = undefined) => {
    const url = `public/bank-validations/validate-aba-routing?abartn=${abartn}`;
    return await this.request.get(url, config);
  };
}
