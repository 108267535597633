import { useState, useRef, memo, useEffect, useId } from 'react';
import classNames from 'classnames';
import InitialSelect from 'react-select';
import Styles from './Input.module.scss';

const ReactSelect = memo(InitialSelect);

const SHRINK_THRESHOLD = 245;

const customStyles = {
  option: (styles) => ({
    ...styles,
  }),
  input: (styles) => ({
    ...styles,
    margin: 0,
    padding: 0,
  }),
  menu: (styles) => ({
    ...styles,
    margin: '2px 0',
  }),
  placeholder: (styles) => ({
    ...styles,
  }),
  control: (styles) => ({
    ...styles,
    boxShadow: 'none',
    ':hover': {
      borderColor: '#a1aab3',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '2px 15px',
    fontSize: '16px',
  }),
  singleValue: (styles) => ({
    ...styles,
    marginLeft: '0',
    fontSize: '16px',
  }),
};

const DummyElem = () => <div />;

const handleChange = (onChange, event, { action, name }) => {
  // Pass any other change events from parent
  try {
    onChange({
      ...event,
      target: { name, value: event },
      action,
    });
  } catch (err) {
    console.error(err);
  }
};

const Select = ({ component, rightAlign }) => {
  const {
    onChange: onSelectChange,
    disabled: selectDisabled,
    style: selectStyle,
    ...selectProps
  } = component(DummyElem).props;

  const { value: initialValue, defaultValue, error, label, isMulti } = selectProps;

  const uniqueId = useId();
  const [value, setValue] = useState(initialValue || defaultValue);

  useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue]);

  const handleSelectChange = (event, actionMeta) => {
    setValue(event);
    handleChange(onSelectChange, event, actionMeta);
  };

  const labelClass = classNames(
    Styles.label,
    error && Styles.error,
    rightAlign && Styles.rightAlign,
    isMulti && value?.length && Styles.hasValue,
    !isMulti && Object.keys(value || {}).length && Styles.hasValue
  );

  return (
    <div
      className={classNames(Styles.dualSelect, rightAlign && Styles.rightAlign)}
      style={selectStyle}>
      <ReactSelect
        className={classNames(Styles.select, error && Styles.error)}
        styles={customStyles}
        placeholder={label}
        onChange={handleSelectChange}
        additional={{ page: 1 }}
        createOptionPosition={'first'}
        classNamePrefix='s-contact'
        isDisabled={selectDisabled}
        name={uniqueId}
        {...selectProps}
      />
      <label htmlFor={uniqueId} className={labelClass}>
        {label}
      </label>
    </div>
  );
};

const DualInput = ({ input, select, secondSelect, className }) => {
  // secondSelect is optional and can be omitted (undefined)

  const elementRef = useRef(null);
  const [shouldShrink, setShouldShrink] = useState(false);
  const checkShouldShrink = () => {
    setShouldShrink(elementRef?.current?.offsetWidth <= SHRINK_THRESHOLD);
  };

  useEffect(() => {
    checkShouldShrink();
    window.addEventListener('resize', checkShouldShrink);

    return () => {
      window.removeEventListener('resize', checkShouldShrink);
    };
  }, []);

  const containerClass = classNames(
    className,
    Styles.container,
    Styles.dualContainer,
    shouldShrink && Styles.shrink
  );

  const { placeholder, ...inputProps } = input(DummyElem).props;
  const { error, label, name } = inputProps;

  return (
    <>
      <div className={containerClass} ref={elementRef}>
        <div className={Styles.dualInput}>
          <input
            className={classNames(Styles.base, Styles.input)}
            placeholder={error ? '' : placeholder || label}
            {...inputProps}
          />
          <label htmlFor={name} className={classNames(Styles.label, error && Styles.error)}>
            {error === true ? `${label} is required` : error || label}
          </label>
        </div>
        <Select component={select} rightAlign={!!secondSelect} />
      </div>
      {secondSelect && (
        <div className={containerClass}>
          <Select component={secondSelect} />
        </div>
      )}
    </>
  );
};

export default DualInput;
