import { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';

import { getOrgInvoices } from '@apis/organizations';
import { getUserInvoices } from '@apis/users';

import { SelectMenu } from 'evergreen-ui/esm/select-menu';
import Position from 'evergreen-ui/esm/constants/src/Position';

import { InvoiceStatus } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { useIntercom } from '@hiredigital/lib/providers/intercom';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import Styles from './Styles.module.scss';
import { getCurrencyIcon, formatNumber } from '@hiredigital/lib/helpers/utils';

const InvoiceFilter = [
  { label: 'Awaiting Approval', value: 1, status: InvoiceStatus.SUBMITTED.id },
  { label: 'Awaiting Payment', value: 2, status: InvoiceStatus.AUTHORISED.id },
  { label: 'Paid', value: 3, status: InvoiceStatus.PAID.id },
  { label: 'All Invoices', value: 4, status: undefined },
];

const InvoiceList = ({ resourceUuid, resource, ...props }) => {
  // For both org and user

  const columns = useMemo(
    () => [
      {
        Header: 'Invoice Number',
        id: 'id',
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => {
          return (
            <div className={Styles.row}>
              <a
                className={Styles.link}
                href={row.original.attachment}
                rel={'noreferrer'}
                target='_blank'>
                {`${row.original.invoiceNumber}`}
              </a>
            </div>
          );
        },
        canSort: true,
      },
      {
        Header: 'Date',
        id: 'date',
        accessor: (d) => toReadableDate(d.date),
        canSort: true,
      },

      {
        Header: 'Status',
        id: 'status',
        accessor: (d) => InvoiceStatus.getLabel(d.status),
        canSort: true,
      },

      {
        Header: 'Amount',
        id: 'totalAmount',
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => {
          return (
            <p className={Styles.currency}>{`${getCurrencyIcon(
              row.original?.currencyCode
            )}${formatNumber(row.original.totalAmount)} ${row.original?.currencyCode || ''}`}</p>
          );
        },
      },
    ],
    []
  );

  const [statusItem, setStatusItem] = useState({
    label: 'All Invoices',
    value: 4,
    status: undefined,
  });
  const [search, setSearch] = useState('');
  const [showHelp, setShowHelp] = useState(false);
  const intercom = useIntercom();

  const getData = (config) => {
    if (resource === 'users') {
      return getUserInvoices(resourceUuid, config);
    } else {
      return getOrgInvoices(resourceUuid, config);
    }
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const handleClearSearch = () => {
    setStatusItem({ label: 'All Invoices', value: 4, status: undefined });
    setSearch('');
  };

  const handleFilterSelect = (item) => setStatusItem(item);

  return (
    <Card>
      <Fragment>
        <Card.Header>
          <div className={Styles.sectionTitle}>{`Invoices`}</div>
          {resource !== 'orgs' && (
            <div className={Styles.sectionHelp} onClick={() => setShowHelp(!showHelp)}>
              When are payments made?
            </div>
          )}
          <SelectMenu
            height={175}
            width={150}
            title={`Filter by`}
            hasFilter={false}
            position={Position.BOTTOM_RIGHT}
            options={InvoiceFilter}
            onSelect={handleFilterSelect}
            selected={statusItem.value}>
            <button className={Styles.filter}>
              {InvoiceFilter.find((v) => v.value === statusItem.value)?.label || `All Invoices`}
              <div className={Styles.arrowDown} />
            </button>
          </SelectMenu>
        </Card.Header>

        {showHelp && (
          <Card.Note className={Styles.cardNote}>
            Payments are made by Hire Digital on behalf of the companies that commission work on the
            platform. Payment is typically made within 30 days project completion or invoice date.
            It may take up to 5 additional business days for your funds to be reflected in your bank
            account.
            <br />
            <br />
            If you have any additional questions about payments or payment processes, you can{' '}
            <a onClick={() => intercom.toggle()}>chat with us</a> or contact{' '}
            <a href='mailto:finance@hiredigital.com'>finance@hiredigital.com</a>.
          </Card.Note>
        )}

        <Card.Item noPadding noBorder>
          <PaginatedTable
            columns={columns}
            search={search}
            filters={{ status: statusItem.status }}
            getListData={getData}
            containerClass={Styles.container}
            tableClass={Styles.table}
            headerCellClass={Styles.headerCell}
            headerRowClass={Styles.headerRow}
            cellClass={Styles.cell}
            emptyContainerClass={Styles.emptyContainer}
            paginationClass={Styles.pagination}
            onClearSearch={handleClearSearch}
            defaultLimit={10}
            defaultPaginationOptions={[5, 10, 20, 50]}
            showParams={false}
          />
        </Card.Item>
      </Fragment>
    </Card>
  );
};

InvoiceList.propTypes = {
  resourceUuid: PropTypes.string,
  resource: PropTypes.string,
};

SelectMenu.propTypes = {
  ...SelectMenu.propTypes,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
  ]),
};

export default InvoiceList;
