/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '@hiredigital/ui/Loader';
import Avatar from '@hiredigital/ui/Avatar';
import Menu from '@hiredigital/ui/Menu/Menu';
import Badge from '@hiredigital/ui/Badge/Badge';

import { getClients, getClient } from '@apis/common';
import ButtonFilter from '../ButtonFilter/ButtonFilter';
import CommonStyles from '../Common.module.scss';

const ClientsList = ({ onFilterSelect, selected }) => {
  const [nextPage, setNextPage] = useState(1);
  const [clients, setClients] = useState([]);

  const handleSelect = (item) => {
    onFilterSelect?.(item);
  };

  useEffect(() => {
    loadMoreClients();
  }, []);

  const loadMoreClients = () => {
    if (!nextPage) return;
    const params = { page: nextPage };
    getClients({ params })
      .then(({ data }) => {
        setClients(data?.results || []);
        setNextPage(data?.meta?.nextPage);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className={CommonStyles.filterItems}>
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={loadMoreClients}
        hasMore={nextPage !== null}
        threshold={10}
        useWindow={false}
        loader={
          <div key={0}>
            <Loader type={Loader.Type.FULL} />
          </div>
        }>
        <Fragment>
          <Menu.Item
            as='div'
            className={classNames(CommonStyles.filterItem, !selected && CommonStyles.selected)}
            onClick={() => handleSelect?.()}>
            <Avatar name={'A'} hash={'all'} style={{ marginRight: '10px' }} />
            {`All Clients`}
          </Menu.Item>
          {clients?.map((v, idx) => (
            <Menu.Item
              key={idx}
              as='div'
              className={classNames(
                CommonStyles.filterItem,
                selected?.uuid === v?.uuid && CommonStyles.selected
              )}
              onClick={() => handleSelect?.(v)}>
              <Avatar
                name={v?.organization?.name}
                hash={v?.organization?.uuid}
                src={v?.organization?.picture}
                style={{ marginRight: '10px' }}
              />
              {v?.organization?.name}
              {v?.isProjectManager && (
                <div style={{ marginLeft: 'auto' }}>
                  <Badge color='green' marginLeft='10px'>
                    {`Manager`}
                  </Badge>
                </div>
              )}
            </Menu.Item>
          ))}
        </Fragment>
      </InfiniteScroll>
    </div>
  );
};

const ClientsFilter = ({ talentUuid, onFilter }) => {
  // Note client is talents

  const [talent, setTalent] = useState();

  useEffect(() => {
    if (!talentUuid) {
      // Clear
      setTalent(talentUuid);
    }
  }, [talentUuid]);

  useEffect(() => {
    const loadData = async (c) => {
      try {
        const { data } = await getClient(c);
        setTalent(data);
      } catch (e) {
        setTalent(null);
      }
    };
    if (talentUuid) {
      loadData(talentUuid);
    }
  }, []);

  return (
    <Menu className={CommonStyles.marginLeft}>
      <Menu.Button as='div'>
        <ButtonFilter className={CommonStyles.buttonFilter}>
          {talent?.organization ? (
            <div className={CommonStyles.selectedFilter}>
              <Avatar
                name={talent?.organization?.name}
                hash={talent?.organization?.uuid}
                src={talent?.organization?.picture}
                style={{ marginRight: '10px' }}
              />
              {talent.organization?.name}
            </div>
          ) : (
            <Fragment>{`Filter by Clients`}</Fragment>
          )}
        </ButtonFilter>
      </Menu.Button>
      <Menu.Items>
        <ClientsList
          onFilterSelect={(item) => {
            setTalent(item);
            onFilter?.(item);
          }}
          selected={talent}
        />
      </Menu.Items>
    </Menu>
  );
};

export default ClientsFilter;
