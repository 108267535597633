import { useState } from 'react';
import PropTypes from 'prop-types';
import Styles from './Detail.module.scss';
import Engagement from './Engagement';
import StartApplication from './StartApplication';
// import { Dialog } from 'evergreen-ui/esm/dialog';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button';
import { postEngagementApplication } from '@apis/engagements';

const InvitationOverview = ({ engagement, onApplicationCreated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const handleConfirm = () => {
    setIsLoading(true);
    postEngagementApplication(engagement?.uuid, {})
      .then(({ data }) => {
        setIsLoading(false);
        onApplicationCreated?.(data);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <div className={Styles.invitationContainer}>
      <Dialog
        isOpen={isShown}
        width={400}
        title={`Start Application`}
        onClose={() => setIsShown(false)}>
        <p className={Styles.notes}>
          {`By starting the application, you acknowledge that the brief and the `}
          {`application process are strictly confidential and you agree that you will `}
          {`be able to meet the indicated project requirements. Are you ready to start the application?`}
        </p>
        <div style={{ marginTop: '30px' }}>
          <Button onClick={handleConfirm} type={Button.Type.BLUE} isLoading={isLoading}>
            {`Confirm to Start`}
          </Button>
          <Button onClick={() => setIsShown(false)} type={Button.Type.LIGHT_GRAY}>
            {`Cancel`}
          </Button>
        </div>
      </Dialog>
      <StartApplication onStart={() => setIsShown(true)} />
      <Engagement engagement={engagement} rightWidth={30} leftWidth={70} />
    </div>
  );
};

InvitationOverview.propTypes = {
  children: PropTypes.node,
};

export default InvitationOverview;
