import { useState, useEffect } from 'react';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import Layout from '@components/Layout/AppLayout';
import Profile from '@components/Profile/Profile';

const OnboardingProfile = () => {
  const [scrollContainer, setScrollContainer] = useState(null);

  useEffect(() => {
    setPageTitle('Profile - Hire Digital');
  }, []);

  return (
    <Layout getScrollContainer={setScrollContainer} style={{ backgroundColor: 'white' }}>
      <Profile scrollContainer={scrollContainer} isOnboarding />
    </Layout>
  );
};

export default OnboardingProfile;
