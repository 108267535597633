import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import toast from 'react-hot-toast';

import { useHistory, useParams } from 'react-router-dom';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import { request } from '@apis/utils';
import { COOKIE_DOMAIN } from '@helpers/utils';
import AppLayout from '@components/Layout/AppLayout';

import { getMe } from '@apis/users';
import { getOrganization } from '@apis/organizations';

const cookies = new Cookies();

const LoginPage = () => {
  const history = useHistory();
  const params = useParams();

  const { clear } = params;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (clear) {
      cookies.remove('token', COOKIE_DOMAIN);
    } else if (cookies.get('token')) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePasswordKeyPress = (e) => {
    if (e.which === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = (event) => {
    const user = {
      username: username,
      password: password,
    };

    request.post(`accounts/login/`, user).then(
      async ({ data: { key } }) => {
        cookies.set('token', key, COOKIE_DOMAIN);

        if (key) {
          const config = { headers: { Authorization: `Token ${key}` } };
          const { data: initialUser } = await getMe(config);
          if (initialUser) {
            // set intial user cookie
            cookies.set('user_uuid', initialUser?.uuid, COOKIE_DOMAIN);
          }

          const orgUuid = cookies.get('org_uuid') || initialUser?.orgs?.[0]?.uuid;
          if (orgUuid) {
            const { data: initialOrg } = await getOrganization(orgUuid, config);

            if (initialOrg) {
              // set initial org cookie
              cookies.set('org_uuid', initialOrg.uuid, COOKIE_DOMAIN);
            } else {
              const orgUuid2 = initialUser?.orgs?.[0]?.uuid;
              if (orgUuid2 && orgUuid != orgUuid2) {
                const { data: initialOrg } = await getOrganization(orgUuid2, config);
                if (initialOrg) {
                  // set initial org cookie
                  cookies.set('org_uuid', initialOrg.uuid, COOKIE_DOMAIN);
                } else {
                  cookies.remove('org_uuid');
                }
              } else {
                cookies.remove('org_uuid');
              }
            }
          }
          // Instead of trying to patch the UI using context dispatch actions and history.push,
          // do a full refresh to create all objects that depends on the new cookies from scratch.
          // This properly simulates a login from the marketing site.
          window.location.href = '/';
        }
      },
      (error) => {
        const data = error?.response?.data || {};
        if (Object.keys(data).length) {
          Object.keys(data).forEach((d) => toast.error(`${data[d]}`, { id: d }));
        } else {
          toast.error(error?.message, { id: error?.message });
        }
      }
    );
  };

  return (
    <AppLayout
      pageHead={{
        title: `Login | Hire Digital`,
      }}
      header={`Developer Login`}>
      <InputContainer>
        <TextInput
          label='Username'
          name='Username'
          value={username}
          onChange={(e) => setUsername(e?.target?.value)}
        />
      </InputContainer>

      <InputContainer>
        <TextInput
          label='Password'
          type='password'
          name='Password'
          value={password}
          onKeyPress={handlePasswordKeyPress}
          onChange={(e) => setPassword(e?.target?.value)}
        />
      </InputContainer>
      <Button type={Button.Type.BLUE} onClick={handleSubmit}>
        Submit
      </Button>
    </AppLayout>
  );
};

export default LoginPage;
