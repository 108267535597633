import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import subMonths from 'date-fns/subMonths';
import format from 'date-fns/format';
import InfiniteScroll from 'react-infinite-scroller';
import qs from 'qs';

import Ellipsis from '@hiredigital/ui/MultiLineEllipsis/SingleLine';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import PaginatedGroupTree from '@hiredigital/ui/PaginatedGroupTree';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { toFormattedDateRange } from '@hiredigital/lib/helpers/date';

import { useUser } from '@context/user';
import { getOrgTimetrackerRelationships } from '@apis/organizations';
import { getEvents, deleteEvent } from '@apis/tracking';
import { useOrg } from '@context/org';
import { useCommon } from '@context/common';
import {
  getTrackingOrgs,
  getTrackingTask,
  getTrackingTimesheet,
  getTrackingUnlinkedTasksEvents,
} from '@apis/tracking';

import Layout from '@components/Layout/AppLayout';
import TitleBar from '@components/Layout/TitleBar';
import TimesheetEntry from '@components/TimesheetEntry/Entry';
import ClientsDropdown from '@components/ListFilters/ClientsDropdown';
import TalentsDropdown from '@components/ListFilters/TalentsDropdown';
import TimeFilter from '@components/ListFilters/TimeFilter/TimeFilter';

import ClientTimesheetEntry from './ClientTimesheetEntry';
import TalentTimesheetEntry from './TalentTimesheetEntry';
import DurationItem from './components/DurationItem';

import Styles from './Styles.module.scss';

const generateMonthsFilterList = () => {
  const months = [];
  const currentEndMonth = endOfMonth(new Date());
  for (var x = 0; x < 12; x++) {
    months.push(endOfMonth(subMonths(currentEndMonth, x)));
  }
  return months;
};

const monthOptions = generateMonthsFilterList()?.map((v, idx) => {
  return {
    label: format(v, 'MMMM yyyy'),
    value: String(idx + 1),
    end: v,
    start: startOfMonth(v),
  };
});

let timeout = null;

const TimesheetList = () => {
  const user = useUser();
  const searchRef = useRef();
  const org = useOrg();
  const common = useCommon();
  const params = useParams();

  const [talent, setTalent] = useState(params?.talent || undefined);
  const [filter, setFilter] = useState(monthOptions[0]);
  const [dateFilter, setDateFilter] = useState({
    start: monthOptions[0]?.start?.toISOString(),
    end: monthOptions[0]?.end?.toISOString(),
  });
  const [search, setSearch] = useState(params?.search || undefined);
  const [isShownEntry, setIsShownEntry] = useState(false);
  const [updateRow, setUpdateRow] = useState(undefined);
  const [newRow, setNewRow] = useState(undefined);
  const [timeEntry, setTimeEntry] = useState({});
  const [isShownConfirm, setIsShownConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [unlinkedItems, setUnlinkedItems] = useState();

  const [resultList, setResultList] = useState([]);
  const [meta, setMeta] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [orgFilter, setOrgFilter] = useState();
  const [renderKey, setRenderKey] = useState(1);
  const [isLoadingUnlinked, setIsLoadingUnlinked] = useState(false);
  const [isTalentViewAsList, setIsTalentViewAsList] = useState(false);

  useEffect(() => {
    if (!isTalentViewAsList) setResultList([]);
  }, [isTalentViewAsList]);

  const scrollParentRef = document.getElementById('bodyContainer');

  const groupColumns = [
    {
      field: ({ picture, name } = {}) =>
        picture && <img src={picture} alt={name} className={Styles.picture} />,
      flex: 0,
    },
    { field: 'name', flex: 1, fontWeight: 600 },
    {
      field: ({ durationFormat: { totalDuration } = {} } = {}) => totalDuration,
      className: Styles.duration,
      fontWeight: 600,
    },
  ];

  const filterMemo = useMemo(
    () => ({
      ...dateFilter,
      organization: orgFilter,
      search,
    }),
    [orgFilter, search, dateFilter]
  );

  useEffect(() => {
    if (params?.search && searchRef?.current) {
      searchRef.current.value = params?.search;
      setSearch(params?.search || undefined);
    }
  }, [searchRef?.current, params?.search]);

  useEffect(() => {
    // Add new row
    if (newRow) {
      setResultList((resultList) => [newRow, ...resultList]);
      if (!isTalentViewAsList) setRenderKey((p) => p + 1);
      setNewRow(undefined);
    }
  }, [newRow]);

  useEffect(() => {
    // Replace existing row
    if (updateRow) {
      setResultList((resultList) =>
        resultList.map((a) => (a.uuid === updateRow.uuid ? updateRow : a))
      );
      if (!isTalentViewAsList) {
        setResultList((resultList) =>
          resultList.find((a) => a.uuid === updateRow.uuid)
            ? resultList
            : [...resultList, updateRow]
        );
        setRenderKey((p) => p + 1);
      }
      setUpdateRow(undefined);
    }
  }, [updateRow]);

  useEffect(() => {
    setDateFilter({
      start: filter?.start?.toISOString(),
      end: filter?.end?.toISOString(),
    });
  }, [filter]);

  useEffect(() => {
    loadData(1);
  }, [search, dateFilter, talent, user?.uuid, org?.uuid, isTalentViewAsList]);

  const loadData = async (page) => {
    if (user?.isTalent && !isTalentViewAsList) return;
    if (!(user?.uuid || org?.uuid)) return;
    setLoading(true);

    const newFilter = talent ? { talent, ...dateFilter } : dateFilter;
    const config = {
      params: { page, search, ...newFilter },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }), // allow query string with same key
    };
    const {
      data: { meta, results },
    } =
      common?.isOrgRequested && org?.uuid
        ? await getOrgTimetrackerRelationships(org?.uuid, config)
        : await getEvents(config);
    const list = page === 1 ? results : [...resultList, ...results];

    setResultList(list);
    setMeta(meta);
    setHasMore(!!meta?.nextPage);
    setLoading(false);
  };

  const handleShowDelete = (item) => {
    setTimeEntry(item);
    setIsShownConfirm(true);
  };

  const handleDelete = useCallback(() => {
    setIsDeleting(true);
    deleteEvent(timeEntry?.uuid)
      .then(() => {
        setResultList((resultList) => resultList.filter((t) => t?.uuid !== timeEntry?.uuid));
        if (!isTalentViewAsList) setRenderKey((p) => p + 1);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsShownConfirm(false);
        setIsDeleting(false);
      });
  }, [timeEntry?.uuid]);

  const handleEdit = (entry) => {
    setTimeEntry(entry);
    setIsShownEntry(true);
  };

  const handleSearchChange = (value) => {
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleAddActivity = () => {
    setTimeEntry({ manual: true });
    setIsShownEntry(true);
  };

  const handleClearSearch = () => {
    setSearch(undefined);
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  const loadMoreItems = () => {
    const nextPage = meta?.nextPage;
    if (nextPage) loadData(nextPage);
  };

  const loadUnlinkedItems = async (config) => {
    try {
      setIsLoadingUnlinked(true);
      const { data: { unlinkedEvents, unlinkedTasks, durationFormat = {} } = {} } =
        (await getTrackingUnlinkedTasksEvents(config)) || {};

      setUnlinkedItems(
        Number(durationFormat?.totalDurationSeconds) > 0
          ? [
              {
                uuid: 1,
                name: 'No Organization',
                type: 'unlinked-items',
                durationFormat,
                children: [
                  {
                    uuid: 2,
                    name: 'Unlinked Events',
                    children: unlinkedEvents?.events,
                    durationFormat: unlinkedEvents?.durationFormat,
                    type: 'unlinked-events',
                  },
                  {
                    uuid: 3,
                    name: 'Unlinked Tasks',
                    children: unlinkedTasks?.tasks,
                    durationFormat: unlinkedTasks?.durationFormat,
                    type: 'unlinked-tasks',
                  },
                ],
              },
            ]
          : []
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingUnlinked(false);
    }
  };

  const getExpandedData = async ({ item, levelIndex, params: { start, end } = {} }) => {
    if (['unlinked-items', 'unlinked-tasks', 'unlinked-events'].includes(item.type)) {
      return item;
    }

    if (!levelIndex) return;
    const { data = {} } =
      (await [getTrackingTimesheet, getTrackingTask][levelIndex - 1](item.uuid, {
        params: { start, end },
      })) || {};
    return data;
  };

  const getChildrenProps = (item, levelIndex) => {
    const items = item?.timesheets || item?.tasks || item?.events || item?.children || [];
    const isUnlinkedEvent = item.type === 'unlinked-events';
    const isEvent = isUnlinkedEvent || levelIndex === 2;

    const columns = isEvent
      ? [
          {
            field: ({ startTime, endTime }) =>
              toFormattedDateRange(startTime, endTime, { formatType: 'medium' }),
            marginLeft: isUnlinkedEvent ? '15px' : '5px',
            fontWeight: 500,
          },
          {
            field: (data) => (
              <DurationItem data={data} onEdit={handleEdit} onDelete={handleShowDelete} />
            ),
            elem: 'div',
            className: Styles.duration,
            marginRight: 0,
          },
        ]
      : [
          { field: 'name', flex: 1 },
          {
            field: ({ durationFormat: { totalDuration } = {} } = {}) => totalDuration,
            className: Styles.duration,
          },
        ];

    return { items, hasChildren: !isEvent, columns };
  };

  const getTreeItemRenderProps = (item) => ({ isDisabled: !!item?.locked });

  useEffect(() => {
    loadUnlinkedItems({ params: { ...dateFilter } });
  }, [dateFilter, renderKey]);

  useEffect(() => {
    setPageTitle('Timesheets - Hire Digital');
  }, []);

  const isFiltered = !!(orgFilter || search);

  return (
    <Layout
      bodyClass={Styles.layoutBody}
      header={
        <TitleBar
          title={`Timesheets`}
          onClearSearch={handleClearSearch}
          onSearchChange={handleSearchChange}
          showSearch={!!search}
          inputRef={searchRef}
          noWrap={false}
          filterComponent={() =>
            user?.isClient ? (
              <>
                <MonthFilter filter={filter} onFilter={setFilter} />
                <TalentsDropdown talentUuid={talent} onFilter={(c) => setTalent(c)} />
              </>
            ) : (
              <>
                {!isTalentViewAsList && <MonthFilter filter={filter} onFilter={setFilter} />}
                <ClientsDropdown
                  talentUuid={talent}
                  onFilter={({ uuid: c, organization: { uuid } = {} } = {}) => {
                    setTalent(c);
                    setOrgFilter(uuid);
                  }}
                />
              </>
            )
          }>
          {user?.isTalent && (
            <Button type={Button.Type.DARKBLUE} onClick={handleAddActivity}>
              {`Add Activity`}
            </Button>
          )}
        </TitleBar>
      }>
      {common?.isReady && (
        <div className={Styles.tableContainer}>
          {user?.isClient ? (
            common?.isOrgRequested && (
              <div>
                <div style={{ display: 'flex' }} className={classNames(Styles.fd, Styles.fd3)}>
                  <div style={{ width: '80%' }} className={Styles.fd1}>
                    <span style={{ marginLeft: '10px' }}>
                      <Ellipsis>{'Talents & Assignments'}</Ellipsis>
                    </span>
                  </div>
                  <div style={{ width: '20%', justifyContent: 'center' }} className={Styles.fd1}>
                    {'Duration'}
                  </div>
                </div>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={1}
                  loadMore={loadMoreItems}
                  hasMore={hasMore}
                  threshold={100}
                  useWindow={false}
                  getScrollParent={() => scrollParentRef}>
                  {resultList.map((data, index) => {
                    return (
                      <div key={index} className={classNames(Styles.list, Styles.withSpacing)}>
                        <ClientTimesheetEntry data={data} filter={dateFilter} />
                      </div>
                    );
                  })}
                  {loading && (
                    <div className={classNames(Styles.fd2, Styles.fd4)}>
                      <Loader />
                    </div>
                  )}

                  {!loading && !resultList.length && (
                    <div className={classNames(Styles.fd2, Styles.fd4)} style={{ padding: '20px' }}>
                      {`No Activity Found`}
                    </div>
                  )}
                </InfiniteScroll>
              </div>
            )
          ) : (
            // user is talent
            <div className={Styles.groupTreeContainer}>
              {!isTalentViewAsList && !!resultList.length && (
                <>
                  <ActivityListHeader recentActionsOnly />
                  {resultList.map((data, index) => (
                    <div key={index} className={Styles.list}>
                      <TalentTimesheetEntry
                        data={data}
                        handleEdit={handleEdit}
                        handleDelete={handleShowDelete}
                      />
                    </div>
                  ))}
                  <div style={{ padding: '15px' }} />
                </>
              )}
              <div className={Styles.viewActivityBar}>
                <span className={Styles.spanViewActivity}>
                  {isTalentViewAsList
                    ? `You're viewing your activity as a list with the recent entries on top.`
                    : `You're viewing your activity grouped by your clients and assignments.`}
                </span>
                <Button
                  onClick={() => setIsTalentViewAsList(!isTalentViewAsList)}
                  type={Button.Type.BLUE_OUTLINE}
                  className={Styles.btnViewActivity}>
                  {isTalentViewAsList ? `Group by Clients` : `View as a List`}
                </Button>
              </div>
              {isTalentViewAsList ? (
                <>
                  <ActivityListHeader />
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={1}
                    loadMore={loadMoreItems}
                    hasMore={hasMore}
                    threshold={10}
                    useWindow={false}
                    getScrollParent={() => scrollParentRef}>
                    {resultList.map((data, index) => (
                      <div key={index} className={Styles.list}>
                        <TalentTimesheetEntry
                          data={data}
                          handleEdit={handleEdit}
                          handleDelete={handleShowDelete}
                        />
                      </div>
                    ))}
                    {loading && (
                      <div className={classNames(Styles.fd2, Styles.fd4)}>
                        <Loader />
                      </div>
                    )}

                    {!loading && !resultList.length && (
                      <div
                        className={classNames(Styles.fd2, Styles.fd4)}
                        style={{ padding: '20px' }}>
                        {`No Activity Found`}
                      </div>
                    )}
                  </InfiniteScroll>
                </>
              ) : (
                <>
                  <div
                    style={{ display: 'flex', paddingRight: '45px' }}
                    className={classNames(Styles.fd, Styles.fd3)}>
                    <div style={{ flex: 1 }} className={Styles.fd1}>
                      {'Activity'}
                    </div>
                    <div style={{ flex: 0 }} className={Styles.fd1}>
                      {'Duration'}
                    </div>
                  </div>
                  <PaginatedGroupTree
                    key={renderKey}
                    filter={filterMemo}
                    getListRequest={getTrackingOrgs}
                    getExpandedData={getExpandedData}
                    getChildrenProps={getChildrenProps}
                    getTreeItemRenderProps={getTreeItemRenderProps}
                    groupColumns={groupColumns}
                    appendItems={isFiltered || isLoadingUnlinked ? [] : unlinkedItems}
                    scrollParent={scrollParentRef}
                    emptyContainerClass={Styles.emptyContainer}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}
      {isShownEntry && (
        <TimesheetEntry
          isShown={true}
          onClose={() => setIsShownEntry(false)}
          data={timeEntry}
          onUpdate={setUpdateRow}
          onAdd={setNewRow}
          OverlapEntryComponent={TalentTimesheetEntry}
        />
      )}
      <Dialog
        title={`Delete Activity`}
        description={
          <>
            <p>Are you sure you want to delete this activity?</p>
            <TalentTimesheetEntry
              data={timeEntry}
              showActionColumn={false}
              className={Styles.deleteDialogEntry}
            />
          </>
        }
        minWidth='450px'
        isOpen={isShownConfirm}
        onClose={() => setIsShownConfirm(false)}>
        <Button onClick={handleDelete} type={Button.Type.BLUE} isLoading={isDeleting}>
          {'Delete'}
        </Button>
        <Button onClick={() => setIsShownConfirm(false)}>{'Cancel'}</Button>
      </Dialog>
    </Layout>
  );
};

const MonthFilter = ({ filter, onFilter }) => (
  <>
    <div className={Styles.timeFilterLabel}>{TimeFilter.LABEL}:</div>
    <TimeFilter
      options={[{ label: 'All Time', value: 'all' }, ...monthOptions]}
      filter={filter}
      onFilter={onFilter}
    />
  </>
);

const ActivityListHeader = ({ recentActionsOnly }) => (
  <div style={{ display: 'flex' }} className={classNames(Styles.fd, Styles.fd3)}>
    <div style={{ width: '60%' }} className={Styles.fd1}>
      <Ellipsis>
        {recentActionsOnly ? 'Recently Added or Edited on This Page' : 'Activity'}
      </Ellipsis>
    </div>
    <div style={{ width: '20%' }} className={Styles.fd1}>
      <Ellipsis>{'Date & Time'}</Ellipsis>
    </div>
    <div style={{ width: '20%' }} className={Styles.fd1}>
      {'Duration'}
    </div>
  </div>
);

export default TimesheetList;
