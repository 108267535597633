import { authRequest } from './utils';

export const saveAttachment = async (file) => {
  if (file) {
    const formData = new FormData();
    formData.append('attachment', file, file.fileName || file.name);

    const url = `attachments/`;
    return await authRequest.post(url, formData);
  } else {
    return null;
  }
};

export const createAdminSkill = async (value) => {
  const data = { label: value, adminGenerated: true };
  const url = `admin/skills/`;
  return await authRequest.post(url, data);
};

// export const getTopics = async () => {
//   if (TopicService.hasData) {
//     return TopicService.getData();
//   } else {
//     const url = `topics`;
//     const data = await appRequest.get(url);
//     TopicService.setData(data);
//     return data;
//   }
// };

export const getCreditPackages = async (config = undefined) => {
  const url = `credit-packages/`;
  return await authRequest.get(url, config);
};

export const getCurrency = async (code, config = undefined) => {
  const url = `currencies/${code}/`;
  return await authRequest.get(url, config);
};

export const getDemographics = async (config = undefined) => {
  const url = `demographics/`;
  return await authRequest.get(url, config);
};

export const getTalentTypes = async (config = undefined) => {
  const url = `talent-types/`;
  return await authRequest.get(url, config);
};

export const getTalentTypeSkills = async (id, config = undefined) => {
  const url = `talent-types/${id}/skills/`;
  return await authRequest.get(url, config);
};

export const getFormats = async (config = undefined) => {
  const url = `formats/`;
  return await authRequest.get(url, config);
};

export const postInterest = async (data, config = undefined) => {
  const url = `public/interest/`;
  return await authRequest.post(url, data, config);
};

export const getClients = async (config = undefined) => {
  const url = `clients/`;
  return await authRequest.get(url, config);
};

export const getClient = async (uuid, config = undefined) => {
  const url = `clients/${uuid}/`;
  return await authRequest.get(url, config);
};

export const getCities = async (search, config = undefined) => {
  const url = `gcities/?search=${search}`;
  return await authRequest.get(url, config);
};

export const postLocations = async (data, config = undefined) => {
  const url = `locations/`;
  return await authRequest.post(url, data, config);
};

export const getOEmbed = async (data) => {
  const url = `iframely/oembed/`;
  return await authRequest.get(url, { params: data });
};

export const getIframely = async (data) => {
  const url = `iframely/iframely/`;
  return await authRequest.get(url, { params: data });
};
