import { useState, useEffect } from 'react';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import Layout from '@components/Layout/AppLayout';
import Profile from '@components/Profile/CardProfile';

const IndexPage = () => {
  const [scrollContainer, setScrollContainer] = useState(null);
  useEffect(() => {
    setPageTitle('Profile - Hire Digital');
  }, []);
  return (
    <Layout getScrollContainer={setScrollContainer} style={{ background: 'white' }}>
      <Profile scrollContainer={scrollContainer} isOnboarding={false} />
    </Layout>
  );
};

export default IndexPage;
