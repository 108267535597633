import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { postLinkMetadata, getIframeCheck } from '@hiredigital/lib/apis/metadata';
import FileViewer from '../FileViewer/FileViewer';
import Styles from './Iframe.module.scss';

const NewIframe = ({ request: req, iframe, className, ...props }) => {
  const [failed, setFailed] = useState();
  const [isLoaded, setLoaded] = useState();
  const [previewHtml, setPreviewHtml] = useState();
  const [trustedUrl, setTrustedUrl] = useState();
  const [previewUrl, setPreviewUrl] = useState();

  useEffect(() => {
    getLink(req, iframe);
  }, [iframe]);

  const getLink = (request, iframe) => {
    setLoaded(false);
    getIframeCheck(request, iframe).then(
      (response) => {
        setLoaded(true);
        setFailed(false);
      },
      (error) => {
        const hostname = extractHostname(iframe);
        const embed = iframe.substr(iframe.lastIndexOf('/') + 1);
        const videoFileLink = ['youtu.be', 'www.youtube.com'];
        const vimeoFileLink = ['vimeo.com'];
        const trelloLink = ['trello.com'];
        const instagramLink = ['www.instagram.com'];

        if (trelloLink.indexOf(hostname) >= 0) {
          setPreviewHtml('<iframe src="' + iframe + '.html"></iframe>');
          setTrustedUrl(iframe + '.html');
          setPreviewUrl(iframe + '.html');
          setFailed(false);
        } else if (videoFileLink.indexOf(hostname) >= 0) {
          const videoId = embed.replace('watch?v=', ''); // make sure video id is extracted from both youtube url
          setTrustedUrl('https://www.youtube.com/embed/' + videoId);
          setFailed(false);
        } else if (vimeoFileLink.indexOf(hostname) >= 0) {
          setTrustedUrl('https://player.vimeo.com/video/' + embed);
          setFailed(false);
        } else if (instagramLink.indexOf(hostname) >= 0) {
          setTrustedUrl(iframe.split('?')[0].replace(/\/$/, '') + '/embed');
          setFailed(false);
        } else {
          const data = {
            url: iframe,
          };

          postLinkMetadata(request, data).then((response) => {
            setPreviewHtml(response.html);
            setPreviewUrl(response.thumbnail_url);
            setFailed(false);
          });
          setTrustedUrl(error.thumbnailUrl);
        }
        setLoaded(true);
      }
    );
  };

  const extractHostname = (url) => {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf('://') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  };

  return (
    <div className={className}>
      {previewHtml && !failed && <div dangerouslySetInnerHTML={{ __html: previewHtml }} />}

      {!previewHtml && !previewUrl && !failed && (
        <iframe frameBorder='0' className={Styles.iframe} src={trustedUrl} />
      )}

      {previewUrl && !failed && !previewHtml && (
        <FileViewer className={Styles.urlPreview} force={true} url={previewUrl} />
      )}

      {failed && isLoaded && (
        <div className={Styles.iframeError}>
          URL does not allow external rendering.
          <a href={trustedUrl} target='_blank'>
            Click here to view in a new tab
          </a>
        </div>
      )}
    </div>
  );
};

NewIframe.propTypes = {
  iframe: PropTypes.string,
  className: PropTypes.string,
};

export default NewIframe;
