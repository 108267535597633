import Cookies from 'universal-cookie';
import { useState, useEffect } from 'react';
import Styles from './Styles.module.scss';

const AdminWrapper = ({ children }) => {
  const [suid, setSuid] = useState();
  const [sun, setSun] = useState();
  const [ready, setReady] = useState(false);
  const cookies = new Cookies();

  useEffect(() => {
    const uuid = cookies.get('__suid');
    const user = cookies.get('__sun');

    setSuid(uuid);
    setSun(user);
    if (uuid) {
      setReady(true);
    }

    return () => {};
  }, []);

  const resetView = () => {
    cookies.remove('__suid');
    cookies.remove('__sun');
    setSuid(undefined);
    setSun(undefined);
    setReady(false);
    // remove
  };

  const hideView = () => {
    setReady(false);
  };

  return (
    <>
      {ready && (
        <div className={Styles.container}>
          <div className={Styles.text}>{`Currently viewing as ${sun}`}</div>
          <div className={Styles.action}>
            <div className={Styles.button} onClick={resetView}>{`Reset`}</div>
            <div className={Styles.button} onClick={hideView}>{`Hide`}</div>
          </div>
        </div>
      )}
      <>{children}</>
    </>
  );
};

export default AdminWrapper;
