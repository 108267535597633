import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TruncateString from 'react-truncate-string';
import Dropzone from 'react-dropzone';
import { formatBytes, getName, ensureHttpPrefix } from '@hiredigital/lib/helpers/utils';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import FileIcon from '../../Icon/FileIcon';
import Styles from './Styles.module.scss';

import Dialog from '../../Dialog/Dialog';

const References = (props) => {
  // this was updated to use individual state instead of creating a big object
  // const [attachments] = useState(props.attachments || []);
  // const [links] = useState(props.links || []);
  const [list, setList] = useState([]);
  const [isLinkFieldShown, setIsLinkFieldShown] = useState(false);
  const [filesUploading, setFilesUploading] = useState(false);
  const [linkValue, setLinkValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShownConfirm, setIsShownConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selected, setSelected] = useState(null);
  // const [percent, setPercent] = useState(0);

  useEffect(() => {
    const links = props?.links || [];
    const attachments = props?.attachments || [];

    const orderedList = links.concat(attachments).sort((a, b) => {
      if (a.created > b.created) return 1;
      if (b.created > a.created) return -1;
      return 0;
    });

    setList(orderedList);
  }, [props?.attachments, props?.links]);

  const handleLinkCancel = () => {
    setLinkValue('');
    setIsLinkFieldShown(false);
  };

  const handleLinkChange = (event) => {
    const updated = ensureHttpPrefix(event.target.value);
    setLinkValue(updated);
    setErrorMessage('');
  };

  const handleLinkCreate = () => {
    setIsLoading(true);

    if (!linkValue) {
      setTimeout(() => {
        setErrorMessage('Enter a valid URL');
        setIsLoading(false);
      }, 500);
      return;
    }

    const payload = {
      [props.linkField]: linkValue,
    };

    props
      .postLinkRequest(payload)
      .then((response) => {
        setList([...list, response.data]);
        setLinkValue('');
        setIsLoading(false);
        setIsLinkFieldShown(false);
      })
      .catch((error) => {
        const msg = error?.response?.data?.[props.linkField]?.[0];
        setErrorMessage(msg || 'Something went wrong!');
        setIsLoading(false);
      });
  };

  const uploadPromise = (file, index) => {
    const formData = new FormData();
    if (file) {
      formData.append(props.attachmentField, file, file.fileName || file.name);
    }

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      // onUploadProgress: (progressEvent) => {
      //   const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //   if (progress >= 100) {
      //     setPercent(100);
      //   } else {
      //     setPercent(progress);
      //   }
      // },
    };

    return new Promise(async (resolve, reject) => {
      try {
        const response = await props.postFileRequest(formData, config);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  };

  const handleDrop = async (acceptedFiles) => {
    setFilesUploading(true);
    const fileActions = acceptedFiles.map(uploadPromise);
    const results = Promise.all(fileActions);

    results.then((response) => {
      setList([...list, ...response.map((r) => r.data)]);
      setFilesUploading(false);
    });
  };

  const handleConfirmDelete = () => {
    const remove = selected?.url ? deleteLink : deleteFile;
    remove(selected, () => {
      setSelected(null);
      setIsShownConfirm(false);
    });
  };

  const deleteLink = (link, callback) => {
    setIsDeleting(true);
    props
      .deleteLinkRequest(link?.id)
      .then(() => {
        setList(list.filter((v) => v.id !== link.id));
        setIsDeleting(false);
        callback?.();
      })
      .catch((error) => {
        setIsDeleting(false);
        console.error(error);
      });
  };

  const deleteFile = (file, callback) => {
    setIsDeleting(true);
    props
      .deleteFileRequest(file.id)
      .then(() => {
        setList(list.filter((v) => v.id !== file.id));
        setIsDeleting(false);
        callback?.();
      })
      .catch((error) => {
        setIsDeleting(false);
        console.log(error);
      });
  };

  const handleDelete = (item) => {
    setSelected(item);
    setIsShownConfirm(true);
  };

  const handleKeyPress = (event) => {
    // For passing up keypresses + autoselect
    if (event.which === 13) {
      handleLinkCreate();
    }
  };

  const toggleLinkHandler = () => {
    setIsLinkFieldShown(!isLinkFieldShown);
    setErrorMessage('');
  };

  const renderedList = list.map((item, index) => {
    return (
      <div
        className={classNames(Styles.item, props.fixedWidthList && Styles.fixedItem)}
        key={item.id}>
        <FileIcon className={Styles.icon} url={item.attachment || item.url} />
        <div className={Styles.left}>
          <a
            className={Styles.primary}
            name={item.attachment || item.url}
            href={item.attachment || item.url}
            target='_blank'
            rel='noopener noreferrer'>
            <TruncateString text={getName(item.attachment) || item.title || item.url} />
          </a>
          <p className={Styles.secondary}>
            {(item.size && formatBytes(item.size)) || item.url || 'Link'}
          </p>
        </div>
        {props.showActions && (
          <div className={Styles.remove} onClick={() => handleDelete(item)}>
            <IconClose className={Styles.closeIcon} />
          </div>
        )}
      </div>
    );
  });

  return (
    <div
      className={classNames(
        props.className,
        Styles.newContainer,
        Styles.multi,
        props.attachments && props.attachments.length > 0 && Styles.hasFile
      )}>
      {list.length ? <div className={Styles.list}>{renderedList}</div> : ''}

      {props.hasMore && !isLinkFieldShown && (
        <div className={Styles.moreActionArea}>
          {props.isLoadingMore ? (
            <Loader size={Loader.Size.SMALL} />
          ) : (
            <button
              onClick={() => props.onLoadMore?.()}
              className={Styles.loadMore}>{`Load more`}</button>
          )}
        </div>
      )}

      {props.showActions && (
        <div className={Styles.row}>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={Styles.addItem}>
                <input {...getInputProps()} />
                {filesUploading && <Loader color={Loader.Color.WHITE} size={Loader.Size.SMALL} />}
                {`+ ${props.attachmentLabel}`}
              </div>
            )}
          </Dropzone>
          {isLinkFieldShown ? (
            <div className={classNames(Styles.field, errorMessage && Styles.hasError)}>
              {errorMessage && (
                <label htmlFor={'links'} className={Styles.label}>
                  {errorMessage}
                </label>
              )}
              <input
                className={Styles.fieldInput}
                id={'links'}
                name={'links'}
                type='text'
                value={linkValue}
                placeholder='Add link'
                onChange={handleLinkChange}
                onKeyPress={handleKeyPress}
              />
              <Button
                className={Styles.fieldButton}
                type={Button.Type.WHITE}
                onClick={handleLinkCancel}>
                {`Cancel`}
              </Button>
              <Button
                className={Styles.fieldButton}
                type={Button.Type.BLUE}
                onClick={handleLinkCreate}>
                {(isLoading && <Loader color={Loader.Color.WHITE} size={Loader.Size.SMALL} />) ||
                  `Add`}
              </Button>
            </div>
          ) : (
            <>
              {!props.disableAddLink && (
                <div className={Styles.addItem} onClick={toggleLinkHandler}>
                  {`+ ${props.linkLabel}`}
                </div>
              )}
            </>
          )}
        </div>
      )}
      <Dialog
        title={`Delete File`}
        description={
          <>
            {`Confirm that you want to delete `}
            <strong>{selected?.title || selected?.url || getName(selected?.attachment)}</strong>
          </>
        }
        isOpen={isShownConfirm}
        onClose={() => setIsShownConfirm(false)}>
        <Button onClick={handleConfirmDelete} type={Button.Type.BLUE} isLoading={isDeleting}>
          Delete
        </Button>
        <Button onClick={() => setIsShownConfirm(false)}>Cancel</Button>
      </Dialog>
    </div>
  );
};

References.propTypes = {
  // Shared Props
  server: PropTypes.string,
  className: PropTypes.string,
  fixedWidthList: PropTypes.bool,
  // Attachment Props
  attachments: PropTypes.array,
  // attachmentName: PropTypes.string,
  attachmentField: PropTypes.string,
  attachmentLabel: PropTypes.string,
  // Link Props
  links: PropTypes.array,
  linkField: PropTypes.string,
  linkLabel: PropTypes.string,
  postFileRequest: PropTypes.func,
  deleteFileRequest: PropTypes.func,
  postLinkRequest: PropTypes.func,
  deleteLinkRequest: PropTypes.func,
  showActions: PropTypes.bool,
  disableAddLink: PropTypes.bool,
  hasMore: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
};

References.defaultProps = {
  attachmentField: 'attachment',
  attachmentLabel: 'Add File',
  linkField: 'url',
  linkLabel: 'Add Link',
  showActions: true,
  disableAddLink: false,
  hasMore: false,
  isLoadingMore: false,
};

export default memo(References);
