import classNames from 'classnames';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import Styles from './Header.module.scss';

const Header = ({ onToggleCollapse, onClose, isCollapse }) => {
  return (
    <div className={Styles.header}>
      <div onClick={onToggleCollapse} className={Styles.title}>
        {`Discussions`}
      </div>
      <div className={Styles.actions}>
        <div
          onClick={onToggleCollapse}
          className={classNames(
            Styles.btnAction,
            Styles.headerBtn,
            isCollapse && Styles.btnCollapse
          )}
          data-test-id={`minmax-discussion-id-${isCollapse ? 'minimize' : 'maximize'}`}>
          <IconDown />
        </div>
        <div
          onClick={onClose}
          className={classNames(Styles.btnAction, Styles.headerBtn)}
          data-test-id='close-discussion-id'>
          <IconClose />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {};

export default Header;
