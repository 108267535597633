import PropTypes from 'prop-types';
import Styles from './Card.module.scss';
import classNames from 'classnames';

const CardAction = ({ className, children, ...props }) => {
  return (
    <div className={classNames(Styles.action, className)} {...props}>
      {children}
    </div>
  );
};

CardAction.propTypes = {
  children: PropTypes.node,
};

export default CardAction;
