export const topicList = [
  {
    id: 1,
    label: 'Automotive',
    specialty: true,
    children: [],
  },
  {
    id: 2,
    label: 'Business & Finance',
    specialty: true,
    children: [
      {
        id: 51,
        label: 'Business',
        specialty: true,
        children: [
          {
            id: 105,
            label: 'Entrepreneurship',
            specialty: true,
            children: [],
          },
          {
            id: 106,
            label: 'Marketing',
            specialty: true,
            children: [],
          },
          {
            id: 107,
            label: 'Media',
            specialty: true,
            children: [],
          },
          {
            id: 108,
            label: 'Advertising',
            specialty: true,
            children: [],
          },
          {
            id: 109,
            label: 'Sales',
            specialty: true,
            children: [],
          },
          {
            id: 110,
            label: 'Energy and Commodities',
            specialty: true,
            children: [],
          },
          {
            id: 111,
            label: 'Business Development',
            specialty: true,
            children: [],
          },
          {
            id: 112,
            label: 'Customer Success',
            specialty: true,
            children: [],
          },
          {
            id: 113,
            label: 'Corporate Wellness',
            specialty: true,
            children: [],
          },
          {
            id: 114,
            label: 'Human Resources',
            specialty: true,
            children: [],
          },
          {
            id: 115,
            label: 'SaaS',
            specialty: true,
            children: [],
          },
          {
            id: 116,
            label: 'Product Design',
            specialty: true,
            children: [],
          },
          {
            id: 117,
            label: 'Productivity',
            specialty: true,
            children: [],
          },
        ],
      },
      {
        id: 52,
        label: 'Finance',
        specialty: true,
        children: [
          {
            id: 93,
            label: 'Corporate Finance',
            specialty: true,
            children: [],
          },
          {
            id: 94,
            label: 'Personal Finance',
            specialty: true,
            children: [],
          },
          {
            id: 95,
            label: 'Investment Management',
            specialty: true,
            children: [],
          },
          {
            id: 96,
            label: 'Real Estate / Property',
            specialty: true,
            children: [],
          },
          {
            id: 97,
            label: 'Banking',
            specialty: true,
            children: [],
          },
          {
            id: 98,
            label: 'Financial Services',
            specialty: true,
            children: [],
          },
          {
            id: 99,
            label: 'Capital Markets',
            specialty: true,
            children: [],
          },
          {
            id: 100,
            label: 'Commercial Real Estate',
            specialty: true,
            children: [],
          },
          {
            id: 101,
            label: 'Insurance',
            specialty: true,
            children: [],
          },
          {
            id: 102,
            label: 'Venture Capital & Private Equity',
            specialty: true,
            children: [],
          },
          {
            id: 103,
            label: 'Investment Banking',
            specialty: true,
            children: [],
          },
          {
            id: 104,
            label: 'Accounting',
            specialty: true,
            children: [],
          },
        ],
      },
      {
        id: 53,
        label: 'Services',
        specialty: true,
        children: [
          {
            id: 121,
            label: 'Food Services',
            specialty: true,
            children: [],
          },
          {
            id: 122,
            label: 'Information Services',
            specialty: true,
            children: [],
          },
          {
            id: 123,
            label: 'Events Services',
            specialty: true,
            children: [],
          },
          {
            id: 124,
            label: 'Consumer Services',
            specialty: true,
            children: [],
          },
          {
            id: 125,
            label: 'Travel and Tourism Services',
            specialty: true,
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    label: 'Energy & Manufacturing',
    specialty: true,
    children: [
      {
        id: 73,
        label: 'Manufacturing',
        specialty: true,
        children: [],
      },
      {
        id: 74,
        label: 'Logistics and Supply Chain',
        specialty: true,
        children: [],
      },
      {
        id: 75,
        label: 'Renewable Energy',
        specialty: true,
        children: [],
      },
      {
        id: 76,
        label: 'Emerging Technologies',
        specialty: true,
        children: [],
      },
      {
        id: 77,
        label: 'Oil & Fossil Fuels',
        specialty: true,
        children: [],
      },
    ],
  },
  {
    id: 4,
    label: 'Human Resources & Recruitment',
    specialty: true,
    children: [
      {
        id: 29,
        label: 'Staffing and Recruiting',
        specialty: true,
        children: [],
      },
      {
        id: 30,
        label: 'Outsourcing / Offshoring',
        specialty: true,
        children: [],
      },
      {
        id: 31,
        label: 'Human Resources',
        specialty: true,
        children: [],
      },
      {
        id: 32,
        label: 'Professional Training & Coaching',
        specialty: true,
        children: [],
      },
    ],
  },
  {
    id: 5,
    label: 'Lifestyle',
    specialty: false,
    children: [
      {
        id: 33,
        label: 'Food & Beverage',
        specialty: false,
        children: [],
      },
      {
        id: 34,
        label: 'Home & Living',
        specialty: false,
        children: [],
      },
      {
        id: 35,
        label: 'Nutrition, Wellness & Fitness',
        specialty: false,
        children: [],
      },
      {
        id: 36,
        label: 'Retirement',
        specialty: false,
        children: [],
      },
      {
        id: 37,
        label: 'Wedding',
        specialty: false,
        children: [],
      },
      {
        id: 38,
        label: 'Art',
        specialty: false,
        children: [
          {
            id: 87,
            label: 'Architecture & Design',
            specialty: false,
            children: [],
          },
          {
            id: 88,
            label: 'Arts & Culture',
            specialty: false,
            children: [],
          },
          {
            id: 89,
            label: 'Design',
            specialty: false,
            children: [],
          },
          {
            id: 90,
            label: 'Film & Entertainment',
            specialty: false,
            children: [],
          },
          {
            id: 91,
            label: 'Photography',
            specialty: false,
            children: [],
          },
          {
            id: 92,
            label: 'Writing and Editing',
            specialty: false,
            children: [],
          },
        ],
      },
      {
        id: 39,
        label: 'Entertainment',
        specialty: false,
        children: [
          {
            id: 118,
            label: 'Entertainment',
            specialty: false,
            children: [],
          },
          {
            id: 119,
            label: 'Gambling & Casinos',
            specialty: false,
            children: [],
          },
          {
            id: 120,
            label: 'Music',
            specialty: false,
            children: [],
          },
        ],
      },
      {
        id: 40,
        label: 'Fashion',
        specialty: false,
        children: [
          {
            id: 84,
            label: 'Beauty',
            specialty: false,
            children: [],
          },
          {
            id: 85,
            label: 'Men’s Style',
            specialty: false,
            children: [],
          },
          {
            id: 86,
            label: 'Women’s Style',
            specialty: false,
            children: [],
          },
        ],
      },
      {
        id: 41,
        label: 'Food',
        specialty: false,
        children: [
          {
            id: 78,
            label: 'Restaurants',
            specialty: false,
            children: [],
          },
          {
            id: 79,
            label: 'Wine and Spirits',
            specialty: false,
            children: [],
          },
        ],
      },
      {
        id: 42,
        label: 'Recreation',
        specialty: false,
        children: [
          {
            id: 80,
            label: 'Gaming',
            specialty: false,
            children: [],
          },
          {
            id: 81,
            label: 'Events',
            specialty: false,
            children: [],
          },
          {
            id: 82,
            label: 'Sports',
            specialty: false,
            children: [],
          },
          {
            id: 83,
            label: 'Shopping',
            specialty: false,
            children: [],
          },
        ],
      },
      {
        id: 43,
        label: 'Parenting',
        specialty: false,
        children: [],
      },
    ],
  },
  {
    id: 6,
    label: 'Nutrition & Healthcare',
    specialty: true,
    children: [
      {
        id: 44,
        label: 'Hospital & Healthcare',
        specialty: true,
        children: [],
      },
      {
        id: 45,
        label: 'Health, Wellness and Fitness',
        specialty: true,
        children: [],
      },
      {
        id: 46,
        label: 'Mental Healthcare',
        specialty: true,
        children: [],
      },
      {
        id: 47,
        label: 'Pharmaceuticals',
        specialty: true,
        children: [],
      },
      {
        id: 48,
        label: 'Veterinary',
        specialty: true,
        children: [],
      },
      {
        id: 49,
        label: 'Medical Devices',
        specialty: true,
        children: [],
      },
      {
        id: 50,
        label: 'Senior Care',
        specialty: true,
        children: [],
      },
    ],
  },
  {
    id: 7,
    label: 'Technology & Innovation',
    specialty: true,
    children: [
      {
        id: 62,
        label: 'Consumer Technology',
        specialty: true,
        children: [],
      },
      {
        id: 63,
        label: 'Enterprise Technology',
        specialty: true,
        children: [],
      },
      {
        id: 64,
        label: 'Innovation',
        specialty: true,
        children: [],
      },
      {
        id: 65,
        label: 'High Tech',
        specialty: true,
        children: [
          {
            id: 126,
            label: 'Defense',
            specialty: true,
            children: [],
          },
          {
            id: 127,
            label: 'Space',
            specialty: true,
            children: [],
          },
          {
            id: 128,
            label: 'Biotechnology',
            specialty: true,
            children: [],
          },
          {
            id: 129,
            label: 'Software',
            specialty: true,
            children: [],
          },
          {
            id: 130,
            label: 'Big Data',
            specialty: true,
            children: [],
          },
          {
            id: 131,
            label: 'Cybersecurity',
            specialty: true,
            children: [],
          },
          {
            id: 132,
            label: 'Automation',
            specialty: true,
            children: [],
          },
          {
            id: 133,
            label: 'Information Technology and Services',
            specialty: true,
            children: [],
          },
          {
            id: 134,
            label: 'Internet',
            specialty: true,
            children: [],
          },
          {
            id: 135,
            label: 'Cloud Computing',
            specialty: true,
            children: [],
          },
          {
            id: 136,
            label: 'Nanotechnology',
            specialty: true,
            children: [],
          },
          {
            id: 137,
            label: 'Electronics & Hardware',
            specialty: true,
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 8,
    label: 'Travel & Hospitality',
    specialty: false,
    children: [
      {
        id: 27,
        label: 'Hospitality',
        specialty: false,
        children: [],
      },
      {
        id: 28,
        label: 'Travel & Tourism',
        specialty: false,
        children: [],
      },
    ],
  },
  {
    id: 9,
    label: 'Other',
    specialty: true,
    children: [],
  },
  {
    id: 24,
    label: 'Politics & Law',
    specialty: true,
    children: [
      {
        id: 54,
        label: 'International Affairs',
        specialty: true,
        children: [],
      },
      {
        id: 55,
        label: 'Politics',
        specialty: true,
        children: [],
      },
      {
        id: 56,
        label: 'Military',
        specialty: true,
        children: [],
      },
      {
        id: 57,
        label: 'Public Policy',
        specialty: true,
        children: [],
      },
      {
        id: 58,
        label: 'Law Enforcement',
        specialty: true,
        children: [],
      },
      {
        id: 59,
        label: 'Business Law',
        specialty: true,
        children: [],
      },
      {
        id: 60,
        label: 'Property Law',
        specialty: true,
        children: [],
      },
      {
        id: 61,
        label: 'Personal Law',
        specialty: true,
        children: [],
      },
    ],
  },
  {
    id: 25,
    label: 'Science',
    specialty: true,
    children: [],
  },
  {
    id: 26,
    label: 'Education',
    specialty: true,
    children: [
      {
        id: 66,
        label: 'Early childhood',
        specialty: true,
        children: [],
      },
      {
        id: 67,
        label: 'Primary Education',
        specialty: true,
        children: [],
      },
      {
        id: 68,
        label: 'Secondary Education',
        specialty: true,
        children: [],
      },
      {
        id: 69,
        label: 'University Education',
        specialty: true,
        children: [],
      },
      {
        id: 70,
        label: 'Continuing Education',
        specialty: true,
        children: [],
      },
      {
        id: 71,
        label: 'Education Services',
        specialty: true,
        children: [],
      },
      {
        id: 72,
        label: 'E-learning',
        specialty: true,
        children: [],
      },
    ],
  },
  {
    id: 27,
    label: 'Hospitality',
    specialty: false,
    children: [],
  },
  {
    id: 28,
    label: 'Travel & Tourism',
    specialty: false,
    children: [],
  },
  {
    id: 29,
    label: 'Staffing and Recruiting',
    specialty: true,
    children: [],
  },
  {
    id: 30,
    label: 'Outsourcing / Offshoring',
    specialty: true,
    children: [],
  },
  {
    id: 31,
    label: 'Human Resources',
    specialty: true,
    children: [],
  },
  {
    id: 32,
    label: 'Professional Training & Coaching',
    specialty: true,
    children: [],
  },
  {
    id: 33,
    label: 'Food & Beverage',
    specialty: false,
    children: [],
  },
  {
    id: 34,
    label: 'Home & Living',
    specialty: false,
    children: [],
  },
  {
    id: 35,
    label: 'Nutrition, Wellness & Fitness',
    specialty: false,
    children: [],
  },
  {
    id: 36,
    label: 'Retirement',
    specialty: false,
    children: [],
  },
  {
    id: 37,
    label: 'Wedding',
    specialty: false,
    children: [],
  },
  {
    id: 38,
    label: 'Art',
    specialty: false,
    children: [
      {
        id: 87,
        label: 'Architecture & Design',
        specialty: false,
        children: [],
      },
      {
        id: 88,
        label: 'Arts & Culture',
        specialty: false,
        children: [],
      },
      {
        id: 89,
        label: 'Design',
        specialty: false,
        children: [],
      },
      {
        id: 90,
        label: 'Film & Entertainment',
        specialty: false,
        children: [],
      },
      {
        id: 91,
        label: 'Photography',
        specialty: false,
        children: [],
      },
      {
        id: 92,
        label: 'Writing and Editing',
        specialty: false,
        children: [],
      },
    ],
  },
  {
    id: 39,
    label: 'Entertainment',
    specialty: false,
    children: [
      {
        id: 118,
        label: 'Entertainment',
        specialty: false,
        children: [],
      },
      {
        id: 119,
        label: 'Gambling & Casinos',
        specialty: false,
        children: [],
      },
      {
        id: 120,
        label: 'Music',
        specialty: false,
        children: [],
      },
    ],
  },
  {
    id: 40,
    label: 'Fashion',
    specialty: false,
    children: [
      {
        id: 84,
        label: 'Beauty',
        specialty: false,
        children: [],
      },
      {
        id: 85,
        label: 'Men’s Style',
        specialty: false,
        children: [],
      },
      {
        id: 86,
        label: 'Women’s Style',
        specialty: false,
        children: [],
      },
    ],
  },
  {
    id: 41,
    label: 'Food',
    specialty: false,
    children: [
      {
        id: 78,
        label: 'Restaurants',
        specialty: false,
        children: [],
      },
      {
        id: 79,
        label: 'Wine and Spirits',
        specialty: false,
        children: [],
      },
    ],
  },
  {
    id: 42,
    label: 'Recreation',
    specialty: false,
    children: [
      {
        id: 80,
        label: 'Gaming',
        specialty: false,
        children: [],
      },
      {
        id: 81,
        label: 'Events',
        specialty: false,
        children: [],
      },
      {
        id: 82,
        label: 'Sports',
        specialty: false,
        children: [],
      },
      {
        id: 83,
        label: 'Shopping',
        specialty: false,
        children: [],
      },
    ],
  },
  {
    id: 43,
    label: 'Parenting',
    specialty: false,
    children: [],
  },
  {
    id: 44,
    label: 'Hospital & Healthcare',
    specialty: true,
    children: [],
  },
  {
    id: 45,
    label: 'Health, Wellness and Fitness',
    specialty: true,
    children: [],
  },
  {
    id: 46,
    label: 'Mental Healthcare',
    specialty: true,
    children: [],
  },
  {
    id: 47,
    label: 'Pharmaceuticals',
    specialty: true,
    children: [],
  },
  {
    id: 48,
    label: 'Veterinary',
    specialty: true,
    children: [],
  },
  {
    id: 49,
    label: 'Medical Devices',
    specialty: true,
    children: [],
  },
  {
    id: 50,
    label: 'Senior Care',
    specialty: true,
    children: [],
  },
  {
    id: 51,
    label: 'Business',
    specialty: true,
    children: [
      {
        id: 105,
        label: 'Entrepreneurship',
        specialty: true,
        children: [],
      },
      {
        id: 106,
        label: 'Marketing',
        specialty: true,
        children: [],
      },
      {
        id: 107,
        label: 'Media',
        specialty: true,
        children: [],
      },
      {
        id: 108,
        label: 'Advertising',
        specialty: true,
        children: [],
      },
      {
        id: 109,
        label: 'Sales',
        specialty: true,
        children: [],
      },
      {
        id: 110,
        label: 'Energy and Commodities',
        specialty: true,
        children: [],
      },
      {
        id: 111,
        label: 'Business Development',
        specialty: true,
        children: [],
      },
      {
        id: 112,
        label: 'Customer Success',
        specialty: true,
        children: [],
      },
      {
        id: 113,
        label: 'Corporate Wellness',
        specialty: true,
        children: [],
      },
      {
        id: 114,
        label: 'Human Resources',
        specialty: true,
        children: [],
      },
      {
        id: 115,
        label: 'SaaS',
        specialty: true,
        children: [],
      },
      {
        id: 116,
        label: 'Product Design',
        specialty: true,
        children: [],
      },
      {
        id: 117,
        label: 'Productivity',
        specialty: true,
        children: [],
      },
    ],
  },
  {
    id: 52,
    label: 'Finance',
    specialty: true,
    children: [
      {
        id: 93,
        label: 'Corporate Finance',
        specialty: true,
        children: [],
      },
      {
        id: 94,
        label: 'Personal Finance',
        specialty: true,
        children: [],
      },
      {
        id: 95,
        label: 'Investment Management',
        specialty: true,
        children: [],
      },
      {
        id: 96,
        label: 'Real Estate / Property',
        specialty: true,
        children: [],
      },
      {
        id: 97,
        label: 'Banking',
        specialty: true,
        children: [],
      },
      {
        id: 98,
        label: 'Financial Services',
        specialty: true,
        children: [],
      },
      {
        id: 99,
        label: 'Capital Markets',
        specialty: true,
        children: [],
      },
      {
        id: 100,
        label: 'Commercial Real Estate',
        specialty: true,
        children: [],
      },
      {
        id: 101,
        label: 'Insurance',
        specialty: true,
        children: [],
      },
      {
        id: 102,
        label: 'Venture Capital & Private Equity',
        specialty: true,
        children: [],
      },
      {
        id: 103,
        label: 'Investment Banking',
        specialty: true,
        children: [],
      },
      {
        id: 104,
        label: 'Accounting',
        specialty: true,
        children: [],
      },
    ],
  },
  {
    id: 53,
    label: 'Services',
    specialty: true,
    children: [
      {
        id: 121,
        label: 'Food Services',
        specialty: true,
        children: [],
      },
      {
        id: 122,
        label: 'Information Services',
        specialty: true,
        children: [],
      },
      {
        id: 123,
        label: 'Events Services',
        specialty: true,
        children: [],
      },
      {
        id: 124,
        label: 'Consumer Services',
        specialty: true,
        children: [],
      },
      {
        id: 125,
        label: 'Travel and Tourism Services',
        specialty: true,
        children: [],
      },
    ],
  },
  {
    id: 54,
    label: 'International Affairs',
    specialty: true,
    children: [],
  },
  {
    id: 55,
    label: 'Politics',
    specialty: true,
    children: [],
  },
  {
    id: 56,
    label: 'Military',
    specialty: true,
    children: [],
  },
  {
    id: 57,
    label: 'Public Policy',
    specialty: true,
    children: [],
  },
  {
    id: 58,
    label: 'Law Enforcement',
    specialty: true,
    children: [],
  },
  {
    id: 59,
    label: 'Business Law',
    specialty: true,
    children: [],
  },
  {
    id: 60,
    label: 'Property Law',
    specialty: true,
    children: [],
  },
  {
    id: 61,
    label: 'Personal Law',
    specialty: true,
    children: [],
  },
  {
    id: 62,
    label: 'Consumer Technology',
    specialty: true,
    children: [],
  },
  {
    id: 63,
    label: 'Enterprise Technology',
    specialty: true,
    children: [],
  },
  {
    id: 64,
    label: 'Innovation',
    specialty: true,
    children: [],
  },
  {
    id: 65,
    label: 'High Tech',
    specialty: true,
    children: [
      {
        id: 126,
        label: 'Defense',
        specialty: true,
        children: [],
      },
      {
        id: 127,
        label: 'Space',
        specialty: true,
        children: [],
      },
      {
        id: 128,
        label: 'Biotechnology',
        specialty: true,
        children: [],
      },
      {
        id: 129,
        label: 'Software',
        specialty: true,
        children: [],
      },
      {
        id: 130,
        label: 'Big Data',
        specialty: true,
        children: [],
      },
      {
        id: 131,
        label: 'Cybersecurity',
        specialty: true,
        children: [],
      },
      {
        id: 132,
        label: 'Automation',
        specialty: true,
        children: [],
      },
      {
        id: 133,
        label: 'Information Technology and Services',
        specialty: true,
        children: [],
      },
      {
        id: 134,
        label: 'Internet',
        specialty: true,
        children: [],
      },
      {
        id: 135,
        label: 'Cloud Computing',
        specialty: true,
        children: [],
      },
      {
        id: 136,
        label: 'Nanotechnology',
        specialty: true,
        children: [],
      },
      {
        id: 137,
        label: 'Electronics & Hardware',
        specialty: true,
        children: [],
      },
    ],
  },
  {
    id: 66,
    label: 'Early childhood',
    specialty: true,
    children: [],
  },
  {
    id: 67,
    label: 'Primary Education',
    specialty: true,
    children: [],
  },
  {
    id: 68,
    label: 'Secondary Education',
    specialty: true,
    children: [],
  },
  {
    id: 69,
    label: 'University Education',
    specialty: true,
    children: [],
  },
  {
    id: 70,
    label: 'Continuing Education',
    specialty: true,
    children: [],
  },
  {
    id: 71,
    label: 'Education Services',
    specialty: true,
    children: [],
  },
  {
    id: 72,
    label: 'E-learning',
    specialty: true,
    children: [],
  },
  {
    id: 73,
    label: 'Manufacturing',
    specialty: true,
    children: [],
  },
  {
    id: 74,
    label: 'Logistics and Supply Chain',
    specialty: true,
    children: [],
  },
  {
    id: 75,
    label: 'Renewable Energy',
    specialty: true,
    children: [],
  },
  {
    id: 76,
    label: 'Emerging Technologies',
    specialty: true,
    children: [],
  },
  {
    id: 77,
    label: 'Oil & Fossil Fuels',
    specialty: true,
    children: [],
  },
  {
    id: 78,
    label: 'Restaurants',
    specialty: false,
    children: [],
  },
  {
    id: 79,
    label: 'Wine and Spirits',
    specialty: false,
    children: [],
  },
  {
    id: 80,
    label: 'Gaming',
    specialty: false,
    children: [],
  },
  {
    id: 81,
    label: 'Events',
    specialty: false,
    children: [],
  },
  {
    id: 82,
    label: 'Sports',
    specialty: false,
    children: [],
  },
  {
    id: 83,
    label: 'Shopping',
    specialty: false,
    children: [],
  },
  {
    id: 84,
    label: 'Beauty',
    specialty: false,
    children: [],
  },
  {
    id: 85,
    label: 'Men’s Style',
    specialty: false,
    children: [],
  },
  {
    id: 86,
    label: 'Women’s Style',
    specialty: false,
    children: [],
  },
  {
    id: 87,
    label: 'Architecture & Design',
    specialty: false,
    children: [],
  },
  {
    id: 88,
    label: 'Arts & Culture',
    specialty: false,
    children: [],
  },
  {
    id: 89,
    label: 'Design',
    specialty: false,
    children: [],
  },
  {
    id: 90,
    label: 'Film & Entertainment',
    specialty: false,
    children: [],
  },
  {
    id: 91,
    label: 'Photography',
    specialty: false,
    children: [],
  },
  {
    id: 92,
    label: 'Writing and Editing',
    specialty: false,
    children: [],
  },
  {
    id: 93,
    label: 'Corporate Finance',
    specialty: true,
    children: [],
  },
  {
    id: 94,
    label: 'Personal Finance',
    specialty: true,
    children: [],
  },
  {
    id: 95,
    label: 'Investment Management',
    specialty: true,
    children: [],
  },
  {
    id: 96,
    label: 'Real Estate / Property',
    specialty: true,
    children: [],
  },
  {
    id: 97,
    label: 'Banking',
    specialty: true,
    children: [],
  },
  {
    id: 98,
    label: 'Financial Services',
    specialty: true,
    children: [],
  },
  {
    id: 99,
    label: 'Capital Markets',
    specialty: true,
    children: [],
  },
  {
    id: 100,
    label: 'Commercial Real Estate',
    specialty: true,
    children: [],
  },
  {
    id: 101,
    label: 'Insurance',
    specialty: true,
    children: [],
  },
  {
    id: 102,
    label: 'Venture Capital & Private Equity',
    specialty: true,
    children: [],
  },
  {
    id: 103,
    label: 'Investment Banking',
    specialty: true,
    children: [],
  },
  {
    id: 104,
    label: 'Accounting',
    specialty: true,
    children: [],
  },
  {
    id: 105,
    label: 'Entrepreneurship',
    specialty: true,
    children: [],
  },
  {
    id: 106,
    label: 'Marketing',
    specialty: true,
    children: [],
  },
  {
    id: 107,
    label: 'Media',
    specialty: true,
    children: [],
  },
  {
    id: 108,
    label: 'Advertising',
    specialty: true,
    children: [],
  },
  {
    id: 109,
    label: 'Sales',
    specialty: true,
    children: [],
  },
  {
    id: 110,
    label: 'Energy and Commodities',
    specialty: true,
    children: [],
  },
  {
    id: 111,
    label: 'Business Development',
    specialty: true,
    children: [],
  },
  {
    id: 112,
    label: 'Customer Success',
    specialty: true,
    children: [],
  },
  {
    id: 113,
    label: 'Corporate Wellness',
    specialty: true,
    children: [],
  },
  {
    id: 114,
    label: 'Human Resources',
    specialty: true,
    children: [],
  },
  {
    id: 115,
    label: 'SaaS',
    specialty: true,
    children: [],
  },
  {
    id: 116,
    label: 'Product Design',
    specialty: true,
    children: [],
  },
  {
    id: 117,
    label: 'Productivity',
    specialty: true,
    children: [],
  },
  {
    id: 118,
    label: 'Entertainment',
    specialty: false,
    children: [],
  },
  {
    id: 119,
    label: 'Gambling & Casinos',
    specialty: false,
    children: [],
  },
  {
    id: 120,
    label: 'Music',
    specialty: false,
    children: [],
  },
  {
    id: 121,
    label: 'Food Services',
    specialty: true,
    children: [],
  },
  {
    id: 122,
    label: 'Information Services',
    specialty: true,
    children: [],
  },
  {
    id: 123,
    label: 'Events Services',
    specialty: true,
    children: [],
  },
  {
    id: 124,
    label: 'Consumer Services',
    specialty: true,
    children: [],
  },
  {
    id: 125,
    label: 'Travel and Tourism Services',
    specialty: true,
    children: [],
  },
  {
    id: 126,
    label: 'Defense',
    specialty: true,
    children: [],
  },
  {
    id: 127,
    label: 'Space',
    specialty: true,
    children: [],
  },
  {
    id: 128,
    label: 'Biotechnology',
    specialty: true,
    children: [],
  },
  {
    id: 129,
    label: 'Software',
    specialty: true,
    children: [],
  },
  {
    id: 130,
    label: 'Big Data',
    specialty: true,
    children: [],
  },
  {
    id: 131,
    label: 'Cybersecurity',
    specialty: true,
    children: [],
  },
  {
    id: 132,
    label: 'Automation',
    specialty: true,
    children: [],
  },
  {
    id: 133,
    label: 'Information Technology and Services',
    specialty: true,
    children: [],
  },
  {
    id: 134,
    label: 'Internet',
    specialty: true,
    children: [],
  },
  {
    id: 135,
    label: 'Cloud Computing',
    specialty: true,
    children: [],
  },
  {
    id: 136,
    label: 'Nanotechnology',
    specialty: true,
    children: [],
  },
  {
    id: 137,
    label: 'Electronics & Hardware',
    specialty: true,
    children: [],
  },
];
