import PropTypes from 'prop-types';
import Styles from '../../Styles.module.scss';
import classNames from 'classnames';

const DetailItem = ({ label, description }) => (
  <div className={Styles.detailRow}>
    <span className={classNames(Styles.boldText, Styles.detailLabel)}>{label}</span>
    <span className={Styles.detailText}>
      {typeof description === 'function' ? description() : description}
    </span>
  </div>
);

DetailItem.propTypes = {};

export default DetailItem;
