import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '@hiredigital/ui/Loader';

import Styles from './Styles.module.scss';

const ShowMore = ({ onLoadMore, pageStart = 1, initLoad = false, hasMore = false, isBusy }) => {
  const [page, setPage] = useState(pageStart);

  useEffect(() => {
    if (initLoad) {
      handleShowMore(pageStart);
    }
  }, []);

  const handleShowMore = (page) => {
    if (hasMore) {
      onLoadMore?.(page);
      incremetPage();
    }
  };

  const incremetPage = () => setPage(page + 1);

  return (
    <div className={Styles.row}>
      {isBusy ? (
        <Loader className={Styles.loader} color={Loader.Color.WHITE} size={Loader.Size.SMALL} />
      ) : (
        <p onClick={() => handleShowMore(page)} className={Styles.showMore}>
          {`Show More`}
        </p>
      )}
    </div>
  );
};

ShowMore.propTypes = {
  data: PropTypes.object,
  pageStart: PropTypes.number,
  initLoad: PropTypes.bool,
  hasMore: PropTypes.bool,
  isBusy: PropTypes.bool,
};

export default ShowMore;
