/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import Styles from './List.module.scss';
import Loader from '@hiredigital/ui/Loader';
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from 'react-infinite-scroller';
import debounce from 'lodash/debounce';
import Discussions from './components/Discussions';
import ListContainer from './components/ListContainer';
import DiscussionsContainer from './components/DiscussionsContainer';
import Header from './components/Header';
import SearchBar from '../SearchBar/SearchBar';
import { useDiscussionsList } from '@context/discussionsList';
import { useOrg } from '@context/org';
import { useUser } from '@context/user';

const List = ({ onClose, onDiscussionSelect, isShown }) => {
  const [isCollapse, setIsCollapse] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [search, setSearch] = useState('');

  const { discussions, meta, isLoading, loadChats } = useDiscussionsList();
  const org = useOrg();
  const user = useUser();

  const performSearch = (search) => loadChats({ search: search }, true, org?.uuid);

  const performDebouncedSearch = useCallback(debounce(performSearch, 350), []);

  const loadMoreDiscussions = () => {
    if (!meta?.nextPage) return;
    if (isLoading) return;
    loadChats({ page: meta?.nextPage }, false, org?.uuid);
  };

  const handleClearSearch = () => setSearch('');

  const handleSearchChange = (e) => setSearch(e.target.value);

  const handleOpenChatList = () => setIsCollapse(false);

  const handleCollapseChatList = () => setIsCollapse(true);

  useEffect(() => {
    if (user) {
      if (user.isClient && org) {
        setIsInit(true);
        loadChats({}, true, org?.uuid);
      } else if (user.isTalent) {
        setIsInit(true);
        loadChats({}, true);
      }
    }
  }, [org, user]);

  useEffect(() => {
    if (isInit) {
      performDebouncedSearch(search);
    }
  }, [search, isInit]);

  return (
    <div className={classNames(Styles.container, !isShown && Styles.hidden)}>
      <Header
        isCollapse={isCollapse}
        onToggleCollapse={isCollapse ? handleOpenChatList : handleCollapseChatList}
        onClose={onClose}
      />
      <DiscussionsContainer className={classNames(Styles.list, !isCollapse && Styles.visible)}>
        <SearchBar
          onSearch={handleSearchChange}
          onClear={handleClearSearch}
          defaultValue={search}
          placeholder='Search Discussions'
        />
        <ListContainer>
          <InfiniteScroll
            initialLoad={false}
            pageStart={1}
            loadMore={loadMoreDiscussions}
            hasMore={meta?.nextPage !== null}
            threshold={10}
            useWindow={false}>
            {!!discussions?.length && (
              <Fragment>
                <Discussions
                  key={1}
                  title={`Direct Messages`}
                  data={discussions.filter(({ type }) => type === 1)}
                  onItemSelect={onDiscussionSelect}
                />
                <Discussions
                  key={2}
                  title={`Projects`}
                  data={discussions.filter(({ type }) => type === 2)}
                  onItemSelect={onDiscussionSelect}
                />
                <Discussions
                  key={3}
                  title={`Applications`}
                  data={discussions.filter(({ type }) => type === 3)}
                  onItemSelect={onDiscussionSelect}
                />
              </Fragment>
            )}
          </InfiniteScroll>
          {isLoading && <Loader className={Styles.loader} color={Loader.Color.BLUE} />}
        </ListContainer>
      </DiscussionsContainer>
    </div>
  );
};

export default List;
