/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import Button from '@hiredigital/ui/Button';
import { getOrgTalents } from '@apis/organizations';
import Item from './Item';
import Styles from './TalentSelector.module.scss';

const TalentSelector = ({
  orgUuid,
  selected = [],
  project = {},
  onItemSelect,
  onItemDeselect,
  onAssignmentSelect,
  updatedTalent,
  currentUser,
}) => {
  const [talents, setTalents] = useState([]);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    loadTalents();
  }, []);

  useEffect(() => {
    if (talents?.length > 0) {
      setTalents(talents?.map((v) => (v?.uuid === updatedTalent?.uuid ? updatedTalent : v)));
    }
  }, [updatedTalent]);

  const loadTalents = (params) => {
    getOrgTalents(orgUuid, { params })
      .then(({ data }) => {
        setMeta(data?.meta);
        const items = [...talents, ...data?.results].map((v) => ({
          ...v,
          assignment: getAssignment(v, project?.members),
        }));
        setTalents(items);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getUuid = (v) => v?.team?.uuid || v?.user?.uuid;

  const getAssignment = (talent, members = []) => {
    if (!members || members?.length === 0) return;
    return members?.find((v) => getUuid(v) === getUuid(talent))?.assignment;
  };

  const handleMore = () => loadTalents({ page: meta?.nextPage });

  const handleSelectItem = (v) => onItemSelect?.(v);
  const handleDeselectItem = (v) => onItemDeselect?.(v);

  return (
    <div className={Styles.container}>
      {talents?.map((v, idx) => (
        <Item
          key={idx}
          item={v}
          orgUuid={orgUuid}
          onSelect={handleSelectItem}
          onDeselect={handleDeselectItem}
          isSelected={selected.includes(getUuid(v))}
          onAssignmentSelect={onAssignmentSelect}
          isClickable={!(currentUser?.isProjectManager && currentUser.uuid === getUuid(v))}
        />
      ))}
      {meta?.nextPage && (
        <div className={Styles.moreContainer}>
          <Button onClick={handleMore} type={Button.Type.LIGHT_GRAY} className={Styles.moreAction}>
            {`Show More`}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TalentSelector;
