/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';

import Menu from '@hiredigital/ui/Menu/Menu';

// import Icon from '@hiredigital/ui/Icon/Icon';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';
import IconSearch from '@hiredigital/ui/Icon/icons/search.inline.svg';

import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button/Button';
import { postConference } from '@apis/conferences';
import { postProjectComplete, postProjectSendBack, postProjectSubmit } from '@apis/projects';
import { useUser } from '@context/user';
import { ProjectStatus } from '@hiredigital/lib/helpers/enum';
import { DiscussionProvider } from '@context/discussion';
import { getBrand } from '@apis/brands';

import Container from './Layout/Container';
import ContentLeft from './Layout/ContentLeft';
import ContentRight from './Layout/ContentRight';
import TabItems from './Components/TabItems';
import BriefArea from './ContentArea/BriefArea';
import FileManager from './ContentArea/FileContentArea';
import ConferenceSchedule from '../DiscussionSchedule/ConferenceSchedule';
import Header from './Layout/Header';

import SplitButton from '@hiredigital/ui/Button/SplitButton';
import InlineAvatars from '@hiredigital/ui/InlineAvatars';
import DiscussionArea from '../Discussions/ContentArea/DiscussionArea';
import NavMenu from './Layout/NavMenu';

import classNames from 'classnames';
import Styles from './Project.module.scss';
import { useDiscussion } from '@context/discussion';

const VIEW = {
  List: { idx: 0 },
  Discussion: { idx: 1 },
  Brief: { idx: 2 },
  Call: { idx: 3 },
  FileView: { idx: 4 },
};
const TAB = { Brief: { idx: 0 }, Discussion: { idx: 1 } };

const ProjectDetail = ({ project, onAfterSubmit, ...props }) => {
  const user = useUser();
  const params = useParams();
  const { loadMessages } = useDiscussion();

  const tabs = [
    { text: 'Brief' },
    {
      text: 'Discussion',
      // eslint-disable-next-line react/display-name
      slotSelected: () => (
        <button onClick={handleSearchShow} className={Styles.actionSearch}>
          <IconSearch className={Styles.searchIcon} />
        </button>
      ),
    },
  ];

  const limitWidth = 415;

  const [activeTabIdx, setActiveTabIdx] = useState(TAB.Brief.idx);
  const [activeMenuIndex, setActiveMenuIndex] = useState(VIEW.List.idx);
  const [showSearch, setShowSearch] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [conference, setConference] = useState(null);
  const [isScheduling, setIsScheduling] = useState(false);
  const [isShownEnd, setIsShownEnd] = useState(false);
  const [isShownBack, setIsShownBack] = useState(false);
  const [isShownSubmit, setIsShownSubmit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [brand, setBrand] = useState(null);
  const [isSmall, setIsSmall] = useState(false);
  const [projectUuid, setProjectUuid] = useState(project?.uuid || params?.slug?.[0]);

  const menus = [
    { caption: 'Files', onClick: setActiveMenuIndex },
    { caption: 'Discussion', onClick: setActiveMenuIndex },
    { caption: 'Brief', onClick: setActiveMenuIndex },
    { caption: 'Call', onClick: () => handleScheduleCall() },
  ];

  useEffect(() => {
    const projectOrgUuid = project?.organization?.uuid;
    if (user?.isTalent && projectOrgUuid) {
      getBrand(projectOrgUuid)
        .then(({ data }) => {
          setBrand(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [user?.isTalent]);

  const handleSearchShow = () => setShowSearch(!showSearch);

  const handleScheduleCall = () => {
    setIsScheduling(true);
    postConference({ discussion: project?.discussion })
      .then(({ data }) => {
        setConference(data);
        setIsShown(true);
        setIsScheduling(false);
      })
      .catch((error) => {
        console.log(error);
        setIsScheduling(false);
      });
  };

  const onCompleteScheduleSubmit = (data) => {
    setIsShown(false);
    loadMessages(project?.discussion?.id, null, true);
  };

  const handleAddParticipant = () => setIsShown(true);

  const handleConfirmEnd = () => {
    setIsSaving(true);
    postProjectComplete(projectUuid, { status: project?.status })
      .then(({ data }) => {
        onAfterSubmit?.(data);
        setIsSaving(false);
        setIsShownEnd(false);
      })
      .catch((error) => {
        setIsSaving(false);
        setIsShownEnd(false);
        console.error(error);
      });
  };

  const handleDownloadAll = () => {
    const url = `${process.env.API_ENDPOINT}/projects/${projectUuid}/content/generate-zip/`;
    const a = document.createElement('a');
    a.href = url;
    a.target = '_self';
    a.click();
    a.remove();
  };

  const handleConfirmSendBack = () => {
    setIsSaving(true);
    postProjectSendBack(projectUuid, { status: project?.status })
      .then(({ data }) => {
        onAfterSubmit?.(data);
        setIsSaving(false);
        setIsShownBack(false);
      })
      .catch((error) => {
        setIsSaving(false);
        setIsShownBack(false);
        console.error(error);
      });
  };

  const handleConfirmSubmitReview = () => {
    setIsSaving(true);
    postProjectSubmit(projectUuid, { status: project?.status })
      .then(({ data }) => {
        onAfterSubmit?.(data);
        setIsSaving(false);
        setIsShownSubmit(false);
      })
      .catch((error) => {
        setIsSaving(false);
        setIsShownSubmit(false);
        console.error(error);
      });
  };

  const RequestEdit = ({ allowEndProject = false }) => (
    <SplitButton
      onClick={() => setIsShownBack(true)}
      type={SplitButton.Type.LIGHT_GRAY}
      style={{ marginLeft: '10px' }}
      more={[
        {
          text: 'Download All Files',
          onClick: handleDownloadAll,
        },
        {
          ...(allowEndProject && {
            text: 'Close Project',
            onClick: () => setIsShownEnd(true),
          }),
        },
      ]}>
      {'Request Edits'}
    </SplitButton>
  );

  const SubmitReview = ({ allowEndProject = false }) => (
    <SplitButton
      onClick={() => setIsShownSubmit(true)}
      type={SplitButton.Type.LIGHT_GRAY}
      style={{ marginLeft: '10px' }}
      more={[
        {
          text: 'Download All Files',
          onClick: handleDownloadAll,
        },
        {
          ...(allowEndProject && {
            text: 'Close Project',
            onClick: () => setIsShownEnd(true),
          }),
        },
      ]}>
      {'Submit for Review'}
    </SplitButton>
  );

  const DropdownActionOnly = ({ allowEndProject = false, hasStatus = true }) => (
    <Menu>
      <Menu.Button as='div' className={Styles.statusContainer}>
        {hasStatus && (
          <Fragment>{project?.status ? ProjectStatus.getEnum(project.status).label : ''}</Fragment>
        )}
        <button className={Styles.moreAction}>
          <IconMoreVertical className={Styles.iconMore} />
        </button>
      </Menu.Button>
      <Menu.Items className={Styles.menuItems}>
        <Menu.Item as='div' className={Styles.menuItem} onClick={handleDownloadAll}>
          {'Download All Files'}
        </Menu.Item>
        {allowEndProject && (
          <Fragment>
            {project?.status !== ProjectStatus.COMPLETED.id && (
              <Menu.Item
                as='div'
                className={Styles.menuItem}
                onClick={() => setIsShownEnd(true)}>{`Close Project`}</Menu.Item>
            )}
          </Fragment>
        )}
      </Menu.Items>
    </Menu>
  );

  const handleResizeArea = (w) => setIsSmall(w < limitWidth);
  const visibleMembers = project?.members.filter((v) => v?.assignment);

  return (
    <Fragment>
      <Container
        header={() => (
          <Header
            title={project?.title || `Untitled Project`}
            navArea={() => (
              <NavMenu
                className={classNames(Styles.hide, Styles.mobileFlex)}
                items={menus}
                activeIndex={activeMenuIndex}
              />
            )}
            mainArea={() => (
              <>
                {user?.uuid && (
                  <Fragment>
                    {visibleMembers?.length > 0 && (
                      <InlineAvatars
                        members={visibleMembers}
                        uuid={projectUuid}
                        toolTipPlace={`bottom`}
                      />
                    )}

                    {project?.status === ProjectStatus.COMPLETED.id && (
                      <DropdownActionOnly allowEndProject={false} />
                    )}

                    {user?.isTalent && (
                      <Fragment>
                        {project?.status === ProjectStatus.IN_REVIEW.id && (
                          <Fragment>
                            {brand?.isProjectManager ? (
                              <RequestEdit allowEndProject={true} />
                            ) : (
                              <DropdownActionOnly allowEndProject={false} hasStatus={false} />
                            )}
                          </Fragment>
                        )}
                        {project?.status === ProjectStatus.IN_PROGRESS.id && (
                          <SubmitReview allowEndProject={brand?.isProjectManager} />
                        )}
                      </Fragment>
                    )}

                    {user?.isClient && (
                      <Fragment>
                        {project?.status === ProjectStatus.IN_REVIEW.id && (
                          <RequestEdit allowEndProject={true} />
                        )}
                        {project?.status === ProjectStatus.IN_PROGRESS.id && (
                          <DropdownActionOnly allowEndProject={true} />
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </>
            )}
          />
        )}>
        <ContentLeft
          className={classNames({
            [Styles.hideOnMobile]: ![VIEW.List.idx, VIEW.FileView.idx].includes(activeMenuIndex),
          })}>
          <FileManager
            project={project || { uuid: projectUuid }}
            onViewFileChanged={() => setActiveMenuIndex(VIEW.FileView.idx)}
            onViewHistoryChanged={() => setActiveMenuIndex(VIEW.FileView.idx)}
            onViewHistoryListChanged={() => setActiveMenuIndex(VIEW.List.idx)}
            listContainerClass={Styles.fullWidthOnMobile}
            isListViewOnly={activeMenuIndex === VIEW.List.idx}
            isContentViewOnly={activeMenuIndex === VIEW.FileView.idx}
          />
        </ContentLeft>
        <ContentRight
          onResize={handleResizeArea}
          className={classNames({
            [Styles.hideOnMobile]: [VIEW.List.idx, VIEW.FileView.idx].includes(activeMenuIndex),
          })}>
          <TabItems
            items={tabs}
            activeIndex={activeTabIdx}
            onSelect={setActiveTabIdx}
            onSearchSelect={handleSearchShow}
            onActionClick={handleScheduleCall}
            isActionBusy={isScheduling}
            actionText={`Schedule Call`}
            className={Styles.hideOnMobile}
            isDropdown={isSmall}
          />
          <BriefArea
            className={classNames({
              [Styles.hide]: activeTabIdx !== TAB.Brief.idx,
              [Styles.hideOnMobile]: activeMenuIndex !== VIEW.Brief.idx,
            })}
            project={project || { uuid: projectUuid }}
            brand={brand}
          />
          <DiscussionArea
            className={classNames({
              [Styles.hide]: activeTabIdx !== TAB.Discussion.idx,
              [Styles.hideOnMobile]: activeMenuIndex !== VIEW.Discussion.idx,
            })}
            sendMessageClass={Styles.noMarginMobile}
            discussion={project?.discussion}
            showSearch={showSearch}
          />
        </ContentRight>
      </Container>

      <Fragment>
        <ConferenceSchedule
          conference={conference}
          discussion={project?.discussion}
          isShown={isShown}
          onCancel={() => setIsShown(false)}
          onClose={() => setIsShown(false)}
          onSubmit={onCompleteScheduleSubmit}
          onAddParticipant={handleAddParticipant}
          confirmScheduleLabel={`Next: Select Participants`}
        />

        <Dialog
          isOpen={isShownEnd}
          title='Confirm Close Project'
          description={`This action will close the project. You won't be able to send messages, but you can continue to access the past messages and project files.`}
          onClose={() => setIsShownEnd(false)}>
          <div style={{ marginTop: '20px' }}>
            <Button type={Button.Type.BLUE} onClick={handleConfirmEnd} isLoading={isSaving}>
              {'Confirm and Close Project'}
            </Button>
            <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsShownEnd(false)}>
              {'Cancel'}
            </Button>
          </div>
        </Dialog>

        <Dialog
          isOpen={isShownBack}
          title='Send Back for Edits'
          description={`Make sure to add all requirements to the discussion before sending it back to the talent.`}
          onClose={() => setIsShownBack(false)}>
          <div style={{ marginTop: '20px' }}>
            <Button type={Button.Type.BLUE} onClick={handleConfirmSendBack} isLoading={isSaving}>
              {'Send Back for Edits'}
            </Button>
            <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsShownBack(false)}>
              {'Cancel'}
            </Button>
          </div>
        </Dialog>

        <Dialog
          isOpen={isShownSubmit}
          title='Submit for Review'
          description={`Please review any references and discussion comments before submitting this draft. Add any notes regarding the submission in the discussion.`}
          onClose={() => setIsShownSubmit(false)}>
          <div style={{ marginTop: '20px' }}>
            <Button
              type={Button.Type.BLUE}
              onClick={handleConfirmSubmitReview}
              isLoading={isSaving}>
              {'Submit for Review'}
            </Button>
            <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsShownSubmit(false)}>
              {'Cancel'}
            </Button>
          </div>
        </Dialog>
      </Fragment>
    </Fragment>
  );
};

export default memo(ProjectDetail);
