import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import { getBrief } from '@apis/briefs';
import Layout from '@components/Layout/AppLayout';
import TitleBar from '@components/Layout/DetailTitleBar';
import withOrgGuard from '../../shared/hoc/withOrgGuard';
import { OrganizationStatus } from '@hiredigital/lib/helpers/enum';

import Action from '@hiredigital/ui/Action';
import Button from '@hiredigital/ui/Button';

import { useUser } from '@context/user';
import { useOrg } from '@context/org';

import Menu from '@hiredigital/ui/Menu/Menu';

import Detail from '@components/Briefs/Detail';
import Styles from './Styles.module.scss';

const BriefDetail = ({ ...props }) => {
  const user = useUser();
  const org = useOrg();
  const params = useParams();
  const history = useHistory();
  const [isEngagementStage, setIsEngagementStage] = useState(false);
  const [initialBrief, setInitialBrief] = useState();
  const { uuid, stage } = params;
  // FIXME: Add CreateNew component to BriefDetail Page

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getBrief(uuid);
        setInitialBrief(response?.data);
        setPageTitle(`${response?.data?.title || 'New Brief'} - Hire Digital`);
      } catch (e) {
        console.error(e);
      }
    };
    if (uuid) {
      loadData();
    }
  }, [uuid]);

  useEffect(() => {
    setIsEngagementStage(stage === 'engagements');
  }, [uuid, stage]);

  const handleAddBrief = async () => {
    history.replace(`/briefs/new`);
  };

  const createProject = () => {
    history.push(`/projects/new?orgUuid=${org?.uuid}`);
  };

  return (
    <Layout
      style={{ backgroundColor: 'white' }}
      bodyClass={Styles.briefForm}
      header={
        <TitleBar
          title={`Talent Briefs`}
          titleLink={`/briefs`}
          subtitle={initialBrief?.title || 'New Brief'}>
          {isEngagementStage && (
            <Action>
              {user?.isClient && org?.status === OrganizationStatus.ACTIVE.id && (
                <Menu>
                  <Menu.Button as='div'>
                    <Button type={Button.Type.DARKBLUE}>{`Create New`}</Button>
                  </Menu.Button>
                  <Menu.Items className={Styles.menuItems}>
                    <Menu.Item
                      as='div'
                      className={Styles.menuItem}
                      onClick={() => handleAddBrief()}>{`New Talent Brief`}</Menu.Item>
                    <Menu.Item
                      as='div'
                      className={Styles.menuItem}
                      onClick={() => createProject()}>{`New Project`}</Menu.Item>
                  </Menu.Items>
                </Menu>
              )}
            </Action>
          )}
        </TitleBar>
      }>
      {initialBrief && <Detail initialBrief={initialBrief} />}
    </Layout>
  );
};

export default withOrgGuard(BriefDetail);
