import { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import {
  patchOrganization,
  postOrgFile,
  postOrgLink,
  deleteOrgFile,
  deleteOrgLink,
} from '@apis/organizations';
import References from '@hiredigital/ui/Form/References';
import QuillEditor from '@hiredigital/ui/Quill/Editor';
import Styles from './BrandGuidelines.module.scss';

const BrandGuidelines = ({ orgUuid, data }) => {
  const [guidelines, setGuidelines] = useState(data?.guidelines);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = () => {
    setIsSaving(true);
    patchOrganization(orgUuid, { guidelines })
      .then(() => {
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  const handleChange = (event) => setGuidelines(event?.target?.value);

  return (
    <Card>
      <Card.Header title={`Brand Guidelines`} />
      <Card.Note>
        {`Add overall guidelines for talents that you work with. You can also add any additional assets below.`}
      </Card.Note>
      <Card.Item>
        <div className={Styles.guidelinesContainer}>
          <QuillEditor
            name='guidelines'
            placeholder={`Add 10 things that will help talents better undertand your brand's style.`}
            onChange={handleChange}
            defaultValue={guidelines}
            toolbar={[
              ['bold', 'italic', 'underline'],
              ['blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              ['link', 'image'],
            ]}
            theme={'snow'}
          />
        </div>
        <References
          className={Styles.attachments}
          attachments={data.attachments}
          links={data.links}
          postLinkRequest={(data, config) => postOrgLink(orgUuid, data, config)}
          postFileRequest={(data, config) => postOrgFile(orgUuid, data, config)}
          deleteLinkRequest={(id) => deleteOrgLink(orgUuid, id)}
          deleteFileRequest={(id) => deleteOrgFile(orgUuid, id)}
          // server={`orgs/${orgUuid}`}
        />
      </Card.Item>
      <Card.Footer right>
        <Button name='submit' onClick={handleSave} type={Button.Type.BLUE}>
          {isSaving && (
            <Loader
              style={{ marginRight: '10px' }}
              color={Loader.Color.WHITE}
              size={Loader.Size.SMALL}
            />
          )}
          {`Save Guidelines`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

BrandGuidelines.propTypes = {
  orgUuid: PropTypes.string,
  data: PropTypes.shape({
    guidelines: PropTypes.string,
  }),
};

export default BrandGuidelines;
