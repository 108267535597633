import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Menu from '@hiredigital/ui/Menu/Menu';
import Styles from '../Dropdown.module.scss';

const TalentMenu = ({ isTalentOnboarding, setMobileOpen }) => {
  const handleHashLinkClick = () => setMobileOpen?.(false);
  const profilePath = isTalentOnboarding ? '/onboarding/profile' : '/profile';

  return (
    <>
      <Menu.Item className={Styles.menuItem} data-test-id='user profile'>
        <HashLink
          smooth
          onClick={handleHashLinkClick}
          to={`${profilePath}#profile`}>{`Profile`}</HashLink>
      </Menu.Item>
      <Menu.Item className={Styles.menuItem} data-test-id='user resume'>
        <HashLink
          smooth
          onClick={handleHashLinkClick}
          to={`${profilePath}#resume`}>{`Resume`}</HashLink>
      </Menu.Item>
      <Menu.Item className={Styles.menuItem} data-test-id='user portfolio'>
        <HashLink
          smooth
          onClick={handleHashLinkClick}
          to={`${profilePath}#portfolio`}>{`Portfolio`}</HashLink>
      </Menu.Item>
      <Menu.Item
        as={Link}
        to={isTalentOnboarding ? '/onboarding/availability' : '/profile/availability'}
        data-test-id='user rates'
        className={Styles.menuItem}>
        {`Rates & Availability`}
      </Menu.Item>
    </>
  );
};

export default TalentMenu;
