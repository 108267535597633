import { authRequest } from './utils';

const serializePublication = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

export const postPublication = async (
  data,
  serialize = serializePublication,
  config = undefined
) => {
  const url = `publications/`;
  return await authRequest.post(url, serialize(data), config);
};
