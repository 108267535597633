import { memo, useState } from 'react';
import classNames from 'classnames';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import format from 'date-fns/format';
import Styles from './Time.module.scss';

const Time = ({ date, className }) => {
  const [isRelative, setIsRelative] = useState(false);
  return (
    <span
      className={classNames(Styles.date, className)}
      onClick={() => setIsRelative((prev) => !prev)}>
      {isRelative
        ? formatDistanceToNow(new Date(date || null), {
            addSuffix: true,
          })
        : format(new Date(date || null), 'h:mm a EEE, d MMM')}
    </span>
  );
};

export default memo(Time);
