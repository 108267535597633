import { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import BankAccountItem from './Item';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';

import { PaymentType } from '@hiredigital/lib/helpers/enum';

import Styles from './BankAccount.module.scss';
import { Toaster } from 'react-hot-toast';
import PaymentMethod from './PaymentMethod';

const List = (props) => {
  const { apiManager, oldApiManager, validator } = props;
  const [bankAccountList, setBankAccountList] = useState([]);
  const [isBankLoaded, setIsBankLoaded] = useState(false);

  // Checks if the bank account uses the new API manager
  const isNewApiManager = (bankAccount) =>
    bankAccount &&
    (!bankAccount.id ||
      bankAccount.type === PaymentType.PAYPAL.id ||
      Object.keys(bankAccount.details || {}).length);

  useEffect(() => {
    loadBankAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadBankAccountList = (callback) => {
    // Not setting this here to avoid collapsing the list of payment methods.
    // setIsBankLoaded(false);
    const getBanks = apiManager?.getBanks;
    getBanks()
      .then((response) => {
        if (!oldApiManager) {
          setBankAccountList(response.data.results);
          setIsBankLoaded(true);
          return;
        }
        // Get the full details from the old API manager for the old type of bank accounts
        let final = response.data.results;
        const promises = [];
        final.forEach((v) => !isNewApiManager(v) && promises.push(oldApiManager.getBank(v.id)));
        Promise.allSettled(promises)
          .then((results) => {
            results.forEach(
              (r) =>
                r.value && (final = final.map((f) => (f.id === r.value.data.id ? r.value.data : f)))
            );
          })
          .catch((err) => console.error(err))
          .finally(() => {
            setBankAccountList(final);
            setIsBankLoaded(true);
          });
      })
      .catch((err) => console.error(err))
      .finally(callback);
  };

  const handleNewBankAccount = () => {
    // If a blank one with no ID exists, cancel the action.
    if (bankAccountList.find((el) => el && !el.id)) return;
    setBankAccountList([{ type: PaymentType.TRANSFERWISE.id, currency: null }, ...bankAccountList]);
  };

  const updateBankAccount = (bankAccount) => {
    let list = bankAccountList.filter((el) => {
      return el && el.id;
    });

    if (bankAccount) {
      if (bankAccount.isDefault) {
        list = list.map((item) => {
          item.isDefault = item.id === bankAccount.id;
          return item;
        });
      }
      list.push(bankAccount);
    }

    setIsBankLoaded(false);
    setBankAccountList(list);
    setIsBankLoaded(true);
  };

  const deleteBankAccount = (bankAccount, callback) => {
    if (bankAccount?.id) {
      const deleteBank = oldApiManager?.deleteBank;
      deleteBank(bankAccount.id).then(
        (response) => {
          loadBankAccountList();
          callback?.();
        },
        (error) => {
          callback?.();
        }
      );
    } else {
      updateBankAccount(bankAccount);
    }
  };

  const handleBankAccountEdit = (bankAccount) => {
    if (bankAccount.isDefault) {
      let list = bankAccountList.map((item) => {
        item.isDefault = item.id === bankAccount.id;
        return item;
      });
      const bankAccountIndex = bankAccountList.findIndex(function (el) {
        return el.id === bankAccount.id;
      });
      list[bankAccountIndex] = bankAccount;
      setIsBankLoaded(false);
      setBankAccountList(list);
      setIsBankLoaded(true);
    }
  };

  return (
    <Card>
      <Card.Header>
        <span>{`Payment Methods`}</span>
        <div className={Styles.action}>
          <Button
            name='Add new payment method'
            type={Button.Type.BLUE}
            onClick={handleNewBankAccount}
            disabled={!isBankLoaded}>
            {`Add`}
          </Button>
        </div>
      </Card.Header>
      <Fragment>
        <Toaster />
        {bankAccountList.length === 0 && (
          <Card.Item>
            <div className={Styles.empty}>
              {isBankLoaded ? `No payment methods have been added.` : <Loader />}
            </div>
          </Card.Item>
        )}
        <div data-test-id='payment-method-form'>
          {isBankLoaded &&
            bankAccountList &&
            bankAccountList.map((bankAccount, index) =>
              isNewApiManager(bankAccount) ? (
                <PaymentMethod
                  // force re-render with refreshed state
                  key={`${bankAccount.id || index}-${Math.random()}`}
                  apiManager={apiManager}
                  bankAccount={bankAccount}
                  loadBankAccountList={loadBankAccountList}
                  showWiseId={props.showWiseId}
                  simpleRow={props.simpleRow}
                />
              ) : (
                // read-only for the old type of bank accounts
                <BankAccountItem
                  apiManager={oldApiManager}
                  validator={validator}
                  data={props.data}
                  type={props.type}
                  bankAccount={bankAccount}
                  updateBankAccount={updateBankAccount}
                  deleteBankAccount={deleteBankAccount}
                  onBankAccountEdit={handleBankAccountEdit}
                  key={`${bankAccount.id || index}-${Math.random()}`}
                  defaultEmail={props.defaultEmail}
                  showWiseId={props.showWiseId}
                  simpleRow={props.simpleRow}
                />
              )
            )}
        </div>
      </Fragment>
    </Card>
  );
};

List.propTypes = {
  apiManager: PropTypes.object,
  validator: PropTypes.object,
  defaultEmail: PropTypes.string,
  showWiseId: PropTypes.bool,
};

export default List;
