import { Fragment, useEffect, useState } from 'react';
import Styles from './Item.module.scss';
import Checkbox from '@hiredigital/ui/Checkbox';
import { toReadableShortDateTime } from '@hiredigital/lib/helpers/date';
import ItemForm from './ItemForm';
import { ProjectDeadlineStatus } from '@hiredigital/lib/helpers/enum';

const Item = ({ item, index, onCreate, onUpdate, onDelete, projectUuid, apiManager }) => {
  const [data, setData] = useState(item);

  const [isNew, setIsNew] = useState(item.isNew);
  const [isEdit, setIsEdit] = useState(item.isEdit);

  useEffect(() => {
    setData(item);
  }, [item]);

  const handleCreate = (d, idx) => {
    setIsEdit(false);
    setIsNew(false);
    setData(d);
    onCreate?.(d, idx);
  };

  const handleUpdate = (d) => {
    setIsEdit(false);
    setIsNew(false);
    setData(d);
    onUpdate?.(d);
  };

  const handleCancel = () => {
    setIsNew(false);
    setIsEdit(false);
  };

  const handleDelete = (d) => {
    setIsNew(false);
    setIsEdit(false);
    onDelete?.(d);
  };

  const handleCheckChange = ({ target: { checked } }) => {
    const { markDeadlineComplete, markDeadlineActive } = apiManager;
    const request = checked ? markDeadlineComplete : markDeadlineActive;

    const status = ProjectDeadlineStatus?.[checked ? 'COMPLETE' : 'ACTIVE']?.id;
    setData({ ...data, status });

    request(projectUuid, item?.id, { status })
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  return (
    <Fragment>
      {isNew || isEdit ? (
        <ItemForm
          projectUuid={projectUuid}
          item={data}
          index={index}
          onCancel={handleCancel}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          apiManager={apiManager}
        />
      ) : (
        <Fragment>
          {data?.id && (
            <div className={Styles.item}>
              <Checkbox
                label={data?.name || 'Untitled task'}
                checked={data?.status === ProjectDeadlineStatus.COMPLETE.id}
                onChange={handleCheckChange}
              />
              <span className={Styles.dateLabel}>
                {data?.dueDate && toReadableShortDateTime(data?.dueDate)}
              </span>
              <button className={Styles.actionEdit} onClick={() => setIsEdit(true)}>
                {`Edit`}
              </button>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Item;
