import { memo } from 'react';
import PropTypes from 'prop-types';

import Styles from './PortfolioViewer.module.scss';

const Body = memo(({ children }) => {
  return <div className={Styles.sideBody}>{children}</div>;
});

Body.propTypes = {
  children: PropTypes.node,
};

export default Body;
