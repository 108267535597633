import { useState, useEffect } from 'react';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button/Button';
import { useOrg } from '@context/org';
import withStripe from '../../shared/hoc/withStripe';
import { postOrgStripeCard } from '@apis/customers';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Styles from './Styles.module.scss';

const NewStripeCardDialog = ({ isShown: initialIsShown, onCloseComplete, onClose }) => {
  const [isShown, setIsShown] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const org = useOrg();

  useEffect(() => {
    setIsShown(initialIsShown);
  }, [initialIsShown]);

  const handleClose = () => {
    setIsShown(false);
    onClose?.();
  };

  const handleSaveCard = () => {
    setIsSaving(true);
    const cardElement = elements.getElement(CardElement);

    try {
      stripe.createToken(cardElement).then(async (result) => {
        await postOrgStripeCard(org?.uuid, { token: result?.token?.id });
        setIsSaving(false);
        handleClose();
      });
    } catch (e) {}
  };

  return (
    <Dialog
      isOpen={isShown}
      title='Add New Credit Card'
      onClose={handleClose}
      onCloseComplete={onCloseComplete}
      minWidth={500}>
      <div style={{ marginBottom: 16 }}>
        <CardElement
          className={Styles.depositInput}
          options={{
            classes: {
              base: Styles.base,
              complete: Styles.complete,
              empty: Styles.empty,
              focus: Styles.focus,
              invalid: Styles.invalid,
            },
          }}
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <Button type={Button.Type.BLUE} onClick={handleSaveCard} isLoading={isSaving}>
          {'Save Card'}
        </Button>
        <Button type={Button.Type.LIGHT_GRAY} onClick={handleClose}>
          {'Cancel'}
        </Button>
      </div>
    </Dialog>
  );
};
export default withStripe(NewStripeCardDialog);
