import classNames from 'classnames';
import Styles from './OverviewStyles.module.scss';

const OverviewBody = ({ children, direction = 'row' }) => (
  <div
    className={classNames(
      Styles.body,
      { [Styles.column]: direction === 'column' },
      { [Styles.row]: direction === 'row' }
    )}>
    {children}
  </div>
);

OverviewBody.propTypes = {};

export default OverviewBody;
