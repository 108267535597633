import { Fragment, memo } from 'react';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import Styles from './Menu.module.scss';
import { snakeToCamel } from '@hiredigital/lib/helpers/utils';

const MenuComponent = memo(({ style, className, children }) => {
  return (
    <Menu as='div' className={classNames(Styles.container, className)} style={style}>
      {children}
    </Menu>
  );
});

const Items = ({ children, className, direction = 'default', ...props }) => {
  return (
    <Transition
      as={Fragment}
      enter={Styles.transition}
      enterFrom={Styles.enterFrom}
      enterTo={Styles.enterTo}
      leave={Styles.transition}
      leaveFrom={Styles.enterTo}
      leaveTo={Styles.enterFrom}>
      <Menu.Items
        className={classNames(
          'g-menu--dropdown',
          Styles.menuItems,
          Styles[snakeToCamel(direction)],
          className
        )}
        {...props}>
        {children}
      </Menu.Items>
    </Transition>
  );
};

const Button = ({ className, children, ...props }) => {
  return (
    <Menu.Button className={classNames(Styles.button, className)} {...props}>
      {children}
    </Menu.Button>
  );
};
MenuComponent.Button = memo(Button);
MenuComponent.Items = memo(Items);
MenuComponent.Item = memo(Menu.Item);
export default MenuComponent;
