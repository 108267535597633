/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button/Button';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import IconExclamation from '@hiredigital/ui/Icon/icons/exclamation.inline.svg';

import { getUserTimesheets } from '@apis/users';
import { postEvent, putEvent, getOverlappingEvents } from '@apis/tracking';
import DatePicker from '@hiredigital/ui/Input/Date/Date';
import { useUser } from '@context/user';
import { durationToSeconds } from '@hiredigital/lib/helpers/date';
import addSeconds from 'date-fns/addSeconds';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import Styles from './Entry.module.scss';

const Entry = ({
  isShown,
  data,
  onClose,
  onCloseComplete,
  onUpdate,
  onAdd,
  OverlapEntryComponent,
  ...props
}) => {
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState(data?.task?.name);
  const [duration, setDuration] = useState(data?.duration);
  const [timesheet, setTimesheet] = useState(data?.task?.timesheet);
  const [start, setStart] = useState(data?.startTime ? parseISO(data?.startTime) : new Date());
  const [isShownEntry, setIsShownEntry] = useState(isShown);
  const [errors, setErrors] = useState({});
  const [overlapEvents, setOverlapEvents] = useState([]);
  const [overlapCount, setOverlapCount] = useState(0);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    setIsShownEntry(isShown);
  }, [isShown]);

  useEffect(() => {
    if (!isShownEntry) {
      onClose?.();
    }
  }, [isShownEntry]);

  const handleSaveActivity = async () => {
    setIsLoading(true);
    setOverlapEvents([]);
    if (!(await validate())) return setIsLoading(false);
    try {
      const payload = {
        manual: data?.manual,
        task: {
          name: description,
          timesheet: timesheet?.uuid,
        },
      };
      if (data?.manual) {
        const seconds = durationToSeconds(duration);
        payload.startTime = formatISO(start);
        payload.endTime = formatISO(addSeconds(new Date(start), seconds));
        payload.duration = seconds;
      }

      if (data?.uuid) {
        putEvent(data?.uuid, payload).then(({ data }) => {
          onUpdate?.(data);
          setIsShownEntry(false);
        });
      } else {
        postEvent(payload).then(({ data }) => {
          onAdd?.(data);
          setIsShownEntry(false);
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onOrgTimesheetOptions = async (search, loadedOptions, { page }) => {
    const data = { params: { search, page, limit: 20 } };
    const response = await getUserTimesheets(user?.uuid, data);
    return {
      options: response.data.results,
      hasMore: response.data.meta.nextPage !== null,
      additional: {
        page: response.data.meta.nextPage,
      },
    };
  };

  const validate = async () => {
    let errors = {};

    if (!start) {
      errors = { ...errors, datetime: 'Date & Time is required' };
    }

    if (!duration) {
      errors = { ...errors, duration: 'Duration is required' };
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    }

    // overlap is checked for manual entries only
    if (!data?.manual) return true;

    try {
      const payload = {
        startTime: formatISO(start),
        duration: duration,
        excludeEvent: data?.uuid,
      };
      const resp = await getOverlappingEvents(payload);
      const noOverlapEvents = !resp?.data?.results?.length;
      setOverlapEvents(resp?.data?.results);
      setOverlapCount(resp?.data?.meta?.count);
      setDisableSave(!noOverlapEvents);
      return noOverlapEvents;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const handleClose = () => {
    setIsShownEntry(false);
    onClose?.();
  };

  return (
    <Dialog
      isOpen={isShownEntry}
      title={`${data?.uuid ? 'Edit' : 'Add'} Activity`}
      onClose={handleClose}
      onCloseComplete={onCloseComplete}
      className={Styles.dialogBody}
      {...props}>
      <InputContainer>
        <TextInput
          defaultValue={description}
          name='activity'
          label={`Activity`}
          onChange={(e) => {
            setErrors({});
            setDescription(e.target.value);
          }}
          error={errors?.description}
        />
      </InputContainer>
      <InputContainer>
        <Select
          data-test-id='assignment'
          classNamePrefix='s-contact'
          name='timesheet'
          label='Assignment'
          menuPlacement='top'
          getOptionLabel={(o) => (user?.isClient ? o.clientTitle : o.talentTitle)}
          getOptionValue={({ uuid }) => uuid}
          onChange={(e) => {
            setErrors({});
            setTimesheet(e.target.value);
          }}
          isPaginated
          loadOptions={onOrgTimesheetOptions}
          defaultValue={timesheet}
          error={errors?.timesheet}
        />
      </InputContainer>
      {data?.manual && (
        <InputGroup>
          <InputContainer>
            <DatePicker
              dateFormat='MM/dd/yyyy hh:mm aa'
              onChange={(date) => {
                setErrors({});
                setDisableSave(false);
                setStart(date);
              }}
              label={`Date & Time`}
              value={start}
              showTimeInput
              error={errors?.datetime}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              name='duration'
              onChange={(e) => {
                setErrors({});
                setDisableSave(false);
                setDuration(e.target.value);
              }}
              label={`Duration (HH:MM:SS)`}
              value={duration || ''}
              mask={`99:99:99`}
              error={errors?.duration}
            />
          </InputContainer>
        </InputGroup>
      )}
      {!!overlapEvents?.length && (
        <>
          <div className={Styles.overlapMessage}>
            <IconExclamation
              className={classNames(Styles.iconExclamation, disableSave && Styles.red)}
            />
            {disableSave ? (
              <div style={{ width: '100%' }}>
                We found {overlapCount} conflict{overlapCount > 1 ? 's' : ''}. Please resolve.
              </div>
            ) : (
              <div style={{ width: '100%' }}>
                Click <b>Save Activity</b> to check again.
              </div>
            )}
          </div>
          <div className={Styles.overlapList}>
            {OverlapEntryComponent &&
              overlapEvents?.map((data, index) => (
                <OverlapEntryComponent key={index} data={data} showActionColumn={false} />
              ))}
          </div>
        </>
      )}
      <div style={{ marginTop: '20px' }}>
        <Button
          type={Button.Type.BLUE}
          onClick={handleSaveActivity}
          isLoading={isLoading}
          disabled={isLoading || disableSave}>
          {'Save Activity'}
        </Button>
        <Button type={Button.Type.LIGHT_GRAY} onClick={handleClose} disabled={isLoading}>
          {'Cancel'}
        </Button>
      </div>
    </Dialog>
  );
};

Entry.propTypes = {};

export default Entry;
