/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect } from 'react';
import Styles from './FileContentArea.module.scss';
import ListView from '../Components/FileSideBar';
import Viewer from '../Viewer/Viewer';
import HistoryViewer from '../Viewer/HistoryViewer';
import { useProjects } from '@context/projects';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import classNames from 'classnames';

const ContentView = memo(({ isHistoryShown, contentId, projectUuid, className }) => {
  return (
    <div className={classNames(Styles.fileContent, className)}>
      <div className={Styles.editorArea}>
        {isHistoryShown ? (
          <HistoryViewer contentId={contentId} projectUuid={projectUuid} />
        ) : (
          <Viewer contentId={contentId} projectUuid={projectUuid} />
        )}
      </div>
    </div>
  );
});

const FileManager = ({
  project,
  onViewFileChanged,
  onViewHistoryListChanged,
  onViewHistoryChanged,
  isListViewOnly,
  isContentViewOnly,
  className,
  listContainerClass,
  viewerClass,
}) => {
  const {
    currentFile,
    files,
    hasLoadedFiles,
    isHistoryShown,
    setIsHistoryShown,
    historyContentId,
    history: fileHistory,
  } = useProjects();
  const history = useHistory();
  const params = useParams();

  const [contentId, setContentId] = useState(params?.contentId);
  const [projectPath, setProjectPath] = useState('');

  const handleItemSelect = (item) => {
    setIsHistoryShown(false);
    setContentId(item.id);
    onViewFileChanged?.(item);
  };

  useEffect(() => {
    if (historyContentId) {
      onViewHistoryListChanged?.();
    }
  }, [historyContentId]);

  useEffect(() => {
    if (fileHistory) {
      onViewHistoryChanged?.();
    }
  }, [fileHistory]);

  useEffect(() => {
    if (currentFile?.id) {
      setContentId(currentFile.id);
    }
  }, [currentFile?.id]);

  useEffect(() => {
    if (params?.contentId) {
      setContentId(Number(params.contentId));
    }
  }, [params?.contentId]);

  useEffect(() => {
    if (!project?.uuid) return;
    if (!hasLoadedFiles) return;

    if (contentId) {
      setProjectPath(`/projects/${project.uuid}/content/${contentId}`);
      return;
    }

    if (files?.length) {
      setProjectPath(`/projects/${project.uuid}/content/${files[0]?.id}`);
      return;
    }

    setProjectPath(`/projects/${project.uuid}`);
  }, [files, hasLoadedFiles, project?.uuid, contentId]);

  useEffect(() => {
    if (projectPath) {
      history.replace(projectPath);
    }
  }, [projectPath]);

  return (
    <div className={classNames(Styles.container, className)}>
      <ListView
        project={project}
        onItemSelect={handleItemSelect}
        className={classNames({ [Styles.hide]: isContentViewOnly }, listContainerClass)}
      />
      {project?.uuid && (
        <ContentView
          isHistoryShown={isHistoryShown}
          projectUuid={project?.uuid}
          contentId={contentId}
          className={classNames({ [Styles.hide]: isListViewOnly }, viewerClass)}
        />
      )}
    </div>
  );
};

export default memo(FileManager);
