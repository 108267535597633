import { Fragment } from 'react';
import Styles from './Styles.module.scss';
import Section from '../StagesLayout/Section';
import DetailItem from './components/details/DetailItem';
import { PreferredPlatform } from '@hiredigital/lib/helpers/enum';

const ScheduledCall = ({ call }) => {
  return (
    <div>
      <Section
        title={`Your Call Has Been Confirmed`}
        subtitle={`A Hire Digital talent specialist will reach out to you soon.`}
      />
      <div className={Styles.callDetailsContainer}>
        <p>
          {`A Hire Digital talent specialist will call you at `}
          <strong>
            {call?.preferred === PreferredPlatform.PHONE.id
              ? call?.number
              : 'via the web call link below'}
          </strong>
          {` on `}
          <strong>{`${call?.dateFormatted} `}</strong>
          <strong>{`${call?.startTimeFormatted} `}</strong>
          <strong>({call?.currentTimezone?.label})</strong>
          {`. `}
          {call?.preferred === PreferredPlatform.WEB_CALL.id &&
            `If we cannot reach you, we will also try calling you at ${call.number}.`}
        </p>
        <div className={Styles.detailItemContainer}>
          {(call?.preferred === PreferredPlatform.PHONE.id || !call?.conferenceUrl) && (
            <DetailItem label={`Contact`} description={call.number} />
          )}
          {call?.preferred === PreferredPlatform.WEB_CALL.id && (
            <DetailItem
              label={`Call Link`}
              description={() => <a href={call?.conferenceUrl}>{call?.conferenceUrl}</a>}
            />
          )}

          {call?.isAsap ? (
            <Fragment>
              <DetailItem label={`Date`} description={`As soon as possible`} />
            </Fragment>
          ) : (
            <Fragment>
              <DetailItem label={`Date`} description={call?.dateFormatted} />
              <DetailItem
                label={`Time`}
                description={`${call?.startTimeFormatted} - ${call?.endTimeFormatted}`}
              />
            </Fragment>
          )}

          <DetailItem
            label={`Timezone`}
            description={`${call?.currentTimezone?.label} (GMT ${call?.currentTimezone?.timezoneOffset})`}
          />
        </div>
      </div>
    </div>
  );
};

ScheduledCall.propTypes = {};

export default ScheduledCall;
