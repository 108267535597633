import { Fragment, memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import IconProfile from '@hiredigital/ui/Icon/icons/profile.inline.svg';

import Menu from '@hiredigital/ui/Menu/Menu';
import Position from 'evergreen-ui/esm/constants/src/Position';
import { Tooltip } from 'evergreen-ui/esm/tooltip';

import NavStyles from '../Nav/Nav.module.scss';
import Styles from '../Dropdown.module.scss';

import { UserStatus } from '@hiredigital/lib/helpers/enum';
import { useUser } from '@context/user';
import { isTalent } from '@helpers/permissions';

import ExpandIcon from '../ExpandIcon/ExpandIcon';
import TalentMenu from './TalentMenu';

const Item = () => {
  return (
    <>
      <IconProfile className={NavStyles.icon} />
      <div className={Styles.title}>Profile</div>
    </>
  );
};

const MemoItem = memo(Item);

const ProfileMenu = ({ setMobileOpen }) => {
  const user = useUser();
  const isTalentOnboarding = isTalent(user) && user?.status === UserStatus.ONBOARDING.id;

  return (
    <Fragment>
      <div className={Styles.menuGroupTitle}>{`Profile`}</div>
      <TalentMenu isTalentOnboarding={isTalentOnboarding} setMobileOpen={setMobileOpen} />
    </Fragment>
  );
};

const MemoProfileMenu = memo(ProfileMenu);

const ProfileDropdown = ({ open, mobileOpen, setMobileOpen }) => {
  const [mobileDropdown, setMobileDropdown] = useState(false);

  useEffect(() => {
    setMobileDropdown(false);
  }, [mobileOpen]);

  useEffect(() => {}, [mobileDropdown]);

  return (
    <Fragment>
      {mobileOpen ? (
        <Menu className={Styles.fullWidth}>
          <Menu.Button
            as='div'
            className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}
            onClick={() => mobileOpen && setMobileDropdown(!mobileDropdown)}>
            <MemoItem />
            <ExpandIcon isOpen={mobileDropdown} style={{ marginLeft: 'auto' }} />
          </Menu.Button>
          <Menu.Items
            className={classNames(Styles.mobileList, mobileDropdown && Styles.mobileListOpen)}>
            <MemoProfileMenu setMobileOpen={setMobileOpen} />
          </Menu.Items>
        </Menu>
      ) : (
        <Menu>
          {({ open: isShown }) => (
            <Fragment>
              <Menu.Button as='div' className={Styles.fullWidth}>
                <Tooltip
                  content={'Profile'}
                  position={Position.RIGHT}
                  isShown={isShown || open || mobileOpen ? false : undefined}>
                  <div className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}>
                    <MemoItem />
                    <IconDown className={Styles.sideArrow} />
                  </div>
                </Tooltip>
              </Menu.Button>
              <Menu.Items
                className={Styles.menuItems}
                direction='right'
                style={{ minWidth: 'max-content' }}>
                <MemoProfileMenu />
              </Menu.Items>
            </Fragment>
          )}
        </Menu>
      )}
    </Fragment>
  );
};
ProfileDropdown.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
};

export default ProfileDropdown;
