export const timezoneList = [
  { id: 1, name: 'Africa/Abidjan', offset: '+0000' },
  { id: 2, name: 'Africa/Accra', offset: '+0000' },
  { id: 3, name: 'Africa/Addis_Ababa', offset: '+0300' },
  { id: 4, name: 'Africa/Algiers', offset: '+0100' },
  { id: 5, name: 'Africa/Asmara', offset: '+0300' },
  { id: 6, name: 'Africa/Bamako', offset: '+0000' },
  { id: 7, name: 'Africa/Bangui', offset: '+0100' },
  { id: 8, name: 'Africa/Banjul', offset: '+0000' },
  { id: 9, name: 'Africa/Bissau', offset: '+0000' },
  { id: 10, name: 'Africa/Blantyre', offset: '+0200' },
  { id: 11, name: 'Africa/Brazzaville', offset: '+0100' },
  { id: 12, name: 'Africa/Bujumbura', offset: '+0200' },
  { id: 13, name: 'Africa/Cairo', offset: '+0200' },
  { id: 14, name: 'Africa/Casablanca', offset: '+0100' },
  { id: 15, name: 'Africa/Ceuta', offset: '+0200' },
  { id: 16, name: 'Africa/Conakry', offset: '+0000' },
  { id: 17, name: 'Africa/Dakar', offset: '+0000' },
  { id: 18, name: 'Africa/Dar_es_Salaam', offset: '+0300' },
  { id: 19, name: 'Africa/Djibouti', offset: '+0300' },
  { id: 20, name: 'Africa/Douala', offset: '+0100' },
  { id: 21, name: 'Africa/El_Aaiun', offset: '+0100' },
  { id: 22, name: 'Africa/Freetown', offset: '+0000' },
  { id: 23, name: 'Africa/Gaborone', offset: '+0200' },
  { id: 24, name: 'Africa/Harare', offset: '+0200' },
  { id: 25, name: 'Africa/Johannesburg', offset: '+0200' },
  { id: 26, name: 'Africa/Juba', offset: '+0300' },
  { id: 27, name: 'Africa/Kampala', offset: '+0300' },
  { id: 28, name: 'Africa/Khartoum', offset: '+0300' },
  { id: 29, name: 'Africa/Kigali', offset: '+0200' },
  { id: 30, name: 'Africa/Kinshasa', offset: '+0100' },
  { id: 31, name: 'Africa/Lagos', offset: '+0100' },
  { id: 32, name: 'Africa/Libreville', offset: '+0100' },
  { id: 33, name: 'Africa/Lome', offset: '+0000' },
  { id: 34, name: 'Africa/Luanda', offset: '+0100' },
  { id: 35, name: 'Africa/Lubumbashi', offset: '+0200' },
  { id: 36, name: 'Africa/Lusaka', offset: '+0200' },
  { id: 37, name: 'Africa/Malabo', offset: '+0100' },
  { id: 38, name: 'Africa/Maputo', offset: '+0200' },
  { id: 39, name: 'Africa/Maseru', offset: '+0200' },
  { id: 40, name: 'Africa/Mbabane', offset: '+0200' },
  { id: 41, name: 'Africa/Mogadishu', offset: '+0300' },
  { id: 42, name: 'Africa/Monrovia', offset: '+0000' },
  { id: 43, name: 'Africa/Nairobi', offset: '+0300' },
  { id: 44, name: 'Africa/Ndjamena', offset: '+0100' },
  { id: 45, name: 'Africa/Niamey', offset: '+0100' },
  { id: 46, name: 'Africa/Nouakchott', offset: '+0000' },
  { id: 47, name: 'Africa/Ouagadougou', offset: '+0000' },
  { id: 48, name: 'Africa/Porto-Novo', offset: '+0100' },
  { id: 49, name: 'Africa/Sao_Tome', offset: '+0000' },
  { id: 50, name: 'Africa/Tripoli', offset: '+0200' },
  { id: 51, name: 'Africa/Tunis', offset: '+0100' },
  { id: 52, name: 'Africa/Windhoek', offset: '+0200' },
  { id: 53, name: 'America/Adak', offset: '-0900' },
  { id: 54, name: 'America/Anchorage', offset: '-0800' },
  { id: 55, name: 'America/Anguilla', offset: '-0400' },
  { id: 56, name: 'America/Antigua', offset: '-0400' },
  { id: 57, name: 'America/Araguaina', offset: '-0300' },
  { id: 58, name: 'America/Argentina/Buenos_Aires', offset: '-0300' },
  { id: 59, name: 'America/Argentina/Catamarca', offset: '-0300' },
  { id: 60, name: 'America/Argentina/Cordoba', offset: '-0300' },
  { id: 61, name: 'America/Argentina/Jujuy', offset: '-0300' },
  { id: 62, name: 'America/Argentina/La_Rioja', offset: '-0300' },
  { id: 63, name: 'America/Argentina/Mendoza', offset: '-0300' },
  { id: 64, name: 'America/Argentina/Rio_Gallegos', offset: '-0300' },
  { id: 65, name: 'America/Argentina/Salta', offset: '-0300' },
  { id: 66, name: 'America/Argentina/San_Juan', offset: '-0300' },
  { id: 67, name: 'America/Argentina/San_Luis', offset: '-0300' },
  { id: 68, name: 'America/Argentina/Tucuman', offset: '-0300' },
  { id: 69, name: 'America/Argentina/Ushuaia', offset: '-0300' },
  { id: 70, name: 'America/Aruba', offset: '-0400' },
  { id: 71, name: 'America/Asuncion', offset: '-0300' },
  { id: 72, name: 'America/Atikokan', offset: '-0500' },
  { id: 73, name: 'America/Bahia', offset: '-0300' },
  { id: 74, name: 'America/Bahia_Banderas', offset: '-0500' },
  { id: 75, name: 'America/Barbados', offset: '-0400' },
  { id: 76, name: 'America/Belem', offset: '-0300' },
  { id: 77, name: 'America/Belize', offset: '-0600' },
  { id: 78, name: 'America/Blanc-Sablon', offset: '-0400' },
  { id: 79, name: 'America/Boa_Vista', offset: '-0400' },
  { id: 80, name: 'America/Bogota', offset: '-0500' },
  { id: 81, name: 'America/Boise', offset: '-0600' },
  { id: 82, name: 'America/Cambridge_Bay', offset: '-0600' },
  { id: 83, name: 'America/Campo_Grande', offset: '-0400' },
  { id: 84, name: 'America/Cancun', offset: '-0500' },
  { id: 85, name: 'America/Caracas', offset: '-0430' },
  { id: 86, name: 'America/Cayenne', offset: '-0300' },
  { id: 87, name: 'America/Cayman', offset: '-0400' },
  { id: 88, name: 'America/Chicago', offset: '-0500' },
  { id: 89, name: 'America/Chihuahua', offset: '-0600' },
  { id: 90, name: 'America/Costa_Rica', offset: '-0600' },
  { id: 91, name: 'America/Creston', offset: '-0700' },
  { id: 92, name: 'America/Cuiaba', offset: '-0400' },
  { id: 93, name: 'America/Curacao', offset: '-0400' },
  { id: 94, name: 'America/Danmarkshavn', offset: '+0000' },
  { id: 95, name: 'America/Dawson', offset: '-0700' },
  { id: 96, name: 'America/Dawson_Creek', offset: '-0700' },
  { id: 97, name: 'America/Denver', offset: '-0600' },
  { id: 98, name: 'America/Detroit', offset: '-0400' },
  { id: 99, name: 'America/Dominica', offset: '-0400' },
  { id: 100, name: 'America/Edmonton', offset: '-0600' },
  { id: 101, name: 'America/Eirunepe', offset: '-0500' },
  { id: 102, name: 'America/El_Salvador', offset: '-0600' },
  { id: 103, name: 'America/Fort_Nelson', offset: '-0700' },
  { id: 104, name: 'America/Fortaleza', offset: '-0300' },
  { id: 105, name: 'America/Glace_Bay', offset: '-0300' },
  { id: 106, name: 'America/Godthab', offset: '-0200' },
  { id: 107, name: 'America/Goose_Bay', offset: '-0300' },
  { id: 108, name: 'America/Grand_Turk', offset: '-0400' },
  { id: 109, name: 'America/Grenada', offset: '-0400' },
  { id: 110, name: 'America/Guadeloupe', offset: '-0400' },
  { id: 111, name: 'America/Guatemala', offset: '-0600' },
  { id: 112, name: 'America/Guayaquil', offset: '-0500' },
  { id: 113, name: 'America/Guyana', offset: '-0400' },
  { id: 114, name: 'America/Halifax', offset: '-0300' },
  { id: 115, name: 'America/Havana', offset: '-0400' },
  { id: 116, name: 'America/Hermosillo', offset: '-0700' },
  { id: 117, name: 'America/Indiana/Indianapolis', offset: '-0400' },
  { id: 118, name: 'America/Indiana/Knox', offset: '-0500' },
  { id: 119, name: 'America/Indiana/Marengo', offset: '-0400' },
  { id: 120, name: 'America/Indiana/Petersburg', offset: '-0400' },
  { id: 121, name: 'America/Indiana/Tell_City', offset: '-0500' },
  { id: 122, name: 'America/Indiana/Vevay', offset: '-0400' },
  { id: 123, name: 'America/Indiana/Vincennes', offset: '-0400' },
  { id: 124, name: 'America/Indiana/Winamac', offset: '-0400' },
  { id: 125, name: 'America/Inuvik', offset: '-0600' },
  { id: 126, name: 'America/Iqaluit', offset: '-0400' },
  { id: 127, name: 'America/Jamaica', offset: '-0500' },
  { id: 128, name: 'America/Juneau', offset: '-0800' },
  { id: 129, name: 'America/Kentucky/Louisville', offset: '-0400' },
  { id: 130, name: 'America/Kentucky/Monticello', offset: '-0400' },
  { id: 131, name: 'America/Kralendijk', offset: '-0400' },
  { id: 132, name: 'America/La_Paz', offset: '-0400' },
  { id: 133, name: 'America/Lima', offset: '-0500' },
  { id: 134, name: 'America/Los_Angeles', offset: '-0700' },
  { id: 135, name: 'America/Lower_Princes', offset: '-0400' },
  { id: 136, name: 'America/Maceio', offset: '-0300' },
  { id: 137, name: 'America/Managua', offset: '-0600' },
  { id: 138, name: 'America/Manaus', offset: '-0400' },
  { id: 139, name: 'America/Marigot', offset: '-0400' },
  { id: 140, name: 'America/Martinique', offset: '-0400' },
  { id: 141, name: 'America/Matamoros', offset: '-0500' },
  { id: 142, name: 'America/Mazatlan', offset: '-0600' },
  { id: 143, name: 'America/Menominee', offset: '-0500' },
  { id: 144, name: 'America/Merida', offset: '-0500' },
  { id: 145, name: 'America/Metlakatla', offset: '-0800' },
  { id: 146, name: 'America/Mexico_City', offset: '-0500' },
  { id: 147, name: 'America/Miquelon', offset: '-0200' },
  { id: 148, name: 'America/Moncton', offset: '-0300' },
  { id: 149, name: 'America/Monterrey', offset: '-0500' },
  { id: 150, name: 'America/Montevideo', offset: '-0300' },
  { id: 151, name: 'America/Montserrat', offset: '-0400' },
  { id: 152, name: 'America/Nassau', offset: '-0400' },
  { id: 153, name: 'America/New_York', offset: '-0400' },
  { id: 154, name: 'America/Nipigon', offset: '-0400' },
  { id: 155, name: 'America/Nome', offset: '-0800' },
  { id: 156, name: 'America/Noronha', offset: '-0200' },
  { id: 157, name: 'America/North_Dakota/Beulah', offset: '-0500' },
  { id: 158, name: 'America/North_Dakota/Center', offset: '-0500' },
  { id: 159, name: 'America/North_Dakota/New_Salem', offset: '-0500' },
  { id: 160, name: 'America/Ojinaga', offset: '-0600' },
  { id: 161, name: 'America/Panama', offset: '-0500' },
  { id: 162, name: 'America/Pangnirtung', offset: '-0400' },
  { id: 163, name: 'America/Paramaribo', offset: '-0300' },
  { id: 164, name: 'America/Phoenix', offset: '-0700' },
  { id: 165, name: 'America/Port-au-Prince', offset: '-0400' },
  { id: 166, name: 'America/Port_of_Spain', offset: '-0400' },
  { id: 167, name: 'America/Porto_Velho', offset: '-0400' },
  { id: 168, name: 'America/Puerto_Rico', offset: '-0400' },
  { id: 169, name: 'America/Rainy_River', offset: '-0500' },
  { id: 170, name: 'America/Rankin_Inlet', offset: '-0500' },
  { id: 171, name: 'America/Recife', offset: '-0300' },
  { id: 172, name: 'America/Regina', offset: '-0600' },
  { id: 173, name: 'America/Resolute', offset: '-0500' },
  { id: 174, name: 'America/Rio_Branco', offset: '-0500' },
  { id: 175, name: 'America/Santa_Isabel', offset: '-0700' },
  { id: 176, name: 'America/Santarem', offset: '-0300' },
  { id: 177, name: 'America/Santiago', offset: '-0300' },
  { id: 178, name: 'America/Santo_Domingo', offset: '-0400' },
  { id: 179, name: 'America/Sao_Paulo', offset: '-0300' },
  { id: 180, name: 'America/Scoresbysund', offset: '+0000' },
  { id: 181, name: 'America/Sitka', offset: '-0800' },
  { id: 182, name: 'America/St_Barthelemy', offset: '-0400' },
  { id: 183, name: 'America/St_Johns', offset: '-0230' },
  { id: 184, name: 'America/St_Kitts', offset: '-0400' },
  { id: 185, name: 'America/St_Lucia', offset: '-0400' },
  { id: 186, name: 'America/St_Thomas', offset: '-0400' },
  { id: 187, name: 'America/St_Vincent', offset: '-0400' },
  { id: 188, name: 'America/Swift_Current', offset: '-0600' },
  { id: 189, name: 'America/Tegucigalpa', offset: '-0600' },
  { id: 190, name: 'America/Thule', offset: '-0300' },
  { id: 191, name: 'America/Thunder_Bay', offset: '-0400' },
  { id: 192, name: 'America/Tijuana', offset: '-0700' },
  { id: 193, name: 'America/Toronto', offset: '-0400' },
  { id: 194, name: 'America/Tortola', offset: '-0400' },
  { id: 195, name: 'America/Vancouver', offset: '-0700' },
  { id: 196, name: 'America/Whitehorse', offset: '-0700' },
  { id: 197, name: 'America/Winnipeg', offset: '-0500' },
  { id: 198, name: 'America/Yakutat', offset: '-0800' },
  { id: 199, name: 'America/Yellowknife', offset: '-0600' },
  { id: 200, name: 'Antarctica/Casey', offset: '+0800' },
  { id: 201, name: 'Antarctica/Davis', offset: '+0700' },
  { id: 202, name: 'Antarctica/DumontDUrville', offset: '+1000' },
  { id: 203, name: 'Antarctica/Macquarie', offset: '+1100' },
  { id: 204, name: 'Antarctica/Mawson', offset: '+0500' },
  { id: 205, name: 'Antarctica/McMurdo', offset: '+1300' },
  { id: 206, name: 'Antarctica/Palmer', offset: '-0300' },
  { id: 207, name: 'Antarctica/Rothera', offset: '-0300' },
  { id: 208, name: 'Antarctica/Syowa', offset: '+0300' },
  { id: 209, name: 'Antarctica/Troll', offset: '+0200' },
  { id: 210, name: 'Antarctica/Vostok', offset: '+0600' },
  { id: 211, name: 'Arctic/Longyearbyen', offset: '+0200' },
  { id: 212, name: 'Asia/Aden', offset: '+0300' },
  { id: 213, name: 'Asia/Almaty', offset: '+0600' },
  { id: 214, name: 'Asia/Amman', offset: '+0300' },
  { id: 215, name: 'Asia/Anadyr', offset: '+1200' },
  { id: 216, name: 'Asia/Aqtau', offset: '+0500' },
  { id: 217, name: 'Asia/Aqtobe', offset: '+0500' },
  { id: 218, name: 'Asia/Ashgabat', offset: '+0500' },
  { id: 219, name: 'Asia/Baghdad', offset: '+0300' },
  { id: 220, name: 'Asia/Bahrain', offset: '+0300' },
  { id: 221, name: 'Asia/Baku', offset: '+0500' },
  { id: 222, name: 'Asia/Bangkok', offset: '+0700' },
  { id: 223, name: 'Asia/Beirut', offset: '+0300' },
  { id: 224, name: 'Asia/Bishkek', offset: '+0600' },
  { id: 225, name: 'Asia/Brunei', offset: '+0800' },
  { id: 226, name: 'Asia/Chita', offset: '+0800' },
  { id: 227, name: 'Asia/Choibalsan', offset: '+0800' },
  { id: 228, name: 'Asia/Colombo', offset: '+0530' },
  { id: 229, name: 'Asia/Damascus', offset: '+0300' },
  { id: 230, name: 'Asia/Dhaka', offset: '+0600' },
  { id: 231, name: 'Asia/Dili', offset: '+0900' },
  { id: 232, name: 'Asia/Dubai', offset: '+0400' },
  { id: 233, name: 'Asia/Dushanbe', offset: '+0500' },
  { id: 234, name: 'Asia/Gaza', offset: '+0300' },
  { id: 235, name: 'Asia/Hebron', offset: '+0300' },
  { id: 236, name: 'Asia/Ho_Chi_Minh', offset: '+0700' },
  { id: 237, name: 'Asia/Hong_Kong', offset: '+0800' },
  { id: 238, name: 'Asia/Hovd', offset: '+0700' },
  { id: 239, name: 'Asia/Irkutsk', offset: '+0800' },
  { id: 240, name: 'Asia/Jakarta', offset: '+0700' },
  { id: 241, name: 'Asia/Jayapura', offset: '+0900' },
  { id: 242, name: 'Asia/Jerusalem', offset: '+0300' },
  { id: 243, name: 'Asia/Kabul', offset: '+0430' },
  { id: 244, name: 'Asia/Kamchatka', offset: '+1200' },
  { id: 245, name: 'Asia/Karachi', offset: '+0500' },
  { id: 246, name: 'Asia/Kathmandu', offset: '+0545' },
  { id: 247, name: 'Asia/Khandyga', offset: '+0900' },
  { id: 248, name: 'Asia/Kolkata', offset: '+0530' },
  { id: 249, name: 'Asia/Krasnoyarsk', offset: '+0700' },
  { id: 250, name: 'Asia/Kuala_Lumpur', offset: '+0800' },
  { id: 251, name: 'Asia/Kuching', offset: '+0800' },
  { id: 252, name: 'Asia/Kuwait', offset: '+0300' },
  { id: 253, name: 'Asia/Macau', offset: '+0800' },
  { id: 254, name: 'Asia/Magadan', offset: '+1000' },
  { id: 255, name: 'Asia/Makassar', offset: '+0800' },
  { id: 256, name: 'Asia/Manila', offset: '+0800' },
  { id: 257, name: 'Asia/Muscat', offset: '+0400' },
  { id: 258, name: 'Asia/Nicosia', offset: '+0300' },
  { id: 259, name: 'Asia/Novokuznetsk', offset: '+0700' },
  { id: 260, name: 'Asia/Novosibirsk', offset: '+0600' },
  { id: 261, name: 'Asia/Omsk', offset: '+0600' },
  { id: 262, name: 'Asia/Oral', offset: '+0500' },
  { id: 263, name: 'Asia/Phnom_Penh', offset: '+0700' },
  { id: 264, name: 'Asia/Pontianak', offset: '+0700' },
  { id: 265, name: 'Asia/Pyongyang', offset: '+0830' },
  { id: 266, name: 'Asia/Qatar', offset: '+0300' },
  { id: 267, name: 'Asia/Qyzylorda', offset: '+0600' },
  { id: 268, name: 'Asia/Rangoon', offset: '+0630' },
  { id: 269, name: 'Asia/Riyadh', offset: '+0300' },
  { id: 270, name: 'Asia/Sakhalin', offset: '+1000' },
  { id: 271, name: 'Asia/Samarkand', offset: '+0500' },
  { id: 272, name: 'Asia/Seoul', offset: '+0900' },
  { id: 273, name: 'Asia/Shanghai', offset: '+0800' },
  { id: 274, name: 'Asia/Singapore', offset: '+0800' },
  { id: 275, name: 'Asia/Srednekolymsk', offset: '+1100' },
  { id: 276, name: 'Asia/Taipei', offset: '+0800' },
  { id: 277, name: 'Asia/Tashkent', offset: '+0500' },
  { id: 278, name: 'Asia/Tbilisi', offset: '+0400' },
  { id: 279, name: 'Asia/Tehran', offset: '+0330' },
  { id: 280, name: 'Asia/Thimphu', offset: '+0600' },
  { id: 281, name: 'Asia/Tokyo', offset: '+0900' },
  { id: 282, name: 'Asia/Ulaanbaatar', offset: '+0800' },
  { id: 283, name: 'Asia/Urumqi', offset: '+0600' },
  { id: 284, name: 'Asia/Ust-Nera', offset: '+1000' },
  { id: 285, name: 'Asia/Vientiane', offset: '+0700' },
  { id: 286, name: 'Asia/Vladivostok', offset: '+1000' },
  { id: 287, name: 'Asia/Yakutsk', offset: '+0900' },
  { id: 288, name: 'Asia/Yekaterinburg', offset: '+0500' },
  { id: 289, name: 'Asia/Yerevan', offset: '+0400' },
  { id: 290, name: 'Atlantic/Azores', offset: '+0000' },
  { id: 291, name: 'Atlantic/Bermuda', offset: '-0300' },
  { id: 292, name: 'Atlantic/Canary', offset: '+0100' },
  { id: 293, name: 'Atlantic/Cape_Verde', offset: '-0100' },
  { id: 294, name: 'Atlantic/Faroe', offset: '+0100' },
  { id: 295, name: 'Atlantic/Madeira', offset: '+0100' },
  { id: 296, name: 'Atlantic/Reykjavik', offset: '+0000' },
  { id: 297, name: 'Atlantic/South_Georgia', offset: '-0200' },
  { id: 298, name: 'Atlantic/St_Helena', offset: '+0000' },
  { id: 299, name: 'Atlantic/Stanley', offset: '-0300' },
  { id: 300, name: 'Australia/Adelaide', offset: '+1030' },
  { id: 301, name: 'Australia/Brisbane', offset: '+1000' },
  { id: 302, name: 'Australia/Broken_Hill', offset: '+1030' },
  { id: 303, name: 'Australia/Currie', offset: '+1100' },
  { id: 304, name: 'Australia/Darwin', offset: '+0930' },
  { id: 305, name: 'Australia/Eucla', offset: '+0845' },
  { id: 306, name: 'Australia/Hobart', offset: '+1100' },
  { id: 307, name: 'Australia/Lindeman', offset: '+1000' },
  { id: 308, name: 'Australia/Lord_Howe', offset: '+1100' },
  { id: 309, name: 'Australia/Melbourne', offset: '+1100' },
  { id: 310, name: 'Australia/Perth', offset: '+0800' },
  { id: 311, name: 'Australia/Sydney', offset: '+1100' },
  { id: 312, name: 'Canada/Atlantic', offset: '-0300' },
  { id: 313, name: 'Canada/Central', offset: '-0500' },
  { id: 314, name: 'Canada/Eastern', offset: '-0400' },
  { id: 315, name: 'Canada/Mountain', offset: '-0600' },
  { id: 316, name: 'Canada/Newfoundland', offset: '-0230' },
  { id: 317, name: 'Canada/Pacific', offset: '-0700' },
  { id: 318, name: 'Europe/Amsterdam', offset: '+0200' },
  { id: 319, name: 'Europe/Andorra', offset: '+0200' },
  { id: 320, name: 'Europe/Athens', offset: '+0300' },
  { id: 321, name: 'Europe/Belgrade', offset: '+0200' },
  { id: 322, name: 'Europe/Berlin', offset: '+0200' },
  { id: 323, name: 'Europe/Bratislava', offset: '+0200' },
  { id: 324, name: 'Europe/Brussels', offset: '+0200' },
  { id: 325, name: 'Europe/Bucharest', offset: '+0300' },
  { id: 326, name: 'Europe/Budapest', offset: '+0200' },
  { id: 327, name: 'Europe/Busingen', offset: '+0200' },
  { id: 328, name: 'Europe/Chisinau', offset: '+0300' },
  { id: 329, name: 'Europe/Copenhagen', offset: '+0200' },
  { id: 330, name: 'Europe/Dublin', offset: '+0100' },
  { id: 331, name: 'Europe/Gibraltar', offset: '+0200' },
  { id: 332, name: 'Europe/Guernsey', offset: '+0100' },
  { id: 333, name: 'Europe/Helsinki', offset: '+0300' },
  { id: 334, name: 'Europe/Isle_of_Man', offset: '+0100' },
  { id: 335, name: 'Europe/Istanbul', offset: '+0300' },
  { id: 336, name: 'Europe/Jersey', offset: '+0100' },
  { id: 337, name: 'Europe/Kaliningrad', offset: '+0200' },
  { id: 338, name: 'Europe/Kiev', offset: '+0300' },
  { id: 339, name: 'Europe/Lisbon', offset: '+0100' },
  { id: 340, name: 'Europe/Ljubljana', offset: '+0200' },
  { id: 341, name: 'Europe/London', offset: '+0100' },
  { id: 342, name: 'Europe/Luxembourg', offset: '+0200' },
  { id: 343, name: 'Europe/Madrid', offset: '+0200' },
  { id: 344, name: 'Europe/Malta', offset: '+0200' },
  { id: 345, name: 'Europe/Mariehamn', offset: '+0300' },
  { id: 346, name: 'Europe/Minsk', offset: '+0300' },
  { id: 347, name: 'Europe/Monaco', offset: '+0200' },
  { id: 348, name: 'Europe/Moscow', offset: '+0300' },
  { id: 349, name: 'Europe/Oslo', offset: '+0200' },
  { id: 350, name: 'Europe/Paris', offset: '+0200' },
  { id: 351, name: 'Europe/Podgorica', offset: '+0200' },
  { id: 352, name: 'Europe/Prague', offset: '+0200' },
  { id: 353, name: 'Europe/Riga', offset: '+0300' },
  { id: 354, name: 'Europe/Rome', offset: '+0200' },
  { id: 355, name: 'Europe/Samara', offset: '+0400' },
  { id: 356, name: 'Europe/San_Marino', offset: '+0200' },
  { id: 357, name: 'Europe/Sarajevo', offset: '+0200' },
  { id: 358, name: 'Europe/Simferopol', offset: '+0300' },
  { id: 359, name: 'Europe/Skopje', offset: '+0200' },
  { id: 360, name: 'Europe/Sofia', offset: '+0300' },
  { id: 361, name: 'Europe/Stockholm', offset: '+0200' },
  { id: 362, name: 'Europe/Tallinn', offset: '+0300' },
  { id: 363, name: 'Europe/Tirane', offset: '+0200' },
  { id: 364, name: 'Europe/Uzhgorod', offset: '+0300' },
  { id: 365, name: 'Europe/Vaduz', offset: '+0200' },
  { id: 366, name: 'Europe/Vatican', offset: '+0200' },
  { id: 367, name: 'Europe/Vienna', offset: '+0200' },
  { id: 368, name: 'Europe/Vilnius', offset: '+0300' },
  { id: 369, name: 'Europe/Volgograd', offset: '+0300' },
  { id: 370, name: 'Europe/Warsaw', offset: '+0200' },
  { id: 371, name: 'Europe/Zagreb', offset: '+0200' },
  { id: 372, name: 'Europe/Zaporozhye', offset: '+0300' },
  { id: 373, name: 'Europe/Zurich', offset: '+0200' },
  { id: 374, name: 'GMT', offset: '+0000' },
  { id: 375, name: 'Indian/Antananarivo', offset: '+0300' },
  { id: 376, name: 'Indian/Chagos', offset: '+0600' },
  { id: 377, name: 'Indian/Christmas', offset: '+0700' },
  { id: 378, name: 'Indian/Cocos', offset: '+0630' },
  { id: 379, name: 'Indian/Comoro', offset: '+0300' },
  { id: 380, name: 'Indian/Kerguelen', offset: '+0500' },
  { id: 381, name: 'Indian/Mahe', offset: '+0400' },
  { id: 382, name: 'Indian/Maldives', offset: '+0500' },
  { id: 383, name: 'Indian/Mauritius', offset: '+0400' },
  { id: 384, name: 'Indian/Mayotte', offset: '+0300' },
  { id: 385, name: 'Indian/Reunion', offset: '+0400' },
  { id: 386, name: 'Pacific/Apia', offset: '+1400' },
  { id: 387, name: 'Pacific/Auckland', offset: '+1300' },
  { id: 388, name: 'Pacific/Bougainville', offset: '+1100' },
  { id: 389, name: 'Pacific/Chatham', offset: '+1345' },
  { id: 390, name: 'Pacific/Chuuk', offset: '+1000' },
  { id: 391, name: 'Pacific/Easter', offset: '-0500' },
  { id: 392, name: 'Pacific/Efate', offset: '+1100' },
  { id: 393, name: 'Pacific/Enderbury', offset: '+1300' },
  { id: 394, name: 'Pacific/Fakaofo', offset: '+1300' },
  { id: 395, name: 'Pacific/Fiji', offset: '+1200' },
  { id: 396, name: 'Pacific/Funafuti', offset: '+1200' },
  { id: 397, name: 'Pacific/Galapagos', offset: '-0600' },
  { id: 398, name: 'Pacific/Gambier', offset: '-0900' },
  { id: 399, name: 'Pacific/Guadalcanal', offset: '+1100' },
  { id: 400, name: 'Pacific/Guam', offset: '+1000' },
  { id: 401, name: 'Pacific/Honolulu', offset: '-1000' },
  { id: 402, name: 'Pacific/Johnston', offset: '-1000' },
  { id: 403, name: 'Pacific/Kiritimati', offset: '+1400' },
  { id: 404, name: 'Pacific/Kosrae', offset: '+1100' },
  { id: 405, name: 'Pacific/Kwajalein', offset: '+1200' },
  { id: 406, name: 'Pacific/Majuro', offset: '+1200' },
  { id: 407, name: 'Pacific/Marquesas', offset: '-0930' },
  { id: 408, name: 'Pacific/Midway', offset: '-1100' },
  { id: 409, name: 'Pacific/Nauru', offset: '+1200' },
  { id: 410, name: 'Pacific/Niue', offset: '-1100' },
  { id: 411, name: 'Pacific/Norfolk', offset: '+1100' },
  { id: 412, name: 'Pacific/Noumea', offset: '+1100' },
  { id: 413, name: 'Pacific/Pago_Pago', offset: '-1100' },
  { id: 414, name: 'Pacific/Palau', offset: '+0900' },
  { id: 415, name: 'Pacific/Pitcairn', offset: '-0800' },
  { id: 416, name: 'Pacific/Pohnpei', offset: '+1100' },
  { id: 417, name: 'Pacific/Port_Moresby', offset: '+1000' },
  { id: 418, name: 'Pacific/Rarotonga', offset: '-1000' },
  { id: 419, name: 'Pacific/Saipan', offset: '+1000' },
  { id: 420, name: 'Pacific/Tahiti', offset: '-1000' },
  { id: 421, name: 'Pacific/Tarawa', offset: '+1200' },
  { id: 422, name: 'Pacific/Tongatapu', offset: '+1300' },
  { id: 423, name: 'Pacific/Wake', offset: '+1200' },
  { id: 424, name: 'Pacific/Wallis', offset: '+1200' },
  { id: 425, name: 'US/Alaska', offset: '-0800' },
  { id: 426, name: 'US/Arizona', offset: '-0700' },
  { id: 427, name: 'US/Central', offset: '-0500' },
  { id: 428, name: 'US/Eastern', offset: '-0400' },
  { id: 429, name: 'US/Hawaii', offset: '-1000' },
  { id: 430, name: 'US/Mountain', offset: '-0600' },
  { id: 431, name: 'US/Pacific', offset: '-0700' },
  { id: 432, name: 'UTC', offset: '+0000' },
];
