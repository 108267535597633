import Styles from './GroupHeader.module.scss';

const GroupHeader = ({ columns, data, children }) => {
  return (
    <div className={Styles.container}>
      {columns?.map(({ field, className, ...rest }, idx) => (
        <span key={idx} className={className} style={{ ...rest }}>
          {typeof field === 'function' ? field(data) : data[field]}
        </span>
      ))}
      {children}
    </div>
  );
};

export default GroupHeader;
