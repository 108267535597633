import { useEffect } from 'react';
import { setPageTitle } from '@hiredigital/lib/helpers/utils';

import Layout from '@components/Layout/AppLayout';
import Availability from '@components/Availability/Availability';

const OnboardingAvailability = () => {
  useEffect(() => {
    setPageTitle('Rates & Availability - Hire Digital');
  }, []);

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <Availability isOnboarding />
    </Layout>
  );
};

export default OnboardingAvailability;
