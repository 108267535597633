import { useState } from 'react';
import classNames from 'classnames';
import { patchUser } from '@apis/users';
import { useCommon, useCommonDispatch } from '@context/common';
import { useUser } from '@context/user';
import { simplifyErrors } from '@hiredigital/lib/helpers/utils';
import { PreferredPlatform } from '@hiredigital/lib/helpers/enum';
import { isTalent, isClient } from '@helpers/permissions';
import ImageDrop from '../Form/File/ImageDrop';
import PhoneNumber from '@hiredigital/ui/PhoneNumber';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import toast from 'react-hot-toast';
import Styles from './UserSettings.module.scss';
import CurrencyDropdown from '@hiredigital/ui/CurrencyDropdown';
import Badge from '@hiredigital/ui/Badge/Badge';

const UserSettings = ({ user: settingsUser }) => {
  const initialUser = useUser();
  const common = useCommon();
  const commonDispatch = useCommonDispatch();
  const [user, setUser] = useState(settingsUser);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingEmailChange, setPendingEmailChange] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [errors, setErrors] = useState({});

  const handleSave = async () => {
    try {
      setIsLoading(true);
      if (isValidPhone) {
        await patchUser(user?.uuid, user);
        if (user.email !== initialUser.email) {
          toast.success('Check your inbox to verify your new email address.');
        } else {
          toast.success('Your account information was updated successfully.');
        }
        // Reset flag so that the demographics and currency are fetched again.
        commonDispatch({
          type: 'UPDATE_CURRENCY_COMPLETION',
          payload: false,
        });
      }
    } catch (error) {
      setErrors(simplifyErrors(error?.response?.data));
    } finally {
      setTimeout(() => setIsLoading(false), 250);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({});
    setUser({ ...user, [name]: value });
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setErrors({});
    if (value !== initialUser.email) {
      setPendingEmailChange(true);
    } else {
      setPendingEmailChange(false);
    }
    setUser({ ...user, [name]: value });
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      contact: {
        ...user.contact,
        [name]: value,
      },
    });
  };

  const handlePhoneChange = (value, valueKey) => {
    setUser({ ...user, contact: { ...user.contact, [valueKey]: value } });
  };

  const handleCurrencyChange = (e) => {
    const { value } = e.target;
    setUser({
      ...user,
      payment: {
        currency: value,
      },
    });
  };

  return (
    <Card>
      <Card.Header title={`Settings`}></Card.Header>
      <Card.Item>
        {isClient(initialUser) ? (
          <>
            <InputGroup>
              <ImageDrop
                className={classNames(Styles.profilePhoto, 'highlight-block')}
                name='picture'
                label='Picture'
                server={`users/${user?.uuid}/`}
                idleLabel='Update Picture'
                value={user?.picture}
                onResponse={({ data: v }) => setUser({ ...user, picture: v?.picture })}
              />
            </InputGroup>

            <InputGroup>
              <InputContainer>
                <TextInput
                  name='firstName'
                  label='First Name'
                  defaultValue={user?.firstName}
                  onChange={handleChange}
                  error={errors?.firstName}
                />
              </InputContainer>
              <InputContainer>
                <TextInput
                  className={'highlight-block'}
                  name='lastName'
                  label='Last Name'
                  defaultValue={user?.lastName}
                  onChange={handleChange}
                  error={errors?.lastName}
                />
              </InputContainer>
            </InputGroup>
          </>
        ) : (
          ''
        )}

        <InputGroup>
          {isTalent(initialUser) ? (
            <InputContainer>
              <TextInput
                className={'highlight-block'}
                name='username'
                label='Username'
                disabled
                defaultValue={user?.username}
                onChange={handleChange}
                error={errors?.username}
              />
            </InputContainer>
          ) : (
            ''
          )}
          <InputContainer style={{ position: 'relative' }}>
            <TextInput
              name='email'
              label='E-mail'
              defaultValue={user?.email}
              onChange={handleEmailChange}
              error={errors?.email}
            />
            {pendingEmailChange ? (
              <Badge color='yellow' borderRadius='20px' position='absolute' top='10px' right='10px'>
                {'Pending'}
              </Badge>
            ) : (
              ''
            )}
          </InputContainer>
        </InputGroup>

        <InputGroup>
          <InputContainer>
            <Select
              className={classNames(Styles.select, 'highlight-block')}
              defaultValue={user?.contact?.timezone}
              classNamePrefix='s-contact'
              name='timezone'
              label='Timezone'
              getOptionLabel={({ name, label, offset }) => {
                return (name || label) + ' (GMT' + offset + ')';
              }}
              getOptionValue={({ id }) => id}
              options={common?.timezones}
              onChange={handleContactChange}
              error={errors?.timezone}
            />
          </InputContainer>

          {user?.uuid && user?.payment && isTalent(initialUser) && (
            <InputContainer>
              <CurrencyDropdown
                className={'highlight-block'}
                type={CurrencyDropdown.Type.DEFAULT}
                defaultValue={user.payment.currency}
                onChange={handleCurrencyChange}
              />
            </InputContainer>
          )}
        </InputGroup>

        <InputGroup>
          <InputContainer>
            {common?.countries && (
              <PhoneNumber
                className={'highlight-block'}
                phoneCountry={user?.contact?.phoneCountry}
                phoneNumber={user?.contact?.phoneNumber}
                onPhoneCountryChange={(v) => handlePhoneChange(v, 'phoneCountry')}
                onPhoneNumberChange={(v) => handlePhoneChange(v, 'phoneNumber')}
                getCountries={async () => ({ data: common.countries })}
                onValidate={setIsValidPhone}
              />
            )}
          </InputContainer>

          <InputContainer>
            <TextInput
              className={'highlight-block'}
              name='skype'
              label='Skype'
              defaultValue={user?.contact?.skype}
              onChange={handleContactChange}
              error={errors?.skype}
            />
          </InputContainer>
        </InputGroup>

        {isClient(initialUser) ? (
          <>
            <InputGroup>
              <InputContainer>
                <TextInput
                  className={'highlight-block'}
                  name='zoom'
                  label='Zoom'
                  defaultValue={user?.contact?.zoom}
                  onChange={handleContactChange}
                  error={errors?.zoom}
                />
              </InputContainer>
              <InputContainer>
                <Select
                  className={classNames(Styles.select, 'highlight-ignore')}
                  defaultValue={PreferredPlatform.getEnum(user?.contact?.preferred)}
                  classNamePrefix='s-contact'
                  name='preferred'
                  label='Call Preference'
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  options={PreferredPlatform.values}
                  onChange={(e) => handleContactChange(e, 'id')}
                  error={errors?.preferred}
                />
              </InputContainer>
            </InputGroup>
          </>
        ) : (
          ''
        )}
      </Card.Item>
      <Card.Footer right>
        <Button
          type={Button.Type.BLUE}
          onClick={handleSave}
          isLoading={isLoading}>{`Save Settings`}</Button>
      </Card.Footer>
    </Card>
  );
};

export default UserSettings;
