import classNames from 'classnames';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import Text from '@hiredigital/ui/Text';
import Styles from './Styles.module.scss';

const Item = ({ onAccept, invitation = {}, isBusy, ...props }) => {
  return (
    <Card.Item {...props} style={{ padding: '12px 20px' }}>
      <div className={Styles.row}>
        <img className={Styles.picture} src={invitation?.picture} alt='' />
        <div style={{ overflow: 'hidden', flex: 1 }}>
          <Text type={Text.Type.PRIMARY}>{`${invitation?.userRole?.label || ''} for ${
            invitation?.organization?.name || invitation?.name
          }`}</Text>
          <p className={classNames(Styles.description, 'highlight-block')}>
            {`Invited by ${invitation?.inviter?.firstName} ${invitation?.inviter?.lastName}`}
          </p>
        </div>
        <Button onClick={() => onAccept?.(invitation)} type={Button.Type.BLUE} isLoading={isBusy}>
          {`Accept`}
        </Button>
      </div>
    </Card.Item>
  );
};

Item.propTypes = {};

export default Item;
