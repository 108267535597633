import { Fragment, memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import classNames from 'classnames';
import Cookies from 'universal-cookie';

import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';

import Menu from '@hiredigital/ui/Menu/Menu';
import Position from 'evergreen-ui/esm/constants/src/Position';
import { Tooltip } from 'evergreen-ui/esm/tooltip';
import { SearchInput } from 'evergreen-ui/esm/search-input';

import { useTeam, useTeamDispatch } from '@context/team';
import { getTeam } from '@apis/teams';
import { COOKIE_DOMAIN } from '@helpers/utils';
import { isTalent } from '@helpers/permissions';
import { UserStatus } from '@hiredigital/lib/helpers/enum';
import { useUser } from '@context/user';
import Avatar from '@hiredigital/ui/Avatar';
import ExpandIcon from '../ExpandIcon/ExpandIcon';

import Styles from '../Dropdown.module.scss';
import TalentMenu from './TalentMenu';

const cookies = new Cookies();

const Item = ({ data }) => {
  return (
    <>
      <Avatar
        className={classNames(Styles.icon, 'highlight-block')}
        name={data?.name || 'Team'}
        src={data?.picture}
      />
      <div className={classNames(Styles.title, 'highlight-mask')}>{data?.name || 'Team'}</div>
    </>
  );
};

const MemoItem = memo(Item);

const TeamMenu = ({ teams = [], setTeamFilter, teamFilter, selectTeam, setMobileOpen }) => {
  const history = useHistory();
  const user = useUser();
  const team = useTeam();

  const isTalentOnboarding = isTalent(user) && user?.status === UserStatus.ONBOARDING.id;

  const dispatch = useTeamDispatch();

  const handleSelectUser = () => {
    dispatch({ type: 'UPDATE', payload: null });
    cookies.remove('team_uuid', COOKIE_DOMAIN);
    history.go(0);
  };

  return (
    <Fragment>
      {user?.isTalent && (
        <Fragment>
          <Menu.Item
            as='div'
            onClick={handleSelectUser}
            className={classNames(
              Styles.menuItem,
              !team?.uuid && Styles.hideOnMobile,
              'highlight-block'
            )}>
            <Avatar
              style={{ marginRight: '10px' }}
              name={user.name}
              hash={user.uuid}
              src={user.picture}
            />
            {user?.name}
          </Menu.Item>
          <div className={classNames(Styles.lineDivider, !team?.uuid && Styles.hideOnMobile)} />
          {teams?.length > 0 && (
            <>
              {teams?.length < 5 ? (
                <div className={Styles.menuGroupTitle}>{`Your Teams`}</div>
              ) : (
                <div className={Styles.searchRow}>
                  <SearchInput
                    placeholder='Filter teams...'
                    width='100%'
                    onChange={(e) => setTeamFilter(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()} // prevent conflicts with Headless UI keyboard navigation
                    value={teamFilter}
                  />
                </div>
              )}
              <div className={Styles.scroller}>
                {teams
                  ?.filter((o) =>
                    teamFilter ? o?.name?.toLowerCase()?.includes(teamFilter?.toLowerCase()) : true
                  )
                  .map((o) => (
                    <Menu.Item
                      key={o.uuid}
                      as='div'
                      className={Styles.menuItem}
                      onClick={() => {
                        selectTeam(o);
                      }}>
                      <div className={Styles.row}>
                        <Avatar
                          style={{ marginRight: '10px' }}
                          name={o.name}
                          hash={o.uuid}
                          src={o.picture}
                        />
                        {o.name}
                      </div>
                    </Menu.Item>
                  ))}
              </div>
            </>
          )}
        </Fragment>
      )}
      {(user?.isTalent && teams?.length > 0) || (
        <Fragment>
          <div className={Styles.menuGroupTitle}>{'Quick Links'}</div>
          <Menu.Item
            as={Link}
            to={`/settings/account`}
            data-test-id='user settings'
            className={Styles.menuItem}>
            {`Account Settings`}
          </Menu.Item>

          {isTalent(user) ? (
            <TalentMenu isTalentOnboarding={isTalentOnboarding} setMobileOpen={setMobileOpen} />
          ) : (
            <>
              <Menu.Item
                as={Link}
                to={`/settings/clients`}
                data-test-id='user orgs'
                className={Styles.menuItem}>
                {`Client Teams`}
              </Menu.Item>
            </>
          )}
        </Fragment>
      )}
      <div className={Styles.lineDivider} />
      <Menu.Item as={Link} to={`/logout`} className={Styles.menuItem}>
        {' '}
        {`Sign Out`}
      </Menu.Item>
    </Fragment>
  );
};

const MemoTeamMenu = memo(TeamMenu);

const TeamDropdown = ({ open, mobileOpen, setMobileOpen }) => {
  const history = useHistory();
  const team = useTeam();
  const user = useUser();

  const [mobileDropdown, setMobileDropdown] = useState(false);
  const [teamFilter, setTeamFilter] = useState('');
  const dispatch = useTeamDispatch();

  useEffect(() => {
    setMobileDropdown(false);
  }, [mobileOpen]);

  useEffect(() => {}, [mobileDropdown]);

  const selectTeam = async (selectedTeam) => {
    try {
      const response = await getTeam(selectedTeam?.uuid);
      const newTeam = response.data;
      cookies.set('team_uuid', selectedTeam?.uuid, COOKIE_DOMAIN);
      dispatch({ type: 'UPDATE', payload: newTeam });
      history.go(0);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      {mobileOpen ? (
        <Menu>
          <Menu.Button
            as='div'
            className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}
            onClick={(e) => {
              e.stopPropagation();
              mobileOpen && setMobileDropdown(!mobileDropdown);
            }}>
            <MemoItem data={team || user} />
            <ExpandIcon isOpen={mobileDropdown} style={{ marginLeft: 'auto' }} />
          </Menu.Button>
          <Menu.Items
            className={classNames(Styles.mobileList, mobileDropdown && Styles.mobileListOpen)}>
            <MemoTeamMenu
              teams={user?.teams}
              setTeamFilter={setTeamFilter}
              teamFilter={teamFilter}
              selectTeam={selectTeam}
              setMobileOpen={setMobileOpen}
            />
          </Menu.Items>
        </Menu>
      ) : (
        <Menu>
          {({ open: isShown }) => (
            <Fragment>
              <Menu.Button as='div' className={Styles.fullWidth}>
                <Tooltip
                  content={team?.name || user?.name || 'Team'}
                  position={Position.RIGHT}
                  isShown={isShown || open || mobileOpen ? false : undefined}>
                  <div className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}>
                    <MemoItem data={team || user} />
                    <IconDown className={Styles.sideArrow} />
                  </div>
                </Tooltip>
              </Menu.Button>
              <Menu.Items
                as='div'
                direction='right'
                className={Styles.menuItems}
                style={{ width: 'auto' }}>
                <MemoTeamMenu
                  teams={user?.teams}
                  setTeamFilter={setTeamFilter}
                  teamFilter={teamFilter}
                  selectTeam={selectTeam}
                />
              </Menu.Items>
            </Fragment>
          )}
        </Menu>
      )}
    </Fragment>
  );
};
TeamDropdown.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
};

export default TeamDropdown;
