import { Fragment, memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import classNames from 'classnames';

import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import IconTeam from '@hiredigital/ui/Icon/icons/expand-team.inline.svg';

import Menu from '@hiredigital/ui/Menu/Menu';
import Position from 'evergreen-ui/esm/constants/src/Position';
import { Tooltip } from 'evergreen-ui/esm/tooltip';

import NavStyles from '../Nav/Nav.module.scss';
import Styles from '../Dropdown.module.scss';

import { useTeam } from '@context/team';

import ExpandIcon from '../ExpandIcon/ExpandIcon';

const Item = () => {
  return (
    <>
      <IconTeam className={NavStyles.icon} />
      <div className={Styles.title}>Team</div>
    </>
  );
};

const MemoItem = memo(Item);

const TeamMenu = ({ setMobileOpen }) => {
  const team = useTeam();

  const handleHashLinkClick = () => setMobileOpen?.(false);

  return (
    <Fragment>
      <div className={Styles.menuGroupTitle}>{`Team`}</div>
      <Menu.Item data-test-id='team profile' className={Styles.menuItem}>
        <HashLink
          smooth
          onClick={handleHashLinkClick}
          to={`/settings/teams/${team.uuid}#profile`}>{`Profile`}</HashLink>
      </Menu.Item>
      <Menu.Item
        as={Link}
        to={`/settings/teams/${team.uuid}/settings`}
        data-test-id='team settings'
        className={Styles.menuItem}>
        {`Settings`}
      </Menu.Item>
      <Menu.Item
        as={Link}
        to={`/settings/teams/${team.uuid}/members`}
        data-test-id='team members'
        className={Styles.menuItem}>
        {`Members`}
      </Menu.Item>
      <Menu.Item data-test-id='team rates' className={Styles.menuItem}>
        <HashLink
          smooth
          onClick={handleHashLinkClick}
          to={`/settings/teams/${team.uuid}#service-rate`}>{`Service & Rates`}</HashLink>
      </Menu.Item>
    </Fragment>
  );
};

const MemoTeamMenu = memo(TeamMenu);

const TeamDropdown = ({ open, mobileOpen, setMobileOpen }) => {
  const [mobileDropdown, setMobileDropdown] = useState(false);

  useEffect(() => {
    setMobileDropdown(false);
  }, [mobileOpen]);

  useEffect(() => {}, [mobileDropdown]);

  return (
    <Fragment>
      {mobileOpen ? (
        <Menu className={Styles.fullWidth}>
          <Menu.Button
            as='div'
            className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}
            onClick={() => mobileOpen && setMobileDropdown(!mobileDropdown)}>
            <MemoItem />
            <ExpandIcon isOpen={mobileDropdown} style={{ marginLeft: 'auto' }} />
          </Menu.Button>
          <Menu.Items
            className={classNames(Styles.mobileList, mobileDropdown && Styles.mobileListOpen)}>
            <MemoTeamMenu setMobileOpen={setMobileOpen} />
          </Menu.Items>
        </Menu>
      ) : (
        <Menu>
          {({ open: isShown }) => (
            <Fragment>
              <Menu.Button as='div' className={Styles.fullWidth}>
                <Tooltip
                  content={'Team'}
                  position={Position.RIGHT}
                  isShown={isShown || open || mobileOpen ? false : undefined}>
                  <div className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}>
                    <MemoItem />
                    <IconDown className={Styles.sideArrow} />
                  </div>
                </Tooltip>
              </Menu.Button>
              <Menu.Items
                className={Styles.menuItems}
                direction='right_bottom'
                style={{ minWidth: 'max-content' }}>
                <MemoTeamMenu setMobileOpen={setMobileOpen} />
              </Menu.Items>
            </Fragment>
          )}
        </Menu>
      )}
    </Fragment>
  );
};
TeamDropdown.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
};

export default TeamDropdown;
