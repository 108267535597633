import { memo } from 'react';
import PropTypes from 'prop-types';

import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';

import Styles from './PortfolioViewer.module.scss';

const MemoIconClose = memo(IconClose);

const Header = memo(({ onClose }) => {
  return (
    <div className={Styles.sideHeader}>
      <button className={Styles.actionClose} onClick={onClose}>
        <MemoIconClose className={Styles.icon} />
      </button>
    </div>
  );
});

Header.propTypes = {
  onClose: PropTypes.func,
};

export default Header;
