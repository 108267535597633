import { useState } from 'react';
import Button from '@hiredigital/ui/Button';
import { useHistory, Link } from 'react-router-dom';

import Menu from '@hiredigital/ui/Menu/Menu';
import Dialog from '@hiredigital/ui/Dialog/Dialog';

import Styles from './Styles.module.scss';

import toast from 'react-hot-toast';

import ManagingOrgList from './ManagingOrgList';

const CreateNew = ({ orgUuid, hideBrief = false, showOrgSelection = false, orgList }) => {
  const history = useHistory();
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClientSelectConfirm = () => {
    setIsConfirmLoading(true);
    if (selected) {
      goToPage(selected);
      setIsDialogShown(false);
      setIsConfirmLoading(false);

      return true;
    } else {
      toast.error('Please select an organization.', { id: 'missingOrgId' });
      setIsConfirmLoading(false);
      return false;
    }
  };

  const handleTalentNewProject = () => {
    if (orgList?.length > 1) {
      setIsDialogShown(true);
      return;
    }

    const client = orgList?.[0];
    goToPage(client);
  };

  const goToPage = (client) => {
    if (!client) {
      console.warn('No client found!');
      return;
    }
    history.push(`/projects/new?orgUuid=${client?.organization?.uuid}`);
  };

  return (
    <>
      <Menu>
        <Menu.Button as='div'>
          <Button className={Styles.mainButton} type={Button.Type.DARKBLUE}>{`Create New`}</Button>
        </Menu.Button>
        <Menu.Items className={Styles.menuItems}>
          {!hideBrief && (
            <Menu.Item
              className={Styles.menuItem}
              as={Link}
              to={`/briefs/new`}>{`New Talent Brief`}</Menu.Item>
          )}
          {showOrgSelection ? (
            <Menu.Item
              as='div'
              className={Styles.menuItem}
              onClick={handleTalentNewProject}>{`New Project`}</Menu.Item>
          ) : (
            <Menu.Item
              as={Link}
              className={Styles.menuItem}
              to={`/projects/new?orgUuid=${orgUuid}`}>{`New Project`}</Menu.Item>
          )}
        </Menu.Items>
      </Menu>
      <Dialog
        isOpen={isDialogShown}
        title='Select Organization'
        onClose={() => setIsDialogShown(false)}>
        <ManagingOrgList orgList={orgList} onSelect={setSelected} />

        <div style={{ marginTop: '20px' }}>
          <Button
            type={Button.Type.BLUE}
            onClick={handleClientSelectConfirm}
            isLoading={isConfirmLoading}>
            {'Submit'}
          </Button>
          <Button type={Button.Type.LIGHT_GRAY} onClick={() => setIsDialogShown(false)}>
            {'Cancel'}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default CreateNew;
