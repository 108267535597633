import { Fragment } from 'react';

import Button from '@hiredigital/ui/Button/Button';

// import * as intercom from '../../lib/intercom';
import { useIntercom } from '@hiredigital/lib/providers/intercom';
import { useHistory } from 'react-router-dom';
import { useUser } from '@context/user';
import Layout from '@components/Layout/AppLayout';
import Section from '@components/StagesLayout/Section';
import Steps from '@components/Guide/StepsContainer';
import BlogTitle from '@components/Guide/BlogTitleContainer';
import PaymentCard from '@components/Guide/PaymentCard';

import Styles from './Styles.module.scss';

const Guide = () => {
  const intercom = useIntercom();
  const user = useUser();
  const history = useHistory();
  return (
    <Layout>
      <div className={Styles.guideContainer}>
        <Section
          className={Styles.section}
          label={`Getting Started`}
          title={`Hi ${user?.name}! Welcome to Hire Digital`}
          subtitle={`Thanks for registering an account on Hire Digital. You’re a few steps away from getting matched to relevant clients. Just take a few moments to complete the following steps.`}
          action={
            <Button
              onClick={() => history.push('/engagements')}
              // href={`/engagements`}
              style={{ marginLeft: 'auto' }}
              type={Button.Type.BLUE_OUTLINE}>
              {`Hide Guide`}
            </Button>
          }>
          <Steps>
            <Steps.Item
              stepNumber={1}
              title={`Complete your Profile`}
              description={`Clients on  Hire Digital will view your profile each time you are matched, so make sure your profile and resume are up to date.`}
              actionLabel={`Edit Profile`}
              href={`/onboarding/profile`}
              isCompleted={user?.profileUpdated}
            />
            <Steps.Item
              stepNumber={2}
              title={`Add Relevant Works`}
              description={`In addition to your profile, your past work will help clients understand what you can accomplish and how your experience aligns to their needs.`}
              actionLabel={`Add Works`}
              href={`/onboarding/profile#portfolio`}
              isCompleted={user?.portfolioUpdated}
            />
            <Steps.Item
              stepNumber={3}
              title={`Add your Rates and Availability`}
              description={`We match clients to you based on your availability and your rates. We recommend you set your availability for at least the next three months.`}
              actionLabel={`Edit Rates and Availability`}
              href={`/onboarding/availability`}
              isCompleted={user?.availabilityUpdated}
            />
          </Steps>
        </Section>
        <Section
          className={Styles.section}
          label={`About Hire Digital`}
          title={`Learn more about Hire Digital`}
          subtitle={`Get the most out of Hire Digital by reading our tips section and insights blog.`}>
          <BlogTitle>
            <BlogTitle.Item
              title={`What is the Hire Digital Talent Network?`}
              href={`${process.env.MARKETING_URL}/talents`}
            />
            <BlogTitle.Item
              title={`Learn about the Application Process`}
              href={`${process.env.MARKETING_URL}/talents/faq`}
            />
            <BlogTitle.Item
              title={`Learn more about our Clients`}
              href={`${process.env.MARKETING_URL}/clients`}
            />
            <BlogTitle.Item
              title={`Visit the Hire Digital Insight Blog`}
              href={`${process.env.MARKETING_URL}/blog`}
            />
          </BlogTitle>
        </Section>
        <Section className={Styles.section} title={`Payment Information`} titleFontSize={24}>
          <PaymentCard>
            <PaymentCard.Item
              img={`/img/project.png`}
              title={`When will I get paid?`}
              description={`Hire Digital processes payments at the end of each month. You can check your payment status under the Settings tab.`}
            />
            <PaymentCard.Item
              img={`/img/other.png`}
              title={`Need other payment methods?`}
              description={() => (
                <Fragment>
                  {`We are considering other payment methods that our talents prefer. If you have one, let us know `}
                  <span
                    className={Styles.linkIntercom}
                    onClick={() => intercom.toggle()}>{`here`}</span>
                  {`.`}
                </Fragment>
              )}
            />
          </PaymentCard>
        </Section>
      </div>
    </Layout>
  );
};

export default Guide;
