import { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MemberInvitationStatus } from '@hiredigital/lib/helpers/enum';
import { toReadableDate } from '@hiredigital/lib/helpers/date';

import Styles from './Styles.module.scss';

import Card from '@hiredigital/ui/Card';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import Text from '@hiredigital/ui/Text';

import Badge from '@hiredigital/ui/Badge/Badge';
import SplitButton from '@hiredigital/ui/Button/SplitButton';

const Type = {
  ORGANIZATION: 0,
  TEAM: 1,
};

const Invitation = ({
  isBusy = false,
  onUpdateInvitation,
  onDeleteInvitation,
  invitation,
  type,
  ...props
}) => {
  const updateInvitation = (invitation, status) => onUpdateInvitation?.(invitation, status);
  const deleteInvitation = (invitation) => onDeleteInvitation?.(invitation);

  return isBusy ? (
    <Card.Item>
      <Loader type={Loader.Type.BLUE} size={Button.Size.SMALL} />
    </Card.Item>
  ) : (
    <Card.Item {...props}>
      <div className={Styles.row}>
        <img
          className={classNames(Styles.picture, 'highlight-block')}
          src={invitation.picture}
          alt={invitation.name}
        />
        <div style={{ overflow: 'hidden' }}>
          <Text type={Text.Type.PRIMARY} className={'highlight-block'}>
            {invitation.name}
          </Text>
          <Text type={Text.Type.SECONDARY}>{invitation.email}</Text>
        </div>

        <div className={Styles.action}>
          {type === Type.ORGANIZATION ? (
            <Fragment>
              {(invitation.created && (
                <p className={Styles.status}>{`Invitation sent on ${toReadableDate(
                  invitation.created
                )}`}</p>
              )) || (
                <p className={Styles.status}>
                  {MemberInvitationStatus.getLabel(invitation.status)}
                </p>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Badge color='orange'>{`Invitation Sent`}</Badge>
            </Fragment>
          )}
          <div>
            <SplitButton
              more={[
                {
                  text: 'Resend Invitation',
                  onClick: () => updateInvitation(invitation, MemberInvitationStatus.RESEND),
                },
                {
                  text: 'Cancel Invitation',
                  onClick: () => deleteInvitation(invitation),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </Card.Item>
  );
};

Invitation.propTypes = {
  invitation: PropTypes.shape({
    picture: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.number,
  }),
  onDeleteInvitation: PropTypes.func,
  onUpdateInvitation: PropTypes.func,
};

export default Invitation;
