import { authRequest } from './utils';

export const getNotifications = async (config = undefined) => {
  const url = `email-notifications/`;
  return await authRequest.get(url, config);
};

export const getNotification = async (uuid, config = undefined) => {
  const url = `email-notifications/${uuid}/`;
  return await authRequest.get(url, config);
};
