import PropTypes from 'prop-types';
import Styles from './Header.module.scss';
import classNames from 'classnames';

const Header = (props) => {
  return (
    <div className={Styles.wrapper}>
      <div className={classNames(Styles.container, props.containerClass)}>{props.children}</div>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  store: PropTypes.object,
};

export default Header;
