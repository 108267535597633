import { Fragment, memo } from 'react';
import SplitButton from '@hiredigital/ui/Button/SplitButton';

import { useUser } from '@context/user';
import { ConferenceStatus, MessageType } from '@hiredigital/lib/helpers/enum';

import Styles from './Message.module.scss';
import MessageBody from './MessageBody';

const MenuActions = ({
  message,
  onAddParticipant,
  onReschedule,
  onCancelSchedule,
  onEdit,
  onDelete,
  onOpenNew,
}) => {
  return (
    <div className={Styles.action}>
      {message?.type === MessageType.ACTIVITY.id && message?.metaObject ? (
        <Fragment>
          {message?.metaObject?.status === ConferenceStatus.CANCELLED.id ? (
            <Fragment />
          ) : (
            <SplitButton
              mainButtonContainerClass={Styles.actionSplitContainer}
              moreButtonClass={Styles.actionSplitButton}
              more={[
                { text: 'Add or Remove Participants', onClick: () => onAddParticipant?.(message) },
                { text: 'Add or Remove Guest', onClick: () => onAddParticipant?.(message) },
                { text: 'Reschedule Call', onClick: () => onReschedule?.(message) },
                { text: 'Cancel Call', onClick: () => onCancelSchedule?.(message) },
              ]}
            />
          )}
        </Fragment>
      ) : (
        <SplitButton
          mainButtonContainerClass={Styles.actionSplitContainer}
          moreButtonClass={Styles.actionSplitButton}
          more={[
            ...(message?.metaObject
              ? [
                  { text: 'Delete', onClick: () => onDelete?.(message) },
                  { text: 'Open in New', onClick: () => onOpenNew?.(message) },
                ]
              : [
                  { text: 'Edit', onClick: () => onEdit?.(message) },
                  { text: 'Delete', onClick: () => onDelete?.(message) },
                ]),
          ]}
        />
      )}
    </div>
  );
};

const MemoMenuActions = memo(MenuActions);
const Message = ({
  discussion,
  nextMessageSender,
  message,
  onDelete,
  onEdit,
  onCancelSchedule,
  onAddParticipant,
  onReschedule,
}) => {
  const currentUser = useUser();

  const onOpenNew = (message) => {
    const url = message?.metaObject?.url || message?.metaObject?.attachment;
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
    a.remove();
  };

  return (
    <div className={Styles.messageContainer} data-test-id='message-container-id'>
      {/*
      showDEtails={nextMessageSender?.uuid !== message?.sender?.uuid}
    */}
      <MessageBody
        message={message}
        showDetails={true}
        discussion={discussion}
        timeClass={Styles.dateVisible}
      />

      {currentUser?.uuid === message?.sender?.uuid && (
        <MemoMenuActions
          {...{
            message,
            onAddParticipant,
            onReschedule,
            onCancelSchedule,
            onEdit,
            onDelete,
            onOpenNew,
          }}
        />
      )}
    </div>
  );
};

export default memo(Message);
