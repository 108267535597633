import { authRequest } from './utils';

const serializerCall = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

const serializerBriefCall = (data) => {
  const serializedData = {
    ...data,
    timezone: data?.timezone?.id,
  };
  return serializedData;
};

export const postCallDaysAvailable = async (
  data,
  serialize = serializerCall,
  config = undefined
) => {
  const url = `calls/days-available/`;
  return await authRequest.post(url, serialize(data), config);
};

export const putBriefCall = async (
  briefUuid,
  callId,
  data,
  serialize = serializerBriefCall,
  config = undefined
) => {
  const url = `briefs/${briefUuid}/call/${callId}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const patchBriefCall = async (
  briefUuid,
  callId,
  data,
  serialize = serializerBriefCall,
  config = undefined
) => {
  const url = `briefs/${briefUuid}/call/${callId}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const patchCall = async (
  briefUuid,
  callId,
  data,
  serialize = serializerBriefCall,
  config = undefined
) => {
  const url = `/call/${callId}/`;
  return await authRequest.patch(url, serialize(data), config);
};
