import convert from 'htmr';
import Styles from './OverviewStyles.module.scss';

const OverviewColumn = ({ children, width = 50 }) => (
  <div className={Styles.columnItem} style={{ width: `${width}%` }}>
    {children}
  </div>
);

OverviewColumn.propTypes = {};

export default OverviewColumn;
