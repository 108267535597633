import { Tab } from '@headlessui/react';
import Styles from './Tab.module.scss';

const Item = ({
  children,
  icon: Icon,
  completedIcon: CompletedIcon,
  isCompleted = false,
  caption,
}) => {
  const TabIcon = isCompleted ? CompletedIcon || Icon : Icon;
  return (
    <Tab className={Styles.tabListItem}>
      {TabIcon && <TabIcon className={Styles.itemIcon} />}
      {caption && <span className={Styles.itemCaption}>{caption}</span>}
      {children}
    </Tab>
  );
};

const Panel = ({ children }) => {
  return <Tab.Panel className={Styles.tabPanelItem}>{children}</Tab.Panel>;
};

const List = ({ children }) => {
  return <Tab.List className={Styles.tabList}>{children}</Tab.List>;
};

const Panels = ({ children }) => {
  return <Tab.Panels className={Styles.tabPanels}>{children}</Tab.Panels>;
};

const TabContainer = ({ children, ...props }) => {
  return (
    <div className={Styles.tabContainer}>
      <Tab.Group {...props}>{children}</Tab.Group>
    </div>
  );
};

TabContainer.List = List;
TabContainer.Item = Item;
TabContainer.Panels = Panels;
TabContainer.Panel = Panel;

export default TabContainer;
