import { Fragment, memo, useState, useEffect } from 'react';
import classNames from 'classnames';
import Styles from './TabItems.module.scss';

// import Icon from '@hiredigital/ui/Icon/Icon';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';

const TabDropdown = ({ items, onSelect, activeIndex, actionText, onActionClick, isActionBusy }) => {
  const newItems = [...items, { text: actionText }];
  const actionIndex = newItems.length - 1;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleSelect = (e, idx) => {
    e.stopPropagation();

    if (idx === actionIndex) {
      onActionClick?.(idx);
      return;
    }

    onSelect?.(idx);
    setIsOpen(false);
  };

  const handleToggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <div className={Styles.dropdown}>
      <div className={Styles.dropdownText} onClick={handleToggleDropdown}>
        {activeIndex !== actionIndex && (
          <Fragment>
            {newItems?.[activeIndex]?.text}
            {newItems?.[activeIndex]?.slotSelected?.()}
          </Fragment>
        )}
      </div>
      <button className={Styles.dropdownAction} onClick={handleToggleDropdown}>
        <IconDown className={Styles.dropdownIcon} />
      </button>
      {isOpen && (
        <div className={Styles.dropdownItems}>
          {newItems?.map((v, idx) => (
            <div
              key={idx}
              onClick={(e) => handleSelect?.(e, idx)}
              className={classNames(Styles.dropdownItem, idx === activeIndex && Styles.active)}>
              {v?.text}
              {actionIndex === idx && (
                <Fragment>
                  {isActionBusy && (
                    <Loader
                      size={Loader.Size.SMALL}
                      type={Loader.Type.BLUE}
                      style={{ marginLeft: '10px' }}
                    />
                  )}
                </Fragment>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const TabItems = ({
  items,
  activeIndex = 0,
  onSelect,
  onSearchSelect,
  isActionBusy,
  actionText,
  onActionClick,
  isDropdown = false,
  children,
  className,
}) => {
  return (
    <div className={classNames(Styles.container, isDropdown && Styles.noPadding, className)}>
      {isDropdown ? (
        <Fragment>
          <TabDropdown
            {...{ items, onSelect, activeIndex, actionText, onActionClick, isActionBusy }}
          />
        </Fragment>
      ) : (
        <Fragment>
          {items.map((v, idx) => (
            <div
              key={idx}
              onClick={() => onSelect?.(idx)}
              className={classNames(Styles.item, idx === activeIndex && Styles.active)}>
              {v?.text}
              {idx === activeIndex && (
                <Fragment>
                  {v?.slotSelected && (
                    <div className={Styles.slotActionContainer}>{v?.slotSelected?.()}</div>
                  )}
                </Fragment>
              )}
            </div>
          ))}
          {children}
          <Button
            type={Button.Type.WHITE_BLUE_OUTLINE}
            className={Styles.actionSchedule}
            onClick={onActionClick}
            isLoading={isActionBusy}>
            {actionText}
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default memo(TabItems);
