import { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import filter from 'lodash/filter';
import Toggle from 'react-toggle';

import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Select from '@hiredigital/ui/Input/Select';

import Quill from '@hiredigital/ui/Quill/Editor';
import CurrencyDropdown from '@hiredigital/ui/CurrencyDropdown';
import {
  PaymentType,
  BankAccountType,
  IdDocumentType,
  BankAccountCurrency,
} from '@hiredigital/lib/helpers/enum';
import { BankDataItems, PayPalDataItems, defaultState } from './constants';
import Styles from './BankAccount.module.scss';
import FlagStyles from '../Flags.module.scss';
import 'react-toggle/style.css'; // for ES6 modules
import toast from 'react-hot-toast';

class BankAccountItem extends Component {
  static propTypes = {
    user: PropTypes.object,
    bankAccount: PropTypes.object,
    updateBankAccount: PropTypes.func,
    deleteBankAccount: PropTypes.func,
    onBankAccountEdit: PropTypes.func,
    defaultEmail: PropTypes.string,
    showWiseId: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const acct = this.props.bankAccount;
    this.state = {
      address: {},
      email: this.props.defaultEmail,
      ...acct,
      lastFour: (
        acct.accountNumber ||
        acct.iban ||
        acct.cardNumber ||
        acct.clabe ||
        acct.bban
      )?.slice(-4),
      simpleRowDetail:
        acct.newBankCode?.title ||
        acct.newSwiftCode?.title ||
        (acct.swift && `BIC Code: ${acct.swift}`) ||
        (acct.bsbCode && `BSB Code: ${acct.bsbCode}`) ||
        (acct.sortCode && `Sort Code: ${acct.sortCode}`) ||
        (acct.ifscCode && `IFSC Code: ${acct.ifscCode}`) ||
        (acct.abartn && `Bank Routing Number: ${acct.abartn}`) ||
        (acct.institutionNumber && `Institution Number: ${acct.institutionNumber}`) ||
        (acct.idDocumentNumber && `ID Document Number: ${acct.idDocumentNumber}`) ||
        (acct.taxId && `Tax ID Number: ${acct.taxId}`) ||
        (acct.phoneNumber && `Phone Number: ${acct.phoneNumber}`) ||
        (acct.currency === 'XOF' && acct.address?.country && `Country: ${acct.address.country}`) ||
        BankAccountCurrency.getEnum(acct.currency)?.name,
      isEdit: false,
      ...defaultState,

      idDocumentTypes: [],
      banks: [],
      branches: [],
      swiftCodes: [],

      accountNumberValid: true,
      sortCodeValid: true,
      ibanValid: true,
      bicCodeValid: true,
      abartnValid: true,
      bsbCodeValid: true,
      ifscCodeValid: true,
      institutionNumberValid: true,
      transitNumberValid: true,
      cardNumberValid: true,

      newBankCodeValid: true,
      accountTypeValid: true,
      newBranchCodeValid: true,
      newSwiftCodeValid: true,
      addressValid: true,
      postalCodeValid: true,
      cityValid: true,
      regionValid: true,
      countryValid: true,
      phoneNumberValid: true,
      taxIdValid: true,
      cpfValid: true,
      rutValid: true,
      idDocumentTypeValid: true,
      idDocumentNumberValid: true,
      prefixValid: true,
      clabeValid: true,
      russiaRegionValid: true,
      bbanValid: true,
      paypalEmailValid: true,
      dateOfBirthValid: true,
      emailValid: true,
      loading: false,
      noOfVisibleFields: 0,
    };

    // additional handling for savings/current - Bank Account Types
    this.bankAccountTempId = 3; // we have savings/current with the same id(2), we need to have a different id for them to be identify
    this.bankAccountTypes = [...BankAccountType.values];
    this.bankAccountTypes[BankAccountType.values.length - 1].id = this.bankAccountTempId;
    this.configureErrorHandler(props.validator?.getRequest());
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.branches !== this.state.branches ||
      nextState.banks !== this.state.banks ||
      nextState.swiftCodes !== this.state.swiftCodes ||
      nextState.idDocumentTypes !== this.state.idDocumentTypes ||
      nextState.loading !== this.state.loading ||
      nextState.isEdit !== this.state.isEdit ||
      nextState.type !== this.state.type ||
      nextState.accountNumberValid !== this.state.accountNumberValid ||
      nextState.sortCodeValid !== this.state.sortCodeValid ||
      nextState.ibanValid !== this.state.ibanValid ||
      nextState.bicCodeValid !== this.state.bicCodeValid ||
      nextState.abartnValid !== this.state.abartnValid ||
      nextState.bsbCodeValid !== this.state.bsbCodeValid ||
      nextState.ifscCodeValid !== this.state.ifscCodeValid ||
      nextState.institutionNumberValid !== this.state.institutionNumberValid ||
      nextState.transitNumberValid !== this.state.transitNumberValid ||
      nextState.cardNumberValid !== this.state.cardNumberValid ||
      nextState.newBankCodeValid !== this.state.newBankCodeValid ||
      nextState.accountTypeValid !== this.state.accountTypeValid ||
      nextState.newBranchCodeValid !== this.state.newBranchCodeValid ||
      nextState.newSwiftCodeValid !== this.state.newSwiftCodeValid ||
      nextState.addressValid !== this.state.addressValid ||
      nextState.postalCodeValid !== this.state.postalCodeValid ||
      nextState.cityValid !== this.state.cityValid ||
      nextState.regionValid !== this.state.regionValid ||
      nextState.countryValid !== this.state.countryValid ||
      nextState.phoneNumberValid !== this.state.phoneNumberValid ||
      nextState.taxIdValid !== this.state.taxIdValid ||
      nextState.cpfValid !== this.state.cpfValid ||
      nextState.rutValid !== this.state.rutValid ||
      nextState.idDocumentTypeValid !== this.state.idDocumentTypeValid ||
      nextState.idDocumentNumberValid !== this.state.idDocumentNumberValid ||
      nextState.prefixValid !== this.state.prefixValid ||
      nextState.clabeValid !== this.state.clabeValid ||
      nextState.russiaRegionValid !== this.state.russiaRegionValid ||
      nextState.bbanValid !== this.state.bbanValid ||
      nextState.paypalEmailValid !== this.state.paypalEmailValid ||
      nextState.dateOfBirthValid !== this.state.dateOfBirthValid ||
      nextState.emailValid !== this.state.emailValid
    ) {
      return true;
    }

    return false;
  }

  componentDidMount = () => {
    if (!this.props.bankAccount.id) {
      this.setState({ isEdit: true });
    }

    if (
      find(this.state.bankCodeCountries, {
        country: this.state.currency,
      })
    ) {
      this.loadBanks(this.state.currency);
    }

    if (
      find(this.state.branchCodeCountries, {
        country: this.state.currency,
      })
    ) {
      this.loadBranches(this.state.currency, this.state.newBankCode);
    }

    if (
      find(this.state.swiftCodeCountries, {
        country: this.state.currency,
      })
    ) {
      this.loadSwiftCodes(this.state.currency);
    }

    if (
      find(this.state.idDocumentTypeCountries, {
        country: this.state.currency,
      })
    ) {
      this.setState({
        idDocumentTypes: filter(IdDocumentType.values, {
          country: this.state.currency,
        }),
      });
    }

    this.availableFields = [];
  };

  configureErrorHandler = (instance) => {
    if (!instance) return;
    instance.interceptors.response.handlers = [];
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        const errors = error?.response?.data?.errors;
        if (errors?.length > 0) {
          const combine = errors
            .map((v) => v?.message || v?.code)
            .reverse()
            .join(' ');
          toast.error(combine);
        }
        return Promise.reject(error);
      }
    );
  };

  handleEdit = () => {
    this.setState({ isEdit: true }, () => {
      this.bankAccountBaseState = { ...this.state }; // create a separate copy of base state to prevent it from being directly mutated
    });
  };

  handleCancel = () => {
    if (this.state.isEdit) {
      this.setState({ ...this.bankAccountBaseState, isEdit: false }); // restore the base state when editing is cancelled
    }

    if (!this.state.id) {
      this.props.updateBankAccount();
    }
  };

  loadBanks = (currency) => {
    const { getBankAccountsBanks } = this.props.apiManager;
    getBankAccountsBanks(currency.slice(0, -1)).then((response) => {
      this.setState({
        banks: response.data.values,
      });
    });
  };

  loadBranches = (currency, bank) => {
    if (bank) {
      const { getBankBranches } = this.props.apiManager;
      getBankBranches(currency.slice(0, -1), bank?.code).then((response) => {
        this.setState({
          branches: response.data.values,
        });
      });
    }
  };

  loadSwiftCodes = (currency) => {
    const { getSwiftCodes } = this.props.apiManager;
    getSwiftCodes(currency.slice(0, -1)).then((response) => {
      this.setState({
        swiftCodes: response.data.values,
      });
    });
  };

  setAvailableFields = (name) => {
    if (this.availableFields.indexOf(name) === -1) {
      this.availableFields.push(name);
    }
  };

  componentDidUpdate = () => {
    this.setState({ noOfVisibleFields: this.availableFields?.length });
  };

  render() {
    const dataItems = this.state.type === PaymentType.PAYPAL.id ? PayPalDataItems : BankDataItems;

    return (
      <>
        {this.state.isEdit && (
          <>
            <Card.Item>
              <div className={Styles.switch}>
                <p className={Styles.switchTitle}>{`Select Payment Method`}</p>
                <div className={Styles.row}>
                  {PaymentType.values.map((type, index) => (
                    <div className={Styles.switchItem} key={index}>
                      <input
                        type='radio'
                        name='type'
                        id={`type_${type.id}`}
                        checked={this.state.type === type.id}
                        value={type.id}
                        disabled
                      />
                      <label className={Styles.switchLabel} htmlFor={`type_${type.id}`}>
                        {type.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </Card.Item>
            <Card.Note className={Styles.cardNote} style={{ fontWeight: 'bold' }}>
              ⚠️ We're migrating to a new payment method management system. Please copy the details,
              delete this payment method and add it again as a new entry.
            </Card.Note>
            <Card.Item>
              <div className={Styles.form} data-test-id='currency-dropdown'>
                {this.state.type === PaymentType.TRANSFERWISE.id && (
                  <>
                    <InputGroup className={Styles.multiRow}>
                      <InputContainer className={Styles.bankFields}>
                        <CurrencyDropdown
                          type={CurrencyDropdown.Type.BANK}
                          defaultValue={this.state.currency}
                          disabled
                        />
                      </InputContainer>
                      {find(this.state.bankCodeCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('newBankCode'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <Select
                              data-test-id='newBankCode'
                              className={Styles.select}
                              defaultValue={this.state.newBankCode}
                              classNamePrefix='s-contact'
                              name='newBankCode'
                              label='Bank'
                              error={!this.state.newBankCodeValid && `Bank code is required.`}
                              getOptionLabel={({ title }) => title}
                              getOptionValue={({ code }) => code}
                              options={this.state.banks}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.branchCodeCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('newBranchCode'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <Select
                              data-test-id='newBranchCode'
                              className={Styles.select}
                              defaultValue={this.state.newBranchCode}
                              classNamePrefix='s-contact'
                              name='newBranchCode'
                              label='Branch'
                              error={!this.state.newBranchCodeValid && `Branch code is required.`}
                              getOptionLabel={({ title }) => title}
                              getOptionValue={({ code }) => code}
                              options={this.state.branches}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.abartnCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('abartn'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='abartn'
                              label='Bank Routing Number'
                              type='number'
                              error={
                                !this.state.abartnValid &&
                                (!this.state.abartn
                                  ? 'Bank routing number is required.'
                                  : 'Enter valid bank routing number')
                              }
                              defaultValue={this.state.abartn}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.accountTypeCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('accountType'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <Select
                              data-test-id='accountType'
                              className={Styles.select}
                              defaultValue={
                                this.bankAccountTypes.find(
                                  ({ id }) => id === this.state.accountType
                                ) || ''
                              }
                              classNamePrefix='s-contact'
                              name='accountType'
                              label='Account Type'
                              error={!this.state.accountTypeValid && `Account type is required.`}
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={this.bankAccountTypes.filter(
                                ({ country }) =>
                                  country === 'all' || country === this.state.currency
                              )}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.accountNumberCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('accountNumber'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='accountNumber'
                              label='Account Number'
                              type='text'
                              error={
                                !this.state.accountNumberValid && `Enter valid account number.`
                              }
                              defaultValue={this.state.accountNumber}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.bbanCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('bban'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='bban'
                              label='BBAN'
                              error={!this.state.bbanValid && `BBAN is required.`}
                              defaultValue={this.state.bban}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.bsbCodeCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('bsbCode'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='bsbCode'
                              label='BSB Code'
                              type='number'
                              error={!this.state.bsbCodeValid && `Enter valid BSB code.`}
                              defaultValue={this.state.bsbCode}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.sortCodeCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('sortCode'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='sortCode'
                              label='Sort Code'
                              type='number'
                              error={!this.state.sortCodeValid && `Enter valid sort code.`}
                              defaultValue={this.state.sortCode}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.ibanCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('iban'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='iban'
                              label='IBAN'
                              error={!this.state.ibanValid && `Enter valid IBAN.`}
                              defaultValue={this.state.iban}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.bicCodeCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('bicCode'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='swift'
                              label='BIC Code'
                              error={!this.state.bicCodeValid && `Enter valid BIC code.`}
                              defaultValue={this.state.swift}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.swiftCodeCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('newSwiftCode'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <Select
                              data-test-id='newSwiftCode'
                              className={Styles.select}
                              defaultValue={this.state.newSwiftCode}
                              classNamePrefix='s-contact'
                              name='newSwiftCode'
                              label='SWIFT Code'
                              error={!this.state.newSwiftCodeValid && `SWIFT code is required.`}
                              getOptionLabel={({ title, code }) => `${title} (${code})`}
                              getOptionValue={({ code }) => code}
                              options={this.state.swiftCodes}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.institutionNumberCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('institutionNumber'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='institutionNumber'
                              label='Institution Number'
                              type='text'
                              error={
                                !this.state.institutionNumberValid &&
                                `Enter valid institution number.`
                              }
                              defaultValue={this.state.institutionNumber}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.transitNumberCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('transitNumber'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='transitNumber'
                              label='Transit Number'
                              type='number'
                              error={
                                !this.state.transitNumberValid && `Enter valid transit number.`
                              }
                              defaultValue={this.state.transitNumber}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.unionPayCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('cardNumber'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='cardNumber'
                              label='UnionPay Card Number'
                              type='number'
                              error={!this.state.cardNumberValid && `Enter valid card number.`}
                              defaultValue={this.state.cardNumber}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.ifscCodeCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('ifscCode'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='ifscCode'
                              label='IFSC Code'
                              error={!this.state.ifscCodeValid && `Enter valid IFSC code.`}
                              defaultValue={this.state.ifscCode}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.taxIdCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('taxId'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='taxId'
                              label='Tax ID Number'
                              type='number'
                              error={!this.state.taxIdValid && `Tax ID number is required.`}
                              defaultValue={this.state.taxId}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.cpfCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('cpf'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='cpf'
                              label='CPF'
                              type='number'
                              error={!this.state.cpfValid && `CPF is required.`}
                              defaultValue={this.state.cpf}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.rutCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('rut'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='rut'
                              label='RUT'
                              type='number'
                              error={!this.state.rutValid && `RUT is required.`}
                              defaultValue={this.state.rut}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.idDocumentTypeCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('idDocumentType'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <Select
                              data-test-id='idDocumentType'
                              className={Styles.select}
                              defaultValue={IdDocumentType.getEnum(
                                this.state.currency === 'CRC'
                                  ? parseInt(this.state.idDocumentType)
                                  : this.state.idDocumentType
                              )}
                              classNamePrefix='s-contact'
                              name='idDocumentType'
                              label='ID Document Type'
                              error={
                                !this.state.idDocumentTypeValid && `ID document type is required.`
                              }
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ id }) => id}
                              options={this.state.idDocumentTypes}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.idDocumentNumberCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('idDocumentNumber'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='idDocumentNumber'
                              label='ID Document Number'
                              error={
                                !this.state.idDocumentNumberValid &&
                                `ID document number is required.`
                              }
                              defaultValue={this.state.idDocumentNumber}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.prefixCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('prefix'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='prefix'
                              label='Prefix'
                              type='number'
                              error={!this.state.prefixValid && `Prefix is required.`}
                              defaultValue={this.state.prefix}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.clabeCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('clabe'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='clabe'
                              label='Clabe'
                              type='number'
                              error={!this.state.clabeValid && `Clabe is required.`}
                              defaultValue={this.state.clabe}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.russiaRegionCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('russiaRegion'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='russiaRegion'
                              label='Russia Region'
                              error={!this.state.russiaRegionValid && `Russia region is required.`}
                              defaultValue={this.state.russiaRegion}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.phoneNumberCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('phoneNumber'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='phoneNumber'
                              label='Phone Number'
                              type='number'
                              error={!this.state.phoneNumberValid && `Phone number is required.`}
                              defaultValue={this.state.phoneNumber}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.dobCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('dateOfBirth'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='dateOfBirth'
                              label='Date of Birth'
                              type='text'
                              mask='9999/99/99'
                              maskChar={'_'}
                              error={!this.state.dateOfBirthValid && `Date of Birth is required.`}
                              defaultValue={this.state.dateOfBirth}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.emailCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('email'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='email'
                              label='E-Mail'
                              type='email'
                              error={!this.state.emailValid && `E-Mail is required.`}
                              defaultValue={this.state.email}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.cityCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('city'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='city'
                              label='City'
                              error={!this.state.cityValid && `City is required.`}
                              defaultValue={this.state.address.city}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {this.state.address &&
                        find(this.state.postalCodeCountries, {
                          country: this.state.currency,
                        }) &&
                        (this.setAvailableFields('postalCode'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='postalCode'
                              label='Postal Code'
                              type='number'
                              error={!this.state.postalCodeValid && `Postal code is required.`}
                              defaultValue={this.state.address.postalCode}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.regionCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('region'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='region'
                              label='Region'
                              error={!this.state.regionValid && `Region is required.`}
                              defaultValue={this.state.address.region}
                              disabled
                            />
                          </InputContainer>
                        ))}

                      {find(this.state.countryCountries, {
                        country: this.state.currency,
                      }) &&
                        (this.setAvailableFields('country'),
                        (
                          <InputContainer className={Styles.bankFields}>
                            <TextInput
                              name='country'
                              label='Country'
                              error={!this.state.countryValid && `Country is required.`}
                              defaultValue={this.state.address.country}
                              disabled
                            />
                          </InputContainer>
                        ))}
                    </InputGroup>
                    {find(this.state.addressCountries, {
                      country: this.state.currency,
                    }) &&
                      (this.setAvailableFields('address'),
                      (
                        <InputGroup>
                          <InputContainer style={{ flex: '100%' }}>
                            <Quill
                              name='address'
                              label='Account Holder Address'
                              placeholder='Add account holder address'
                              error={!this.state.addressValid && `Address is required.`}
                              value={this.state.address.address || ''}
                              disabled
                              legacyCompat
                            />
                          </InputContainer>
                        </InputGroup>
                      ))}
                  </>
                )}
                {this.state.type === PaymentType.PAYPAL.id &&
                  (this.setAvailableFields('paypalEmail'),
                  (
                    <InputGroup>
                      <InputContainer>
                        <TextInput
                          name='paypalEmail'
                          label='PayPal E-mail'
                          error={!this.state.paypalEmailValid && `Enter valid E-mail.`}
                          defaultValue={this.state.paypalEmail}
                          disabled
                        />
                      </InputContainer>
                    </InputGroup>
                  ))}
              </div>
            </Card.Item>
            <Card.Footer>
              {this.state.id && (
                <Button
                  name='delete'
                  type={Button.Type.WHITE}
                  onClick={(e) => this.props.deleteBankAccount(this.props.bankAccount)}>
                  {`Delete`}
                </Button>
              )}
              <div className={Styles.action}>
                <div
                  style={{
                    padding: '3px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: '15px',
                  }}>
                  <Toggle
                    id='is-default'
                    name='isDefault'
                    defaultChecked={this.state.isDefault}
                    disabled
                  />
                  <label
                    htmlFor='is-default'
                    className={Styles.toggleLabel}>{`Set as Default Payment`}</label>
                </div>

                <Button
                  name='cancel'
                  type={Button.Type.WHITE_BLUE_OUTLINE}
                  onClick={this.handleCancel}>
                  {`Cancel`}
                </Button>
              </div>
            </Card.Footer>
          </>
        )}

        {!this.state.isEdit && (
          <Card.Item>
            <div className={Styles.listPreview}>
              <div
                className={classNames(
                  Styles.row,
                  this.props.simpleRow ? Styles.simpleRow : Styles.fullDetailRow
                )}>
                {this.props.simpleRow ? (
                  <>
                    <div className={Styles.paymentType}>
                      {this.state.id && this.state.type === PaymentType.TRANSFERWISE.id && (
                        <div>
                          <p className={Styles.title}>
                            {`Bank Account${
                              this.state.lastFour ? ` ending in ${this.state.lastFour}` : ''
                            } `}
                            {this.state.currency && (
                              <span
                                title={BankAccountCurrency.getEnum(this.state.currency).name}
                                className={classNames(Styles.currencyBadge, FlagStyles.f16)}>
                                <span
                                  className={classNames(
                                    FlagStyles.flag,
                                    FlagStyles?.[
                                      BankAccountCurrency.getEnum(
                                        this.state.currency
                                      ).country?.toLowerCase()
                                    ]
                                  )}
                                />{' '}
                                {this.state.currency}
                              </span>
                            )}
                          </p>
                          <span className={Styles.default}>{this.state.simpleRowDetail}</span>
                        </div>
                      )}
                      {this.state.id &&
                        this.state.type === PaymentType.PAYPAL.id &&
                        this.state.paypalEmail && (
                          <div>
                            <p className={Styles.title}>{`PayPal Account`}</p>
                            <span className={Styles.default}>{this.state.paypalEmail}</span>
                          </div>
                        )}
                    </div>
                    {this.state.isDefault && (
                      <div className={Styles.details}>
                        <span className={Styles.default}>{`Default Account`}</span>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className={Styles.paymentType}>
                      {this.state.id && this.state.type === PaymentType.TRANSFERWISE.id && (
                        <div>
                          <p className={Styles.title}>{`Bank Account`}</p>
                          {this.state.isDefault && (
                            <span className={Styles.default}>{`Default Account`}</span>
                          )}
                        </div>
                      )}
                      {this.state.id &&
                        this.state.type === PaymentType.PAYPAL.id &&
                        this.state.paypalEmail && (
                          <div>
                            <p className={Styles.title}>{`PayPal Account`}</p>
                            {this.state.isDefault && (
                              <span className={Styles.default}>{`Default Account`}</span>
                            )}
                          </div>
                        )}
                    </div>
                    <div className={Styles.details}>
                      {dataItems.map((item, index) => {
                        const content = item.field(this.state);
                        const visible = item.visible(this.state);
                        return visible && content ? (
                          <div key={index} className={Styles.field}>
                            <div className={Styles.label}>{item.label}</div>
                            <div className={Styles.content}>{content}</div>
                          </div>
                        ) : null;
                      })}
                      {this.props.showWiseId && (
                        <div className={Styles.field}>
                          <div className={Styles.label}>{`Wise ID`}</div>
                          <div className={Styles.content}>
                            {this.state.transferwiseId || 'None '}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div className={Styles.action}>
                  <Button
                    data-test-id='edit-button-bank-item'
                    name='Edit'
                    type={Button.Type.BLUE_OUTLINE}
                    onClick={this.handleEdit}>
                    {`⚠️ Edit`}
                  </Button>
                </div>
              </div>
            </div>
          </Card.Item>
        )}
      </>
    );
  }
}

export default BankAccountItem;
