import { useMemo, Fragment, useState, useEffect, useRef } from 'react';

import { useParams, Link } from 'react-router-dom';
import { useUser } from '@context/user';
import { useTeam } from '@context/team';
import { useCommon } from '@context/common';

import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import { getUserEngagements } from '@apis/users';
import { getTeamEngagements } from '@apis/teams';
import { ApplicationStatus, UserEngagementFilter, UserStatus } from '@hiredigital/lib/helpers/enum';

import Layout from '@components/Layout/AppLayout';
import OnboardingWelcome from '@components/OnboardingWelcome/OnboardingWelcome';
import TitleBar from '@components/Layout/TitleBar';
import CreateNew from '@components/CreateNew/CreateNew';
import StatusFilter from '@components/ListFilters/StatusFilter/StatusFilter';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';

import Styles from './Styles.module.scss';
import DashboardStyles from '../../styles/Dashboard.module.scss';

const EngagementList = () => {
  const user = useUser();
  const team = useTeam();
  const params = useParams();
  const common = useCommon();

  const searchRef = useRef();

  const [search, setSearch] = useState(params?.search || undefined);
  // const [isDialogShown, setIsDialogShown] = useState(false);
  // const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [managingOrgs, setManagingOrgs] = useState([]);
  // const [selected, setSelected] = useState(null);
  const [filter, setFilter] = useState(null);

  let timeout = null;

  useEffect(() => {
    setPageTitle('Engagements - Hire Digital');
  }, []);

  useEffect(() => {
    if (params?.search) {
      searchRef.current.value = params?.search;
    }
  }, [searchRef, params]);

  useEffect(() => {
    const clients = team?.clients || user?.clients || [];
    setManagingOrgs(clients?.filter((v) => v.isProjectManager) || []);
  }, [user?.clients, team?.clients]);

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        id: 'title',
        canSort: true,
        minWidth: 300,
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original: e } }) => {
          const title = e?.title || e?.altTitle || 'New Engagement';
          return e?.assignments?.length > 0 ? (
            <Link className={Styles.title} to={`/assignments/${e?.assignments?.[0]?.uuid}`}>
              {title}
            </Link>
          ) : (
            <Link className={Styles.title} to={`/engagements/${e?.uuid}`}>
              {title}
            </Link>
          );
        },
      },
      {
        Header: 'Client',
        id: 'client_name',
        accessor: (e) => e?.clientName,
      },
      {
        Header: 'Role',
        id: 'talent_type',
        accessor: (e) => e?.talentType?.label,
      },
      {
        Header: 'Date',
        id: 'publish_date',
        accessor: (e) => (e?.publishDate ? toReadableDate(e?.publishDate) : null),
      },
      {
        Header: 'Status',
        id: 'status',
        canSort: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ row: { original: e } }) => {
          return (
            <Fragment>
              {!!e?.applications?.length && !e?.assignments?.length && (
                <Fragment>
                  <p className={DashboardStyles.label}>
                    {`Application - ` +
                      ApplicationStatus.getEnum(e?.applications?.[0]?.status)?.statusLabel}
                  </p>
                </Fragment>
              )}
              {!!e?.invitations?.length && !e?.applications?.length && !e?.assignments?.length && (
                <p className={DashboardStyles.label}>{`Invitation`}</p>
              )}
              {!!e?.assignments?.length && <p className={DashboardStyles.label}>{`Assignment`}</p>}
              {!e?.invitations?.length && !e?.applications?.length && !e?.assignments?.length && (
                <p className={DashboardStyles.label}>{`Open Engagement`}</p>
              )}
            </Fragment>
          );
        },
      },
    ],
    []
  );

  const handleSearchChange = (value) => {
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleFilterSelect = (item) => setFilter(item.id);

  const handleClearFilters = () => {
    setSearch('');
    setFilter(null);
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  const handleClearSearch = () => {
    setSearch('');
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  const getEmptySubtitle = () => {
    if (search) {
      // eslint-disable-next-line react/display-name
      return () => (
        <span>
          {`No engagements matching `}
          <strong>{`${search}`}</strong>
          {` could be found.`}
        </span>
      );
    }

    if (filter) {
      return `No engagements matched that status.`;
    }

    return `Your Hire Digital coordinator is looking for new engagements for you.`;
  };

  const commonProps = {
    columns,
    rowClass: Styles.row,
    cellClass: Styles.cell,
    tableClass: Styles.table,
    headerCellClass: Styles.headerCell,
    headerRowClass: Styles.headerRow,
    paginationClass: Styles.pagination,
    emptyContainerClass: Styles.emptyContainer,
    loadingContainerClass: Styles.loadingContainer,

    search,
    onClearSearch: handleClearFilters,
    emptyTitle: `No Engagements Found`,
    emptySubtitle: getEmptySubtitle(),
    defaultOrdering: params?.ordering,
  };

  return (
    <Layout
      bodyClass={Styles.layoutBody}
      header={
        <TitleBar
          inputRef={searchRef}
          title={`Engagements`}
          searchPlaceholder={`Search engagements`}
          onClearSearch={handleClearSearch}
          onSearchChange={handleSearchChange}
          showSearch={!!search}
          noWrap={false}
          filterComponent={() => (
            <StatusFilter
              initialStatus={filter}
              optionEnum={UserEngagementFilter}
              onSelect={handleFilterSelect}
            />
          )}>
          {user?.isTalent && user?.isProjectManager && managingOrgs?.length > 0 && (
            <CreateNew
              hideBrief={user?.isTalent}
              showOrgSelection={user?.isProjectManager}
              orgList={managingOrgs}
            />
          )}
        </TitleBar>
      }>
      <div className={Styles.tableContainer}>
        {user?.status === UserStatus.ONBOARDING.id && (
          <OnboardingWelcome
            message={`The Engagements page is where you will find new engagements with brands that match your expertise. Please complete your onboarding to get matched to new engagements.`}
          />
        )}

        {common?.isReady && (
          <PaginatedTable
            getListData={async (config) =>
              team?.uuid
                ? await getTeamEngagements(team?.uuid, config)
                : await getUserEngagements(user?.uuid, config)
            }
            filters={{ engagementType: filter }}
            {...commonProps}
          />
        )}
      </div>
    </Layout>
  );
};

export default EngagementList;
