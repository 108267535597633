import { Fragment, useState, useEffect, useRef } from 'react';
import Styles from './Chat.module.scss';
import classNames from 'classnames';
import ChatHeader from './components/ChatHeader';
import DroppableArea from './components/DroppableArea';
import MessageList from '../Discussions/MessageList';
import NewMessage from '../Discussions/NewMessage';
import { DiscussionProvider } from '@context/discussion';
import { getDiscussion } from '@apis/discussions';
import { useHistory } from 'react-router-dom';
import { useUser } from '@context/user';
import SearchBar from '../SearchBar/SearchBar';
import { useChatController, useChatControllerDispatch } from '@context/chatController';

let timeout = null;

const Chat = ({ discussionId, onClose, minimize = true, collapse = false }) => {
  const listRef = useRef();
  const history = useHistory();
  const user = useUser();

  const controller = useChatController();
  const controllerDispatch = useChatControllerDispatch();

  const [isCollapse, setIsCollapse] = useState(collapse);
  const [isMinimize, setIsMinimize] = useState(minimize);
  const [discussion, setDiscussion] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSearchShown, setIsSearchShown] = useState(false);
  const [search, setSearch] = useState('');
  const [isClearable, setIsClearable] = useState(false);

  useEffect(() => {
    getDiscussion(discussionId)
      .then(({ data }) => {
        if (!data?.related && (!data?.participants || data?.participants?.length === 0)) {
          forceClose();
          return;
        }
        setDiscussion(data);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        forceClose();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const chats = { ...controller.activeChats, [discussionId]: { isMinimize, isCollapse } };
    controllerDispatch({ type: 'UPDATE_ACTIVE_CHATS', payload: chats });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMinimize, isCollapse]);

  const forceClose = () => {
    const chats = { ...controller.activeChats };
    delete chats[discussionId];
    controllerDispatch({ type: 'UPDATE_ACTIVE_CHATS', payload: chats });
  };

  const handleSelectTitle = () => {
    if (discussion?.related?.model === 'Project') {
      history.push(`/projects/${discussion?.related?.id}`);
      return;
    }

    if (discussion?.related?.model === 'Application') {
      if (user?.isClient) {
        history.push(`/briefs/${discussion?.related?.brief}`);
      } else {
        history.push(`/engagements/${discussion?.related?.engagement}`);
      }
      return;
    }

    setIsCollapse(!isCollapse);
  };

  const handleOpenChatBox = () => setIsCollapse(false);

  const handleCollapseChatBox = () => setIsCollapse(true);

  const handleMinimizeChatBox = () => setIsMinimize(true);

  const handleMaximizeChatBox = () => setIsMinimize(false);

  const handleChatBoxClose = () => onClose?.(discussionId);

  const handlToggleSearch = () => setIsSearchShown(!isSearchShown);

  const handleSearchChange = ({ target: { value } }) => {
    setIsClearable(!!value);
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleClearSearch = () => {
    setIsClearable(false);
    setSearch('');
  };

  return (
    <DiscussionProvider>
      <div
        className={classNames(Styles.chatBox, {
          [Styles.isExpand]: !isMinimize && !isCollapse,
          [Styles.isOpen]: !isCollapse,
        })}>
        <ChatHeader
          title={discussion?.title || ''}
          isMinimize={isMinimize}
          isCollapse={isCollapse}
          onToggleMinimize={isCollapse ? handleOpenChatBox : handleCollapseChatBox}
          onToggleCollapse={isCollapse ? handleOpenChatBox : handleCollapseChatBox}
          onMaximize={handleMaximizeChatBox}
          onMinimize={handleMinimizeChatBox}
          onClose={handleChatBoxClose}
          onChatTitleClick={handleSelectTitle}
          onSearch={handlToggleSearch}
        />
        {!isCollapse && isSearchShown && (
          <SearchBar
            defaultValue={search}
            onSearch={handleSearchChange}
            onClear={handleClearSearch}
            showClear={isClearable}
          />
        )}
        <DroppableArea
          contentArea={() => (
            <Fragment>
              {isLoaded && <MessageList ref={listRef} discussion={discussion} search={search} />}
            </Fragment>
          )}
          actionArea={(open) => (
            <Fragment>
              {isLoaded && !isCollapse ? (
                <NewMessage discussion={discussion} onAddAttachment={open} listRef={listRef} />
              ) : (
                ''
              )}
            </Fragment>
          )}
          discussionId={discussionId}
          isCollapse={isCollapse}
        />
      </div>
    </DiscussionProvider>
  );
};

//ChatBox.Container = ChatBoxContainer;
export default Chat;
