// Use baseAuthRequest to avoid case conversion
import { baseAuthRequest } from './utils';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

// In case there's an Admin user, we should allow them to use the API with their own token.
// The frontend token will be missing if the Admin user didn't also login to the Frontend.
const adminToken = cookies.get('admin_token');

const attachAdminToken = (config) => {
  // skip if there's no admin token
  if (!adminToken) return config;

  // replace token with admin token
  return {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Token ${adminToken}`,
    },
  };
};

export const getCurrencies = async (config = undefined) => {
  const url = 'wise/currencies/';
  return await baseAuthRequest.get(url, attachAdminToken(config));
};

export const getRequirements = async (currency, config = undefined) => {
  const url = `wise/requirements/?target=${currency}`;
  return await baseAuthRequest.get(url, attachAdminToken(config));
};

export const postRequirements = async (currency, data, config = undefined) => {
  const url = `wise/requirements/?target=${currency}`;
  return await baseAuthRequest.post(url, data, attachAdminToken(config));
};
