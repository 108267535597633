import Loader from '@hiredigital/ui/Loader';
import classNames from 'classnames';
import Styles from './StatusText.module.scss';

const StatusText = ({ isSaving, savingText, savedText }) => {
  return (
    <>
      <span
        className={classNames(Styles.text, Styles.alignLeft, Styles.savedText, {
          [Styles.hide]: isSaving,
        })}>
        {savedText}
      </span>
      <span
        className={classNames(Styles.text, Styles.alignLeft, Styles.savingText, {
          [Styles.hide]: !isSaving,
        })}>
        <Loader size={Loader.Size.SMALL} className={Styles.loader} />
        {savingText}
      </span>
    </>
  );
};

export default StatusText;
