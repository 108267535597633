import { useImperativeHandle, useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
// import Icon from '../Icon/Icon';
import Portal from '../Portal/Portal';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import classNames from 'classnames';
import Styles from './ImageViewer.module.scss';

// This should be replaced with FilePreview

const ImageViewer = forwardRef(({ title: initalTitle, url: initialUrl }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(initalTitle);
  const [url, setUrl] = useState(initialUrl);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [dimensionClass, setDimensionClass] = useState(Styles.normal);
  const [loaded, setLoaded] = useState(false);
  useImperativeHandle(ref, () => ({
    show: (u, t) => {
      setIsOpen(true);
      setUrl(u);
      setTitle(t);
    },
  }));

  useEffect(() => {
    setUrl(url);
    setTitle(title);
  }, []);

  const close = () => {
    setIsOpen(false);
  };

  const toggleZoom = () => {
    if (zoomLevel == 1) {
      setZoomLevel(0);
    } else {
      setZoomLevel(1);
    }
  };

  const getDimensions = ({ target }) => {
    setLoaded(true);
    setHeight(target.offsetHeight);
    setWidth(target.offsetWidth);
    if (target.offsetHeight > target.offsetWidth * 2) {
      setDimensionClass(Styles.tall);
    } else if (target.offsetWidth > target.offsetHeight * 2) {
      setDimensionClass(Styles.wide);
    } else if (target.offsetWidth < 900) {
      setDimensionClass(Styles.small);
    } else {
      setDimensionClass(Styles.large);
    }
  };

  return (
    <div>
      {isOpen ? (
        <Portal>
          <div className={Styles.modalContainer}>
            <div className={Styles.modalPanel}>
              <div className={Styles.header}>
                <div className={Styles.title}>{title}</div>
                <div className={Styles.close} onClick={close}>
                  <IconClose className={Styles.closeIcon} />
                </div>
              </div>
              <div
                className={classNames(
                  Styles.content,
                  zoomLevel ? Styles.zoom1 : Styles.zoom0,
                  dimensionClass,
                  loaded && Styles.loaded
                )}>
                <img
                  src={url}
                  onClick={toggleZoom}
                  className={Styles.image}
                  alt={url}
                  onLoad={getDimensions}
                  width={width}
                  height={height}
                />
              </div>
            </div>
          </div>
        </Portal>
      ) : (
        ''
      )}
    </div>
  );
});

ImageViewer.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
  force: PropTypes.bool,
};

export default ImageViewer;
