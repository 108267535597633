import { Fragment, useState, useCallback } from 'react';
import Styles from './DroppableArea.module.scss';
import classNames from 'classnames';
import ProgressBar from './ProgressBar';
import { useDropzone } from 'react-dropzone';
import { postDiscussionAttachment } from '@apis/discussions';
import { useDiscussion } from '@context/discussion';

const DroppableArea = ({
  children,
  contentArea,
  actionArea,
  discussionId,
  isCollapse,
  // isMinimize,
}) => {
  const { loadMessages } = useDiscussion();
  const [uploadProgress, setUploadProgress] = useState(undefined);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const formData = new FormData();
        formData.append('attachment', file, file.fileName || file.name);

        const config = {
          onUploadProgress: (progressEvent) => {
            const prog = parseInt((100.0 * progressEvent.loaded) / progressEvent.total, 10);
            setUploadProgress(prog);
          },
        };

        postDiscussionAttachment(discussionId, formData, config).then(
          (response) => {
            setUploadProgress(undefined);
            loadMessages(discussionId, { params: { limit: 75 } }, true);
          },
          (error) => {}
        );
      });

      // Do something with the files
    },
    [uploadProgress]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
  });

  return (
    <Fragment>
      <div
        className={classNames(
          Styles.messageArea,
          // isMinimize && Styles.minimize,
          isCollapse && Styles.collapse
        )}>
        <div className={Styles.dropContainer} {...getRootProps()}>
          <div className={classNames(Styles.dragContainer, isDragActive && Styles.active)}>
            {`Drop files here to add them to the discussion.`}
          </div>
          {contentArea?.()}
          <input {...getInputProps()} />
        </div>
        {uploadProgress && <ProgressBar value={uploadProgress} />}
      </div>
      {actionArea?.(open)}
    </Fragment>
  );
};

export default DroppableArea;
