import Styles from './EducationForm.module.scss';
import Education from '@components/Resume/Education/Education';

const EducationForm = ({ style, value, ...props }) => {
  return (
    <div className={Styles.container}>
      <Education user={{}} title={`Add Education`} addText={`Add Education`} noBorder />
    </div>
  );
};

export default EducationForm;
