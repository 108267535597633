import { useMemo, Fragment, useEffect, useState, useRef } from 'react';
import convert from 'htmr';
import { useParams } from 'react-router-dom';

import { setPageTitle } from '@hiredigital/lib/helpers/utils';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import InlineAvatars from '@hiredigital/ui/InlineAvatars';
import PaginatedTable from '@hiredigital/ui/PaginatedTable/Container';
import {
  ProjectStatus,
  OrganizationStatus,
  ProjectFilter,
  UserStatus,
} from '@hiredigital/lib/helpers/enum';

import { getTeamProjects } from '@apis/teams';
import { getProjects, getOrgProjects } from '@apis/projects';
import { useUser } from '@context/user';
import { useOrg } from '@context/org';
import { useTeam } from '@context/team';
import { useCommon } from '@context/common';

import Layout from '@components/Layout/AppLayout';
import OnboardingWelcome from '@components/OnboardingWelcome/OnboardingWelcome';
import Link from '@components/Link';
import TitleBar from '@components/Layout/TitleBar';
import CreateNew from '@components/CreateNew/CreateNew';
import ClientsDropdown from '@components/ListFilters/ClientsDropdown';
import TalentsDropdown from '@components/ListFilters/TalentsDropdown';
import StatusFilter from '@components/ListFilters/StatusFilter/StatusFilter';

import Styles from './Styles.module.scss';

let timeout = null;

const ProjectList = () => {
  const user = useUser();
  const org = useOrg();
  const team = useTeam();
  const common = useCommon();
  const params = useParams();
  const searchRef = useRef();

  const [talent, setTalent] = useState(params?.talent || undefined);
  const [status, setStatus] = useState(params?.status || undefined);
  const [search, setSearch] = useState(params?.search || undefined);
  // const [isSaving, setIsSaving] = useState(false);
  const [managingOrgs, setManagingOrgs] = useState([]);
  // const [isDialogShown, setIsDialogShown] = useState(false);
  // const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  // const [selected, setSelected] = useState(null);

  useEffect(() => {
    setPageTitle('Projects - Hire Digital');
  }, []);

  useEffect(() => {
    if (params?.search) {
      searchRef.current.value = params?.search;
    }
  }, [searchRef, params]);

  useEffect(() => {
    const clients = team?.clients || user?.clients || [];
    setManagingOrgs(clients?.filter((v) => v.isProjectManager) || []);
  }, [user?.clients, team?.clients]);

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        id: 'title',
        canSort: true,
        minWidth: 300,
        truncate: true,
        // eslint-disable-next-line react/display-name
        accessor: (p) => {
          return (
            <Fragment>
              <Link
                className={Styles.title}
                href={
                  p.status === ProjectStatus.DRAFT.id
                    ? `projects/new/${p.uuid}`
                    : `projects/${p.uuid}`
                }>
                {p.title || 'Untitled Project'}
              </Link>
              <div className={Styles.notification}>
                {convert(p?.latestNotification?.summary || '')}
              </div>
            </Fragment>
          );
        },
      },
      {
        Header: 'Team',
        id: 'team',
        truncate: false,
        // eslint-disable-next-line react/display-name
        accessor: (p) => {
          return <InlineAvatars members={p?.members || []} uuid={p?.uuid} />;
        },
      },
      {
        Header: 'Client',
        id: 'client',
        canSort: false,
        accessor: (p) => p.organization?.name,
      },

      {
        Header: 'Last Updated',
        id: 'modified',
        minWidth: 100,
        accessor: (p) => toReadableDate(p.modified || p.created),
      },
      {
        Header: 'Status',
        id: 'status',
        width: 100,
        accessor: (p) => ProjectStatus.getLabel(p.status),
      },
    ],
    []
  );

  const handleSearchChange = (value) => {
    debounceSearch(() => setSearch(value || undefined));
  };

  const debounceSearch = (callback, wait = 500) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  };

  const handleFilterStatusSelect = (item) => setStatus(item?.id);

  const statusFilter = { status: ProjectFilter.getEnum(status)?.status || undefined };

  const handleClearFilters = () => {
    setTalent(null);
    setStatus(null);
    setSearch(null);
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  const handleClearSearch = () => {
    setSearch(null);
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  const getEmptySubtitle = () => {
    if (search) {
      // eslint-disable-next-line react/display-name
      return () => (
        <span>
          {`No projects matching `}
          <strong>{`${search}`}</strong>
          {` could be found. Try another search term.`}
        </span>
      );
    }
    return `No projects matched that filter. Try another filter.`;
  };

  const commonProps = {
    columns,
    rowClass: Styles.row,
    cellClass: Styles.cell,
    tableClass: Styles.table,
    headerCellClass: Styles.headerCell,
    headerRowClass: Styles.headerRow,
    paginationClass: Styles.pagination,
    emptyContainerClass: Styles.emptyContainer,
    loadingContainerClass: Styles.loadingContainer,

    search,
    onClearSearch: handleClearFilters,
    emptyTitle: `No Projects Found`,
    emptySubtitle: getEmptySubtitle(),
    defaultOrdering: params?.ordering || 'status',
  };

  return (
    <Layout
      bodyClass={Styles.layoutBody}
      header={
        <TitleBar
          title={`Projects`}
          onClearSearch={handleClearSearch}
          onSearchChange={handleSearchChange}
          showSearch={!!search}
          inputRef={searchRef}
          noWrap={false}
          filterComponent={() => (
            <Fragment>
              {user?.isTalent && (
                <ClientsDropdown talentUuid={talent} onFilter={({ uuid }) => setTalent(uuid)} />
              )}
              {user?.isClient && (
                <TalentsDropdown talentUuid={talent} onFilter={(t) => setTalent(t)} />
              )}
              <StatusFilter
                initialStatus={status}
                optionEnum={ProjectFilter}
                onSelect={handleFilterStatusSelect}
              />
            </Fragment>
          )}>
          {((user?.isClient && org?.status === OrganizationStatus.ACTIVE.id) ||
            (user?.isTalent && user?.isProjectManager && managingOrgs?.length > 0)) && (
            <CreateNew
              orgUuid={org?.uuid}
              hideBrief={user?.isTalent}
              showOrgSelection={user?.isProjectManager}
              orgList={managingOrgs}
            />
          )}
        </TitleBar>
      }>
      <div className={Styles.tableContainer}>
        {/*TODO check if this is correct*/}

        {user?.status === UserStatus.ONBOARDING.id && (
          <OnboardingWelcome
            message={`Projects are where you can collaborate with your clients. Here you will be able to submit work and communicate with clients. Please complete your onboarding to get matched to new engagements.`}
          />
        )}

        {common?.isReady && (
          <Fragment>
            {user?.isClient ? (
              <PaginatedTable
                hiddenColumns={org ? ['client'] : []}
                getListData={async (config) =>
                  org?.uuid ? await getOrgProjects(org?.uuid, config) : await getProjects(config)
                }
                filters={{
                  talent: talent,
                  ...statusFilter,
                }}
                {...commonProps}
              />
            ) : (
              <PaginatedTable
                getListData={async (config) =>
                  team?.uuid ? await getTeamProjects(team?.uuid, config) : await getProjects(config)
                }
                filters={{
                  talent: talent,
                  ...statusFilter,
                }}
                {...commonProps}
              />
            )}
          </Fragment>
        )}
      </div>
    </Layout>
  );
};

export default ProjectList;
