import { memo } from 'react';
import PropTypes from 'prop-types';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconMoreVertical from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';
import { Popover } from 'evergreen-ui/commonjs/popover';
import { Menu } from 'evergreen-ui/commonjs/menu';
import Position from 'evergreen-ui/commonjs/constants/src/Position';

import classNames from 'classnames';
import Styles from './MoreDropdown.module.scss';

const MemoButton = memo(Button);

const MemoIconMoreVertical = memo(IconMoreVertical);

const MoreDropdown = ({
  children,
  containerProps,
  moreButtonClass,
  containerClass,
  type = Button.Type.BLUE,
  options = [],
  isLoading = false,
  ...props
}) => {
  return (
    <div className={classNames(Styles.container, containerClass)} {...containerProps}>
      <Popover
        minWidth={0}
        position={Position.BOTTOM_RIGHT}
        content={({ close }) =>
          options?.length > 0 && (
            <Menu>
              <Menu.Group>
                {options
                  ?.filter((v) => !!v?.label)
                  ?.map((v, idx) => {
                    return (
                      <Menu.Item
                        key={idx}
                        onSelect={() => {
                          v?.onSelect?.();
                          close();
                        }}>
                        {v?.label}
                      </Menu.Item>
                    );
                  })}
              </Menu.Group>
            </Menu>
          )
        }>
        <div className={Styles.btnMore}>
          {isLoading && <Loader size={Loader.Size.SMALL} />}
          <MemoButton type={type} className={classNames(moreButtonClass)}>
            <MemoIconMoreVertical className={Styles.icon} />
          </MemoButton>
        </div>
      </Popover>
    </div>
  );
};

MoreDropdown.propTypes = {
  containerProps: PropTypes.object,
  moreButtonClass: PropTypes.string,
  containerClass: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};
const MemoMoreDropdown = memo(MoreDropdown);

MemoMoreDropdown.Type = Button.Type;

export default MemoMoreDropdown;
