import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Loader from '@hiredigital/ui/Loader';

import withOrgGuard from '../../shared/hoc/withOrgGuard';
import { BriefStatus } from '@hiredigital/lib/helpers/enum';
import { useOrg } from '@context/org';
import { postBrief } from '@apis/briefs';

import Layout from '@components/Layout/AppLayout';
import TitleBar from '@components/Layout/DetailTitleBar';
import Styles from './Styles.module.scss';

const NewBrief = ({ ...props }) => {
  const org = useOrg();
  const history = useHistory();

  useEffect(() => {
    const data = {
      organization: org?.uuid,
      status: BriefStatus.DRAFT.id,
      currencyCode: org?.payment?.currency || 'SGD',
      languages: [{ id: 19, name: 'English (UK)' }],
    };
    postBrief(data)
      .then(({ data }) => {
        history.replace(`/briefs/${data?.uuid}`);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [org, history]);

  return (
    <Layout
      style={{ backgroundColor: 'white' }}
      bodyClass={Styles.briefForm}
      header={
        <TitleBar title={`Talent Briefs`} titleLink={`/briefs`} subtitle={'New Brief'}></TitleBar>
      }>
      <Loader type={Loader.Type.FULL} />
    </Layout>
  );
};

export default withOrgGuard(NewBrief);
