import { Role } from '@hiredigital/lib/helpers/enum';

export const isTalent = (user) => {
  const roles = [Role.TALENT.id];
  return Boolean(user?.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isClient = (user) => {
  const roles = [Role.CLIENT.id];
  return Boolean(user?.roles?.filter((value) => roles.includes(value)).length > 0);
};

export const isAdmin = (user) => {
  // Admin Roles
  // Super User, Staff User Settings
  if (user.isSuperuser) return true;
  const roles = [Role.ADMIN.id];
  return Boolean(user?.roles?.filter((value) => roles.includes(value)).length > 0);
};
