/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getMessageRedirectData } from '@apis/messages';

import Loader from '@hiredigital/ui/Loader';
import Layout from '@components/Layout/AppLayout';

import { useUser } from '@context/user';
import { useCallback } from 'react';

const Index = () => {
  const params = useParams();
  const history = useHistory();
  const user = useUser();

  useEffect(() => {
    const { messageId } = params;
    getMessageRedirectData(messageId).then(({ data }) => {
      history.push(getCorrectPath(data?.discussion || {}));
    });
  }, [params]);

  const getCorrectPath = useCallback(
    (discussion) => {
      const discussionId = discussion?.id;
      switch (discussion?.related?.model) {
        case 'Project':
          const uuid = discussion.related.id;
          return `/projects/${uuid}`;
        case 'Application':
          return user?.isClient
            ? `/briefs/${discussion.related?.brief}/engagements?discussion=${discussionId}`
            : `/engagements/${discussion.related?.engagement}?discussion=${discussionId}`;
        case 'OrganizationTalent':
          return `/projects?discussion=${discussionId}`;
        default:
          return '/404';
      }
    },
    [user]
  );

  return (
    <Layout>
      <Loader style={{ margin: 'auto' }} />
    </Layout>
  );
};

export default Index;
