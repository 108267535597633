import { useEffect, useState, useRef } from 'react';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import throttle from 'lodash/throttle';
import Styles from './Toolbar.module.scss';
import classNames from 'classnames';

const tools = [
  { type: 'ql-bold' },
  { type: 'ql-italic' },
  { type: 'ql-underline' },
  { type: 'ql-strike' },
  { type: 'ql-blockquote' },
  { type: 'ql-list', value: 'bullet' },
  { type: 'ql-list', value: 'ordered' },
  { type: 'ql-indent', value: '-1' },
  { type: 'ql-indent', value: '+1' },
  { type: 'ql-header', elem: 'select', defaultValue: 'normal', options: [1, 2, 3, 'normal'] },
  { type: 'ql-align' },
  { type: 'ql-link' },
  { type: 'ql-image' },
  { type: 'ql-video' },
];

const Toolbar = () => {
  const refTools = useRef();

  const [isMounted, setIsMounted] = useState(false);
  const [hasExpandOption, setHasExpandOption] = useState(false);
  const [toolbarWidth, setToolbarWidth] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleResize = throttle(
    ([
      {
        contentRect: { width },
      },
    ]) => {
      setToolbarWidth(width);
    },
    100
  );

  const hideWrappedElements = () => {
    const tools = refTools.current.children;

    Array.from(tools).forEach((item) => {
      const isWrapped = tools[0].offsetTop < item.offsetTop;

      if (isWrapped && !isExpanded) {
        item.classList.add(Styles.hidden);
        return;
      }

      item.classList.remove(Styles.hidden);
    });

    setHasExpandOption(hasWrappedElement());
  };

  const hasWrappedElement = () => {
    const { firstChild, lastChild } = refTools.current;
    return firstChild?.offsetTop < lastChild?.offsetTop;
  };

  useEffect(() => {
    setIsMounted(true);

    if (!refTools.current) return;

    const elem = refTools.current;

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(elem);

    return () => resizeObserver.unobserve(elem);
  }, []);

  useEffect(() => {
    hideWrappedElements();
  }, [toolbarWidth]);

  useEffect(() => {
    setIsExpanded(false);
  }, [hasExpandOption]);

  useEffect(() => {
    if (!hasExpandOption) return;
    if (isExpanded) {
      // show all the tools if toolbar is on expanded state
      const tools = refTools.current.children;
      Array.from(tools).forEach((item) => item.classList.remove(Styles.hidden));
    }
  }, [isExpanded, hasExpandOption]);

  return (
    <div id='toolbar' className={Styles.toolbar}>
      <div className={classNames(Styles.toolsArea, { [Styles.expanded]: isExpanded })}>
        {/* classes with prefix 'ql' are intentionally set as global to let react-quill attach styling and handlers to them*/}
        <div ref={refTools} className={classNames('ql-formats', Styles.toolButtons)}>
          {isMounted &&
            tools.map(({ elem: Elem = 'button', type, options, ...rest }, idx) => (
              <Elem className={type} {...rest}>
                {options?.map((v) => (
                  <option value={v} />
                ))}
              </Elem>
            ))}
        </div>
        {hasExpandOption && (
          <button onClick={() => setIsExpanded((v) => !v)} className={Styles.btnExpand}>
            <IconDown className={Styles.iconArrow} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Toolbar;
