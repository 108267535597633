import { Fragment, useEffect, useState, memo } from 'react';
// import { formatDistanceToNow, format } from 'date-fns';
import classNames from 'classnames';

import Position from 'evergreen-ui/esm/constants/src/Position';
import { Tooltip } from 'evergreen-ui/esm/tooltip';
import Avatar from '@hiredigital/ui/Avatar';
import ConfirmAvailability from '../../../ConfirmAvailability/ConfirmAvailability';

import { ConferenceStatus, ConferenceUserStatus } from '@hiredigital/lib/helpers/enum';
import { callTimeFormatter, callDayFormatter } from '@hiredigital/lib/helpers/date';

import { useUser } from '@context/user';
import { useCommon } from '@context/common';

import Styles from './Styles.module.scss';
import DiscussionStyles from '../../ProjectDiscussion.module.scss';

const formatArray = (arr) => {
  let outStr = '';
  if (arr.length === 1) {
    outStr = arr[0];
  } else if (arr.length === 2) {
    //joins all with "and" but no commas
    //example: "bob and sam"
    outStr = arr.join(' and ');
  } else if (arr.length > 2) {
    //joins all with commas, but last one gets ", and" (oxford comma!)
    //example: "bob, joe, and sam"
    outStr = arr.slice(0, -1).join(', ') + ', and ' + arr.slice(-1);
  }
  return outStr;
};

const ScheduleTable = ({
  formattedDate,
  formattedTime,
  isLocalTime,
  setIsLocalTime,
  common,
  conference,
  formattedConfirmedParticipants,
  children,
}) => (
  <div className={Styles.conferenceTable}>
    <div className={Styles.tableBody}>
      <div className={Styles.tableRow}>
        <div className={classNames(Styles.tableCell, Styles.label)}>Day</div>
        <div className={Styles.tableCell}>{formattedDate(conference.call)}</div>
      </div>
      <div className={Styles.tableRow}>
        <div className={classNames(Styles.tableCell, Styles.label)}>Time</div>
        <div className={Styles.tableCell}>{formattedTime(conference.call)}</div>
      </div>
      <div className={Styles.tableRow}>
        <div className={classNames(Styles.tableCell, Styles.label)}>Timezone</div>
        <div className={Styles.tableCell} style={{ display: 'flex' }}>
          {`GMT`}
          {isLocalTime ? common.timezone.timezoneOffset : conference.currentTimezone.timezoneOffset}
          <span
            className={Styles.link}
            onClick={() => setIsLocalTime(!isLocalTime)}
            style={{ marginLeft: '10px' }}>
            Switch {isLocalTime ? 'to Conference Time' : 'to Local Time'}
          </span>
        </div>
      </div>
      <div className={Styles.tableRow}>
        <div className={classNames(Styles.tableCell, Styles.label)}>Link</div>
        <div className={Styles.tableCell}>
          <a
            className={Styles.link}
            href={conference.call.conferenceUrl}
            target='_blank'
            rel='noopener noreferrer'>
            {conference.call.conferenceUrl}
          </a>
        </div>
      </div>
      <div className={Styles.tableRow}>
        <div className={classNames(Styles.tableCell, Styles.label)}>Participants</div>
        <div className={Styles.tableCell} style={{ textTransform: 'capitalize' }}>
          {formattedConfirmedParticipants}
        </div>
      </div>
      {children}
    </div>
  </div>
);

const OtherParticipantsStatus = ({ conference, user, setIsConfirmShown }) => {
  const isCreator = conference.creator?.uuid === user?.uuid;
  const confirmedParticipants = conference.users?.filter(
    (v) => v.user?.uuid !== conference.creator?.uuid && v.status === ConferenceStatus.CONFIRMED.id
  );

  return (
    <Fragment>
      {isCreator ? (
        <Fragment>
          {confirmedParticipants?.map((v) => (
            <Fragment key={v?.uuid}>
              <div className={Styles.tableRow}>
                <p>
                  <strong>{`${v?.user?.name} `}</strong>
                  {` has confirmed availability.`}
                </p>
              </div>
              <NotifyMessage />
            </Fragment>
          ))}
        </Fragment>
      ) : (
        <Fragment>
          {conference.users
            ?.filter(
              (v) => v.status === ConferenceUserStatus.PENDING.id && v.user?.uuid !== user?.uuid
            )
            ?.map((v) => (
              <div key={v?.uuid} className={Styles.tableRow}>
                <p>
                  <strong>{`${v?.user?.name} `}</strong>
                  {` still need to respond in order to schedule the call.`}
                </p>
              </div>
            ))}
        </Fragment>
      )}
    </Fragment>
  );
};

const ConfirmedMessage = ({
  conference,
  creator,
  formattedParticipants,
  formattedConfirmedParticipants,
  formattedDate,
  isLocalTime,
  setIsLocalTime,
  formattedTime,
  common,
  user,
  setIsConfirmShown,
  showOtherParticipantsStatus = false,
}) => {
  return (
    <div className={Styles.content}>
      <p>
        <strong>{creator?.name}</strong>
        {`${creator.scheduledAction}`}
        {formattedParticipants && (
          <Fragment>
            {` with `}
            <strong>{formattedParticipants}</strong>
          </Fragment>
        )}
        {`.`}
      </p>
      <ScheduleTable
        {...{
          formattedConfirmedParticipants,
          formattedDate,
          isLocalTime,
          setIsLocalTime,
          formattedTime,
          common,
          conference,
        }}>
        {conference.users?.some(
          (v) => v.status === ConferenceUserStatus.PENDING.id && v.user?.uuid === user?.uuid
        ) && <SelectAvailability {...{ setIsConfirmShown }} />}
      </ScheduleTable>
      {showOtherParticipantsStatus && (
        <OtherParticipantsStatus {...{ conference, user, setIsConfirmShown }} />
      )}
    </div>
  );
};

const NotifyMessage = (props) => (
  <p style={{ margin: '10px 0' }} {...props}>
    {`We will notify you with the call link via e-mail when everyone has confirmed their availability.`}
  </p>
);

const SelectAvailability = ({ setIsConfirmShown }) => (
  <div className={Styles.tableRow}>
    <button onClick={() => setIsConfirmShown(true)} className={Styles.actionSelectAvailability}>
      {`Select Availability`}
    </button>
  </div>
);

const PendingMessage = ({
  conference,
  creator,
  formattedParticipants,
  formattedDate,
  isLocalTime,
  setIsLocalTime,
  formattedTime,
  formattedTimezone,
  formattedOffset,
  user,
  setIsConfirmShown,
}) => (
  <div className={Styles.content}>
    <p>
      <strong>{creator?.name}</strong>
      {`${creator.pendingAction}`}
      {formattedParticipants && (
        <Fragment>
          {` with `}
          <strong>{formattedParticipants}</strong>
        </Fragment>
      )}
      {` for one of the following times `}
      <span className={Styles.link} onClick={() => setIsLocalTime(!isLocalTime)}>
        {`${formattedTimezone(conference.currentTimezone)} (GMT${formattedOffset(
          conference.currentTimezone
        )}):`}
      </span>
    </p>

    <div className={Styles.conferenceTable}>
      <div className={Styles.tableBody}>
        {conference.times
          .sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
          .map((time) => (
            <div className={Styles.tableRow} key={time.id}>
              <div className={Styles.tableCell}>{`${formattedDate(time)} ${formattedTime(
                time
              )}`}</div>
              <div
                className={classNames(Styles.tableCell, Styles.end)}
                style={{ marginLeft: 'auto' }}>
                {time.availableUsers.map((user) => (
                  <Tooltip
                    key={user?.uuid}
                    content={`Selected by ${user?.name}`}
                    position={Position.TOP}>
                    <Avatar
                      style={{ marginLeft: '10px', height: '20px', width: '20px' }}
                      className={Styles.image}
                      src={user.picture}
                      name={user?.name}
                      showTooltip
                    />
                  </Tooltip>
                ))}
              </div>
            </div>
          ))}
        {conference.creator?.uuid !== user?.uuid &&
          conference.users?.some(
            (v) => v.user?.uuid === user?.uuid && v.status === ConferenceStatus.PENDING.id
          ) && <SelectAvailability {...{ setIsConfirmShown }} />}
      </div>
    </div>

    <NotifyMessage />
  </div>
);

const CancelledMessage = ({ creator, formattedParticipants }) => (
  <div className={Styles.content}>
    <p>
      <strong>{creator?.name}</strong>
      {`${creator.cancelAction}${formattedParticipants ? ' with ' + formattedParticipants : ''}.`}
    </p>
  </div>
);

const Conference = memo(({ message, discussion, ...props }) => {
  const [isConfirmShown, setIsConfirmShown] = useState(false);
  const [isLocalTime, setIsLocalTime] = useState(false);
  const [conference, setConference] = useState(message.metaObject);
  const user = useUser();
  const common = useCommon();

  useEffect(() => {
    setConference(message.metaObject);
  }, [message]);

  const creator =
    conference.creator.uuid === user?.uuid
      ? {
          name: 'You',
          pendingAction: ' have requested for a call',
          scheduledAction: ' have scheduled a call',
          cancelAction: ' have cancelled the call',
        }
      : {
          name: conference.creator?.name,
          pendingAction: ' would like to schedule a call',
          scheduledAction: ' has scheduled a call',
          cancelAction: ' has cancelled the call',
        };

  const moveUserToFirst = (items, userUuid) => {
    const users = [...items];

    if (!userUuid || users?.length <= 1) {
      return users;
    }
    const idx = users.map((v) => v.uuid).indexOf(userUuid);
    if (idx < 0) {
      return users;
    }

    users.unshift(users.splice(idx, 1)[0]);
    return users;
  };

  const participants = moveUserToFirst(conference.users, user?.uuid)
    .filter((p) => {
      return (
        [ConferenceUserStatus.PENDING.id, ConferenceUserStatus.CONFIRMED.id].indexOf(p.status) !==
          -1 && p.user?.uuid !== conference.creator.uuid
      );
    })
    .map((p) => {
      if (p?.email === null) {
        return p?.user?.uuid === user?.uuid ? 'you' : p?.user?.name;
      } else {
        return p.email;
      }
    });

  const confirmedParticipants = moveUserToFirst(conference.users, user?.uuid)
    .filter(
      (p) =>
        [ConferenceUserStatus.CONFIRMED.id, ConferenceUserStatus.NOT_REQUIRED.id].indexOf(
          p.status
        ) !== -1
    )
    .map((p) => {
      if (p?.email === null) {
        return p?.user?.uuid === user?.uuid ? 'you' : p?.user?.name;
      } else {
        return p.email;
      }
    });

  const formattedTime = (time) => {
    return `${callTimeFormatter(
      time.startTime,
      isLocalTime ? common.timezone.timezoneOffset : conference.currentTimezone.timezoneOffset
    )}`;
  };

  const formattedDate = (time) => {
    return `${callDayFormatter(
      time.startTime,
      isLocalTime ? common.timezone.timezoneOffset : conference.currentTimezone.timezoneOffset
    )}`;
  };

  const formattedTimezone = (timezone) => {
    return `${isLocalTime ? common.timezone.label : timezone.label}`;
  };

  const formattedOffset = (timezone) => {
    return `${isLocalTime ? common.timezone.timezoneOffset : timezone.timezoneOffset}`;
  };

  const formattedParticipants = formatArray(participants);
  const formattedConfirmedParticipants = formatArray(confirmedParticipants);

  const commonProps = {
    isLocalTime,
    setIsLocalTime,
    conference,
    creator,
    formattedParticipants,
    formattedDate,
    formattedTime,
    formattedConfirmedParticipants,
    common,
    discussion,
    message,
    isConfirmShown,
    setIsConfirmShown,
    formattedTimezone,
    formattedOffset,
    user,
  };

  // other participants had already confirmed
  const isOthersConfirmed = conference.users?.some(
    (v) => v.status === ConferenceStatus.CONFIRMED.id && v?.user?.uuid !== conference.creator?.uuid
  );

  const Row = ({ conference }) => {
    switch (conference.status) {
      case ConferenceStatus.DRAFT.id:
        return <div />;
      case ConferenceStatus.PENDING.id:
        return isOthersConfirmed ? (
          <ConfirmedMessage {...commonProps} showOtherParticipantsStatus />
        ) : (
          <PendingMessage {...commonProps} />
        );
      case ConferenceStatus.CONFIRMED.id:
        return <ConfirmedMessage {...commonProps} />;
      case ConferenceStatus.COMPLETED.id:
        return <div />;
      case ConferenceStatus.CANCELLED.id:
        return <CancelledMessage {...commonProps} />;
      case ConferenceStatus.PENDING_RESCHEDULE.id:
        return <div />;
      default:
        return null;
    }
  };

  return (
    <>
      {/*<div className={DiscussionStyles.imageContainer}>*/}
      {/*<Icon.Phone className={DiscussionStyles.icon} />*/}
      {/*</div>*/}
      <div className={DiscussionStyles.content}>
        <Row conference={conference} />
      </div>
      <div className={DiscussionStyles.action}></div>
      <ConfirmAvailability
        conferenceUuid={conference?.uuid}
        isShown={isConfirmShown}
        onClose={() => setIsConfirmShown(false)}
        onCancel={() => setIsConfirmShown(false)}
        onSubmit={() => setIsConfirmShown(false)}
        discussion={discussion}
        message={message}
      />
    </>
  );
});

export default Conference;
