import { forwardRef, memo } from 'react';
import Styles from './FormArea.module.scss';

const FormArea = ({ title, description, children, forwardedRef, ...props }) => {
  return (
    <div className={Styles.linkForm} {...props} ref={forwardedRef}>
      <p className={Styles.title}>{title}</p>
      <p className={Styles.description}>
        {typeof description === 'function' ? description() : description}
      </p>
      {children}
      <img src={`/img/attachments.png`} className={Styles.emptyImage} alt={'desk'} />
    </div>
  );
};

const MemoFormArea = memo(FormArea);

// eslint-disable-next-line react/display-name
export default forwardRef((props, ref) => <MemoFormArea {...props} forwardedRef={ref} />);
