import { useRef, memo } from 'react';
import classNames from 'classnames';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';

import Styles from './SearchBar.module.scss';

const SearchBar = ({
  defaultValue,
  onSearch,
  onClear,
  showClear,
  placeholder = 'Search Discussion',
  className,
}) => {
  const refInput = useRef();

  const handleClear = (e) => {
    if (refInput?.current) {
      refInput.current.value = '';
    }
    onClear?.();
  };

  return (
    <div className={classNames(Styles.searchBar, className)}>
      <button className={Styles.actionSearch}>
        <svg className={Styles.iconSearch} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'>
          <path
            id='search'
            fill='currentColor'
            fillRule='nonzero'
            d='M23.1 20.69a1.19 1.19 0 0 1-1.68 1.68l-4.1-4.1A6 6 0 1 1 19 16.6l4.1 4.1zM18 13.28a4 4 0 1 0-8 0 4 4 0 0 0 8 0z'></path>
        </svg>
      </button>
      <input
        ref={refInput}
        className={Styles.searchField}
        type={`text`}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onSearch}
        data-test-id='search-id'
      />
      {(refInput?.current?.value || showClear) && (
        <div onClick={handleClear} className={classNames(Styles.btnAction, Styles.btnClearSearch)}>
          <IconClose />
        </div>
      )}
    </div>
  );
};

const MemoSearchBar = memo(SearchBar);

export default MemoSearchBar;
