import { toCamelCaseDict } from '@hiredigital/lib/apis/utils';

export default class SocketConnection {
  // FIXME: This may not be the best approach to hold onto the sockets.
  // See PR #1669 for comments about using an alternative method.
  static sockets = new Map();
  static openStates = [WebSocket.CONNECTING, WebSocket.OPEN];
  baseUrl = process.env.WS_ENDPOINT;

  constructor(path) {
    if (path) {
      this.url = `${this.baseUrl}/${path}/`;
    }
  }

  setPath = (path = '') => {
    this.url = `${this.baseUrl}/${path}/`;
  };

  open = (path) => {
    if (path) this.setPath(path);
    const existingSocket = SocketConnection.sockets.get(this.url);

    if (existingSocket && SocketConnection.openStates.includes(existingSocket.readyState)) {
      this.socket = existingSocket;
    } else {
      existingSocket?.close?.(); // ensure it's closed
      SocketConnection.sockets.set(this.url, new WebSocket(this.url));
      this.socket = SocketConnection.sockets.get(this.url);
    }

    return this;
  };

  close = () => this.socket?.close();

  onOpen = (cb) => {
    this.socket.onopen = cb;
    return this;
  };

  onMessage = (cb) => {
    this.socket.onmessage = ({ data }) =>
      cb(
        typeof JSON.parse(data) === 'object' ? toCamelCaseDict(JSON.parse(data)) : JSON.parse(data)
      );
    return this;
  };

  onClose = (cb) => {
    this.socket.onclose = cb;
    return this;
  };

  onError = (cb) => {
    this.socket.onerror = cb;
    return this;
  };

  send = (data) => {
    // console.log('sending', this.socket)
    if (!this.socket || this.socket?.readyState !== WebSocket.OPEN) {
      return;
    }
    // console.log('pass send')
    this.socket.send(JSON.stringify(data));
    return this;
  };
}

export const userSocket = new SocketConnection();
// export const discussionSocket = new SocketConnection();
