import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import classNames from 'classnames';

const MainColumn = ({ children, withPadding, withBottomBorder, direction = 'column' }) => (
  <div
    className={classNames(
      Styles.mainColumn,
      { [Styles.withPadding]: withPadding },
      { [Styles.withBottomBorder]: withBottomBorder }
    )}
    style={{ flexDirection: direction }}>
    {children}
  </div>
);

MainColumn.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.string,
};

MainColumn.Direction = {
  ROW: 'row',
  COLUMN: 'column',
};

export default MainColumn;
