import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'evergreen-ui/esm/theme';

import { UserProvider } from './shared/context/user';
import { OrgProvider } from './shared/context/org';
import { TeamProvider } from './shared/context/team';
import { NotificationsProvider } from './shared/context/notifications';
import { ChatControllerProvider } from './shared/context/chatController';
import { CommonProvider } from './shared/context/common';

import PrivateRoute from '@helpers/PrivateRoute';
import LoginPage from './pages/login';
import LogoutPage from './pages/logout';
// import Dashboard from './pages/index';

import AssignmentsDetail from './pages/assignments/detail';
import BriefsDetail from './pages/briefs/detail';
import BriefsNew from './pages/briefs/new';
import BriefsIndex from './pages/briefs';
import EmailRedirect from './pages/email-redirect';

import EngagementsDetail from './pages/engagements/detail';
import EngagementsIndex from './pages/engagements';

import GuideIndex from './pages/guide';
import OnboardingAvailability from './pages/onboarding/availability';
import OnboardingProfile from './pages/onboarding/profile';

import ProfileIndex from './pages/profile';
import ProfileAvailability from './pages/profile/availability';

import ProjectsNewDetail from './pages/projects/new/detail';
import ProjectsNewIndex from './pages/projects/new/index';
import ProjectsDetail from './pages/projects/detail';
import ProjectsIndex from './pages/projects';

import SettingsClientsIndex from './pages/settings/clients';
import SettingsTeamsIndex from './pages/settings/teams';
import SettingsTeamsDetail from './pages/settings/teams/detail';
import SettingsTeamsDetailMembers from './pages/settings/teams/detail/members';
import SettingsTeamsDetailSettings from './pages/settings/teams/detail/settings';

import SettingsClientsBilling from './pages/settings/clients/detail/billing';
import SettingsClientsProfile from './pages/settings/clients/detail/profile';
import SettingsClientsMembers from './pages/settings/clients/detail/members';
import SettingsClientsSettings from './pages/settings/clients/detail/settings';

import SettingsClientsProfileRedirect from './pages/settings/clients/redirect/profile';
import SettingsClientsSettingsRedirect from './pages/settings/clients/redirect/settings';
import SettingsClientsMembersRedirect from './pages/settings/clients/redirect/members';
import SettingsClientsPaymentsRedirect from './pages/settings/clients/redirect/payments';

import TalentOnboarding from './pages/talent/onboarding';

// import SettingsIndex from './pages/settings';
import SettingsAccount from './pages/settings/account';
import SettingsPayments from './pages/settings/payments';
import SettingsNotifications from './pages/settings/notifications';

// Redirects
import DiscussionRedirect from './pages/notifications/redirects/discussions';
import MessageRedirect from './pages/notifications/redirects/messages';
import OrgSettingsRedirect from './pages/notifications/redirects/org-settings';
import BriefsRedirect from './pages/notifications/redirects/briefs';

import PageNotFound from './pages/404';
import TimesheetsIndex from './pages/timesheets/index';

import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import AdminWrapper from '@components/AdminWrapper/AdminWrapper';

import Helper from '@components/Layout/Helper';

import theme from '@hiredigital/ui/theme';
import { authRequest } from '@apis/utils';

import InterceptorProvider from '@hiredigital/ui/InterceptorProvider';
import { IntercomProvider } from '@hiredigital/lib/providers/intercom';
import { ChatSettingsProvider } from '@context/chatSettings';
import { NetworkStatusProvider } from '@context/network';
import { useOnlineStatus } from '@hooks/useOnlineStatus';

// if (process.env.ENV === 'local') {
//   const { whyDidYouUpdate } = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;

const INTERCOM_SETTINGS = {
  app_id: INTERCOM_APP_ID,
  // custom_launcher_selector: '#intercom-chat',
  // alignment: 'left',
  horizontal_padding: 20,
  vertical_padding: 20,
  // hide_default_launcher: true,
};

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/dashboard/timesheets' render={() => <Redirect to='/timesheets' />} />
        <Route exact path='/dashboard/projects' render={() => <Redirect to='/projects' />} />
        <Route exact path='/dashboard/clients' render={() => <Redirect to='/engagements' />} />
        <Route exact path='/dashboard/briefs' render={() => <Redirect to='/briefs' />} />
        <Route exact path='/app-notifications' render={() => <Redirect to='/projects' />} />

        <Route
          exact
          path='/settings/email-notification'
          render={() => <Redirect to='/settings/notifications' />}
        />
        <Route
          exact
          path='/settings/user/payments'
          render={() => <Redirect to='/settings/payments' />}
        />

        <Route
          exact
          path='/settings/availability'
          render={() => <Redirect to='/profile/availability' />}
        />

        <Route exact path='/dashboard' render={() => <Redirect to='/projects' />} />
        <Route exact path='/settings' render={() => <Redirect to='/settings/account' />} />
        <Route exact path='/' render={() => <Redirect to='/projects' />} />

        <Route exact path='/login' component={LoginPage} />
        <Route exact path='/logout' component={LogoutPage} />
        <Route exact path='/talent/onboarding' component={TalentOnboarding} />

        <PrivateRoute exact path='/assignments/:uuid' component={AssignmentsDetail} />

        <PrivateRoute exact path='/briefs/new' component={BriefsNew} />
        <PrivateRoute exact path='/briefs/:uuid/:stage?' component={BriefsDetail} />
        <PrivateRoute exact path='/briefs' component={BriefsIndex} />
        <PrivateRoute exact path='/email-redirect/:id/:source?' component={EmailRedirect} />
        <PrivateRoute exact path='/engagements/:uuid' component={EngagementsDetail} />
        <PrivateRoute exact path='/engagements' component={EngagementsIndex} />
        <PrivateRoute exact path='/guide' component={GuideIndex} />
        <PrivateRoute exact path='/onboarding/profile' component={OnboardingProfile} />
        <PrivateRoute exact path='/onboarding/availability' component={OnboardingAvailability} />
        <PrivateRoute exact path='/profile/availability' component={ProfileAvailability} />

        <PrivateRoute exact path='/profile' component={ProfileIndex} />

        <PrivateRoute path='/projects/new/:uuid' component={ProjectsNewDetail} />
        <PrivateRoute path='/projects/new' component={ProjectsNewIndex} />
        <PrivateRoute
          exact
          path={['/projects/:uuid', '/projects/:uuid/content/:contentId']}
          component={ProjectsDetail}
        />
        <PrivateRoute exact path='/projects' component={ProjectsIndex} />

        <PrivateRoute
          exact
          path='/settings/org/profile'
          component={SettingsClientsProfileRedirect}
        />
        <PrivateRoute
          exact
          path='/settings/org/settings'
          component={SettingsClientsSettingsRedirect}
        />
        <PrivateRoute
          exact
          path='/settings/org/members'
          component={SettingsClientsMembersRedirect}
        />
        <PrivateRoute
          exact
          path='/settings/org/payments'
          component={SettingsClientsPaymentsRedirect}
        />

        <PrivateRoute
          exact
          path='/settings/clients/:uuid/billing'
          component={SettingsClientsBilling}
        />
        <PrivateRoute
          exact
          path='/settings/clients/:uuid/members'
          component={SettingsClientsMembers}
        />
        <PrivateRoute
          exact
          path='/settings/clients/:uuid/profile'
          component={SettingsClientsProfile}
        />
        <PrivateRoute
          exact
          path='/settings/clients/:uuid/settings'
          component={SettingsClientsSettings}
        />
        <PrivateRoute exact path='/settings/clients' component={SettingsClientsIndex} />

        <PrivateRoute
          exact
          path='/settings/teams/:uuid/members'
          component={SettingsTeamsDetailMembers}
        />
        <PrivateRoute
          exact
          path='/settings/teams/:uuid/settings'
          component={SettingsTeamsDetailSettings}
        />
        <PrivateRoute exact path='/settings/teams/:uuid' component={SettingsTeamsDetail} />
        <PrivateRoute exact path='/settings/teams' component={SettingsTeamsIndex} />

        <PrivateRoute exact path='/settings/account' component={SettingsAccount} />
        <PrivateRoute exact path='/settings/payments' component={SettingsPayments} />
        <PrivateRoute exact path='/settings/notifications' component={SettingsNotifications} />
        {/*<Route exact path='/timesheets' render={() => <Redirect to='/timesheets' />} />*/}
        <PrivateRoute exact path='/timesheets' component={TimesheetsIndex} />
        {/* redirect routes */}
        <PrivateRoute
          exact
          path='/discussions/:discussionId/redirect'
          component={DiscussionRedirect}
        />
        <PrivateRoute exact path='/messages/:messageId/redirect' component={MessageRedirect} />
        <PrivateRoute exact path='/settings/org/payments' component={OrgSettingsRedirect} />
        <Route
          exact
          path='/settings/user/companies'
          render={() => <Redirect to='/settings/clients' />}
        />
        <PrivateRoute
          exact
          path='/briefs/:uuid/engagements/:engagementUuid'
          component={BriefsRedirect}
        />

        {/* fallback */}
        <Route exact path='/404' component={PageNotFound} />
        <Route component={PageNotFound} />
      </Switch>
      {/* Toaster is brought in via helper */}
      {/*<Toaster />*/}
      <Helper />
    </Router>
  );
};

const DataProviders = ({ children }) => {
  return (
    <CommonProvider>
      <UserProvider>
        <OrgProvider>
          <TeamProvider>
            <ChatSettingsProvider>
              <ChatControllerProvider>
                <NotificationsProvider>
                  <ErrorBoundary>
                    <AdminWrapper>{children}</AdminWrapper>
                  </ErrorBoundary>
                </NotificationsProvider>
              </ChatControllerProvider>
            </ChatSettingsProvider>
          </TeamProvider>
        </OrgProvider>
      </UserProvider>
    </CommonProvider>
  );
};

const NetworkStatusManager = ({ children }) => {
  const { isOnline, setIsOnline } = useOnlineStatus();
  const history = useHistory();

  return (
    <NetworkStatusProvider initial={{ isOnline, setIsOnline }}>
      <InterceptorProvider
        requests={[authRequest]}
        onNetworkSuccess={() => setIsOnline(true)}
        onNetworkError={() => setIsOnline(false)}
        onAuthorizationError={() =>
          process.env.ENV !== 'local'
            ? (window.location.href = `${process.env.MARKETING_URL}/login`)
            : history?.push(`/login`)
        }>
        {children}
      </InterceptorProvider>
    </NetworkStatusProvider>
  );
};

const ServiceProviders = ({ children }) => {
  return (
    <ThemeProvider value={theme}>
      <IntercomProvider appId={INTERCOM_APP_ID} settings={INTERCOM_SETTINGS}>
        {children}
      </IntercomProvider>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <ServiceProviders>
      <NetworkStatusManager>
        <DataProviders>
          <Routes />
        </DataProviders>
      </NetworkStatusManager>
    </ServiceProviders>
  );
};
export default App;

// window.store = BaseModel;
