import { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import OverviewHeader from '../Briefs/components/overview/OverviewHeader';
import OverviewItem from '../Briefs/components/overview/OverviewItem';
import OverviewContent from '../Briefs/components/overview/OverviewContent';
import OverviewBody from '../Briefs/components/overview/OverviewBody';
import OverviewColumn from '../Briefs/components/overview/OverviewColumn';
import FileList from '../Files/FileList';
import { getBrand } from '@apis/brands';
import { VisibilityOption } from '@hiredigital/lib/helpers/enum';
import Styles from './BrandProfile.module.scss';
import convert from 'htmr';

const BrandProfile = ({ orgUuid, organization = null, engagement, ...props }) => {
  const [brand, setBrand] = useState({});
  const [isConfidential, setIsConfidential] = useState(false);

  useEffect(() => {
    setIsConfidential(
      [VisibilityOption.PRIVATE_CONFIDENTIAL.id, VisibilityOption.PUBLIC_CONFIDENTIAL.id].includes(
        engagement?.visibility
      )
    );
  }, [engagement]);

  const formatItems = (items, key) => {
    return !!items?.length ? items?.map((v) => v[key || 'label'])?.join(', ') : 'No preference';
  };

  const loadBrand = useCallback(() => {
    getBrand(orgUuid)
      .then(({ data }) => {
        setBrand(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [orgUuid]);

  useEffect(() => {
    setIsConfidential(!orgUuid && !engagement?.clientName);

    if (organization) {
      setBrand(organization);
      return;
    }

    loadBrand();
  }, [orgUuid, engagement, loadBrand, organization]);

  return (
    <OverviewContent {...props}>
      <OverviewHeader
        title={() => (
          <Fragment>
            {convert(engagement?.clientName || brand?.name || 'About the Client')}
          </Fragment>
        )}
        enableEdit={false}
        withBorder
      />
      <OverviewBody>
        <OverviewColumn width={65}>
          {brand?.description && (
            <OverviewItem
              title={`Client Details`}
              content={engagement.clientDetails || brand?.description}
              isHtml
            />
          )}
          {isConfidential && (
            <div className={Styles.confidentialNote}>
              <strong>Note:</strong>{' '}
              {`This client's profile is confidential. You will be able to view their profile once your proposal has been reviewed.`}
            </div>
          )}
          {brand?.guidelines && (
            <OverviewItem title={`Client Brand Guidelines`} content={brand?.guidelines} isHtml />
          )}
          {(brand?.attachments?.length > 0 || brand?.links?.length > 0) && (
            <FileList attachments={brand?.attachments} links={brand?.links} />
          )}
        </OverviewColumn>
        <OverviewColumn width={35}>
          {brand?.topics?.length > 0 && (
            <OverviewItem title={`Verticals`} content={formatItems(brand?.topics)} />
          )}
          {brand?.social?.website && (
            <OverviewItem title={`Website`} content={brand?.social?.website} isLink />
          )}
          {brand?.social?.facebook && (
            <OverviewItem
              title={`Facebook`}
              content={`https://www.facebook.com/${brand?.social?.facebook}`}
              isLink
            />
          )}
          {brand?.social?.twitter && (
            <OverviewItem
              title={`Twitter`}
              content={`https://twitter.com/${brand?.social?.twitter}`}
              isLink
            />
          )}
          {brand?.social?.linkedin && (
            <OverviewItem
              title={`Linkedin`}
              content={`https://www.linkedin.com/company/${brand?.social?.linkedin}`}
              isLink
            />
          )}
          {brand?.social?.instagram && (
            <OverviewItem
              title={`Instagram`}
              content={`https://www.instagram.com/${brand?.social?.instagram}`}
              isLink
            />
          )}
          {brand?.social?.youtube && (
            <OverviewItem title={`Youtube`} content={brand?.social?.youtube} isLink />
          )}
        </OverviewColumn>
      </OverviewBody>
    </OverviewContent>
  );
};

BrandProfile.propTypes = {
  children: PropTypes.node,
};

export default BrandProfile;
