import { useState, useEffect } from 'react';
import classNames from 'classnames';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';

import { getProjectFileHistories } from '@apis/projects';
import { toReadableDateTime } from '@hiredigital/lib/helpers/date';
import { useProjects } from '@context/projects';

import Styles from './TextHistory.module.scss';

const TextHistory = ({ row, projectUuid }) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { history, cancelHistory, selectHistory } = useProjects();

  useEffect(() => {
    setIsLoading(true);
    getProjectFileHistories(projectUuid, row?.original?.id)
      .then(({ data }) => {
        setItems(data?.results);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const handleCancel = () => {
    row?.toggleRowExpanded(false);
    cancelHistory();
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>{`History`}</div>
      {items?.map((v, idx) => (
        <div
          key={idx}
          className={classNames(Styles.item, history?.id === v?.id && Styles.selected)}
          onClick={() => selectHistory(v, row?.original)}>
          <p className={Styles.title}>{v?.title || `Untitled`}</p>
          <p className={Styles.date}>{`Saved ${toReadableDateTime(v?.modified)}`}</p>
        </div>
      ))}
      <div className={Styles.actionRow}>
        <Button onClick={() => handleCancel()} type={Button.Type.BLUE_OUTLINE}>
          {`Cancel`}
        </Button>
      </div>

      {isLoading && <Loader size={Loader.Size.SMALL} />}
    </div>
  );
};

export default TextHistory;
