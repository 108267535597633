// import Icon from '@hiredigital/ui/Icon/Icon';
import Word from '@hiredigital/ui/Icon/icons/word.inline.svg';
import Excel from '@hiredigital/ui/Icon/icons/excel.inline.svg';
import Audio from '@hiredigital/ui/Icon/icons/audio.inline.svg';
import Pdf from '@hiredigital/ui/Icon/icons/pdf.inline.svg';
import Text from '@hiredigital/ui/Icon/icons/text.inline.svg';
import Picture from '@hiredigital/ui/Icon/icons/picture.inline.svg';
import File from '@hiredigital/ui/Icon/icons/file.inline.svg';

const FileIcon = ({ className, url = '' }) => {
  const ext = url.substr(url.lastIndexOf('.') + 1).toLowerCase();

  switch (ext) {
    case 'doc':
    case 'docx':
      return <Word className={className} />;
    case 'xls':
    case 'xlsx':
      return <Excel className={className} />;
    case 'mp3':
    case 'aac':
    case 'mp4':
    case 'avi':
    case 'wmv':
      return <Audio className={className} />;
    case 'pdf':
      return <Pdf className={className} />;
    case 'txt':
      return <Text className={className} />;
    case 'jpg':
    case 'jpeg':
    case 'bmp':
    case 'png':
    case 'gif':
    case 'tiff':
    case 'eps':
    case 'psd':
    case 'ai':
    case 'indd':
    case 'src':
      return <Picture className={className} />;
    default:
      return <File className={className} />;
  }
};

export default FileIcon;
