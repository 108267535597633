import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import OverviewHeader from '../Briefs/components/overview/OverviewHeader';
import OverviewItem from '../Briefs/components/overview/OverviewItem';
import OverviewContent from '../Briefs/components/overview/OverviewContent';
import OverviewBody from '../Briefs/components/overview/OverviewBody';
import OverviewColumn from '../Briefs/components/overview/OverviewColumn';
import { toReadableDate } from '@hiredigital/lib/helpers/date';
import BrandProfile from '../BrandProfile/BrandProfile';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import { patchAssignment } from '@apis/assignments';
import { useUser } from '@context/user';

import Styles from './Detail.module.scss';

const Detail = ({ assignment }) => {
  const user = useUser();

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState(assignment?.title || '');

  const formatItems = (items, key) => {
    return !!items?.length ? items?.map((v) => v[key || 'label'])?.join(', ') : 'No preference';
  };

  const formatTimeLine = (assignment) => {
    if (!assignment?.startDate && !assignment?.endDate) {
      return 'Ongoing';
    }
    const start = `${assignment?.startDate ? toReadableDate(assignment?.startDate) : 'Immediate'}`;
    const end = `${assignment?.endDate ? toReadableDate(assignment?.endDate) : 'Ongoing'}`;
    return `${start} - ${end}`;
  };

  const handleSave = () => {
    setIsSaving(true);
    patchAssignment(assignment?.uuid, { title })
      .then(({ data }) => {
        setIsEditing(false);
        setIsSaving(false);
      })
      .catch((error) => {
        console.error(error);
        setIsEditing(false);
        setIsSaving(false);
      });
  };

  // TODO: I left out links because I could not find an example with a link. - Tim

  return (
    <div>
      <OverviewContent>
        <OverviewHeader enableEdit={false} withBorder>
          <div className={Styles.header}>
            {isEditing ? (
              <Fragment>
                <TextInput
                  label={`Title`}
                  className={Styles.inputTitle}
                  onChange={(e) => setTitle(e.target.value)}
                  defaultValue={title}
                />
                <Button
                  isLoading={isSaving}
                  onClick={handleSave}
                  type={Button.Type.BLUE}
                  className={Styles.actionEdit}>
                  {`Save`}
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <h4 className={Styles.title}>{title || 'Untitled Assignment'}</h4>
                {!user?.isTalent && (
                  <Button
                    onClick={() => setIsEditing(true)}
                    type={Button.Type.BLUE_OUTLINE}
                    className={Styles.actionEdit}>
                    {`Edit`}
                  </Button>
                )}
              </Fragment>
            )}
          </div>
        </OverviewHeader>
        <OverviewBody>
          <OverviewColumn width={65}>
            {assignment?.engagement?.responsibilities && (
              <OverviewItem
                title={`Responsibilities`}
                content={assignment?.engagement?.responsibilities}
                isHtml
              />
            )}
            {assignment?.engagement?.requirements && (
              <OverviewItem
                title={`Requirements`}
                content={assignment?.engagement?.requirements}
                isHtml
              />
            )}
            {assignment?.engagement?.talentTerms && (
              <OverviewItem
                title={`Engagement Terms`}
                content={assignment?.engagement?.talentTerms}
                isHtml
              />
            )}
          </OverviewColumn>
          <OverviewColumn width={35}>
            <OverviewItem title={`Timeline`} content={formatTimeLine(assignment)} />
            {assignment?.engagement?.skills?.length > 0 && (
              <OverviewItem
                title={`Experience`}
                content={formatItems(assignment?.engagement?.skills)}
              />
            )}
            {assignment?.engagement?.languages?.length > 0 && (
              <OverviewItem
                title={`Language`}
                content={formatItems(assignment?.engagement?.languages, 'name')}
              />
            )}
            {assignment?.engagement?.countries?.length > 0 && (
              <OverviewItem
                title={`Country`}
                content={formatItems(assignment?.engagement?.countries, 'name')}
              />
            )}
            {assignment?.engagement?.location && (
              <OverviewItem title={`Location`} content={assignment?.engagement?.location} isHtml />
            )}
          </OverviewColumn>
        </OverviewBody>
      </OverviewContent>
      {(assignment?.application && (
        <OverviewContent>
          <OverviewHeader enableEdit={false} withBorder>
            <div className={Styles.header}>
              <h4 className={Styles.title}>{`Your Application`}</h4>
            </div>
          </OverviewHeader>
          <OverviewBody>
            <OverviewColumn width={65}>
              <OverviewItem content={assignment?.application?.content} isHtml />
              {/*{assignment?.application?.links.map((link, index) => {
                return <OverviewItem title={`Website`} content={link} isLink />;
              })}*/}
            </OverviewColumn>
          </OverviewBody>
        </OverviewContent>
      )) ||
        ''}

      <BrandProfile
        orgUuid={assignment?.organization?.uuid}
        engagement={assignment?.engagement || {}}
      />
    </div>
  );
};

Detail.propTypes = {
  children: PropTypes.node,
};

export default Detail;
