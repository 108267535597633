import Styles from './Guide.module.scss';
import StepItem from './StepItem';

const StepsContainer = ({ children }) => {
  return <div className={Styles.guideStepsContainer}>{children}</div>;
};

StepsContainer.Item = StepItem;

export default StepsContainer;
