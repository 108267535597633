import { Fragment, useEffect, useState, forwardRef } from 'react';
import toast from 'react-hot-toast';

// import { Dialog } from 'evergreen-ui/esm/dialog';
import Dialog from '@hiredigital/ui/Dialog/Dialog';
import Button from '@hiredigital/ui/Button/Button';

import { useDiscussion } from '@context/discussion';
import { useUser } from '@context/user';
import { getConference } from '@apis/conferences';

import InfiniteScrollReverse from './InfiniteScrollReverse';
import Message from './MessageContent/Message';
import Portal from '@hiredigital/ui/Portal/Portal';
import QuillEditor from '@hiredigital/ui/Quill/Editor';
import ConferenceSchedule from '../DiscussionSchedule/ConferenceSchedule';

import Styles from './ProjectDiscussion.module.scss';

const MessageList = forwardRef(({ discussion, search, ...props }, ref) => {
  const {
    messages,
    messagesMeta,
    updateMessage,
    deleteMessage,
    cancelSchedule,
    loadMessages,
    openConnection,
    isSocketOpen,
  } = useDiscussion();
  const user = useUser();

  const [isLoading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isConferenceShown, setIsConferenceShown] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [editContent, setEditContent] = useState(null);
  const [conference, setConference] = useState(null);
  const [message, setMessage] = useState({});
  const [stage, setStage] = useState(1);
  const [isMounted, setIsMounted] = useState(false);
  const [ws, setWs] = useState(null);
  const [isReschedule, setIsReschedule] = useState(false);
  const [conferenceType, setConferenceType] = useState(ConferenceSchedule.Type.NEW_SCHEDULE);

  useEffect(() => {
    const socket = openConnection(discussion.id);
    setWs(socket);
    showMessages({}, true);
    setIsMounted(true);
    return () => {
      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSocketOpen) {
      ws?.send({ type: 'mark_all_as_read' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocketOpen]);

  useEffect(() => {
    showMessages({ search }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const showMessages = async (params, reset = false) => {
    setLoading(true);
    const config = { params: { limit: 75, ...params } };
    await loadMessages(discussion.id, config, reset);
    setLoading(false);
  };

  const showMoreMessages = async (nextPage) => {
    if (!isLoading && messagesMeta.nextPage) {
      setLoading(true);

      try {
        await showMessages({ page: messagesMeta.nextPage });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  const handleDelete = () => {
    deleteMessage(discussion.id, message.id);
    setShowDelete(false);
  };

  const handleEdit = () => {
    updateMessage(discussion.id, { ...message, content: editContent || undefined });
    setShowEdit(false);
  };

  const handleCancelCall = () => {
    cancelSchedule(message);
    setShowCancel(false);
  };

  const handleShowReschedule = (msg, initialStage = 1) => {
    const uuid = msg.metaObject?.uuid;
    setIsReschedule(initialStage === 1 ? true : false);
    setStage(initialStage);
    setMessage(msg);
    getConference(uuid)
      .then(({ data }) => {
        setConference(data);
        setConferenceType(ConferenceSchedule.Type.RESCHEDULE);
        setIsConferenceShown(true);
      })
      .catch((error) => {
        console.error(error);
        setIsConferenceShown(false);
      });
  };

  const handleShowEdit = (msg) => {
    setMessage(msg);
    setEditContent(msg?.content);
    setShowEdit(true);
  };

  const handleShowCancel = (msg) => {
    setMessage(msg);
    setShowCancel(true);
  };

  const handleShowDelete = (msg) => {
    setMessage(msg);
    setShowDelete(true);
  };

  const handleScheduleSubmitCompletion = () => {
    updateMessage(discussion.id, { id: message?.id });
    setIsConferenceShown(false);
  };

  const reversedMessages = [...messages].reverse();

  return (
    <Fragment>
      <InfiniteScrollReverse
        ref={ref}
        className={Styles.listContainer}
        hasMore={messagesMeta.nextPage ? true : false}
        isLoading={isLoading}
        loadMore={showMoreMessages}
        loadArea={100}>
        {reversedMessages.map((item, index) => (
          <Message
            key={index}
            discussion={discussion}
            nextMessageSender={reversedMessages?.[index - 1]?.sender}
            message={item}
            onAddParticipant={(v) => handleShowReschedule(v, 2)}
            onReschedule={(v) => handleShowReschedule(v, 1)}
            onEdit={handleShowEdit}
            onDelete={handleShowDelete}
            onCancelSchedule={handleShowCancel}
          />
        ))}
      </InfiniteScrollReverse>

      <Portal>
        {isMounted && (
          <Fragment>
            <Dialog isOpen={showDelete} title='Delete Message' onClose={() => setShowDelete(false)}>
              {`Please confirm that you would like to delete this message.`}
              <div style={{ marginTop: '30px' }}>
                <Button onClick={handleDelete} type={Button.Type.BLUE} isLoading={isLoading}>
                  {`Confirm`}
                </Button>
                <Button onClick={() => setShowDelete(false)} type={Button.Type.LIGHT_GRAY}>
                  {`Cancel`}
                </Button>
              </div>
            </Dialog>

            <Dialog
              className={Styles.editContainer}
              isOpen={showEdit}
              title='Edit Message'
              onClose={(event) => {
                if (event || editContent === message.content) setShowEdit(false);
                else
                  toast.error('Please save or discard the changes.', {
                    id: 'unsavedChanges',
                  });
              }}>
              <QuillEditor
                className={Styles.editMessage}
                inputClassName={Styles.input}
                name='message'
                placeholder={`Add your message here`}
                onSubmit={handleEdit}
                onChange={(e) => setEditContent(e.target.value)}
                defaultValue={message.content}
                mentionList={discussion.participants}
                theme={'snow'}
                plugins={{
                  mention: true,
                  fileDrop: true,
                  magicUrl: true,
                }}
                currentUser={user}
              />
              <div style={{ marginTop: '30px' }}>
                <Button onClick={handleEdit} type={Button.Type.BLUE}>
                  {`Save`}
                </Button>
                <Button onClick={() => setShowEdit(false)} type={Button.Type.LIGHT_GRAY}>
                  {`Cancel`}
                </Button>
              </div>
            </Dialog>

            <Dialog isOpen={showCancel} title='Cancel Call' onClose={() => setShowCancel(false)}>
              {`Confirm that you would like to cancel the call. You can schedule a new call if necessary.`}
              <div style={{ marginTop: '30px' }}>
                <Button onClick={handleCancelCall} type={Button.Type.BLUE}>
                  {`Cancel Call`}
                </Button>
                <Button onClick={() => setShowCancel(false)} type={Button.Type.LIGHT_GRAY}>
                  {`Go Back`}
                </Button>
              </div>
            </Dialog>

            {isConferenceShown && (
              <ConferenceSchedule
                isShown={isConferenceShown}
                stage={stage}
                conference={conference}
                discussion={discussion}
                onCancel={() => setIsConferenceShown(false)}
                onClose={() => setIsConferenceShown(false)}
                onSubmit={handleScheduleSubmitCompletion}
                type={conferenceType}
                isReschedule={isReschedule}
              />
            )}
          </Fragment>
        )}
      </Portal>
    </Fragment>
  );
});

export default MessageList;
