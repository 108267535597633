import classNames from 'classnames';
import Styles from './DiscussionsContainer.module.scss';

const DiscussionsContainer = ({ className, children }) => {
  return <div className={classNames(Styles.discussionsContainer, className)}>{children}</div>;
};

DiscussionsContainer.propTypes = {};

export default DiscussionsContainer;
