import { useState } from 'react';
import { changeUserPassword } from '@apis/users';

import Password from '@hiredigital/ui/Input/Password/Password';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextInput from '@hiredigital/ui/Input/TextInput';

import toast from 'react-hot-toast';

const UserPassword = () => {
  const [oldPassword, setOldPassword] = useState();
  const [newPassword1, setNewPassword1] = useState();
  const [newPassword2, setNewPassword2] = useState();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    if (Object.keys(errors).length > 0) return;
    setErrors({});
    try {
      setIsLoading(true);
      await changeUserPassword({ oldPassword, newPassword1, newPassword2 });
      toast.success('Your password was updated successfully.', {
        id: 'password',
      });
    } catch (error) {
      setErrors(error.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header title={`Update Password`}></Card.Header>
      <Card.Item>
        <form>
          <InputGroup>
            <InputContainer>
              <TextInput
                className={'highlight-block'}
                name='oldPassword'
                autoComplete='current-password'
                label={`Current Password`}
                type='password'
                error={errors?.oldPassword}
                onChange={({ target: { value } }) => {
                  setErrors({});
                  setOldPassword(value);
                }}
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <Password
                className={'highlight-block'}
                id='password1'
                name='newPassword1'
                value={newPassword1}
                minLength={6}
                placeholder={`New Password`}
                error={errors?.newPassword1}
                autoFocus
                showStrength
                onChange={(e) => {
                  setErrors({});
                  setNewPassword1(e?.target?.value);
                }}
              />
            </InputContainer>
            <InputContainer>
              <Password
                className={'highlight-block'}
                id='password2'
                name='newPassword2'
                value={newPassword2}
                minLength={6}
                placeholder={`New Password Again`}
                error={errors?.newPassword2}
                autoFocus
                showStrength
                onChange={(e) => {
                  setErrors({});
                  setNewPassword2(e?.target?.value);
                }}
              />
            </InputContainer>
          </InputGroup>
        </form>
      </Card.Item>
      <Card.Footer right>
        <Button type={Button.Type.BLUE} onClick={handleSave} isLoading={isLoading}>
          {`Save Password`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default UserPassword;
