import { authRequest } from './utils';

const serializeTeam = (data) => {
  const serializedData = {
    ...data,
    countries: data?.countries?.map((obj) => obj?.id),
    languages: data?.languages?.map((obj) => obj?.id),
    topics: data?.topics?.map((obj) => obj?.id),
    contact: {
      ...data.contact,
      timezone: data?.contact?.timezone?.id,
      user: data?.contact?.user?.uuid,
      preferred: data?.contact?.preferred?.id,
    },
    payment: {
      ...data.payment,
      currency: data?.payment?.currency?.id,
    },
  };
  return serializedData;
};

export const getTeams = async (config = undefined) => {
  const url = `teams/`;
  return await authRequest.get(url, config);
};

export const getTeam = async (uuid, config = undefined) => {
  const url = `teams/${uuid}/`;
  return await authRequest.get(url, config);
};

export const postTeam = async (data, serialize = serializeTeam, config = undefined) => {
  const url = `teams/`;
  return await authRequest.post(url, serialize(data), config);
};

export const putTeam = async (uuid, data, serialize = serializeTeam, config = undefined) => {
  const url = `teams/${uuid}/`;
  return await authRequest.put(url, serialize(data), config);
};

export const patchTeam = async (uuid, data, serialize = serializeTeam, config = undefined) => {
  const url = `teams/${uuid}/`;
  return await authRequest.patch(url, serialize(data), config);
};

export const deleteTeam = async (uuid, config = undefined) => {
  const url = `teams/${uuid}/`;
  return await authRequest.delete(url, config);
};

export const getTeamServiceRates = async (uuid, config = undefined) => {
  const url = `teams/${uuid}/service-rates/`;
  return await authRequest.get(url, config);
};

export const getTeamServiceRate = async (uuid, rateUuid, config = undefined) => {
  const url = `teams/${uuid}/service-rates/${rateUuid}/`;
  return await authRequest.get(url, config);
};

export const postTeamServiceRate = async (uuid, data, config = undefined) => {
  const url = `teams/${uuid}/service-rates/`;
  return await authRequest.post(url, data, config);
};

export const putTeamServiceRate = async (uuid, rateUuid, data, config = undefined) => {
  const url = `teams/${uuid}/service-rates/${rateUuid}/`;
  return await authRequest.put(url, data, config);
};

export const patchTeamServiceRate = async (uuid, rateUuid, data, config = undefined) => {
  const url = `teams/${uuid}/service-rates/${rateUuid}/`;
  return await authRequest.patch(url, data, config);
};

export const deleteTeamServiceRate = async (uuid, rateUuid, config = undefined) => {
  const url = `teams/${uuid}/service-rates/${rateUuid}/`;
  return await authRequest.delete(url, config);
};

export const getTeamEngagements = async (uuid, config = undefined) => {
  const url = `teams/${uuid}/engagements/`;
  return await authRequest.get(url, config);
};

export const getTeamProjects = async (uuid, config = undefined) => {
  const url = `teams/${uuid}/projects/`;
  return await authRequest.get(url, config);
};

export const generateTeamAgreement = async (uuid, data, config = undefined) => {
  const url = `teams/${uuid}/agreements/generate/`;
  return await authRequest.post(url, data, config);
};

export const getTeamAgreementLink = async (uuid, agreementId, data, config = undefined) => {
  const url = `teams/${uuid}/agreements/${agreementId}/get-agreement-link/`;
  return await authRequest.get(url, data, config);
};

export const getTeamUsers = async (uuid, config = undefined) => {
  const url = `teams/${uuid}/users/`;
  return await authRequest.get(url, config);
};

export const postTeamUser = async (uuid, data, config = undefined) => {
  const url = `teams/${uuid}/users/`;
  return await authRequest.post(url, data, config);
};

export const putTeamUser = async (uuid, userId, data, config = undefined) => {
  const url = `teams/${uuid}/users/${userId}/`;
  return await authRequest.put(url, data, config);
};

export const patchTeamUser = async (uuid, userId, data, config = undefined) => {
  const url = `teams/${uuid}/users/${userId}/`;
  return await authRequest.patch(url, data, config);
};

export const deleteTeamUser = async (uuid, userId, config = undefined) => {
  const url = `teams/${uuid}/users/${userId}/`;
  return await authRequest.delete(url, config);
};

export const getTeamInvitations = async (uuid, config = undefined) => {
  const url = `teams/${uuid}/invitations/`;
  return await authRequest.get(url, config);
};

export const postTeamInvitation = async (uuid, data, config = undefined) => {
  const url = `teams/${uuid}/invitations/`;
  return await authRequest.post(url, data, config);
};

export const putTeamInvitation = async (uuid, userId, data, config = undefined) => {
  const url = `teams/${uuid}/invitations/${userId}/`;
  return await authRequest.put(url, data, config);
};

export const patchTeamInvitation = async (uuid, userId, data, config = undefined) => {
  const url = `teams/${uuid}/invitations/${userId}/`;
  return await authRequest.patch(url, data, config);
};

export const deleteTeamInvitation = async (uuid, userId, config = undefined) => {
  const url = `teams/${uuid}/invitations/${userId}/`;
  return await authRequest.delete(url, config);
};

export const getTeamClients = async (uuid, config = undefined) => {
  const url = `teams/${uuid}/clients/`;
  return await authRequest.get(url, config);
};
