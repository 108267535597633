import { useState, memo, useEffect } from 'react';
import Styles from '../../Styles.module.scss';
import TimeItem from './TimeItem';
import Loader from '@hiredigital/ui/Loader';

const AvailableTimes = ({ isBusy, data, onItemSelect, selected }) => {
  const getActiveIndex = () => {
    const selectedIndex = data.findIndex((v) => selected.startFormatted === v.startFormatted);
    return selectedIndex < 0 ? 0 : selectedIndex;
  };

  const [activeItems, setActiveItems] = useState([getActiveIndex()]);

  const handleItemSelect = (idx, item) => {
    setActiveItems([idx]);
    onItemSelect?.(item);
  };

  useEffect(() => {
    setActiveItems([getActiveIndex()]);
  }, [selected, data]);

  return (
    <div className={Styles.availableTimesList}>
      {isBusy ? (
        <Loader color={Loader.Color.BLUE} size={Loader.Size.SMALL} />
      ) : (
        data?.map((v, idx) => (
          <TimeItem
            key={idx}
            onClick={() => handleItemSelect(idx, v)}
            isActive={activeItems.includes(idx)}
            label={`${v.startFormatted}-${v.endFormatted}`}
          />
        ))
      )}
    </div>
  );
};

AvailableTimes.propTypes = {};

export default memo(AvailableTimes);
