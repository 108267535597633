import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import Loader from '@hiredigital/ui/Loader';

const OverlayLoader = ({ isLoading }) => {
  return isLoading ? (
    <div className={Styles.overlay}>
      <Loader color={Loader.Color.BLUE} size={Loader.Size.LARGE} />
    </div>
  ) : null;
};

OverlayLoader.propTypes = {
  children: PropTypes.node,
};

export default OverlayLoader;
