export class BankApiManager {
  constructor(
    request,
    resourceUuid,
    { resourcePrefix, resource = 'users', subResource = 'wise-bank-accounts' } = {}
  ) {
    this.request = request;
    this.subResource = subResource;

    const prefix = resourcePrefix ? `${resourcePrefix}/` : '';
    this.resourceUrl = `${prefix}${resource}/${resourceUuid}/`;
  }

  getBanks = async (config) => {
    const url = `${this.resourceUrl}${this.subResource}/`;
    return await this.request.get(url, config);
  };

  getBank = async (id, config) => {
    const url = `${this.resourceUrl}${this.subResource}/${id}/`;
    return await this.request.get(url, config);
  };

  postBank = async (data, config) => {
    const url = `${this.resourceUrl}${this.subResource}/`;
    return await this.request.post(url, data, config);
  };

  deleteBank = async (id, config) => {
    const url = `${this.resourceUrl}${this.subResource}/${id}/`;
    return await this.request.delete(url, config);
  };

  putBank = async (id, data, config) => {
    const url = `${this.resourceUrl}${this.subResource}/${id}/`;
    return await this.request.put(url, data, config);
  };

  patchEmail = async (data, config) => {
    const url = `${this.resourceUrl}`;
    return await this.request.patch(url, data, config);
  };

  postTransferwiseAcccount = async (id, config = undefined) => {
    const url = `${this.resourceUrl}${this.subResource}/${id}/update-transferwise/`;
    return await this.request.post(url, config);
  };

  getBankAccountsBanks = async (countryCode, config = undefined) => {
    const url = `${this.resourceUrl}${this.subResource}/banks/?country=${countryCode}`;
    return await this.request.get(url, config);
  };

  getBankBranches = async (countryCode, bankCode, config = undefined) => {
    const url = `${this.resourceUrl}${this.subResource}/bank-branches/?country=${countryCode}&bank_code=${bankCode}`;
    return await this.request.get(url, config);
  };

  getSwiftCodes = async (countryCode, config = undefined) => {
    const url = `${this.resourceUrl}${this.subResource}/swift-codes/?country=${countryCode}`;
    return await this.request.get(url, config);
  };
}
