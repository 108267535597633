import { useParams } from 'react-router-dom';
import Layout from '@components/Layout/AppLayout';
import MembersList from '@components/Members/List';

const Members = () => {
  const { uuid } = useParams();

  return (
    <Layout>
      <div>{uuid && <MembersList resourceUuid={uuid} type={MembersList.Type.TEAM} />}</div>
    </Layout>
  );
};

export default Members;
