import Styles from './Item.module.scss';
import classNames from 'classnames';
import SplitButton from '@hiredigital/ui/Button/SplitButton';
import Avatar from '@hiredigital/ui/Avatar';
import { AssignmentStatus } from '@hiredigital/lib/helpers/enum';
import { useUser } from '@context/user';

const Item = ({ data, onRemove, isEditable }) => {
  const user = useUser();
  const isCurrentUser = !!user?.uuid && data?.user?.uuid === user.uuid;

  return (
    <div
      className={classNames(
        Styles.item,
        data?.assignment?.status === AssignmentStatus.INACTIVE.id && Styles.inactive
      )}>
      <div className={Styles.talent}>
        <Avatar src={data?.user?.picture} name={data?.user?.name} style={{ marginRight: '5px' }} />
        {data?.user?.name}
      </div>
      {data?.assignment?.status === AssignmentStatus.INACTIVE.id && (
        <span className={Styles.status}>{`Inactive`}</span>
      )}
      {isEditable && !isCurrentUser && (
        <SplitButton more={[{ text: 'Remove', onClick: () => onRemove?.(data) }]} />
      )}
    </div>
  );
};

export default Item;
