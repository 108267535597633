import { ApiManager } from '../ApiManager';

export class ProjectDeadlineApiManager extends ApiManager {
  constructor(request, resourcePrefix = '') {
    super(request, 'projects', resourcePrefix);
  }

  getDeadlines = async (uuid, config = undefined) => {
    const url = `${this.resourcePath}/${uuid}/deadlines/`;
    return await this.request.get(url, config);
  };

  markDeadlineComplete = async (uuid, projectId, data, config = undefined) => {
    const url = `${this.resourcePath}/${uuid}/deadlines/${projectId}/complete/`;
    return await this.request.post(url, data, config);
  };

  markDeadlineActive = async (uuid, projectId, data, config = undefined) => {
    const url = `${this.resourcePath}/${uuid}/deadlines/${projectId}/active/`;
    return await this.request.post(url, data, config);
  };

  createDeadline = async (uuid, data, config = undefined) => {
    const url = `${this.resourcePath}/${uuid}/deadlines/`;
    return await this.request.post(url, data, config);
  };

  updateDeadline = async (uuid, projectId, data, config = undefined) => {
    const url = `${this.resourcePath}/${uuid}/deadlines/${projectId}/`;
    return await this.request.put(url, data, config);
  };

  deleteDeadline = async (uuid, projectId, config = undefined) => {
    const url = `${this.resourcePath}/${uuid}/deadlines/${projectId}/`;
    return await this.request.delete(url, config);
  };
}
