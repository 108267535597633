import { useState } from 'react';
import PropTypes from 'prop-types';

import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';
import TextArea from '@hiredigital/ui/Input/TextArea';

import { useCommon } from '@context/common';
import { patchOrganization } from '@apis/organizations';
import { patchTeam } from '@apis/teams';

let saveTimeout = null;

const Type = {
  ORGANIZATION: 0,
  TEAM: 1,
};

const Address = ({ resourceUuid, data, type = Type.ORGANIZATION }) => {
  const common = useCommon();

  const [isSaving, setIsSaving] = useState(false);
  const [address, setAddress] = useState(data?.address);
  const [country, setCountry] = useState(data?.country);
  const [city, setCity] = useState(data?.city);
  const [region, setRegion] = useState(data?.region);
  const [postalCode, setPostalCode] = useState(data?.postalCode);

  const saveWithDelay = (timeout = 3000) => {
    if (saveTimeout) clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      saveAddress();
    }, timeout);
  };

  const saveAddress = () => {
    const data = {
      address: {
        address,
        country,
        city,
        region,
        postalCode,
      },
    };

    setIsSaving(true);
    const requests = [patchOrganization, patchTeam];
    requests[type](resourceUuid, data)
      .then((response) => {
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  return (
    <Card>
      <Card.Header title={`Address`} />
      <Card.Item>
        <InputGroup>
          <InputContainer>
            <TextArea
              className={'highlight-block'}
              label={`Address`}
              defaultValue={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <TextInput
              className={'highlight-block'}
              label={`Postal Code`}
              defaultValue={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              className={'highlight-block'}
              label={`City`}
              defaultValue={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <TextInput
              className={'highlight-block'}
              label={`State/Region`}
              defaultValue={region}
              onChange={(e) => setRegion(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              className={'highlight-block'}
              label={`Country`}
              defaultValue={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </InputContainer>
        </InputGroup>
      </Card.Item>
      <Card.Footer right>
        <Button onClick={() => saveWithDelay(0)} isLoading={isSaving} type={Button.Type.BLUE}>
          {`Save Address`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

Address.propTypes = {
  orgUuid: PropTypes.string,
  data: PropTypes.shape({
    contact: PropTypes.object,
    username: PropTypes.string,
    email: PropTypes.string,
  }),
  type: PropTypes.number,
};

export default Address;
