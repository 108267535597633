import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@hiredigital/ui/Avatar';

import Button from '@hiredigital/ui/Button';
import Select from '@hiredigital/ui/Input/Select';
import Styles from './Styles.module.scss';

const ItemType = {
  ADDED: 1,
  NOT_ADDED: 0,
};

const requestAvailabilityOptions = [
  {
    label: `Request Availability`,
    id: 1,
  },
  {
    label: `Don't Request Availability`,
    id: 3,
  },
];

const ParticipantItem = ({
  user,
  type,
  showAction = true,
  onRemove,
  onAdd,
  onRequestAvailabilityChange,
  callType,
  isReschedule,
  email,
  handleRemoveGuest,
}) => {
  const handleRequestAvailability = (value) => onRequestAvailabilityChange?.(value, user);
  const handleRemoveUser = (value) => {
    if (user === null) {
      handleRemoveGuest?.();
    } else {
      onRemove?.(value);
    }
  };
  return (
    <div className={Styles.participantItem}>
      <div className={Styles.participantDetails}>
        {user?.picture ? (
          <img className={Styles.participantPhoto} src={user?.picture} />
        ) : (
          <Avatar style={{ height: 30, width: 30, marginRight: 10 }} name={user?.name || email} />
        )}
        <p className={Styles.participantName}>{user?.name || email}</p>
      </div>
      {showAction && (
        <div className={Styles.actionArea}>
          {type === ItemType.ADDED ? (
            <Fragment>
              {(callType === 'reschedule' && isReschedule) || user === null ? (
                ''
              ) : (
                <Select
                  label={`Availability`}
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ id }) => id}
                  options={requestAvailabilityOptions}
                  defaultValue={{ label: `Request Availability`, id: 1 }}
                  onChange={(e) => handleRequestAvailability(e.target.value)}
                />
              )}
              <Button
                onClick={() => handleRemoveUser(user)}
                type={Button.Type.BLUE_OUTLINE}
                className={Styles.btnAction}>
                {`Remove`}
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button
                onClick={() => onAdd?.(user)}
                type={Button.Type.BLUE}
                className={Styles.btnAction}>
                {`Add`}
              </Button>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

ParticipantItem.Type = ItemType;

ParticipantItem.propTypes = {
  data: PropTypes.object,
  type: PropTypes.number,
  showAction: PropTypes.bool,
};

export default ParticipantItem;
