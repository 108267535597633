import classNames from 'classnames';
// import Icon from '@hiredigital/ui/Icon/Icon';
import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import IconClose from '@hiredigital/ui/Icon/icons/close.inline.svg';
import IconSearch from '@hiredigital/ui/Icon/icons/search.inline.svg';
import IconExpand from '@hiredigital/ui/Icon/icons/expand.inline.svg';
import IconMinimize from '@hiredigital/ui/Icon/icons/minimize.inline.svg';
import Styles from './Header.module.scss';

const ChatHeader = ({
  title,
  isMinimize,
  isCollapse,
  onToggleMinimize,
  onToggleCollapse,
  onMaximize,
  onMinimize,
  onClose,
  onSearch,
  onChatTitleClick,
}) => {
  const handleTitleClick = (e) => {
    if (onChatTitleClick) {
      e.stopPropagation();
      onChatTitleClick?.();
    }
  };

  return (
    <div className={Styles.header}>
      <div onClick={onToggleMinimize} className={Styles.title}>
        <button
          onClick={handleTitleClick}
          className={onChatTitleClick && Styles.linkTitle}
          title={title || ''}
          data-test-id='minimize-chat-id'>
          {title || ''}
        </button>
      </div>
      <div className={Styles.actions}>
        <button
          onClick={() => onSearch?.()}
          className={classNames(Styles.btnAction, Styles.headerBtn)}
          data-test-id='search-chat-id'>
          <IconSearch />
        </button>
        {!isCollapse &&
          (isMinimize ? (
            <button
              onClick={onMaximize}
              className={classNames(Styles.btnAction, Styles.headerBtn)}
              data-test-id='maximize-chat-id'>
              <IconExpand />
            </button>
          ) : (
            <button onClick={onMinimize} className={classNames(Styles.btnAction, Styles.headerBtn)}>
              <IconMinimize />
            </button>
          ))}

        <button
          onClick={onToggleCollapse}
          className={classNames(
            Styles.btnAction,
            Styles.headerBtn,
            isCollapse && Styles.btnCollapse
          )}>
          <IconDown />
        </button>

        <button
          onClick={onClose}
          className={classNames(Styles.btnAction, Styles.headerBtn)}
          data-test-id='close-chat-id'>
          <IconClose />
        </button>
      </div>
    </div>
  );
};

ChatHeader.propTypes = {};

export default ChatHeader;
