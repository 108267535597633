import PropTypes from 'prop-types';
import LayoutRow from './LayoutRow';
import MainColumn from './MainColumn';
import AsideColumn from './AsideColumn';
import LayoutHeader from './LayoutHeader';
import Styles from './Styles.module.scss';

const FormLayout = ({
  title,
  subtitle,
  pillNumber,
  pillDescription,
  children,
  number,
  ...props
}) => (
  <div className={Styles.formSection} {...props}>
    {title && (
      <div className={Styles.formHeader}>
        <div className={Styles.headline} {...(!!number && { 'data-number': number })}>
          {typeof title === 'function' ? title() : title}
        </div>
        {subtitle && (
          <div className={Styles.subtitle}>
            {typeof subtitle === 'function' ? subtitle() : subtitle}
          </div>
        )}
      </div>
    )}
    {children}
  </div>
);

FormLayout.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormLayout.Row = LayoutRow;
FormLayout.MainColumn = MainColumn;
FormLayout.AsideColumn = AsideColumn;
FormLayout.LayoutHeader = LayoutHeader;

export default FormLayout;
