import { createContext, useState, useContext, useCallback, useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';
import {
  getDiscussion,
  getDiscussions,
  getDiscussionMessages,
  putDiscussionMessage,
  createDiscussionMessage,
  deleteDiscussionMessage,
} from '../apis/discussions';
import { patchConference } from '../apis/conferences';
import { MessageMode, ConferenceStatus, MessageType } from '@hiredigital/lib/helpers/enum';
import SocketConnection from '../helpers/wsConnection';
import { useUser } from '../context/user';
import { useNetworkStatus } from '@context/network';
import { useDiscussionsList } from './discussionsList';

const cookies = new Cookies();
const DiscussionContext = createContext();

const DiscussionProvider = ({ children }) => {
  const { isOnline } = useNetworkStatus();
  const discussionSocket = new SocketConnection();

  const user = useUser();
  const { setDiscussions, discussions } = useDiscussionsList();

  const [isSocketOpen, setIsSocketOpen] = useState(false);

  const [messages, setMessages] = useState([]);
  const [messagesMeta, setMessagesMeta] = useState([]);
  const [discussionId, setDiscussionId] = useState(null);

  const messagesRef = useRef(); // necessary to update state in handlers

  useEffect(() => {
    messagesRef.current = { messages, discussions, discussionId }; // make sure we have updated reference of messages
  }, [messages, discussions, discussionId]);

  const addMessage = async (uuid, message, config) => {
    const updated = [{ ...message, type: MessageType.TEXT.id }, ...messages];
    setMessages(updated); // insert message even before getting the response

    const { data } = await createDiscussionMessage(uuid, message, undefined, config);
    setMessages(updated.map((v, idx) => (idx === 0 ? data : v)));
  };

  const updateMessage = async (uuid, message, config) => {
    const { data } = await putDiscussionMessage(uuid, message.id, message, undefined, config);
    const newMessages = messages.map((origMsg) => (origMsg.id === message.id ? data : origMsg));
    setMessages(newMessages);
  };

  const deleteMessage = async (uuid, messageId, config) => {
    await deleteDiscussionMessage(uuid, messageId, config);
    const tempMessages = [...messages];
    const deletedMsgIndex = tempMessages.findIndex((m) => m.id === messageId);
    tempMessages.splice(deletedMsgIndex, 1);
    setMessages(tempMessages);
  };

  const cancelSchedule = async (message, config) => {
    const uuid = message?.metaObject?.uuid;
    const { data } = await patchConference(uuid, { status: ConferenceStatus.CANCELLED.id }, config);
    const item = { ...message, metaObject: { ...message?.metaObject, ...data } };
    const newMessages = messages.map((origMsg) => (origMsg.id === message.id ? item : origMsg));
    setMessages(newMessages);
  };

  const loadMessages = async (uuid, config, reset = false) => {
    const { data } = await getDiscussionMessages(uuid, config);
    setMessagesMeta(data.meta);

    if (reset) {
      setMessages(data.results);
    } else {
      const newMessages = [...messages, ...data.results];
      setMessages(newMessages);
    }
  };

  // Websockets
  const openConnection = (uuid, initialConnection = true) => {
    const token = cookies.get('token');
    if (initialConnection) setDiscussionId(uuid);
    try {
      discussionSocket
        .open(initialConnection ? `discussions/${uuid}/?token=${token}` : undefined)
        .onMessage(onDataReceive)
        .onOpen(onOpen)
        .onClose(onClose);
    } catch (e) {
      // reconnection attempts will throw an error when the initial connection wasn't made yet
      // we'll ignore invalid URL error in reconnection attempts as the URL is not ready yet
      if (initialConnection || e.name !== 'SyntaxError') console.error(e);
    }
    return discussionSocket;
  };

  const reopenConnection = () => {
    if (isOnline) {
      openConnection(null, /* initialConnection= */ false);
    } else {
      discussionSocket.close();
    }
  };

  const onOpen = () => {
    console.log('discussion socket opened!');
    setIsSocketOpen(true);
  };

  const onClose = () => {
    console.log('discussion socket closed.');
    if (!isOnline) return;
    setTimeout(reopenConnection, 5000); // check again in 5 seconds
  };

  useEffect(reopenConnection, [isOnline]);

  const onDataReceive = (data) => {
    switch (data?.type) {
      case 'chat_message':
        updateChat(data);
        break;
      case 'send_mark_as_read':
        updateDiscussions(data);
        break;
      case MessageMode.UPDATED:
        break;
      default:
    }
  };

  const updateDiscussions = (data) => {
    if (data?.discussion) {
      const { discussions, discussionId } = messagesRef.current;
      setDiscussions(
        discussions?.map((v) => {
          return v?.id === discussionId ? { ...v, hasUnread: data?.discussion?.hasUnread } : v;
        }) || []
      );
    }
  };

  const updateChat = ({ message }) => {
    if (message?.sender?.uuid === user?.uuid) {
      return;
    }

    const mode = message?.status || MessageMode.CREATED;
    switch (mode) {
      case MessageMode.CREATED:
        const { messages } = messagesRef.current;
        setMessages([message, ...messages]);
        break;
      case MessageMode.UPDATED:
        break;
      default:
    }
  };

  return (
    <DiscussionContext.Provider
      value={{
        openConnection,
        messages,
        setMessages,
        messagesMeta,
        setMessagesMeta,
        loadMessages,
        addMessage,
        deleteMessage,
        updateMessage,
        cancelSchedule,
        isSocketOpen,
        // refreshDiscussions,
        // updateDiscussions,
        // deleteDiscussion,
        // addDiscussion,
      }}>
      {children}
    </DiscussionContext.Provider>
  );
};

const useDiscussion = () => useContext(DiscussionContext);

export { DiscussionProvider, DiscussionContext, useDiscussion };
