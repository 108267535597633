import TreeView from '@hiredigital/ui/TreeView';
import Styles from './ItemRow.module.scss';

const ItemRow = ({ content }) => (
  <TreeView.Item
    className={Styles.item}
    columns={[
      {
        content,
        className: Styles.fullRow,
      },
    ]}
  />
);

export default ItemRow;
