import Styles from '../../Styles.module.scss';

const HelpOptions = ({ onChatSelect, onCallSelect, onEmailSelect }) => (
  <div>
    <p className={Styles.smallTitle}>{`Need Help?`}</p>
    <p className={Styles.normalSubtitle}>
      {`Select one of the options below to get in touch with a  Hire Digital representative.`}
    </p>
    <div className={Styles.helpActions}>
      <button onClick={onCallSelect} className={Styles.helpAction}>
        <svg
          className={Styles.helpIcon}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 30 30'
          icon-phone=''>
          <path
            fill='currentColor'
            fillRule='nonzero'
            d='M12.87972 11.6137c.22154-.51881.28634-1.14663.16647-1.75235l-1.0371-4.10653c-.12552-.4963-.60558-.81767-1.11227-.74457l-5.0396.72704c-.51533.07434-.88738.53166-.85532 1.05133.29058 4.71 2.24642 9.16614 5.6197 12.57396 3.3982 3.4324 7.97297 5.39215 12.78779 5.63614.53163.02694.99118-.3673 1.0454-.89684l.54-5.27283c.05126-.50043-.27722-.96104-.76706-1.0756l-3.76787-.88122c-.66948-.1364-1.33454-.05596-1.92626.22297l-1.36208.58699c-1.0857-.71808-1.82022-1.30535-2.62538-2.11868-.83498-.84345-1.42372-1.59309-2.124-2.6878l.45758-1.26202zm9.68478 11.31482c-3.97585-.41072-7.70505-2.12814-10.52156-4.97299-2.80917-2.83793-4.52261-6.48356-4.96459-10.3737l3.2126-.46347.80479 3.1823c.0306.16169.00784.38223-.07677.58176l-.6436 1.77089a1 1 0 0 0 .08967.86694c.89356 1.4445 1.60862 2.3885 2.65977 3.45032 1.02737 1.0378 1.95854 1.75392 3.4115 2.6841a1 1 0 0 0 .93518.07605l1.88452-.81274c.2325-.10898.45884-.13636.67709-.09203l2.88248.67449-.35108 3.42808z'></path>
        </svg>
        {`Call`}
      </button>
      <button onClick={onChatSelect} className={Styles.helpAction}>
        <svg
          className={Styles.helpIcon}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 30 30'
          icon-chat=''>
          <g fill='none' fillRule='evenodd'>
            <path
              d='M7.2857 7c-.6302 0-1.2004.254-1.6133.6634C5.2565 8.0759 5 8.6459 5 9.275v9.45c0 .6291.2565 1.1991.6724 1.6116.4129.4094.983.6634 1.6133.6634H10.5c.5009 0 .973.2354 1.2698.637L15 25.5774l3.2302-3.9404c.2967-.4016.7689-.637 1.2698-.637h3.2143c.6302 0 1.2004-.254 1.6133-.6634.4159-.4125.6724-.9825.6724-1.6116v-9.45c0-.6291-.2565-1.1991-.6724-1.6116A2.2843 2.2843 0 0022.7143 7H7.2857z'
              stroke='currentColor'
              strokeWidth='2'
              stroke-line='round'></path>
            <path
              d='M10.8333 14c0 .767-.6218 1.3889-1.3889 1.3889-.767 0-1.3888-.6218-1.3888-1.3889 0-.767.6218-1.3889 1.3888-1.3889.7671 0 1.389.6218 1.389 1.3889zm5.5556 0c0 .767-.6218 1.3889-1.3889 1.3889-.767 0-1.3889-.6218-1.3889-1.3889 0-.767.6218-1.3889 1.3889-1.3889.767 0 1.3889.6218 1.3889 1.3889zm5.5555 0c0 .767-.6218 1.3889-1.3888 1.3889-.7671 0-1.389-.6218-1.389-1.3889 0-.767.6219-1.3889 1.389-1.3889.767 0 1.3888.6218 1.3888 1.3889z'
              fill='currentColor'
              fillRule='nonzero'></path>
          </g>
        </svg>
        {`Chat`}
      </button>
      <button onClick={onEmailSelect} className={Styles.helpAction}>
        <a className={Styles.helpLink} href={`mailto:contact@hiredigital.com`}>
          <svg
            className={Styles.helpIcon}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 30 30'
            icon-email=''>
            <path
              fill='currentColor'
              fillRule='nonzero'
              d='M23 10.88197V9.5H7v1.38197l8 4 8-4zm0 2.23606l-8 4-8-4V20.5h16v-7.38197zM7 7.5h16c1.10457 0 2 .89543 2 2v11c0 1.10457-.89543 2-2 2H7c-1.10457 0-2-.89543-2-2v-11c0-1.10457.89543-2 2-2z'></path>
          </svg>
          {`Email`}
        </a>
      </button>
    </div>
  </div>
);

HelpOptions.propTypes = {};

export default HelpOptions;
