import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from 'react-infinite-scroller';

import Loader from '@hiredigital/ui/Loader';
import Badge from '@hiredigital/ui/Badge/Badge';

import { getOrgTalents, getOrgTalent } from '@apis/organizations';
import { useOrg } from '@context/org';

import Avatar from '@hiredigital/ui/Avatar';
// import ButtonFilter from '../ButtonFilter/ButtonFilter';

import Menu from '@hiredigital/ui/Menu/Menu';
import CommonStyles from '../Common.module.scss';

const TalentsList = ({ onFilterSelect, selected }) => {
  const org = useOrg();

  const [nextPage, setNextPage] = useState(1);
  const [talents, setTalents] = useState([]);

  const handleSelect = (item) => {
    onFilterSelect?.(item);
  };

  useEffect(() => {
    loadMoreTalents(org?.uuid);
  }, []);

  const loadMoreTalents = () => {
    if (!nextPage) return;
    const params = { page: nextPage };
    getOrgTalents(org?.uuid, { params })
      .then(({ data }) => {
        setTalents(data?.results || []);
        setNextPage(data?.meta?.nextPage);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className={CommonStyles.filterItems}>
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={loadMoreTalents}
        hasMore={nextPage !== null}
        threshold={10}
        useWindow={false}
        loader={
          <div key={0}>
            <Loader type={Loader.Type.FULL} />
          </div>
        }>
        <Fragment>
          <div
            className={classNames(CommonStyles.filterItem, !selected && CommonStyles.selected)}
            onClick={() => handleSelect?.()}>
            <Avatar name={'A'} hash={'all'} style={{ marginRight: '10px' }} />
            {`All Talents`}
          </div>
          {talents?.map((v, idx) => (
            <Menu.Item
              as='div'
              key={idx}
              className={classNames(
                CommonStyles.filterItem,
                selected?.uuid === v?.uuid && CommonStyles.selected
              )}
              onClick={() => handleSelect?.(v)}>
              <Avatar
                name={v?.user?.name}
                hash={v?.user?.uuid}
                src={v?.user?.picture}
                style={{ marginRight: '10px' }}
              />
              {v?.user?.name}
              {v?.isProjectManager && (
                <Badge color='green' marginLeft='auto'>
                  {`Manager`}
                </Badge>
              )}
            </Menu.Item>
          ))}
        </Fragment>
      </InfiniteScroll>
    </div>
  );
};

const TalentsFilter = ({ talentUuid, onFilter }) => {
  const org = useOrg();
  const [talent, setTalent] = useState();

  useEffect(() => {
    if (!talentUuid) {
      // Clear
      setTalent(talentUuid);
    }
  }, [talentUuid]);

  useEffect(() => {
    const loadOrgData = async (o, t) => {
      try {
        const { data } = await getOrgTalent(o, t);
        setTalent(data);
      } catch (e) {
        setTalent(null);
      }
    };

    if (org && talentUuid) {
      loadOrgData(org?.uuid, talentUuid);
    }
  }, [org]);

  return (
    <>
      <Menu className={classNames(CommonStyles.marginLeft, CommonStyles.filterContainer)}>
        <Menu.Button className={CommonStyles.buttonFilter}>
          {talent?.user ? (
            <div className={CommonStyles.selectedFilter}>
              <Avatar
                name={talent?.user?.name}
                hash={talent?.user?.uuid}
                src={talent?.user?.picture}
                style={{ marginRight: '10px' }}
              />
              {talent.user?.name}
            </div>
          ) : (
            <Fragment>{`Filter by Talents`}</Fragment>
          )}
        </Menu.Button>
        <Menu.Items>
          <TalentsList
            onFilterSelect={(item) => {
              setTalent(item);
              onFilter?.(item?.uuid);
            }}
            selected={talent}
          />
        </Menu.Items>
      </Menu>
    </>
  );
};

export default TalentsFilter;
