import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import { patchOrganization } from '@apis/organizations';

import Card from '@hiredigital/ui/Card';
import Loader from '@hiredigital/ui/Loader';
import Select from '@hiredigital/ui/Input/Select';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/TextInput';
import InputGroup from '@hiredigital/ui/Form/InputGroup';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import SegmentedControl from '../SegmentedControl/SegmentedControl';
import { useCommon } from '@context/common';
import QuillEditor from '@hiredigital/ui/Quill/Editor';

const CompanyProfile = ({ orgUuid, data }) => {
  const common = useCommon();
  const [organization, setOrganization] = useState(data);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {}, []);

  const handleSubmit = () => {
    const { name, description, social, languages, countries, confidential, topics } = organization;

    const payload = {
      name,
      description,
      social,
      languages,
      countries,
      confidential,
      topics,
    };

    setIsSaving(true);
    patchOrganization(orgUuid, payload)
      .then(({ data }) => {
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrganization({
      ...organization,
      [name]: value,
    });
  };

  const handleSocialChange = (event) => {
    const { name, value } = event.target;
    setOrganization({
      ...organization,
      social: {
        ...organization?.social,
        [name]: value,
      },
    });
  };

  const handleSegment = (v) =>
    setOrganization({
      ...organization,
      confidential: v,
    });

  return (
    <Card>
      <Card.Header title={`Company Profile`} />
      <Card.Item>
        <InputGroup>
          <InputContainer>
            <TextInput
              onChange={handleChange}
              label={`Company Name`}
              name={`name`}
              defaultValue={organization?.name}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Company Website`}
              name={`website`}
              defaultValue={organization?.social?.website}
            />
          </InputContainer>
        </InputGroup>
        <InputContainer>
          <QuillEditor
            name='description'
            label={`Company Description`}
            placeholder={`Add a description of your business to share with potential hires.`}
            onChange={handleChange}
            defaultValue={organization?.description}
            toolbar={[
              ['bold', 'italic', 'underline'],
              ['blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              ['link', 'image'],
            ]}
            legacyCompat
          />
        </InputContainer>
        <InputGroup>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Facebook Page`}
              name={`facebook`}
              defaultValue={organization?.social?.facebook}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Twitter Profile`}
              name={`twitter`}
              defaultValue={organization?.social?.twitter}
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`LinkedIn Page`}
              name={`linkedin`}
              defaultValue={organization?.social?.linkedin}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Google+ Profile`}
              name={`googlePlus`}
              defaultValue={organization?.social?.googlePlus}
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Instagram Profile`}
              name={`instagram`}
              defaultValue={organization?.social?.instagram}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              onChange={handleSocialChange}
              label={`Youtube Channel`}
              name={`youtube`}
              defaultValue={organization?.social?.youtube}
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <Select
              data-test-id='countries'
              name='countries'
              label={`Markets`}
              onChange={handleChange}
              defaultValue={organization?.countries}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              options={common?.countries}
              isMulti
            />
          </InputContainer>
          <InputContainer>
            <Select
              data-test-id='languages'
              name='languages'
              label={`Languages`}
              onChange={handleChange}
              defaultValue={organization?.languages}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              options={common?.languages}
              isMulti
            />
          </InputContainer>
        </InputGroup>
        <InputGroup>
          <InputContainer>
            <Select
              data-test-id='topics'
              name='topics'
              label={`Verticals`}
              onChange={handleChange}
              defaultValue={organization?.topics}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ id }) => id}
              options={common?.topics}
              isMulti
              isMultiLevel
              hideSelectedOptions={false}
            />
          </InputContainer>
        </InputGroup>
      </Card.Item>
      <Card.Item>
        <div className={Styles.profileSettings}>
          <div className={Styles.settings}>
            <p>{`Profile Visibility`}</p>
            <p
              className={
                Styles.description
              }>{`Talents will be able to see your company profile when applying to your briefs. (Recommended)`}</p>
          </div>
          <div>
            <SegmentedControl
              name={`confidential`}
              options={[
                { label: 'Private', value: true },
                { label: 'Default', value: false },
              ]}
              defaultValue={organization?.confidential}
              height={40}
              width={120}
              onChange={handleSegment}
            />
          </div>
        </div>
      </Card.Item>
      <Card.Footer right>
        <Button name='submit' onClick={handleSubmit} type={Button.Type.BLUE}>
          {isSaving && (
            <Loader
              style={{ marginRight: '10px' }}
              color={Loader.Color.WHITE}
              size={Loader.Size.SMALL}
            />
          )}
          {`Save Profile`}
        </Button>
      </Card.Footer>
    </Card>
  );
};

CompanyProfile.propTypes = {
  orgUuid: PropTypes.string,
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    languages: PropTypes.array,
    topics: PropTypes.array,
    countries: PropTypes.array,
    social: PropTypes.object,
    confidential: PropTypes.bool,
  }),
};

export default CompanyProfile;
