import { Fragment, useState } from 'react';

import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';

import Badge from '@hiredigital/ui/Badge/Badge';

import {
  ApplicationStatus,
  FrontendEngagementStatus,
  ConferenceStatus,
  RateType,
} from '@hiredigital/lib/helpers/enum';
import { callTimeFormatter, callDayFormatter } from '@hiredigital/lib/helpers/date';

import SplitButton from '@hiredigital/ui/Button/SplitButton';
import PortfolioList from '../../../PortfolioList/PortfolioList';
import References from '@hiredigital/ui/Form/References';
import OverviewItem from '../overview/OverviewItem';
import { formatCurrency } from '@hiredigital/lib/helpers/utils';
import { useOrg } from '@context/org';
import { useCommon } from '@context/common';

import Styles from './Candidates.module.scss';

const isNewRates = /^true$/i.test(process.env.NEW_RATES_FLAG);

const Item = ({
  application,
  engagement,
  onAcceptCandidate,
  onAddTalentToProject,
  onRequestInterview,
  onRescheduleInterview,
  onCancelInterview,
  onViewDiscussion,
  isBusy = false,
}) => {
  const org = useOrg();
  const common = useCommon();
  const [isLocalTime, setIsLocalTime] = useState(false);
  const [conference, setConference] = useState(application?.discussion?.conference || {});

  const portfolios = (
    application?.team?.portfolioItems ||
    application?.user?.portfolioItems ||
    []
  ).slice(0, 3);
  const stripeCurrency = org?.payment?.stripeCurrency;
  const colorMaps = [
    null,
    'gray', // Badge.Type.GRAY, // Styles.gray,
    'blue', // Badge.Type.BLUE, // Styles.blue,
    'orange', // Badge.Type.ORANGE, // Styles.orange,
    'green', // Badge.Type.GREEN, // Styles.green,
    'gray', // Badge.Type.GRAY, // Styles.gray,
    'gray', // Badge.Type.GRAY, // Styles.gray,
    'blue', // Badge.Type.BLUE, // Styles.blue,
    'gray', // Badge.Type.GRAY, // Styles.gray,
    'orange', // Badge.Type.ORANGE, // Styles.orange,
  ];

  const formattedTime = (time) => {
    return `${callTimeFormatter(
      time,
      isLocalTime ? common?.timezone?.timezoneOffset : conference?.currentTimezone?.timezoneOffset
    )}`;
  };

  const formattedDate = (time) => {
    return `${callDayFormatter(
      time,
      isLocalTime ? common.timezone?.timezoneOffset : conference?.currentTimezone?.timezoneOffset
    )}`;
  };

  const handleView = () => {
    const resource = application?.team ? 'teams' : 'resumes';
    const id = application?.team?.slug || application?.user?.username;
    const accessKey = application?.resumeAccessKey;
    window.open(
      `${process.env.MARKETING_URL}/${resource}/${id}${
        accessKey ? `?access_key=${accessKey}` : ``
      }`,
      '_blank'
    );
  };

  return (
    <Fragment>
      <div className={Styles.item}>
        <div className={Styles.userRow}>
          <img
            className={Styles.picture}
            src={application?.team?.picture || application?.user?.picture}
          />
          <div className={Styles.userDetails}>
            <div className={Styles.userRow}>
              <p className={Styles.name}>{application?.team?.name || application?.user?.name}</p>
              <Badge color={colorMaps[application?.status]} marginLeft='10px'>
                {ApplicationStatus.getEnum(application?.status)?.statusLabel}
              </Badge>

              {/* <span className={classNames(Styles.label, colorMaps[application?.status])}>
                {ApplicationStatus.getEnum(application?.status)?.statusLabel}
              </span> */}
            </div>
            <p>
              {application?.team?.portfolio?.primarySkill?.noun ||
                application?.user?.portfolio?.primarySkill?.noun}
            </p>
            {(application?.team?.headline || application?.user?.headline) && (
              <p className={Styles.headline}>
                {application?.team?.headline || application?.user?.headline}
              </p>
            )}
          </div>
        </div>
        {((engagement?.status === FrontendEngagementStatus.MATCH.id &&
          application?.status !== ApplicationStatus.REJECTED.id) ||
          application?.status === ApplicationStatus.ACCEPTED.id) && (
          <div className={Styles.actionRow}>
            {application?.status === ApplicationStatus.ACCEPTED.id && (
              <Button
                name='submit'
                type={Button.Type.BLUE}
                onClick={() => onAddTalentToProject?.(engagement?.assignments?.[0], application)}>
                {`Add to a New Project`}
              </Button>
            )}

            {[
              ApplicationStatus.SUBMITTED.id,
              ApplicationStatus.SUBMITTED_REVIEWED.id,
              ApplicationStatus.INTERVIEW.id,
              ApplicationStatus.INTERVIEW_SELECTED.id,
            ].includes(application?.status) && (
              <Button
                name='submit'
                type={Button.Type.BLUE}
                onClick={() => onAcceptCandidate?.(application, engagement)}>
                {`Accept Candidate`}
              </Button>
            )}

            {engagement.status === FrontendEngagementStatus.MATCH.id &&
              application.status !== ApplicationStatus.ACCEPTED.id &&
              application.status !== ApplicationStatus.REJECTED.id &&
              (!application?.discussion?.conference ||
                [ConferenceStatus.DRAFT.id, ConferenceStatus.CANCELLED.id].includes(
                  application?.discussion?.conference?.status
                )) && (
                <Button
                  name='submit'
                  type={Button.Type.BLUE_OUTLINE}
                  onClick={() => onRequestInterview?.(application, engagement)}>
                  {`Request Interview`}
                </Button>
              )}
            {application?.discussion?.conference &&
              engagement?.status === FrontendEngagementStatus.MATCH.id &&
              application.status !== ApplicationStatus.REJECTED.id &&
              [
                ConferenceStatus.PENDING.id,
                ConferenceStatus.PENDING_RESCHEDULE.id,
                ConferenceStatus.CONFIRMED.id,
              ].includes(application?.discussion?.conference?.status) && (
                <SplitButton
                  onClick={() => onViewDiscussion?.(application, engagement)}
                  type={SplitButton.Type.BLUE_OUTLINE}
                  style={{ marginLeft: '10px' }}
                  more={[
                    {
                      text: 'Reschedule',
                      onClick: () => onRescheduleInterview?.(application, engagement),
                    },
                    {
                      text: 'Cancel Schedule',
                      onClick: () => onCancelInterview?.(application, engagement),
                    },
                  ]}>
                  {'Interview'}
                </SplitButton>
              )}

            <Button name='submit' type={Button.Type.BLUE_OUTLINE} onClick={handleView}>
              {`View ${application?.team ? `Team` : `Resume`}`}
            </Button>

            {application?.discussion?.id &&
              application?.discussion?.conference?.status !== ConferenceStatus.DRAFT.id &&
              [
                ApplicationStatus.INTERVIEW.id,
                ApplicationStatus.INTERVIEW_SELECTED.id,
                ApplicationStatus.ACCEPTED.id,
                ApplicationStatus.REJECTED.id,
              ].includes(application?.status) && (
                <Button
                  name='submit'
                  type={Button.Type.BLUE_OUTLINE}
                  onClick={() => onViewDiscussion?.(application, engagement)}>
                  {`View Discussion`}
                </Button>
              )}
          </div>
        )}
        <div>
          {portfolios.length > 0 && application?.user && (
            <div style={{ marginTop: '10px' }}>
              <PortfolioList
                resourceUuid={application?.user?.uuid}
                type={PortfolioList.Type.USER}
                data={portfolios}
                viewOnly
              />
            </div>
          )}

          {application?.status !== ApplicationStatus.REJECTED.id && (
            <Fragment>
              {engagement?.status === FrontendEngagementStatus.MATCH.id &&
                application?.discussion?.conference?.status === ConferenceStatus.CONFIRMED.id &&
                conference?.call?.startTime && (
                  <div className={Styles.interview}>
                    <OverviewItem title={`Interview`}>
                      {`Your interview has been scheduled for `}
                      <strong>
                        {`${`${formattedDate(conference?.call?.startTime)} ${formattedTime(
                          conference?.call?.startTime
                        )}`} `}
                        <span onClick={() => setIsLocalTime(!isLocalTime)} className={Styles.link}>
                          {`(${
                            isLocalTime
                              ? `${common.timezone?.label} ${common.timezone?.timezoneOffset}`
                              : `${conference?.call?.currentTimezone?.label} ${conference?.call?.currentTimezone?.timezoneOffset}`
                          })`}
                        </span>
                      </strong>
                      {` via `}
                      <a
                        href={conference?.call?.conferenceUrl}
                        target={`_blank`}
                        className={Styles.link}>
                        {conference?.call?.conferenceUrl}
                      </a>
                    </OverviewItem>
                  </div>
                )}

              {((!isNewRates && application?.brandRate > 0) ||
                (isNewRates && application?.clientEngagementRate?.rateDisplayText)) && (
                <OverviewItem title={`Rate`} style={{ marginTop: '10px' }}>
                  {isNewRates
                    ? application?.clientEngagementRate?.rateDisplayText
                    : `${['SGD', 'sgd'].includes(stripeCurrency) ? 'S' : ''}${formatCurrency(
                        application?.brandRate || 0,
                        0
                      )} ${
                        application?.brandRateType
                          ? RateType.getEnum(application?.brandRateType).value
                          : ''
                      }`}
                </OverviewItem>
              )}

              {(!!application?.attachments?.length || !!application?.content) && (
                <OverviewItem title={`Proposal`} style={{ marginTop: '10px' }}>
                  <div style={{ marginTop: '10px' }}>
                    <References
                      attachments={application?.attachments || []}
                      links={[]}
                      showActions={false}
                    />
                  </div>
                </OverviewItem>
              )}
            </Fragment>
          )}
        </div>
        {isBusy && (
          <div className={Styles.overlay}>
            <Loader color={Loader.Color.WHITE} size={Loader.Size.LARGE} />
          </div>
        )}
      </div>
    </Fragment>
  );
};

Item.propTypes = {};

export default Item;
