import { Tooltip } from 'evergreen-ui/esm/tooltip';
import Avatar from '@hiredigital/ui/Avatar';
import classNames from 'classnames';
import Styles from './InlineAvatars.module.scss';

const InlineAvatars = ({ members = [], uuid, limit = 3, toolTipPlace = 'top', ...props }) => {
  const uniqueId = uuid || new Date().getUTCMilliseconds();
  return (
    <div {...props}>
      <Tooltip
        content={
          <div className={classNames(Styles.memberContainer, 'highlight-block')}>
            {members.map((member, idx) => (
              <div key={idx} className={Styles.memberRow}>
                <Avatar
                  style={{ margin: '4px 7px', marginLeft: '0' }}
                  name={member.user?.name || member.team?.name}
                  src={member.user?.picture || member.team?.picture}
                />
                <span>{member.user?.name || member.team?.name}</span>
              </div>
            ))}
          </div>
        }>
        <div
          className={classNames(Styles.memberRow, 'highlight-block')}
          data-tip
          data-for={uniqueId}>
          {members.slice(0, limit).map((member, idx) => (
            <Avatar
              key={idx}
              style={{ margin: '3px', height: '30px', width: '30px' }}
              name={member.user?.name || member.team?.name}
              src={member.user?.picture || member.team?.picture}
            />
          ))}
          {members?.length > limit && (
            <Avatar
              style={{ margin: '3px', height: '30px', width: '30px' }}
              name={`+${members?.length - limit}`}
              showWholeName
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default InlineAvatars;
