import Styles from './NewAccountToast.module.scss';

const NewAccountToast = ({ onLinkClick }) => (
  <div className={Styles.container}>
    <button className={Styles.link} onClick={onLinkClick}>{`Click here`}</button>
    {` to create an account and track your application process.`}
  </div>
);

export default NewAccountToast;
