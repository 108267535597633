import Styles from './Note.module.scss';

const Note = ({ description, children }) => {
  return (
    <div className={Styles.note}>
      <div>{typeof description === 'function' ? description() : description}</div>
      {children}
    </div>
  );
};

export default Note;
