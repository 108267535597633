import { useEffect, useState } from 'react';
import Button from '@hiredigital/ui/Button';
import Loader from '@hiredigital/ui/Loader';
import { useOrg } from '@context/org';
import MainContainer from '../StagesLayout/MainContainer';
import ActionBar from '../StagesLayout/ActionBar';
import { postBriefSubmit } from '@apis/briefs';
import SecurityDeposit from '../CompanyPayments/SecurityDeposit';

const Deposit = ({ initialBrief, onBack, onAfterSubmit }) => {
  const org = useOrg();

  const [isSaving, setIsSaving] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDepositPaid, setIsDepositPaid] = useState(false);

  useEffect(() => {
    if (org) {
      setIsDepositPaid(org?.depositPaid);
    }
    setIsLoaded(true);
  }, [org]);

  const handleBack = () => onBack?.('/overview');

  const handleSubmit = () => {
    setIsSaving(true);
    const payload = { page: initialBrief?.stage };
    postBriefSubmit(initialBrief?.uuid, payload)
      .then(({ data }) => {
        onAfterSubmit?.(data);
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
      });
  };

  return (
    <MainContainer
      title={`Project Deposit`}
      subtitle={`Place a deposit towards your first invoice. The deposit is fully refundable and will be returned if the talent is not matched.`}
      onBack={handleBack}
      backLabel={`Back to Confirmation`}>
      <SecurityDeposit onDepositSuccess={() => setIsDepositPaid(true)} />
      {isLoaded && isDepositPaid && (
        <ActionBar isWhite>
          <Button name='submit' type={Button.Type.BLUE} onClick={handleSubmit}>
            {isSaving && (
              <Loader
                style={{ marginRight: '10px' }}
                color={Loader.Color.WHITE}
                size={Loader.Size.SMALL}
              />
            )}
            {`Save and Continue`}
          </Button>
        </ActionBar>
      )}
    </MainContainer>
  );
};

Deposit.propTypes = {};

export default Deposit;
