// import { request } from './utils';

const serializeMetadata = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

export const postLinkMetadata = async (
  request,
  data,
  serialize = serializeMetadata,
  config = undefined
) => {
  const url = `get-link-metadata/`;
  return await request.post(url, serialize(data), config);
};

export const getIframeCheck = async (request, iframeUrl, data, config = undefined) => {
  const url = `/checkiframe?url=${iframeUrl}`;
  return await request.get(url, data, config);
};
