import classNames from 'classnames';
import Styles from './Badge.module.scss';

const Badge = ({ color = 'neutral', className, children, ...props }) => {
  return (
    <div className={classNames(Styles.badge, Styles[color], className)} style={{ ...props }}>
      {children}
    </div>
  );
};

export default Badge;
