import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles.module.scss';
import classNames from 'classnames';

const NavIndicator = ({ activeIndex, length, onSelect, navIndicatorClass }) => {
  const [active, setActive] = useState([activeIndex]);

  useEffect(() => {
    setActive([activeIndex]);
  }, [activeIndex]);

  const handleSelect = (e, idx) => {
    e.stopPropagation();
    setActive([idx]);
    onSelect?.(idx);
  };

  return (
    <ul className={classNames(Styles.navItems, navIndicatorClass)}>
      {Array.from({ length }).map((v, idx) => (
        <li key={idx} className={Styles.navListItem} onClick={(e) => handleSelect(e, idx)}>
          <span className={classNames(Styles.navItem, { [Styles.active]: active.includes(idx) })} />
        </li>
      ))}
    </ul>
  );
};

NavIndicator.propTypes = {
  length: PropTypes.number,
  activeIndex: PropTypes.number,
  navIndicatorClass: PropTypes.string,
};

export default NavIndicator;
