import { Fragment, memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import IconDown from '@hiredigital/ui/Icon/icons/down.inline.svg';
import IconOrg from '@hiredigital/ui/Icon/icons/company.inline.svg';

import Menu from '@hiredigital/ui/Menu/Menu';
import Position from 'evergreen-ui/esm/constants/src/Position';
import { Tooltip } from 'evergreen-ui/esm/tooltip';

import NavStyles from '../Nav/Nav.module.scss';
import Styles from '../Dropdown.module.scss';

import { useOrg } from '@context/org';

import ExpandIcon from '../ExpandIcon/ExpandIcon';

const Item = () => {
  return (
    <>
      <IconOrg className={NavStyles.icon} />
      <div className={Styles.title}>Company</div>
    </>
  );
};

const MemoItem = memo(Item);

const OrgMenu = () => {
  const org = useOrg();

  return (
    <Fragment>
      <div className={Styles.menuGroupTitle}>{`Company`}</div>
      <Menu.Item
        as={Link}
        to={`/settings/clients/${org.uuid}/profile`}
        data-test-id='org profile'
        className={Styles.menuItem}>
        {/* <Link to={`/settings/clients/${org.uuid}/profile`}> {`Profile & Guidelines`}</Link> */}
        {`Profile & Guidelines`}
      </Menu.Item>
      <Menu.Item
        as={Link}
        to={`/settings/clients/${org.uuid}/settings`}
        data-test-id='org settings'
        className={Styles.menuItem}>
        {/* <Link to={`/settings/clients/${org.uuid}/settings`}>{`Settings`}</Link> */}
        {`Settings`}
      </Menu.Item>
      <Menu.Item
        as={Link}
        to={`/settings/clients/${org.uuid}/members`}
        data-test-id='org members'
        className={Styles.menuItem}>
        {/* <Link to={`/settings/clients/${org.uuid}/members`}>{`Members`}</Link> */}
        {`Members`}
      </Menu.Item>
      {org?.isManager && (
        <Menu.Item
          as={Link}
          to={`/settings/clients/${org.uuid}/billing`}
          data-test-id='org billing'
          className={Styles.menuItem}>
          {/* <Link to={`/settings/clients/${org.uuid}/billing`}>{`Billing`}</Link> */}
          {`Billing`}
        </Menu.Item>
      )}
    </Fragment>
  );
};

const MemoOrgMenu = memo(OrgMenu);

const OrgDropdown = ({ open, mobileOpen }) => {
  const [mobileDropdown, setMobileDropdown] = useState(false);

  useEffect(() => {
    setMobileDropdown(false);
  }, [mobileOpen]);

  useEffect(() => {}, [mobileDropdown]);

  return (
    <Fragment>
      {mobileOpen ? (
        <Menu className={Styles.fullWidth}>
          <Menu.Button
            as='div'
            className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}
            onClick={() => mobileOpen && setMobileDropdown(!mobileDropdown)}>
            <MemoItem />
            <ExpandIcon isOpen={mobileDropdown} style={{ marginLeft: 'auto' }} />
          </Menu.Button>
          <Menu.Items
            className={classNames(Styles.mobileList, mobileDropdown && Styles.mobileListOpen)}>
            <MemoOrgMenu />
          </Menu.Items>
        </Menu>
      ) : (
        <Menu>
          {({ open: isShown }) => (
            <Fragment>
              <Menu.Button as='div' className={Styles.fullWidth}>
                <Tooltip
                  content={'Company'}
                  position={Position.RIGHT}
                  isShown={isShown || open || mobileOpen ? false : undefined}>
                  <div className={classNames(Styles.button, open && Styles.open, Styles.fullWidth)}>
                    <MemoItem />
                    <IconDown className={Styles.sideArrow} />
                  </div>
                </Tooltip>
              </Menu.Button>
              <Menu.Items
                className={Styles.menuItems}
                direction='right_bottom'
                style={{ minWidth: 'max-content' }}>
                <MemoOrgMenu />
              </Menu.Items>
            </Fragment>
          )}
        </Menu>
      )}
    </Fragment>
  );
};
OrgDropdown.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
};

export default OrgDropdown;
