import Group from './Group';
import Item from './Item';

const DirectMessages = ({ data, title, onItemSelect }) => {
  return (
    <Group title={title}>
      {data.map((item, index) => (
        <Item key={index} data={item} onItemSelect={onItemSelect} />
      ))}
    </Group>
  );
};

DirectMessages.propTypes = {};

export default DirectMessages;
