import { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Layout from '@components/Layout/AppLayout';
import PointContact from '@components/PointContact/PointContact';
import Agreement from '@components/Agreement/Agreement';
import Address from '@components/Address/Address';
import BankAccounts from '@hiredigital/ui/BankAccounts';

import { BankApiManager, BankValidator } from '@hiredigital/lib/apis/class/banks';
import { authRequest, request } from '@apis/utils';

import { getTeam } from '@apis/teams';

const TeamSettings = () => {
  const { uuid } = useParams();
  const [team, setTeam] = useState({});

  useEffect(() => {
    if (uuid) {
      getTeam(uuid)
        .then(({ data }) => setTeam(data))
        .catch((error) => console.error(error));
    }
  }, [uuid]);

  return (
    <Layout>
      <div>
        {team?.uuid && (
          <Fragment>
            <PointContact
              resourceUuid={team.uuid}
              data={team.contact}
              type={PointContact.Type.TEAM}
              users={team?.users?.map((v) => v?.user)}
            />
            <Address resourceUuid={team.uuid} data={team.address} type={PointContact.Type.TEAM} />
            <Agreement resourceUuid={uuid} data={team} type={PointContact.Type.TEAM} />
            <BankAccounts
              apiManager={new BankApiManager(authRequest, team.uuid, { resource: 'teams' })}
              oldApiManager={
                new BankApiManager(authRequest, team.uuid, {
                  resource: 'teams',
                  subResource: 'bank-accounts',
                })
              }
              validator={new BankValidator(request)}
              simpleRow
            />
          </Fragment>
        )}
      </div>
    </Layout>
  );
};

export default TeamSettings;
