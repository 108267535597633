import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './Styles.module.scss';

const ActionBar = ({ isWhite = true, children }) => {
  return (
    <>
      <div className={classNames(isWhite && Styles.isWhite, Styles.footerAction)}>
        <div className={Styles.actionBar}>{children}</div>
      </div>
      {/*<div className={Styles.footerShadow} />*/}
    </>
  );
};

ActionBar.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string,
};

export default ActionBar;
