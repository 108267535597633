import classNames from 'classnames';
import Styles from './Guide.module.scss';
import PaymentItem from './PaymentItem';

const PaymentCard = ({ children }) => {
  return <div className={classNames(Styles.item, Styles.paymentItem)}>{children}</div>;
};

PaymentCard.Item = PaymentItem;

export default PaymentCard;
