import { useState } from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

// import { authRequest, baseAuthRequest } from 'lib/apis/utils';
import Button from '../../Button/Button';
import classNames from 'classnames';
import Styles from './File.module.scss';
import TruncateString from 'react-truncate-string';

const NewSingle = ({
  name,
  formDataKey,
  label,
  error,
  accept,
  value,
  hideRemove,
  onDropRejected,
  ...props
}) => {
  const [progress, setProgress] = useState(0);
  const [progressLabel, setProgressLabel] = useState(null);

  const handleUploadProgress = (progressEvent) => {
    const prog = parseInt((100.0 * progressEvent.loaded) / progressEvent.total, 10);
    setProgress(prog);
    setProgressLabel(prog === 100 ? 'Loading...' : `${prog}%`);
  };

  const handleDrop = (acceptedFiles) => {
    const { fileUploadRequest, httpRequest, method, server, onResponse } = props;

    acceptedFiles.forEach((file) => {
      const formData = new FormData();
      if (file) {
        formData.append(formDataKey || name, file, file.fileName || file.name);
      }

      const config = {
        onUploadProgress: handleUploadProgress,
      };

      (server
        ? httpRequest[method](server, formData, config)
        : fileUploadRequest(formData, config)
      ).then(
        ({ data }) => {
          setProgress(0);
          setProgressLabel(null);
          onResponse?.(data);
        },
        (error) => {
          // pass
        }
      );
    });
  };

  const handleRemove = (event) => {
    event.stopPropagation();
    const { fileRemoveRequest, httpRequest, method, server, onResponse } = props;

    (server ? httpRequest[method](server) : fileRemoveRequest()).then(
      (response) => {
        onResponse?.(response?.data);
      },
      (error) => {
        // pass
      }
    );
  };

  const getName = (url) => {
    if (url) {
      const fileName = url.substring(url.lastIndexOf('/') + 1);
      const urlParts = fileName.split('?');
      return urlParts.shift();
    }
  };

  return (
    <Dropzone
      onDrop={handleDrop}
      accept={accept ? accept : ''}
      onDropRejected={onDropRejected}
      multiple={false}>
      {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
        <div
          className={classNames(
            Styles.newContainer,
            value && Styles.hasFile,
            isDragActive && Styles.dropIsActive,
            isDragAccept && Styles.dragAccept,
            isDragReject && Styles.dragReject
          )}
          {...getRootProps()}>
          <div className={classNames(Styles.progress)} style={{ width: `${progress}%` }} />

          <input {...getInputProps()} />
          <label htmlFor={name} className={classNames(Styles.label, error && Styles.error)}>
            {error || label}
          </label>
          {/*<div className={classNames(Styles.drop)}>
            <div className={Styles.placeholder}>
              <span>{progressLabel || label}</span>
            </div>
          </div>*/}
          <div className={classNames(Styles.file)}>
            <div className={Styles.fileName}>
              <TruncateString text={progressLabel || getName(value)} />
              {!hideRemove && (
                <div className={Styles.fileRemove} onClick={handleRemove}>
                  <div className={Styles.removeCross}>
                    <svg
                      width='26'
                      height='26'
                      viewBox='0 0 26 26'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M11.586 13l-2.293 2.293a1 1 0 0 0 1.414 1.414L13 14.414l2.293 2.293a1 1 0 0 0 1.414-1.414L14.414 13l2.293-2.293a1 1 0 0 0-1.414-1.414L13 11.586l-2.293-2.293a1 1 0 0 0-1.414 1.414L11.586 13z'
                        fill='currentColor'
                        fillRule='nonzero'
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={Styles.newAddButton} onClick={(e) => e.stopPropagation()}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button type={Button.Type.LIGHT_GRAY}>{`Browse`}</Button>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

NewSingle.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  idleLabel: PropTypes.string,
  formDataKey: PropTypes.string,
  required: PropTypes.bool,
  onInit: PropTypes.func,
  onResponse: PropTypes.func,
  hideRemove: PropTypes.bool,
  accept: PropTypes.array,
  onDropRejected: PropTypes.func,
  fileUploadRequest: PropTypes.func,
  fileRemoveRequest: PropTypes.func,
  value: PropTypes.string,
  // added support for custom http request, method and server
  request: PropTypes.func,
  method: PropTypes.string,
  server: PropTypes.string,
};

NewSingle.defaultProps = {
  hideRemove: false,
  method: 'put',
};

export default NewSingle;
