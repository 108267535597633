import { useState } from 'react';
import PropTypes from 'prop-types';
import Styles from '../../Styles.module.scss';

const InfoAlert = ({ content }) => {
  return <p className={Styles.alert}>{content}</p>;
};

InfoAlert.propTypes = {};

export default InfoAlert;
