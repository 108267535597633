import { useRef } from 'react';
import Styles from './ProfileImage.module.scss';

const ProfileImage = ({ children, ...props }) => {
  const refImage = useRef();

  return (
    <div className={Styles.container}>
      <input ref={refImage} type='file' style={{ display: 'none' }} />
      <div className={Styles.imageContainer}></div>
      <button
        onClick={() => refImage.current.click()}
        className={Styles.actionUpload}>{`Upload Profile Picture`}</button>
    </div>
  );
};

export default ProfileImage;
