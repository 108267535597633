import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Styles from '../../Styles.module.scss';
import classNames from 'classnames';
import Loader from '@hiredigital/ui/Loader';

const TileItem = memo(
  ({ isActive, onSelect, onItemAdd, onItemSubtract, data, isMulti, isBusy, ...props }) => {
    const [count, setCount] = useState(data?.items?.length);

    useEffect(() => {
      setCount(data?.items?.length);
    }, [data.items]);

    const handleAddItem = (e) => {
      // setCount(count + 1);

      // e.stopPropagation();
      onItemAdd?.(data);
    };

    const handleRemoveItem = (e) => {
      // setCount(count - 1);
      // e.stopPropagation();
      onItemSubtract?.(data);
    };

    const handleSelect = (e) => {
      if (!data?.items || !data?.items?.length) {
        handleAddItem(e);
      }
    };

    return (
      <div className={Styles.talentItem} {...props}>
        <div className={classNames(Styles.itemContent, { [Styles.active]: count })}>
          {isBusy && (
            <Loader
              color={Loader.Color.BLUE}
              size={Loader.Size.SMALL}
              style={{ position: 'absolute' }}
            />
          )}
          <div className={Styles.itemText} onClick={handleSelect}>
            <span>
              {isMulti && count > 0 && <span className={Styles.itemCount}>{count}</span>}
              {(data?.items?.length > 1 ? data?.pluralLabel : data?.label) || 'No Name'}
            </span>
          </div>
          {isMulti && count > 0 && (
            <div className={Styles.iconContainer}>
              <div className={Styles.itemAction} onClick={handleAddItem}>
                <svg
                  className={classNames(Styles.tileIcon, Styles.angleUp)}
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  icon-angle="'up'">
                  <path
                    d='M12.705 15.127a1 1 0 0 1-1.412 0L5.742 9.57A.822.822 0 1 1 6.905 8.41l4.29 4.293.805.804.805-.804 4.29-4.288a.822.822 0 1 1 1.163 1.164l-5.553 5.549z'
                    fill='currentColor'
                    fillRule='nonzero'></path>
                </svg>
              </div>
              <div className={Styles.itemAction} onClick={handleRemoveItem}>
                <svg
                  className={Styles.tileIcon}
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  icon-angle="'up'">
                  <path
                    d='M12.705 15.127a1 1 0 0 1-1.412 0L5.742 9.57A.822.822 0 1 1 6.905 8.41l4.29 4.293.805.804.805-.804 4.29-4.288a.822.822 0 1 1 1.163 1.164l-5.553 5.549z'
                    fill='currentColor'
                    fillRule='nonzero'></path>
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

// TileItem.propTypes = {};

export default TileItem;
